import React from 'react'
import { PropTypes } from 'prop-types'
import { Controller, useFormContext } from 'react-hook-form'
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
} from '@material-ui/core'
import FormControlWrapper from '../TextFormControl/TextFormControl.styles'

const NumberFormControl = ({
  label,
  error,
  isAutoFocus,
  name,
  placeholder,
  required,
  requiredMessage,
  pattern,
  minValue,
  patternMessage,
}) => {
 const { control, watch } = useFormContext()
  const fielValue = watch(name)
  return (
    <FormControlWrapper>
      <FormControl variant="standard" error={!!error}>
        <InputLabel htmlFor={name} className="label-colored">
          {label}
          {required && !fielValue?.trim() ? '*' : ''}
        </InputLabel>
        <Controller
          name={name}
          defaultValue=""
          control={control}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Input
              id={name}
              autoFocus={isAutoFocus}
              placeholder={placeholder}
              inputProps={{
                inputMode: 'numeric',
                numeric: true,
                digitOnly: true,
              }}
              type="number"
              aria-describedby={`${name}-text`}
              className="input-colored"
              onChange={onChange}
              onBlur={onBlur}
              inputRef={ref}
              value={value || fielValue}
            />
          )}
          rules={{
            required: {
              value: required,
              message: requiredMessage,
            },
            pattern: {
              value: pattern,
              message: patternMessage,
            },
            min: {
              value: minValue,
              message: `El valor debe ser mayor a ${minValue}`,
            },
          }}
        />
        {!!error && (
          <FormHelperText className="error-message" id={`${name}-text`}>
            {error}
          </FormHelperText>
        )}
      </FormControl>
    </FormControlWrapper>
  )
}
NumberFormControl.defaultProps = {
  required: false,
  requiredMessage: '',
  placeholder: '',
  minValue: 1,
  isAutoFocus: false,
  pattern: undefined,
  patternMessage: '',
  error:'',
}

NumberFormControl.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  pattern: PropTypes.any,
  placeholder: PropTypes.string,
  minValue: PropTypes.number,
  isAutoFocus: PropTypes.bool,
  requiredMessage: PropTypes.string,
  patternMessage: PropTypes.string,
}
export default NumberFormControl
