import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Container, BodyContent } from '../App/Common/index'
import { Body } from './ChartComponents/ChartUtilComponents'
import {
  getDashboardToday,
  getLastWorkOrdersRatings,
  getWorkshopHistoryLastMonths,
  getTopsTen,
  getTopCitiesAverageScores,
  getWorkOrderScore,
  getMechanicBilling,
  getMechanicEfficiency,
  getMechanicScore,
} from '../../actions/DashboardAction'

import TodaySectionComponent from './Sections/TodaySectionComponent'
import LastWorkOrdersRatingsSectionComponent from './Sections/LastWorkOrdersRatingsSectionComponent'
import WorkshopHistoryLastMonthsSectionComponent from './Sections/WorkshopHistoryLastMonthsSectionComponent'
import TopTenSectionComponent from './Sections/TopTenSectionComponent'
import TopCitiesAverageScoresHistorySectionComponent from './Sections/TopCitiesAverageScoresHistorySectionComponent'
import './Home.css'
import MetricModalByMechanic from './Metrics/MetricModalByMechanic'
import { AppLoader } from '../App/Common/AppLoader'

class Home extends Component {
  state = {
    todaySectionData: {},
    lastWorkOrdersRatingSectionData: [],
    workshopHistoryLastMonthsSectionData: {},
    topsTenSectionData: {},
    topCitiesAverageScoresHistorySectionData: {},
    lastMechanicScore: {},
    lastMechanicBilling: {},
    lastMechanicEfficiency: {},
    loadingTodaySectionData: true,
    loadingLastWorkOrdersRatingSectionData: true,
    loadingWorkshopHistoryLastMonthsSectionData: true,
    loadingTopsTenSectionData: true,
    loadingTopCitiesAverageScoresHistorySectionData: true,
    loadingMechanicScoresHistorySectionData: true,
    loadingMechanicBillingHistorySectionData: true,
    loadingMechanicEfficiencyHistorySectionData: true,
    showMetricModal: false,
    mechanicId: 0
  }

  async componentDidMount() {
    const {
      getDashboardTodayAction,
      getLastWorkOrdersRatingsAction,
      getWorkshopHistoryLastMonthsAction,
      getMechanicScoreAction,
      getTopsTenAction,
      getTopCitiesAverageScoresAction,
      getWorkOrderScoreAction,
      getMechanicBillingAction,
      getMechanicEfficiencyAction,
      currentUser
    } = this.props

    const todaySectionData = await getDashboardTodayAction()
    this.setState({
      loadingTodaySectionData: false,
      todaySectionData,
    })

    const lastWorkOrdersRatingSectionData =
      await getLastWorkOrdersRatingsAction()
    this.setState({
      loadingLastWorkOrdersRatingSectionData: false,
      lastWorkOrdersRatingSectionData,
    })

    const topsTenSectionData = await getTopsTenAction()
    this.setState({
      loadingTopsTenSectionData: false,
      topsTenSectionData,
    })

    const topCitiesAverageScoresHistorySectionData =
      await getTopCitiesAverageScoresAction()
    this.setState({
      loadingTopCitiesAverageScoresHistorySectionData: false,
      topCitiesAverageScoresHistorySectionData,
    })

    const getWorkOrderScoreSectionDataSectionData =
      await getWorkOrderScoreAction()
    this.setState({
      getWorkOrderScoreSectionDataSectionData
    })

    const workshopHistoryLastMonthsSectionData =
      await getWorkshopHistoryLastMonthsAction()
    this.setState({
      loadingWorkshopHistoryLastMonthsSectionData: false,
      workshopHistoryLastMonthsSectionData,
    })

    const mechanicBillingSectionData = 
      await getMechanicBillingAction(currentUser.employee_id)

    const getEspecificMechanicBilling = ()=>{
      const now = new Date()
      const month = now.getMonth() + 1
      const data = _.filter(mechanicBillingSectionData.invoiceMonthly.billedMonthly, (e)=>{
        return e.month === month
      })
      const billing = Math.round((data[0].invoiced / currentUser.employee_metric_goal) * 100, 2)

      const dataAv = _.filter(mechanicBillingSectionData.avInvoiceMonthly, (e)=>{
        return e.month === month
      })

      const fusionBilling = {inv: billing ,avInv: dataAv[0] , invData: data[0] }

      return fusionBilling
    }


    this.setState({
      loadingMechanicBillingHistorySectionData: false,
      lastMechanicBilling: getEspecificMechanicBilling() ,
    })

    const mechanicEfficiencySectionData = 
    await getMechanicEfficiencyAction(currentUser.employee_id)

  const getEspecificMechanicEfficiency = ()=>{
    const now = new Date()
    const month = now.getMonth() + 1
    const data = _.filter(mechanicEfficiencySectionData, (e)=>{
      return e.month === month
    }) 
    return data[0]
  }


  this.setState({
    loadingMechanicEfficiencyHistorySectionData: false,
    lastMechanicEfficiency: getEspecificMechanicEfficiency() ,
  })

  const  getMechanicScoreDataSection = await getMechanicScoreAction(currentUser.employee_id)
    this.setState({
      getMechanicScoreDataSection
    })

    const getEspecificMechanicScore = ()=>{
      const now = new Date()
      const month = now.getMonth() + 1
      const score = _.filter(getMechanicScoreDataSection.scoreMon1, (e)=>{
        return e.month === month
      })
      const otherScore = _.filter(getMechanicScoreDataSection.scoreMon2, (e)=>{
        return e.month === month
      })
      const fusionScore = {
        score: score[0].score,
        totalWorkOrder: otherScore[0].totalWorkOrder,
        percentage: otherScore[0].percentage
      }
      return fusionScore
    }

    this.setState({
      loadingMechanicScoresHistorySectionData: false,
      lastMechanicScore: getEspecificMechanicScore() ,
    })
    
  }

  goToHistory = () =>{
    const { history } = this.props
    history.push("/history")
  }

  goToMetrics = () =>{
    const { history } = this.props
    history.push("/metrics")
  }

  toggleMetricModal = () =>{
    const { showMetricModal } = this.state
    this.setState({ showMetricModal: !showMetricModal })
  } 

  setMechanicId = (id) =>{
    this.setState({
      mechanicId: id
    })
  }
  
  render() {
    const { currentUser } = this.props
    const {
      todaySectionData,
      lastWorkOrdersRatingSectionData,
      workshopHistoryLastMonthsSectionData,
      topsTenSectionData,
      topCitiesAverageScoresHistorySectionData,
      loadingTodaySectionData,
      loadingLastWorkOrdersRatingSectionData,
      loadingWorkshopHistoryLastMonthsSectionData,
      loadingTopsTenSectionData,
      loadingTopCitiesAverageScoresHistorySectionData,
      getWorkOrderScoreSectionDataSectionData,
      lastMechanicScore,
      lastMechanicBilling,
      lastMechanicEfficiency,
      getMechanicScoreDataSection,
      loadingMechanicScoresHistorySectionData,
      loadingMechanicEfficiencyHistorySectionData,
      loadingMechanicBillingHistorySectionData,
      mechanicId,
      showMetricModal
    } = this.state

    const firstSectionsLoading =
      loadingTodaySectionData &&
      loadingLastWorkOrdersRatingSectionData &&
      loadingMechanicScoresHistorySectionData &&
      loadingMechanicBillingHistorySectionData &&
      loadingMechanicEfficiencyHistorySectionData

    return (
      <Container>
        <BodyContent>
          <Body>
            {firstSectionsLoading ? (
              <div style={{ height: '500px'}}><AppLoader /></div>
            ) : (
              <>
                <TodaySectionComponent
                  title={
                    currentUser.rol_id === 3
                      ? `Bienvenido ${currentUser.employee_name}`
                      : 'Tu Taller Hoy'
                  }
                  data={currentUser.rol_id === 3 ? false : todaySectionData}
                  infoUser={currentUser}
                  score={
                    topCitiesAverageScoresHistorySectionData.averageMechanicScores
                  }
                />
                <LastWorkOrdersRatingsSectionComponent
                    title={
                      currentUser.rol_id === 3
                        ? 'Métricas de este mes'
                        : 'Calificaciones y Comentarios En OTs Más Recientes'
                    }
                    data={lastWorkOrdersRatingSectionData}
                    lastMechanicScore={lastMechanicScore}
                    otherMechanicScoreData={getMechanicScoreDataSection}
                    lastMechanicBilling={lastMechanicBilling}
                    lastMechanicEfficiency={lastMechanicEfficiency}
                    infoUser={currentUser}
                    goToMetrics={this.goToMetrics}
                  />
                {loadingWorkshopHistoryLastMonthsSectionData ? (
                  <AppLoader />
                ) : (
                  <WorkshopHistoryLastMonthsSectionComponent
                    title={
                      currentUser.rol_id === 3
                        ? 'Ultimas reseñas'
                        : 'Tu Taller A Través De Los Meses'
                    }
                    data={workshopHistoryLastMonthsSectionData}
                    active={currentUser.rol_id === 3 ? 0 : 1}
                    infoUser={currentUser}
                    workOrderScore={getWorkOrderScoreSectionDataSectionData}
                    goToHistory={this.goToHistory}
                  />
                )}
                {loadingTopsTenSectionData ? (
                  <AppLoader />
                ) : (
                  <TopTenSectionComponent
                    title="Tu Taller Este Año"
                    data={topsTenSectionData}
                    active={currentUser.rol_id === 3 ? 0 : 1}
                  />
                )}
                {loadingTopCitiesAverageScoresHistorySectionData ? (
                  <AppLoader />
                ) : (
                  <TopCitiesAverageScoresHistorySectionComponent
                    data={topCitiesAverageScoresHistorySectionData}
                    active={currentUser.rol_id === 3 ? 0 : 1}
                    toggleModal={this.toggleMetricModal}
                    setMechanicId={this.setMechanicId}
                  />
                )}
              </>
            )}
          </Body>
          <MetricModalByMechanic
            modalOpen={showMetricModal}
            toggleModal={this.toggleMetricModal}
            mechanicId={mechanicId}
          />
        </BodyContent>
      </Container>
    )
  }
}

Home.propTypes = {
  getDashboardTodayAction: PropTypes.func.isRequired,
  getLastWorkOrdersRatingsAction: PropTypes.func.isRequired,
  getWorkshopHistoryLastMonthsAction: PropTypes.func.isRequired,
  getMechanicScoreAction: PropTypes.func.isRequired,
  getTopsTenAction: PropTypes.func.isRequired,
  getWorkOrderScoreAction: PropTypes.func.isRequired,
  getTopCitiesAverageScoresAction: PropTypes.func.isRequired,
  getMechanicBillingAction:PropTypes.func.isRequired,
  getMechanicEfficiencyAction: PropTypes.func.isRequired,
  currentUser: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.object).isRequired,
}

const mapStateToProps = ({ AppReducer }) => ({
  data: AppReducer.dashboardData,
  socket: AppReducer.socket,
  currentUser: AppReducer.currentUser,
})

const mapDispatchToProps = (dispatch) => ({
  getDashboardTodayAction() {
    return dispatch(getDashboardToday())
  },
  getLastWorkOrdersRatingsAction() {
    return dispatch(getLastWorkOrdersRatings())
  },
  getWorkshopHistoryLastMonthsAction() {
    return getWorkshopHistoryLastMonths()
  },
  getMechanicScoreAction(mechanic_id) {
    return getMechanicScore(mechanic_id)
  },
  getMechanicBillingAction(mechanic_id) {
    return getMechanicBilling(mechanic_id)
  },
  getMechanicEfficiencyAction(mechanic_id) {
    return getMechanicEfficiency(mechanic_id)
  },
  getTopsTenAction() {
    return getTopsTen()
  },
  getTopCitiesAverageScoresAction() {
    return getTopCitiesAverageScores()
  },
  getWorkOrderScoreAction() {
    return getWorkOrderScore()
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)
