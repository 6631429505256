import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom';
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Badge } from 'antd';
import {
  BellOutlined
} from "@ant-design/icons";

import { connect } from 'react-redux';

import './AvatarElements.scss'

const AvatarContainer = (props) => {
  const { bgColor, style, children } = props
  return (
    <div
      className='user-card'
      style={{
        display: 'flex',
        background: bgColor,
        ...style,
      }}
    >
      {children}
    </div>
  )
}

AvatarContainer.propTypes = {
  bgColor: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.objectOf(PropTypes.any),
}

AvatarContainer.defaultProps = {
  bgColor: '',
  children: <div />,
  style: {},
}

const AvatarPhoto = (props) => {
  const { width, height, style, onClick, title, pathImage, borderRadius } =
    props
  return (
    <button
      type="button"
      style={{
        padding: '0',
        margin: '0',
        borderRadius: '50%',
        border: 'none',
        outline: 'none',
        width,
        height,
        cursor: 'pointer',
        ...style,
      }}
      onClick={onClick}
      title={title}
    >
      <img
        src={pathImage}
        style={{
          borderRadius: `${borderRadius}%`,
          width,
          height,
        }}
        alt="Avatar"
      />
    </button>
  )
}

AvatarPhoto.propTypes = {
  pathImage: PropTypes.string.isRequired,
  borderRadius: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  style: PropTypes.objectOf(PropTypes.any),
  onClick: PropTypes.func,
  title: PropTypes.string,
}

AvatarPhoto.defaultProps = {
  borderRadius: 0,
  width: 0,
  height: 0,
  style: {},
  onClick: () => { },
  title: '',
}

const AvatarInformationContainer = (props) => {
  const { pLeft, pRight, style, children } = props
  return (
    <div
      style={{
        paddingLeft: pLeft,
        paddingTop: pRight,
        ...style,
      }}
    >
      {children}
    </div>
  )
}

AvatarInformationContainer.propTypes = {
  pLeft: PropTypes.number,
  pRight: PropTypes.number,
  children: PropTypes.node,
  style: PropTypes.objectOf(PropTypes.any),
}

AvatarInformationContainer.defaultProps = {
  pLeft: '',
  pRight: '',
  children: <div />,
  style: {},
}

const AvatarNickName = styled.div`
  font-size: 14px;
  color: #e5e5e5;
  text-align: left;
  text-transform: capitalize;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

const AvatarActions = (props) => {
  const { style, children } = props
  return (
    <div
      className='user-card-container'
      style={{
        paddingTop: 5,
        ...style,
        display: 'flex',
        justifyContent: 'space=around'
      }}
    >
      {children}
    </div>
  )
}

AvatarActions.propTypes = {
  style: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.node,
}

AvatarActions.defaultProps = {
  style: {},
  children: <div />,
}

const AvatarNotificationsCount = (props) => {
  const { style, count, forceReloadSidebar } = props;
  const [isShaking, setIsShaking] = useState(false);

  useEffect(() => {
    // Shake the icon when count changes
    setIsShaking(true);

    // Reset the shaking state after a short delay
    const timerId = setTimeout(() => {
      setIsShaking(false);
    }, 500); // The duration of the shake animation (0.5s)

    return () => clearTimeout(timerId);
  }, [count]);

  const handleReloadSidebar = () => {
    forceReloadSidebar();
  };

  const iconClassName = isShaking ? 'counter-icon shake' : 'counter-icon';

  return (
    <div
      style={{
        ...style,
        display: 'flex',
      }}
    >
      {count > 0 ? (
        <Badge count={count} showZero overflowCount={999}>
          <NavLink to="/notifications" onClick={handleReloadSidebar}>
            <div className="counter-container">
              <BellOutlined className={iconClassName} />
            </div>
          </NavLink>
        </Badge>
      ) : (
        <NavLink to="/notifications" onClick={handleReloadSidebar}>
          <div className="counter-container">
            <BellOutlined className={iconClassName} />
          </div>
        </NavLink>
      )}
    </div>
  );
};

AvatarNotificationsCount.propTypes = {
  style: PropTypes.objectOf(PropTypes.any),
  count: PropTypes.number.isRequired,
  forceReloadSidebar: PropTypes.func.isRequired,
};


AvatarNotificationsCount.defaultProps = {
  style: {}
}

const ConnectedAvatarNotificationsCount = connect((store) => ({
  count: store.AppReducer.notificationsCount,
}))(AvatarNotificationsCount);

export {
  AvatarContainer,
  AvatarPhoto,
  AvatarInformationContainer,
  AvatarNickName,
  AvatarActions,
  ConnectedAvatarNotificationsCount
};