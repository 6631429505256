import React from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory from 'react-bootstrap-table2-filter'
import paginationFactory from 'react-bootstrap-table2-paginator'
import PropTypes from 'prop-types'
import _ from 'lodash'
import styled from 'styled-components'
import { AppLoader } from './AppLoader'


const BootstrapTableWithDynamicPagination = ({
  keyField,
  data,
  columns,
  changePage,
  state,
  loadingData,
}) => {

  const customTotal = () => {
    return (
      <span className="react-bootstrap-table-pagination-total">
        {`Total ${
          data.totalRecords
        } registros. Página ${state.page} de ${Math.max(data.totalPages, 1)}`}
      </span>
    )
  }

  const options = {
    paginationSize: Math.max(data.totalPages, 1),
    sizePerPage: state.size,
    alwaysShowAllBtns: true,
    showTotal: true,
    hidePageListOnlyOnePage: true,
    paginationTotalRenderer: customTotal,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    onPageChange: async (page) => {
      changePage(page)
    },
    hideSizePerPage: true,
    totalSize: data.totalRecords,
  }

  return (
    <Wrapper>
      <BootstrapTable
        noDataIndication={() =>
          loadingData ? <AppLoader /> : 'Sin datos para mostrar'
        }
        keyField={keyField}
        data={loadingData ? [] : _.values(data.data)}
        columns={columns}
        filter={filterFactory()}
        remote
        pagination={paginationFactory(options)}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .react-bootstrap-table-pagination-list-hidden {
    display: none;
  }
`

BootstrapTableWithDynamicPagination.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.object,
    totalRecords: PropTypes.number,
    totalPages: PropTypes.number,
  }).isRequired,
  columns: PropTypes.arrayOf(PropTypes.any).isRequired,
  keyField: PropTypes.string.isRequired,
  changePage: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  loadingData: PropTypes.bool.isRequired,
}

export default BootstrapTableWithDynamicPagination
