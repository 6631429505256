/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { PropTypes } from 'prop-types'
import { Button } from 'antd'

import { ModalButtonsContainer } from '../../App/Reception/AddReception/VehicleStep/VehicleStep.style'
import TextFormControl from '../../App/Common/TextFormControl/TextFormControl'
import { ModelFormContainer } from '../AddModelForm/AddModelForm.style'

const AddBrandForm = ({ children, handleModelFormSubmit }) => {
  const methods = useForm({ mode: 'onChange' })
  const {
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = methods

  const isSubmitDisabled = !isValid

  const resetForm = () => {
    reset({ brandName: '' })
  }

  const onSubmit = (data) => {
    if (!isSubmitDisabled) {
      handleModelFormSubmit({
        brandName: data.brandName,
      })

      resetForm()
    }
  }

  useEffect(() => {
    resetForm()
  }, [])

  return (
    <FormProvider {...methods}>
      <ModelFormContainer onSubmit={handleSubmit(onSubmit)}>
        <TextFormControl
          label="Nombre"
          isAutoFocus
          name="brandName"
          error={errors?.brandName?.message}
          required
          requiredMessage="Ingresa un nombre para la marca"
        />
        <ModalButtonsContainer style={{ marginTop: '1.5rem' }}>
          {children}
          <Button type="primary" htmlType="submit" disabled={isSubmitDisabled}>
            Agregar
          </Button>
        </ModalButtonsContainer>
      </ModelFormContainer>
    </FormProvider>
  )
}

AddBrandForm.propTypes = {
  children: PropTypes.node,
  handleModelFormSubmit: PropTypes.func.isRequired,
}

AddBrandForm.defaultProps = {
  children: <></>,
}

export default AddBrandForm
