import React from 'react';

import {
  UserOutlined,
  TeamOutlined,
  CarFilled,
  BellFilled,
  ControlFilled,
  ExclamationCircleOutlined,
  AreaChartOutlined,
  ToolFilled,
  HistoryOutlined,
  StarFilled,
  SettingFilled,
  DatabaseFilled,
  FileTextFilled,
  GoldFilled
} from "@ant-design/icons";

import HandWithMoneyIcon from '../icons/HandWithMoneyIcon'
import HandWithHeartIcon from '../icons/HandWithHeartIcon'


const menuContent = [
  {
    title: "Recepción de vehículos",
    id: "receptions",
    path: "/receptions",
    icon: {
      size: 15,
      name: <FileTextFilled />,
    },
  },
  {
    title: "Tu taller en vivo",
    id: "workOrders",
    path: "/workOrders",
    icon: {
      size: 15,
      name: <ToolFilled />,
    },
    suffix: "Nuevo",
  },
  {
    id: "divider_1",
    divider: true,
    text: "",
  },
  {
    title: "Clientes",
    id: "customers",
    path: "/customers",
    icon: {
      size: 15,
      name: <UserOutlined />,
    },
  },
  {
    title: "Empleados",
    id: "employees",
    path: "/employees",
    icon: {
      size: 15,
      name: <TeamOutlined />,
    },
  },
  {
    title: "Vehículos",
    id: "vehicles",
    path: "/vehicles",
    icon: {
      size: 15,
      name: <CarFilled />,
    },
  },
  {
    title: "Notificaciones",
    id: "notifications_parent",
    path: "/notifications-parent",
    icon: {
      size: 15,
      name: <BellFilled />,
    },
    subItems: [
      {
        id: "workOrderList",
        title: "Configuración",
        path: "/workOrderList",
        icon: {
          size: 15,
          name: <ControlFilled />,
        },
      },
      {
        id: "notifications",
        title: "Todas tus notificaciones",
        path: "/notifications",
        icon: {
          size: 15,
          name: <ExclamationCircleOutlined />,
        },
      },
    ],
  },
  {
    id: "divider_2",
    divider: true,
    text: "",
  },
  {
    title: "Finanzas del taller",
    id: "Home",
    path: "/finances",
    icon: {
      size: 15,
      name: <AreaChartOutlined />,
    },
  },
  {
    title: "Ventas",
    id: "productOrders",
    path: "/ProductOrders",
    icon: {
      size: 15,
      name: <FileTextFilled />,
    },
  },
  {
    title: "Facturas",
    id: "invoices",
    path: "/invoices",
    icon: {
      size: 15,
      name: <FileTextFilled />,
    },
  },
  {
    title: "Inventario",
    id: "products",
    path: "/products",
    icon: {
      size: 15,
      name: <GoldFilled />,
    },
  },
  {
    id: "divider_3",
    divider: true,
    text: "",
  },
  {
    title: "Historial",
    id: "history_parent",
    path: "/history_parent",
    icon: {
      size: 15,
      name: <HistoryOutlined />,
    },
    subItems: [
      {
        id: "repairHistory",
        title: "Historial de reparaciones",
        path: "/history",
        icon: {
          size: 15,
          name: <HistoryOutlined />,
        },
      },
      {
        id: "salesHistory",
        title: "Historial de ventas",
        path: "/salesHistory",
        icon: {
          size: 15,
          name: <HistoryOutlined />,
        },
      },
    ],
  },
  {
    id: "divider_4",
    divider: true,
    text: "",
  },
  {
    title: "Marketplace",
    id: "marketplace",
    path: "/marketplace",
    icon: {
      size: 15,
      name: <StarFilled />,
    },
  },
  {
    id: "divider_5",
    divider: true,
    text: "",
  },
  {
    title: "Catálogos",
    id: "catalogs",
    path: "/catalogs",
    icon: {
      size: 15,
      name: <DatabaseFilled />,
    },
    subItems: [
      {
        id: "production",
        title: "Producción",
        path: "/production",
        icon: {
          size: 15,
          name: <DatabaseFilled />,
        },
        subItems: [
          {
            id: "serviceTypes",
            title: "Áreas de Servicio",
            path: "/servicetypes",
            icon: {
              size: 15,
              name: <SettingFilled />,
            },
          },
          {
            id: "categories",
            title: "Categorías",
            path: "/categories",
            icon: {
              size: 15,
              name: <SettingFilled />,
            },
          },
          {
            id: "processes",
            title: "Procesos",
            path: "/processes",
            icon: {
              size: 15,
              name: <ControlFilled />,
            },
          },
          {
            id: "packages",
            title: "Paquetes",
            path: "/packages",
            icon: {
              size: 15,
              name: <HandWithMoneyIcon color="#FFF" />,
            },
          },
          {
            id: "providers",
            title: "Proveedores",
            path: "/providers",
            icon: {
              size: 15,
              name: <HandWithHeartIcon color="#FFF" />
            },
          },
        ],
      },
      {
        id: "invoicing",
        title: "Facturación",
        path: "/invoicing",
        icon: {
          size: 15,
          name: <FileTextFilled />,
        },
        subItems: [
          {
            id: "contributorTypes",
            title: "Contribuyentes",
            path: "/contributorTypes",
            icon: {
              size: 15,
              name: <FileTextFilled />,
            },
          },
          {
            id: "series",
            title: "Series",
            path: "/series",
            icon: {
              size: 15,
              name: <SettingFilled />,
            },
          },
          {
            id: "invoiceType",
            title: "Tipos de factura",
            path: "/invoiceType",
            icon: {
              size: 15,
              name: <ControlFilled />,
            },
          },
        ],
      },
      {
        title: "Vehículos",
        id: "vehicles",
        path: "/vehicles",
        icon: {
          size: 15,
          name: <CarFilled />,
        },
        subItems: [
          {
            id: "brands",
            title: "Marcas",
            path: "/brands",
            icon: {
              size: 15,
              name: <CarFilled />,
            },
          },
          {
            id: "models",
            title: "Modelos",
            path: "/models",
            icon: {
              size: 15,
              name: <CarFilled />,
            },
          },
          {
            id: "items",
            title: "Items",
            path: "/items",
            icon: {
              size: 15,
              name: <DatabaseFilled />,
            },
          },
          {
            id: "vehicleTypes",
            title: "Tipos de vehículo",
            path: "/vehicleTypes",
            icon: {
              size: 15,
              name: <CarFilled />,
            },
          },
        ],
      },
    ],
  },
]

export { menuContent }
