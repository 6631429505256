import axios from 'axios'
import _ from 'lodash'

import {
  GET_INVOICES_TYPES,
  ADD_INVOICES_TYPES,
  UPDATE_INVOICES_TYPES,
  DELETE_INVOICES_TYPES,
} from '../actionTypes/InvoiceTypesTypes'
import { NotificationHandler } from '../Helpers'

const getInvoiceTypeAction = (payload) => ({
  type: GET_INVOICES_TYPES,
  payload,
})

const addInvoiceTypeAction = (payload) => ({
  type: ADD_INVOICES_TYPES,
  payload,
})

const updateInvoiceTypeAction = (payload) => ({
  type: UPDATE_INVOICES_TYPES,
  payload,
})

const deleteInvoiceTypeAction = (payload) => ({
  type: DELETE_INVOICES_TYPES,
  payload,
})

export const getInvoiceType = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const invoiceTypeQuantity = _.size(
      getState().InvoiceTypeReducer.invoiceTypes
    )
    if (invoiceTypeQuantity === 0) {
      axios
        .get('/api/invoice_types')
        .then((response) => {
          if (!response.data.error) {
            const invoiceTypes = _.keyBy(response.data.data, 'invoice_type_id')
            dispatch(getInvoiceTypeAction(invoiceTypes))
            resolve(response.data)
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al obtener los tipos de factura'
            )
          }
        })
        .catch(() => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al obtener los tipos de factura'
          )
        })
    } else {
      resolve({})
    }
  })

export const addInvoiceTypes = (invoiceType) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const newInvoiceType = {
      ...invoiceType,
      workshop_id: getState().AppReducer.currentUser.workshop_id,
    }
    axios
      .post('/api/invoice_types', newInvoiceType)
      .then((response) => {
        if (!response.data.error) {
          dispatch(addInvoiceTypeAction(response.data.data))
          resolve(response)
          NotificationHandler('Done', 'info', 'Tipo de factura agregado')
        } else {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al agregar el tipo de factura'
          )
        }
      })
      .catch(() => {
        reject()
        NotificationHandler(
          'Error',
          'error',
          'Error al agregar el tipo de factura'
        )
      })
  })

export const updateInvoiceType = (invoiceType) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    if (invoiceType.invoice_type_id) {
      const newInvoiceType = {
        ...invoiceType,
        workshop_id: getState().AppReducer.currentUser.workshop_id,
      }
      axios
        .put(
          `/api/invoice_types/${invoiceType.invoice_type_id}`,
          newInvoiceType
        )
        .then((response) => {
          if (!response.data.error) {
            dispatch(updateInvoiceTypeAction(response.data.data))
            resolve(response)
            NotificationHandler('Done', 'info', 'Tipo de factura actualizado')
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al actualizar el tipo de factura'
            )
          }
        })
        .catch(() => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al actualizar el tipo de factura'
          )
        })
    } else {
      reject()
      NotificationHandler(
        'Error',
        'error',
        `No se proporcionó id a: ${invoiceType.invoice_type_name}`
      )
    }
  })

export const deleteInvoiceType = (invoiceType) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (invoiceType.invoice_type_id) {
      axios
        .delete(`/api/invoice_types/${invoiceType.invoice_type_id}`)
        .then((response) => {
          if (response.data.error) {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al eliminar el tipo de factura'
            )
          } else {
            dispatch(deleteInvoiceTypeAction(response.data.data))
            resolve(response)
            NotificationHandler('Done', 'success', 'Tipo de factura eliminado')
          }
        })
        .catch(() => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al eliminar el tipo de factura'
          )
        })
    } else {
      reject()
      NotificationHandler(
        'Error',
        'error',
        `No se proporcionó id a: ${invoiceType.invoice_type_name}`
      )
    }
  })
