import styled from 'styled-components'

export const LoadingThumbnailWrapper = styled.div`
  display: flex;
  margin: auto;
  justify-content: center;
  height: 100%;
`

export const DefaultThumbnailImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`
