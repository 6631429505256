import _ from 'lodash'

import {
  GET_SERIES,
  ADD_SERIES,
  UPDATE_SERIES,
  DELETE_SERIES,
  UPDATE_ACTUAL_NUMBER,
} from '../actionTypes/SeriesTypes'

export default (
  state = {
    series: {},
  },
  action
) => {
  switch (action.type) {
    case GET_SERIES: {
      return {
        ...state,
        series: action.payload,
      }
    }
    case ADD_SERIES: {
      return {
        ...state,
        series: {
          ...state.series,
          [action.payload.serie_id]: action.payload,
        },
      }
    }
    case UPDATE_SERIES: {
      return {
        ...state,
        series: {
          ...state.series,
          [action.payload.serie_id]: action.payload,
        },
      }
    }
    case DELETE_SERIES: {
      const newState = _.cloneDeep(state)
      delete newState.series[action.payload.serie_id]
      return newState
    }
    case UPDATE_ACTUAL_NUMBER: {
      return {
        ...state,
        series: {
          ...state.series,
          [action.payload.serie_id]: {
            ...state.series[action.payload.serie_id],
            serie_actual_number: action.payload.serie_actual_number,
          },
        },
      }
    }
    default: {
      return state
    }
  }
}
