import axios from 'axios'
import _ from 'lodash'
import { MarketplaceService } from '../Constants'

import {
  GET_PRODUCTS,
  ADD_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
  MODIFY_STOCK_PRODUCT,
  PRODUCTS_LOADING,
} from '../actionTypes/ProductTypes'
import { NotificationHandler } from '../Helpers'

const getProductsActions = (payload) => ({
  type: GET_PRODUCTS,
  payload,
})

const addProductAction = (payload) => ({
  type: ADD_PRODUCT,
  payload,
})

const updateProductAction = (payload) => ({
  type: UPDATE_PRODUCT,
  payload,
})

const deleteProductAction = (payload) => ({
  type: DELETE_PRODUCT,
  payload,
})

const modifyStockProductAction = (payload) => ({
  type: MODIFY_STOCK_PRODUCT, // TODO: crear un reducer específico de modificación de stock
  payload,
})

const productsLoading = (payload) => ({
  type: PRODUCTS_LOADING,
  payload,
})

export const getProducts = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const productsQuantity = _.size(getState().ProductReducer.products)
    if (productsQuantity >= 0) {
      // test
      axios
        .get('/api/products')
        .then((response) => {
          if (!response.data.error) {
            const products = _.keyBy(
              response.data.data.map((p) => {
                return {
                  ...p,
                  showModels:
                    p.models !== null
                      ? JSON.parse(p.models)
                          .flatMap((m) => m.label)
                          .join(', ')
                      : '',
                }
              }),
              'product_id'
            ) //
            dispatch(getProductsActions(products))
            resolve(response.data)
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al obtener respuestos',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al obtener respuestos',
            error
          )
        })
    } else {
      resolve({})
    }
  })

export const addProduct = (product) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { workshop_id } = getState().AppReducer.currentUser
    const api = axios.create({
      baseURL: MarketplaceService,
    })
    console.log(product)
    api
      .post('/api/products', { ...product, workshop_id })
      .then((response) => {
        if (!response.data.error) {
          const productToRedux = {
            ...response.data.data,
            branch: { Mientras: 'Esto' },
            category:
              getState().CategoryReducer.categories[product.category_id],
          }
          dispatch(addProductAction(productToRedux))
          resolve(response)
          NotificationHandler('Done', 'info', 'Repuesto agregado')
        } else {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al agregar el repuesto',
            response.data.data
          )
        }
      })
      .catch((error) => {
        reject()
        NotificationHandler(
          'Error',
          'error',
          'Error al agregar el repuesto',
          error
        )
      })
  })

export const updateProduct = (product) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    if (product.product_id) {
      const api = axios.create({
        baseURL: MarketplaceService,
      })
      api
        .put(`/api/products/${product.product_id}`, product)
        .then((response) => {
          if (!response.data.error) {
            const productToRedux = {
              ...product,
              // branch: { Mientras: 'Esto' },
              showModels:
                product.models !== null
                  ? JSON.parse(product.models)
                      .flatMap((m) => m.label)
                      .join(', ')
                  : '',
              category:
                getState().CategoryReducer.categories[product.category_id],
            }
            dispatch(updateProductAction(productToRedux))
            resolve(response)
            NotificationHandler(
              'Done',
              'info',
              'Repuesto modificado correctamente'
            )
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al actualizar el repuesto',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al actualizar el repuesto',
            error
          )
        })
    } else {
      reject()
      NotificationHandler(
        'Error',
        'error',
        `No se proporcionó id a: ${product.product_name}`
      )
    }
  })

export const deleteProduct = (product) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (product.product_id) {
      axios
        .delete(`/api/products/${product.product_id}`)
        .then((response) => {
          if (!response.data.error) {
            dispatch(deleteProductAction(product))
            resolve(response)
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al eliminar el repuesto',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al eliminar el repuesto',
            error
          )
        })
    } else {
      reject()
      NotificationHandler(
        'Error',
        'error',
        `No se proporcionó id a: ${product.product_name}`
      )
    }
  })

export const modifyStockProduct =
  (productToModifyStock) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const { workshop_id } = getState().AppReducer.currentUser
      const api = axios.create({
        baseURL: MarketplaceService,
      })
      api
        .post('/api/products/modify-stock', {
          ...productToModifyStock,
          workshopId: workshop_id,
        })
        .then((response) => {
          if (!response.data.error) {
            const productToRedux = {
              ...response.data.data,
              branch: '', // TODO: Descartadas las sucursales
              showModels:
                response.data.data.models !== null
                  ? JSON.parse(response.data.data.models)
                      .flatMap((m) => m.label)
                      .join(', ')
                  : '',
              category: response.data.data.category_id,
            }
            dispatch(modifyStockProductAction(productToRedux)) // TODO: reducer para actualizar info de repuesto
            resolve(response)
            NotificationHandler('Done', 'info', 'Stock de repuesto modificado')
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al modificar stock',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al modificar stock',
            error
          )
        })
    })

export const fullSearch = (search) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    if (search.length >= 2) {
      dispatch(productsLoading(true))
      const { workshop_id } = getState().AppReducer.currentUser
      const workshopId = Number(workshop_id)
      const api = axios.create({
        baseURL: MarketplaceService,
      })
      api
        .get(
          `/api/products/metadata/?workshopId=${workshopId}&search=${search}`
        )
        .then((response) => {
          const products = _.keyBy(
            response.data.map((p) => {
              return {
                ...p,
                showModels:
                  p.models !== null
                    ? JSON.parse(p.models)
                        .flatMap((m) => m.label)
                        .join(', ')
                    : '',
              }
            }),
            'product_id'
          ) //
          dispatch(getProductsActions(products))
          resolve(response.data)
          //
          dispatch(productsLoading(false))
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al obtener respuestos',
            error
          )
        })
    } else if (search.length === 0) {
      // console.info("Todos los respuestos");
      axios.get('/api/products').then((response) => {
        if (!response.data.error) {
          const products = _.keyBy(response.data.data, 'product_id')
          dispatch(getProductsActions(products))
          resolve(response.data)
        } else {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al obtener respuestos',
            response.data.data
          )
        }
      })
    } else {
      // console.info('No se puede buscar con menos de 3 caracteres');
      resolve({})
    }
  })

export const fullSearchMarketplace = (search) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    if (search.length >= 2) {
      dispatch(productsLoading(true))
      const { workshop_id } = getState().AppReducer.currentUser
      const workshopId = Number(workshop_id)
      const api = axios.create({
        baseURL: MarketplaceService,
      })
      api
        .get(
          `/api/products/metadata-marketplace/?workshopId=${workshopId}&search=${search}`
        )
        .then((response) => {
          const products = _.keyBy(response.data, 'product_id')
          dispatch(getProductsActions(products))
          resolve(response.data)
          //
          dispatch(productsLoading(false))
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al obtener respuestos',
            error
          )
        })
    } else {
      // No se puede buscar con menos de 3 caracteres
      dispatch(getProductsActions([]))
      resolve({})
    }
  })
