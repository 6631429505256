import styled from 'styled-components'
import { PropTypes } from 'prop-types'
import { Table } from 'antd'
import React from 'react'

import { BREAK_POINTS_DESKTOP_FIRST } from '../../../../global/css/breakPoints'

const BudgetTable = ({ data = [], total }) => {
  const getTypeName = (type) => {
    switch (type) {
      case 'service':
        return 'Servicio'
      case 'package':
        return 'Paquete'
      case 'product':
        return 'Repuesto'
      default:
        return type
    }
  }

  const calculatePriceWithDiscount = (record) => {
    const totalPrice = Number(record.priceWithTax) * Number(record.quantity)
    const discount = (totalPrice * Number(record.discount)) / 100

    return discount ? totalPrice - discount : totalPrice
  }

  const columns = [
    {
      title: 'Repuesto/Servicio',
      dataIndex: 'name',
      key: 'name',
      width: 200,
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
      render: (value) => getTypeName(value),
      width: 100,
    },
    {
      title: 'Cantidad',
      dataIndex: 'quantity',
      key: 'quantity',
      width: 100,
    },
    {
      title: 'Descuento',
      dataIndex: 'discount',
      key: 'discount',
      width: 100,
    },
    {
      title: 'Precio',
      dataIndex: 'priceWithTax',
      key: 'priceWithTax',
      render: (text, record) => {
        return `$ ${calculatePriceWithDiscount(record)?.toFixed(2)}`
      },
      width: 100,
    },
  ]

  return (
    <Table
      dataSource={data}
      columns={columns}
      pagination={false}
      scroll={{ y: 400 }}
      footer={() => (
        <BudgetTotal>
          Total: <span>${total?.toFixed(2)}</span>
        </BudgetTotal>
      )}
    />
  )
}

export default BudgetTable

BudgetTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['package', 'service', 'product']).isRequired,
      quantity: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      priceWithTax: PropTypes.string.isRequired,
      discount: PropTypes.string.isRequired,
      unitPrice: PropTypes.string.isRequired,
      tax: PropTypes.string.isRequired,
      affectedSales: PropTypes.string.isRequired,
    })
  ).isRequired,
  total: PropTypes.number.isRequired,
}

const BudgetTotal = styled.p`
  text-align: right;
  margin-right: 5rem;
  font-weight: bold;
  font-size: 2rem;

  span {
    margin-left: 0.5rem;
  }

  @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
    margin: 1.5rem;
  }
`
