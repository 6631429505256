import React from 'react'
import PropTypes from 'prop-types'

import { SummaryCardContainer } from './VehicleSummaryCard.style'
import { useAddReceptionContext } from '../../../../../../contexts/AddReceptionContext'

const VehicleSummaryCard = ({ icon }) => {
  const { state } = useAddReceptionContext()
  const { newVehicle } = state

  return (
    <SummaryCardContainer>
      {icon}
      <div className="vehicle-details">
        <h2>
          {newVehicle.selectedBrand.customData.brand_name}{' '}
          {newVehicle.selectedModel.customData.model_name}
        </h2>
        <p>
          <span>Placa: </span>
          {newVehicle.vehicleLicensePlate}
        </p>
        <p>
          <span>Año: </span>
          {newVehicle.selectedYearMomentObj.format('YYYY')}
        </p>
      </div>
    </SummaryCardContainer>
  )
}

export default VehicleSummaryCard

VehicleSummaryCard.propTypes = {
  icon: PropTypes.node.isRequired,
}
