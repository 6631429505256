import styled from 'styled-components'
import { FormControl as Fc } from 'react-bootstrap'

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  padding: 10px 0px;
`

export const ControlLabel = styled.label`
  font-size: 13px;
  color: #191919;
`

export const FormControl = styled(Fc)``

export const InvoiceFormHeader = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .col-6 {
    width: 48%;
    div {
      margin-bottom: 10px;
    }
  }
`
