import React from 'react'
import {
  FormGroup,
  FormControl,
  ControlLabel,
  Form,
  Row,
  Col,
  Button,
} from 'react-bootstrap'
import PropTypes from 'prop-types'
import { SaveOutlined } from '@ant-design/icons'
import { CardContainer, CardTitle, Card } from './Components'


const BasicInfoForm = ({ state, currentUser, handleChange, handleSubmit }) => {
  const {
    employee_address,
    employee_email,
    employee_lastname,
    employee_name,
    employee_phone,
    employee_phone2,
    loading,
  } = state
  const { employee_dui, employee_nit } = currentUser
  return (
    <CardContainer width={45}>
      <CardTitle>Información básica</CardTitle>
      <Card>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col sm={12} md={6}>
              <FormGroup>
                <ControlLabel>Nombres</ControlLabel>
                <FormControl
                  onChange={handleChange}
                  name="employee_name"
                  type="text"
                  value={employee_name}
                />
              </FormGroup>
            </Col>
            <Col sm={12} md={6}>
              <FormGroup>
                <ControlLabel>Apellidos</ControlLabel>
                <FormControl
                  onChange={handleChange}
                  name="employee_lastname"
                  type="text"
                  value={employee_lastname}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6}>
              <FormGroup>
                <ControlLabel>Teléfono</ControlLabel>
                <FormControl
                  onChange={handleChange}
                  name="employee_phone"
                  type="text"
                  value={employee_phone}
                />
              </FormGroup>
            </Col>
            <Col sm={12} md={6}>
              <FormGroup>
                <ControlLabel>Teléfono 2</ControlLabel>
                <FormControl
                  onChange={handleChange}
                  name="employee_phone2"
                  type="text"
                  value={employee_phone2}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col sm={12} md={8}>
              <FormGroup>
                <ControlLabel>Email</ControlLabel>
                <FormControl
                  onChange={handleChange}
                  name="employee_email"
                  type="text"
                  value={employee_email}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col sm={12} md={12}>
              <FormGroup>
                <ControlLabel>Dirección</ControlLabel>
                <FormControl
                  onChange={handleChange}
                  name="employee_address"
                  type="text"
                  value={employee_address}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12}>
              <FormGroup>
                <ControlLabel>DUI</ControlLabel>
                <FormControl
                  onChange={handleChange}
                  readOnly
                  name="employee_address"
                  type="text"
                  value={employee_dui}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12}>
              <FormGroup>
                <ControlLabel>NIT</ControlLabel>
                <FormControl
                  onChange={handleChange}
                  readOnly
                  name="employee_address"
                  type="text"
                  value={employee_nit}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12}>
              <FormGroup>
                <ControlLabel>Imagen de usuario</ControlLabel>
                <FormControl
                  onChange={handleChange}
                  name="employee_urlimg"
                  type="file"
                />
              </FormGroup>
            </Col>
          </Row>
          <Button type="submit" bsStyle="primary" disabled={loading} className="btn-antd-icon">
            <SaveOutlined /> Guardar
          </Button>
        </Form>
      </Card>
    </CardContainer>
  )
}

BasicInfoForm.propTypes = {
  state: PropTypes.objectOf(PropTypes.any).isRequired,
  currentUser: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

export default BasicInfoForm
