import PropTypes from 'prop-types'

const PhoneInputPropTypes = {
  phone: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  showError: PropTypes.bool,
  setError: PropTypes.func.isRequired,
  countryCode: PropTypes.number.isRequired,
  isError: PropTypes.bool.isRequired,
  required: PropTypes.bool,
  ref: PropTypes.any,
  handleFocus: PropTypes.func,
}

export default PhoneInputPropTypes
