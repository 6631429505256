import styled from 'styled-components'

const AddContactFormWrapper = styled.div`
  height: 100%;

  form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .phone-wrapper {
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .info-disclaimer {
        width: fit-content;
        align-self: center;
      }
    }
  }
`
export default AddContactFormWrapper
