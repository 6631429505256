/* eslint-disable no-async-promise-executor */
import Axios from 'axios'
import moment from 'moment'


import { NotificationHandler } from '../Helpers'
import { GET_DASHBOARD_TODAY } from '../actionTypes/DashboardTypes'

const getDashboardTodayAction = (payload) => ({
  type: GET_DASHBOARD_TODAY,
  payload,
})

export const getDashboardToday = () => (dispatch) =>
  new Promise((resolve, reject) => {
    const current_date = moment().format('YYYY-MM-DD')
    Axios.get(`/api/stats_daily?current_date=${current_date}`)
      .then((response) => {
        if (response.status === 200 && response.status < 300) {
          if (response.data.error) {
            reject(response.data.data)
            NotificationHandler(
              'Error',
              'error',
              'Error obtener estadísticas diarias'
            )
          } else {
            const {
              data: { data },
            } = response
            dispatch(getDashboardTodayAction(data))
            resolve(data)
          }
        } else {
          reject(response)
          NotificationHandler(
            'Error',
            'error',
            'Error obtener estadísticas diarias'
          )
        }
      })
      .catch((error) => {
        reject(error)
        NotificationHandler(
          'Error',
          'error',
          'Error obtener estadísticas diarias'
        )
      })
  })

export const getLastWorkOrdersRatings = () => (dispatch) =>
  new Promise((resolve, reject) => {
    Axios.get('/api/dashboardLatestScore')
      .then((response) => {
        if (response.status === 200 && response.status < 300) {
          if (response.data.error) {
            reject(response.data.data)
          } else {
            dispatch(getDashboardTodayAction(response.data.data))
            resolve(response.data.data)
          }
        } else {
          reject(response)
          NotificationHandler(
            'Error',
            'error',
            'Error obtener últimas calificaciones'
          )
        }
      })
      .catch((error) => {
        reject(error)
        NotificationHandler(
          'Error',
          'error',
          'Error obtener últimas calificaciones'
        )
      })
  })

export const getWorkshopHistoryLastMonths = () =>
  new Promise(async (resolve, reject) => {
    try {
      const current_date = moment().format('YYYY-MM-DD')

      const invoiceMonthly = await Axios.get(
        `/api/history_stats/invoicingMonthly?current_date=${current_date}`
      )
      const invoiceDaily = await Axios.get(
        `/api/history_stats/invoicingDaily?current_date=${current_date}`
      )
      const lastMonthsMechanics = await Axios.get(
        `/api/history_stats/mechanics?current_date=${current_date}`
      )
      const lastMonthsWorkshop = await Axios.get(
        `/api/history_stats/workshop?current_date=${current_date}`
      )

      const result = {
        invoiceMonthly: invoiceMonthly.data.data,
        invoiceDaily: invoiceDaily.data.data,
        lastMonthsMechanics: lastMonthsMechanics.data.data,
        lastMonthsWorkshop: lastMonthsWorkshop.data.data,
      }
      resolve(result)
    } catch (error) {
      reject(error)
      NotificationHandler(
        'Error',
        'error',
        'Error obtener historial calificaciones'
      )
    }
  })

  export const getMechanicBilling = (mechanic_id) =>
  new Promise(async (resolve, reject) => {
    try {
      const current_date = moment().format('YYYY-MM-DD')

      const invoiceMonthly = await Axios.get(
        `/api/history_stats/mechanics/${mechanic_id}/MonthlyBilling?current_date=${current_date}`
      )

      const avInvoiceMonthly = await Axios.get(
        `/api/history_stats/mechanics/${mechanic_id}/averageMonthlyBilling?current_date=${current_date}`
      )

      const result = {
        invoiceMonthly: invoiceMonthly.data.data,
        avInvoiceMonthly: avInvoiceMonthly.data.data,
      }

      resolve(result)
    } catch (error) {
      reject(error)
      NotificationHandler(
        'Error',
        'error',
        'Error obtener lo facturado'
      )
    }
  })

  export const getMechanicEfficiency = (mechanic_id) =>
  new Promise(async (resolve, reject) => {
    try {
      const current_date = moment().format('YYYY-MM-DD')

      const efficiencyMonthly = await Axios.get(
        `/api/history_stats/mechanics/${mechanic_id}/efficiencyMonthlyTime?current_date=${current_date}`
      )

      resolve(efficiencyMonthly.data.data)
    } catch (error) {
      reject(error)
      NotificationHandler(
        'Error',
        'error',
        'Error obtener la efficiencia'
      )
    }
  })

  export const getMechanicScore = (mechanic_id) =>
  new Promise(async (resolve, reject) => {
    try {
      const current_date = moment().format('YYYY-MM-DD')

      const scoreMonthly = await Axios.get(
        `/api/history_stats/mechanics/${mechanic_id}/monthlyScores?current_date=${current_date}`
      )
      const anotherScoreMonthly = await Axios.get(
        `/api/dashboardScore/mechanics/${mechanic_id}/currentMonthlyScore?current_date=${current_date}`
      )
      
      const result = {
        scoreMon1: scoreMonthly.data.data,
        scoreMon2: anotherScoreMonthly.data.data,
      }

      resolve(result)
    } catch (error) {
      reject(error)
      NotificationHandler(
        'Error',
        'error',
        'Error obtener lo facturado'
      )
    }
  })

export const getTopsTen = () =>
  new Promise(async (resolve, reject) => {
    try {
      const topTenProcess = await Axios.get('/api/tops/process')
      const topTenProducts = await Axios.get('/api/tops/products')
      const topTenVehicles = await Axios.get('/api/tops/vehicles')

      const result = {
        topTenProcess: topTenProcess.data.data,
        topTenProducts: topTenProducts.data.data,
        topTenVehicles: topTenVehicles.data.data,
      }
      resolve(result)
    } catch (error) {
      reject(error)
      NotificationHandler('Error', 'error', 'Error obtener top ten')
    }
  })

export const getWorkOrderScore =
  (query = '', page = 1, size = 10, init, end) =>
    new Promise((resolve, reject) => {
      let i = init
      let e = end
      if (init === undefined || init === null || init === '') {
        i = moment().subtract(30, 'days').format('YYYY-MM-DD')
      }

      if (end === undefined || end === null || end === '') {
        e = moment().format('YYYY-MM-DD')
      }

      Axios
        .get(
          `/api/work_orders_history?query=${query}&page=${page}&pageSize=${size}&init=${i}&end=${e}`
        )
        .then((response) => {
          if (!response.data.error) { 
            resolve(response.data.data)
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al obtener el historial',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al obtener el historial',
            error
          )
        })
})

export const getTopCitiesAverageScores = () =>
  new Promise(async (resolve, reject) => {
    try {
      const topTenCities = await Axios.get('/api/topCities')
      const averageMechanicScores = await Axios.get(
        '/api/dashboardScore/mechanics'
      )
      const averageWorkshopScores = await Axios.get(
        '/api/dashboardScore/workshop'
      )

      const result = {
        topTenCities: topTenCities.data.data,
        averageMechanicScores: averageMechanicScores.data.data,
        averageWorkshopScores: averageWorkshopScores.data.data,
      }
      resolve(result)
    } catch (error) {
      reject(error)
      NotificationHandler('Error', 'error', 'Error obtener top ten')
    }
  })
