import styled from 'styled-components'
import { BREAK_POINTS_DESKTOP_FIRST } from '../../../../../../global/css/breakPoints'

export const MechanicAsignationWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  .no-mechanic {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    p {
      color: #717171;
      font-size: 1.3rem;
      i {
        margin-right: 6px;
      }
    }
    .assign-btn {
      max-width: 218px;
      background-color: unset;
      border-radius: 8px;
      border: 2px solid #1e1e40;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      color: #1e1e40;
      display: flex;
      font-size: 1.5rem;
      gap: 14px;
      align-items: center;
      justify-content: center;
      padding: 14px 32px;
      font-weight: 600;
      &:hover {
        background-color: #1e1e40;
        color: #ffffff;
      }
      i {
        font-size: 1.6rem;
      }
    }
  }
  .selected-mechanic {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    border-radius: 10px;
    border: 2px solid #1e1e40;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.3rem;
      border: unset;
      background-color: #1e1e40;
      color:#ffffff;
      padding: 8px 0;
      gap:8px;
      border-radius: 0 0 8px 8px;

      &:hover{
        opacity: 0.9;
      }
    }
    .header {
      padding: 8px 12px;
      display: flex;
      align-items: center;
      color: #1e1e40;
      font-size: 1.5rem;
      gap: 8px;
      margin: unset;
      font-weight: 600;

      i {
        font-size: 1.8rem;
      }
    }

    .avatar-wrapper {
      padding: 8px 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .avatar {
        display: flex;
        align-items: center;
        gap: 8px;
        p {
          font-weight: 600;
          margin: unset;
        }
      }
    }
  }
  @media (${BREAK_POINTS_DESKTOP_FIRST.MOBILE}) {
    width: 100%;
    .no-mechanic {
      width: 100%;
      .assign-btn {
        width: 100%;
        max-width: unset;
      }
    }
  }
`
