export const newVehicleInitialState = {
  vehicleLicensePlate: '',
  selectedBrand: null,
  selectedModel: null,
  selectedYearMomentObj: null,
  measureUnitId: null,
  colors: [],
}

export const contactInitialState = {
  contactName: '',
  contactPhone: '',
  contactCountryCode: null,
}

export const vehicleStateInitial = {
  vehicleGasLevel: 0,
  vehicleOdometer: null,
}

export const addReceptionInitialState = {
  step: 1,
  subStep: null,

  // to store the list of products and process
  processes: [],
  products: [],

  // { value: '', label: ''} used on select component
  vehicleValue: undefined,
  selectedVehicle: null,
  vehicleSearchPlate: '',
  clientValue: null,
  client: null,

  selectedLastOwner: null,
  lastOwner: null,

  // Needed to identify if replace owner or not
  replaceOwner: false,

  // Needed to identify if the client is validated
  isClientValidated: false,

  // Needed for substeps
  newVehicle: { ...newVehicleInitialState },

  contact: { ...contactInitialState },
  photos: [],
  videos: [],

  vehicleState: { ...vehicleStateInitial },

  lastWorkOrders: null,

  selectedProducts: [],
  selectedServices: [],

  packageValue: undefined,
  packageDetails: undefined,

  observations: null,

  mechanic: undefined,

  receptionId: null,

  receptionToEdit: {},

  isVehicleSaved: false,
  isClientSaved: false,
  isEdit: false,
}

export const addReceptionActions = {
  SET_NEXT_STEP: 'SET_NEXT_STEP',
  SET_PREVIOUS_STEP: 'SET_PREVIOUS_STEP',
  SET_STEP: 'SET_STEP',

  SET_SUB_STEP: 'SET_SUB_STEP',
  SET_VEHICLE_SEARCH_PLATE: 'SET_VEHICLE_SEARCH_PLATE',

  // to store the list of products and process
  SET_PROCESSES: 'SET_PROCESSES',
  SET_PRODUCTS: 'SET_PRODUCTS',

  SELECT_VEHICLE: 'SELECT_VEHICLE',
  SET_VEHICLE_VALUE: 'SET_VEHICLE_VALUE',

  SELECT_CLIENT: 'SELECT_CLIENT',
  SET_CLIENT_VALUE: 'SET_CLIENT_VALUE',

  SET_NEW_VEHICLE: 'SET_NEW_VEHICLE',
  RESET_NEW_VEHICLE: 'RESET_NEW_VEHICLE',

  SET_CONTACT: 'SET_CONTACT',
  RESET_CONTACT: 'RESET_CONTACT',

  SET_PHOTOS: 'SET_PHOTOS',
  SET_VIDEOS: 'SET_VIDEOS',

  SET_REPLACE_OWNER: 'SET_REPLACE_OWNER',

  SET_CLIENT_VALIDATED: 'SET_CLIENT_VALIDATED',
  SET_VEHICLE_STATE: 'SET_VEHICLE_STATE',

  SET_LAST_WORK_ORDERS: 'SET_LAST_WORK_ORDERS',

  SET_ADITIONAL_PRODUCTS: 'SET_ADITIONAL_PRODUCTS',
  SET_ADITIONAL_SERVICES: 'SET_ADITIONAL_SERVICES',

  SET_PACKAGE: 'SET_PACKAGE',
  SET_PACKAGE_DETAILS: 'SET_PACKAGE_DETAILS',

  SET_OBSERVATIONS: 'SET_OBSERVATIONS',

  SET_MECHANIC: 'SET_MECHANIC',

  SET_RECEPTION_ID: 'SET_RECEPTION_ID',

  SET_LAST_OWNER: 'SET_LAST_OWNER',

  SET_CLIENT_SAVED: 'SET_CLIENT_SAVED',
  SET_VEHICLE_SAVED: 'SET_VEHICLE_SAVED',

  SET_IS_EDIT: 'SET_IS_EDIT',
  RESET_RECEPTION: 'RESET_RECEPTION',
}

export const receptionTotalSteps = 10

export const AddReceptionReducer = (state, action) => {
  switch (action.type) {
    case addReceptionActions.SET_STEP:
      return { ...state, step: action.payload }
    case addReceptionActions.SET_NEXT_STEP:
      if (state.step < receptionTotalSteps) {
        return { ...state, step: state.step + 1 }
      }
      return state
    case addReceptionActions.SET_PREVIOUS_STEP:
      if (state.step > 1) {
        return { ...state, step: state.step - 1 }
      }
      return state
    case addReceptionActions.SET_SUB_STEP:
      return {
        ...state,
        subStep: action.payload,
      }
    case addReceptionActions.SET_VEHICLE_SEARCH_PLATE:
      return {
        ...state,
        vehicleSearchPlate: action.payload,
      }
    case addReceptionActions.SET_PROCESSES:
      return { ...state, processes: action.payload }
    case addReceptionActions.SET_PRODUCTS:
      return { ...state, products: action.payload }
    case addReceptionActions.SELECT_VEHICLE:
      return {
        ...state,
        selectedVehicle: action.payload.selectedVehicle,
        vehicleValue: action.payload.vehicleValue,
        lastWorkOrders: null,
      }
    case addReceptionActions.SELECT_CLIENT:
      return {
        ...state,
        client: action.payload?.client,
        clientValue: action.payload?.clientValue,
      }
    case addReceptionActions.SET_LAST_OWNER:
      return {
        ...state,
        lastOwner: action.payload?.lastOwner,
        selectedLastOwner: action.payload?.selectedLastOwner,
      }
    case addReceptionActions.SET_NEW_VEHICLE:
      return {
        ...state,
        newVehicle: action.payload,
      }
    case addReceptionActions.RESET_NEW_VEHICLE:
      return {
        ...state,
        newVehicle: { ...newVehicleInitialState },
      }
    case addReceptionActions.SET_VEHICLE_VALUE:
      return { ...state, vehicleValue: action.payload }

    case addReceptionActions.SET_CLIENT_VALUE:
      return { ...state, clientValue: action.payload }
    case addReceptionActions.SET_CONTACT:
      return { ...state, contact: action.payload }
    case addReceptionActions.RESET_CONTACT:
      return { ...state, contact: { ...contactInitialState } }
    case addReceptionActions.SET_PHOTOS:
      return {
        ...state,
        photos: action.payload,
      }
      case addReceptionActions.SET_VIDEOS:
        return {
          ...state,
          videos: action.payload,
        }
    case addReceptionActions.SET_REPLACE_OWNER:
      return {
        ...state,
        replaceOwner: action.payload,
      }
    case addReceptionActions.SET_CLIENT_VALIDATED:
      return {
        ...state,
        isClientValidated: action.payload,
      }
    case addReceptionActions.SET_VEHICLE_STATE:
      return {
        ...state,
        vehicleState: { ...state.vehicleState, ...action.payload },
      }
    case addReceptionActions.SET_LAST_WORK_ORDERS:
      return { ...state, lastWorkOrders: action.payload }
    case addReceptionActions.SET_ADITIONAL_PRODUCTS:
      return { ...state, selectedProducts: action.payload }
    case addReceptionActions.SET_ADITIONAL_SERVICES:
      return { ...state, selectedServices: action.payload }

    case addReceptionActions.SET_PACKAGE:
      return { ...state, packageValue: action.payload }
    case addReceptionActions.SET_PACKAGE_DETAILS:
      return { ...state, packageDetails: action.payload }
    case addReceptionActions.SET_OBSERVATIONS:
      return { ...state, observations: action.payload }
    case addReceptionActions.SET_MECHANIC:
      return { ...state, mechanic: action.payload }
    case addReceptionActions.SET_RECEPTION_ID:
      return { ...state, receptionId: action.payload }
    case addReceptionActions.SET_VEHICLE_SAVED:
      return { ...state, isVehicleSaved: action.payload }
    case addReceptionActions.SET_CLIENT_SAVED:
      return { ...state, isClientSaved: action.payload }
    case addReceptionActions.SET_IS_EDIT:
      return {
        ...state,
        receptionToEdit: action.payload,
        receptionId: action.payload.receptionId,
        isEdit: action.payload.isEdit,
        selectedVehicle: action.payload.selectedVehicle,
        isVehicleSaved: action.payload.isVehicleSaved,
        isClientSaved: action.payload.isClientSaved,
        client: action.payload.client,
        contact: action.payload.contact || contactInitialState,
        photos: action.payload.photos || [],
        videos: action.payload.videos || [],
        vehicleState: {
          vehicleGasLevel: action.payload.vehicleState.vehicleGasLevel || 0,
          vehicleOdometer: action.payload.vehicleState.vehicleOdometer || null,
        },
        packageValue: {
          label: action.payload.package.packageName,
          value: action.payload.package.packageId,
        },
        packageDetails: {
          ...action.payload.package,
        },
        selectedServices: action.payload.aditionalServices,
        selectedProducts: action.payload.aditionalProducts,
        observations: action.payload.observations,
      }
    case addReceptionActions.RESET_RECEPTION:
      return { ...addReceptionInitialState }

    default:
      return state
  }
}
