import React, { Component } from 'react'
import { Modal, FormGroup, ControlLabel, Button, Form } from 'react-bootstrap'
import _ from 'lodash'

import DatePicker from 'react-datepicker'
import moment from 'moment'
import PropTypes from 'prop-types'
import { SaveOutlined } from '@ant-design/icons'
import { getValidationState } from '../Common'
import ClaimForm from './ClaimForm'
import { getWorkOrderTotal, getTotalTime } from './ClaimDetails/functions'
import { NotificationHandler } from '../../../Helpers'

class ClaimModal extends Component {
  state = {
    loading: false,
    reception_id: null,
    package_id: null,
    employee_id: null,
    mechanic_id: null,
    work_order_id: '',
    work_order_time: 0,
    work_order_date: moment(),
    alreadyHadProcessesOnRender: false,
    work_order_type: 1,
    work_order_claim: null,
    person_id: null,
    workOrderDetails: {},
    claimDetails: {},
    processesThatApply: {},
    productsThatApply: {},
  }

  componentDidUpdate(prevProps) {
    const { selectedWorkOrder } = this.props
    const { selectedWorkOrder: prevSelectedWorkOrder } = prevProps

    if (
      selectedWorkOrder !== undefined &&
      selectedWorkOrder !== null &&
      prevSelectedWorkOrder !== undefined &&
      prevSelectedWorkOrder !== null &&
      selectedWorkOrder !== prevSelectedWorkOrder
    ) {
      this.setWorkOrder(selectedWorkOrder)
    }
  }

  setWorkOrder = (workOrder) => {
    const {
      package_id,
      budgets,
      work_order_time,
      work_order_real_time,
      mechanic_id,
      employee_id,
      budget_id,
      details,
      person_id,
    } = workOrder

    let processesOBJ = {}

    if (package_id) {
      const { packages } = this.props
      processesOBJ = this.getPackageProcesses(packages, package_id)
    }

    const productsThatApply = this.getFilteredProducts()

    let newState = {}
    newState = {
      reception_id: budgets.reception_id,
      work_order_date: moment(),
      work_order_real_time,
      work_order_time,
      mechanic_id,
      package_id,
      packageDetails: processesOBJ.packageDetails,
      processesThatApply: processesOBJ.processes,
      productsThatApply,
      employee_id,
      budget_id,
      person_id,
      workOrderDetails: details,
    }

    this.setState(newState)
  }

  clearComponent = () => {
    this.setState({
      reception_id: '',
      work_order_date: moment(),
      work_order_real_time: '',
      work_order_time: '',
      mechanic_id: '',
      package_id: '',
      package_price: '',
      packageDetails: '',
      processesThatApply: {},
      employee_id: '',
      budget_id: '',
      workOrderDetails: [],
      person_id: null,
      claimDetails: {},
    })
  }

  handleChangeSelect = (value, field) => {
    if (field === 'reception' && value) {
      this.setReception(value, this.props)
    }
    if (value) {
      if (`${field}_id` === 'employee_id') {
        this.setState({ employee_id: value[`${field}_id`] })
      } else if (`${field}_id` === 'mechanic_id') {
        this.setState({ mechanic_id: value.employee_id })
      } else {
        this.setState({ [`${field}_id`]: value[`${field}_id`] })
      }
    } else {
      this.setState({ [`${field}_id`]: null })
    }
  }

  handleChange = (e, type) => {
    const { name } = e.target
    let { value } = e.target
    if (type === 'double') {
      value = value.replace(/[^0-9.]/g, '')
    } else if (type === 'integer') {
      value = value.replace(/[^0-9]/g, '')
    }
    this.setState({
      [name]: value,
    })
  }

  handleChangeDate = (value) => {
    this.setState({
      product_expire_date: value,
    })
  }

  parseReceptions = (receptions) =>
    _.map(receptions, (reception) => ({
      ...reception,
      plate: reception.vehicle.vehicle_license_plate,
    }))

  getPackageProcesses = (packages, package_id) => {
    const { processes } = this.props

    const {
      selectedWorkOrder: { details },
    } = this.props

    const extraProcesses = _.map(
      _.filter(details, (detail) => detail.process),
      (detail) => detail.process
    )

    const valueIDS = [
      ...packages[package_id].packageProcess,
      ...extraProcesses,
    ].map((packageP) => packageP.process_id)

    const processesArray = _.filter(
      processes,
      // eslint-disable-next-line consistent-return
      (process) => {
        if (_.includes(valueIDS, process.process_id)) {
          return process
        }
      }
    )

    return {
      packageDetails: packages[package_id].packageProcess.map(
        (packageP) => packageP.process
      ),
      processes: _.keyBy(processesArray, 'process_id'),
    }
  }

  getFilteredProducts = () => {
    const { products } = this.props

    const {
      selectedWorkOrder: { details },
    } = this.props

    const extraProducts = _.map(
      _.filter(details, (detail) => detail.product),
      (detail) => detail.product
    )

    const valueIDS = extraProducts.map((product) => product.product_id)

    // eslint-disable-next-line consistent-return
    const productsArray = _.filter(products, (product) => {
      if (_.includes(valueIDS, product.product_id)) {
        return product
      }
    })

    return _.keyBy(productsArray, 'product_id')
  }

  getWorkOrderDetails = (details, processes, editMode) => {
    const workOrderDetails = {}
    // eslint-disable-next-line consistent-return
    _.forEach(details, (detail, index) => {
      try {
        if (!editMode) {
          workOrderDetails[index] = {
            work_order_detail_id: detail.budget_detail_id,
            work_order_detail_item: detail.budget_detail_item,
            work_order_detail_item_price: detail.budget_detail_item_price,
            work_order_detail_workforce_id: detail.budget_detail_workforce_id,
            work_order_detail_workforce_price:
              detail.budget_detail_workforce_price,
            budget_detail_type: detail.budget_detail_type,
            work_order_detail_estimated_time:
              processes[detail.budget_detail_workforce_id]
                .process_estimated_time,
          }
        } else {
          workOrderDetails[index] = {
            work_order_detail_id: detail.work_order_detail_id,
            work_order_detail_item: detail.work_order_detail_item,
            work_order_detail_item_price: detail.work_order_detail_item_price,
            work_order_detail_workforce_id:
              detail.work_order_detail_workforce_id,
            work_order_detail_workforce_price:
              detail.work_order_detail_workforce_price,
            budget_detail_type: 0,
            work_order_detail_estimated_time:
              processes[detail.work_order_detail_workforce_id]
                .process_estimated_time,
            work_order_detail_quantity: detail.work_order_detail_quantity,
            isDeleted: false,
          }
        }
      } catch (error) {
        return error
      }
    })
    return workOrderDetails
  }

  handleChangeDetail = (e, key) => {
    const { claimDetails } = this.state
    this.setState({
      claimDetails: {
        ...claimDetails,
        [key]: {
          ...claimDetails[key],
          [e.target.name]: e.target.value,
        },
      },
    })
  }

  handleChangeSelectDetail = (value, field, key, extra_detail = false) => {
    const state = _.cloneDeep(this.state)

    if (!value) {
      state.claimDetails[key][field] = null
    } else if (field === 'work_order_detail_item') {
      state.claimDetails[key][field] = value.product_id
      state.claimDetails[key].work_order_detail_item_price = extra_detail
        ? value.product_price
        : 0
      state.claimDetails[key].recommended_work_order_detail_item_price =
        value.product_price
    } else if (field === 'work_order_detail_workforce_id') {
      state.claimDetails[key][field] = value.process_id
      state.claimDetails[key].work_order_detail_workforce_price = extra_detail
        ? value.process_price
        : 0
      state.claimDetails[key].recommended_work_order_detail_workforce_price =
        value.process_price
      state.claimDetails[key].work_order_detail_estimated_time =
        value.process_estimated_time
    } else if (field === 'budget_detail_type') {
      state.claimDetails[key][field] = value.budget_detail_type_id
    }

    this.setState(state)
  }

  addWorkOrderDetail = (typeCard = 'product', extra_detail = false) => {
    const { claimDetails } = this.state
    const lastKey = _.findLastKey(claimDetails)
    let newKey
    if (Number.isNaN(lastKey) || lastKey === undefined) {
      newKey = extra_detail ? '0extra' : 0
    } else {
      newKey = extra_detail
        ? `${parseInt(lastKey, 10) + 1}extra`
        : parseInt(lastKey, 10) + 1
    }
    const state = {
      ...this.state,
    }

    state.claimDetails[newKey] = {
      detail_key: newKey,
      work_order_detail_item: null,
      work_order_detail_item_price: 0,
      recommended_work_order_detail_item_price: 0,
      work_order_detail_workforce_id: null,
      work_order_detail_item_quantity: 1,
      work_order_detail_discount: 0,
      work_order_detail_workforce_price: 0,
      recommended_work_order_detail_workforce_price: 0,
      work_order_detail_estimated_time: '',
      type_card: typeCard,
      extra_detail,
    }

    this.setState(state)
  }

  deleteWorkOrderDetail = (key) => {
    const state = _.cloneDeep(this.state)
    if (state.claimDetails[key].work_order_detail_id) {
      state.claimDetails[key].isDeleted = true
    } else {
      delete state.claimDetails[key]
    }
    this.setState(state)
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.setState({ loading: false })
    const {
      selectedWorkOrder,
      toggleModal,
      addWorkOrder,
      currentUser: { employee_id },
    } = this.props
    toggleModal()
    const { work_order_date, mechanic_id, claimDetails, budget_id, person_id } =
      this.state

    if (!mechanic_id) {
      NotificationHandler('Error', 'error', 'Falta agregar mecánico')
      return
    }

    const workOrder = {
      work_order_progress: 0,
      employee_id,
      work_order_date,
      budget_id,
      work_order_time: parseFloat(getTotalTime(claimDetails).total),
      work_order_claim: selectedWorkOrder.work_order_id,
      mechanic_id,
      work_order_type: 0,
      work_order_total: parseFloat(
        getWorkOrderTotal(claimDetails, [], true) || 0
      ),
      work_order_status_id: 1,
      work_order_invoiced: 0,
      workOrderDetails: claimDetails,
      person_id,
    }
    addWorkOrder(workOrder)
      .then(() => {
        this.setState({
          loading: false,
        })
      })
      .catch(() => {
        this.setState({
          loading: false,
        })
      })
  }

  render() {
    const {
      modalOpen,
      toggleModal,
      editMode,
      packages,
      employees,
      products,
      receptions,
      processes,
    } = this.props
    const { loading, work_order_date, processesThatApply, productsThatApply } =
      this.state

    return (
      <Modal show={modalOpen} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {'Agregar '}
            Orden de Trabajo por reclamo
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.handleSubmit}>
            <div>
              <FormGroup validationState={getValidationState('brand', true)}>
                <ControlLabel>Fecha: </ControlLabel>
                <DatePicker
                  selected={work_order_date}
                  dateFormat="LL"
                  className="form-control"
                  readOnly
                />
              </FormGroup>
            </div>
            <ClaimForm
              state={this.state}
              handleChangeSelect={this.handleChangeSelect}
              packages={packages}
              employees={employees}
              productsThatApply={productsThatApply}
              products={products}
              addWorkOrderDetail={this.addWorkOrderDetail}
              deleteWorkOrderDetail={this.deleteWorkOrderDetail}
              handleChangeDetail={this.handleChangeDetail}
              handleChangeSelectDetail={this.handleChangeSelectDetail}
              processesThatApply={processesThatApply}
              processes={processes}
              receptions={receptions}
              editMode={editMode}
            />
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button type="submit" hidden id="submitWorkOrderForm" />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={toggleModal}>Cerrar</Button>
          <Button
            disabled={loading}
            bsStyle="primary"
            onClick={() => {
              document.getElementById('submitWorkOrderForm').click()
            }}
            className="btn-and-icon"
          >
            <SaveOutlined />
            {' Agregar'}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

ClaimModal.propTypes = {
  editMode: PropTypes.bool.isRequired,
  selectedWorkOrder: PropTypes.objectOf(PropTypes.string).isRequired,
  packages: PropTypes.objectOf(PropTypes.string).isRequired,
  processes: PropTypes.objectOf(PropTypes.string).isRequired,
  toggleModal: PropTypes.func.isRequired,
  addWorkOrder: PropTypes.func.isRequired,
  currentUser: PropTypes.objectOf(PropTypes.string).isRequired,
  modalOpen: PropTypes.bool.isRequired,
  employees: PropTypes.arrayOf(PropTypes.object).isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  receptions: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default ClaimModal
