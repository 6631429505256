import React from 'react'
import { Button } from 'react-bootstrap'
import ReactToolTip from 'react-tooltip'
import { textFilter } from 'react-bootstrap-table2-filter'
import { EditFilled, EyeFilled, DeleteFilled } from '@ant-design/icons';
import {
  formatterHeaderStyle,
  columnStyle,
  columnHeaderStyle,
} from '../../../Utilities/TableStyles'

const styleIcons = {
  fontSize: '17px',
}

const editButton = (updateEmployee, can_edit) => (cell, row) =>
(
  <Button
    onClick={() => {
      updateEmployee(row)
    }}
    bsStyle="link"
    bsSize="small"
    data-tip={can_edit ? 'Modificar' : 'Ver'}
  >
    {can_edit ? <EditFilled style={styleIcons} /> : <EyeFilled style={styleIcons} />}
    <ReactToolTip />
  </Button>
)

const deleteButton = (deleteEmployee) => (cell, row) =>
(
  <Button
    onClick={() => deleteEmployee(row)}
    bsStyle="link"
    bsSize="small"
    style={{ color: 'red' }}
    data-tip="Eliminar"
  >
    <DeleteFilled style={styleIcons} />
    <ReactToolTip />
  </Button>
)

const getColumns = (
  updateEmployee,
  deleteEmployee,
  { can_edit, can_delete }
) => {
  const columns = [
    {
      dataField: 'employee_name',
      text: 'Nombre',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: textFilter({
        delay: 100,
        style: {
          margin: '10px 0 0 0',
          padding: '20px 2%',
        },
        placeholder: 'Buscar',
      }),
    },
    {
      dataField: 'employee_lastname',
      text: 'Apellidos',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: textFilter({
        delay: 100,
        style: {
          margin: '10px 0 0 0',
          padding: '20px 2%',
        },
        placeholder: 'Buscar',
      }),
    },
    {
      dataField: 'employee_phone',
      text: 'Teléfono',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: textFilter({
        delay: 100,
        style: {
          margin: '10px 0 0 0',
          padding: '20px 2%',
        },
        placeholder: 'Buscar',
      }),
    },
    {
      dataField: 'employee_dui',
      text: 'DUI',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: false,
      filter: textFilter({
        delay: 100,
        style: {
          margin: '10px 0 0 0',
          padding: '20px 2%',
        },
        placeholder: 'Buscar ',
      }),
    },
    {
      dataField: '1',
      className: 'test',
      text: '',
      headerStyle: formatterHeaderStyle,
      style: columnHeaderStyle({ textAlign: 'center' }),
      formatter: editButton(updateEmployee, can_edit),
    },
  ]
  if (can_delete) {
    columns.push({
      dataField: '2',
      className: ' ',
      text: '',
      headerStyle: formatterHeaderStyle,
      style: columnHeaderStyle({ textAlign: 'center' }),
      formatter: deleteButton(deleteEmployee),
    })
  }
  return columns
}

export default getColumns
