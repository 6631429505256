import React from 'react'
import { Button } from 'react-bootstrap'
import ReactToolTip from 'react-tooltip'
import { formatPhoneNumber } from 'react-phone-number-input'
import { textFilter } from 'react-bootstrap-table2-filter'
import { EditFilled, EyeFilled, DeleteFilled } from '@ant-design/icons';
import {
  formatterHeaderStyle,
  columnStyle,
  columnHeaderStyle,
} from '../../Utilities/TableStyles'
import countryCodeToLetters from '../../Utilities/CountryCodeToLetters'

const styleIcons = {
  fontSize: '17px',
}

const editButton = (updatePerson, can_edit) => (cell, row) =>
(
  <Button
    onClick={() => {
      updatePerson(row)
    }}
    bsStyle="link"
    bsSize="small"
    data-tip={can_edit ? 'Modificar' : 'Ver'}
  >
    {can_edit ? <EditFilled style={styleIcons} /> : <EyeFilled style={styleIcons} />}
    <ReactToolTip />
  </Button>
)

const deleteButton = (deletePerson) => (cell, row) =>
(
  <Button
    onClick={() => deletePerson(row)}
    bsStyle="link"
    bsSize="small"
    style={{ color: 'red' }}
    data-tip="Eliminar"
  >
    <DeleteFilled style={styleIcons} />
    <ReactToolTip />
  </Button>
)

const texFilterOption = {
  delay: 100,
  style: {
    margin: '10px 0 0 0',
    padding: '20px 2%',
  },
  placeholder: 'Buscar',
}

const getColumns = (updatePerson, deletePerson, { can_edit, can_delete }) => {
  const columns = [
    {
      dataField: 'country_code',
      text: 'País',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      formatter: (cell, row) => `${countryCodeToLetters(row.country_code)}`,
    },
    {
      dataField: 'fullName',
      text: 'Nombre',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: textFilter(texFilterOption),
    },
    {
      dataField: 'person_phone',
      text: 'Teléfono',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: textFilter(texFilterOption),
      formatter: (cell, row) =>
        `${formatPhoneNumber(`+${row.country_code}${row.person_phone}`)}`,
    },
    {
      dataField: 'id2',
      className: 'test',
      text: '',
      headerStyle: formatterHeaderStyle,
      style: columnHeaderStyle({ textAlign: 'center' }),
      formatter: editButton(updatePerson, can_edit),
    },
  ]
  if (can_delete) {
    columns.push({
      dataField: 'id3',
      text: '',
      headerStyle: formatterHeaderStyle,
      style: columnHeaderStyle({ textAlign: 'center' }),
      formatter: deleteButton(deletePerson),
    })
  }
  return columns
}

export default getColumns
