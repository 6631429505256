import styled from 'styled-components'

export const VideoPreviewWrapper = styled.div`
  display: flex;

  button,
  button:hover,
  button:active,
  button:focus {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    outline: none;
  }

  .image-gallery-icon {
    display: block;
    position: static;
  }

  .image-gallery-svg {
    height: 50px !important;
    &:hover {
      color: #d60e46 !important;
      background-color: unset !important;
    }
  }
`

export const ThumbnailWrapper = styled.div`
  display: inline-block;
  cursor: pointer;
  border: ${({ active }) => (active ? '3px solid #000' : 'none')};
  transform: ${({ index, currentIndex }) => {
    const distance = index - currentIndex
    return `translateX(${distance * 8}px)`
  }};
  transition: transform 0.3s ease-in-out;
`

export const VideoGalleryContainer = styled.div`
  margin-bottom: 10px;
  overflow: hidden;
`

export const ThumbnailsWrapper = styled.div`
  max-width: 400px;
  display: flex;
  transition: transform 0.3s ease-in-out;
  transform: translateX(${({ currentIndex }) => currentIndex * -50}px);

  .video-wrapper {
    video {
      padding: 2px;
      object-fit: cover;
    }
  }
`
