import axios from 'axios'
import _ from 'lodash'

import {
  GET_EMPLOYEES,
  ADD_EMPLOYEE,
  UPDATE_EMPLOYEE,
  DELETE_EMPLOYEE,
} from '../actionTypes/EmployeeTypes'
import { GET_MECHANICS } from '../actionTypes/MechanicTypes'
import { NotificationHandler } from '../Helpers'
import { UPDATE_PROFILE } from '../actionTypes/AppTypes'

const getEmployeesAction = (payload) => ({
  type: GET_EMPLOYEES,
  payload,
})

const getMechanicsAction = (payload) => ({
  type: GET_MECHANICS,
  payload,
})

const addEmployeeAction = (payload) => ({
  type: ADD_EMPLOYEE,
  payload,
})

const updateEmployeeAction = (payload) => ({
  type: UPDATE_EMPLOYEE,
  payload,
})

const deleteEmployeeAction = (payload) => ({
  type: DELETE_EMPLOYEE,
  payload,
})

export const getEmployees = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const employeesAmount = _.size(getState().EmployeeReducer.employees)
    if (employeesAmount === 0) {
      axios
        .get('/api/employees')
        .then((response) => {
          if (!response.data.error) {
            const employees = _.keyBy(response.data.data, 'employee_id')
            dispatch(getEmployeesAction(employees))
            resolve(response.data)
          } else {
            reject()
            NotificationHandler(
              'Error:',
              'error',
              'Error al obtener empleados',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error: No se cargaron los empleados',
            error
          )
        })
    } else {
      resolve({})
    }
  })

export const getMechanics = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const employeesAmount = _.size(getState().MechanicReducer.mechanics)
    if (employeesAmount === 0) {
      axios
        .get('/api/employees/mechanics')
        .then((response) => {
          if (response.data.error) {
            reject()
            NotificationHandler(
              'Error:',
              'error',
              'Error al obtener empleados',
              response.data.data
            )
          } else {
            dispatch(getMechanicsAction(response.data.data))
            resolve(response.data)
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error: No se cargaron los empleados',
            error
          )
        })
    } else {
      resolve({})
    }
  })

export const addEmployee = (employee, file) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { branch_id } = getState().AppReducer.currentUser
    axios
      .post('/api/employees', { ...employee, branch_id })
      .then((response) => {
        if (!response.data.error) {
          if(file){
            axios
              .post(`/api/employees/${response.data.data.employee_id}/mechanicPhoto`, file)
              .then((responses)=>{
                if (!responses.data.error) {
                  const employeeToRedux = {
                    ...responses.data.data,
                    rol: getState().RolReducer.roles[employee.rol_id],
                  }
                  dispatch(addEmployeeAction(employeeToRedux))
                  resolve(responses)
                  NotificationHandler('Hecho', 'info', 'Empleado registrado')
          
                }else{
                  reject()
                  NotificationHandler('Error', 'error', responses.data.data)
                }
              }).catch((err)=>{
                reject()
                NotificationHandler('Error', 'error', "Error al cargar la imagen" , err)
              })} else{
                  const employeeToRedux = {
                      ...response.data.data,
                      rol: getState().RolReducer.roles[employee.rol_id],
                    }
                    dispatch(addEmployeeAction(employeeToRedux))
                    resolve(response)
                    NotificationHandler('Hecho', 'info', 'Empleado registrado')
              }
            
        } else {
          reject()
          NotificationHandler('Error', 'error', response.data.data)
        }
      })
      .catch((error) => {
        reject()
        NotificationHandler(
          'Error',
          'error',
          'Error no se registró el empleado',
          error
        )
      })
  })

export const updateEmployee = (employee) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    if (employee.employee_id) {
      const { branch_id } = getState().AppReducer.currentUser
      axios
        .put(`/api/employees/${employee.employee_id}`, {
          ...employee,
          branch_id,
        })
        .then((response) => {
          if (!response.data.error) {
            if(employee.file){
              axios
              .post(`/api/employees/${employee.employee_id}/mechanicPhoto`, employee.file)
              .then((responses)=>{
                if (!responses.data.error) {
                  const employeeToRedux = {
                      ...employee,
                      rol: getState().RolReducer.roles[employee.rol_id],
                    }
                    dispatch(updateEmployeeAction(employeeToRedux))
                    resolve(responses)
                    NotificationHandler(
                      'Hecho:',
                      'info',
                      'Datos actualizados correctamente'
                    )
          
                }else{
                  reject()
                  NotificationHandler('Error', 'error', responses.data.data)
                }
              }).catch((err)=>{
                reject()
                NotificationHandler('Error', 'error', "Error al cargar la imagen", err)
              })} else{
                  const employeeToRedux = {
                      ...employee,
                      rol: getState().RolReducer.roles[employee.rol_id],
                    }
                    dispatch(updateEmployeeAction(employeeToRedux))
                    resolve(response)
                    NotificationHandler(
                      'Hecho:',
                      'info',
                      'Datos actualizados correctamente'
                    )
              }
          } else {
            reject()
            NotificationHandler('Error', 'error', response.data.data)
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler('Error', 'error', 'Error al actualizar', error)
        })
    } else {
      reject()
      NotificationHandler(
        'Error',
        'error',
        `No se proporcionó id a: ${employee.employee_name}`
      )
    }
  })

export const updateProfile = (employee, employee_urlimg2) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (employee.employee_id) {
      axios
        .put(`/api/employees/profile/${employee.employee_id}`, employee)
        .then((response) => {
          if (!response.data.error) {
            if(employee_urlimg2){
            axios
            .post(`/api/employees/${employee.employee_id}/mechanicPhoto`, employee_urlimg2)
            .then((responses)=>{
              if (!responses.data.error) {
              dispatch(updateEmployeeAction(responses.data.data))
              dispatch({
                type: UPDATE_PROFILE,
                payload: responses.data.data,
              })
              resolve(responses)
              NotificationHandler(
                'Hecho:',
                'info',
                'Datos actualizados correctamente'
              )}else{
                reject()
                NotificationHandler('Error', 'error', responses.data.data)
              }
            }).catch((err)=>{
              reject()
              NotificationHandler('Error', 'error', "Error al cargar la imagen", err)
            })}else{
              dispatch(updateEmployeeAction(response.data.data))
              dispatch({
                type: UPDATE_PROFILE,
                payload: response.data.data,
              })
              resolve(response)
              NotificationHandler(
                'Hecho:',
                'info',
                'Datos actualizados correctamente'
              )
            }
          } else {
            reject()
            NotificationHandler('Error', 'error', response.data.data)
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler('Error', 'error', 'Error al actualizar', error)
        })
    } else {
      reject()
      NotificationHandler(
        'Error',
        'error',
        `No se proporcionó id a: ${employee.employee_name}`
      )
    }
})

export const updatePhoto = (id, file) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (id) {
            axios
            .post(`/api/employees/${id}/mechanicPhoto`, file)
            .then((responses)=>{
              if (!responses.data.error) {
              dispatch(updateEmployeeAction(responses.data.data))
              dispatch({
                type: UPDATE_PROFILE,
                payload: responses.data.data,
              })
              resolve(responses)
              NotificationHandler(
                'Hecho:',
                'info',
                'Foto de perfil actualizado correctamente'
              )}else{
                reject()
                NotificationHandler('Error', 'error', responses.data.data)
              }
            }).catch((err)=>{
              reject()
              NotificationHandler('Error', 'error', "Error al cargar la imagen", err)
            })
        } else {
          reject()
          NotificationHandler(
            'Error',
            'error',
            `Error al cargar la imagen`
          )
    }
})

export const deleteEmployee = (employee) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { employee_id: current_employee_id } =
      getState().AppReducer.currentUser

    if (employee.employee_id !== current_employee_id) {
      axios
        .delete(`/api/employees/${employee.employee_id}`)
        .then((response) => {
          if (!response.data.error) {
            dispatch(deleteEmployeeAction(employee))
            resolve(response)
            NotificationHandler('Hecho:', 'info', 'Empleado eliminado')
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error:No se eliminó intentelo de nuevo',
              response.data.data
            )
          }
        })
        .catch((error) => {
          NotificationHandler(
            'Error',
            'error',
            'Error al eliminar datos de empleado',
            error
          )
          reject()
        })
    } else {
      NotificationHandler(
        'Error',
        'error',
        'No se puede eliminar a usted mismo'
      )
      reject()
    }
  })

export const updateCredentials = (employee) => (dispatch) =>
  new Promise((resolve, reject) => {
    axios
      .put(`/api/employees/credentials/${employee.id}`, employee)
      .then((response) => {
        if (response.status === 200 && response.status < 300) {
          const data = {
            ...employee.row,
            employee_username: employee.employee_username,
          }
          resolve(response)
          dispatch(updateEmployeeAction(data))
        } else {
          reject()
        }
      })
      .catch((error) => {
        reject(error.message)
      })
  })

export const comparePassword = (employee) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/api/employees/compare/${employee.id}`, employee)
      .then((response) => {
        if (response.status === 200 && response.status < 300) {
          resolve(response.data)
        } else {
          reject()
        }
      })
      .then((error) => {
        reject(error)
      })
  })

export const requestResetPassword = (email) =>
  new Promise((resolve, reject) => {
    axios
      .put('/api/employees/forgotPassword', { employee_email: email })
      .then((response) => {
        if (!response.error) {
          return resolve(response.data)
        }
        // eslint-disable-next-line prefer-promise-reject-errors
        return reject({ message: response.data })
      })
      .catch((error) => {
        return reject(error)
      })
  })

export const resetPassword = (newPassword, token) =>
  new Promise((resolve, reject) => {
    axios
      .put(
        '/api/employees/resetPassword',
        { newPassword },
        {
          headers: {
            token,
          },
        }
      )
      .then((response) => {
        if (!response.error) {
          return resolve(response.data)
        }
        // eslint-disable-next-line prefer-promise-reject-errors
        return reject({ message: response.data })
      })
      .catch((error) => {
        return reject(error)
      })
  })
