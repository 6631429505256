import React, { useMemo } from 'react'
import { EyeOutlined, LoadingOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'

import videoIcon from '../../../../../public/images/video.png'

import {
  DefaultThumbnailImg,
  LoadingThumbnailWrapper,
} from './InputThumbnail.style'

const InputThumbnail = ({ fileObject, handleVideoPreview }) => {
  const videoURL = useMemo(
    () =>
      fileObject?.url
        ? fileObject.url
        : URL.createObjectURL(fileObject?.originFileObj),
    []
  )

  const renderVideo = () => {
    const { status } = fileObject

    if (status === 'uploading') {
      return (
        <LoadingThumbnailWrapper>
          <LoadingOutlined style={{ fontSize: '30px' }} />
        </LoadingThumbnailWrapper>
      )
    }

    if (status === 'done') {
      return (
        <ReactPlayer
          url={videoURL}
          width="100%"
          height="100%"
          playing={false}
          controls={false}
          style={{ objectFit: 'cover', overflow: 'hidden' }}
          muted
        />
      )
    }

    if (status === 'error') {
      return <p>Error</p>
    }

    return (
      <DefaultThumbnailImg src={videoIcon} alt={fileObject?.name || 'Video'} />
    )
  }

  return (
    <>
      {fileObject.type && fileObject.type.startsWith('video/') ? (
        <>
          <div
            className="ant-upload-list-item ant-upload-list-item-done"
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            <span className="ant-upload-list-item-actions">
              <button
                type="button"
                onClick={() => handleVideoPreview(fileObject)}
                className="ant-btn ant-btn-text ant-btn-sm ant-btn-icon-only ant-upload-list-item-action anticon"
                style={{ backgroundColor: 'transparent' }}
              >
                <EyeOutlined />
              </button>
            </span>
            {renderVideo()}
          </div>
        </>
      ) : (
        <p>Archivo no válido.</p>
      )}
    </>
  )
}

InputThumbnail.propTypes = {
  fileObject: PropTypes.object.isRequired,
  handleVideoPreview: PropTypes.func.isRequired,
}

export default InputThumbnail
