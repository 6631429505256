import styled from 'styled-components'

export const ModelFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 2rem;

  padding: 1rem 0;

  div.custom-select {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-top: 0.8rem;

    label.custom-select__label {
      font-weight: 500;
      font-size: 1.4rem;
    }

    span {
      font-weight: 400;
    }
  }
`
