import _ from 'lodash'

import {
  GET_COLORS,
  ADD_COLOR,
  UPDATE_COLOR,
  DELETE_COLOR,
} from '../actionTypes/ColorTypes'

export default (
  state = {
    colors: {},
  },
  action
) => {
  switch (action.type) {
    case GET_COLORS: {
      return {
        ...state,
        colors: action.payload,
      }
    }
    case ADD_COLOR: {
      return {
        ...state,
        colors: {
          ...state.colors,
          [action.payload.color_id]: action.payload,
        },
      }
    }
    case UPDATE_COLOR: {
      return {
        ...state,
        colors: {
          ...state.colors,
          [action.payload.color_id]: action.payload,
        },
      }
    }
    case DELETE_COLOR: {
      const newState = _.cloneDeep(state)
      delete newState.colors[action.payload.color_id]
      return newState
    }

    default: {
      return state
    }
  }
}
