import React, { Component } from 'react'
import {
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Col,
  Button,
  Row,
  Form,
} from 'react-bootstrap'
import _ from 'lodash'
import Select from 'react-select'
import PropTypes from 'prop-types'
import { SaveOutlined } from '@ant-design/icons'
import { showConfirmDialog } from '../../../Common'

class SerieModal extends Component {
  state = {
    serie_number: '',
    serie_begin: 0,
    serie_end: 0,
    serie_length: 0,
    serie_invoice_type_id: null,
    loading: false,
  }

  componentDidUpdate(prevProps) {
    const { selectedSerie } = this.props
    const { selectedSerie: prevSelectedSerie } = prevProps

    if (selectedSerie !== prevSelectedSerie) {
      const { length } = Object.keys(selectedSerie)
      const id = selectedSerie.serie_id
      if (
        length === 0 ||
        id === undefined ||
        id === null ||
        selectedSerie === undefined ||
        selectedSerie === null
      ) {
        this.clearComponent()
      } else if (length > 0 && id) {
        this.setSeriesModalState()
      }
    }
  }

  setSeriesModalState() {
    const { selectedSerie } = this.props
    const {
      serie_number,
      serie_begin,
      serie_end,
      invoice_type_id: serie_invoice_type_id,
      serie_length,
    } = selectedSerie

    this.setState({
      serie_number,
      serie_begin,
      serie_end,
      serie_invoice_type_id,
      serie_length,
      loading: false,
    })
  }

  clearComponent = () => {
    this.setState({
      serie_number: '',
      serie_begin: '',
      serie_end: '',
      serie_length: '',
      serie_invoice_type_id: '',
      loading: false,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { selectedSerie, toggleModal, updateSerie, addSerie, editMode } =
      this.props
    const {
      serie_number,
      serie_begin,
      serie_end,
      serie_invoice_type_id,
      serie_length,
    } = this.state

    const serie = {
      serie_id: selectedSerie.serie_id || null,
      serie_number,
      serie_begin,
      serie_end,
      invoice_type_id: serie_invoice_type_id,
      serie_length,
    }

    if (editMode) {
      toggleModal()
      showConfirmDialog(
        '¿Modificar?',
        `¿Modificar la serie con número: ${serie.serie_number}`,
        updateSerie,
        serie,
        () => { },
        toggleModal,
        toggleModal
      )
    } else {
      addSerie(serie)
        .then(() => {
          this.setState({ loading: false })
          this.clearComponent()
          toggleModal()
        })
        .catch(() => {
          this.setState({ loading: false })
          this.clearComponent()
          toggleModal()
        })
    }
  }

  handleChange = (e) => {
    const { name } = e.target
    let { value } = e.target
    let serieLength = 0
    if (e.target.name === 'serie_end') {
      value = value.replace(/[^0-9.]/g, '')
      const { serie_begin } = this.state
      serieLength = parseFloat(value) - parseFloat(serie_begin)
      if (!serieLength) {
        serieLength = ''
      }
    } else if (e.target.name === 'serie_begin') {
      value = value.replace(/[^0-9.]/g, '')
      const { serie_end } = this.state
      serieLength = parseFloat(serie_end) - parseFloat(value)
      if (!serieLength) {
        serieLength = ''
      }
    }
    this.setState({
      [name]: value,
      serie_length: serieLength,
    })
  }

  handleChangeSelect = (invoiceType, field) => {
    if (invoiceType) {
      this.setState({
        [`serie_${field}_id`]: invoiceType[`${field}_id`],
      })
    } else {
      this.setState({
        [`serie_${field}_id`]: null,
      })
    }
  }

  render() {
    const { editMode, modalOpen, toggleModal, pagePermissions, invoiceTypes } =
      this.props
    const {
      serie_number,
      serie_begin,
      serie_end,
      serie_invoice_type_id,
      serie_length,
      loading,
    } = this.state
    let title = editMode ? 'Modificar' : 'Agregar'
    if (title === 'Modificar')
      title = pagePermissions.can_edit ? 'Modificar' : ''
    return (
      <Modal bsSize="lg" show={modalOpen} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>{title} Serie</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.handleSubmit}>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <ControlLabel>Texto de serie: </ControlLabel>
                  <FormControl
                    name="serie_number"
                    type="text"
                    value={serie_number}
                    onChange={this.handleChange}
                    required
                    placeholder="Ingresar"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <ControlLabel>Inicio de Serie: </ControlLabel>
                  <FormControl
                    name="serie_begin"
                    type="text"
                    value={serie_begin}
                    onChange={this.handleChange}
                    required
                    placeholder="000"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <ControlLabel>Fin de Serie: </ControlLabel>
                  <FormControl
                    name="serie_end"
                    type="text"
                    value={serie_end}
                    onChange={this.handleChange}
                    required
                    placeholder="000"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <ControlLabel>Longitud: </ControlLabel>
                  <FormControl
                    name="serie_length"
                    type="text"
                    value={serie_length}
                    onChange={this.handleChange}
                    required
                    readOnly
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <ControlLabel>Tipo de facturación: </ControlLabel>
                  <Select
                    placeholder="Tipo de facturación"
                    options={_.values(invoiceTypes)}
                    labelKey="invoice_type_name"
                    valueKey="invoice_type_id"
                    value={serie_invoice_type_id}
                    onChange={(value) => {
                      this.handleChangeSelect(value, 'invoice_type')
                    }}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button type="submit" hidden id="submitModelForm" />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={toggleModal}>Cerrar</Button>
          {!!pagePermissions.can_edit && (
            <Button
              disabled={loading}
              bsStyle="primary"
              onClick={() => {
                document.getElementById('submitModelForm').click()
              }}
              className="btn-antd-icon"
            >
              <SaveOutlined />
              {editMode ? ' Modificar' : ' Agregar'}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    )
  }
}

SerieModal.propTypes = {
  editMode: PropTypes.bool.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  addSerie: PropTypes.func.isRequired,
  updateSerie: PropTypes.func.isRequired,
  invoiceTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedSerie: PropTypes.objectOf(PropTypes.object).isRequired,
  pagePermissions: PropTypes.objectOf(PropTypes.object).isRequired,
}

export default SerieModal
