import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal, Button, Row, Col} from 'react-bootstrap'
import styled from 'styled-components'
import { BREAK_POINTS_DESKTOP_FIRST } from '../../../global/css/breakPoints'


const backTitle = [
    { 'background-color' : '#219653'},
    { 'background-color' : '#F44336'},
    { 'background-color' : '#FFCC4D'},
  ]
const titleArray = [
    { title:'Facturación', secondTitle: 'Facturado este mes', firstIcon: 'fas fa-dollar-sign', thirdTitle: 'Promedio de facturacion', secondIcon: 'fas fa-chart-line' , forthTitle: 'Vehiculos revisados', thirdIcon: 'fas fa-tachometer-alt'},
    { title:'Eficiencia',  secondTitle: 'Horas trabajadas en el mes', firstIcon: 'fas fa-hourglass-start', thirdTitle: 'Expectativa de horas en el mes', secondIcon: 'fas fa-hourglass-end' , forthTitle: 'Formula', thirdIcon: 'fas fa-calculator'},
    { title:'Evaluación', secondTitle: 'Puntuacion del mes', firstIcon: 'fas fa-star', thirdTitle: 'Porcentaje de calificacion de clientes', secondIcon: 'fas fa-percentage' , forthTitle: 'Vehiculos evaluados', thirdIcon: 'fas fa-tachometer-alt'},
  ]  

class MetricModal extends Component{
    state = {
      firstData: 0,
      secondData: 0,
      thirdData: 0,
      fourthData: 0
    }

    componentDidUpdate(prevProps){
      const { data } =  this.props
      const { data: prevData } = prevProps

      if(data !== prevData){
        this.getData(data)
      }
         
    }

    getData(data){
      const { typeId, currentUser } =  this.props
      switch (typeId) {
        case 0:
          this.setState({
            firstData: data.invData ? (data.invData.invoiced).toFixed(2) : 0,
            secondData: data.avInv ? (data.avInv.invoiced).toFixed(2) : 0,
            thirdData: data.avInv ? data.avInv.totalVehicles : 0,
            fourthData: data.metric ? parseFloat(data.metric).toFixed(2) : parseFloat(currentUser.employee_metric_goal).toFixed(2)
          })
          break
        case 1:
          this.setState({
            firstData: data.totalRealTime ? Math.round(data.totalRealTime, 2) : 0,
            secondData: data.totalTime ? Math.round(data.totalTime, 2) : 0
          })
        break
        case 2:
          this.setState({
            firstData: data.score ? data.score : 0,
            secondData: data.percentage ? data.percentage : 0 ,
            thirdData: data.totalWorkOrder ? data.totalWorkOrder : 0,
          })
        break      
      
        default:
          this.setState({
            firstData: 0,
            secondData: 0,
            thirdData: 0,
          })
          break
      }
    }
    
    render() {
        const { modalOpen, toggleModal, typeId} = this.props
        const { firstData, secondData, thirdData, fourthData } = this.state
        return (
          <Modal show={modalOpen} onHide={toggleModal}>
            <Modal.Header closeButton style={backTitle[typeId]}>
              <Modal.Title>
                {titleArray[typeId].title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
              <Summary>
                <Row>
                  <Col md={6} xs={12} className="text-center">
                    <i className={titleArray[typeId].firstIcon} />
                    <span className={typeId === 0 ? '--in-process' : '--in-extraTime'} />
                    { typeId !== 0 ? <h1>{firstData}</h1> : <h3>{`Haz facturado $${firstData} de una meta de $${fourthData}`}</h3>}
                    <h3>{titleArray[typeId].secondTitle}</h3>
                  </Col>
                  <Col md={6} xs={12} className="text-center" >
                    <i className={titleArray[typeId].secondIcon} />
                    <span className="--in-pause" />
                    <h1>{typeId === 0 ? `$ ${secondData}` : secondData}</h1>
                    <h3>{titleArray[typeId].thirdTitle}</h3>
                  </Col>
                  <Col xs={12} className="text-center" >
                    <i className={titleArray[typeId].thirdIcon} />
                    <span className="--in-inline" />
                    <h1>{typeId === 1 ? `${firstData} / ${secondData} * 100` : thirdData}</h1>
                    <h3>{titleArray[typeId].forthTitle}</h3>
                  </Col>
                </Row>                      
              </Summary>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={toggleModal}>Cerrar</Button>
            </Modal.Footer>
          </Modal>
        )
      }
}

const Summary = styled.div`
  i {
    font-size: 4rem;
    padding-bottom: 10px;
    padding-top: 15px;
    display: block !important;
    @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
      font-size: 3rem;
    }
  }
  .col-md-4{
    text-align: -webkit-center;
  }
  .col-xs-4{
    text-align: -webkit-center;
  }
  span{
    display: inline-flex; 
    border: 10px solid #000;
    border-block-end: none;
    width: 25%;
    &.--in-total{
      border-color: #06d6a0 !important;
    }
    &.--in-pause{
      border-color: #e63946 !important;
    }
    &.--in-extraTime{
      border-color: #ffba08 !important;
    }
    &.--in-inline{
      border-color: #000 !important;
    }
    @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
      width: 70%;
    }
  }
  h1{
    font-weight: bold;
  }
  h3{
    font-weight: bold;
    text-align: center;
  }
  @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
    h3{
      font-size: 1.2rem;
    }
  }

`

MetricModal.propTypes = {
    modalOpen: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    typeId: PropTypes.string.isRequired,
    currentUser: PropTypes.objectOf(PropTypes.any).isRequired,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
}
const mapStateToProps = (store) => ({
  currentUser: store.AppReducer.currentUser,
})
  
export default connect(mapStateToProps)(MetricModal)