import { Modal } from 'antd'
import styled from 'styled-components'

const AddProductModalWrapper = styled(Modal)`
  &.add-product {
    .ant-modal-content {
      .ant-modal-header {
        .ant-modal-title {
          font-size: 1.8rem;
        }
      }
      .ant-modal-body {
        margin: 20px 0 34px 0;
        .main-inputs-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 12px;
          .ant-select-selection-item{
            max-width: 90px;
          } .ant-select-selector,
          .ant-select-arrow {
            border-color: #acaeb0;
            color: black;
            span {
              color: black;
            }
          }
          .disclaimer {
            width: fit-content;
            padding: 4px 8px;
            border-radius: 4px;
            margin-bottom: 12px;
            display: flex;

            background-color: #eeeeee;

            align-items: center;
            p {
              font-size: 12px;
              margin: unset;
            }
          }
          .discount {
            margin: unset;
            width: 35%;
            font-weight: 500;
            font-size: 1.4rem;
            span {
              border-color: #acaeb0;
            }
          }
        }
        .selectedOptions {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .loader {
            padding: 20px 0;
          }
          h2 {
            font-family: inherit;
            font-size: 1.6rem;
          }
          .product {
            position: relative;
            padding: 12px 8px;
            border-radius: 8px;
            border: #acaeb0 solid 1px;
            display: flex;
            flex-direction: column;
            gap: 8px;

            .delete {
              position: absolute;
              right: 0;
              top: 2px;
              background: unset;
              margin: unset;
              border: unset;
              font-size: 1.6rem;
              color: #e33244;
              &:hover {
                opacity: 0.7;
              }
            }
            .product-name-wrapper {
              display: flex;
              flex-direction: column;
              gap: 8px;
              h4,
              p {
                margin: unset;
              }

              h4 {
                font-size: 1.6rem;
                max-width: 80%;
              }
              p {
                margin-top: 16px;
                font-size: 1.4rem;
              }
            }
            .secondary-inputs-wrapper {
              display: flex;
              justify-content: space-between;
              label {
                max-width: 50%;
                font-size: 1.3rem;
                font-weight: 500;
                input {
                  border: unset;
                  width: 100%;
                  border-radius: 4px;
                  padding: 4px 8px;
                  background-color: #eeeeee;
                }
              }
              .quantity,
              .price {
                width: 30%;
              }
            }

            .subtotal-wrapper {
              text-align: right;
              margin: unset;
              margin-top: 16px;
            }
          }
        }
        .total {
          text-align: right;
          margin-top: 30px;
          font-size: 1.6rem;
        }
      }
    }
  }
`

export default AddProductModalWrapper
