import React, { useState } from 'react'

import AddProductModal from '../../Common/AddProductModal/AddProductModal'
import AddPhotoModal from '../../Common/AddPhotoModal/AddPhotoModal'
import AddSomethingModalCustom from './AddSomethingModal.styles'
import AddProcessModal from '../../Common/AddProcessModal'
import AddSomethigModalTypes from './AddSomething.types'
import AddVideosModal from '../../Common/AddVideoModal/AddVideoModal'

const AddSomethingModal = ({
  isOpen,
  setOpen,
  products,
  processes,
  workOrderId,
  setTab,
  scrollToChat,
  receptionId,
  workshopId,
}) => {
  const [isProductModalOpen, setIsProductModalOpen] = useState(false)
  const [isPhotoModalOpen, setIsPhotoModalOpen] = useState(false)
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false)
  const [isProcessModalOpen, setIsProcessModalOpen] = useState(false)

  const handleAddComment = () => {
    setOpen(false)
    scrollToChat()
  }

  const openProcessModal = () => {
    setIsProcessModalOpen(true)
    setOpen(false)
  }

  return (
    <>
      <AddSomethingModalCustom
        open={isOpen}
        onClose={() => setOpen(false)}
        className="add-modal"
      >
        <div className="content">
          <button
            type="button"
            onClick={() => setOpen(false)}
            className="close-btn"
          >
            <i className="fas fa-times" />
          </button>
          <h1>¿Qué acción necesitas realizar?</h1>
          <ul className="options">
            <li>
              <button type="button" onClick={openProcessModal}>
                <i className="fas fa-cogs" />
                Agregar proceso
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={() => {
                  setIsProductModalOpen(true)
                  setOpen(false)
                }}
              >
                <i className="fas fa-box-open" />
                Agregar repuesto
              </button>
            </li>
            <li>
              <button type="button" onClick={() => handleAddComment()}>
                <i className="fas fa-comment-alt" />
                Agregar comentario
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={() => {
                  setIsPhotoModalOpen(true)
                  setOpen(false)
                }}
              >
                <i className="fas fa-images" />
                Agregar fotografías
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={() => {
                  setIsVideoModalOpen(true)
                  setOpen(false)
                }}
              >
                <i className="fas fa-video" />
                Agregar videos{' '}
                <span
                  className="badge badge-primary"
                  style={{ backgroundColor: 'rgb(6, 214, 160)' }}
                >
                  Nuevo
                </span>
              </button>
            </li>
          </ul>
        </div>
      </AddSomethingModalCustom>
      <AddProductModal
        isOpen={isProductModalOpen}
        setOpen={setIsProductModalOpen}
        setOptionsModal={setOpen}
        setTab={setTab}
        products={products}
        workOrderId={workOrderId}
      />
      <AddPhotoModal
        isOpen={isPhotoModalOpen}
        setOpen={setIsPhotoModalOpen}
        setOptionsModal={setOpen}
        setTab={setTab}
        receptionId={receptionId}
        workshopId={workshopId}
      />
      <AddVideosModal
        isOpen={isVideoModalOpen}
        setOpen={setIsVideoModalOpen}
        setOptionsModal={setOpen}
        setTab={setTab}
        receptionId={receptionId}
        workshopId={workshopId}
      />
      <AddProcessModal
        isOpen={isProcessModalOpen}
        setOpen={setIsProcessModalOpen}
        setOptionsModal={setOpen}
        setTab={setTab}
        processes={processes}
        workOrderId={workOrderId}
      />
    </>
  )
}

AddSomethingModal.propTypes = AddSomethigModalTypes

export default AddSomethingModal
