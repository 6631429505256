import styled from 'styled-components'
import { BREAK_POINTS_DESKTOP_FIRST } from '../../../../../../global/css/breakPoints'

const EditPhoneInputWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
  flex-direction: row;
  flex-wrap: wrap;

  .input-wrapper {
    .phone-input {
      max-width: 200px;
    }
  }
  .edit-actions {
    .button {
      height: min-content;
    }
    .loader {
      display: flex;
      gap:8px;
      justify-content: center;
      align-items: center;
      font-size: 1.2rem;
      color: #616060;
    }
  }

  @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
    .input-wrapper {
      width: 100%;
      .phone-input {
        max-width: unset;
        width: 100%;
      }
    }
  }
`

export default EditPhoneInputWrapper
