import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import { Button } from 'react-bootstrap'
import GradientTextComponent from '../../../Common/GradientText.js/GradientText'
import { ResumeStepWrapper } from './ResumeStep.styles'
import PreviousNextButton from '../../../../buttons/PreviousNextButton/PreviousNextButton'
import Card from './Card/Card'
import TotalTime from './TotalTime/TotalTime'
import PackageProcess from './PackageProcess/PackageProcess'
import MechanicAsigantion from './MechanicAsignation/MechanicAsigantion'
import { useAddReceptionContext } from '../../../../../contexts/AddReceptionContext'
import { useModal } from '../../../../../hooks/useModal'
import SuccessModal from '../../../Common/SuccessModal/SuccessModal'
import {
  createReception,
  editReception,
} from '../../../../../api/newReceptionService'
import {
  NotificationHandler,
  getAddedAndRemovedElements,
} from '../../../../../Helpers'

const ResumeStep = ({ handlePrevious, history }) => {
  const { state } = useAddReceptionContext()
  const [isWorkOrder, setIsWorkOrder] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isModalOpen, openModal, closeModal] = useModal()
  const {
    selectedVehicle,
    client,
    receptionId,
    contact,
    vehicleState,
    packageDetails,
    selectedProducts,
    selectedServices,
    observations,
    mechanic,
    isEdit,
    receptionToEdit,
  } = state

  const isEditText = 'Actualizar recepción'
  const noEditText = mechanic ? 'Crear orden de trabajo' : 'Crear recepción'
  const buttonText = isEdit ? isEditText : noEditText

  const getTransformServices = (servicesArray) => {
    return servicesArray?.map((service) => ({
      budgetDetailId: service.budgetDetailId,
      processId: service.processId,
      processTime: service.processTime,
      processPrice: parseFloat(service?.process_placed_price),
      processDiscount: service?.process_placed_discount || 0,
    }))
  }

  const getTransformedProducts = (productsArray) => {
    return productsArray?.map((product) => ({
      budgetDetailId: product.budgetDetailId,
      productId: product.productId,
      productPrice: parseFloat(product?.product_placed_price),
      productQuantity: parseInt(product.product_asked_quantity, 10),
      productDiscount: product?.product_placed_discount || 0,
    }))
  }

  const submitReception = async () => {
    try {
      setIsLoading(true);
      openModal()

      const mappedPackageProcesses = () => {
        return packageDetails?.processes?.map((process) => ({
          packageProcessId: process.packageProcessId,
          processId: process.processId,
          processTime: process.processTime,
          processPrice: process.processPrice,
          processDiscount: packageDetails?.packageDiscount || 0,
          processSequence: process.processSequence,
        }))
      }

      const mappedProcesses = getTransformServices(selectedServices)
      const mappedProducts = getTransformedProducts(selectedProducts)

      const body = {
        vehicleId: selectedVehicle.vehicleId,
        personId: client.ownerId,
        addAsContact: !!contact.contactName,
        contactName: contact.contactName || '',
        contactPhone: contact.contactPhone || '',
        contactCountryCode: contact.contactCountryCode || null,
        fuelAmount: vehicleState.vehicleGasLevel || 0,
        odometerValue: parseInt(vehicleState.vehicleOdometer, 10) || 0,
        packageId: packageDetails?.packageId || null,
        packageTotal: packageDetails?.packagePrice || 0,
        packageDiscount: packageDetails?.packageDiscount || 0,
        packageTotalTime: packageDetails?.packageTotalTime || 0,
        packageDetails: mappedPackageProcesses() || [],
        processes: mappedProcesses || [],
        products: mappedProducts || [],
        createWorkOrder: !!mechanic,
        mechanicId: mechanic?.mechanicId || 0,
        observations: observations || '',
      }
      const response = await createReception(receptionId, body)

      if (response.data.workOrderId) {
        setIsWorkOrder(true)
      }

      setIsLoading(false)

      setTimeout(() => {
        if (response.data.workOrderId) {
          history.push('/workOrders')
        } else if (response.data.receptionId && !response.data.workOrderId) {
          history.push('/receptions')
        }
        closeModal()
      }, 3000)
      
    } catch (e) {
      
      setIsLoading(false)
      closeModal()
      NotificationHandler(
        'Error creando recepcion',
        'error',
        'Intenta nuevamente'
      )
    }
  }

  const buildEditReceptionBody = () => {
    const reception = {
      vehicleGasLevel: vehicleState.vehicleGasLevel || 0,
      vehicleOdometer: parseInt(vehicleState.vehicleOdometer, 10) || 0,
      observations: observations || '',
    }

    const originalPackageId = receptionToEdit.package.packageId

    const {
      addedElements: addedProducts,
      removedElements: deletedProducts,
      unchangedElements: editedProducts,
    } = getAddedAndRemovedElements(
      receptionToEdit.aditionalProducts,
      selectedProducts,
      'productId'
    )

    const {
      addedElements: addedProcesses,
      removedElements: deletedProcesses,
      unchangedElements: editedProcesses,
    } = getAddedAndRemovedElements(
      receptionToEdit.aditionalServices,
      selectedServices,
      'processId'
    )

    const elementsAdded = addedProcesses.length > 0 || addedProducts.length > 0
    const elementsEdited =
      editedProcesses.length > 0 || editedProducts.length > 0
    const elementsDeleted =
      deletedProcesses.length > 0 || deletedProducts.length > 0

    // Handle package cases: replace, edit or remove
    if (packageDetails?.packageId) {
      const packageData = {
        packageId: packageDetails.packageId,
        packagePrice: packageDetails?.packagePrice || 0,
        packageTime: packageDetails?.packageTotalTime || 0,
        packageDiscount: packageDetails?.packageDiscount || 0,
      }

      if (packageDetails.packageId !== originalPackageId) {
        reception.replacePackage = { ...packageData }
      } else {
        reception.editPackage = { ...packageData }
      }
    } else {
      reception.deletePackage = { packageId: originalPackageId }
    }

    // Handle services/products cases: add, edit or remove
    if (elementsAdded) {
      reception.addElements = {
        processes: getTransformServices(addedProcesses),
        products: getTransformedProducts(addedProducts),
      }
    }

    if (elementsDeleted) {
      reception.deleteElements = {
        processes: deletedProcesses.map((process) => process.budgetDetailId),
        products: deletedProducts.map((product) => product.budgetDetailId),
      }
    }

    if (elementsEdited) {
      reception.editElements = {
        processes: getTransformServices(editedProcesses),
        products: getTransformedProducts(editedProducts),
      }
    }

    return reception
  }

  const updateReception = async () => {
    const receptionReqBody = buildEditReceptionBody()

    try {
      await editReception(receptionId, receptionReqBody)

      NotificationHandler(`¡Orden #${receptionId} actualizada!`, 'success')
      history.push('/receptions')
    } catch (err) {
      NotificationHandler(
        'No se pudo editar la recepción',
        'error',
        'Ocurrió un error, valida que todo esté correcto.'
      )
      console.log(err)
    }
  }

  return (
    <>
      <main className="new-reception__main">
        <div className="new-reception__main__question">
          <GradientTextComponent text="Resumen de la recepción " />
        </div>
        <div className="new-reception__main__content">
          <ResumeStepWrapper>
            <Card />
            <TotalTime />
            <PackageProcess />
            {!isEdit && <MechanicAsigantion />}
          </ResumeStepWrapper>
        </div>
      </main>
      <div className="new-reception__navigation-buttons">
        <PreviousNextButton
          isPrevious
          onClickHandler={() => {
            handlePrevious()
          }}
        />
        <Button
          bsStyle="success"
          onClick={() => (isEdit ? updateReception() : submitReception())}
        >
          {buttonText}
        </Button>
      </div>
      <SuccessModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        isLoading={isLoading}
        text={
          isWorkOrder
            ? '¡Felicidades! tu orden de trabajo fue creada correctamente'
            : '¡Felicidades! tu recepción fue registrada correctamente'
        }
      />
    </>
  )
}

ResumeStep.propTypes = {
  handlePrevious: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default ResumeStep
