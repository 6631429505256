import React, { useState } from 'react'
import { PropTypes } from 'prop-types'

import { multimediaTabsIds, multimediaTypes } from '../../../../Constants'
import { NotificationHandler } from '../../../../Helpers'
import AddVideosModalStyled from './AddVideoModal.style'
import LoaderDynamicText from '../LoaderDynamicText'
import VideosInput from '../VideosInput/VideosInput'
import { addMultimediaService } from '../../../../api/apiAddPhotosService'

const AddVideosModal = ({
  isOpen,
  setOpen,
  setOptionsModal = null,
  setTab,
  receptionId,
  workshopId,
}) => {
  const [videos, setVideos] = useState([])
  const [loading, setLoading] = useState(false)

  const abortController = new AbortController()
  const { signal } = abortController

  const resetValues = () => {
    setLoading(false)
    setOpen(false)
    setVideos([])
  }

  const handleCancel = () => {
    if (setOptionsModal) {
      setOptionsModal(true)
    }
    resetValues()
  }

  const handleOk = async () => {
    try {
      setLoading(true)
      await setTab('3', multimediaTabsIds.VIDEOS)
      resetValues()
      NotificationHandler('¡Listo!', 'info', 'Videos agregados')
    } catch (error) {
      NotificationHandler('Error', 'error', 'No se pudieron agregar los videos')
    }
  }

  const uploadOneVideo = async (file) => {
    await addMultimediaService(
      receptionId,
      workshopId,
      [file],
      false,
      multimediaTypes.VIDEO,
      signal
    )
  }

  return (
    <AddVideosModalStyled
      title="Agregar videos"
      open={isOpen}
      onOk={handleOk}
      closable={videos.length <= 0}
      onCancel={videos.length > 0 ? handleOk : handleCancel}
      okText="Listo"
      cancelText="Cancelar"
      className="add-photos"
      okButtonProps={{
        style: {
          backgroundColor: videos.length <= 0 ? '#eeeeee' : '#1E1E40',
          display: loading ? 'none' : 'inline-block',
        },
        disabled: videos.length <= 0,
      }}
      cancelButtonProps={{
        style: {
          display: loading || videos.length > 0 ? 'none' : 'inline-block',
        },
      }}
    >
      {loading && (
        <div className="loader">
          <LoaderDynamicText
            textArray={['Actualizando orden de trabajo...']}
            colored
            interval={1}
          />
        </div>
      )}
      {!loading && (
        <VideosInput
          videos={videos}
          maxVideosAllowed={4}
          handleAddVideo={setVideos}
          uploadOneVideo={uploadOneVideo}
          abortController={abortController}
        />
      )}
    </AddVideosModalStyled>
  )
}

AddVideosModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setTab: PropTypes.func.isRequired,
  setOptionsModal: PropTypes.func,
  receptionId: PropTypes.number.isRequired,
  workshopId: PropTypes.number.isRequired,
}

AddVideosModal.defaultProps = {
  setOptionsModal: () => true,
}
export default AddVideosModal
