import React from 'react'
import { FormGroup, ControlLabel, Col, Row } from 'react-bootstrap'
import _ from 'lodash'
import Select from 'react-select'
import PropTypes from 'prop-types'
import ClaimDetails from './ClaimDetails/ClaimDetails'
import { getValidationState } from '../Common'

const addMechanicsName = (mechanics) =>
  _.map(mechanics, (mechanic) => {
    const name = `${mechanic.employee_name} ${mechanic.employee_lastname}`
    return {
      ...mechanic,
      name,
    }
  })

const ClaimForm = ({
  packages,
  employees,
  state,
  handleChangeSelect,
  productsThatApply,
  products,
  handleChangeDetail,
  deleteWorkOrderDetail,
  addWorkOrderDetail,
  handleChangeSelectDetail,
  processes,
  processesThatApply,
  editMode,
}) => {
  const {
    package_id,
    workOrderDetails,
    claimDetails,
    packageDetails,
    mechanic_id,
  } = state

  return (
    <div>
      <Row>
        <Col xs={12}>
          <FormGroup validationState={getValidationState('brand', true)}>
            <ControlLabel>Paquete: </ControlLabel>
            <Select
              placeholder="Paquete"
              options={_.values(packages)}
              labelKey="package_name"
              valueKey="package_id"
              value={package_id}
              onChange={(value) => {
                handleChangeSelect(value, 'package')
              }}
              required
              disabled={editMode}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup validationState={getValidationState('employee', true)}>
            <ControlLabel>Mecánico: </ControlLabel>
            <Select
              placeholder="Mecánico"
              options={addMechanicsName(employees)}
              labelKey="name"
              value={mechanic_id}
              valueKey="employee_id"
              onChange={(value) => {
                handleChangeSelect(value, 'mechanic')
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <div>
        <ClaimDetails
          claimDetails={claimDetails}
          products={products}
          productsThatApply={productsThatApply}
          processes={processes}
          processesThatApply={processesThatApply}
          services={packages}
          workOrderDetails={workOrderDetails}
          packageDetails={_.keyBy(packageDetails, 'process_id')}
          handleChangeDetail={handleChangeDetail}
          deleteWorkOrderDetail={deleteWorkOrderDetail}
          handleChangeSelectDetail={handleChangeSelectDetail}
          addWorkOrderDetail={addWorkOrderDetail}
          editMode={editMode}
        />
      </div>
    </div>
  )
}

ClaimForm.propTypes = {
  packages: PropTypes.objectOf(PropTypes.object).isRequired,
  employees: PropTypes.arrayOf(PropTypes.object).isRequired,
  state: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChangeSelect: PropTypes.func.isRequired,
  productsThatApply: PropTypes.objectOf(PropTypes.any).isRequired,
  products: PropTypes.objectOf(PropTypes.object).isRequired,
  handleChangeDetail: PropTypes.func.isRequired,
  deleteWorkOrderDetail: PropTypes.func.isRequired,
  addWorkOrderDetail: PropTypes.func.isRequired,
  handleChangeSelectDetail: PropTypes.func.isRequired,
  processes: PropTypes.objectOf(PropTypes.any).isRequired,
  processesThatApply: PropTypes.objectOf(PropTypes.any).isRequired,
  editMode: PropTypes.bool.isRequired,
}

export default ClaimForm
