/* eslint-disable no-param-reassign */
import axios from 'axios'
import _ from 'lodash'

import {
  GET_PROVIDERS,
  ADD_PROVIDERS,
  UPDATE_PROVIDERS,
  DELETE_PROVIDERS,
} from '../actionTypes/ProviderTypes'
import { NotificationHandler } from '../Helpers'

const getProvidersActions = (payload) => ({
  type: GET_PROVIDERS,
  payload,
})

const addProviderAction = (payload) => ({
  type: ADD_PROVIDERS,
  payload,
})

const updateProviderAction = (payload) => ({
  type: UPDATE_PROVIDERS,
  payload,
})

const deleteProviderAction = (payload) => ({
  type: DELETE_PROVIDERS,
  payload,
})

export const getProviders = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const providersQuantity = _.size(getState().ProviderReducer.providers)
    if (providersQuantity === 0) {
      axios
        .get('/api/provider/')
        .then((response) => {
          if (!response.data.error) {
            const provider = _.keyBy(response.data.data, 'provider_id')
            dispatch(getProvidersActions(provider))
            resolve(response.data)
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al obtener proveedores',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al obtener proveedores',
            error
          )
        })
    } else {
      resolve({})
    }
  })

export const addProvider = (provider, contacts) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { workshop_id } = getState().AppReducer.currentUser

    axios
      .post('/api/provider/', { ...provider, workshop_id })
      .then((response) => {
        if (!response.data.error) {
          const { provider_id } = response.data.data

          const providerToRedux = {
            ...response.data.data,
          }

          const tmpContacts = _.map(contacts, (c) => {
            c.provider_id = provider_id
            delete c.provider_contact_id
            delete c.isNew
            return c
          })

          axios
            .post('/api/providerContact', { provider_contacts: tmpContacts })
            .then((responseContacts) => {
              if (responseContacts.data.error) {
                reject()
                NotificationHandler(
                  'Error',
                  'error',
                  'Error al agregar actividad',
                  responseContacts.data.data
                )
              } else {
                providerToRedux.providerContacts = responseContacts.data.data
                dispatch(addProviderAction(providerToRedux))
                resolve(response)
                NotificationHandler('Done', 'info', 'Proveedor agregado')
              }
            })
        } else {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al agregar el proveedor',
            response.data.data
          )
        }
      })
      .catch((error) => {
        reject()
        NotificationHandler(
          'Error',
          'error',
          'Error al agregar el proveedor',
          error
        )
      })
  })

export const updateProvider = (provider, contacts) => (dispatch) =>
  new Promise((resolve, reject) => {
    const { provider_id } = provider
    if (provider_id) {
      // Update all provider's contacts
      // The contacts are send in two categories:
      //   - toAdd array: When a contact doesn't exist in the database
      //   - toUpdate array: When a created contact has been modified o deactivate

      const tmpToUpdate = _.filter(contacts, (c) => c.isNew === undefined)

      const tmpToAdd = _.map(
        _.filter(contacts, (c) => c.isNew !== undefined),
        (cont) => {
          cont.provider_id = provider_id
          delete cont.isNew
          delete cont.provider_contact_id
          return cont
        }
      )

      const contactsToSend = {
        toAdd: tmpToAdd,
        toUpdate: tmpToUpdate,
      }

      axios
        .put(`/api/provider/${provider_id}`, provider)
        .then((responseProvider) => {
          if (!responseProvider.data.error) {
            const providerToRedux = {
              ...responseProvider.data.data,
            }

            axios
              .put(`/api/providerContact/${provider_id}`, contactsToSend)
              .then((responseContacts) => {
                if (responseContacts.data.error) {
                  reject()
                  NotificationHandler(
                    'Error',
                    'error',
                    'Error al actualizar el proveedor',
                    responseContacts.data.data
                  )
                } else {
                  providerToRedux.providerContacts = responseContacts.data.data
                  dispatch(updateProviderAction(providerToRedux))
                  NotificationHandler(
                    'Done',
                    'success',
                    'Proveedor actualizado exitosamente'
                  )
                  resolve(responseContacts)
                }
              })
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al actualizar el proveedor',
              responseProvider.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al actualizar el proveedor',
            error
          )
        })
    } else {
      reject()
      NotificationHandler(
        'Error',
        'error',
        `No se proporcionó id a: ${provider.provider_name}`
      )
    }
  })

export const deleteProvider = (provider) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (provider.provider_id) {
      axios
        .delete(`/api/provider/${provider.provider_id}`)
        .then((response) => {
          if (!response.data.error) {
            NotificationHandler(
              'Done',
              'success',
              'Proveedor eliminado exitosamente'
            )
            dispatch(deleteProviderAction(provider))
            resolve(response)
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al eliminar el proveedor',
              response.data.data
            )
          }
        })
        .catch((error) => {
          NotificationHandler(
            'Error',
            'error',
            'Error al eliminar el proveedor',
            error
          )
        })
    } else {
      reject()
      NotificationHandler(
        'Error',
        'error',
        `No se proporcionó id a: ${provider.provider_name}`
      )
    }
  })
