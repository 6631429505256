import _ from 'lodash'

export const getWorkOrderTotal = (workOrderDetail, returnFloat) => {
  let total = 0
  let totalProducts = 0
  _.forEach(workOrderDetail, (detail) => {
    if (!detail.isDeleted) {
      totalProducts = parseFloat(
        detail.work_order_detail_item_price *
          detail.work_order_detail_item_quantity
      )

      if (detail.work_order_detail_discount) {
        totalProducts -=
          totalProducts * (detail.work_order_detail_discount / 100)
      }

      total += parseFloat(totalProducts || 0)
    }
  })
  if (returnFloat) {
    return total
  }
  return total.toLocaleString('en', { style: 'currency', currency: 'USD' })
}

export const getWorkOrderDetails = (details, editMode) => {
  const workOrderDetails = {}
  _.forEach(details, (detail, index) => {
    try {
      if (!editMode) {
        workOrderDetails[index] = {
          work_order_detail_id: detail.budget_detail_id,
          work_order_detail_item: detail.budget_detail_item,
          work_order_detail_item_price: detail.budget_detail_item_price,
          recommended_work_order_detail_item_price:
            detail.budget_detail_item_price,
          work_order_detail_item_quantity: parseFloat(
            detail.budget_detail_item_quantity
          ),
          work_order_detail_discount: detail.budget_detail_discount,
          type_card: 'product',
        }
      } else {
        workOrderDetails[index] = {
          work_order_detail_id: detail.work_order_detail_id,
          work_order_detail_item: detail.work_order_detail_item,
          work_order_detail_item_price: detail.work_order_detail_item_price,
          recommended_work_order_detail_item_price:
            detail.work_order_detail_item_price,
          work_order_detail_item_quantity:
            detail.work_order_detail_item_quantity,
          work_order_detail_discount: detail.work_order_detail_discount,
          isDeleted: false,
          // used to display the item card type in the budget form step
          // the item card type if used in the <ProductOrderDetail /> component
          type_card: 'product',
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error: ', error)
    }
  })
  return workOrderDetails
}

export const parsePerson = (persons) =>
  _.map(persons, (person) => ({
    ...person,
    fullName: `${person.person_document_number || ''} - ${
      person.person_firstname
    } ${person.person_lastname}`,
  }))

export const getFullNames = (data) =>
  _.map(data, (person) => ({
    ...person,
    fullNameEmployee: `${person.employee.employee_name} ${person.employee.employee_lastname}`,
    fullNameClient: `${person.person.person_document_number} - ${person.person.person_firstname} ${person.person.person_lastname}`,
  }))

export const getProductFullName = (data) =>
  _.map(data, (product) => ({
    ...product,
    fullNameProduct: `${product.product_name} - ${product.product_bar_code}`,
  }))
