import React from 'react'
import { PropTypes } from 'prop-types'
import { Button } from 'antd'

import { LastWorkOrderCard, LastWorkOrderInfo } from './WorkOrderCard.style'
import { getTimeElapsed } from '../../../../../../Helpers'

export const WorkOrderCard = ({ workOrder, setWorkOrderDetail }) => {
  const timeAgo = getTimeElapsed(workOrder.work_order_date)

  const setDetail = () => {
    setWorkOrderDetail(workOrder)
  }

  return (
    <LastWorkOrderCard onClick={setDetail}>
      <div className="work-order-card__header">
        <h4>#{workOrder.work_order_id}</h4>
        <span>{timeAgo}</span>
      </div>
      <div className="work-order-card__body">
        <LastWorkOrderInfo>
          <i className="fas fa-tachometer-alt" />
          <p>
            <span>Odómetro: </span>{' '}
            {workOrder?.budgets?.reception?.reception_millage ||
              'No disponible'}
          </p>
        </LastWorkOrderInfo>
        <LastWorkOrderInfo>
          <i className="fas fa-box-open" />
          <p>
            <span>Paquete: </span>
            {workOrder?.package?.package_name}
          </p>
        </LastWorkOrderInfo>
        <LastWorkOrderInfo>
          <i className="fas fa-wrench" />
          <p>
            <span>Mecánico: </span>
            {workOrder.mechanic_fullname}
          </p>
        </LastWorkOrderInfo>
      </div>
      <div className="work-order-card__footer">
        <Button
          type="primary"
          style={{ background: '#391C42' }}
          onClick={setDetail}
        >
          Ver más
        </Button>
      </div>
    </LastWorkOrderCard>
  )
}

WorkOrderCard.propTypes = {
  workOrder: PropTypes.object.isRequired,
  setWorkOrderDetail: PropTypes.func.isRequired,
}
