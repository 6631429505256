import _ from 'lodash'

import {
  GET_RECEPTIONS,
  CLEAN_RECEPTION,
  SET_LOADING,
  ADD_RECEPTION,
  UPDATE_RECEPTION,
  DELETE_RECEPTION,
  DELETE_RECEPTION_IMAGES,
  SET_RECEPTION_TO_WORK_ORDER,
} from '../actionTypes/ReceptionTypes'

export default (
  state = {
    receptions: {},
    recepciones: [],
    receptionToWorkOrder: null,
    createReceptionLoading: false,
  },
  action
) => {
  switch (action.type) {
    case GET_RECEPTIONS: {
      return {
        ...state,
        receptions: action.payload,
      }
    }
    case ADD_RECEPTION: {
      return {
        ...state,
        receptions: {
          ...state.receptions,
          [action.payload.reception_id]: action.payload,
        },
      }
    }
    case UPDATE_RECEPTION: {
      return {
        ...state,
        receptions: {
          ...state.receptions,
          [action.payload.reception_id]: action.payload,
        },
      }
    }
    case DELETE_RECEPTION: {
      const newState = _.cloneDeep(state)
      delete newState.receptions[action.payload.reception_id]
      return newState
    }
    case CLEAN_RECEPTION: {
      return {
        ...state,
        receptionToWorkOrder: null,
      }
    }
    case DELETE_RECEPTION_IMAGES: {
      const newState = _.cloneDeep(state)
      const { ids, receptionId } = action.payload
      newState.receptions[receptionId].images = newState.receptions[
        receptionId
      ].images.filter((img) => !_.includes(ids, img.reception_photo_id))
      return newState
    }
    case SET_RECEPTION_TO_WORK_ORDER: {
      return {
        ...state,
        receptionToWorkOrder: action.payload,
      }
    }
    case SET_LOADING: {
      return {
        ...state,
        createReceptionLoading: action.payload,
      }
    }
    default: {
      return state
    }
  }
}
