import styled from 'styled-components'

export const SearchLabel = styled.label`
  font-size: 1em;
  font-weight: 600;
`

export const SearchBarWrapper = styled.div`
  position: relative;
  padding: 2px;

  i.fa-search {
    position: absolute;
    top: 14px;
    left: 10px;

    color: #c0c0c4;

    z-index: 20;
  }

  div.ant-select-single.ant-select-lg .ant-select-selector {
    padding-left: 32px;
    span.ant-select-selection-search input.ant-select-selection-search-input {
      padding-left: 20px;
    }
  }

  span.ant-select-selection-search input#car-plate {
    text-transform: uppercase !important;
  }
`
