import axios from 'axios'
import _ from 'lodash'

import {
  GET_PAYMENT_CONDITIONS,
  ADD_PAYMENT_CONDITIONS,
  UPDATE_PAYMENT_CONDITIONS,
  DELETE_PAYMENT_CONDITIONS,
} from '../actionTypes/PaymentConditionTypes'
import { NotificationHandler } from '../Helpers'

const getPaymentConditionAction = (payload) => ({
  type: GET_PAYMENT_CONDITIONS,
  payload,
})

const addPaymentConditionAction = (payload) => ({
  type: ADD_PAYMENT_CONDITIONS,
  payload,
})

const updatePaymentConditionAction = (payload) => ({
  type: UPDATE_PAYMENT_CONDITIONS,
  payload,
})

const deletePaymentConditionAction = (payload) => ({
  type: DELETE_PAYMENT_CONDITIONS,
  payload,
})

export const getPaymentConditions = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const quantity = _.size(
      getState().PaymentConditionReducer.paymentConditions
    )
    const paymentConditionQuantity = quantity
    if (paymentConditionQuantity === 0) {
      axios
        .get('/api/paymentCondition')
        .then((response) => {
          if (!response.data.error) {
            const paymentConditions = _.keyBy(
              response.data.data,
              'payment_condition_id'
            )
            dispatch(getPaymentConditionAction(paymentConditions))
            resolve(response.data)
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al obtener las condiciones de pago',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al obtener la condición de pago',
            error
          )
        })
    } else {
      resolve({})
    }
  })

export const addPaymentConditions = (paymentCondition) => (dispatch) =>
  new Promise((resolve, reject) => {
    axios
      .post('/api/paymentCondition', paymentCondition)
      .then((response) => {
        if (!response.data.error) {
          const paymentConditionToRedux = {
            ...response.data.data,
          }
          dispatch(addPaymentConditionAction(paymentConditionToRedux))
          resolve(response)
          NotificationHandler('Done', 'info', 'Condiciones de pago agregada')
        } else {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al agregar condiciones de pago',
            response.data.data
          )
        }
      })
      .catch((error) => {
        reject()
        NotificationHandler(
          'Error',
          'error',
          'Error al agregar condiciones de pago',
          error
        )
      })
  })

export const updatePaymentConditions = (paymentCondition) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (paymentCondition.payment_condition_id) {
      axios
        .put(
          `/api/paymentCondition/${paymentCondition.payment_condition_id}`,
          paymentCondition
        )
        .then((response) => {
          if (!response.data.error) {
            const paymentConditionToRedux = {
              ...response.data.data,
            }
            dispatch(updatePaymentConditionAction(paymentConditionToRedux))
            resolve(response)
            NotificationHandler(
              'Done',
              'info',
              'Condición de pago actualizada',
              response.data.data
            )
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al actualizar la condición de pago',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al actualizar la condición de pago',
            error
          )
        })
    } else {
      reject()
      NotificationHandler(
        'Error',
        'error',
        `No se proporcionó id a: ${paymentCondition.payment_condition_name}`
      )
    }
  })

export const deletePaymentCondition = (paymentCondition) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (paymentCondition.payment_condition_id) {
      axios
        .delete(
          `/api/paymentCondition/${paymentCondition.payment_condition_id}`
        )
        .then((response) => {
          if (response.data.error) {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al eliminar las condiciones de pago',
              response.data.data
            )
          } else {
            dispatch(deletePaymentConditionAction(paymentCondition))
            resolve(response)
            NotificationHandler(
              'Done',
              'success',
              'Condición de pago eliminada',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al eliminar la condición de pago',
            error
          )
        })
    } else {
      reject()
      NotificationHandler(
        'Error',
        'error',
        `No se proporcionó id a: ${paymentCondition.payment_condition_name}`
      )
    }
  })
