import React from 'react'
import { PropTypes } from 'prop-types'

import { DropdownDots } from '../../Common/DropdownDots/DropdownDots'
import { TitleActionsContainer } from './TabSectionTitle.style'

export const TabSectionTitle = ({ title, dropdownItems }) => {
  return (
    <TitleActionsContainer>
      <h2 className="work-order-list__title">{title}</h2>
      <DropdownDots items={dropdownItems} />
    </TitleActionsContainer>
  )
}

export const dropdownItemShape = PropTypes.shape({
  key: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  onClickHandler: PropTypes.func.isRequired,
})

TabSectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  dropdownItems: PropTypes.arrayOf(dropdownItemShape),
}

TabSectionTitle.defaultProps = {
  dropdownItems: [],
}
