import axios from 'axios'
import _ from 'lodash'

import {
  GET_BUDGETS,
  ADD_BUDGET,
  DELETE_BUDGET,
} from '../actionTypes/BudgetTypes'
import { NotificationHandler } from '../Helpers'

const getBudgetsActions = (payload) => ({
  type: GET_BUDGETS,
  payload,
})

const addBudgetAction = (payload) => ({
  type: ADD_BUDGET,
  payload,
})

const deleteBudgetAction = (payload) => ({
  type: DELETE_BUDGET,
  payload,
})

export const getBudgets = (forceFetch) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const BudgetsQuantity = _.size(getState().BudgetReducer.budgets)
    if (BudgetsQuantity === 0 || forceFetch) {
      axios
        .get('/api/budgets')
        .then((response) => {
          if (!response.data.error) {
            const budgets = _.keyBy(response.data.data, 'budget_id')
            dispatch(getBudgetsActions(budgets))
            resolve(response.data)
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al obtener los presupuestos',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al obtener los presupuestos',
            error
          )
        })
    } else {
      resolve({})
    }
  })

export const sendBudget = (budget) =>
  new Promise((resolve, reject) => {
    axios
      .post('/api/budgets', budget)
      .then((response) => {
        if (!response.data.error) {
          const details = []
          _.forEach(budget.budgetDetails, (detail) => {
            details.push({
              budget_id: response.data.data.budget_id,
              budget_detail_item: detail.budget_detail_item,
              budget_detail_item_price: parseFloat(
                detail.budget_detail_item_price || 0
              ),
              budget_detail_workforce_id: detail.budget_detail_workforce_id,
              budget_detail_discount_workforce: parseFloat(
                detail.budget_detail_discount_workforce || 0
              ),
              budget_detail_discount: parseFloat(
                detail.budget_detail_discount || 0
              ),
              budget_detail_item_quantity: parseFloat(
                detail.budget_detail_item_quantity || 0
              ),
              budget_detail_workforce_price: parseFloat(
                detail.budget_detail_workforce_price || 0
              ),
              budget_detail_type: detail.budget_detail_type,
              budget_detail_description: detail.budget_detail_description,
            })
          })
          axios
            .post('/api/budget_details', { budget_details: details })
            .then((detailResponse) => {
              if (!detailResponse.data.error) {
                resolve(detailResponse.data)
              } else {
                reject()
              }
            })
        } else {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al agregar el presupuesto',
            response.data.data
          )
        }
      })
      .catch((error) => {
        reject()
        NotificationHandler(
          'Error',
          'error',
          'Error al agregar el presupuesto',
          error
        )
      })
  })

export const addBudget = (budget) => (dispatch, getState) =>
  sendBudget(budget).then((response) => {
    NotificationHandler('Done', 'info', 'presupuesto agregado')
    const budgetToRedux = {
      ...response.data.data,
      reception: getState().CategoryReducerr.receptions[budget.budget_id],
      employee: getState().EmployeeReducer.employees[budget.budget_id],
      package: getState().PackageReducer.packages[budget.budget_id],
    }
    dispatch(addBudgetAction(budgetToRedux))
  })

const updateBudgetDetails = (details, budgetId) =>
  new Promise((resolve, reject) => {
    const detailsPromises = []
    let addNewBudgets
    _.forEach(details, (detail) => {
      if (detail.budget_detail_id) {
        if (!detail.isDeleted) {
          detailsPromises.push(
            new Promise((resolveDetail, rejectDetail) => {
              axios
                .put(`/api/budget_details/${detail.budget_detail_id}`, detail)
                .then((detailResponse) => {
                  if (!detailResponse.data.error) {
                    resolveDetail(detailResponse.data)
                  } else {
                    rejectDetail()
                    NotificationHandler(
                      'Error',
                      'error',
                      'Error al actualizar el presupuesto',
                      detailResponse.data.data
                    )
                  }
                })
                .catch((error) => {
                  rejectDetail()
                  NotificationHandler(
                    'Error',
                    'error',
                    'Error al actualizar el presupuesto',
                    error
                  )
                })
            })
          )
        } else {
          detailsPromises.push(
            new Promise((resolveDetail, rejectDetail) => {
              axios
                .delete(
                  `/api/budget_details/${detail.budget_detail_id}`,
                  detail
                )
                .then((detailResponse) => {
                  if (!detailResponse.data.error) {
                    resolveDetail(detailResponse.data)
                  } else {
                    rejectDetail()
                    NotificationHandler(
                      'Error',
                      'error',
                      'Error al borrar detalle',
                      detailResponse.data.data
                    )
                  }
                })
                .catch((error) => {
                  rejectDetail()
                  NotificationHandler(
                    'Error',
                    'error',
                    'Error al borrar detalle',
                    error
                  )
                })
            })
          )
        }
      } else {
        const detailsToAdd = []
        detailsToAdd.push({
          budget_id: budgetId,
          budget_detail_item: detail.budget_detail_item,
          budget_detail_item_price: detail.budget_detail_item_price,
          budget_detail_workforce_id: detail.budget_detail_workforce_id,
          budget_detail_discount_workforce: parseFloat(
            detail.budget_detail_discount_workforce
          ),
          budget_detail_discount: parseFloat(detail.budget_detail_discount),
          budget_detail_item_quantity: detail.budget_detail_item_quantity,
          budget_detail_workforce_price: detail.budget_detail_workforce_price,
          budget_detail_type: detail.budget_detail_type,
          budget_detail_description: detail.budget_detail_description,
        })
        addNewBudgets = new Promise((resolveAdd, rejectAdd) => {
          axios
            .post('/api/budget_details', { budget_details: detailsToAdd })
            .then((detailResponse) => {
              if (!detailResponse.data.error) {
                resolveAdd()
              } else {
                rejectAdd()
                NotificationHandler(
                  'Error',
                  'error',
                  'Error al actualizar el detalle del presupuesto',
                  detailResponse.data
                )
              }
            })
        })
      }
      Promise.all([...detailsPromises, addNewBudgets])
        .then(() => {
          resolve()
        })
        .catch(() => {
          reject()
        })
    })
  })
export const updateBudget = (budget) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (budget.budget_id) {
      axios
        .put(`/api/budgets/${budget.budget_id}`, budget)
        .then((response) => {
          if (!response.data.error) {
            updateBudgetDetails(budget.budgetDetails, budget.budget_id).then(
              () => {
                dispatch(getBudgets(true))
                resolve()
              }
            )
            resolve(response)
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al actualizar el presupuesto',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al actualizar el presupuesto',
            error
          )
        })
    } else {
      reject()
      NotificationHandler(
        'Error',
        'error',
        `No se proporcionó id a: ${budget.budget_name}`
      )
    }
  })

export const deleteBudget = (budget) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (budget.Budget_id) {
      axios
        .delete(`/api/budgets/${budget.Budget_id}`)
        .then((response) => {
          if (!response.data.error) {
            dispatch(deleteBudgetAction(budget))
            resolve(response)
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al eliminar el presupuesto',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al eliminar el presupuesto',
            error
          )
        })
    } else {
      reject()
      NotificationHandler(
        'Error',
        'error',
        `No se proporcionó id a: ${budget.Budget_name}`
      )
    }
  })
