import React, { useState } from 'react'
import { Select, Spin } from 'antd'
import PropTypes from 'prop-types'

import SelectFooterButton from '../../App/Common/SelectFooterButton/SelectFooterButton'

const CustomSelect = ({
  id,
  items, // Array of {value: '', label: ''} objects for select
  value,
  isLoading,
  isAutoFocus,
  addItemText,
  label,
  defaultValue,
  placeholder,
  disabled,
  handleValueChange,
  handleOnAdd,
  required,
}) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false)

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  const handleDropdownVisibleChange = (open) => {
    setDropdownOpen(open)
  }

  const handleAddElement = () => {
    setDropdownOpen(false)
    handleOnAdd()
  }
  return (
    <div className="custom-select">
      {label && (
        <label htmlFor={id} className="custom-select__label">
          {label}
          {required && !value?.value ? '*' : ''}
        </label>
      )}
      <Select
        id={id}
        size="large"
        value={value}
        disabled={disabled}
        defaultValue={defaultValue}
        onDropdownVisibleChange={handleDropdownVisibleChange}
        open={isDropdownOpen}
        placeholder={placeholder}
        style={{ width: '100%', fontSize: '14px' }}
        onChange={handleValueChange}
        defaultActiveFirstOption={false}
        showSearch={!value?.value}
        placement="bottomLeft"
        notFoundContent={isLoading ? <Spin size="small" /> : <></>}
        options={items}
        labelInValue
        autoFocus={isAutoFocus}
        optionFilterProp="children"
        filterOption={filterOption}
        listHeight="13vh"
        dropdownRender={(menu) => (
          <>
            {menu}
            {addItemText && (
              <SelectFooterButton
                buttonText={addItemText}
                onClickHandler={handleAddElement}
              />
            )}
          </>
        )}
      />
    </div>
  )
}
export default CustomSelect

CustomSelect.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([undefined])]),
  isLoading: PropTypes.bool,
  addItemText: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.object,
  isAutoFocus: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  handleValueChange: PropTypes.func.isRequired,
  handleOnAdd: PropTypes.func,
  required: PropTypes.bool,
}

CustomSelect.defaultProps = {
  addItemText: '',
  isLoading: false,
  placeholder: 'Selecciona una opción',
  label: '',
  defaultValue: undefined,
  isAutoFocus: false,
  disabled: false,
  handleOnAdd: () => true,
  value: { value: '', label: '' },
  required: false,
}
