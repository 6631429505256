import styled from 'styled-components'

export const ConfirmModalBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;

  text-align: center;

  i {
    color: #efc900;
    font-size: 3rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
  }

  span {
    margin-bottom: 1rem;
  }
`

export const UploadVideoLoader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 16px;

  p {
    font-size: 13px;
  }
`
