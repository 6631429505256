import paginationFactory from 'react-bootstrap-table2-paginator'
import { Grid, Row, Col, Panel, Button } from 'react-bootstrap'
import filterFactory from 'react-bootstrap-table2-filter'
import BootstrapTable from 'react-bootstrap-table-next'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { MenuOutlined, PlusCircleFilled } from '@ant-design/icons';
import {
  Container,
  ContainerHeader,
  BodyContent,
  showConfirmDialog,
  PanelHeader,
} from '../../Common/index'
import getColumns from './EmployeeColumns'
import EmployeeModal from './EmployeeModal'
import {
  getEmployees,
  addEmployee,
  updateEmployee,
  deleteEmployee,
} from '../../../../actions/EmployeeAction'
import { getRoles } from '../../../../actions/RolAction'
import { BREAK_POINTS_DESKTOP_FIRST } from '../../../../global/css/breakPoints'
import { AppLoader } from '../../Common/AppLoader'

const SCREEN_ID = 9

class EmployeeContainer extends Component {
  state = {
    showModal: false,
    loading: true,
    selectedEmployee: {},
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions)
    const { getAllEmployees, getAllRoles } = this.props
    getAllEmployees()
    this.setStateForLoading()
    getAllRoles()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  setStateForLoading = () => {
    this.setState({
      loading: false,
    })
  }

  updateDimensions = () => {
    const { width } = window.screen
    this.setState({ width })
  }

  toggleModal = () => {
    const { showModal } = this.state
    this.setState({ showModal: !showModal })
  }

  addEmployee = () => {
    this.setState({ selectedEmployee: {} })
    this.toggleModal()
  }

  updateEmployee = (employee) => {
    this.setState({ selectedEmployee: employee })
    this.toggleModal()
  }

  deleteEmployee = (employee) => {
    const { removeEmployee } = this.props
    showConfirmDialog(
      '¿Eliminar?',
      `¿Desea eliminar los datos del empleado ${employee.employee_name}?`,
      removeEmployee,
      employee
    )
  }

  render() {
    const { loading, selectedEmployee, showModal, width } = this.state
    const {
      roles,
      employees,
      permissions,
      createEmployee,
      modifyEmployee,
      workshop_id,
      currentUser,
      workshopCountryCode
    } = this.props
    const { can_write, can_edit, can_delete } = permissions[SCREEN_ID]
    const pagePermissions = {
      can_write,
      can_edit,
      can_delete,
    }
    return (
      <Container>
        <ContainerHeader title="Empleados" />
        <BodyContent key="cont">
          <Grid fluid>
            <Row>
              <Col sm={10} xs={12} md={12} key="k-22">
                <Panel>
                  <Panel.Heading bsClass="panel-heading">
                    <Panel.Title>
                      <PanelHeader>
                        <div>
                          <p id="title">
                            <MenuOutlined style={{ marginRight: '7px' }} /> Administrar Empleados
                          </p>
                        </div>
                        <div>
                          {!!can_write && (
                            <Button
                              onClick={this.addEmployee}
                              bsStyle="success"
                              className="btn-add btn-antd-icon"
                            >
                              <PlusCircleFilled />
                              Agregar registro
                            </Button>
                          )}
                        </div>
                      </PanelHeader>
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    {loading ? (
                      <AppLoader />
                    ) : (
                      <Row>
                        <Col md={10} />
                        <Col md={12}>
                          <CustomStyledTable>
                            <BootstrapTable
                              noDataIndication="Sin datos para mostrar"
                              keyField="employee_id"
                              data={_.values(employees).reverse()}
                              columns={getColumns(
                                this.updateEmployee,
                                this.deleteEmployee,
                                {
                                  can_edit,
                                  can_delete,
                                },
                                width
                              )}
                              filter={filterFactory()}
                              pagination={paginationFactory()}
                            />
                          </CustomStyledTable>
                        </Col>
                      </Row>
                    )}
                  </Panel.Body>
                </Panel>
              </Col>
            </Row>
          </Grid>
          <EmployeeModal
            modalOpen={showModal}
            toggleModal={this.toggleModal}
            editMode={!_.isEmpty(selectedEmployee)}
            selectedEmployee={selectedEmployee}
            workshop_id={workshop_id}
            roles={roles}
            fieldName="employee"
            fieldNameTitle="Empleado"
            addEmployee={createEmployee}
            updateEmployee={modifyEmployee}
            pagePermissions={pagePermissions}
            currentUser={currentUser}
            workshopCountryCode={workshopCountryCode}
          />
        </BodyContent>
      </Container>
    )
  }
}

const CustomStyledTable = styled.div`
  @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
    table {
      border: none;
      padding: 0px 10px;

      thead {
        tr {
          display: flex;
          flex-direction: column;

          th {
            label {
              width: 100%;
            }
          }

          th {
            border: none;
          }

          th:nth-last-child(1),
          th:nth-last-child(2) {
            display: none;
          }
        }
      }

      tbody {
        tr {
          border: 1px solid #ddd;
          display: flex;
          flex-wrap: wrap;
          border-radius: 10px;
          margin: 20px 10px;
          padding-top: 10px;
          padding-bottom: 10px;

          td {
            width: 100%;
            border: 0;
            border-right: 0px !important;
            border-left: 0px !important;
          }

          td:nth-last-child(1),
          td:nth-last-child(2) {
            width: 40%;
          }

          td::before {
            font-weight: bold;
            margin-right: 0.5rem;
          }

          td:nth-child(1)::before {
            content: 'Nombres:';
          }

          td:nth-child(2)::before {
            content: 'Apellidos:';
          }

          td:nth-child(3)::before {
            content: 'Teléfono:';
          }

          td:nth-child(4)::before {
            content: 'Documento de Identidad:';
          }
        }
      }
    }
  }
`

const mapStateToProps = (store) => ({
  employees: store.EmployeeReducer.employees,
  permissions: store.AppReducer.permissions,
  workshop_id: store.AppReducer.currentUser.workshop_id,
  roles: store.RolReducer.roles,
  currentUser: store.AppReducer.currentUser,
  workshopCountryCode: store.AppReducer.workshopCountryCode,
})

const mapDispatchToProps = (dispatch) => ({
  getAllEmployees() {
    dispatch(getEmployees())
  },
  createEmployee(employee, file) {
    return dispatch(addEmployee(employee, file))
  },

  modifyEmployee(employee) {
    return dispatch(updateEmployee(employee))
  },
  removeEmployee(employee) {
    return dispatch(deleteEmployee(employee))
  },
  getAllRoles() {
    dispatch(getRoles())
  },
})

EmployeeContainer.propTypes = {
  permissions: PropTypes.objectOf(PropTypes.object).isRequired,
  employees: PropTypes.arrayOf(PropTypes.object).isRequired,
  roles: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentUser: PropTypes.objectOf(PropTypes.any).isRequired,
  workshopCountryCode: PropTypes.objectOf(PropTypes.any).isRequired,
  workshop_id: PropTypes.number.isRequired,
  getAllEmployees: PropTypes.func.isRequired,
  createEmployee: PropTypes.func.isRequired,
  modifyEmployee: PropTypes.func.isRequired,
  removeEmployee: PropTypes.func.isRequired,
  getAllRoles: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeContainer)
