import axios from 'axios'
import _ from 'lodash'

import {
  GET_BRANDS,
  ADD_BRAND,
  UPDATE_BRAND,
  DELETE_BRAND,
} from '../actionTypes/brandTypes'
import { NotificationHandler } from '../Helpers'
import { createBrand } from '../api/newReceptionService'

const getBrandAction = (payload) => ({
  type: GET_BRANDS,
  payload,
})

const addBrandAction = (payload) => ({
  type: ADD_BRAND,
  payload,
})

const updateBrandAction = (payload) => ({
  type: UPDATE_BRAND,
  payload,
})

const deleteBrandAction = (payload) => ({
  type: DELETE_BRAND,
  payload,
})

const getBrands = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const brandsQuantity = _.size(getState().BrandReducer.brands)
    if (brandsQuantity === 0) {
      axios
        .get('/api/brands')
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            const brands = _.keyBy(response.data.data, 'brand_id')
            dispatch(getBrandAction(brands))
            resolve(response.data)
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al obtener marcas',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al obtener marcas',
            error
          )
        })
    } else {
      resolve({})
    }
  })

const addBrand = (brand) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { workshop_id } = getState().AppReducer.currentUser
    axios
      .post('/api/brands', { ...brand, workshop_id })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          dispatch(addBrandAction(response.data.data))
          resolve(response)
          NotificationHandler('Agregada', 'success', `Marca agregada`)
        } else {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al agregar la marca',
            response.data.data
          )
        }
      })
      .catch((error) => {
        reject()
        NotificationHandler(
          'Error',
          'error',
          'Error al agregar la marca',
          error
        )
      })
  })

const addReceptionBrand = (brand) => async (dispatch) => {
  try {
    const response = await createBrand(brand)

    dispatch(addBrandAction(response.data))

    NotificationHandler(
      'Marca agregada',
      'success',
      `La marca se guardó correctamente`
    )
    return response.data
  } catch (e) {
    NotificationHandler('Error', 'error', 'Error al agregar la marca')
    return e
  }
}

const updateBrand = (brand) => (dispatch) =>
  new Promise((resolve, reject) => {
    axios
      .put(`/api/brands/${brand.id}`, brand)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          dispatch(updateBrandAction(response.data.data))
          resolve(response)
          NotificationHandler('Actualizada', 'success', `Marca actualizada`)
        } else {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al actualizar la marca',
            response.data.data
          )
        }
      })
      .catch((error) => {
        reject()
        NotificationHandler(
          'Error',
          'error',
          'Error al actualizar la marca',
          error
        )
      })
  })

const deleteBrand = (brand) => (dispatch) =>
  new Promise((resolve, reject) => {
    axios
      .delete(`/api/brands/${brand.id}`)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          dispatch(deleteBrandAction(response.data.data))
          resolve(response)
          NotificationHandler('Borrada', 'success', `Marca borrada`)
        } else {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al eliminar la marca',
            response.data.data
          )
        }
      })
      .catch((error) => {
        reject()
        NotificationHandler(
          'Error',
          'error',
          'Error al eliminar la marca',
          error
        )
      })
  })

export { getBrands, addBrand, updateBrand, deleteBrand, addReceptionBrand }
