import styled from 'styled-components'
import BREAK_POINTS from '../../../../../../global/css/breakPoints'

export const SummaryCardContainer = styled.article`
  width: 100%;
  padding: 1rem 1.5rem;

  display: flex;
  align-items: center;
  justify-content: start;
  gap: 14px;

  border: solid 3px #bc134c;
  border-radius: 10px;

  margin: 3px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

  @media (${BREAK_POINTS.TABLET}) {
    justify-content: space-evenly;
    padding: 1rem 2.5rem;
    gap: 16px;
  }

  i {
    font-size: 4.5rem;
  }

  h2 {
    font-size: 1em;
    font-weight: bold;
    margin: 0;
  }
  .vehicle-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 4px;
  }
`
