import { GET_CITIES } from '../actionTypes/CityTypes'

const CityReducer = (
  state = {
    cities: {},
  },
  action
) => {
  switch (action.type) {
    case GET_CITIES: {
      return {
        ...state,
        cities: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export default CityReducer
