import React from 'react'
import { Button } from 'react-bootstrap'
import ReactToolTip from 'react-tooltip'
// import { textFilter } from 'react-bootstrap-table2-filter'

import {
  // formatterHeaderStyle,
  formatterHeaderStyleActions,
  columnStyle,
  columnHeaderStyle,
} from '../../Utilities/TableStyles'

const styleIcons = {
  fontSize: '17px',
}

/* const editButton = (editProduct, can_edit) => (cell, row) =>
  (
    <Button
      onClick={() => {
        editProduct(row)
      }}
      bsStyle="link"
      bsSize="small"
      data-tip={can_edit ? 'Modificar' : 'Ver'}
    >
      <i
        className={can_edit ? 'fas fa-pencil-alt' : 'fas fa-eye'}
        style={styleIcons}
      />
      <ReactToolTip />
    </Button>
  ) */

/* const deleteButton = (deleteProduct) => (cell, row) =>
  (
    <Button
      onClick={() => deleteProduct(row)}
      bsStyle="link"
      bsSize="small"
      style={{ color: 'red' }}
      data-tip="Eliminar"
    >
      <i className="fas fa-trash" style={styleIcons} />
      <ReactToolTip />
    </Button>
  ) */

  const addStockButton = (addStockProduct) => (cell, row) =>
  (
    <Button
      onClick={() => addStockProduct(row)}
      bsStyle="link"
      bsSize="small"
      style={{ color: 'green' }}
      data-tip="Comprar"
    >
      <i className="fas fa-shopping-cart" style={styleIcons} />
      <ReactToolTip />
    </Button>
  )

/*  const removeStockButton = (removeStockProduct) => (cell, row) =>
  (
    <Button
      onClick={() => removeStockProduct(row)}
      bsStyle="link"
      bsSize="small"
      style={{ color: 'red' }}
      data-tip="Remover stock"
    >
      <i className="fas fa-minus-circle" style={styleIcons} />
      <ReactToolTip />
    </Button>
  ) */

const getColumns = (editProduct, { can_edit }) => {
  const columns = [
    {
      dataField: 'product_name',
      text: 'Nombre',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: false /* textFilter({
        delay: 100,
        style: {
          margin: '10px 0 0 0',
          padding: '20px 2%',
        },
        placeholder: 'Buscar',
      }), */
    },
    {
      dataField: 'product_bar_code',
      text: 'Código',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: false /* textFilter({
        delay: 100,
        style: {
          margin: '10px 0 0 0',
          padding: '20px 2%',
        },
        placeholder: 'Buscar',
      }), */
    },
    {
      dataField: 'product_price',
      text: 'Precio ($)',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: false /* textFilter({
        delay: 100,
        style: {
          margin: '10px 0 0 0',
          padding: '20px 2%',
        },
        placeholder: 'Buscar',
      }), */
    },
/*     {
      dataField: 'product_quantity',
      text: 'Cantidad',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: false 
    }, */
    /* {
      dataField: 'category.category_name',
      text: 'Categoría',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: textFilter({
        delay: 100,
        style: {
          margin: '10px 0 0 0',
          padding: '20px 2%',
        },
        placeholder: 'Buscar',
      }),
    },
    {
      dataField: 'product_cost',
      text: 'Costo ($)',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: textFilter({
        delay: 100,
        style: {
          margin: '10px 0 0 0',
          padding: '20px 2%',
        },
        placeholder: 'Buscar',
      }),
    }, */


     {
      dataField: 'id1',
      className: 'test',
      text: '',
      headerStyle: formatterHeaderStyleActions,
      style: columnHeaderStyle({ textAlign: 'center' }),
      formatter: addStockButton(editProduct, can_edit),
    },

    /*  {
      dataField: 'id3',
      className: 'test',
      text: '',
      headerStyle: formatterHeaderStyleActions,
      style: columnHeaderStyle({ textAlign: 'center' }),
      formatter: addStockButton(addStockProduct),
    },
    {
      dataField: 'id4',
      className: 'test',
      text: '',
      headerStyle: formatterHeaderStyleActions,
      style: columnHeaderStyle({ textAlign: 'center' }),
      formatter: removeStockButton(removeStockProduct),
    }, */


  ]
/*   if (can_delete) {
    columns.push({
      dataField: 'id2',
      text: '',
      headerStyle: formatterHeaderStyleActions,
      style: columnHeaderStyle({ textAlign: 'center' }),
      formatter: deleteButton(deleteProduct),
    })
  } */
  return columns
}

export default getColumns
