import styled from 'styled-components'

export const HeaderItemsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ left }) => (left ? 'space-between' : 'space-around')};
  flex-wrap: wrap;
  margin-top: 10px;
`

export const SearchbarContainer = styled.div`
  float: right;
  width: 400px;
  margin-top: 5px;
  
  @media (max-width: 700px) {
    width: 100%;
  }
`
export const IconH1 = styled.h1`
  float: right;
  @media (max-width: 900px) {
    display: none;
  }
`

export const ComponentContainer = styled.div`
  width: 400px;
  margin-top: 20px;
`
export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`

export const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 18px;
`
