import React from 'react'
import PropTypes from 'prop-types'

import { EmptyStateContainer } from './EmptyState.style'

const EmptyState = ({ text }) => {
  return (
    <EmptyStateContainer>
      <i className="fas fa-times prefix-circle" />
      <p>{text}</p>
    </EmptyStateContainer>
  )
}

export default EmptyState

EmptyState.propTypes = {
  text: PropTypes.string.isRequired,
}
