import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Panel, Row } from 'react-bootstrap'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Tooltip, Modal, Tabs } from 'antd'
import {
  CarFilled,
  ClockCircleFilled,
  ShareAltOutlined,
} from '@ant-design/icons'
import moment from 'moment'
import axios from 'axios'
import _ from 'lodash'

import { Container, showConfirmDialog } from '../Common/index'
import LoaderDynamicText from '../Common/LoaderDynamicText'
import BREAK_POINTS, {
  BREAK_POINTS_DESKTOP_FIRST,
} from '../../../global/css/breakPoints'
import { NotificationHandler, buildTimeObjectString } from '../../../Helpers'
import {
  updateWorkOrderProcessTimeTracker,
  updateWorkOrderProcessTimeTrackerWithReturn,
  updateWorkOrderAction as updateWorkOrder,
  setWorkOrderToAdministrate,
  updateWorkOrderTimeStatus,
  finishWorkOrder,
  getCommentsWorkOrder,
  addComment,
  getWorkOrderById,
} from '../../../actions/WorkOrderAction'
import Timer from './Timer'
import PauseWorkOrderModal from './PauseWorkOrderModal'
import { AppLoader } from '../Common/AppLoader'
import Chat from './chat/Chat'
import WorkOrderPhotos from './photos/WorkOrderPhotos'
import EmptyState from '../Common/EmptyState/EmptyState'
import { ClientsUIURL, ClientsURL, multimediaTabsIds, multimediaTypes } from '../../../Constants'
import { fetchWorkOrderBudget, sendChatMessage } from '../../../api/apiService'
import CustomTabs from '../Common/CustomTabs/CustomTabs'
import DetailsPhotosIcon from '../../icons/DetailsPhotosIcon'
import InfoCard from '../Common/InfoCard/InfoCard'
import AddProductModal from '../Common/AddProductModal/AddProductModal'
import { TabSectionTitle } from './TabSectionTitle/TabSectionTitle'
import AddSomethingModal from './AddSomethingModal/AddSomethingModal'
import { getProducts } from '../../../actions/ProductActions'
import { getProcesses } from '../../../actions/ProcessAction'
import ProcessIcon from '../../icons/ProcessIcon'
import ImageBgContainer from '../Common/ImageBgContainer/ImageBgContainer'
import AddProcessModal from '../Common/AddProcessModal'
import PauseIcon from '../../icons/PauseIcon'
import PlayIcon from '../../icons/PlayIcon'
import CogsIcon from '../../icons/CogsIcon'
import BoxOpenIcon from '../../icons/BoxOpenIcon'
import ImagesIcon from '../../icons/ImagesIcon'
import DollarSignIcon from '../../icons/DollarSignIcon'
import AddPhotoModal from '../Common/AddPhotoModal/AddPhotoModal'
import BudgetTable from '../tables/BudgetTable'

// import { fuelTypes } from '../Reception/Reception/common'
import TachometerIcon from '../../icons/TachometerIcon';
import GasPumpIcon from '../../icons/GasPumpIcon';
import MotorcycleIcon from '../../icons/MotorcycleIcon';
import VideoCameraIcon from '../../icons/VideoCameraInput'
import VideoGallery from '../Common/VideosGallery/VideosGallery'
import AddVideosModal from '../Common/AddVideoModal/AddVideoModal'
import SparePartsButton from '../Common/SparePartsButton/SparePartsButton'

// SCREEN ID FOR PERMISSIONS
// const SCREEN_ID = 50

const styleIcon = {
  paddingRight: '10px',
  fontSize: '2.5rem',
  marginTop: '-1px',
}

class AdministrateWorkOrder extends Component {
  state = {
    workOrderToAdministrate: {},
    timers: [],
    timeObject: { days: 0, hours: 0, minutes: 0, seconds: 0 },
    totalTimeObject: { days: 0, hours: 0, minutes: 0, seconds: 0 },
    isTimerLoading: true,
    workOrderStillActive: false,
    workOrderPauseActive: false,
    progressTimerPercentaje: 0,
    showPauseModal: false,
    comment: null,
    activeTabKey: '1',
    activeGalleryTabKey: undefined,
    isAddModalOpen: false,
    isChatFocused: false,
    photos: [],
    videos: [],
    budget: {},
    photosLoading: false,
    videosLoading: false,
    photoPreviewOpen: false,
    videoPreviewOpen: false,
    isAddPhotoModalOpen: false,
    isAddVideoModalOpen: false,
    newPhotoAdded: false,
    newVideoAdded: false,
    isProductModalOpen: false,
    isProcessModalOpen: false,
    isBudgetModalOpen: false,
  }

  initialDate = new Date()

  constructor(props) {
    super(props)
    this.chatRef = React.createRef()
    this.tabsRef = React.createRef()
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const {
      workOrderToAdministrate,
      getAllProducts,
      getAllProcesses,
      history,
      getCommentsWorkOrderFn,
    } = this.props;

    const fromNotificationsWorkOrderId = this.props.match.params.id;

    if (fromNotificationsWorkOrderId) {
      // Fetch the work order by its ID
      this.props
        .getWorkOrder(fromNotificationsWorkOrderId)
        .then((workOrder) => {
          if (workOrder) {
            // Set the fetched work order
            this.setWorkOrderToAdministrate(workOrder)
            getCommentsWorkOrderFn(workOrder.work_order_id)
            getAllProducts()
            getAllProcesses()
            this.fetchPhotos(workOrder.work_order_id)
            this.fetchVideos(workOrder.work_order_id)
            this.fetchBudget(workOrder.work_order_id)
          } else {
            // Handle the case where the work order with the specified ID was not found
            history.push('/workOrders')
          }
        })
    } else if (
      workOrderToAdministrate !== undefined &&
      Object.keys(workOrderToAdministrate).length > 0
    ) {
      this.setState({ isAddModalOpen: workOrderToAdministrate.isAddModalOpen || false });
      this.setWorkOrderToAdministrate(workOrderToAdministrate);
      getCommentsWorkOrderFn(workOrderToAdministrate.work_order_id);
    } else {
      history.push('/workOrders')
    }

    if (workOrderToAdministrate?.workOrderStatus?.work_order_status_id === 4) {
      this.setState({
        totalTimeObject: this.minutesToTimeObject(
          workOrderToAdministrate.work_order_time
        ),
      });
    }

    getAllProducts()
    this.fetchMultimedia(workOrderToAdministrate.work_order_id)
    this.fetchBudget(workOrderToAdministrate.work_order_id)
  }

  componentDidUpdate(prevProps) {
    // Check if the work order's timeTracker has changed
    const { workOrderToAdministrate } = this.props;
    const { workOrderToAdministrate: prevWorkOrderToAdministrate } = prevProps;
    if (
      workOrderToAdministrate !== null &&
      prevWorkOrderToAdministrate !== undefined &&
      workOrderToAdministrate.timeTracker !== prevWorkOrderToAdministrate.timeTracker
    ) {
      this.setWorkOrderToAdministrate(workOrderToAdministrate);
    }
  }

  componentWillUnmount() {
    this.stopTimers()
  }

  setWorkOrderToAdministrate = (workOrder) => {
    let newWorkOrderToAdministrate = {}
    const { timeTracker, work_order_end } = workOrder
    const orderedTimeTracker = _.values(
      _.orderBy(timeTracker, ['time_tracker_sequence'], ['asc'])
    )
    const processes = orderedTimeTracker.filter((time) => time.process)

    // 1 = in process
    const currentActiveProcess = processes.find(
      (process) => process.process_status === 1
    )
    // 2 = in pause
    const pauseActiveProcess = processes.find(
      (process) => process.process_status === 2
    )

    // 0 = without processing
    const nextInactiveProcess = processes.find(
      (process) => process.process_status === 0
    )

    if (
      !currentActiveProcess &&
      !nextInactiveProcess &&
      !work_order_end &&
      !pauseActiveProcess
    ) {
      this.setState({
        workOrderStillActive: true,
        workOrderPauseActive: false,
      })
      this.startProgressTimerCount()
    } else if (pauseActiveProcess) {
      this.setState({
        workOrderStillActive: false,
        workOrderPauseActive: true,
        // Calculates total time when paused
        totalTimeObject: this.minutesToTimeObject(
          this.getRemainingTotalTime(workOrder) +
          (pauseActiveProcess?.process?.process_estimated_time -
            pauseActiveProcess?.process_real_time)
        ),
        isTimerLoading: false,
      })
      this.stopTimers()
    } else {
      // Not started order
      this.setState({
        workOrderStillActive: false,
        workOrderPauseActive: false,
        totalTimeObject: this.minutesToTimeObject(workOrder.work_order_time),
      })
    }

    const products = _.filter(workOrder.details, (detail) => detail.product)

    newWorkOrderToAdministrate = {
      ...workOrder,
      timeTracker: processes,
      currentActiveProcess,
      nextInactiveProcess,
      products,
    }
    this.setState(
      {
        workOrderToAdministrate: newWorkOrderToAdministrate,
      },
      this.startDownTimerCount
    )
  }

  setActiveTabKey = async (tab, galleryTab = multimediaTabsIds.PHOTOS) => {
    const { getWorkOrder, setWorkOrderToAdministrateAction } = this.props
    const { workOrderToAdministrate } = this.state
    const workOrder = await getWorkOrder(workOrderToAdministrate.work_order_id)
    await setWorkOrderToAdministrateAction(workOrder)
    this.setWorkOrderToAdministrate(workOrder)

    if (tab === '3') {
      if (galleryTab === multimediaTabsIds.VIDEOS) {
        this.setState({
          newVideoAdded: true,
        })

        await this.fetchVideos(workOrder.work_order_id)
        this.setState({
          activeGalleryTabKey: multimediaTabsIds.VIDEOS,
        })
      } else {
        await this.fetchPhotos(workOrder.work_order_id)
        this.setState({
          newPhotoAdded: true,
          activeGalleryTabKey: multimediaTabsIds.PHOTOS,
        })
      }
    }

    this.setState({ activeTabKey: tab })
    if (this.tabsRef.current) {
      this.tabsRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  scrollToChat = () => {
    if (this.chatRef.current) {
      this.chatRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    this.setState({ isChatFocused: true })
  }

  setChatFocus = (value) => {
    this.setState({ isChatFocused: value })
  }

  fetchBudget = async (workOrderId) => {
    try {
      const budgetData = await fetchWorkOrderBudget(workOrderId);
      this.setState({ budget: budgetData });
    } catch (error) {
      NotificationHandler('Error', 'error', 'Error al obtener el presupuesto');
    }
  };

  getPhotosAndVideos = (multimediaArray) => {
    const foundVideos = []
    const foundPhotos = []

    multimediaArray.forEach((resource) =>
      resource.type === multimediaTypes.VIDEO
        ? foundVideos.push(resource)
        : foundPhotos.push(resource)
    )

    return { foundVideos, foundPhotos }
  }

  fetchMultimedia = async (workOrderId) => {
    this.setState({ photosLoading: true, videosLoading: true })
    try {
      const response = await axios.get(
        `${ClientsURL}/api/client/work-order-detail/${workOrderId}`
      )
      const { data } = response
      const multimedia = this.getPhotosAndVideos(data.receptionPhotos)
      this.setState({
        photos: multimedia.foundPhotos,
        videos: multimedia.foundVideos,
      })
    } catch (error) {
      console.log('Failed to fetch multimedia')
      console.log(error)
    } finally {
      this.setState({ photosLoading: false, videosLoading: false })
    }
  }



  fetchPhotos = async (workOrderId) => {
    try {
      this.setState({ photosLoading: true })
      const response = await axios.get(
        `${ClientsURL}/api/client/work-order-detail/${workOrderId}`
      )

      if (response.status === 200) {
        const { data } = response
        this.setState({
          photos: data.receptionPhotos.filter(
            (resource) => resource.type === multimediaTypes.IMAGE
          ),
        })
      } else {
        console.log('Failed to fetch photos')
      }
    } catch (error) {
      console.log('Failed to fetch photos')
    } finally {
      this.setState({ photosLoading: false })
    }
  }

  fetchVideos = async (workOrderId) => {
    this.setState({ videosLoading: true })
    try {
      const response = await axios.get(
        `${ClientsURL}/api/client/work-order-detail/${workOrderId}`
      )
      const { data } = response
      this.setState({
        videos: data.receptionPhotos.filter(
          (resource) => resource.type === multimediaTypes.VIDEO
        ),
      })
    } catch (error) {
      console.log('Failed to fetch videos')
    } finally {
      this.setState({ videosLoading: false })
    }
  }

  togglePhotoPreview = () => {
    this.setState((prevState) => ({
      photoPreviewOpen: !prevState.photoPreviewOpen,
    }))
  }

  toggleVideoPreview = () => {
    this.setState((prevState) => ({
      videoPreviewOpen: !prevState.videoPreviewOpen,
    }))
  }

  getClassNameStatusColor = (status) => {
    let modifier = ''

    switch (status) {
      case 1: {
        modifier = '--in-process'
        break
      }
      case 2: {
        modifier = '--in-pause'
        break
      }
      case 3: {
        modifier = '--finished'
        break
      }
      default: {
        modifier = '--not-started'
        break
      }
    }

    return modifier
  }

  setOpenAddModal = (value) => {
    this.setState({ isAddModalOpen: value })
  }

  setIsAddPhotoModalOpen = (value) => {
    this.setState({ isAddPhotoModalOpen: value })
  }

  setIsAddVideoModalOpen = (value) => {
    this.setState({ isAddVideoModalOpen: value })
  }

  handleProductModalOpen = (value) => {
    this.setState({ isProductModalOpen: value })
  }

  handleProcessModalOpen = (value) => {
    this.setState({ isProcessModalOpen: value })
  }

  handleBudgetModalOpen = async (value) => {
    const { workOrderToAdministrate } = this.state
    if (value === true) {
      await this.fetchBudget(workOrderToAdministrate.work_order_id)
    }
    this.setState({ isBudgetModalOpen: value })
  }

  addMinutesToTimeObject = (timeObj, minutesToAdd) => {
    const { days, hours, minutes, seconds } = timeObj

    // Calculate the total minutes
    const totalMinutes = days * 24 * 60 + hours * 60 + minutes

    // Add the minutes
    const newTotalMinutes = totalMinutes + minutesToAdd

    // Calculate days, hours, and minutes for the new total
    const newDays = Math.floor(newTotalMinutes / (24 * 60))
    const newHours = Math.floor((newTotalMinutes % (24 * 60)) / 60)
    const newMinutes = newTotalMinutes % 60

    // Create and return the new time object
    const newTimeObject = {
      days: newDays,
      hours: newHours,
      minutes: newMinutes,
      seconds,
    }

    return newTimeObject
  }

  minutesToTimeObject = (mins) => {
    const days = Math.floor(mins / (24 * 60))
    const hours = Math.floor((mins % (24 * 60)) / 60)
    const minutes = mins % 60

    return {
      days,
      hours,
      minutes,
      seconds: 0,
    }
  }

  handleTabChange = (key) => {
    this.setState({ activeTabKey: key, newVideoAdded: false })
  }

  handleGalleryTabChange = (key) => {
    this.setState({ activeGalleryTabKey: key })
  }

  startTimeTrackerProcess = async () => {
    const {
      workOrderToAdministrate: { nextInactiveProcess },
    } = this.state

    if (nextInactiveProcess === undefined || nextInactiveProcess === null) {
      return
    }

    const newNextInactiveProcess = {
      ...nextInactiveProcess,
      process_status: 1,
    }
    // console.log(">>>Comparar: ", newNextInactiveProcess);
    const { updateWorkOrderProcessTimeTrackerAction } = this.props

    await updateWorkOrderProcessTimeTrackerAction(newNextInactiveProcess)
  }

  applyRealTimeToProcess = async (process, status, time) => {
    const modifiedProcess = {
      ...process,
      process_status: status,
      process_real_time: time,
    }

    const { updateWorkOrderProcessTimeTrackerAction } = this.props
    // console.log(">>>>>> Aplicando tiempo real al proceso", modifiedProcess);
    await updateWorkOrderProcessTimeTrackerAction(modifiedProcess)
  }

  applyRealTimeToProcessNoStatus = async (process, time) => {
    const modifiedProcess = {
      ...process,
      process_real_time: time,
    }

    const { updateWorkOrderProcessTimeTrackerAction } = this.props
    // console.log(">>>>>> Aplicando tiempo real al proceso", modifiedProcess);
    await updateWorkOrderProcessTimeTrackerAction(modifiedProcess)
  }

  finishActiveTimeTrackerProcess = async () => {
    // AQUÍ!!
    const {
      workOrderToAdministrate: { currentActiveProcess },
    } = this.state

    if (currentActiveProcess === undefined || currentActiveProcess === null) {
      return
    }

    const { updateWorkOrderProcessTimeTrackerAction } = this.props
    const newCurrentActiveProcess = {
      ...currentActiveProcess,
      process_status: 3,
    }
    // console.log("Sending to finalice currentActiveProcess: ", currentActiveProcess);
    // console.log("Modified: ", newCurrentActiveProcess);

    await updateWorkOrderProcessTimeTrackerAction(newCurrentActiveProcess).then(
      () => {
        // console.log("Timer stops");
        this.stopTimers()
      }
    )
  }

  startDownTimerCount = () => {
    const { workOrderToAdministrate, timers } = this.state

    if (
      workOrderToAdministrate.currentActiveProcess &&
      workOrderToAdministrate.work_order_status_id !== 2
    ) {
      // console.log("Revisando procesos completados mientras no estaba en la pantalla, solo se evaluan los tipo 1, en proceso, por eso no se toman en cuenta los 3 que son los siguientes");
      this.stopTimers()
      const timer = setInterval(
        () => this.manageCountDownTimerProcesses(),
        1000
      )

      this.setState({
        timers: [...timers, timer],
      })
    }
  }

  // https://www.w3schools.com/howto/tryit.asp?filename=tryhow_js_countdown
  getCountDownTime = (countDownDate, process_estimated_time) => {
    const now = new Date().getTime()

    const estimatedSeconds = process_estimated_time * 60000

    const difference = countDownDate - now

    const progressTimerPercentaje = (difference / estimatedSeconds) * 100

    const days = Math.floor(difference / (1000 * 60 * 60 * 24))
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    )
    // Add 1 minute because the timer shows one less
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((difference % (1000 * 60)) / 1000)
    return {
      difference,
      timeObject: { days, hours, minutes, seconds },
      progressTimerPercentaje,
    }
  }

  getRemainingTotalTime = (workOrder) => {
    return workOrder.timeTracker.reduce(
      (accumulator, currentValue) =>
        currentValue.time_tracker_start === null
          ? accumulator + currentValue.process.process_estimated_time
          : accumulator,
      0
    )
  }

  manageCountDownTimerProcesses = async () => {
    const {
      workOrderToAdministrate,
      workOrderToAdministrate: { currentActiveProcess },
      // workOrderStartDatetime
    } = this.state

    if (currentActiveProcess) {
      const {
        process: { process_estimated_time },
      } = currentActiveProcess

      // console.log("Proceso activo: ", currentActiveProcess);

      let currentProcessDatetime = ''

      // Cuando hay una pausa se setea el real_time en TIME_TRACKER, si es así el tiempo restante se calcula restando esos minutos redondos del tiempo del proceso
      // Aquí se usa el momento actual - el tiempo del proceso PERO se considera si estuvo pausado
      if (currentActiveProcess.process_real_time !== 0) {
        // Si el proceso estuvo pausado
        // console.log("Seteando tiempo actual del proceso");
        currentProcessDatetime = new Date(
          moment(currentActiveProcess.time_tracker_start).add(
            parseInt(
              process_estimated_time - currentActiveProcess.process_real_time,
              10
            ),
            'm'
          )
        ).getTime()
      } else {
        // Pero si no, se calcula basado en el momento actual - la duración del proceso
        // sacar del estado el timeToDiscount
        // console.log("Calculando tiempo actual del proceso");
        currentProcessDatetime = new Date(
          moment(currentActiveProcess.time_tracker_start).add(
            parseInt(process_estimated_time, 10),
            'm'
          )
        ).getTime()

        // BE, si aquí el tiempo da negativo, el proceso se termina, se guarda lo que queda y se le resta al siguiente proceso
      }

      // process_estimated_time - (currentActiveProcess.process_real_time))
      // Math.trunc(differences / 60000)
      const { difference, timeObject, progressTimerPercentaje } =
        this.getCountDownTime(currentProcessDatetime, process_estimated_time)

      // Minutes of processes that are not started yet
      const remainingTime = this.getRemainingTotalTime(workOrderToAdministrate)
      const totalRemainingTimeObj = this.addMinutesToTimeObject(
        timeObject,
        remainingTime
      )

      // const m = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
      // const s = Math.floor((difference % (1000 * 60)) / 1000)
      // console.log("Falta para terminar el proceso activo actual ", m, " minutos y ", s, " segundos");

      // console.log(">>>>> timeObject: ", timeObject);
      this.setState({
        timeObject,
        progressTimerPercentaje,
        isTimerLoading: false,
        totalTimeObject: totalRemainingTimeObj,
      }) // Render del timer

      if (difference < 0) {
        // ya el tiempo sobrepasa el tiempo estimado del proceso, esto sirve para detectar que el último proceso en el que quedó la orden en la pantalla como activo, ya termino basado en el tiempo real transcurrido, esto me sirve por que la diferencia es negativa

        // Calcular una nueva diferencia que no tenga 1 hora y 1 minuto con segundos correctos
        // El POSIBLE inicio para el nuevo proceso será en este instante
        // por cada proceso no iniciado que falte, en un foreach
        const now = new Date().getTime()

        // console.log("Transformando tiempo negativo en tiempo a descontar de siguientes procesos");

        const correctDifference = now - currentProcessDatetime
        const daysX = Math.floor(correctDifference / (1000 * 60 * 60 * 24))
        const hoursX = Math.floor(
          (correctDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        )
        const minutesX = Math.floor(
          (correctDifference % (1000 * 60 * 60)) / (1000 * 60)
        )

        // const secondsX = Math.floor((correctDifference % (1000 * 60)) / 1000)
        // console.log("Tiempo transcurrido desde que se finalizo el proceso anterior ", hoursX, " horas , ", minutesX, " minutos y ", secondsX, " segundos");

        // Ya que la diferencia es negativa, definitivamente se completa el actual
        // y después es necesario sacar los procesos que no estan iniciados

        const minutesToApply =
          minutesX +
          (hoursX > 0 ? hoursX * 60 : 0) +
          (daysX > 0 ? daysX * hoursX * 60 : 0)

        const unstartedAndUnfinishedProcesses =
          workOrderToAdministrate.timeTracker.filter(
            (time) => time.process_status === 0 || time.process_status === 1
          )

        if (unstartedAndUnfinishedProcesses.length > 1) {
          // cambiar?
          // Si entro aquí es porque este proceso se finaliza al 100%, mandar como process_real_time el estimated_time
          await this.finishActiveTimeTrackerProcess() // se detiene el actual y se pasa al siguiente PERO debo calcular si el tiempo extra que hizo negativo sirve para completar otros procesos

          const discountMinutesToEachProcess = minutesToApply - 1

          if (discountMinutesToEachProcess > 0) {
            let x = discountMinutesToEachProcess
            let firstLoop = false
            unstartedAndUnfinishedProcesses.forEach((processElement) => {
              if (firstLoop) {
                // const statusProcess = processElement.process_status
                const assignedTimeToProcess =
                  processElement.process.process_estimated_time
                // console.log("Tiempo asignado al proceso ", assignedTimeToProcess);

                const resultToDiscountToAssignedTimeToProcess =
                  assignedTimeToProcess - x

                let discountTimeToProcess = 0
                if (resultToDiscountToAssignedTimeToProcess < 0) {
                  discountTimeToProcess = assignedTimeToProcess
                  x = resultToDiscountToAssignedTimeToProcess * -1
                } else {
                  discountTimeToProcess = x
                  x = 0
                }
                // console.log("Tiempo a descontar al proceso ", discountTimeToProcess);

                // API
                this.applyRealTimeToProcess(
                  processElement,
                  0,
                  discountTimeToProcess
                ) // aún no han sido iniciados
              } /* else{
                const assignedTimeToProcess = processElement.process.process_estimated_time
                console.log("Tiempo asignado al proceso ACTUAL", assignedTimeToProcess);
                this.applyRealTimeToProcessNoStatus(processElement, assignedTimeToProcess)
              } */
              firstLoop = true
            })

            this.startTimeTrackerProcess() // Solo lo finalizo, pero ya arriba asigne los correctos real time de cada uno
          } else {
            this.startTimeTrackerProcess()
          }
        } else {
          // significa que era el último proceso
          await this.finishActiveTimeTrackerProcess()
          this.startProgressTimerCount()
          this.setState({ workOrderStillActive: true })
        }
      }
    }
  }

  startProgressTimerCount = () => {
    const { timers } = this.state
    this.stopTimers()

    const timer = setInterval(() => this.manageCountProgressTimer(), 1000)

    this.setState({
      timers: [...timers, timer],
    })
  }

  getProgressTime = (workOrderStartDatetime, workOrderEstimatedTime) => {
    // https://stackoverflow.com/a/1197947
    let workOrderTotalTime = new Date(workOrderStartDatetime)

    workOrderTotalTime.setMinutes(
      workOrderTotalTime.getMinutes() + parseInt(workOrderEstimatedTime, 10)
    )

    workOrderTotalTime = workOrderTotalTime.getTime()

    const now = new Date().getTime()

    const difference = now - workOrderTotalTime
    const days = Math.floor(difference / (1000 * 60 * 60 * 24))
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    )
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((difference % (1000 * 60)) / 1000)

    return {
      timeObject: { days, hours, minutes, seconds },
    }
  }

  manageCountProgressTimer = () => {
    const { workOrderToAdministrate } = this.state
    if (workOrderToAdministrate.work_order_status_id !== 2) {
      const { timeObject } = this.getProgressTime(
        workOrderToAdministrate.work_order_start,
        workOrderToAdministrate.work_order_time -
        workOrderToAdministrate.work_order_real_time
      )
      this.setState({ timeObject, isTimerLoading: false })
    }
  }

  stopTimers = () => {
    const { timers } = this.state
    // console.log(timers);
    if (timers.length > 0) {
      timers.map((timer) => clearInterval(timer))
      this.setState({ timers: [] })
      return Promise.resolve('Timers have been removed')
    }

    return Promise.resolve("Doesn't exist timers to remove")
  }

  startWorkOrder = async () => {
    try {
      this.startTimeTrackerProcess()

      const { workOrderToAdministrate } = this.state
      const { updateWorkOrderTimeStatusAction, currentUser } = this.props

      // To update work_order principal "time-tracker"
      updateWorkOrderTimeStatusAction({
        work_order_id: workOrderToAdministrate.work_order_id,
        work_order_start: moment(),
        work_order_status_id: 1,
      })

      // Sends message only if order is not started
      if (workOrderToAdministrate.work_order_status_id === 4) {
        await sendChatMessage(
          '¡La orden de trabajo ha sido iniciada!',
          currentUser.employee_id,
          workOrderToAdministrate.work_order_id
        )
      }
    } catch (error) {
      NotificationHandler('Error', 'error', error.message || 'Error')
    }
  }

  shareWorkOrder = () => {
    const { workOrderToAdministrate } = this.state;
    const url = `${ClientsUIURL}/work-orders/work-order-detail/${workOrderToAdministrate.work_order_id}?cid=${workOrderToAdministrate.person_id}`;

    if (navigator.share) {
      navigator
        .share({
          title: 'Compartir detalle de orden de trabajo',
          url,
        })
        .then(() => {
          console.log('Shared successfully');
        })
        .catch((error) => {
          console.error('Error sharing:', error);
        });
    } else {
      // Fallback for browsers that do not support navigator.share
      // You can open the URL in a new tab/window as a fallback
      window.open(url, '_blank', 'noreferrer');
    }
  };


  startAllPendingProcesses = async () => {
    const { workOrderToAdministrate } = this.state

    const startedProcesses = workOrderToAdministrate.timeTracker.filter(
      (time) => time.process_status === 1
    )

    const unstartedProcesses = workOrderToAdministrate.timeTracker.filter(
      (time) =>
        time.process_status === 0 ||
        time.process_status === null ||
        time.process_status === 2
    )

    const orderedUnstartedProcesses = _.orderBy(unstartedProcesses, [
      'time_process_sequence',
      'asc',
    ])

    const { updateWorkOrderProcessTimeTrackerWithReturnAction } = this.props

    const recentStartedProcesses = orderedUnstartedProcesses.map((time) =>
      updateWorkOrderProcessTimeTrackerWithReturnAction({
        ...time,
        process_status: 1,
      })
    )

    return Promise.all(recentStartedProcesses)
      .then((recentStartedProcessesResponse) => {
        return Promise.resolve([
          ...startedProcesses,
          ...recentStartedProcessesResponse,
        ])
      })
      .catch((error) => Promise.reject(error))
  }

  finishWorkOrder = async () => {
    try {
      const {
        updateWorkOrderAction,
        setWorkOrderToAdministrateAction,
        updateWorkOrderTimeStatusAction,
        endWorkOrder,
      } = this.props

      const { workOrderToAdministrate } = this.state

      const startedProcesses = await this.startAllPendingProcesses()

      const finishedProcesses = await this.finishAllPendingProcesses(
        startedProcesses
      )

      const newWorkOrderToAdministrate = {
        ...workOrderToAdministrate,
        timeTracker: finishedProcesses,
        currentActiveProcess: null,
        nextInactiveProcess: null,
        work_order_end: moment(),
        work_order_status_id: 3,
        work_order_finished_date: moment(),
      }

      this.stopTimers()

      // To update work_order principal "time-tracker"
      await updateWorkOrderTimeStatusAction(
        {
          work_order_id: workOrderToAdministrate.work_order_id,
          work_order_end: moment(),
          work_order_start: workOrderToAdministrate.work_order_start,
          work_order_status_id: 3,
          work_order_finished_date: moment(),
          work_order_real_time: workOrderToAdministrate.work_order_real_time,
        },
        true
      )
      await setWorkOrderToAdministrateAction(newWorkOrderToAdministrate)
      await updateWorkOrderAction(newWorkOrderToAdministrate)
      await endWorkOrder(newWorkOrderToAdministrate)

      this.setState({ workOrderPauseActive: false })
    } catch (error) {
      NotificationHandler('Error', 'error', error.message || 'Error')
    }
  }

  finishAllPendingProcesses = async (startedProcesses) => {
    const { workOrderToAdministrate } = this.state

    if (startedProcesses.length > 0) {
      const finishedProcessesFromState =
        workOrderToAdministrate.timeTracker.filter(
          (time) => time.process_status === 3
        )

      const orderedFinishedProcessesFromState = _.orderBy(
        finishedProcessesFromState,
        ['time_process_sequence', 'asc']
      )

      const { updateWorkOrderProcessTimeTrackerWithReturnAction } = this.props

      const finishedProcesses = startedProcesses.map((time) =>
        updateWorkOrderProcessTimeTrackerWithReturnAction({
          ...time,
          process_status: 3,
        })
      )

      return Promise.all(finishedProcesses)
        .then((finishedProcessesResponse) => {
          return Promise.resolve([
            ...orderedFinishedProcessesFromState,
            ...finishedProcessesResponse,
          ])
        })
        .catch((error) => Promise.reject(error))
    }

    return Promise.resolve(workOrderToAdministrate.timeTracker)
  }

  finishConfirmation = () => {
    showConfirmDialog(
      'Finalizar',
      '¿Desea finalizar la orden de trabajo? - Todas las ordenes seran enviadas a facturación',
      this.finishWorkOrder
    )
  }

  pauseAllPendingProcesses = async (pausedProcesses, reason, comment) => {
    const { workOrderToAdministrate } = this.state

    if (pausedProcesses.length > 0) {
      const unstartedProcesses = workOrderToAdministrate.timeTracker.filter(
        (time) => time.process_status === 0 || time.process_status === null
      )

      const finishedProcesses = workOrderToAdministrate.timeTracker.filter(
        (time) => time.process_status === 3
      )

      const pausedProcessesFromState =
        workOrderToAdministrate.timeTracker.filter(
          (time) => time.process_status === 2
        )

      const orderedUnstartedProcessesFromState = _.orderBy(unstartedProcesses, [
        'time_tracker_id',
        'asc',
      ])

      const { updateWorkOrderProcessTimeTrackerWithReturnAction } = this.props

      const now = new Date().getTime()
      const startDateTime = new Date(
        pausedProcesses[0].time_tracker_start
      ).getTime()
      const difference = startDateTime - now
      const timeReal = Math.abs(Math.trunc(difference / 60000))

      const updateProcesses = pausedProcesses.map((time) =>
        updateWorkOrderProcessTimeTrackerWithReturnAction({
          ...time,
          process_status: 2,
          pause_reason_comment: comment,
          pause_reason_id: reason,
          process_real_time: pausedProcesses[0].process_real_time + timeReal,
        })
      )

      return Promise.all(updateProcesses)
        .then((pausedProcessesResponse) => {
          return Promise.resolve([
            ...finishedProcesses,
            ...pausedProcessesFromState,
            ...pausedProcessesResponse,
            ...orderedUnstartedProcessesFromState,
          ])
        })
        .catch((error) => Promise.reject(error))
    }

    return Promise.resolve(workOrderToAdministrate.timeTracker)
  }

  pauseOnlyStartedProcesses = async () => {
    const { workOrderToAdministrate } = this.state

    const pausedProcesses = workOrderToAdministrate.timeTracker.filter(
      (time) => time.process_status === 1
    )

    const orderedPausedProcesses = _.orderBy(pausedProcesses, [
      'time_process_sequence',
      'asc',
    ])

    const { updateWorkOrderProcessTimeTrackerWithReturnAction } = this.props

    const recentStartedProcesses = orderedPausedProcesses.map((time) =>
      updateWorkOrderProcessTimeTrackerWithReturnAction({
        ...time,
        process_status: 2,
      })
    )

    return Promise.all(recentStartedProcesses)
      .then((recentStartedProcessesResponse) => {
        return Promise.resolve([...recentStartedProcessesResponse])
      })
      .catch((error) => Promise.reject(error))
  }

  stopWorkOrder = async (reason, comment) => {
    try {
      const {
        updateWorkOrderAction,
        setWorkOrderToAdministrateAction,
        updateWorkOrderTimeStatusAction,
      } = this.props

      const { workOrderToAdministrate, progressTimerPercentaje } = this.state
      if (progressTimerPercentaje > 0) {
        const startedProcesses = await this.pauseOnlyStartedProcesses()

        const pausedProcesses = await this.pauseAllPendingProcesses(
          startedProcesses,
          reason,
          comment
        )

        this.stopTimers()

        // To update work_order principal "time-tracker"
        updateWorkOrderTimeStatusAction(
          {
            work_order_id: workOrderToAdministrate.work_order_id,
            work_order_end: null,
            work_order_start: workOrderToAdministrate.work_order_start,
            work_order_status_id: 2,
            work_order_real_time: workOrderToAdministrate.work_order_real_time,
          },
          true
        )

        const now = new Date().getTime()
        const startDateTime = new Date(
          workOrderToAdministrate.work_order_start
        ).getTime()
        const difference = startDateTime - now
        const timeReal = Math.abs(Math.round(difference / 60000))
        const newWorkOrderToAdministrate = {
          ...workOrderToAdministrate,
          timeTracker: pausedProcesses,
          work_order_end: null,
          work_order_status_id: 2,
          work_order_real_time:
            workOrderToAdministrate.work_order_real_time + timeReal,
        }

        await setWorkOrderToAdministrateAction(newWorkOrderToAdministrate)
        await updateWorkOrderAction(newWorkOrderToAdministrate)

        this.setState({
          workOrderPauseActive: true,
        })
      }
    } catch (error) {
      NotificationHandler('Error', 'error', error.message || 'Error')
    }
  }

  resumeAllPendingProcesses = async (resumeProcesses) => {
    const { workOrderToAdministrate } = this.state

    if (resumeProcesses.length > 0) {
      const unstartedProcesses = workOrderToAdministrate.timeTracker.filter(
        (time) => time.process_status === 0 || time.process_status === null
      )

      const finishedProcesses = workOrderToAdministrate.timeTracker.filter(
        (time) => time.process_status === 3
      )

      const resumeProcessesFromState =
        workOrderToAdministrate.timeTracker.filter(
          (time) => time.process_status === 1
        )

      const orderedResumeProcessesFromState = _.orderBy(
        resumeProcessesFromState,
        ['time_tracker_id', 'asc']
      )

      const { updateWorkOrderProcessTimeTrackerWithReturnAction } = this.props

      const updateProcesses = resumeProcesses.map((time) =>
        updateWorkOrderProcessTimeTrackerWithReturnAction({
          ...time,
          process_status: 1,
        })
      )
      return Promise.all(updateProcesses)
        .then((pausedProcessesResponse) => {
          return Promise.resolve([
            ...finishedProcesses,
            ...orderedResumeProcessesFromState,
            ...pausedProcessesResponse,
            ...unstartedProcesses,
          ])
        })
        .catch((error) => Promise.reject(error))
    }

    return Promise.resolve(workOrderToAdministrate.timeTracker)
  }

  startOnlyPausedProcesses = async () => {
    const { workOrderToAdministrate } = this.state

    const startedProcesses = workOrderToAdministrate.timeTracker.filter(
      (time) => time.process_status === 2
    )

    const orderedUnstartedProcesses = _.orderBy(startedProcesses, [
      'time_tracker_id',
      'asc',
    ])

    const { updateWorkOrderProcessTimeTrackerWithReturnAction } = this.props

    const recentStartedProcesses = orderedUnstartedProcesses.map((time) =>
      updateWorkOrderProcessTimeTrackerWithReturnAction({
        ...time,
        process_status: 1,
      })
    )

    return Promise.all(recentStartedProcesses)
      .then((recentStartedProcessesResponse) => {
        return Promise.resolve([...recentStartedProcessesResponse])
      })
      .catch((error) => Promise.reject(error))
  }

  resumeWorkOrder = async () => {
    this.setState({ isTimerLoading: true })
    try {
      const {
        updateWorkOrderAction,
        setWorkOrderToAdministrateAction,
        updateWorkOrderTimeStatusAction,
      } = this.props

      const { workOrderToAdministrate } = this.state

      const startedProcesses = await this.startOnlyPausedProcesses()

      const resumeProcesses = await this.resumeAllPendingProcesses(
        startedProcesses
      )

      const newWorkOrderToAdministrate = {
        ...workOrderToAdministrate,
        timeTracker: resumeProcesses,
        work_order_status_id: 1,
        work_order_start: moment(),
      }

      // To update work_order principal "time-tracker"
      updateWorkOrderTimeStatusAction(
        {
          work_order_id: workOrderToAdministrate.work_order_id,
          work_order_status_id: 1,
          work_order_start: moment(),
          work_order_real_time: workOrderToAdministrate.work_order_real_time,
        },
        false,
        true
      )

      await setWorkOrderToAdministrateAction(newWorkOrderToAdministrate)
      await updateWorkOrderAction(newWorkOrderToAdministrate)

      this.setState({ workOrderPauseActive: false })
    } catch (error) {
      NotificationHandler('Error', 'error', error.message || 'Error')
    }
  }

  openPauseModal = () => {
    this.togglePauseModal()
  }

  togglePauseModal = () => {
    const { showPauseModal } = this.state
    this.setState({ showPauseModal: !showPauseModal })
  }

  goToInvoice = () => {
    const { history } = this.props
    history.push('/Invoices')
  }

  onChangeComment = (e) => {
    this.setState({ comment: e.target.value })
  }

  addCommentOnEnter = (e) => {
    if (e.keyCode === 13) {
      this.addComment()
    }
  }

  addComment = () => {
    const { addCommentFn, currentUser, workOrderToAdministrate } = this.props
    const { comment } = this.state
    this.setState({ comment: '' })
    const principalProcess = workOrderToAdministrate.timeTracker.find(
      (process) => process.process_status === 1 || process.process_status === 2
    )
    const commentJson = {
      userId: currentUser.employee_id,
      workOrderId: workOrderToAdministrate.work_order_id,
      comment,
      principalProcess: principalProcess
        ? principalProcess.process.process_name
        : '',
      associatedProducts: '',
    }
    addCommentFn(commentJson)
  }

  getTimerColor = () => {
    const { progressTimerPercentaje } = this.state
    let color = ''
    if (progressTimerPercentaje >= 66) {
      color = ''
    } else if (progressTimerPercentaje >= 33) {
      color = 'warning'
    } else if (progressTimerPercentaje > 0 && progressTimerPercentaje < 33) {
      color = 'warning'
    } else {
      color = 'error'
    }

    return color
  }

  getPausedProcessName = () => {
    const paused = this.state.workOrderToAdministrate?.timeTracker?.filter(
      (timer) => timer.process_status === 2
    )

    return paused.length > 0 ? paused[0].process.process_name : ''
  }

  render() {
    const {
      workOrderToAdministrate,
      timeObject,
      totalTimeObject,
      workOrderStillActive,
      showPauseModal,
      workOrderPauseActive,
      activeTabKey,
      activeGalleryTabKey,
      isAddModalOpen,
      isChatFocused,
      photos,
      videos,
      budget,
      photosLoading,
      videosLoading,
      photoPreviewOpen,
      videoPreviewOpen,
      isAddPhotoModalOpen,
      isAddVideoModalOpen,
      newPhotoAdded,
      // eslint-disable-next-line no-unused-vars
      newVideoAdded,
      isProductModalOpen,
      isProcessModalOpen,
      isBudgetModalOpen,
    } = this.state

    const { products, allProcesses, currentUser } = this.props

    const videosGreaterQuantity = videos?.length >= photos?.length

    const productsActions = [
      {
        label: 'Agregar respuestos',
        key: '0',
        onClickHandler: () => this.handleProductModalOpen(true),
      },
    ]

    const processesActions = [
      {
        label: 'Agregar proceso',
        key: '0',
        onClickHandler: () => this.handleProcessModalOpen(true),
      },
    ]
    // TODO: These items are just for demo, each section must have their own
    const photosItems = [
      {
        label: 'Agregar fotos',
        key: '0',
        onClickHandler: () => this.setIsAddPhotoModalOpen(true),
      },
      {
        label: 'Agregar videos',
        key: '1',
        onClickHandler: () => this.setIsAddVideoModalOpen(true),
      },
    ]

    const multimediaTabs = [
      {
        key: multimediaTabsIds.PHOTOS,
        label: 'Fotografías',
        children: (
          <WorkOrderPhotosSection>
            <div className="photos-wrapper">
              <WorkOrderPhotos
                photos={photos}
                loading={photosLoading}
                newPhotoAdded={newPhotoAdded}
              />
            </div>
          </WorkOrderPhotosSection>
        ),
      },
      {
        key: multimediaTabsIds.VIDEOS,
        label: 'Videos',
        children: (
          <WorkOrderPhotosSection>
            <div>
              <VideoGallery
                videos={videos}
                loading={videosLoading}
                newVideoAdded={newVideoAdded} />
            </div>
          </WorkOrderPhotosSection>
        ),
      },
    ]

    const tabs = [
      {
        key: '1',
        label: (
          <span className='btn-svg-icon' style={{ display: 'flex', alignItems: 'center' }}>
            <CogsIcon /> Procesos
          </span>
        ),
        children: Object.keys(workOrderToAdministrate).length > 0 && (
          <ProcessInfoSection>
            <div className="work-order-list">
              <TabSectionTitle
                title="Procesos en la orden de trabajo"
                dropdownItems={processesActions}
              />
              <div className="work-order-list__states">
                <span className="work-order-list__states__state --in-process">
                  En proceso
                </span>
                <span className="work-order-list__states__state --in-pause">
                  En pausa
                </span>
                <span className="work-order-list__states__state --finished">
                  Finalizado
                </span>
              </div>
              <ContainerWithScroll>
                {workOrderToAdministrate.timeTracker.map((time, index) => (
                  <div
                    className={`work-order-list__item ${this.getClassNameStatusColor(
                      time.process_status
                    )}`}
                  >
                    <ProcessIcon className="work-order-list__item__icon" />
                    <div className="work-order-list__item__detail">
                      <b>
                        {index + 1}. {time.process.process_name}
                      </b>
                      <br />
                      <span className="work-order-list__item__detail__time">
                        <ClockCircleFilled />{' '}
                        {time.process.process_estimated_time} mins
                      </span>
                    </div>
                  </div>
                ))}
              </ContainerWithScroll>
            </div>
          </ProcessInfoSection>
        ),
      },
      {
        key: '2',
        label: (
          <span className='btn-svg-icon' style={{ display: 'flex', alignItems: 'center' }}>
            <BoxOpenIcon /> Repuestos
          </span>
        ),
        children: Object.keys(workOrderToAdministrate).length > 0 && (
          <CommonSection>
            <div className="work-order-list">
              <TabSectionTitle
                title="Repuestos en la orden de trabajo"
                dropdownItems={productsActions}
              />
              {(!workOrderToAdministrate.products ||
                workOrderToAdministrate.products.length === 0) && (
                  <EmptyState text="No existen respuestos agregados" />
                )}
              <ContainerWithScroll>
                {workOrderToAdministrate.products &&
                  workOrderToAdministrate.products.map((product) => (
                    <div className="work-order-list__item products">
                      <ProductIcon className="work-order-list__item__icon" />
                      <div className="work-order-list__item__detail">
                        <div className="work-order-list__item__detail__name">
                          <span>
                            <b>{product.product.product_name}</b>
                          </span>
                          <span>
                            {' '}
                            Cantidad: {product.work_order_detail_item_quantity}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
              </ContainerWithScroll>
            </div>
            {/* <div className="work-order-list">
              <TabSectionTitle
                title="Solicitudes de respuestos"
                dropdownItems={items}
              />
              <EmptyState text="No existen solicitudes de respuestos" />
                </div> */}
          </CommonSection>
        ),
      },
      {
        key: '3',
        label: (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                paddingRight: '5px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <DetailsPhotosIcon />
            </div>{' '}
            Galería / Detalles
          </span>
        ),
        children: Object.keys(workOrderToAdministrate).length > 0 && (
          <>
            <CommonSection>
              <div className="work-order-list">
                <TabSectionTitle
                  title="Galería y detalles del vehículo"
                  dropdownItems={photosItems}
                />

                <VehicleModelLabel>
                  {
                    workOrderToAdministrate.budgets?.reception.vehicle.model
                      .brand.brand_name
                  }

                  <span>
                    {' '}
                    {
                      workOrderToAdministrate.budgets?.reception.vehicle.model
                        .model_name
                    }{' '}
                    {
                      workOrderToAdministrate.budgets?.reception.vehicle
                        .vehicle_license_plate
                    }
                  </span>
                </VehicleModelLabel>
                <SubTabs>
                  <Tabs
                    items={
                      videosGreaterQuantity
                        ? multimediaTabs.reverse() // To show videos tab first
                        : multimediaTabs
                    }
                    activeKey={activeGalleryTabKey}
                    defaultActiveKey={
                      videosGreaterQuantity
                        ? multimediaTabsIds.VIDEOS
                        : multimediaTabsIds.PHOTOS
                    }
                    tabBarStyle={{ marginBottom: '12px' }}
                    onChange={this.handleGalleryTabChange}
                    className="details-subtabs"
                  />
                </SubTabs>
                <WorkOrderDetailsSection>
                  <div className="work-order-details">
                    <div className="work-order-details__data">
                      <DetailData>
                        <CarFilled style={{
                          fontSize: '2rem',
                          margin: '10px',
                          textAlign: 'center',
                          color: '#d60e46'
                        }} />
                        <div>
                          <p>
                            Placa:{' '}
                            <span>
                              {
                                workOrderToAdministrate.budgets?.reception
                                  .vehicle.vehicle_license_plate
                              }
                            </span>
                          </p>
                        </div>
                      </DetailData>
                      <DetailData>
                        <GasPumpIcon color="#d60e46" size="2rem" style={{
                          textAlign: 'center'
                        }} />
                        <div>
                          <p>
                            Cantidad de gasolina:
                            <span>
                              {(() => {
                                const fuelTypeName = workOrderToAdministrate.budgets.reception?.reception_fuel_amount > 0 ?
                                  `${workOrderToAdministrate.budgets.reception?.reception_fuel_amount}%` : 'Dato no disponible'
                                return fuelTypeName
                              })()}
                            </span>
                          </p>
                        </div>
                      </DetailData>
                      <DetailData>
                        <TachometerIcon size="2rem" color="#d60e46" style={{
                          textAlign: 'center'
                        }} />
                        <div>
                          <p>
                            Odómetro:
                            <span>
                              {workOrderToAdministrate.budgets?.reception
                                .reception_millage !== null
                                ? workOrderToAdministrate.budgets?.reception
                                  .reception_millage
                                : 'Dato no disponible'}
                            </span>
                          </p>
                        </div>
                      </DetailData>
                    </div>
                  </div>
                </WorkOrderDetailsSection>
              </div>
            </CommonSection>
          </>
        ),
      },
    ]

    return (
      <Container>
        <div className="content-header">
          <HeaderItemsContainer>
            <FlexBetweenContainer>
              <TitleText>
                Orden #{workOrderToAdministrate.work_order_id}
              </TitleText>
              {Object.keys(workOrderToAdministrate).length > 0 && (
                <FlexContainer>
                  <CommonButton
                    type="button"
                    $bgColor="#1E1E40"
                    className="btn-large"
                    onClick={() => this.handleBudgetModalOpen(true)}
                  >
                    <DollarSignIcon color="#FFF" size="1.75rem" />
                    Presupuesto
                  </CommonButton>
                  <Tooltip title="Compartir" placement="bottom">
                    <CommonButton
                      type="button"
                      onClick={this.shareWorkOrder}
                      className="btn-large"
                    >
                      <ShareAltOutlined style={{ fontSize: '1.75rem' }} />
                    </CommonButton>
                  </Tooltip>
                </FlexContainer>
              )}
            </FlexBetweenContainer>

            {Object.keys(workOrderToAdministrate).length > 0 && (
              <HeaderInfo>
                <VehicleInfo>
                  {this.props.currentUser.workshop.workshop_type === 1 ? (<MotorcycleIcon size="4rem" color="#333" />) : (<CarFilled style={{ fontSize: '4rem', color: "#333" }} />)}
                  <p>
                    {
                      workOrderToAdministrate.budgets?.reception.vehicle.model
                        .brand.brand_name
                    }{' '}
                    {
                      workOrderToAdministrate.budgets?.reception.vehicle.model
                        .model_name
                    }
                    <span>
                      {
                        workOrderToAdministrate.budgets?.reception.vehicle
                          .vehicle_license_plate
                      }
                    </span>
                  </p>
                </VehicleInfo>
                <StatusLabel
                  className={`${this.getClassNameStatusColor(
                    workOrderToAdministrate.workOrderStatus?.work_order_status_id
                  )}`}
                >
                  {workOrderToAdministrate.workOrderStatus
                    ?.work_order_status_id === 4
                    ? 'Sin iniciar'
                    : workOrderToAdministrate.workOrderStatus
                      ?.work_order_status_name}
                </StatusLabel>
                <SparePartsButton message={`Hola, necesito un repuesto para este vehículo: ${workOrderToAdministrate.budgets?.reception.vehicle.model.brand.brand_name} ${workOrderToAdministrate.budgets?.reception.vehicle.model.model_name} año ${workOrderToAdministrate.budgets?.reception.vehicle.vehicle_year}, en el taller ${currentUser.workshop.workshop_name}`} />

              </HeaderInfo>
            )}
          </HeaderItemsContainer>
        </div>
        <Panel.Body>
          <Row>
            {Object.keys(workOrderToAdministrate).length <= 0 ? (
              <LoaderDynamicText
                textArray={[
                  'Cargando órden de trabajo...',
                  'Preparando fotos...',
                  'Completando...',
                ]}
                colored
                interval={1}
                position="relative"
              />
            ) : (
              <WorkOrderContainer>
                <MainGridLayout>
                  <div className="background-container">
                    <ImageBgContainer
                      backgroundImage={
                        !photosLoading && photos.length > 0
                          ? photos[0].receptionPhotoUrl
                          : 'https://images4.alphacoders.com/839/thumb-1920-83944.jpg'
                      }
                    >
                      <WorkOderProcess className="work-order-process">
                        <div className="work-order-process__photo-button">
                          <Tooltip title="Ver videos">
                            <button
                              type="button"
                              onClick={this.toggleVideoPreview}
                            >
                              <VideoCameraIcon size="2rem" color="#fff" />
                            </button>
                          </Tooltip>
                          <Tooltip title="Ver fotografías">
                            <button
                              type="button"
                              onClick={this.togglePhotoPreview}
                            >
                              <ImagesIcon size="2rem" color="#fff" />
                            </button>
                          </Tooltip>
                        </div>
                        <div>
                          <p className="work-order-process">
                            {workOrderToAdministrate.currentActiveProcess &&
                              !workOrderPauseActive &&
                              'Proceso actual'}
                            {workOrderToAdministrate.nextInactiveProcess &&
                              !workOrderToAdministrate.currentActiveProcess &&
                              !workOrderPauseActive &&
                              workOrderToAdministrate.work_order_status_id ===
                              4 &&
                              'Proceso a iniciar'}
                            {workOrderToAdministrate.nextInactiveProcess &&
                              !workOrderToAdministrate.currentActiveProcess &&
                              !workOrderPauseActive &&
                              workOrderToAdministrate.work_order_status_id ===
                              1 &&
                              'Proceso adicional a iniciar'}
                            {!workOrderToAdministrate.currentActiveProcess &&
                              !workOrderToAdministrate.nextInactiveProcess &&
                              !workOrderPauseActive &&
                              'Sin procesos pendientes'}
                            {workOrderPauseActive && (
                              <>
                                <span style={{ color: '#FF6161' }}>
                                  Proceso en pausa
                                </span>
                              </>
                            )}
                          </p>
                          <SimpleFlexContainer>
                            {workOrderPauseActive && (
                              <p className="work-order-process__title">
                                <span>
                                  <ProcessIcon
                                    hexColor="#fff"
                                    className="work-order-process__icon"
                                  />
                                </span>
                                {this.getPausedProcessName()}
                              </p>
                            )}
                            {!workOrderPauseActive &&
                              workOrderToAdministrate.currentActiveProcess && (
                                <p className="work-order-process__title">
                                  <span>
                                    <ProcessIcon
                                      hexColor="#fff"
                                      className="work-order-process__icon"
                                    />
                                  </span>
                                  {
                                    workOrderToAdministrate.currentActiveProcess
                                      .process.process_name
                                  }
                                </p>
                              )}
                            {!workOrderPauseActive &&
                              workOrderToAdministrate.nextInactiveProcess &&
                              !workOrderToAdministrate.currentActiveProcess && (
                                <p className="work-order-process__title">
                                  <span>
                                    <ProcessIcon
                                      hexColor="#fff"
                                      className="work-order-process__icon"
                                    />
                                  </span>
                                  {
                                    workOrderToAdministrate.nextInactiveProcess
                                      .process.process_name
                                  }
                                </p>
                              )}
                          </SimpleFlexContainer>
                        </div>
                        <div className="work-order-process__main-process">
                          <div>
                            {!this.state.isTimerLoading &&
                              (workOrderToAdministrate.currentActiveProcess ||
                                (workOrderStillActive &&
                                  !workOrderPauseActive)) && (
                                <>
                                  {!workOrderToAdministrate.currentActiveProcess &&
                                    !workOrderToAdministrate.nextInactiveProcess ? (
                                    ''
                                  ) : (
                                    <Tooltip
                                      title="Pausar orden de trabajo"
                                      placement="right"
                                    >
                                      <ProcessButton
                                        type="button"
                                        onClick={this.openPauseModal}
                                      >
                                        <PauseIcon />
                                      </ProcessButton>
                                    </Tooltip>
                                  )}
                                </>
                              )}
                            {workOrderPauseActive && (
                              <Tooltip
                                title="Reanudar orden de trabajo"
                                placement="right"
                              >
                                <ProcessButton
                                  type="button"
                                  onClick={this.resumeWorkOrder}
                                >
                                  <PlayIcon />
                                </ProcessButton>
                              </Tooltip>
                            )}
                            {workOrderToAdministrate.nextInactiveProcess &&
                              !workOrderToAdministrate.currentActiveProcess &&
                              !workOrderPauseActive && (
                                <Tooltip title="Iniciar" placement="right">
                                  <ProcessButton
                                    type="button"
                                    onClick={this.startWorkOrder}
                                  >
                                    <PlayIcon />
                                  </ProcessButton>
                                </Tooltip>
                              )}
                            {workOrderStillActive &&
                              workOrderToAdministrate.timeTracker.length >
                              0 && (
                                <i className="fas fa-exclamation-triangle" />
                              )}
                          </div>
                          <div>
                            <p className="work-order-process__current-process__message">
                              {workOrderToAdministrate.nextInactiveProcess &&
                                !workOrderToAdministrate.currentActiveProcess &&
                                !workOrderPauseActive &&
                                `${workOrderToAdministrate.work_order_status_id ===
                                  1
                                  ? 'Iniciar los nuevos procesos'
                                  : 'Debe iniciar la orden de trabajo'
                                }`}
                            </p>
                            {!workOrderToAdministrate.currentActiveProcess &&
                              !workOrderToAdministrate.nextInactiveProcess &&
                              !workOrderPauseActive &&
                              !workOrderStillActive && (
                                <InvoiceBtn
                                  type="button"
                                  onClick={this.goToInvoice}
                                >
                                  Ir a facturacion
                                  <i
                                    className="fas fa-check"
                                    style={styleIcon}
                                  />
                                </InvoiceBtn>
                              )}
                          </div>
                        </div>
                        <CurrentProcessContainer>
                          <div className="work-order-process__current-process__actions">
                            {workOrderToAdministrate.currentActiveProcess && (
                              <div className="timer">
                                {this.state.isTimerLoading ? (
                                  <AppLoader width="100px" />
                                ) : (
                                  <>
                                    <p className="remaining">Tiempo restante</p>
                                    <Timer time={timeObject} />
                                  </>
                                )}
                              </div>
                            )}
                            {workOrderStillActive &&
                              workOrderToAdministrate.timeTracker.length >
                              0 && (
                                <div className="timer">
                                  {this.state.isTimerLoading ? (
                                    <AppLoader width="100px" />
                                  ) : (
                                    <>
                                      <p className="extra-time">
                                        Orden en tiempo extra
                                      </p>
                                      <Timer time={timeObject} />
                                    </>
                                  )}
                                </div>
                              )}
                          </div>
                        </CurrentProcessContainer>
                        {!(
                          workOrderStillActive &&
                          workOrderToAdministrate.timeTracker.length > 0
                        ) &&
                          (workOrderToAdministrate.workOrderStatus
                            .work_order_status_id === 4 ||
                            !this.state.isTimerLoading) ? (
                          <div className="work-order-process__total_time">
                            <p>
                              {`Tiempo ${
                                // Order is not started
                                workOrderToAdministrate.workOrderStatus
                                  .work_order_status_id === 4 ||
                                  // Order has been finished
                                  (!workOrderToAdministrate.currentActiveProcess &&
                                    !workOrderToAdministrate.nextInactiveProcess &&
                                    !workOrderPauseActive &&
                                    !workOrderStillActive)
                                  ? 'total'
                                  : 'restante'
                                } de la orden: `}
                              <span>
                                {/* Dont show 0 hours and  */}
                                {buildTimeObjectString(totalTimeObject) ||
                                  '0 minutos'}
                              </span>
                            </p>
                          </div>
                        ) : (
                          <div />
                        )}
                      </WorkOderProcess>
                    </ImageBgContainer>
                    <FlexColContainer $padding="2.5rem auto 2rem">
                      <Tooltip
                        title="Ahora puedes agregar videos a la orden de trabajo"
                        color="#1890ff"
                        defaultOpen
                        destroyTooltipOnHide
                        overlayInnerStyle={{ textAlign: 'center' }}
                      >
                        <AddElementButton>
                          <h2>¿Necesitas añadir algo?</h2>
                          <button
                            type="button"
                            onClick={() => this.setOpenAddModal(true)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                            >
                              <rect
                                width="32"
                                height="32"
                                rx="16"
                                fill="white"
                              />
                              <path
                                d="M16 0C7.16129 0 0 7.16129 0 16C0 24.8387 7.16129 32 16 32C24.8387 32 32 24.8387 32 16C32 7.16129 24.8387 0 16 0ZM25.2903 17.8065C25.2903 18.2323 24.9419 18.5806 24.5161 18.5806H18.5806V24.5161C18.5806 24.9419 18.2323 25.2903 17.8065 25.2903H14.1935C13.7677 25.2903 13.4194 24.9419 13.4194 24.5161V18.5806H7.48387C7.05806 18.5806 6.70968 18.2323 6.70968 17.8065V14.1935C6.70968 13.7677 7.05806 13.4194 7.48387 13.4194H13.4194V7.48387C13.4194 7.05806 13.7677 6.70968 14.1935 6.70968H17.8065C18.2323 6.70968 18.5806 7.05806 18.5806 7.48387V13.4194H24.5161C24.9419 13.4194 25.2903 13.7677 25.2903 14.1935V17.8065Z"
                                fill="#1E1E40"
                              />
                            </svg>
                          </button>
                        </AddElementButton>
                      </Tooltip>
                      {workOrderToAdministrate.budgets?.reception
                        .reception_description && (
                          <InfoCard
                            title="Observaciones"
                            limit={125}
                            text={
                              workOrderToAdministrate.budgets?.reception
                                .reception_description
                            }
                          />
                        )}
                    </FlexColContainer>
                  </div>
                  <TabsSectionContainer ref={this.tabsRef}>
                    <CustomTabs
                      activeTabKey={activeTabKey}
                      handleTabChange={this.handleTabChange}
                      tabs={tabs}
                    />
                  </TabsSectionContainer>
                  <FlexColContainer $padding="0" ref={this.chatRef}>
                    <h2
                      className="work-order-list__title"
                      style={{ marginBottom: '20px' }}
                    >
                      Conversa con el cliente
                    </h2>
                    <Chat
                      orderId={workOrderToAdministrate.work_order_id}
                      brand={
                        workOrderToAdministrate.budgets?.reception.vehicle.model
                          .brand.brand_name
                      }
                      model={
                        workOrderToAdministrate.budgets?.reception.vehicle.model
                          .model_name
                      }
                      year={
                        workOrderToAdministrate.budgets?.reception.vehicle
                          .vehicle_year
                      }
                      clientName={`${workOrderToAdministrate.budgets?.reception.payor.person_firstname} ${workOrderToAdministrate.budgets?.reception.payor.person_lastname}`}
                      currentUserId={currentUser.employee_id}
                      focused={isChatFocused}
                      setFocus={this.setChatFocus}
                    />
                  </FlexColContainer>
                  <Modal
                    title="Fotografías en recepción"
                    centered
                    open={photoPreviewOpen}
                    width={800}
                    onCancel={this.togglePhotoPreview}
                    footer={null}
                  >
                    <WorkOrderPhotos photos={photos} loading={photosLoading} />
                  </Modal>
                  <Modal
                    title="Videos en recepción"
                    centered
                    open={videoPreviewOpen}
                    width={800}
                    onCancel={this.toggleVideoPreview}
                    footer={null}
                  >
                    <VideoGallery videos={videos} loading={videosLoading} />
                  </Modal>
                </MainGridLayout>
              </WorkOrderContainer>
            )}
          </Row>
          {!this.state.isTimerLoading &&
            (workOrderToAdministrate.currentActiveProcess ||
              (workOrderStillActive && !workOrderPauseActive)) && (
              <CompleteOrderButton
                type="button"
                onClick={this.finishConfirmation}
              >
                <i className="fas fa-check" style={styleIcon} />
                Completar orden de trabajo
              </CompleteOrderButton>
            )}
        </Panel.Body>
        <PauseWorkOrderModal
          modalOpen={showPauseModal}
          toggleModal={this.togglePauseModal}
          workOrderToAdministrate={workOrderToAdministrate}
          pauseWorkOrder={this.stopWorkOrder}
        />
        <AddSomethingModal
          isOpen={isAddModalOpen}
          setOpen={this.setOpenAddModal}
          products={products}
          processes={allProcesses}
          workOrderId={workOrderToAdministrate?.work_order_id}
          receptionId={workOrderToAdministrate.budgets?.reception_id}
          workshopId={currentUser.workshop.workshop_id}
          setTab={this.setActiveTabKey}
          scrollToChat={this.scrollToChat}
        />
        <AddPhotoModal
          receptionId={workOrderToAdministrate.budgets?.reception_id}
          isOpen={isAddPhotoModalOpen}
          workshopId={currentUser.workshop.workshop_id}
          setOpen={this.setIsAddPhotoModalOpen}
          setTab={this.setActiveTabKey}
        />
        <AddVideosModal
          isOpen={isAddVideoModalOpen}
          setOpen={this.setIsAddVideoModalOpen}
          setTab={this.setActiveTabKey}
          receptionId={workOrderToAdministrate.budgets?.reception_id}
          workshopId={currentUser.workshop.workshop_id}
        />
        <AddProductModal
          isOpen={isProductModalOpen}
          setOpen={this.handleProductModalOpen}
          setTab={this.setActiveTabKey}
          products={products}
          workOrderId={workOrderToAdministrate?.work_order_id}
        />
        <AddProcessModal
          isOpen={isProcessModalOpen}
          setOpen={this.handleProcessModalOpen}
          setTab={this.setActiveTabKey}
          processes={allProcesses}
          workOrderId={workOrderToAdministrate?.work_order_id}
        />
        <Modal
          title={
            <h3 style={{ marginBottom: '2rem' }}>Detalle de la factura</h3>
          }
          open={isBudgetModalOpen}
          onCancel={() => this.handleBudgetModalOpen(false)}
          footer={null}
          width="825px"
          centered
        >
          <BudgetTable data={budget.detail} total={budget.total} />
        </Modal>
      </Container>
    )
  }
}

const MainGridLayout = styled.div`
  display: flex;
  padding: 8px 12px;
  width: 100%;
  flex-direction: column;
  gap: 30px;

  @media (${BREAK_POINTS.DESKTOP}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    padding: 16px 20px;
    gap: 20px;
  }

  .background-container {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;

    section {
      flex: 1;
    }
  }
`

const TabsSectionContainer = styled.div`
  grid-area: 1 / 2 / 3 / 3;
  display: flex;
  justify-content: stretch;

  /* To change styles of antd tabs component in order to take all available space */
  & > :first-child {
    display: flex;
    flex: 1;

    .ant-tabs-tabpane.ant-tabs-tabpane-active {
      flex: 1;
    }

    & > :nth-child(2) {
      display: flex;
      flex-direction: column;
      flex: 1;

      .ant-tabs-content.ant-tabs-content-top {
        display: flex;
        flex-direction: column;
        flex: 1;
      }

      & > :first-child {
        display: flex;
        flex: 1;

        & > :first-child {
          flex: 1;
          width: 100%;
        }
      }
    }
  }
  @media (${BREAK_POINTS_DESKTOP_FIRST.MOBILE}) {
    display: block;
  }
`

const TitleText = styled.div`
  font-weight: 700;
  font-size: 2rem;
`

const FlexColContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: 12px;
  padding: ${(props) => props.$padding || '2rem auto'};
`

const AddElementButton = styled.div`
  align-self: end;
  margin: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  h2 {
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: unset;
  }

  button {
    margin: unset;
    background-color: unset;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (${BREAK_POINTS.TABLET}) {
    align-self: center;
  }
`

export const HeaderItemsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 25px;
  padding: 16px;

  @media (${BREAK_POINTS.DESKTOP}) {
    padding: 16px 20px;
  }

  @media (${BREAK_POINTS.DESKTOP_LARGE}) {
    padding: 16px 24px;
  }
`

export const FlexContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;

  @media (${BREAK_POINTS_DESKTOP_FIRST.MOBILE}) {
    justify-content: center;
  }
`

export const SimpleFlexContainer = styled.div`
  p {
    margin: 0;
    display: flex;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }

  span {
    width: 2rem;
    height: 2rem;
    display: flex;
  }
`

export const FlexBetweenContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 15px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`

const ProcessInfoSection = styled.section`
  height: 100%;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 100%;
  padding: 16px;
  grid-column: 1 / 3;
  background-color: #fff;
  border-radius: 10px;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

  @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
    grid-column: 1 / 1;
    flex-direction: column;
  }

  div {
    width: 100%;
  }

  div.separator {
    width: 2px;
    margin-right: 15px;
    height: 100%;
    background-color: #d9d9d9;

    @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
      width: 100%;
      margin: 15px 0;
      height: 2px;
    }
  }
`

const WorkOrderDetailsSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  grid-column: 1 / 3;
  background-color: #fff;
  border-radius: 15px;

  div.work-order-details__data {
    display: flex;
    flex-direction: column;
    gap: 12px;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (${BREAK_POINTS.TABLET}) {
      flex-direction: row;
    }
  }

  @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
    grid-column: 1 / 1;
  }
`

export const HeaderInfo = styled.div`
  width: 100%;
  display: flex;
  gap: 15px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (${BREAK_POINTS.TABLET}) {
    justify-content: start;
  }
`

const CommonSection = styled.section`
  width: 100%;
  flex: 1;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 16px;
  background-color: #fff;
  border-radius: 15px;

  overflow-y: auto;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
`

const DetailData = styled.section`
  display: flex;
  align-items: center;
  gap: 4px;

  i {
    width: 3.5rem;
    text-align: center;
    font-size: 2rem;
    color: #d60e46;
  }

  p {
    font-weight: bold;
    margin: 0;

    span {
      font-weight: 400;
      margin-left: 5px;
    }

    @media (${BREAK_POINTS.DESKTOP}) {
      display: flex;
      flex-direction: column;
      span {
        font-weight: 400;
        margin-left: 0;
      }
    }
  }
`

const SubTabs = styled.div`
  .ant-tabs.ant-tabs-top.ant-tabs-mobile.details-subtabs {
    .ant-tabs-content-holder {
      .ant-tabs-content {
        .ant-tabs-tabpane section {
          padding: 0;
        }
      }
    }
  }
`

const VehicleInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 700;
  margin: 0;

  p {
    display: flex;
    flex-direction: column;
    text-transform: capitalize;
    margin: 0;

    span {
      font-weight: 300;
      text-transform: capitalize;
    }
  }

  i {
    font-size: 4rem;
  }
`

const StatusLabel = styled.span`
  padding: 6px 12px;
  border-radius: 10px;
  font-weight: 700;

  @media (${BREAK_POINTS_DESKTOP_FIRST.MOBILE}) {
    top: 10px;
    right: 10px;
    font-size: 0.9em;
    padding: 6px 10px;
  }

  &.--in-process {
    border: rgb(0, 105, 217) solid 3px;
    color: rgb(0, 105, 217);
  }

  &.--in-pause {
    border: rgb(186, 50, 50) solid 3px;
    color: rgb(186, 50, 50);
  }

  &.--finished {
    border: rgb(50, 186, 53) solid 3px;
    color: rgb(50, 186, 53);
  }

  &.--not-started {
    border: rgb(109, 109, 109) solid 3px;
    color: rgb(109, 109, 109);
  }
`

const VehicleModelLabel = styled.p`
  display: inline-flex;
  gap: 5px;
  padding: 6px 12px;
  border-radius: 10px;
  font-weight: 700;
  border: #d60e46 solid 3px;
  color: #d60e46;
  text-align: center;
  margin: 1.5rem auto;

  span {
    font-weight: 400;
  }
`

const InvoiceBtn = styled.div`
  border-radius: 5px;
  max-width: 250px;
  background-color: #337ab7;
  border: none;
  outline: none;
  color: #fff;
  font-weight: 700;
  padding: 1rem 1.7rem;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  figure {
    width: 1.2rem;
    margin-left: 1rem;

    svg {
      width: 100%;
    }
  }

  &:hover {
    transform: scale(1.01);
    transition-duration: 0.3s;
  }
`

const CommonButton = styled.button`
  background-color: ${(props) => (props.$bgColor ? props.$bgColor : '#288dc3')};
  border: none;
  outline: none;
  color: var(--tertiary-text-color);
  font-weight: bold;
  padding: 0.8rem 1.2rem 0.8rem 1rem;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  font-size: 1.35rem;

  i {
    padding-right: 0;
    font-size: 1.75rem;
  }

  p {
    padding: 0;
  }

  &:hover {
    transform: scale(1.01);
  }
`

const ContainerWithScroll = styled.div`
  overflow-y: auto;
  max-height: 975px;

  @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
    max-height: 500px;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(140, 35, 106);
    background: linear-gradient(
      353deg,
      rgba(140, 35, 106, 0.8) 40%,
      rgba(80, 52, 127, 0.8) 100%
    );
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgb(140, 35, 106);
    background: linear-gradient(
      353deg,
      rgba(140, 35, 106) 40%,
      rgba(80, 52, 127) 100%
    );
  }
`

const CompleteOrderButton = styled.button`
  display: flex;
  justify-content: center;
  align-self: center;
  margin: 30px auto 16px;
  outline: none;
  border-radius: 5px;
  border: none;
  padding: 10px 15px;
  background-color: #25a244;
  color: #fff !important;
  font-size: 1.5rem;
  font-weight: 700;
  padding: 1rem 1.7rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin-top: 3rem;

  &:hover {
    transform: scale(1.01);
    transition-duration: 0.3s;
  }

  @media (${BREAK_POINTS.DESKTOP}) {
    margin: 3rem auto 16px;
  }
`

const ProcessButton = styled.button`
  border: none;
  margin: 10px 0 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  -webkit-appearance: none;

  &:hover {
    transform: scale(1.03);
    transition-duration: 0.2s;
  }
`

const WorkOderProcess = styled.div`
  width: 100%;
  padding: 1.4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 40px;

  i.fa-exclamation-triangle {
    font-size: 4rem;
    opacity: 0.8;
  }

  .work-order-process {
    &__icon {
      width: 3rem;
      display: inline-block;
      margin-bottom: 0;
    }

    &__title {
      color: #fff;
      font-size: 1.5rem;
      font-weight: bold;

      @media (${BREAK_POINTS.DESKTOP}) {
        font-size: 1.75rem;
      }
    }

    &__photo-button {
      width: 100%;
      display: flex;
      justify-content: end;
      margin-bottom: 3rem;
      gap: 16px;

      button {
        border: none;
        margin: 0;
        padding: 0;
        width: auto;
        overflow: visible;

        background: transparent;
        -webkit-appearance: none;
      }

      svg {
        cursor: pointer;
        opacity: 0.9;
        font-size: 2rem;

        &:hover {
          opacity: 1;
        }
      }
    }

    &__main-process {
      i {
        font-size: 4rem;
      }

      p {
        margin: 0;
      }
    }

    &__process-time {
    }

    &__total_time {
      width: 100%;
      display: flex;
      justify-content: end;
      margin-top: 3rem;

      p {
        margin-bottom: 0;
      }

      p span {
        font-weight: bold;
      }
    }
  }
`

const WorkOrderContainer = styled.div`
  display: flex;
  flex-direction: column;

  .pad-top {
    margin-top: 15px;

    button.complete-btn {
      background-color: #25a244;
    }
  }
  .pause-btn {
    width: 100%;
    background-color: #e63946 !important;
  }

  .start-btn {
    background-color: #25a244 !important;
  }

  .btn-large {
    width: full;
    font-size: 1.5rem;
    padding: 10px 15px;
    border-radius: 20px;
    align-items: center;
  }

  .title {
    font-weight: 700;
    font-size: 2rem;
    margin-top: 0rem;
    margin-bottom: 0;

    @media (${BREAK_POINTS.TABLET}) {
      width: 100%;
    }
  }

  &__message {
    font-size: 1.5rem;
    color: var(--error-text-color);
    margin-bottom: 2rem;
  }

  figure {
    width: 1.2rem;
    margin-left: 0.5rem;

    svg {
      width: 100%;
    }
  }

  @media (${BREAK_POINTS.TABLET}) {
    width: 100%;
  }

  .work-order-details {
    flex: 1;

    button {
      background-color: #288dc3;
      border: none;
      outline: none;
      color: var(--tertiary-text-color);
      font-weight: 700;
      padding: 1rem 1.7rem;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      border-radius: 35px;
      font-size: 1.2rem;
      height: 38px;
      margin: 20px 0;
      i {
        font-size: 1.2rem;
      }
    }

    &__title {
      font-size: 2rem;
      font-weight: 700;
      margin: 16px 0 2rem;

      @media (${BREAK_POINTS_DESKTOP_FIRST.MOBILE}) {
        margin-top: 2rem;
      }
    }

    &__detail {
      margin-bottom: 1.5rem;

      p:nth-child(1) {
        color: var(--primary-text-color);

        margin-bottom: 0;
      }

      p:nth-child(2) {
        color: var(--secondary-text-color);

        &.--in-process {
          color: #0069d9;
        }

        &.--in-pause {
          color: #e63946;
        }

        &.--finished {
          color: #218838;
        }
      }
    }
  }

  .work-order-list {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__title {
      font-size: 1.6rem;
      font-weight: 700;
      margin: 10px 0 1rem;

      @media (${BREAK_POINTS.TABLET}) {
        font-size: 1.75rem;
      }
    }

    &__states {
      margin-bottom: 2rem;

      &__state {
        display: relative;
        margin-right: 1rem;
        font-size: 1.2rem;
        color: var(--secondary-text-color);

        &::before {
          display: inline-block;
          content: ' ';
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin-right: 2px;
        }

        &.--in-process::before {
          background-color: #0069d9;
        }

        &.--in-pause::before {
          background-color: #e63946;
        }
        &.--finished::before {
          background-color: #218838;
        }
      }
    }

    &__item {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;

      border-radius: 10px;
      padding: 1rem;

      &.--in-process {
        color: #0069d9;
        background-color: rgba(0, 105, 217, 0.2);

        svg path {
          fill: #0069d9;
        }
      }

      &.--in-pause {
        color: #fff !important;
        background-color: #e63946;

        svg path {
          fill: #fff;
        }
      }

      &.--finished {
        color: #218838;
        background-color: rgba(33, 136, 56, 0.2);

        svg path {
          fill: #218838;
        }
      }

      &__icon {
        width: 3rem;
        display: inline-block;
        margin-right: 2rem;
      }

      &__detail {
        font-size: 1.3rem;

        &__name {
          display: flex;
          flex-direction: column;
        }

        &__time {
        }
      }
    }

    .products {
      margin-bottom: 0.6rem;
    }

    @media (${BREAK_POINTS.DESKTOP}) {
      margin-bottom: 0rem;
    }
  }

  .work-order-productss {
    flex: 1;
  }

  @media (${BREAK_POINTS.TABLET}) {
    flex-wrap: wrap;
    flex-direction: row;
  }
`

const CurrentProcessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;

  .remaining,
  .extra-time {
    font-weight: bold;
  }
`

const WorkOrderPhotosSection = styled.section`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding: 16px;
  grid-column: 1 / 3;
  background-color: #fff;
  border-radius: 15px;

  .photos-wrapper {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
  }
  div.work-order-details > h2 > span {
    background-color: #06d6a0;
  }

  @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
    grid-column: 1 / 1;
  }
`
const ProductIcon = ({ className }) => (
  <figure className={className}>
    <svg viewBox="0 0 24 24" fill="#071A29">
      <g>
        <path d="M20.6,5.7c0-0.2-0.1-0.3-0.2-0.4l-8.1-4.7c-0.1-0.1-0.3-0.1-0.5,0L3.6,5.3C3.5,5.4,3.4,5.5,3.4,5.7c0,0.2,0.1,0.3,0.2,0.4   l8.1,4.7c0.1,0,0.2,0.1,0.2,0.1s0.2,0,0.2-0.1l8.1-4.7C20.5,6,20.6,5.8,20.6,5.7z" />
        <path d="M10.6,13.2L2.5,8.5C2.3,8.4,2.1,8.4,2,8.5C1.9,8.6,1.8,8.8,1.8,8.9v9.4c0,0.2,0.1,0.3,0.2,0.4l8.1,4.7   c0.1,0,0.2,0.1,0.2,0.1s0.2,0,0.2-0.1c0.1-0.1,0.2-0.2,0.2-0.4v-9.4C10.9,13.4,10.8,13.3,10.6,13.2z" />
        <path d="M22,8.5c-0.1-0.1-0.3-0.1-0.5,0l-8.1,4.7c-0.1,0.1-0.2,0.2-0.2,0.4V23c0,0.2,0.1,0.3,0.2,0.4c0.1,0,0.2,0.1,0.2,0.1   s0.2,0,0.2-0.1l8.1-4.7c0.1-0.1,0.2-0.2,0.2-0.4V8.9C22.2,8.8,22.1,8.6,22,8.5z" />
      </g>
    </svg>
  </figure>
)

ProductIcon.propTypes = {
  className: PropTypes.string.isRequired,
}

const mapStateToProps = (store) => ({
  currentUser: store.AppReducer.currentUser,
  workOrderToAdministrate: store.WorkOrderReducer.workOrderToAdministrate,
  commentsWorkOrder: store.WorkOrderReducer.commentsWorkOrder,
  products: store.ProductReducer.products,
  allProcesses: store.ProcessReducer.processes,
})

const mapDispatchToProps = (dispatch) => ({
  updateWorkOrderProcessTimeTrackerAction(timeTracker) {
    return dispatch(updateWorkOrderProcessTimeTracker(timeTracker))
  },
  updateWorkOrderProcessTimeTrackerWithReturnAction(timeTracker) {
    return dispatch(updateWorkOrderProcessTimeTrackerWithReturn(timeTracker))
  },
  updateWorkOrderAction(workOrder) {
    return dispatch(updateWorkOrder(workOrder))
  },
  setWorkOrderToAdministrateAction(workOrder) {
    return dispatch(setWorkOrderToAdministrate(workOrder))
  },
  updateWorkOrderTimeStatusAction(
    workOrder,
    updateWorkOrderRealTime,
    initiate
  ) {
    return dispatch(
      updateWorkOrderTimeStatus(workOrder, updateWorkOrderRealTime, initiate)
    )
  },
  endWorkOrder(workOrder) {
    return dispatch(finishWorkOrder(workOrder))
  },
  getCommentsWorkOrderFn(workOrder) {
    return dispatch(getCommentsWorkOrder(workOrder))
  },
  addCommentFn(comment) {
    return dispatch(addComment(comment))
  },
  getAllProducts() {
    return dispatch(getProducts())
  },
  getAllProcesses() {
    return dispatch(getProcesses())
  },
  getWorkOrder(workOrderId) {
    return dispatch(getWorkOrderById(workOrderId))
  },
})

AdministrateWorkOrder.propTypes = {
  workOrderToAdministrate: PropTypes.objectOf(PropTypes.any).isRequired,
  currentUser: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  updateWorkOrderProcessTimeTrackerAction: PropTypes.func.isRequired,
  updateWorkOrderProcessTimeTrackerWithReturnAction: PropTypes.func.isRequired,
  updateWorkOrderAction: PropTypes.func.isRequired,
  setWorkOrderToAdministrateAction: PropTypes.func.isRequired,
  updateWorkOrderTimeStatusAction: PropTypes.func.isRequired,
  endWorkOrder: PropTypes.func.isRequired,
  getCommentsWorkOrderFn: PropTypes.func.isRequired,
  addCommentFn: PropTypes.func.isRequired,
  products: PropTypes.objectOf(PropTypes.any).isRequired,
  allProcesses: PropTypes.objectOf(PropTypes.any).isRequired,
  getAllProducts: PropTypes.func.isRequired,
  getAllProcesses: PropTypes.func.isRequired,
  getWorkOrder: PropTypes.func.isRequired,
  match: PropTypes.PropTypes.objectOf(PropTypes.any).isRequired,
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdministrateWorkOrder)
)
