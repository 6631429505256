import { useCallback, useState, useMemo } from 'react'

export const useServicesTab = (
  processesArray,
  initialSelectedServices = [],
  isCamelCase = false
) => {
  // TODO: This is going to be deleted when new endpoint is completely integrated
  const idField = isCamelCase ? 'processId' : 'process_id'
  const priceField = isCamelCase ? 'processPrice' : 'process_price'
  const timeField = isCamelCase ? 'processTime' : 'process_estimated_time'

  const initialDiscount =
    initialSelectedServices?.length > 0
      ? initialSelectedServices[0].process_placed_discount
      : 0

  const initialValues =
    initialSelectedServices?.length > 0
      ? initialSelectedServices.map((process) => process[idField])
      : []

  const [openOptions, setOpenOptions] = useState(false)
  const [discount, setDiscount] = useState(initialDiscount)
  const [value, setValue] = useState(initialValues)
  const [selectedServices, setSelectedServices] = useState(
    initialSelectedServices
  )

  const getUpdatedSelectedElements = (newValue) =>
    selectedServices.filter((selectedProduct) => {
      const isSelectedProductInNewValue = newValue.includes(
        selectedProduct[idField]
      )
      return isSelectedProductInNewValue
    })

  const deleteService = (index) => {
    const updatedProducts = selectedServices.filter(
      (item) => item[idField] !== index
    )
    const updatedValues = value.filter((number) => number !== index)

    setSelectedServices(updatedProducts)
    setValue(updatedValues)
  }

  const handlePriceChange = (newPrice, productId) => {
    const indexToUpdate = selectedServices.findIndex(
      (option) => option[idField] === productId
    )
    const updatedOptions = [...selectedServices]

    updatedOptions[indexToUpdate].process_placed_price = newPrice

    setSelectedServices(updatedOptions)
  }

  const selectCustomFilter = useCallback((inputValue, option) => {
    return option.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
  }, [])

  const totalEstimatedTime = useMemo(
    () =>
      selectedServices.reduce((accumulator, currentItem) => {
        return accumulator + currentItem[timeField]
      }, 0),
    [selectedServices]
  )

  const resetValues = () => {
    setValue([])
    setSelectedServices([])
    setDiscount(0)
  }

  const getTotalWithDiscount = (price, discountValue) => {
    let result = price

    if (discountValue > 0) {
      result -= (result * discountValue) / 100
    }
    return result ? parseFloat(result) : 0.0
  }

  const getTotal = () => {
    const processessTotal = selectedServices.reduce(
      (accumulator, currentItem) => {
        return (
          accumulator +
            getTotalWithDiscount(
              currentItem.process_placed_price,
              currentItem.process_placed_discount
            ) || 0
        )
      },
      0
    )

    return processessTotal.toFixed(2)
  }

  const handleChange = (newValue) => {
    setValue(newValue)

    const updatedSelectedProcesses = getUpdatedSelectedElements(newValue)

    newValue.forEach((id) => {
      const isIdInSelectedProcesses = selectedServices.some(
        (selectedProcess) => selectedProcess[idField] === id
      )

      if (!isIdInSelectedProcesses) {
        const processIndex = processesArray.findIndex(
          (process) => process[idField] === id
        )

        if (processIndex !== -1) {
          const process = processesArray[processIndex]
          const processWithFields = {
            ...process,
            process_placed_price: parseFloat(process[priceField]).toFixed(2),
          }

          updatedSelectedProcesses.push(processWithFields)
        }
      }
    })

    setSelectedServices(updatedSelectedProcesses)
    setOpenOptions(false)
  }

  const handleDiscountChange = (newDiscount) => {
    const validateDiscount = Math.min(100, Math.max(0, newDiscount))

    const updateSelectedProcesses = selectedServices.map((process) => ({
      ...process,
      process_placed_discount: validateDiscount,
    }))

    setDiscount(validateDiscount)
    setSelectedServices(updateSelectedProcesses)
  }

  const selectNewService = (newService) => {
    handleChange([...value, newService.processId])
    setSelectedServices([
      ...selectedServices,
      {
        ...newService,
        process_placed_price: parseFloat(newService[priceField]).toFixed(2),
        process_placed_discount: discount,
      },
    ])
  }

  const handlers = {
    handleChange,
    handlePriceChange,
    handleDiscountChange,
  }

  return {
    totalEstimatedTime,
    value,
    selectedServices,
    discount,
    openOptions,
    selectCustomFilter,
    setOpenOptions,
    getTotal,
    getTotalWithDiscount,
    selectNewService,
    resetValues,
    deleteService,
    handlers,
  }
}
