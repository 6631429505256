import React, { Component } from 'react'
import {
  Modal,
  FormGroup,
  ControlLabel,
  Col,
  Button,
  Row,
  Form,
  FormControl,
} from 'react-bootstrap'
import _ from 'lodash'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import PropTypes from 'prop-types'
import { SaveOutlined } from '@ant-design/icons'
import ProductOrderDetails from './Details/ProductOrderDetails'
import PersonModal from '../Persons/PersonModal'

import { getValidationState, showConfirmDialog } from '../Common'
import {
  getWorkOrderTotal,
  getWorkOrderDetails,
  parsePerson,
} from './functions'

class ProductOrderModal extends Component {
  state = {
    loading: false,
    showPersonModal: false,
    employee_id: null,
    person_id: null,
    work_order_date: moment(),
    work_order_type: 2,
    workOrderDetails: {},
    work_order_invoiced: 0,
  }

  componentDidUpdate(prevProps) {
    const { selectedOrder } = this.props
    const { selectedOrder: prevSelectedOrder } = prevProps

    if (selectedOrder !== prevSelectedOrder) {
      const { length } = Object.keys(selectedOrder)
      const id = selectedOrder.work_order_id
      if (
        length === 0 ||
        id === undefined ||
        id === null ||
        selectedOrder === undefined ||
        selectedOrder === null
      ) {
        this.clearComponent()
      } else if (length > 0 && id) {
        this.setOrder()
      }
    }
  }

  setOrder = () => {
    const { selectedOrder } = this.props
    const {
      work_order_date,
      employee_id,
      details,
      person_id,
      work_order_invoiced,
    } = selectedOrder
    const { processes } = this.props

    let newState = {}
    newState = {
      work_order_date: moment(work_order_date),
      person_id,
      employee_id,
      work_order_invoiced,
      workOrderDetails: getWorkOrderDetails(details, processes, true),
    }
    this.setState(newState)
  }

  clearComponent = () => {
    this.setState({
      work_order_date: moment(),
      processes: [],
      employee_id: '',
      employee: {},
      person_id: '',
      workOrderDetails: {},
    })
  }

  toggleCustomerModal = () => {
    const { showPersonModal } = this.state
    this.setState({ showPersonModal: !showPersonModal })
  }

  handleChangeSelect = (value, field) => {
    if (value) {
      if (field === 'person' && value.person_id === 'add') {
        this.toggleCustomerModal()
        return
      }
      this.setState({ [`${field}_id`]: value[`${field}_id`] })
    } else {
      this.setState({
        [`${field}_id`]: null,
      })
    }
  }

  handleChangeDetail = (e, key, work_order_detail_item_max_quantity) => {
    const { name } = e.target
    let { value } = e.target
    value = value.replace(/[^\d]/, '');

    if (value > work_order_detail_item_max_quantity) {
      value = work_order_detail_item_max_quantity
    }

    /*  if(value == ''){
        value = 1
      } 
  
      if(value == 0){
        value = 1
      } */

    if (name.includes('discount')) {
      value = value.replace(/[^0-9-]/g, '')
      value = parseFloat(value || 0)

      /*  if (value > 100) {
            value = 100
          } */
    }

    /**
     * to remove negatives or extreme decimals
     *
     * zero: 0, 0.0
     * negatives: -10, -1, -0.001111
     * extreme decimals: 0.0000001, 0.001, 0.01232135
     *
     * the minimum price should be 0.01
     *
     * tests --> https://regexr.com/5pqt2
     */

    if (name.includes('price')) {
      if (parseFloat(value) < 0) {
        value = 0.01
      }

      value = `${value}`.replace(
        /(?:^-)|(?:^\d{1,})\.((?:\d{3,})|(?:0{1}\d{2,}))/g,
        0.01
      )
    }

    const { workOrderDetails } = this.state
    this.setState({
      workOrderDetails: {
        ...workOrderDetails,
        [key]: {
          ...workOrderDetails[key],
          [name]: value,
        },
      },
    })
  }

  handleChangeSelectDetail = (value, field, key) => {
    const { workOrderDetails } = this.state
    if (value) {
      this.setState({
        workOrderDetails: {
          ...workOrderDetails,
          [key]: {
            ...workOrderDetails[key],
            [field]: value.product_id,
            work_order_detail_item_price: value.product_price,
            recommended_work_order_detail_item_price: value.product_price,
            work_order_detail_item_quantity: 1,
            work_order_detail_item_max_quantity: value.product_quantity
          },
        },
      })
    } else {
      this.setState({
        workOrderDetails: {
          ...workOrderDetails,
          [key]: {
            work_order_detail_item_quantity: '',
            work_order_detail_item_max_quantity: '',
            work_order_detail_item_price: '',
            recommended_work_order_detail_item_price: '',
            work_order_detail_item: null,
            work_order_detail_discount: ''
          },
        },
      })
    }
  }

  addWorkOrderDetail = (typeCard = 'product') => {
    const { workOrderDetails } = this.state

    const lastKey = _.findLastKey(workOrderDetails)
    let newKey
    if (Number.isNaN(lastKey) || lastKey === undefined) {
      newKey = 0
    } else {
      newKey = parseInt(lastKey, 10) + 1
    }
    const state = {
      ...this.state,
    }

    state.workOrderDetails[newKey] = {
      work_order_detail_item: null,
      work_order_detail_item_price: 0,
      work_order_detail_item_quantity: 0,
      work_order_detail_item_max_quantity: 0,
      work_order_detail_discount: 0,
      type_card: typeCard,
    }

    this.setState(state)
  }

  deleteWorkOrderDetail = (key) => {
    const state = _.cloneDeep(this.state)
    if (state.workOrderDetails[key].work_order_detail_id) {
      state.workOrderDetails[key].isDeleted = true
    } else {
      delete state.workOrderDetails[key]
    }
    this.setState(state)
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.setState({ loading: false })
    const {
      selectedOrder,
      toggleModal,
      updateOrder,
      editMode,
      addOrder,
      employee: { employee_id },
    } = this.props
    toggleModal()
    const { work_order_date, work_order_type, workOrderDetails, person_id } =
      this.state

    const productOrder = {
      work_order_id: selectedOrder.work_order_id || null,
      employee_id,
      work_order_date,
      person_id,
      work_order_type,
      work_order_total: parseFloat(
        getWorkOrderTotal(workOrderDetails || 0, true)
      ),
      work_order_status_id: 3,
      workOrderDetails,
    }

    if (!editMode) {
      addOrder({ ...productOrder, work_order_invoiced: 0 })
        .then(() => {
          this.setState({
            loading: false,
          })
        })
        .catch(() => {
          this.setState({
            loading: false,
          })
        })
    } else {
      showConfirmDialog(
        '¿Modificar?',
        '¿Desea modificar la orden de venta?',
        updateOrder,
        productOrder,
        () => { },
        toggleModal,
        toggleModal
      )
    }
  }

  render() {
    const {
      modalOpen,
      toggleModal,
      editMode,
      products,
      pagePermissions,
      persons,
      permissions,
      employee,
      paymentConditions,
      states,
      countries,
      cities,
      contributors,
      addPerson,
    } = this.props

    const {
      loading,
      work_order_date,
      person_id,
      showPersonModal,
      workOrderDetails,
      work_order_invoiced,
    } = this.state

    let can_edit
    if (pagePermissions) {
      ; ({ can_edit } = pagePermissions)
    }

    const personPagePermissions = {
      can_write: permissions[20].can_write,
      can_edit: permissions[20].can_edit,
    }

    return (
      <Modal show={modalOpen} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode ? 'Modificar ' : 'Agregar '}
            Orden de Venta
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.handleSubmit}>
            <div>
              <FormGroup validationState={getValidationState('brand', true)}>
                <ControlLabel>Fecha: </ControlLabel>
                <DatePicker
                  selected={work_order_date}
                  dateFormat="LL"
                  className="form-control"
                  readOnly
                />
              </FormGroup>
            </div>
            <Row>
              <Col md={12}>
                <FormGroup
                  validationState={getValidationState('Cliente', true)}
                >
                  <ControlLabel>Cliente: </ControlLabel>
                  <Select
                    placeholder="Cliente"
                    options={[
                      { person_id: 'add', fullName: 'Agregar cliente' },
                      ..._.values(parsePerson(persons)),
                    ]}
                    labelKey="fullName"
                    valueKey="person_id"
                    value={person_id}
                    onChange={(value) => {
                      this.handleChangeSelect(value, 'person')
                    }}
                    required
                    disabled={editMode}
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup
                  validationState={getValidationState('Vendedor', true)}
                >
                  <ControlLabel>Vendedor: </ControlLabel>
                  <FormControl
                    placeholder="Vendedor"
                    value={`${employee.employee_name || ''} ${employee.employee_lastname || ''
                      }`}
                    readOnly
                  />
                </FormGroup>
              </Col>
            </Row>
            <ProductOrderDetails
              products={products}
              workOrderDetails={workOrderDetails}
              handleChangeDetail={this.handleChangeDetail}
              deleteWorkOrderDetail={this.deleteWorkOrderDetail}
              handleChangeSelectDetail={this.handleChangeSelectDetail}
              addWorkOrderDetail={this.addWorkOrderDetail}
              editMode={editMode}
            />
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button type="submit" hidden id="submitWorkOrderForm" />
          </Form>
          <PersonModal
            modalOpen={showPersonModal}
            selectedPerson={{}}
            toggleModal={this.toggleCustomerModal}
            addPerson={addPerson}
            states={states}
            countries={countries}
            cities={cities}
            contributors={contributors}
            pagePermissions={personPagePermissions}
            paymentConditions={paymentConditions}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={toggleModal}>Cerrar</Button>
          {!!can_edit && !work_order_invoiced && (
            <Button
              disabled={loading}
              bsStyle="primary"
              onClick={() => {
                document.getElementById('submitWorkOrderForm').click()
              }}
              className="btn-antd-icon"
            >
              <SaveOutlined />
              {editMode ? ' Modificar' : ' Agregar'}
            </Button>
          )}
          {!!work_order_invoiced && (
            <Button disabled bsStyle="primary">
              <i className="fas fa-money-bill-alt" />
              {' Orden ya facturada'}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    )
  }
}

ProductOrderModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  addOrder: PropTypes.func.isRequired,
  updateOrder: PropTypes.func.isRequired,
  modalOpen: PropTypes.func.isRequired,
  addPerson: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  selectedOrder: PropTypes.objectOf(PropTypes.any).isRequired,
  employee: PropTypes.objectOf(PropTypes.any).isRequired,
  processes: PropTypes.arrayOf(PropTypes.object).isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  persons: PropTypes.arrayOf(PropTypes.object).isRequired,
  states: PropTypes.arrayOf(PropTypes.object).isRequired,
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  cities: PropTypes.arrayOf(PropTypes.object).isRequired,
  contributors: PropTypes.arrayOf(PropTypes.object).isRequired,
  paymentConditions: PropTypes.arrayOf(PropTypes.object).isRequired,
  pagePermissions: PropTypes.objectOf(PropTypes.bool).isRequired,
  permissions: PropTypes.objectOf(PropTypes.bool).isRequired,
}

export default ProductOrderModal
