import { GET_MEASURE_UNITS } from '../actionTypes/MeasureUnitTypes'

export default (
  state = {
    measureUnits: {},
  },
  action
) => {
  switch (action.type) {
    case GET_MEASURE_UNITS: {
      return {
        ...state,
        measureUnits: action.payload,
      }
    }

    default: {
      return state
    }
  }
}
