import axios from 'axios'
import _ from 'lodash'

import {
  GET_COLORS,
  ADD_COLOR,
  UPDATE_COLOR,
  DELETE_COLOR,
} from '../actionTypes/ColorTypes'
import { NotificationHandler } from '../Helpers'

const getColorsAction = (payload) => ({
  type: GET_COLORS,
  payload,
})

const addColorAction = (payload) => ({
  type: ADD_COLOR,
  payload,
})

const updateColorAction = (payload) => ({
  type: UPDATE_COLOR,
  payload,
})

const deleteColorAction = (payload) => ({
  type: DELETE_COLOR,
  payload,
})

export const getColors = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const colorsQuantity = _.size(getState().ColorReducer.colors)
    if (colorsQuantity === 0) {
      axios
        .get('/api/colors')
        .then((response) => {
          if (!response.data.error) {
            const colors = _.keyBy(response.data.data, 'color_id')
            dispatch(getColorsAction(colors))
            resolve(response.data)
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al obtener los colores',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al obtener los colores',
            error
          )
        })
    } else {
      resolve({})
    }
  })

export const addColor = (color) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { workshop_id } = getState().AppReducer.currentUser

    axios
      .post('/api/colors', { ...color, workshop_id })
      .then((response) => {
        if (!response.data.error) {
          dispatch(addColorAction(response.data.data))
          resolve(response)
          NotificationHandler('Done', 'info', 'Color agregado')
        } else {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al agregar el color',
            response.data.data
          )
        }
      })
      .catch((error) => {
        reject()
        NotificationHandler(
          'Error',
          'error',
          'Error al agregar el color',
          error
        )
      })
  })

export const updateColor = (color) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (color.color_id) {
      axios
        .put(`/api/colors/${color.color_id}`, color)
        .then((response) => {
          if (!response.data.error) {
            dispatch(updateColorAction(color))
            resolve(response)
            NotificationHandler('Done', 'info', 'Color actualizado')
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al actualizar el color',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al actualizar el color',
            error
          )
        })
    } else {
      reject()
      NotificationHandler(
        'Error',
        'error',
        `No se proporcionó id a: ${color.color_name}`
      )
    }
  })

export const deleteColor = (color) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (color.color_id) {
      axios
        .delete(`/api/colors/${color.color_id}`)
        .then((response) => {
          if (!response.data.error) {
            dispatch(deleteColorAction(color))
            resolve(response)
            NotificationHandler('Done', 'info', 'Color eliminado')
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al eliminar el color',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al eliminar el color',
            error
          )
        })
    } else {
      reject()
      NotificationHandler(
        'Error',
        'error',
        `No se proporcionó id a: ${color.color_name}`
      )
    }
  })
