import _ from 'lodash'

export const getPackageDetailsTotal = (packageDetails, returnAsFloat) => {
  let total = 0
  _.forEach(packageDetails, (detail) => {
    total += parseFloat(detail.process_price || 0)
  })
  if (returnAsFloat) {
    return total
  }
  return total.toLocaleString('en', { style: 'currency', currency: 'USD' })
}

export const getWorkOrderTotal = (
  workOrderDetail,
  packageDetails,
  returnAsFloat
) => {
  let total = 0
  _.forEach(workOrderDetail, (detail) => {
    if (!workOrderDetail.isDeleted) {
      total +=
        parseFloat(detail.work_order_detail_item_price || 0) +
        parseFloat(detail.work_order_detail_workforce_price || 0)
    }
  })
  total += getPackageDetailsTotal(packageDetails, true)

  if (returnAsFloat) {
    return total
  }
  return total.toLocaleString('en', { style: 'currency', currency: 'USD' })
}

const toHours = (tiempo) => {
  let hours = 0
  let minutos = 0
  hours = parseInt(tiempo / 60, 10)
  minutos = tiempo - hours * 60
  return `${hours > 9 ? hours : `0${hours}`}:${
    minutos > 9 ? minutos : `0${minutos}`
  } mins`
}

export const getTotalTime = (workOrderDetail) => {
  let total = 0
  _.forEach(workOrderDetail, (detail) => {
    if (!detail.isDeleted) {
      total += parseInt(detail.work_order_detail_estimated_time || 0, 10)
    }
  })

  return toHours(parseInt(total, 10))
}

export const getPackageDetailsTimeTotal = (
  packageDetails,
  returnJustNumber
) => {
  let time = 0
  _.forEach(packageDetails, (detail) => {
    time +=
      parseInt(
        detail.process_estimated_time ||
          detail.work_order_detail_estimated_time,
        10
      ) || 0
  })
  if (returnJustNumber) {
    return `${time}`
  }
  return `${toHours(parseInt(time, 10))}`
}

export const getDetailsTotal = (details) => {
  let total = 0
  _.forEach(details, (detail) => {
    total +=
      parseFloat(detail.work_order_detail_item_price || 0) +
      parseFloat(detail.work_order_detail_workforce_price || 0)
  })

  return total.toLocaleString('en', { style: 'currency', currency: 'USD' })
}
