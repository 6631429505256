import { Grid, Row, Col, Panel } from 'react-bootstrap'
import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Container, BodyContent, PanelHeader } from '../Common/index'
import BootstrapTableWithDynamicPagination from './BootstrapTableWithDynamicPagination'
import { BREAK_POINTS_DESKTOP_FIRST } from '../../../global/css/breakPoints'

import getColumns from './Columns'
import { getSalesHistory } from '../../../actions/HistoryAction'
import { getPackages } from '../../../actions/PackageAction'
import { getProducts } from '../../../actions/ProductActions'
import { getProcesses } from '../../../actions/ProcessAction'
import { getMechanics } from '../../../actions/EmployeeAction'
import { getReceptions } from '../../../actions/ReceptionAction'
import { addWorkOrder } from '../../../actions/WorkOrderAction'
import { getWorkOrderStatuses } from '../../../actions/WorkOrderStatusAction'
import { getServiceTypes } from '../../../actions/ServiceTypeAction'
import HistoryModal from './historyModal'
import PrintModal from '../Invoice/InvoiceForm/PrintModal'
import {
  printInvoicePDF,
  updateInvoice,
} from '../Invoice/InvoiceForm/InvoiceFunctions'
import printTicketPDF from '../Invoice/InvoiceForm/TicketFunctions'
import { AppLoader } from '../Common/AppLoader'

class HistoryContainer extends Component {
  state = {
    showModal: false,
    showClaimModal: false,
    loading: true,
    selectedHistory: {},
    showModalInvoice: false,
    pdfUrl: '',
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions)
    const {
      getActualSalesHistory,
      getAllMechanics,
      getServices,
      getAllProcesses,
      getAllReceptions,
      getAllPackages,
      getAllProducts,
      getAllWorkOrderStatuses,
    } = this.props
    getAllMechanics()
    getAllPackages()
    getAllProducts()
    getAllWorkOrderStatuses()
    getAllReceptions()
    getAllProcesses()
    getServices()
    getActualSalesHistory().then(() => {
      this.setState({ loading: false })
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  toggleModal = () => {
    const { showModal } = this.state
    this.setState({ showModal: !showModal })
  }

  toggleClaimModal = () => {
    const { showClaimModal } = this.state
    this.setState({ showClaimModal: !showClaimModal })
  }

  seeData = (history) => {
    this.setState({ selectedHistory: history })
    this.toggleModal()
  }

  toggleModalInvoice = () => {
    const { showModalInvoice } = this.state
    this.setState({ showModalInvoice: !showModalInvoice })
  }

  onlyPrint = async (workOrderToInvoice) => {
    const { headerFields, bodyFields, footerDetails } = updateInvoice(
      workOrderToInvoice,
      0.13
    )

    const invoiceId = workOrderToInvoice.invoice.invoice_id

    const {
      person,
      invoice: {
        invoice_number,
        serie: {
          invoiceTypes: { invoice_type_code },
        },
      },
      workshop,
    } = workOrderToInvoice

    let pdfUrl = null
    if (invoice_type_code === 1) {
      pdfUrl = await printTicketPDF(
        workshop,
        invoice_number,
        bodyFields,
        footerDetails,
        person
      )
    } else {
      pdfUrl = await printInvoicePDF(headerFields, bodyFields)
    }

    this.setState({
      pdfUrl,
      showModalInvoice: true,
      invoiceId,
    })
  }

  render() {
    const {
      loading,
      selectedHistory,
      showModal,
      showModalInvoice,
      pdfUrl,
      invoiceId,
    } = this.state

    const { salesHistory, getActualSalesHistory } = this.props

    return (
      <Container>
        <BodyContent key="cont">
          <Grid fluid>
            <Row>
              <Col sm={12} key="k-22">
                <Panel>
                  <Panel.Heading bsClass="panel-heading">
                    <Panel.Title>
                      <PanelHeader>
                        <div>
                          <p id="title">
                            <i className="fas fa-history prefix" /> Historial de ventas
                          </p>
                        </div>
                      </PanelHeader>
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    {loading ? (
                      <AppLoader />
                    ) : (
                      <Row>
                        <Col m={12}>
                          <CustomStyledTable>
                            <BootstrapTableWithDynamicPagination
                              keyField="work_order_id"
                              data={salesHistory}
                              columns={getColumns(this.seeData, this.onlyPrint)}
                              getPaginatedList={getActualSalesHistory}
                              filterByDate
                            />
                          </CustomStyledTable>
                        </Col>
                      </Row>
                    )}
                  </Panel.Body>
                </Panel>
              </Col>
            </Row>
          </Grid>
          <HistoryModal
            modalOpen={showModal}
            toggleModal={this.toggleModal}
            selectedHistory={selectedHistory}
            fieldName={selectedHistory.work_order_number}
            fieldNameTitle="Empleado"
          />
          <PrintModal
            modalOpen={showModalInvoice}
            toggleModal={this.toggleModalInvoice}
            toggleModalCloseButton={this.toggleModalInvoice}
            disabled
            src={pdfUrl}
            invoiceId={invoiceId}
            invoiceWorkOrder={this.invoiceWorkOrder}
            loadingInvoicingWorkOrder={false}
            closeButton
            keyboard
            backdrop
            displayFinishButton={false}
          />
        </BodyContent>
      </Container>
    )
  }
}

const CustomStyledTable = styled.div`
  @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
    table {
      padding: 0px 10px;

      thead {
        display: none;
      }

      tbody {
        tr {
          border: 1px solid #ddd;
          display: flex;
          flex-wrap: wrap;
          border-radius: 10px;
          margin: 20px 10px;
          padding-top: 10px;
          padding-bottom: 10px;

          td {
            width: 100%;
            border: 0;
            border-right: 0px !important;
            border-left: 0px !important;
          }

          td:nth-last-child(1),
          td:nth-last-child(2) {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40%;
          }

          td::before {
            font-weight: bold;
            margin-right: 0.5rem;
          }

          td:nth-child(1)::before {
            content: 'Orden:';
          }

          td:nth-child(2)::before {
            content: 'Nombres cliente:';
          }

          td:nth-child(3)::before {
            content: 'Apellidos cliente:';
          }

          td:nth-child(4)::before {
            content: 'Empleado nombres:';
          }

          td:nth-child(5)::before {
            content: 'Empleado apellidos:';
          }

          td:nth-child(6)::before {
            content: 'Total:';
          }

          td:nth-child(7)::before {
            content: 'Fecha:';
          }
        }
      }
    }
  }
`

const mapStateToProps = (store) => ({
  permissions: store.AppReducer.permissions,
  currentUser: store.AppReducer.currentUser,
  salesHistory: store.HistoryReducer.salesHistory,
  packages: store.PackageReducer.packages,
  products: store.ProductReducer.products,
  mechanics: store.MechanicReducer.mechanics,
  processes: store.ProcessReducer.processes,
  workOrderStatuses: store.WorkOrderStatusReducer.workOrderStatuses,
  services: store.ServiceTypeReducer.serviceTypes,
  receptions: _.filter(
    store.ReceptionReducer.receptions,
    (reception) => reception.reception_state === 1
  ),
})

const mapDispatchToProps = (dispatch) => ({
  getActualSalesHistory(query, sortBy, orderBy, page, size, init, end) {
    return dispatch(
      getSalesHistory(query, sortBy, orderBy, page, size, init, end)
    )
  },
  getAllPackages() {
    return dispatch(getPackages())
  },
  getAllProducts() {
    return dispatch(getProducts())
  },
  getAllProcesses() {
    return dispatch(getProcesses())
  },
  getAllWorkOrderStatuses() {
    return dispatch(getWorkOrderStatuses())
  },
  getAllMechanics() {
    return dispatch(getMechanics())
  },
  getAllReceptions() {
    return dispatch(getReceptions())
  },
  getServices() {
    return dispatch(getServiceTypes())
  },
  createWorkOrder(workOrder) {
    return dispatch(addWorkOrder(workOrder))
  },
})

HistoryContainer.propTypes = {
  getActualSalesHistory: PropTypes.func.isRequired,
  getServices: PropTypes.func.isRequired,
  getAllProcesses: PropTypes.func.isRequired,
  getAllWorkOrderStatuses: PropTypes.func.isRequired,
  getAllMechanics: PropTypes.func.isRequired,
  getAllReceptions: PropTypes.func.isRequired,
  getAllPackages: PropTypes.func.isRequired,
  getAllProducts: PropTypes.func.isRequired,
  salesHistory: PropTypes.objectOf(PropTypes.object).isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoryContainer)
