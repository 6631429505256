/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { Panel, Row, Col, ProgressBar, Alert, Button } from 'react-bootstrap'
import styled from 'styled-components'
import moment from 'moment'
import 'moment/locale/es'
import {
  DeleteFilled,
  PlusCircleFilled,
  SettingFilled,
  ToolFilled,
  ClockCircleOutlined,
  UpOutlined,
  DownOutlined,
  UserOutlined,
  PhoneFilled,
  CalendarOutlined,
  PauseCircleFilled,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import _ from 'lodash'
import ExclamationTriangle from '../../../../icons/ExclamationTriangle'
import PlayFaIcon from '../../../../icons/PlayFaIcon'

import { BREAK_POINTS_DESKTOP_FIRST } from '../../../../../global/css/breakPoints'
import { PanelHeader } from '../../../Common'
import { Card, Body } from '../Components'

moment.locale('es')

// const getData = (time) => {
//   let status
//   /*  let totalTimeMinutes = 0
//     const endTime = new Date()
//     if (time.time_tracker_end) {
//       totalTimeMinutes =
//         moment(time.time_tracker_end || undefined).unix() -
//         moment(time.time_tracker_start || undefined).unix() / 60
//     } else {
//       const startTime = moment(time.time_tracker_start).add(12, 'hours')
//       totalTimeMinutes =
//         moment(endTime || undefined).unix() -
//         moment(startTime || undefined).unix() / 60
//     } */
//   const startDate = time.time_tracker_start
//     ? moment(time.time_tracker_start).format('DD/MM/YYYY HH:mm:ss')
//     : 'Sin Iniciar'
//   const endDate = time.time_tracker_end
//     ? moment(time.time_tracker_end).format('DD/MM/YYYY HH:mm:ss')
//     : 'Sin finalizar'
//   switch (time.process_status) {
//     case 1: {
//       status = 'En proceso'
//       break
//     }
//     case 2: {
//       status = 'Stand By'
//       break
//     }
//     case 3: {
//       status = 'Finalizado'
//       break
//     }
//     default: {
//       status = 'Sin iniciar'
//       break
//     }
//   }

//   return `
//     <div>
//       <b>${status}</b><br />
//       <b>Inicio: </b>${startDate}<br />
//       <b>Fin: </b>${endDate || 'Sin fecha'}<br />
//     </div>`
// }

export const WorkOrderCard = ({
  workOrder,
  loadingBoxes,
  extraTime,
  setWorkOrderToAdministrate,
  startWorkOrder,
  updateWorkOrderProcessTimeTrackerAction,
  deleteWorkOrder,
}) => {
  const [detailsExpanded, setDetailsExpanded] = useState(false)

  const handleEditClick = (e) => {
    e.stopPropagation()
    setWorkOrderToAdministrate(workOrder, true)

  }

  const handleDeleteClick = (e) => {
    e.stopPropagation()
    deleteWorkOrder(workOrder)
  }

  const toggleDetailsExpanded = (e) => {
    e.stopPropagation()
    setDetailsExpanded(!detailsExpanded)
  }

  const {
    mechanic,
    budgets: budget,
    budgets,
    work_order_comments,
    work_order_id,
    work_order_start,
  } = workOrder
  let { timeTracker } = workOrder
  timeTracker = _.values(
    _.orderBy(
      timeTracker.filter((tracker) => tracker.process),
      ['time_tracker_sequence'],
      ['asc']
    )
  )
  let brand
  let model
  let plate
  let year
  let mechanicName
  const processesQuantity = timeTracker.length
  const now = new Date().getTime()
  const startDateTime = new Date(work_order_start).getTime()
  const difference = now - startDateTime
  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
  const finishedProcesses = timeTracker.filter(
    (time) => time.process_status === 3
  ).length
  // finishedProcesses
  const currentActiveProcess = _.filter(timeTracker, (t) => {
    return t.process_status === 1
  })
  const nextProcess = _.filter(timeTracker, (t) => {
    return t.process_status === 0
  })
  let processName = 'No hay proceso activo'
  let className = 'nullProgress'
  // workOrder.work_order_status_id
  if (workOrder.work_order_status_id === 1 && workOrder.work_order_type !== 0) {
    // !=3
    if (currentActiveProcess[0]) {
      if (
        currentActiveProcess[0].process.process_estimated_time - minutes >
        0
      ) {
        className = null
        processName = currentActiveProcess[0].process.process_name
      } else if (nextProcess[0]) {
        const newNextProcess = {
          ...nextProcess,
          process_status: 1,
        }
        updateWorkOrderProcessTimeTrackerAction(newNextProcess)
        className = null
        processName = nextProcess[0].process.process_name
      }
    } else if (
      finishedProcesses > 0 &&
      finishedProcesses === timeTracker.length
    ) {
      className = null
      processName = timeTracker[timeTracker.length - 1].process.process_name
    } else if (finishedProcesses === 0 && timeTracker.length === 0) {
      // Este escenario solo se cumple cuando una orden se inicio (paso a 1) y luego se le quitaron todos los procesos
      className = null
    }
  }
  const overitingProcessBar = _.filter(extraTime, (e) => {
    return e.work_order_id === workOrder.work_order_id
  })

  // Order with aditional processes in extra time
  const hasPendingProcesses =
    className &&
    workOrder.work_order_status_id === 1 &&
    finishedProcesses < processesQuantity

  const getHeaderColor = (order, extra) => {
    const color = extra ? 3 : order.work_order_status_id
    switch (color) {
      case 1:
        return 'in-progress'
      case 2:
        return 'paused'
      case 3:
        return 'in-extraTime'
      default:
        return ''
    }
  }

  const percentage =
    overitingProcessBar.length > 0
      ? 100
      : Math.round((finishedProcesses / processesQuantity) * 100)
  // const progressBarPercentage = 100 / processesQuantity

  const date = moment(workOrder.work_order_date)
  const dateNow = moment()
  /* const promiseDate = moment(budget.reception.reception_delivery_date) */
  const lapsedDays = (dateNow.unix() - date.unix()) / 86400
  /* const remainingDays = (promiseDate.unix() - dateNow.unix()) / 86400 */
  if (mechanic) {
    mechanicName = `${mechanic.employee_name} ${mechanic.employee_lastname}`
  }
  if (budget) {
    if (budget.reception) {
      if (budget.reception.vehicle) {
        year = budget.reception.vehicle.vehicle_year
        brand = budget.reception.vehicle.model.brand.brand_name
        model = budget.reception.vehicle.model.model_name
        plate = budget.reception.vehicle.vehicle_license_plate
      }
    }
  }

  const getCausePause = () => {
    const options = [
      { value: 1, label: 'Busqueda de Respuesto(s)' },
      { value: 2, label: 'Respuesto(s) Equivocado(s)' },
      { value: 3, label: 'Generando Cotización' },
      { value: 4, label: 'Análisis e Investigación Técnica' },
      { value: 5, label: 'Confirmación de precios con proveedor' },
      { value: 6, label: 'Esperando Confirmación del Cliente' },
      { value: 7, label: 'Pieza en Torno' },
      { value: 8, label: 'Almuerzo' },
      { value: 9, label: 'Receso' },
      { value: 10, label: 'Otro' },
    ]

    const causePause = _.filter(workOrder.timeTracker, (n) => {
      return n.process_status === 2 // si el process status es 3 (como era con la orden 6297) falla, ¿que es 1 y 3? estaba en stand by la orden, en 2 y pause reason del timetracker en 1
    })

    try {
      const option = _.filter(options, (o) => {
        return o.value === causePause[0].pause_reason_id
      })
      return option[0].label
    } catch (exception) {
      return ''
    }
  }

  const getDiffPause = () => {
    // const now = new Date().getTime()
    // const startDateTime = new Date(workOrder.work_order_start).getTime()
    // const difference = now - startDateTime
    const days = Math.floor(difference / (1000 * 60 * 60 * 24))
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    )
    // const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
    return `${days > 0 ? days : 0} días, ${hours} horas, ${minutes} minutos`
  }

  const startWorkOrderClick = (e) => {
    e.stopPropagation()
    startWorkOrder(workOrder)
  }

  const getWarnStatusBar = () => {
    if (hasPendingProcesses) {
      return (
        <StatusBar key={currentActiveProcess.time_tracker_id}>
          <ExclamationTriangle fill="#f4bc0d" />
          <p>Procesos adicionales pendientes</p>
        </StatusBar>
      )
    }

    // Paused order
    if (workOrder.work_order_status_id === 2) {
      return (
        <StatusBar
          key={currentActiveProcess.time_tracker_id}
          className="status-paused"
        >
          <div>
            <PauseCircleFilled style={{ color: '#CF0B0B', fontSize: '2rem' }} />
            <p className="status-name">Orden de trabajo en pausa</p>
          </div>

          {workOrder.work_order_status_id === 2 && (
            <div className="pause-details">
              <p>
                <span>Causa: </span>
                {`${getCausePause(workOrder)}`}
              </p>
              <p>
                <span>Tiempo en pausa: </span> {`${getDiffPause(workOrder)}`}
              </p>
            </div>
          )}
        </StatusBar>
      )
    }

    return (
      <StatusBar key={currentActiveProcess.time_tracker_id}>
        <ExclamationTriangle color="#f4bc0d" />
        <p>
          Orden de trabajo sin iniciar
          {hasPendingProcesses ? 'Orden de trabajo sin iniciar' : ''}
        </p>
      </StatusBar>
    )
  }

  return (
    <Card
      loading={loadingBoxes[workOrder.work_order_id] ? 'true' : ''}
      onClick={() => setWorkOrderToAdministrate(workOrder)}
    >
      <Panel.Heading
        bsClass="panel-heading"
        className={getHeaderColor(workOrder, overitingProcessBar.length)}
      >
        <Panel.Title>
          <PanelHeader>
            <CustomTitle>
              {`(#${work_order_id}) ${plate} ${brand} ${model} ${year}`}
            </CustomTitle>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              {/* <IconButton
                      type="button"
                      data-tip="Finalizar"
                      onClick={() => {
                        finishWorkOrder(workOrder)
                      }}
                      disabled={workOrder.work_order_status_id === 2}
                    >
                      <i
                        className="fas fa-check-circle"
                        style={{
                          marginRight: '5px',
                          fontSize: '2rem',
                          color: `${
                            workOrder.work_order_status_id !== 1 ||
                            overitingProcessBar.length > 0
                              ? '#06d6a0'
                              : '#fff'
                          }`,
                        }}
                      />
                    </IconButton> */}
              {/* <IconButton
                      type="button"
                      onClick={() =>
                        this.setWorkOrderToAdministrate(workOrder)
                      }
                      data-tip="Administrar"
                    >
                      <i
                        className="fas fa-wrench prefix"
                        style={{ marginRight: '5px', fontSize: '2rem' }}
                      />
                    </IconButton> */}
              <IconButton
                type="button"
                data-tip="Agregar"
                onClick={handleEditClick}
              >
                <PlusCircleFilled style={{
                  fontSize: '2rem',
                  color: '#fff',
                }} />
              </IconButton>
              <IconButton
                type="button"
                data-tip="Eliminar"
                onClick={handleDeleteClick}
              >
                <DeleteFilled style={{
                  fontSize: '2rem',
                  color:
                    workOrder.work_order_status_id === 2
                      ? '#FF9393'
                      : '#e63946',
                }} />
              </IconButton>
            </div>
          </PanelHeader>
        </Panel.Title>
      </Panel.Heading>
      <Body style={{ paddingTop: '5px' }}>
        {className ? (
          <FlexWrapper>
            {getWarnStatusBar()}
            <ButtonsContainer>
              {/* Shows button only if not started */}
              {workOrder.work_order_status_id !== 2 && (
                <Button
                  onClick={startWorkOrderClick}
                  bsStyle="success"
                  className="btn-svg-icon"
                >
                  <PlayFaIcon color="#fff" size="14px" />
                  Iniciar
                </Button>
              )}
              <Button
                onClick={() => setWorkOrderToAdministrate(workOrder)}
                bsStyle="info rounded"
                className="btn-antd-icon"
              >
                <SettingFilled style={{ fontSize: '14px' }} />
                Administrar
              </Button>
            </ButtonsContainer>
          </FlexWrapper>
        ) : (
          <FlexWrapper>
            <StatusBar
              key={currentActiveProcess.time_tracker_id}
              $bgColor="#EDFCED"
            // data-tip={getData(currentActiveProcess)}
            >
              <p>Proceso activo: </p>
              <span>{processName}</span>
            </StatusBar>
            <ButtonsContainer>
              <Button
                className="rounded "

                onClick={() => setWorkOrderToAdministrate(workOrder)}
                bsStyle="info rounded"
              >
                <i className="fa fa-cog prefix" style={{ marginRight: '5px' }} />
                Administrar
              </Button>
            </ButtonsContainer>
          </FlexWrapper>
        )}
        <FlexWrapper>
          <MainDetailsWrapper>
            <DetailsData>
              <ToolFilled />
              Mecánico:
              <span>{mechanicName}</span>
            </DetailsData>
            <DetailsData>
              <ClockCircleOutlined />
              Días transcurridos:
              <span>{parseInt(lapsedDays, 10)}</span>
            </DetailsData>
          </MainDetailsWrapper>
          <DetailsButton type="button" onClick={toggleDetailsExpanded}>
            {detailsExpanded ? 'Ocultar detalles' : 'Ver detalles'}{' '}
            {detailsExpanded ? (<UpOutlined />) : (<DownOutlined />)}
          </DetailsButton>
        </FlexWrapper>
        {detailsExpanded && (
          <ClientDetailsWrapper>
            <DetailsData>
              <UserOutlined />
              Cliente:
              <span>{`${budgets?.reception?.payor.person_firstname} ${budgets?.reception?.payor.person_lastname}`}</span>
            </DetailsData>
            <DetailsData>
              <PhoneFilled />
              Teléfono:
              <span>{budgets?.reception?.payor.person_phone}</span>
            </DetailsData>
            <DetailsData>
              <CalendarOutlined />
              Fecha:
              <span>{date.format('dddd DD MMMM YYYY')}</span>
            </DetailsData>
          </ClientDetailsWrapper>
        )}
        <div>
          <div>
            {work_order_comments && work_order_comments !== 'null' && (
              <Row>
                <Col sm={12}>
                  <Alert bsStyle="danger">
                    <div style={{ display: 'flex' }}>
                      <div>
                        <h2 style={{ display: 'inline-block' }}>
                          <ExclamationCircleOutlined />
                        </h2>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          marginLeft: '10px',
                        }}
                      >
                        <h5
                          style={{
                            display: 'inline-block',
                            marginTop: '10px',
                          }}
                        >
                          <b>{work_order_comments}</b>
                        </h5>
                      </div>
                    </div>
                  </Alert>
                </Col>
              </Row>
            )}
          </div>
          {/* <BottomDiv> */}
          {/* <Col sm={3}>
                    <center>
                      <SubTitle align="center">
                        <i className="far fa-calendar-alt" />
                        {' Estimado:'}{' '}
                        {promiseDate.format('dddd DD MMMM YYYY')}
                      </SubTitle>
                    </center>
                    </Col> */}

          {/* <Col sm={3}>
                    <center>
                      <SubTitle color="#BB0000">
                        <i className="far fa-hourglass" />
                        {` ${parseInt(remainingDays, 10) + 1}`} días
                        restantes
                      </SubTitle>
                    </center>
                    </Col> */}
          {/* </BottomDiv> */}
        </div>
      </Body>
      <div style={{ padding: '0 10px' }}>
        <center>
          <b>{percentage || 0}%</b>
        </center>
        <ProgressBar
          active
          animated
          bsStyle={workOrder.work_order_status_id === 2 ? 'danger' : 'info'}
          now={percentage || 0}
        />
      </div>
    </Card>
  )
}

const CustomTitle = styled.h4`
  font-size: 1.5rem;
  font-weight: bold;

  @media (${BREAK_POINTS_DESKTOP_FIRST.MOBILE}) {
    font-size: 1.25rem;
  }
`

const IconButton = styled.button`
  background-color: transparent;
  border-color: transparent;
  &:focus {
    border-color: transparent;
  }
`

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 0;
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  flex-wrap: wrap;

  @media (${BREAK_POINTS_DESKTOP_FIRST.MOBILE}) {
    padding: 10px 0 0px;
  }
`

const MainDetailsWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  @media (${BREAK_POINTS_DESKTOP_FIRST.MOBILE}) {
    gap: 5px;
  }
`

const ClientDetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 5px;
  background-color: #f8f8f8;
  padding: 15px 10px;

  @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
    flex-direction: column;
    gap: 8px;
  }
`

const DetailsData = styled.p`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  font-weight: 700;
  margin: 0;

  span {
    font-weight: 400;
  }
`

const DetailsButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;

  display: flex;
  color: #787878;
  align-items: center;
  gap: 6px;
  font-weight: 500;
  outline: revert;
  border-bottom: solid 1px #000;

  &:hover {
    font-weight: 700;
  }

  @media (${BREAK_POINTS_DESKTOP_FIRST.MOBILE}) {
    margin-top: 12px;
    margin-left: auto;
  }
`

const StatusBar = styled.div`
  display: flex;
  flex: 1;
  margin-right: 10px;
  flex-wrap: wrap;
  align-items: center;
  gap: 6px;
  padding: 1rem;
  border-radius: 10px;
  font-weight: 700;
  text-transform: uppercase;

  background-color: ${(props) => props.$bgColor || '#F4F4F4'};

  @media (${BREAK_POINTS_DESKTOP_FIRST.MOBILE}) {
    flex: auto;
    width: 100%;
  }

  span {
    color: #40a540;
    @media (${BREAK_POINTS_DESKTOP_FIRST.MOBILE}) {
      font-size: 0.9em;
    }
  }

  p {
    margin: 0;
    @media (${BREAK_POINTS_DESKTOP_FIRST.MOBILE}) {
      font-size: 0.9em;
    }
  }

  i {
    color: #f4bc0d;
  }

  &.status-paused {
    flex-direction: column;
    align-items: flex-start;
    div:first-child {
      display: flex;
      gap: 6px;
      flex-wrap: wrap;

      i,
      p.status-name {
        color: #cf0b0b;
      }

      i {
        font-size: 2rem;
      }
    }

    .pause-details {
      display: flex;
      gap: 1.4rem;
      flex-wrap: wrap;
      color: #000;
      text-transform: none;

      @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
        gap: 8px;
      }

      p {
        font-weight: 400;
        @media (${BREAK_POINTS_DESKTOP_FIRST.MOBILE}) {
          font-size: 1.2rem;
        }
      }

      span {
        color: #000;
        font-weight: bold;
        @media (${BREAK_POINTS_DESKTOP_FIRST.MOBILE}) {
          font-size: 1.2rem;
        }
      }
    }
  }
`
