import styled from 'styled-components'

export const ImageSectionWrapper = styled.section`
  width: 100%;
  height: 100%;
  background-image: ${(props) => `url(${props.backgroundImage})`};
  background-color: rgba(0, 0, 0, 0.5);
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  background-position: center;

  display: flex;
  flex-direction: column;

  .content-wrapper {
    width: 100%;
    min-height: 100%;
    border-radius: 10px;

    backdrop-filter: blur(2px);
    backdrop-filter: brightness(30%);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    align-items: center;
    color: white;

    .background-wrapper {
      width: 100%;
      height: 100%;
      overflow: hidden;

      .content {
        display: flex;
        justify-content: center;
        border-radius: 10px;
        align-items: stretch;
        height: 100%;
      }
    }
  }
`
