import React from 'react'
import { PropTypes } from 'prop-types'
import { ProductWrapper, ProductsWrapper } from './Products.styles'
import { useAddReceptionContext } from '../../../../../../../contexts/AddReceptionContext'
import EmptyState from '../../../../../Common/EmptyState/EmptyState'
import { getTotalWithDiscount } from '../../../../../../Utilities/totalWithDiscount'

const Product = ({ name, price, cant, unitPrice, discount }) => {
  return (
    <ProductWrapper>
      <div className="name">
        <p className="product-name">{name}</p>
        {(price !== unitPrice || cant > 1) && (
          <p className="product-price">
            Precio unitario: ${unitPrice.toFixed(2)}{' '}
            {discount > 0 && <span>-{discount}%</span>}
          </p>
        )}
        <p className="required">
          <i className="fas fa-exclamation-circle" />
          Obligatorio
        </p>
      </div>
      <div className="detail">
        <p className="cant">{cant}</p>
        <p className="price">${(parseFloat(price, 10) * cant).toFixed(2)}</p>
      </div>
    </ProductWrapper>
  )
}
Product.propTypes = {
  name: PropTypes.string.isRequired,
  price: PropTypes.any.isRequired,
  unitPrice: PropTypes.number.isRequired,
  discount: PropTypes.number.isRequired,
  cant: PropTypes.any.isRequired,
}

const Products = () => {
  const { state } = useAddReceptionContext()
  const { selectedProducts } = state

  if (selectedProducts.length < 1) {
    return (
      <ProductsWrapper>
        <EmptyState text="Sin respuestos registrados" />
      </ProductsWrapper>
    )
  }
  return (
    <ProductsWrapper>
      {selectedProducts.map((item) => (
        <Product
          key={item.productName}
          name={item.productName}
          price={getTotalWithDiscount(
            item.product_placed_price,
            item.product_placed_discount
          )}
          discount={item.product_placed_discount}
          cant={item.product_asked_quantity}
          unitPrice={item.productPrice}
        />
      ))}
    </ProductsWrapper>
  )
}

export default Products
