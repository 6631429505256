import styled from 'styled-components'

const FormControlWrapper = styled.div`
  .MuiFormControl-root {
    width: 100%;
    font-family: 'Lato', Helvetica, Arial, sans-serif;

    .label-colored {
      font-family: 'Lato', Helvetica, Arial, sans-serif;
      font-weight: 600;
      color: #8a8a8a;
      font-size: 15px;
    }

    textarea {
      resize: none;
    }
    
    .label-no-value {
      background-image: unset !important;
      color: #8a8a8a !important;
    }

    .label-error {
      color: #f44336 !important;
      background-image: unset !important;
    }

    .label-value {
      background-image: linear-gradient(to right, #1e1e40, #b80667);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
    }

    .MuiFormLabel-root.Mui-focused {
      background-image: linear-gradient(to right, #1e1e40, #b80667);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      font-size: 20px;
    }
    .MuiInputLabel-shrink {
      background-image: linear-gradient(to right, #1e1e40, #b80667);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      font-size: 20px;
    }
    .MuiFormLabel-root.Mui-error {
      color: #f44336;
      background-image: unset;
    }
    .MuiInput-underline::after,
    .MuiInput-underline:hover:not(.Mui-disabled):before {
      height: 2px;
      width: 100%;
      background: linear-gradient(270deg, #8d2866 52.48%, #1e1e40 100%);
      border-bottom: unset;
    }
    .MuiInput-underline.Mui-error:after {
      background: #f44336;
    }
    .MuiInputBase-input {
      font-size: 14px;
    }
  }
  .error-message {
    font-size: 12px;
  }
`

export default FormControlWrapper
