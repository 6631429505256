import styled from 'styled-components'

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  border: 2px solid #8d2866;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 16px;
  gap: 20px;
  margin: 0 auto;
  margin-bottom: 24px;
  .selected-vehicle-details {
    margin: 0 8px;
    display: flex;
    align-items: center;
    gap: 12px;
    i {
      font-size: 4rem;
    }
    .details {
      p {
        font-weight: 700;
        text-transform: capitalize;
        margin: 0;
      }
      .license-plate {
        font-weight: 300;
      }
    }
  }
  .vehicle-state {
    margin: 0 8px;
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    justify-content: space-between;
    p {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .clients {
    display: flex;
    flex-direction: column;
    gap:8px;
    margin: 0 -16px -16px -16px;
    padding: 16px 14px;
    background-color: #f9f4ff;
    border-radius: 0px 0px 10px 10px;
    .client {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      .client-phone {
        display: flex;
        align-items: center;
        gap: 8px;
        color: #374957;
        i {
          transform: rotate(90deg);
        }
      }
    }
  }
`
