import axios from 'axios'
import _ from 'lodash'

import { GET_VEHICLES, DELETE_VEHICLE } from '../actionTypes/VehicleTypes'
import { NotificationHandler } from '../Helpers'

const getVehiclesAction = (payload) => ({
  type: GET_VEHICLES,
  payload,
})

const deleteVehicleAction = (payload) => ({
  type: DELETE_VEHICLE,
  payload,
})

export const getVehicles = (forceFetch) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const vehiclesQuantity = _.size(getState().VehicleReducer.vehicles)
    if (vehiclesQuantity === 0 || forceFetch) {
      axios
        .get('/api/vehicles')
        .then((response) => {
          if (!response.data.error) {
            const vehicles = _.keyBy(response.data.data, 'vehicle_id')
            dispatch(getVehiclesAction(vehicles))
            resolve(response.data)
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al obtener vehículos',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al obtener vehículos',
            error
          )
        })
    } else {
      resolve({})
    }
  })

export const addVehicle = (vehicle) => (dispatch) =>
  new Promise((resolve, reject) => {
    axios
      .post('/api/vehicles', vehicle)
      .then((response) => {
        if (!response.data.error) {
          axios
            .post('/api/person_vehicles', {
              person_id: vehicle.person_id,
              vehicle_id: response.data.data.vehicle_id,
            })
            .then((result) => {
              if (!result.data.error) {
                dispatch(getVehicles(true))
                resolve(result)
                NotificationHandler('Done', 'success', 'Vehículo agregado')
              } else {
                reject()
                NotificationHandler(
                  'Error',
                  'error',
                  'Error al agregar el vehículo',
                  result.data.data
                )
              }
            })
            .catch((error) => {
              reject()
              NotificationHandler(
                'Error',
                'error',
                'Error al agregar el vehículo',
                error
              )
            })
        } else {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al agregar el vehículo',
            response.data.data
          )
        }
      })
      .catch((error) => {
        reject()
        NotificationHandler(
          'Error',
          'error',
          'Error al agregar el vehículo',
          error
        )
      })
  })

export const updateVehicle = (vehicle) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (vehicle.vehicle_id) {
      axios
        .put(`/api/vehicles/${vehicle.vehicle_id}`, vehicle)
        .then((response) => {
          if (!response.data.error) {
            dispatch(getVehicles(true))
            resolve(response)
            NotificationHandler('Done', 'info', 'Vehículo actualizado')
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al actualizar el vehículo',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al actualizar el vehículo',
            error
          )
        })
    } else {
      reject()
      NotificationHandler(
        'Error',
        'error',
        `No se proporcionó id a: ${vehicle.vehicle_license_plate}`
      )
    }
  })

export const deleteVehicle = (vehicle) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (vehicle.vehicle_id) {
      axios
        .delete(`/api/vehicles/${vehicle.vehicle_id}`)
        .then((response) => {
          if (!response.data.error) {
            dispatch(deleteVehicleAction(vehicle))
            resolve(response)
            NotificationHandler('Done', 'success', 'Vehículo eliminado')
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al eliminar el vehículo',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al eliminar el vehículo',
            error
          )
        })
    } else {
      reject()
      NotificationHandler(
        'Error',
        'error',
        `No se proporcionó id a: ${vehicle.vehicle_license_plate}`
      )
    }
  })
