import axios from 'axios'
import moment from 'moment'
import { NotificationHandler } from '../Helpers'
import { addMultimediaService } from './apiAddPhotosService'

export const fetchProcesses = async () => {
  const response = await axios.get('/api/process/reception/all')

  return response.data
}

export const fetchProducts = async () => {
  const response = await axios.get('/api/products/reception/all')

  return response.data
}

export const createBrand = async (newBrand) => {
  const response = await axios.post('/api/brands/reception', { ...newBrand })

  return response.data
}

export const createModel = async (newModel) => {
  const response = await axios.post('/api/models/reception', { ...newModel })

  return response.data
}

export const createVehicle = async (newVehicle) => {
  const response = await axios.post('/api/vehicles/reception', {
    ...newVehicle,
  })

  return response.data
}

export const createProcess = async (newProcess) => {
  const response = await axios.post('/api/process/reception/', {
    ...newProcess,
  })

  return response.data
}
export const createProduct = async (newProduct) => {
  const response = await axios.post('/api/products/reception/', {
    ...newProduct,
  })

  return response.data
}

export const fetchLastFiveWorkOrders = async (vehiclePlate = '') => {
  const dateFormat = 'YYYY-MM-DD'

  const currentDate = moment().format(dateFormat)
  const threeYearsAgoDate = moment().subtract(5, 'years').format(dateFormat)

  const response = await axios.get(
    `/api/work_orders_history?placa=${vehiclePlate.toLowerCase()}&initialDate=${threeYearsAgoDate}&finalDate=${currentDate}&pageSize=5&page=1`
  )

  return response.data
}

export const fetchCategories = async () => {
  const response = await axios.get('/api/category/reception/all')

  return response.data.data
}

export const createReserveReception = async (clientId, vehicleId, contact) => {
  try {
    const body = {
      vehicleId,
      personId: clientId,
      ...contact,
    }
    const response = await axios.post('/api/receptions/reception/', {
      ...body,
    })
    return response.data
  } catch (error) {
    console.log('Error reservando recepcion')
    return { error: 'no se pudo reservar la recepcion' }
  }
}

export const saveReceptionPhotos = async (receptionId, workShopId, photos) => {
  try {
    await addMultimediaService(receptionId, workShopId, photos, true)
  } catch {
    NotificationHandler(
      'Problema al cargar fotos',
      'warning',
      `Hubo problemas cargando las fotos en la recepción #${receptionId}, puedes intentarlo nuevamente al crear la orden.`,
      null,
      6
    )
  }
}

export const createReception = async (receptionId, body) => {
  try {
    const response = await axios.patch(
      `/api/receptions/reception/details/${receptionId}`,
      {
        ...body,
      }
    )
    return response.data
  } catch (error) {
    throw ('Error creando recepcion', error)
  }
}

export const editReception = async (receptionId, body) => {
  try {
    const response = await axios.patch(
      `/api/receptions/reception/${receptionId}`,
      {
        ...body,
      }
    )
    return response.data
  } catch (error) {
    throw ('Error al editar recepcion', error)
  }
}

export const getOneReceptionDetail = async (receptionId) => {
  try {
    const response = await axios.get(`/api/receptions/reception/${receptionId}`)
    return response.data
  } catch (error) {
    throw ('Error al obtener detalle de recepción', error)
  }
}
