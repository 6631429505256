import React from 'react'
import { Button } from 'react-bootstrap'
import ReactToolTip from 'react-tooltip'
import moment from 'moment'
import { textFilter } from 'react-bootstrap-table2-filter'
import { EditFilled, EyeFilled, DeleteFilled } from '@ant-design/icons';
import {
  formatterHeaderStyle,
  columnHeaderStyle,
  columnStyle,
} from '../../Utilities/TableStyles'

const styleIcons = {
  fontSize: '17px',
}

const productOrderInvoiced = (row) => Object.keys(row.invoice).length > 0

const editButton = (update, can_edit) => (cell, row) =>
(
  <Button
    onClick={() => {
      update(row)
    }}
    bsStyle="link"
    bsSize="small"
    data-tip={can_edit ? 'Modificar' : 'Ver'}
    disabled={productOrderInvoiced(row)}
  >
    {can_edit ? <EditFilled style={styleIcons} /> : <EyeFilled style={styleIcons} />}
    <ReactToolTip />
  </Button>
)

const deleteButton = (_delete) => (cell, row) =>
(
  <Button
    onClick={() => _delete(row)}
    bsStyle="link"
    bsSize="small"
    style={{ color: 'red' }}
    data-tip="Eliminar"
    disabled={productOrderInvoiced(row)}
  >
    <DeleteFilled style={styleIcons} />
    <ReactToolTip />
  </Button>
)

const stateLabel = (invoice) => (
  <span>{Object.keys(invoice).length > 0 ? 'Procesada' : 'Sin procesar'}</span>
)

const texFilterOption = {
  delay: 100,
  style: {
    margin: '10px 0 0 0',
    padding: '20px 2%',
  },
  placeholder: 'Buscar',
}

const getDate = (date) => moment(date).format('dddd D MMMM YYYY')

const getDollar = (total) =>
  total.toLocaleString('en', { style: 'currency', currency: 'USD' })

const getColumns = (update, _delete, { can_edit, can_delete }) => {
  const columns = [
    {
      dataField: 'work_order_id',
      text: 'Número',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: textFilter(texFilterOption),
      formatter: (id) => `#${id}`,
    },
    {
      dataField: 'work_order_date',
      text: 'Fecha',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: textFilter(texFilterOption),
      formatter: getDate,
    },
    {
      dataField: 'fullNameClient',
      text: 'Cliente',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: textFilter(texFilterOption),
    },
    {
      dataField: 'fullNameEmployee',
      text: 'Vendedor',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: textFilter(texFilterOption),
    },
    {
      dataField: 'work_order_total',
      text: 'Total',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: textFilter(texFilterOption),
      formatter: getDollar,
    },
    {
      dataField: 'invoice',
      text: 'Estado',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: textFilter(texFilterOption),
      formatter: stateLabel,
    },
    {
      dataField: '1',
      className: 'test',
      text: '',
      headerStyle: formatterHeaderStyle,
      style: columnHeaderStyle({ textAlign: 'center' }),
      formatter: editButton(update, can_edit),
    },
  ]
  if (can_delete) {
    columns.push({
      dataField: '3',
      text: '',
      headerStyle: formatterHeaderStyle,
      style: columnHeaderStyle({ textAlign: 'center' }),
      formatter: deleteButton(_delete),
    })
  }
  return columns
}

export default getColumns
