import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'
import { Image } from 'antd'

import WarningDisclaimerAnimated from '../../../Common/WarningDisclaimerAnimated/WarningDisclaimerAnimated'
import PreviousNextButton from '../../../../buttons/PreviousNextButton/PreviousNextButton'
import { useAddReceptionContext } from '../../../../../contexts/AddReceptionContext'
import PhotoInputCameraOpen from '../../../Common/PhotoInputCameraOpen/PhotoInputCameraOpen'
import { addMultimediaService } from '../../../../../api/apiAddPhotosService'
import { addReceptionActions } from '../../../../../reducers/AddReceptionReducer'
import GradientTextComponent from '../../../Common/GradientText.js/GradientText'
import { saveReceptionPhotos } from '../../../../../api/newReceptionService'
import VideosInput from '../../../Common/VideosInput/VideosInput'
import { multimediaTypes } from '../../../../../Constants'
import { getNextButtonText } from '../../../../../Helpers'
import { PhotoStepWrapper } from './PhotoStep.styles'

const PhotoStep = ({ handleNext, handlePrevious, workshopId }) => {
  const { state, dispatch } = useAddReceptionContext()
  const {
    photos: photosValue,
    videos: videosValue,
    receptionId,
    isEdit,
  } = state
  const [photos, setPhotos] = useState(photosValue.photos || [])
  const [videos, setVideos] = useState(videosValue || [])

  const skipButtonEnabled = isEdit
    ? false
    : photosValue?.length < 1 &&
      photos.length < 1 &&
      videosValue?.length < 1 &&
      videos.length < 1

  const nextText = getNextButtonText(!skipButtonEnabled, isEdit)

  const abortController = new AbortController()
  const { signal } = abortController

  const handleAddPhotos = (value) => {
    setPhotos(value)
  }

  const handleAddVideos = (value) => {
    setVideos(value)
  }

  const handleSavePhotos = () => {
    if (photosValue?.length > 0) {
      return
    }

    if (photos.length > 0) {
      saveReceptionPhotos(receptionId, workshopId, photos)
      dispatch({
        type: addReceptionActions.SET_PHOTOS,
        payload: photos,
      })
    }
  }

  const uploadOneVideo = async (file) => {
    await addMultimediaService(
      receptionId,
      workshopId,
      [file],
      true,
      multimediaTypes.VIDEO,
      signal
    )
  }

  const handleSaveVideos = () => {
    if (videos.length > 0) {
      dispatch({
        type: addReceptionActions.SET_VIDEOS,
        payload: videos,
      })
    }
  }

  const handlePrevStep = () => {
    handleSaveVideos()
    handlePrevious()
  }

  return (
    <>
      <main className="new-reception__main">
        <div className="new-reception__main__question">
          <GradientTextComponent text="Fotografías y videos" />
        </div>
        <div className="new-reception__main__content">
          <PhotoStepWrapper>
            {photosValue?.length < 1 ? (
              <>
                {photos.length < 1 && (
                  <WarningDisclaimerAnimated message="Las fotografías y videos pueden ser tu mejor <b>aliado</b>. Captura el estado del vehículo para tener un registro completo." />
                )}
                <div className="photo-input">
                  <PhotoInputCameraOpen
                    photos={photos}
                    maxPhotosAllowed={4}
                    handleAddPhoto={handleAddPhotos}
                  />
                </div>
              </>
            ) : (
              <>
                <h4>Fotografías agregadas ({photosValue.length})</h4>
                <div className="saved-photos-wrapper">
                  <Image.PreviewGroup>
                    {photosValue?.map((url, index) => (
                      // Images loaded from open camera do not have uid
                      <Image
                        key={
                          url.lastModified
                            ? url.lastModified + url?.uid
                            : url.newReceptionPhotoId || index
                        }
                        src={
                          url.originFileObj
                            ? URL.createObjectURL(url.originFileObj)
                            : url.url
                        }
                      />
                    ))}
                  </Image.PreviewGroup>
                </div>
              </>
            )}
            <div className="photo-input">
              <VideosInput
                videos={videos}
                maxVideosAllowed={4}
                handleAddVideo={handleAddVideos}
                uploadOneVideo={uploadOneVideo}
                abortController={abortController}
              />
            </div>
          </PhotoStepWrapper>
        </div>
      </main>
      <div className="new-reception__navigation-buttons">
        <PreviousNextButton isPrevious onClickHandler={handlePrevStep} />
        <PreviousNextButton
          nextText={nextText}
          isSkip={skipButtonEnabled}
          isPrevious={false}
          onClickHandler={() => {
            handleSavePhotos()
            handleSaveVideos()
            handleNext()
          }}
        />
      </div>
    </>
  )
}

const mapStateToProps = (store) => ({
  workshopId: store.AppReducer.workshopId,
})

PhotoStep.propTypes = {
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
  workshopId: PropTypes.number.isRequired,
}

export default connect(mapStateToProps)(PhotoStep)
