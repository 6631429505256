import _ from 'lodash'

import {
  GET_MODELS,
  ADD_MODEL,
  UPDATE_MODEL,
  DELETE_MODEL,
} from '../actionTypes/modelTypes'

const ModelReducer = (
  state = {
    models: {},
  },
  action
) => {
  if (action.type === GET_MODELS) {
    return {
      ...state,
      models: action.payload,
    }
  }
  if (action.type === ADD_MODEL || action.type === UPDATE_MODEL) {
    return {
      ...state,
      models: {
        ...state.models,
        [action.payload.model_id]: action.payload,
      },
    }
  }
  if (action.type === DELETE_MODEL) {
    const newState = _.cloneDeep(state)
    delete newState.models[action.payload.model_id]

    return newState
  }

  return state
}

export default ModelReducer
