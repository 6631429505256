import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import { useFormContext, Controller } from 'react-hook-form'
import { FormControl, FormHelperText, InputLabel } from '@material-ui/core'
import PhoneFormControlWrapper from './PhoneFormControl.styles'
import PhoneInputComponent from '../PhoneInput/PhoneInput'

const PhoneFormControl = ({
  label,
  error,
  name,
  required,
  requiredMessage,
  workShopCode,
}) => {
  const { control, watch } = useFormContext()
  const [errorMessage, setErrorMessage] = useState(false)
  const [focus, setFocus] = useState(false)
  const phoneValue = watch(name)
  const labelClass =
    phoneValue || focus
      ? 'label-colored label-colored-value'
      : 'label-colored'
  return (
    <PhoneFormControlWrapper
      error={errorMessage || error}
      isValue={phoneValue || focus}
    >
      <FormControl error={!!error}>
        <InputLabel htmlFor={name} className={labelClass}>
          {label}
          {required && !phoneValue ? '*' : ''}
        </InputLabel>
        <Controller
          name={name}
          defaultValue=""
          control={control}
          render={({ field: { onChange, value, ref } }) => (
            <PhoneInputComponent
              showError={false}
              phone={value}
              onChange={onChange}
              isError={!!errorMessage}
              setError={setErrorMessage}
              countryCode={parseInt(workShopCode, 10)}
              phoneRef={ref}
              handleFocus={setFocus}
            />
          )}
          rules={{
            required: {
              value: required,
              message: requiredMessage,
            },
          }}
        />
        {(errorMessage || error) && (
          <FormHelperText className="error-message" id={`${name}-text`}>
            {errorMessage ? 'Ingrese un numero de teléfono valido.' : error}
          </FormHelperText>
        )}
      </FormControl>
    </PhoneFormControlWrapper>
  )
}

PhoneFormControl.defaultProps = {
  required: false,
  requiredMessage: '',
  error:''
}

PhoneFormControl.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  workShopCode: PropTypes.any.isRequired,
  requiredMessage: PropTypes.string,
}
export default PhoneFormControl
