import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Col,
  Button,
  Row,
  Form,
  Tooltip,
} from 'react-bootstrap';
import { isValidPhoneNumber } from 'react-phone-number-input'
import { SaveOutlined } from '@ant-design/icons'
import Contacts from './contacts/contacts';
import { getValidationState, showConfirmDialog } from '../../Common';



import PhoneInputStandalone from '../../Common/PhoneInputStandalone/PhoneInputStandalone'
import { getCountryCode, formatPhoneToDB } from '../../../Utilities/formatPhone'

const Tool = ({ text }) => <Tooltip id="tooltip">{text}</Tooltip>;

function ProviderModal({
  editMode,
  modalOpen,
  toggleModal,
  addProvider,
  updateProvider,
  selectedProvider: initialProvider,
  pagePermissions,
  workshopCountryCode
}) {
  const [provider, setProvider] = useState({
    provider_name: '',
    provider_adress: '',
    provider_phone: '',
    phoneError: false,
    country_code: null
  });

  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState({});

  useEffect(() => {
    setProvider({
      provider_name: initialProvider.provider_name,
      provider_adress: initialProvider.provider_adress,
      provider_phone: initialProvider.provider_phone,
      country_code: initialProvider.country_code,
    });
    setContacts(
      _.keyBy(initialProvider.providerContacts, 'provider_contact_id') || {}
    );
    setLoading(false);
  }, [initialProvider]);

  const clearComponent = () => {
    setProvider({
      provider_name: '',
      provider_adress: '',
      provider_phone: '',
      country_code: ''
    });
    setContacts({});
    setLoading(false);
  };

  const deleteContactItem = (e, id) => {
    e.preventDefault();
    const newContacts = { ...contacts };

    if (editMode) {
      const tmpContact = _.find(
        contacts,
        (c) => `${c.provider_contact_id}` === `${id}`
      );
      const { provider_contact_id } = tmpContact;

      if (tmpContact && !`${provider_contact_id}`.includes('tmp')) {
        newContacts[id] = {
          ...tmpContact,
          provider_active: 0,
        };
      } else {
        delete newContacts[id];
      }
    } else {
      delete newContacts[id];
    }

    setContacts(newContacts);
  };

  const handleChangeContact = (e, id) => {
    const { name, value } = e.target;
    const newContacts = {
      ...contacts,
      [id]: {
        ...contacts[id],
        [name]: value,
      },
    };
    setContacts(newContacts);
  };

  const handlePhoneEdit = (value) => {
    const countryCode = getCountryCode(value);
    setProvider(({
      ...provider,
      provider_phone: value,
      country_code: countryCode || '',
      phoneError: value ? !isValidPhoneNumber(value) : null, // Validate phone number here
    }));
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    toggleModal();

    const providerToSubmit = {
      ...provider,
      provider_phone: formatPhoneToDB(provider.provider_phone),
      provider_id: initialProvider.provider_id || null,
      country_code: provider.country_code
    };

    if (editMode) {
      showConfirmDialog(
        '¿Modificar?',
        `¿Modificar el proveedor: ${providerToSubmit.provider_name}`,
        updateProvider,
        providerToSubmit,
        () => { },
        toggleModal,
        toggleModal,
        contacts
      );
    } else {
      addProvider(providerToSubmit, contacts)
        .then(() => {
          clearComponent();
        })
        .catch(() => {
          setLoading(false);
          toggleModal();
        });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedProvider = {
      ...provider,
      [name]: value,
    };
    setProvider(updatedProvider);
  };

  const { provider_name, provider_adress, provider_phone, phoneError, country_code } = provider;

  let can_edit = false;

  if (pagePermissions) {
    can_edit = pagePermissions.can_edit;
  }

  let title = editMode ? 'Modificar' : 'Agregar';

  if (title === 'Modificar') title = can_edit ? 'Modificar' : '';

  const validatePhone = () => (phoneError ? 'error' : null);

  return (
    <Modal show={modalOpen} onHide={toggleModal}>
      <Modal.Header closeButton>
        <Modal.Title>{title} Proveedor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={12}>
              <FormGroup validationState={getValidationState(provider_name)}>
                <ControlLabel>Nombre: </ControlLabel>
                <FormControl
                  name="provider_name"
                  type="text"
                  value={provider_name}
                  onChange={handleChange}
                  required
                  placeholder="Ingresar"
                />
              </FormGroup>
              <FormGroup validationState={getValidationState(provider_adress)}>
                <ControlLabel>Dirección: </ControlLabel>
                <FormControl
                  name="provider_adress"
                  type="text"
                  componentClass="textarea"
                  value={provider_adress}
                  onChange={handleChange}
                  required
                  placeholder="Ingresar"
                />
              </FormGroup>
              <FormGroup validationState={validatePhone()}>
                <ControlLabel>Télefono:</ControlLabel>
                <PhoneInputStandalone
                  name="provider_phone"
                  phone={provider_phone}
                  onPhoneChange={(value) => handlePhoneEdit(value)}
                  setError={(error) => setProvider((prevState) => ({
                    ...prevState,
                    phoneError: error,
                  }))}
                  isError={phoneError}
                  countryCode={country_code || workshopCountryCode || localStorage.getItem('workshopCountryCode')}
                  required
                />
              </FormGroup>
            </Col>
          </Row>

          <Contacts
            contactsList={contacts}
            handleChangeContact={handleChangeContact}
            handleRemoveContact={deleteContactItem}
          />
          <button type="submit" hidden id="submitCategoryForm" aria-labelledby='submitCategoryForm' />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={toggleModal}>Cerrar</Button>
        {can_edit && (
          <Button
            disabled={loading}
            bsStyle="primary"
            onClick={() => {
              document.getElementById('submitCategoryForm').click();
            }}
            className="btn-antd-icon"
          >
            <SaveOutlined />
            {editMode ? ' Modificar' : ' Agregar'}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

Tool.propTypes = {
  text: PropTypes.string.isRequired,
};

ProviderModal.propTypes = {
  editMode: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  addProvider: PropTypes.func.isRequired,
  updateProvider: PropTypes.func.isRequired,
  selectedProvider: PropTypes.objectOf(PropTypes.object),
  pagePermissions: PropTypes.objectOf(PropTypes.object).isRequired,
  workshopCountryCode: PropTypes.string.isRequired,
};

ProviderModal.defaultProps = {
  selectedProvider: null, // Provide a default value or null if it's not required
};

export default ProviderModal;
