import React from 'react'
import { Button, FormControl, ControlLabel } from 'react-bootstrap'
import styled from 'styled-components'
import Select from 'react-select'
import _ from 'lodash'
import PropTypes from 'prop-types'

const getTotal = (detail) => {
  let {
    work_order_detail_workforce_price,
    work_order_detail_item_price,
    work_order_detail_item_quantity,
  } = detail
  if (work_order_detail_workforce_price === '') {
    work_order_detail_workforce_price = 0
  }
  if (work_order_detail_item_price === '') {
    work_order_detail_item_price = 0
  }
  if (work_order_detail_item_quantity === '') {
    work_order_detail_item_quantity = 0
  }
  let totalProducts = parseFloat(
    work_order_detail_item_price * (work_order_detail_item_quantity || 0)
  )

  if (detail.work_order_detail_discount) {
    totalProducts -= totalProducts * (detail.work_order_detail_discount / 100)
  }

  let totalWorkforce = parseFloat(work_order_detail_workforce_price || 0)
  if (detail.work_order_detail_discount_workforce) {
    totalWorkforce -=
      totalWorkforce * (detail.work_order_detail_discount_workforce / 100)
  }

  const total = parseFloat(totalProducts || 0) + parseFloat(totalWorkforce || 0)

  return total.toLocaleString('en', { style: 'currency', currency: 'USD' })
}

const getProducts = (products) => {
  const codeProduct = _.map(products, (product) => {
    const aux = _.cloneDeep(product)
    aux.fullName = `${aux.product_name} - ${aux.product_bar_code}`
    return aux
  })
  return [...codeProduct]
}

const getAvailableOptions = (processes) =>
  _.filter(processes, (process) => !process.isUsed)

const WorkOrderDetail = ({
  detail,
  products,
  processes,
  id,
  handleChangeDetail,
  deleteWorkOrderDetail,
  handleChangeSelectDetail,
}) => {
  const {
    work_order_detail_item,
    work_order_detail_item_price,
    recommended_work_order_detail_item_price,
    fromCombo,
    work_order_detail_workforce_price,
    recommended_work_order_detail_workforce_price,
    work_order_detail_workforce_id,
    work_order_detail_estimated_time,
    work_order_detail_item_quantity,
    work_order_detail_item_max_quantity,
    type_card,
    ableToEdit,
  } = detail
  let time = '0 mins'
  if (work_order_detail_estimated_time) {
    time = `${work_order_detail_estimated_time} ${
      work_order_detail_estimated_time && 'mins'
    }`
  }

  const editedProductNamePlusQuantity = getProducts(products).map((a) => {
    return {
      ...a,
      fullName: `${a.fullName} (${a.product_quantity})`,
      disabled: a.product_quantity <= 0,
    }
  })

  return (
    <Row fromCombo={fromCombo}>
      <ItemTitle typeCard={type_card} />
      {type_card === 'product' && (
        <Product>
          <Select
            placeholder="Repuesto"
            noResultsText="Sin resultados"
            value={work_order_detail_item}
            onChange={(e) =>
              handleChangeSelectDetail(e, 'work_order_detail_item', id)
            }
            valueKey="product_id"
            labelKey="fullName"
            options={editedProductNamePlusQuantity}
            disabled={!!work_order_detail_item}
          />
        </Product>
      )}

      {type_card === 'product' && (
        <ProductQuantity>
          <ControlLabel>Cantidad</ControlLabel>
          <FormControl
            type="text"
            name="work_order_detail_item_quantity"
            value={work_order_detail_item_quantity}
            placeholder="Cantidad"
            onChange={(e) =>
              handleChangeDetail(e, id, work_order_detail_item_max_quantity)
            }
          />
        </ProductQuantity>
      )}

      {type_card === 'product' && (
        <ProductPrice>
          <ControlLabel>Precio sugerido</ControlLabel>
          <FormControl
            type="text"
            name="work_order_detail_item_price"
            value={recommended_work_order_detail_item_price || ''}
            placeholder="Precio"
            readOnly
          />
        </ProductPrice>
      )}

      {type_card === 'product' && (
        <ProductPrice>
          <ControlLabel>Precio</ControlLabel>
          <FormControl
            type="text"
            name="work_order_detail_item_price"
            value={work_order_detail_item_price || ''}
            placeholder="Precio"
            onChange={(e) => handleChangeDetail(e, id)}
          />
        </ProductPrice>
      )}

      {type_card === 'service' && (
        <Process>
          <Select
            placeholder="Mano de obra"
            noResultsText="Sin resultados"
            value={work_order_detail_workforce_id}
            onChange={(e) =>
              handleChangeSelectDetail(e, 'work_order_detail_workforce_id', id)
            }
            valueKey="process_id"
            labelKey="process_name"
            options={getAvailableOptions(processes)}
            disabled={!ableToEdit || !!work_order_detail_workforce_id}
          />
        </Process>
      )}

      {type_card === 'service' && (
        <ProcessPrice>
          <ControlLabel>Precio sugerido</ControlLabel>
          <FormControl
            type="text"
            name="work_order_detail_workforce_price"
            value={recommended_work_order_detail_workforce_price}
            placeholder="Precio"
            readOnly
          />
        </ProcessPrice>
      )}

      {type_card === 'service' && (
        <ProcessPrice>
          <ControlLabel>Precio</ControlLabel>
          <FormControl
            type="text"
            name="work_order_detail_workforce_price"
            value={work_order_detail_workforce_price}
            placeholder="Precio"
            onChange={(e) => handleChangeDetail(e, id)}
            disabled={!ableToEdit}
          />
        </ProcessPrice>
      )}

      {type_card === 'service' && (
        <EstimatedTime>
          <FormControl
            type="text"
            value={time}
            placeholder="Estimado"
            readOnly
          />
        </EstimatedTime>
      )}

      <Total>
        <p>Sub-total</p>
        <FormControl
          type="text"
          value={getTotal(detail)}
          placeholder="Total"
          onChange={(e) => handleChangeDetail(e, id)}
          required
          readOnly
        />
      </Total>
      <DeleteButton>
        <center>
          <Button
            onClick={() => deleteWorkOrderDetail(id)}
            style={{ margin: 'auto' }}
            bsSize="sm"
            bsStyle="danger"
            disabled={!ableToEdit}
          >
            <span className="btn-icon">
              <svg viewBox="0 0 24 24" width="100%" height="100%">
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3zm-8 5v6h2v-6H9zm4 0v6h2v-6h-2zM9 4v2h6V4H9z" />
              </svg>
            </span>
            Remover {type_card === 'product' ? 'repuesto' : 'servicio'}
          </Button>
        </center>
      </DeleteButton>
    </Row>
  )
}

const Row = styled.tr`
  ${(props) => props.fromCombo && 'background-color: #b9f6ca;'}
`
const ItemTitle = ({ typeCard }) => (
  <td>{typeCard === 'product' ? 'Repuesto' : 'Servicio'} adicional</td>
)

ItemTitle.propTypes = {
  typeCard: PropTypes.string.isRequired,
}

const Product = styled.td`
  width: 30%;
`

const ProductQuantity = styled.td`
  width: 5%;
`
const ProductPrice = styled.td`
  width: 9%;
`

const Process = styled.td`
  width: 20%;
`
const ProcessPrice = styled.td`
  width: 8%;
`

const EstimatedTime = styled.td`
  width: 8%;
`
const Total = styled.td`
  width: 10%;

  p {
    text-align: center;
    text-decoration: underline;
  }
`
const DeleteButton = styled.td`
  width: 5%;

  button {
    display: flex;
    align-items: center;
    border-radius: 4px !important;

    .btn-icon {
      width: 1.5rem;
      margin-right: 0.5rem;

      svg {
        fill: white;
      }
    }
  }
`

WorkOrderDetail.propTypes = {
  detail: PropTypes.objectOf(PropTypes.any).isRequired,
  products: PropTypes.objectOf(PropTypes.any).isRequired,
  processes: PropTypes.objectOf(PropTypes.any).isRequired,
  id: PropTypes.string.isRequired,
  handleChangeDetail: PropTypes.func.isRequired,
  deleteWorkOrderDetail: PropTypes.func.isRequired,
  handleChangeSelectDetail: PropTypes.func.isRequired,
}

export default WorkOrderDetail
