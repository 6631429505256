/* eslint-disable no-async-promise-executor */
import axios from 'axios'
import _ from 'lodash'

import {
  GET_PERSONS,
  ADD_PERSON,
  UPDATE_PERSON,
  DELETE_PERSON,
  UPDATE_PERSON_PHONE,
} from '../actionTypes/PersonTypes'
import { NotificationHandler } from '../Helpers'
import { addPersonWorkshop } from './PersonWorkshopAction'

const getPersonsAction = (payload) => ({
  type: GET_PERSONS,
  payload,
})

const addPersonAction = (payload) => ({
  type: ADD_PERSON,
  payload,
})

const updatePersonAction = (payload) => ({
  type: UPDATE_PERSON,
  payload,
})

const updatePersonPhoneAction = (payload) => ({
  type: UPDATE_PERSON_PHONE,
  payload,
})

const deletePersonAction = (payload) => ({
  type: DELETE_PERSON,
  payload,
})

export const getPersons = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const personsQuantity = _.size(getState().PersonReducer.persons)
    if (personsQuantity === 0) {
      axios
        .get('/api/persons')
        .then((response) => {
          if (!response.data.error) {
            const persons = _.keyBy(response.data.data, 'person_id')
            dispatch(getPersonsAction(persons))
            resolve(response.data)
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al obtener los clientes',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al obtener los clientes',
            error
          )
        })
    } else {
      resolve({})
    }
  })

export const getPersonsToSync = (query) => () =>
  new Promise(async (resolve, reject) => {
    try {
      const result = await axios.get(
        `/api/persons/allpersons/search?query=${query}`
      )
      const persons = result.data.data

      const personsFormatted = _.values(persons).map((person) => ({
        ...person,
        full: `${person.person_firstname} ${person.person_lastname} - ${person.person_document_number} - ${person.person_email}`,
      }))
      resolve(personsFormatted)
    } catch (error) {
      NotificationHandler('Error', 'error', 'No se pudo obtener clientes')
      reject(error)
    }
  })

const updateContacts = (contacts, customerId) =>
  new Promise((resolve, reject) => {
    const data = { contacts: [] }

    data.contacts = _.map(contacts, (item) => {
      const contact = item
      delete contact.id
      if (contact.person_id !== undefined) delete contact.person_id
      delete contact.isNew
      contact.person_father_id = customerId
      contact.person_is_contact = 1
      return contact
    })
    axios
      .put(`/api/persons/${customerId}/contacts`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })

export const addPerson = (person, contacts) => (dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    try {
      const { workshop_id } = getState().AppReducer.currentUser

      const newPerson = await axios.post('/api/persons', {
        ...person,
        workshop_id,
      })

      const { person_id } = newPerson.data.data

      const personWorkshop = {
        workshop_id,
        person_id,
        person_status: 1,
      }

      if (newPerson.data.error) {
        throw new Error(newPerson.data.data)
      }

      await dispatch(addPersonWorkshop(personWorkshop))

      if (contacts) {
        updateContacts(contacts, person_id)
          .then((addedContacts) => {
            NotificationHandler('Done', 'success', 'Cliente agregado')
            const personToRedux = {
              ...person,
              ...newPerson.data.data,
            }
            if (_.isEmpty(contacts)) {
              personToRedux.contacts = []
            } else {
              personToRedux.contacts = addedContacts.data.data
            }
            dispatch(addPersonAction(personToRedux))
            resolve(personToRedux)
          })
          .catch(() => {
            reject()
          })
      } else {
        NotificationHandler('Done', 'success', 'Cliente agregado')
        dispatch(addPersonAction(newPerson.data.data))
        resolve(newPerson.data.data)
      }
    } catch (e) {
      NotificationHandler('Error', 'error', e.message)
      reject()
    }
  })

export const syncPersonWorkshop = (person) => (dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    try {
      const { workshop_id } = getState().AppReducer.currentUser
      const { person_id } = person

      const personWorkshop = {
        workshop_id,
        person_id,
        person_status: 1,
      }

      await dispatch(addPersonWorkshop(personWorkshop))
      await dispatch(addPersonAction(person))

      NotificationHandler('Done', 'success', 'Cliente sincronizado con éxito')
      resolve(person)
    } catch (e) {
      NotificationHandler('Error', 'error', 'No se pudo sincronizar el cliente')
      reject(e)
    }
  })

export const updatePerson = (person, contacts) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (person.person_id) {
      axios
        .put(`/api/persons/${person.person_id}`, { ...person })
        .then((response) => {
          if (response.data.error) {
            NotificationHandler('Error', 'error', response.data.data)
            reject()
          } else if (contacts) {
            const { person_id } = response.data.data
            updateContacts(contacts, person_id).then((updatedContacts) => {
              NotificationHandler(
                'Hecho:',
                'success',
                'Datos de Cliente actualizados'
              )
              const personToRedux = {
                ...person,
                ...response.data.data,
              }

              if (_.isEmpty(contacts)) {
                personToRedux.contacts = []
              } else {
                personToRedux.contacts = updatedContacts.data.data
              }
              dispatch(updatePersonAction(personToRedux))
              resolve(personToRedux)
            })
          } else {
            NotificationHandler(
              'Hecho:',
              'success',
              'Datos de Cliente actualizados'
            )
            const personToRedux = {
              ...response.data.data,
            }
            personToRedux.contacts = []
            dispatch(updatePersonAction(personToRedux))
            resolve(personToRedux)
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error:',
            'error',
            'No se enviaron datos actualizados',
            error
          )
        })
    } else {
      NotificationHandler('Error', 'error', 'No se proporcionó id')
      reject()
    }
  })

export const updatePersonPhone = (id, phone, country_code) => (dispatch) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`/api/persons/${id}/phone`, { person_phone: phone, country_code })
      .then((response) => {
        if (response.data.error) {
          NotificationHandler('Error', 'error', response.data.data)
          reject()
        } else {
          NotificationHandler(
            'Hecho:',
            'success',
            'Telefono de Cliente actualizado'
          )
          const personToRedux = {
            ...response.data.data,
          }
          dispatch(updatePersonPhoneAction(personToRedux))
          resolve(personToRedux)
        }
      })
      .catch((error) => {
        reject()
        console.log(error)
        NotificationHandler(
          'Error:',
          'error',
          'No se enviaron datos actualizados',
          error
        )
      })
  })

export const deletePerson = (person) => (dispatch) =>
  new Promise((resolve, reject) => {
    axios
      .delete(`/api/person_workshops/${person.person_id}`)
      .then((response) => {
        if (response.data.error) {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al eliminar a el cliente',
            response.data.data
          )
        } else {
          dispatch(deletePersonAction(response.data.data))
          resolve(response.data.data)
          NotificationHandler('Done', 'success', 'cliente eliminado')
        }
      })
      .catch((error) => {
        reject()
        NotificationHandler(
          'Error',
          'error',
          'Error al eliminar a el cliente',
          error
        )
      })
  })

export const deletePersonState = (person) => (dispatch) =>
  new Promise((resolve) => {
    resolve()
    dispatch(deletePersonAction(person))
  })
