import React, { Component } from 'react'
import {
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Col,
  Button,
  Row,
  Form,
} from 'react-bootstrap'
import PropTypes from 'prop-types'
import { getValidationState } from '../../Common'

class ModifyStockProductModal extends Component {
  state = {
    product_name: '',
    product_quantity: '',
    modifyByQuantity: '',
    description: '',
    product_cost: '',
    loading: false
  }

  componentDidUpdate = (prevProps) => {
    const { selectedProduct } = this.props
    const { selectedProduct: prevSelectProduct } = prevProps // ?

    if (selectedProduct !== prevSelectProduct) {
      this.setProductModalState()
    }
  }

  setProductModalState() {
    const { selectedProduct } = this.props
    this.setState({
      product_name: selectedProduct.product_name,
      product_quantity: selectedProduct.product_quantity,
      modifyByQuantity: '',
      description: '',
      product_cost: selectedProduct.product_cost,
      loading: false,
    })
  }

  clearComponent = () => {
    this.setState({
      product_name: '',
      product_quantity: '',
      modifyByQuantity: '',
      description: '',
      product_cost: '',
      loading: false
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const {
      selectedProduct,
      toggleModal,
      modifyStockProduct,
      modifyStockMode
    } = this.props

    toggleModal()


    const {
      modifyByQuantity,
      description,
      product_cost
    } = this.state

    const productToModifyStock = {
      productId: selectedProduct.product_id,
      modifyBy:  parseInt(modifyByQuantity, 10),
      operation: modifyStockMode,
      description, // : description,
      productCost: product_cost,
      source: "Manual movement",
      userId: 1
    }

    modifyStockProduct(productToModifyStock)
      .then(() => {
        this.clearComponent()
      })
      .catch(() => {
        this.setState({ loading: false })
        toggleModal()
      })

      this.setState({ // limpiar ambos campos al volver a abrir el modal
        modifyByQuantity: '',
        description: '',
        product_cost: '',
        loading: false,
      })
  }

  handleChange = (e, type, mode) => { 
    const { name } = e.target
    let { value } = e.target
    value = value.replace(/[^\d]/, '');
    const {product_quantity: quantity} = this.state
    if (mode === "O" && quantity < value) {
      value = quantity
    }

    if (value === 0) {
      value = ""
    }

    if (type === 'double') {
      value = value.replace(/[^0-9.]/g, '')
    } /* else if (type === 'integer') {
      value = value.replace(/[^0-9]/g, '')
    } */

    this.setState({
      [name]: value,
    })
  }

  handleChangeCost = (e, type) => { 
    const { name } = e.target
    let { value } = e.target

    if (value === 0) {
      value = ""
    }

    if (type === 'double') {
      value = value.replace(/[^0-9.]/g, '')
    } /* else if (type === 'integer') {
      value = value.replace(/[^0-9]/g, '')
    } */

    this.setState({
      [name]: value,
    })
  }

  handleChangeText = (e) => { 
    const { name } = e.target
    const { value } = e.target
    this.setState({
      [name]: value,
    })
  }

  render() {
    const { modifyStockMode, modalOpen, toggleModal, pagePermissions } = this.props
    const {
      product_name,
      product_quantity,
      modifyByQuantity,
      description,
      product_cost,
      loading,
    } = this.state

    let can_edit
    if (pagePermissions) {
      ; ({ can_edit } = pagePermissions)
    }

    return (
      <Modal bsSize="lg" show={modalOpen} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {modifyStockMode === "I" ? 'Agregar ' : 'Remover '}
            repuesto
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.handleSubmit}>
            <Row>
              <Col md={6}>
                <FormGroup validationState={getValidationState(product_name)}>
                  <ControlLabel>Nombre: </ControlLabel>
                  <FormControl
                    name="product_name"
                    type="text"
                    value={product_name}
                    required
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup
                  validationState={getValidationState(product_quantity)}
                >
                  <ControlLabel>Cantidad actual: </ControlLabel>
                  <FormControl
                    name="product_quantity"
                    type="text"
                    value={product_quantity}
                    required
                    disabled
                  />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup validationState={getValidationState(description)}>
                  <ControlLabel>Descripción: </ControlLabel>
                  <FormControl
                    name="description"
                    type="text"
                    value={description}
                    onChange={this.handleChangeText}
                    placeholder="Descripción de movimiento"
                  />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup
                  validationState={getValidationState(modifyByQuantity)}
                >
                  <ControlLabel>Cantidad a {modifyStockMode === "I" ? 'agregar' : 'remover'} : </ControlLabel>
                  <FormControl
                    name="modifyByQuantity"
                    type="text"
                    value={modifyByQuantity}
                    onChange={(e) => {
                      this.handleChange(e, 'integer', modifyStockMode)
                    }}
                    required
                  />
                </FormGroup>
              </Col>

              {modifyStockMode === "I" &&
              <Col md={6}>
                <FormGroup validationState={getValidationState(product_cost)}>
                  <ControlLabel>Cost: </ControlLabel>
                  <FormControl
                    name="product_cost"
                    type="text"
                    value={product_cost}
                    onChange={this.handleChangeCost}
                    placeholder="Costo"
                  />
                </FormGroup>
              </Col>
              }

            </Row>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button type="submit" hidden id="submitModifyStockProduct" />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={toggleModal}>Cerrar</Button>
          {!!can_edit && (
            <Button
              disabled={loading}
              bsStyle="primary"
              onClick={() => {
                document.getElementById('submitModifyStockProduct').click()
              }}
            >
              Procesar
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    )
  }
}

ModifyStockProductModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  modifyStockMode: PropTypes.func.isRequired,
  modifyStockProduct: PropTypes.func.isRequired,
  selectedProduct: PropTypes.objectOf(PropTypes.string).isRequired,
  pagePermissions: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default ModifyStockProductModal
