import styled from 'styled-components'
import { BREAK_POINTS_DESKTOP_FIRST } from '../../../global/css/breakPoints'

const MultiplePhotoInputWrapper = styled.div`
  .content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .ant-upload-list-item-actions {
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      align-items: center;
      gap: 15px;
      .anticon-eye {
        margin-top: 5px !important;
      }
    }
    .upload {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 50px;
        width: 50px;
        object-fit: cover;
        margin-top: 5px;
      }
    }
    .images-wrapper {
      display: flex;
      gap: 16px;
      flex-wrap: wrap;
    }
  }

  .title {
    display: flex;
    align-items: center;
    gap: 6px;

    span {
      color: black;
      font-size: 16px;
    }
  }

  @media (${BREAK_POINTS_DESKTOP_FIRST.MOBILE}) {
    .content {
      gap: 16px;
      .images-wrapper {
        justify-content: flex-start;
        align-items: center;
      }
    }
  }
`

export default MultiplePhotoInputWrapper
