import React from 'react'
import { Table, Button } from 'react-bootstrap'
import _ from 'lodash'
import Select from 'react-select'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const noRepeatProcesses = (processes, processesState) => {
  const taken = []
  _.forEach(processesState, (process) => {
    if (process) {
      if (process.process_id) {
        taken.push(process.process_id)
      }
    }
  })
  const noRepeated = _.filter(
    processes,
    (process) => !_.includes(taken, process.process_id)
  )
  return noRepeated
}

const ProcessTable = ({
  processes,
  processesState,
  handleChangeProcess,
  editMode,
  addEmptyItemProcess,
}) => (
  <CustomStyledTable>
    <Table striped hover bordered condensed>
      <thead>
        <tr>
          <th>Órden</th>
          <th>Proceso</th>
        </tr>
      </thead>
      <tbody>
        {_.map(processesState, (process, key) => {
          if (process) {
            const options = [...noRepeatProcesses(processes, processesState)]
            if (processes[process.process_id]) {
              options.push(processes[process.process_id])
            }
            return (
              <tr key={key}>
                <td>
                  <p
                    style={{
                      fontWeight: 'bold',
                      fontSize: '10',
                      textAlign: 'center',
                    }}
                  >
                    {parseInt(key, 10) + 1}
                  </p>
                </td>
                <td>
                  <Select
                    required={key === '0'}
                    placeholder="Proceso"
                    options={options}
                    labelKey="process_name"
                    valueKey="process_id"
                    value={process.process_id}
                    onChange={(value) => {
                      handleChangeProcess(value, key)
                    }}
                  />
                </td>
              </tr>
            )
          }
          return ''
        })}
        {editMode && (
          <tr>
            <td colSpan="2" style={{ textAlign: 'right' }}>
              <Button
                bsStyle="success"
                onClick={() => addEmptyItemProcess()}
                style={{ borderRadius: '20px !important', fontSize: '1.2rem' }}
              >
                Agregar proceso
              </Button>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  </CustomStyledTable>
)

const CustomStyledTable = styled.div`
  margin: 0px auto;

  table {
    thead {
      th:nth-child(2) {
        width: 100%;
      }
    }
  }
`

ProcessTable.propTypes = {
  processes: PropTypes.objectOf(PropTypes.object).isRequired,
  processesState: PropTypes.objectOf(PropTypes.object).isRequired,
  handleChangeProcess: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  addEmptyItemProcess: PropTypes.func.isRequired,
}

export default ProcessTable
