/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { PropTypes } from 'prop-types'
import { Button } from 'antd'

import { ModalButtonsContainer } from '../../App/Reception/AddReception/VehicleStep/VehicleStep.style'
import NumberFormControl from '../../App/Common/NumberFormControl/NumberFormControl'
import TextFormControl from '../../App/Common/TextFormControl/TextFormControl'
import CustomSelect from '../../inputs/CustomSelect/CustomSelect'
import { ModelFormContainer } from './AddModelForm.style'
import ImageSelect from '../../inputs/ImageSelect/ImageSelect'

const AddModelForm = ({
  selectedBrand,
  brandsOptions,
  workshopId,
  children,
  vehicleTypesOptions,
  handleModelFormSubmit,
}) => {
  const [brand, setBrand] = useState(selectedBrand || undefined)
  const [vehicleType, setVehicleType] = useState()

  const methods = useForm({ mode: 'onChange' })
  const {
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = methods

  const isSubmitDisabled = !(isValid && brand?.value && vehicleType?.value)

  const resetForm = () => {
    reset({ modelName: '', cylinder: '' })
    setVehicleType(undefined)
  }

  const onSubmit = (data) => {
    if (!isSubmitDisabled) {
      handleModelFormSubmit({
        model_id: null,
        model_name: data.modelName,
        model_cylinder_capacity: data.cylinder,
        brand_id: brand?.value,
        vehicle_type_id: vehicleType?.value,
        workshop_id: workshopId,
      })

      resetForm()
    }
  }

  useEffect(() => {
    resetForm()
  }, [])

  return (
    <FormProvider {...methods}>
      <ModelFormContainer onSubmit={handleSubmit(onSubmit)}>
        <ImageSelect
          id="brand-select"
          imgPropName="brand_logo_url"
          items={brandsOptions}
          value={brand}
          label="Marca"
          placeholder="Selecciona una marca"
          handleValueChange={setBrand}
        />
        <TextFormControl
          label="Nombre"
          isAutoFocus
          name="modelName"
          error={errors?.modelName?.message}
          required
          requiredMessage="Ingresa un nombre para el modelo"
        />
        <NumberFormControl
          label="Cilindraje"
          name="cylinder"
          error={errors?.cylinder?.message}
          required
          requiredMessage="Ingresa el cilindraje"
        />
        <CustomSelect
          id="vehicle-types-select"
          items={vehicleTypesOptions}
          value={vehicleType}
          label="Tipo de vehículo"
          placeholder="Selecciona el tipo de vehículo"
          handleValueChange={setVehicleType}
        />
        <ModalButtonsContainer style={{ marginTop: '1.5rem' }}>
          {children}
          <Button type="primary" htmlType="submit" disabled={isSubmitDisabled}>
            Agregar
          </Button>
        </ModalButtonsContainer>
      </ModelFormContainer>
    </FormProvider>
  )
}

AddModelForm.propTypes = {
  brandsOptions: PropTypes.array.isRequired,
  selectedBrand: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.oneOf([null]),
  ]),
  workshopId: PropTypes.number.isRequired,
  children: PropTypes.node,
  vehicleTypesOptions: PropTypes.array.isRequired,
  handleModelFormSubmit: PropTypes.func.isRequired,
}

AddModelForm.defaultProps = {
  children: <></>,
  selectedBrand: null,
}

export default AddModelForm
