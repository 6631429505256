import axios from 'axios'

export const updateWorkOrderProduct = async (work_order_id, product) => {
  try {
    const productsPayload = {
      workOrderId: work_order_id,
      productId: product.product_id,
      productPrice: product.product_placed_price,
      productQuantity: product.product_asked_quantity,
      productDiscount: product.product_placed_discount,
    }

    const response = await axios.post(
      'api/work_order_details/product',
      productsPayload
    )

    if (response.error) {
      throw response.error
    }
    return response
  } catch (error) {
    throw ('Error Actualizando respuestos', error)
  }
}
