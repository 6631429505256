import React from 'react'
import { Button } from 'react-bootstrap'
import ReactToolTip from 'react-tooltip'
import { textFilter } from 'react-bootstrap-table2-filter'
import { EditFilled, DeleteFilled } from '@ant-design/icons';
import {
  formatterHeaderStyle,
  columnStyle,
  columnHeaderStyle,
} from '../../../Utilities/TableStyles'

const styleIcons = {
  fontSize: '17px',
}

const editButton = (editPackage) => (cell, row) =>
(
  <Button
    onClick={() => {
      editPackage(row)
    }}
    bsStyle="link"
    bsSize="small"
    data-tip="Modificar"
  >
    <EditFilled style={styleIcons} />
    <ReactToolTip />
  </Button>
)

const deleteButton = (deletePackage) => (cell, row) =>
(
  <Button
    onClick={() => deletePackage(row)}
    bsStyle="link"
    bsSize="small"
    style={{ color: 'red' }}
    data-tip="Eliminar"
  >
    <DeleteFilled style={styleIcons} />
    <ReactToolTip />
  </Button>
)

const getColumns = (editPackage, deletePackage, { can_edit, can_delete }) => {
  const columns = [
    {
      dataField: 'package_name',
      text: 'Nombre',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: textFilter({
        delay: 100,
        style: {
          margin: '10px 0 0 0',
          padding: '20px 2%',
        },
        placeholder: 'Buscar',
      }),
    },
    {
      dataField: 'package_price',
      text: 'Precio',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: textFilter({
        delay: 100,
        style: {
          margin: '10px 0 0 0',
          padding: '20px 2%',
        },
        placeholder: 'Buscar',
      }),
      formatter: (cell, row) => {
        const price = parseFloat(row.package_price);
        if (Number.isNaN(price)) {
          return '';
        }
        const formattedPrice = price.toFixed(2);
        return `$${formattedPrice.replace('.', ',')}`;
      },
    },
    {
      dataField: 'serviceType.service_type_name',
      text: 'Área de servicio',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: textFilter({
        delay: 100,
        style: {
          margin: '10px 0 0 0',
          padding: '20px 2%',
        },
        placeholder: 'Buscar',
      }),
    },
  ]
  if (can_edit) {
    columns.push({
      dataField: 'id1',
      className: 'test',
      text: '',
      headerStyle: formatterHeaderStyle,
      style: columnHeaderStyle({ textAlign: 'center' }),
      formatter: editButton(editPackage),
    })
  }
  if (can_delete) {
    columns.push({
      dataField: 'id2',
      text: '',
      headerStyle: formatterHeaderStyle,
      style: columnHeaderStyle({ textAlign: 'center' }),
      formatter: deleteButton(deletePackage),
    })
  }
  return columns
}

export default getColumns
