import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { StarFilled } from '@ant-design/icons'
import { ReviewContainer, Title } from './ReviewCard.styles'
import { SeeMoreButton } from '../InfoCard/InfoCard.style'
import UserEditIcon from '../../../icons/UserEditIcon'


const ReviewCard = ({ text, limit, score }) => {
  const [expanded, setExpanded] = useState(false)

  const toggleExpand = () => {
    setExpanded(!expanded)
  }

  return (
    <ReviewContainer>
      <Title>
        <UserEditIcon color="#1e1e40" size="14px" />
        Valoración del cliente
      </Title>
      {score && (
        <div className="rating">
          <p>
            <b>Calificación: </b>
          </p>
          <p>
            <StarFilled />
            {parseFloat(score) || 'Sin calificación'}
          </p>
        </div>
      )}
      {text && (
        <p>
          <b>Comentario: </b>
          {expanded ? text : `${text.slice(0, limit)}`}
          {text.length > limit && (
            <SeeMoreButton onClick={toggleExpand}>
              {!expanded ? '... Ver más' : '... Ver menos'}
            </SeeMoreButton>
          )}
        </p>
      )}
      {!score && !text && (
        <p className="no-review">
          No se registraron valoraciones por parte del cliente.
        </p>
      )}
    </ReviewContainer>
  )
}

ReviewCard.propTypes = {
  text: PropTypes.string.isRequired,
  limit: PropTypes.number.isRequired,
  score: PropTypes.number.isRequired,
}

export default ReviewCard
