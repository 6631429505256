import _ from 'lodash'

import {
  GET_CONTRIBUTOR_TYPES,
  ADD_CONTRIBUTOR_TYPE,
  UPDATE_CONTRIBUTOR_TYPE,
  DELETE_CONTRIBUTOR_TYPE,
} from '../actionTypes/ContributorTypesTypes'

export default (
  state = {
    contributorTypes: {},
  },
  action
) => {
  switch (action.type) {
    case GET_CONTRIBUTOR_TYPES: {
      return {
        ...state,
        contributorTypes: action.payload,
      }
    }
    case ADD_CONTRIBUTOR_TYPE: {
      return {
        ...state,
        contributorTypes: {
          ...state.contributorTypes,
          [action.payload.contributor_type_id]: action.payload,
        },
      }
    }
    case UPDATE_CONTRIBUTOR_TYPE: {
      return {
        ...state,
        contributorTypes: {
          ...state.contributorTypes,
          [action.payload.contributor_type_id]: action.payload,
        },
      }
    }
    case DELETE_CONTRIBUTOR_TYPE: {
      const newState = _.cloneDeep(state)
      delete newState.contributorTypes[action.payload.contributor_type_id]
      return newState
    }

    default: {
      return state
    }
  }
}
