import styled from 'styled-components'
import BREAK_POINTS, {
  BREAK_POINTS_DESKTOP_FIRST,
} from '../../../../../global/css/breakPoints'

export const PhotoStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  margin-top: 12px;

  @media (${BREAK_POINTS.TABLET_LARGE}) {
    margin-top: 0;
  }

  h4 {
    font-size: 1em;
    color: #8f8f8f;
  }
  .saved-photos-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    .ant-image {
      height: 100px;
      width: 100px;
      padding: 8px;
      border: 1px solid #d9d9d9;
      border-radius: 8px;
      .ant-image-img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
  .photo-input {
    margin-top: 12px;
    div {
      overflow-x: hidden;
      width: 100%;
      .title {
        text-align: end;
        color: #8f8f8f;
      }
      span {
        .ant-upload-list {
          display: flex;
          flex-wrap: wrap;
        }
      }
    }
  }
  @media (${BREAK_POINTS_DESKTOP_FIRST.MOBILE}) {
    h4 {
      text-align: center;
    }
    .photo-input {
      margin-top: unset;
      div {
        .title {
          text-align: center;
        }
      }
    }
  }
`
