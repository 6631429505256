import React, { useEffect, useState } from 'react'
import { LinearProgress } from '@material-ui/core'
import Upload from 'antd/es/upload/Upload'
import ReactPlayer from 'react-player'
import { PropTypes } from 'prop-types'
import { Modal, Button } from 'antd'

import MultiplePhotoInputWrapper from '../../MultiplePhotoInput/MultiplePhotoInput.styles'
import { ConfirmModalBody, UploadVideoLoader } from './VideosInput.style'
import UploadedVideoCard from './UploadedVideoCard/UploadedVideoCard'
import UploadModalBody from './UploadModalBody/UploadModalBody'
import InputThumbnail from './InputThumbnail/InputThumbnail'
import InfoDisclaimer from '../InfoDisclaimer/InfoDisclaimer'
import { AddVideoIcon } from '../../../icons/AddVideoIcon'
import { MAX_VIDEO_DURATION } from '../../../../Constants'
import { NotificationHandler } from '../../../../Helpers'

const supportedVideoFormats = [
  'video/quicktime',
  'video/mp4',
  'video/ogg',
  'video/webm',
]

const VideosInput = ({
  handleAddVideo,
  videos,
  maxVideosAllowed,
  uploadOneVideo,
  abortController,
}) => {
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewTitle, setPreviewTitle] = useState('')
  const [videoUrl, setVideoUrl] = useState('')

  const [currentFile, setCurrentFile] = useState(null)
  const [currentFileList, setCurrentFileList] = useState([])
  const [videoDuration, setVideoDuration] = useState(0)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false)

  const openConfirmModal = (duration) => {
    if (duration > (MAX_VIDEO_DURATION || 60)) {
      setConfirmModalOpen(true)
    } else {
      setConfirmModalOpen(false)
      setIsModalOpen(true)
    }
  }

  const onChange = (info) => {
    if (supportedVideoFormats.includes(info.file.type)) {
      const url = URL.createObjectURL(info.file.originFileObj)

      setCurrentFile(info.file)
      setCurrentFileList(info.fileList)

      if (!videoUrl) {
        setVideoUrl(url)
      }

      const video = document.createElement('video')
      video.src = url

      video.addEventListener('loadedmetadata', () => {
        const { duration } = video

        if (info.file.status !== 'removed') {
          openConfirmModal(duration)
        }

        setVideoDuration(duration)
      })

      video.load()

      handleAddVideo(info.fileList)
    } else {
      NotificationHandler(
        'No podemos guardar tu video',
        'error',
        'El formato no es compatible, puedes utilizar mp4, webm y mov'
      )
    }
  }

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok')
    }, 100)
  }

  const isSavedVideo = (file) => file?.receptionPhotoId

  const handleVideoPreview = (file) => {
    const videoURL = isSavedVideo(file)
      ? file.url
      : URL.createObjectURL(file.originFileObj)

    setVideoUrl(videoURL)
    setPreviewVisible(true)
    setPreviewTitle(file?.name || 'Previsualización de video')
  }

  const closeConfirmationModal = () => {
    currentFileList.pop()
    setConfirmModalOpen(false)
  }

  const closeUploadModal = () => {
    setCurrentFile(null)
    setVideoUrl('')
    setPreviewVisible(false)
  }

  const handleVideoUpload = async () => {
    setConfirmModalOpen(false)

    try {
      setIsModalOpen(true)
      await uploadOneVideo(currentFile)
    } catch (err) {
      console.log(err)
      NotificationHandler(
        'No se pudo guardar tu video',
        'error',
        'Ocurrió un error inesperado, intenta nuevamente'
      )
      currentFileList.pop()
    } finally {
      setIsModalOpen(false)
    }
  }

  const handleCancelUpload = () => {
    currentFileList.pop()
    setIsModalOpen(false)
    abortController?.abort()
  }

  useEffect(() => {
    if (isModalOpen && currentFile.originFileObj) {
      handleVideoUpload()
    }

    return () => abortController?.abort()
  }, [isModalOpen])

  // In case remove video is needed, use the 2nd param "actions" -> actions.remove
  const customVideoRender = (file) => {
    // Already uploaded videos shown in edit mode
    if (isSavedVideo(file)) {
      return (
        <UploadedVideoCard
          uploadedVideo={file}
          handleVideoPreview={handleVideoPreview}
        />
      )
    }
    if (file.type.startsWith('video/')) {
      return (
        <InputThumbnail
          fileObject={file}
          handleVideoPreview={handleVideoPreview}
        />
      )
    }
    return null
  }

  return (
    <MultiplePhotoInputWrapper>
      <p className="title" style={{ marginBottom: '12px' }}>
        <span>Videos</span>
        {videos.length > 0 && ` (${videos.length})`}
      </p>
      <InfoDisclaimer
        message={
          <>
            Los videos deben durar menos de {MAX_VIDEO_DURATION / 2} segundos.{' '}
            <p>
              <span style={{ fontWeight: '600' }}>Formatos aceptados: </span>
              mp4, mov y webm.
            </p>
          </>
        }
      />
      <Upload
        accept="video/*"
        name="videos"
        listType="picture-card"
        className="content"
        fileList={videos}
        onChange={onChange}
        customRequest={dummyRequest}
        // Videos have a custom render method to show its preview
        itemRender={(_, file) => customVideoRender(file)}
        progress={{
          strokeColor: {
            '0%': '#108ee9',
            '100%': '#87d068',
          },
          strokeWidth: 3,
          format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
        }}
      >
        {videos.length + 1 <= maxVideosAllowed && (
          <div className="upload">
            <AddVideoIcon />
          </div>
        )}
      </Upload>
      {currentFile?.originFileObj && isModalOpen && (
        <Modal
          open={isModalOpen}
          cancelText="Cancelar carga"
          footer={() => (
            <div style={{ marginRight: 'auto' }}>
              <Button onClick={handleCancelUpload}>Cancelar</Button>
            </div>
          )}
          closable={false}
          centered
          width={350}
        >
          <div>
            <UploadModalBody
              fileObject={currentFile}
              videoDuration={videoDuration}
            />
            <UploadVideoLoader>
              <p>Guardando video...</p>
              <LinearProgress
                style={{ width: '100%' }}
                color="primary"
                fourColor={false}
                variant="indeterminate"
              />
            </UploadVideoLoader>
          </div>
        </Modal>
      )}
      <Modal
        open={previewVisible}
        onCancel={closeUploadModal}
        footer={null}
        title={previewTitle}
        centered
        cancelText="Cancelar"
      >
        <ReactPlayer
          url={videoUrl}
          playing
          width="100%"
          height="65vh"
          volume={0.5}
          controls
          style={{ margin: 'auto' }}
        />
      </Modal>
      <Modal
        open={isConfirmModalOpen}
        onCancel={closeConfirmationModal}
        width={350}
        okText="Continuar"
        closable={false}
        cancelText="Cancelar"
        onOk={() => setIsModalOpen(true)}
        centered
      >
        <ConfirmModalBody>
          <i className="fas fa-exclamation-triangle" />
          <p>
            Este video es demasiado grande y podría tomar mucho tiempo en ser
            cargado.
          </p>
          <span>¿Deseas continuar?</span>
        </ConfirmModalBody>
      </Modal>
    </MultiplePhotoInputWrapper>
  )
}

VideosInput.propTypes = {
  videos: PropTypes.array.isRequired,
  handleAddVideo: PropTypes.func.isRequired,
  uploadOneVideo: PropTypes.func.isRequired,
  abortController: PropTypes.isRequired,
  maxVideosAllowed: PropTypes.number,
}

VideosInput.defaultProps = {
  maxVideosAllowed: 5,
}

export default VideosInput
