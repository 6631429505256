import React from 'react'
import { PropTypes } from 'prop-types'

import { ImageSectionWrapper } from './ImageBgContainer.style'

const ImageBgContainer = ({ backgroundImage, children }) => {
  return (
    <ImageSectionWrapper backgroundImage={backgroundImage}>
      <div className="content-wrapper">
        <div className="background-wrapper">
          <div className="content">{children}</div>
        </div>
      </div>
    </ImageSectionWrapper>
  )
}

export default ImageBgContainer

ImageBgContainer.propTypes = {
  backgroundImage: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}
