import React from 'react'
import SelectedVehicleCardWrapper from './SelectedVehicleCard.styles'
import { useAddReceptionContext } from '../../../../../contexts/AddReceptionContext'

const SelectedVehicleCard = () => {
    const { state } = useAddReceptionContext()
    const { selectedVehicle } = state

  return (
    <SelectedVehicleCardWrapper>
      <i className="fas fa-motorcycle" />
      <div className="details">
        <p>{`${selectedVehicle?.brandName} ${selectedVehicle?.modelName}`}</p>
        <p className='license-plate'>{selectedVehicle?.licensePlate}</p>
      </div>
    </SelectedVehicleCardWrapper>
  )
}

export default SelectedVehicleCard