import styled from 'styled-components'

const PhoneFormControlWrapper = styled.div`
  position: relative;
  .MuiFormControl-root {
    width: 100%;
    .label-colored {
      font-family: 'Lato', Helvetica, Arial, sans-serif;
      font-weight: 600;
      color: ${(props) => (props.error ? '#f44336' : '#8A8A8A')};
      font-size: 15px;
      top: ${(props) => (props.isValue ? '-34px' : '-14px')};
      left: ${(props) => (props.isValue ? '0px' : '35px')};
      transition: all 0.2s ease-in-out;
    }
    .label-colored-value {
      background-image: ${(props) =>
        props.error ? 'unset' : 'linear-gradient(to right, #1e1e40, #b80667)'};
      background-clip: text;
      -webkit-background-clip: text;
      color: ${(props) => (props.error ? '#f44336' : 'transparent')};
    }

    .phone-input {
      width: 100%;
      margin-top: 8px;
      .PhoneInputInput {
        border: unset;
        border-radius: unset;
        border-bottom: ${(props) =>
          props.error
            ? '2px solid rgb(244, 67, 54);'
            : '1px solid rgba(0, 0, 0, 0.42)'};
        &:hover {
          border-bottom: ${(props) =>
            props.error
              ? '2px solid rgb(244, 67, 54);'
              : '2px solid rgba(0, 0, 0, 0.42)'};
          border-image: ${(props) =>
            props.error
              ? 'unset'
              : 'linear-gradient(270deg, #8d2866 52.48%, #1e1e40 100%);'};
          border-image-slice: 1;
          border-image-width: 0 0 0 0 3px; /* Adjust the width of the border as needed */
          border-image-outset: 0 0 0 0;
          border-image-repeat: stretch;
        }
        &:focus {
          outline: none;
        }
      }
    }
    .PhoneInputInput:focus + .label-colored {
      top: -34px;
      font-size: 15px;
    }
  }
  .error-message {
    font-size: 12px;
    color: #f44336;
  }
`
export default PhoneFormControlWrapper
