export default (employee_name, employee_lastname) => {
  const name = {
    firstname: '',
    lastname: '',
  }
  if (employee_name.includes(' ')) {
    const firstnameIndex = employee_name.indexOf(' ')
    name.firstname = employee_name.substring(0, firstnameIndex)
  } else {
    name.firstname = employee_name
  }
  if (employee_lastname.includes(' ')) {
    const lastnameIndex = employee_lastname.indexOf(' ')
    name.lastname = employee_lastname.substring(0, lastnameIndex)
  } else {
    name.lastname = employee_lastname
  }
  return name
}
