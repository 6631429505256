import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ColorPicker, Button } from 'antd'

import { carColors } from '../../../../Constants'
import {
  ColorPickerWrapper,
  Container,
  Summary,
  ColorSquare,
} from './MultiColorPicker.style'

const MultiColorPicker = ({ selectedColors, setSelectedColors }) => {
  const [customColor, setCustomColor] = useState()

  const addColor = (color) => {
    setSelectedColors([...selectedColors, color])
  }

  const addCustomColor = (color) => {
    setSelectedColors([...selectedColors, color.toHexString()])
  }

  const removeColor = (color) => {
    setSelectedColors(selectedColors.filter((c) => c !== color))
  }

  return (
    <ColorPickerWrapper>
      <Container>
        {carColors.map((color) => (
          <ColorSquare
            key={color}
            color={color}
            className={color === '#FFFFFF' ? 'white-square' : ''}
            selected={selectedColors.includes(color)}
            onClick={() => {
              if (selectedColors.includes(color)) {
                removeColor(color)
              } else {
                addColor(color)
              }
            }}
          />
        ))}
        <div className="custom-color-picker">
          <ColorPicker
            value={customColor}
            onChange={setCustomColor}
            onChangeComplete={addCustomColor}
          >
            <Button
              style={{
                fontSize: '1.6rem',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                padding: '2px 8px',
              }}
            >
              +
            </Button>
          </ColorPicker>
        </div>
      </Container>
      {selectedColors.length > 0 && (
        <Summary>
          <p>Colores seleccionados:</p>
          <Container>
            {selectedColors.map((color) => (
              <ColorSquare
                key={color}
                color={color}
                className={color === '#FFFFFF' ? 'white-square' : ''}
                selected={selectedColors.includes(color)}
                onClick={() => {
                  if (selectedColors.includes(color)) {
                    removeColor(color)
                  } else {
                    addColor(color)
                  }
                }}
              />
            ))}
          </Container>
        </Summary>
      )}
    </ColorPickerWrapper>
  )
}

MultiColorPicker.propTypes = {
  selectedColors: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedColors: PropTypes.func.isRequired,
}

export default MultiColorPicker
