import Axios from 'axios'
import _ from 'lodash'
import jwt from 'jsonwebtoken'

import {
  LOG_IN,
  SET_CURRENT_USER,
  SET_USER_PERMISSIONS,
  SET_WORKSHOP_COUNTRY_CODE,
  UPDATE_NOTIFICATION_COUNT
} from '../actionTypes/AppTypes'
import { setAuthorizationToken } from '../Helpers'
import { getScreens } from './ScreenAction'

const logInAction = () => ({
  type: LOG_IN,
})

const setCurrentUserAction = (payload) => ({
  type: SET_CURRENT_USER,
  payload,
})

const setWorkshopCountryCode = (payload) => ({
  type: SET_WORKSHOP_COUNTRY_CODE,
  payload,
});

const setCurrentNotificationCount = (payload) => ({
  type: UPDATE_NOTIFICATION_COUNT,
  payload,
})

export const getUser = (id) =>
  new Promise((resolve, reject) => {
    Axios.get(`/api/employees/${id}`)
      .then((response) => {
        if (response.status === 200 && response.status < 300) {
          if (response.data.error) {
            reject(response.data.data)
          } else {
            resolve(response)
          }
        } else {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })

export const setCurrentUser = (user) =>
  new Promise((resolve, reject) => {
    const id = user.employee_id
    getUser(id)
      .then((response) => {
        resolve(response.data.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

export const logIn = (username, password) => (dispatch) =>
  new Promise((resolve, reject) => {
    Axios.post('/api/employees/login', { username, password })
      .then((response) => {
        if (response.data.error) {
          reject(response.data)
        } else {
          const { user } = response.data
          const decodedToken = jwt.decode(response.data.token)
          const expirationTime = decodedToken.exp
          setAuthorizationToken(response.data.token, expirationTime)
          dispatch(setWorkshopCountryCode(response.data.workshopCountryCode));
          localStorage.setItem('workshopCountryCode', response.data.workshopCountryCode);
          dispatch(getScreens())
          dispatch({
            type: SET_USER_PERMISSIONS,
            payload: _.keyBy(user.screens, 'screen_id'),
          })
          dispatch(setCurrentUserAction(user))
          dispatch(logInAction())
          resolve()
        }
      })
      .catch((error) => {
        reject(error)
      })
  })

export const updateNotificationCount = (employeeId) => async (dispatch) =>
  new Promise((resolve, reject) => {
    try {
      Axios.get(`/api/work-order-follow-configuration/notifications-count-not-viewed/${employeeId.employee_id}`)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            const count = response.data.data;
            dispatch(setCurrentNotificationCount(count));
            resolve(count); // Resolve with the count value
          } else {
            reject(response.data.data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    } catch (error) {
      reject(error);
    }
  });