import styled from 'styled-components'

export const AddProductFormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 2rem;
  padding: 1rem 0;

  .inputs {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: space-between;

    .MuiFormControl-root {
      max-width: 150px;
    }
  }
  @media (max-width: 408px) {
    .inputs {
      gap:2rem;
      .price-wrapper {
        width: 100%;
        .MuiFormControl-root {
          max-width: unset;
          width: 100%;
        }
      }
    }
  }
`
