import axios from 'axios'
import _ from 'lodash'

import { GET_STATES } from '../actionTypes/StateTypes'
import { NotificationHandler } from '../Helpers'

const getStatesAction = (payload) => ({
  type: GET_STATES,
  payload,
})

// eslint-disable-next-line import/prefer-default-export
export const getStates = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const statesQuantity = _.size(getState().StateReducer.states)
    if (statesQuantity === 0) {
      axios
        .get('/api/states')
        .then((response) => {
          if (!response.data.error) {
            const states = _.keyBy(response.data.data, 'state_id')
            dispatch(getStatesAction(states))
            resolve(response.data)
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al obtener estados',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al obtener estados',
            error
          )
        })
    } else {
      resolve({})
    }
  })
