import styled from "styled-components";

// eslint-disable-next-line import/prefer-default-export
export const EmptyStateContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  margin-top: 35px;

  i {
    color: #d0d0d0;
    font-size: 5rem;
    text-align: center;
  }

  p {
    text-align: center;
  }
`