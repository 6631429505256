import _ from 'lodash'

import {
  GET_PRODUCT_ORDER,
  ADD_PRODUCT_ORDER,
  UPDATE_PRODUCT_ORDER,
  DELETE_PRODUCT_ORDER,
} from '../actionTypes/ProductOrderTypes'

export default (state = { productOrders: {} }, action) => {
  switch (action.type) {
    case GET_PRODUCT_ORDER: {
      return {
        ...state,
        productOrders: action.payload,
      }
    }
    case ADD_PRODUCT_ORDER: {
      return {
        ...state,
        productOrders: {
          ...state.productOrders,
          [action.payload.work_order_id]: action.payload,
        },
      }
    }
    case UPDATE_PRODUCT_ORDER: {
      return {
        ...state,
        productOrders: {
          ...state.productOrders,
          [action.payload.work_order_id]: action.payload,
        },
      }
    }
    case DELETE_PRODUCT_ORDER: {
      const newState = _.cloneDeep(state)
      delete newState.productOrders[action.payload.work_order_id]
      return newState
    }

    default: {
      return state
    }
  }
}
