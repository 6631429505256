import Styled from 'styled-components'

export const ButtonShowList = Styled.div`
    color: white;
    cursor: pointer;
    background-color: #212121;
    padding: 10px;
    border-radius: 5px;
    width: 150px;
    font-size:1.2em;
    text-align: center;
    flex: 1;
    &:hover{
        color: white;
        background-color: #757575;
    }
`

export const ReceptionItemText = Styled.div`
    padding: 10px;
    font-size: 1.2em;
`

export const ListButton = Styled.button`
    background-color: ${({ active }) => (active ? 'white' : '#F4F4F4')};
`

export const List = Styled.div`
    height: 600px;
`

export const ReceptionList = Styled.div`
    max-width: 400px;
    border-radius: 4px 0px 4px 4px;
    background-color: #FFF;
    padding: 15px;
    margin: 7rem 0 0 0;
    background-color: white;
    box-shadow: 5px 5px 5px #ccc;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border: 1px solid #CCCCCC;
    position: relative;

    .input-container {
        margin: 10px 0px;
    }

    .receptionList-title {
        font-size: 18px;
        color: #37474f;
        margin: 0px;
    }

    .list-handler {
        position: absolute;
        display: flex;
        top: -4.5rem;
        left: 0;

        ${ListButton} {
            font-size: 16px;
            border-radius: 2px;
            border: 1px solid #CCCCCC;
            padding: 1rem;
            display: flex;
            align-items: center;
        }
    }
`

export const ListItem = Styled.div`
    padding: 10px 15px;
    width: 100%;
    margin-bottom: 20px;
    border: 3px #eeeeee solid;
    background-color: #fafafa;
    box-shadow: 2px 2px 2px #C7C7C7;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    border-radius: 10px;

    &:hover{
        background-color: #eeeeee;
    }
    .reception-item-header {
        display: flex;
        justify-content: space-between;
        font-weight: 600;
    }
    .reception-item-body {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    .vehicle-icon-container{
        svg {  margin-right: 5px;}
    }

`

export const ItemText = Styled.p`
    display: inline-block;
    margin: 0px;
    font-weight: bold;
`
export const ItemTextCustomer = Styled.p`
    display: inline-block;
    color: #616161 ;
    margin: 0px;
    margin-left: 5px;
    font-size: 1.1em;
`

export const BrandAndModelText = Styled.p`
    display: inline-block;
    color: #616161;
    padding: 5px;
    margin: 10px;
    font-size: 1.1em;
    padding-left: 5%;
`

export const ToInvoiceButton = Styled.div`
    background-color: #616161 ;
    box-sizing: border-box;
    width: 30%;
    border-radius: 5px;
    height: 2em;
    color: #eeeeee ;
    margin: 10px;
    text-align: center;
    &:hover{
        cursor: pointer;

    }
`
