import React, { Component } from 'react'
import {
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Col,
  Button,
  Row,
  Form,
} from 'react-bootstrap'
import Select from 'react-select'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { SaveOutlined } from '@ant-design/icons'
import { getValidationState, showConfirmDialog } from '../../Common'
import ProcessTable from './ProcessTable'
import { roundNumberTwoDecimals } from '../../../../Helpers'

class PackageModal extends Component {
  state = {
    package_name: '',
    package_price: '',
    service_type_id: null,
    loading: false,
    processesState: {
      0: {
        process_id: null,
      },
    },
    total_package_time: 0,
  }

  componentDidUpdate(prevProps) {
    const { selectedPackage } = this.props
    const { selectedPackage: prevSelectedPackage } = prevProps

    if (selectedPackage !== prevSelectedPackage) {
      const { length } = Object.keys(selectedPackage)
      const id = selectedPackage.package_id
      if (
        length === 0 ||
        id === undefined ||
        id === null ||
        selectedPackage === undefined ||
        selectedPackage === null
      ) {
        this.clearComponent()
      } else if (length > 0 && id) {
        this.setPackageModalState()
      }
    }
  }

  setPackageModalState() {
    const { selectedPackage } = this.props
    const { package_name, package_price, service_type_id, packageProcess } =
      selectedPackage

    const processesState = {}
    _.forEach(packageProcess, (process, key) => {
      processesState[key] = {
        ...process.process,
      }
    })
    this.setState({
      package_name,
      package_price,
      service_type_id,
      processesState,
      total_package_time: this.updatePackageTotalTime(_.values(processesState)),
    })
  }

  handleChange = (e) => {
    const { name } = e.target
    let { value } = e.target
    if (name === 'package_price') {
      value = value.replace(/[^0-9.]/g, '')
    }
    this.setState({
      [name]: value,
    })
  }

  handleChangeSelect = (serviceType, field) => {
    if (serviceType) {
      this.setState({
        [`${field}_id`]: serviceType[`${field}_id`],
      })
    } else {
      this.setState({
        [`${field}_id`]: null,
      })
    }
  }

  handleChangeProcess = (value, key) => {
    const { processesState } = this.state
    const lastKey = _.findLastKey(processesState)

    if (value) {
      const state = {
        processesState: {
          ...processesState,
          [key]: {
            ...processesState[key],
            ...value,
          },
        },
      }
      if (lastKey === key) {
        state.processesState[parseInt(key, 10) + 1] = {
          process_id: null,
        }
      } else if (processesState[lastKey].process_id) {
        state.processesState[key + 1] = {
          process_id: null,
        }
      }

      this.setState({
        ...state,
        total_package_time: this.updatePackageTotalTime(
          _.values(state.processesState)
        ),
      })
    } else {
      const state = {
        processesState: {
          ...processesState,
          [key]: {
            ...processesState[key],
            process_id: null,
          },
        },
      }
      this.setState({
        ...state,
        total_package_time: this.updatePackageTotalTime(
          _.values(state.processesState)
        ),
      })
    }
  }

  addEmptyItemProcess = () => {
    const { processesState } = this.state
    const lastKey = _.findLastKey(processesState)

    const newProcessesState = {
      ...processesState,
      [parseInt(lastKey, 10) + 1]: {
        process_id: null,
      },
    }

    this.setState({ processesState: newProcessesState })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const {
      selectedPackage,
      editMode,
      toggleModal,
      addPackage,
      updatePackage,
    } = this.props

    const { package_name, package_price, service_type_id, processesState } =
      this.state

    const pack = {
      package_id: selectedPackage.package_id || null,
      package_name,
      package_price: roundNumberTwoDecimals(package_price),
      service_type_id,
    }
    if (editMode) {
      toggleModal()
      showConfirmDialog(
        '¿Modificar?',
        `¿Modificar el paquete ${package_name}?`,
        updatePackage,
        pack,
        () => { },
        toggleModal,
        toggleModal,
        processesState
      )
    } else {
      this.setState({ loading: true })
      addPackage(pack, processesState)
        .then(() => {
          this.clearComponent(true)
        })
        .catch(() => {
          this.setState({ loading: false })
        })
    }
  }

  activeFormSubmit = (e) => {
    e.preventDefault()
    document.getElementById('submitCategoryForm').click()
  }

  clearComponent = (canToggleModal) => {
    if (canToggleModal) {
      const { toggleModal } = this.props
      toggleModal()
    }
    this.setState({
      loading: false,
      package_name: '',
      package_price: '',
      service_type_id: '',
      processesState: {
        0: {
          process_id: null,
        },
      },
      total_package_time: 0,
    })
  }

  // eslint-disable-next-line class-methods-use-this
  updatePackageTotalTime(processes) {
    if (processes.length === 0) {
      return 0
    }

    return processes
      .filter((process) => process.process_id !== null)
      .map((process) => process.process_estimated_time)
      .reduce((sum, time) => sum + time)
  }

  render() {
    const { modalOpen, toggleModal, editMode, serviceTypes, processes } =
      this.props
    const {
      package_name,
      package_price,
      service_type_id,
      loading,
      processesState,
      total_package_time,
    } = this.state

    return (
      <Modal bsSize="lg" show={modalOpen} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode ? 'Modificar ' : 'Agregar '}
            Paquete
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.handleSubmit}>
            <Row>
              <Col md={6}>
                <FormGroup validationState={getValidationState(package_name)}>
                  <ControlLabel>Nombre: </ControlLabel>
                  <FormControl
                    name="package_name"
                    type="text"
                    value={package_name}
                    onChange={this.handleChange}
                    required
                    placeholder="Ingresar"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup
                  validationState={getValidationState(service_type_id, true)}
                >
                  <ControlLabel>Área de servicio: </ControlLabel>
                  <Select
                    placeholder="Área de servicio"
                    options={_.values(serviceTypes)}
                    labelKey="service_type_name"
                    valueKey="service_type_id"
                    value={service_type_id}
                    onChange={(value) => {
                      this.handleChangeSelect(value, 'service_type')
                    }}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup validationState={getValidationState(package_price)}>
                  <ControlLabel>Precio: </ControlLabel>
                  <FormControl
                    name="package_price"
                    type="text"
                    value={package_price}
                    onChange={this.handleChange}
                    required
                    placeholder="$"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <ControlLabel>Total tiempo: </ControlLabel>
                  <FormControl
                    name="total_process_time"
                    type="text"
                    value={`${total_package_time} mins`}
                    readOnly
                  />
                  <p
                    style={{
                      color: 'var(--secondary-text-color)',
                      fontSize: '1.2rem',
                    }}
                  >
                    El total de tiempo se calcula de forma automática en base a
                    el tiempo de cada proceso agregado*
                  </p>
                </FormGroup>
              </Col>
            </Row>
            <ProcessTable
              processesState={processesState}
              processes={processes}
              handleChangeProcess={this.handleChangeProcess}
              editMode={editMode}
              addEmptyItemProcess={this.addEmptyItemProcess}
            />
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button type="submit" hidden id="submitCategoryForm" />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={toggleModal}>Cerrar</Button>
          <Button
            disabled={loading}
            bsStyle="primary"
            onClick={(e) => this.activeFormSubmit(e)}
            className="btn-antd-icon"
          >
            <SaveOutlined />
            {editMode ? ' Modificar' : ' Agregar'}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

PackageModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  editMode: PropTypes.bool.isRequired,
  selectedPackage: PropTypes.objectOf(PropTypes.any).isRequired,
  serviceTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  toggleModal: PropTypes.func.isRequired,
  addPackage: PropTypes.func.isRequired,
  updatePackage: PropTypes.func.isRequired,
  processes: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default PackageModal
