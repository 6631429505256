/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-extraneous-dependencies */
import React, { Component } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import NotificationSystem from 'react-notification-system'
import { connect } from 'react-redux'
import { ConfigProvider } from 'antd'
import PropTypes from 'prop-types'
import RequestResetPassword from './components/App/Login/RequestResetPassword'
import { ADD_NOTIFICATION_REF, LOG_OUT } from './actionTypes/AppTypes'
import ResetPassword from './components/App/Login/ResetPassword'
import { NotificationHandler, isTokenExpired } from './Helpers'
import SidebarPro from './components/SidebarPro/SidebarPro'
import { RedirectToLogin } from './RedirectToLogin'
import { antdTheme } from './theme'
import { AddReceptionProvider } from './contexts/AddReceptionContext'
import Login from './components/App/Login/Login'
import Full from './components/container/Full'
import Autenticate from './Autenticate'
import store from './store'

class App extends Component {
  constructor(props) {
    super(props)
    this.NotificationContainer = React.createRef()
    this.sidebarProRef = React.createRef()
  }

  state = {
    startTokenInterval: false,
    sidebarKey: 0,
  }

  componentDidMount() {
    store.dispatch({
      type: ADD_NOTIFICATION_REF,
      payload: this.NotificationContainer.current,
    })

    if (localStorage.getItem('jwtToken')) {
      this.setState({ startTokenInterval: true })
    }
  }

  componentDidUpdate() {
    const { startTokenInterval } = this.state
    if (startTokenInterval) {
      this.startTokenCheckInterval()
    }
  }

  setStartTokenInterval = (startTokenInterval) => {
    this.setState({ startTokenInterval })

    // to remove the notification created when the token is expired
    const Notification = store.getState().AppReducer.NotificationContainer
    Notification.clearNotifications()
  }

  forceReloadSidebar = () => {
    this.setState((prevState) => ({
      sidebarKey: prevState.sidebarKey + 1,
    }))
  }

  startTokenCheckInterval() {
    const { history } = this.props
    this.tokenCheckInterval = setInterval(() => {
      const token = localStorage.getItem('jwtToken')
      if (!token) {
        clearInterval(this.tokenCheckInterval)
        return
      }
      if (isTokenExpired()) {
        store.dispatch({ type: LOG_OUT })
        history.push('/login')
        NotificationHandler(
          'Sesión expirada',
          'error',
          'Vuelve a iniciar sesión',
          null,
          0
        )
      }
    }, 1000)
  }

  render() {
    const { logged } = this.props
    const { collapsed, toggled, sidebarKey } = this.state

    return (
      <ConfigProvider theme={antdTheme}>
        <AddReceptionProvider>
          <div className="app-container">
            {logged ? (
              <div className="app-content">
                <SidebarPro
                  key={sidebarKey} // Pass the key as a prop to SidebarPro
                  forceReloadSidebar={this.forceReloadSidebar} //
                  collapsed={collapsed}
                  toggled={toggled}
                  onBackdropClick={this.toggleToggled}
                />
                <div className={`main-content ${collapsed ? 'collapsed' : ''}`}>
                  <Switch>
                    <Route
                      path="/login"
                      render={(props) => (
                        <Login
                          {...props}
                          setStartTokenInterval={this.setStartTokenInterval}
                        />
                      )}
                    />
                    <Route path="/" component={Autenticate(Full)} />
                  </Switch>
                </div>
              </div>
            ) : (
              <Switch>
                <Route
                  path="/login"
                  render={(props) => (
                    <Login
                      {...props}
                      setStartTokenInterval={this.setStartTokenInterval}
                    />
                  )}
                />
                <Route path="/reset-password" component={ResetPassword} />
                <Route
                  path="/request-reset-password"
                  component={RequestResetPassword}
                />
                <Route path="/" component={RedirectToLogin} />
              </Switch>
            )}
            <NotificationSystem ref={this.NotificationContainer} />
          </div>
        </AddReceptionProvider>
      </ConfigProvider>
    )
  }
}

const mapStateToProps = (reducers) => ({
  logged: reducers.AppReducer.logged,
})

App.propTypes = {
  logged: PropTypes.bool.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default withRouter(connect(mapStateToProps)(App))
