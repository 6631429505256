import React, { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import DateRangePropTypes from './DateRangePicker.types'
import DateRangeWrapper from './DateRangePicker.styles'




const DateRangePicker = ({setStartDateGlobal, setEndDateGlobal, clearIntervals}) => {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  useEffect(() => {
    if (clearIntervals) {
      setStartDate(null)
      setEndDate(null)
    }
 },[clearIntervals])

  const handleSetStartDate = (date) => {
    setStartDate(date);
    if (date) {
      setStartDateGlobal(date.format('YYYY-MM-DD'))
    } else {
      setStartDateGlobal('')
    }
  }

    const handleSetEndDate = (date) => {
      setEndDate(date)
      if (date) {
        setEndDateGlobal(date.format('YYYY-MM-DD'))
      } else {
        setEndDateGlobal('')
      }
    }

  return (
    <DateRangeWrapper>
      <i className="far fa-calendar-alt icon" />
      <div className="calendar">
        <ReactDatePicker
          showYearDropdown
          isClearable
          showMonthDropdown
          selected={startDate}
          placeholderText="Desde"
          onChange={(date) => handleSetStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
        />
        <div className="guion" />
        <ReactDatePicker
          isClearable
          showYearDropdown
          showMonthDropdown
          placeholderText="Hasta"
          selected={endDate}
          onChange={(date) => handleSetEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
        />
      </div>
    </DateRangeWrapper>
  )
}
DateRangePicker.propTypes = DateRangePropTypes

export default DateRangePicker
