import styled from 'styled-components'

export const ArrowButton = styled.button`
  /* Reset default button styles */
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;

  display: flex;
  align-items: center;
  gap: 6px;

  font-weight: 600;
  font-size: 16px;
  color: #8d2866;

  opacity: 0.8;
  transition-duration: 0.3s;

  &:hover:not(:disabled) {
    opacity: 1;
    font-weight: 700;

    i.fas.fa-chevron-right {
      transform: scale(1.2);
      transform: translateX(3px);
    }

    i.fas.fa-chevron-left {
      transform: scale(1.2);
      transform: translateX(-3px);
    }
  }

  &:disabled {
    opacity: 0.6;
    color: #858585;
    cursor: not-allowed;
  }

  span {
    text-decoration: underline;
  }

  i.fas.fa-chevron-left,
  i.fas.fa-chevron-right {
    padding: 0;
  }

  i.fas.fa-chevron-right {
    text-decoration: none;
  }
`

export const PrimaryArrowButton = styled.button`
  margin: 10px;
  padding: 8px 14px;
  text-align: center;
  transition: 0.5s;
  background-size: 300% auto;
  color: white;
  border-radius: 10px;
  display: block;
  border: 0px;
  font-weight: 600;
  
  cursor: pointer;

  background-image: linear-gradient(
    130deg,
    #45398d 0%,
    #9a265f 51%,
    #45398d 100%
  );
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  &:hover {
    background-position: -50%;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
    box-shadow: 0px 0px 14px -7px #f09819;
  }
  
  i.fas.fa-chevron-right {
    padding-left: 6px;
  }

  &:active {
    transform: scale(0.95);
  }

  &:hover:not(:disabled) {
    opacity: 1;
    font-weight: 700;

    i.fas.fa-chevron-right {
      transform: scale(1.2);
      transform: translateX(3px);
    }
  }

  &:disabled {
    opacity: 0.7;
    background-image: none;
    background-color: #B0B0B0;
    cursor: not-allowed;
  }


  &.omit-button {
    background-image: none;
    padding: 6px 14px;
    background-color: #fff;
    color: #8d2866;

    border: 2px solid  #8d2866;
  }
`
