import React, { useState } from 'react'
import Select from 'react-select'
import _ from 'lodash'
import { FormGroup, ControlLabel, FormControl, Col, Row } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { getValidationState } from '../Common'

import PhoneInputComponent from '../Common/PhoneInput/PhoneInput'
import { emailRegex } from '../../Utilities/validations'

const isBusiness = (type) => parseInt(type, 10) === 1

const PersonForm = ({
  state,
  /* states, */
  handleChange,
  handleChangeSelect,
  contributorTypes,
  // handleChangeDocumentType,
  editMode,
  handleChangePersonType,
  handlePhoneEdit,
  workShopCode,
}) => {
  const {
    person_firstname,
    person_lastname,
    person_phone,
    person_email,
    person_email_backup,
    person_type,
    /* person_bussiness_name, */
    person_bussines_activity,
    /* city_id,
    state_id,
    filteredCities, */
    person_nrc,
    // document_type_id,
    // person_document_number,
    contributor_type_id,
    userApp,
    personTypes,
    /* person_position, */
    person_contact_number,
    country_code,
  } = state
  const [phoneError, setPhoneError] = useState(false)

  const validatePhone = () => {
    if (!person_phone) {
      return null
    }
    return phoneError ? 'error' : 'success'
  }

  const validateFields = (value, type) => {
    let result
    switch (type) {
      case 'email':
        result = emailRegex.test(value) ? 'success' : 'error'
        break
      case 'select':
        result = getValidationState(value, true)
        break
      default:
        result = getValidationState(value)
        break
    }
    return result
  }

  return (
    <div>
      <Row>
        <Col xs={12}>
          <ControlLabel>Tipo de cliente: </ControlLabel>
          <Select
            options={_.values(personTypes)}
            value={person_type}
            onChange={(value) => {
              handleChangePersonType(value)
            }}
            labelKey="label"
            valueKey="person_type"
            placeholder="Seleccione"
          />
        </Col>

        <Col
          xs={12}
          md={isBusiness(person_type) ? 12 : 6}
          lg={isBusiness(person_type) ? 12 : 6}
        >
          <FormGroup
            validationState={
              person_firstname ? validateFields(person_firstname) : null
            }
          >
            <ControlLabel>Nombres: </ControlLabel>
            <FormControl
              name="person_firstname"
              type="text"
              value={person_firstname}
              onChange={handleChange}
              required
              placeholder="Ingresar"
            />
          </FormGroup>
        </Col>
        <Col
          xs={12}
          md={isBusiness(person_type) ? 12 : 6}
          lg={isBusiness(person_type) ? 12 : 6}
        >
          <FormGroup
            validationState={
              person_lastname ? validateFields(person_lastname) : null
            }
          >
            <ControlLabel>Apellidos: </ControlLabel>
            <FormControl
              name="person_lastname"
              type="text"
              value={person_lastname}
              onChange={handleChange}
              required
              placeholder="Ingresar"
            />
          </FormGroup>
        </Col>

        {isBusiness(person_type) && (
          <Col xs={12} md={12} lg={12}>
            <FormGroup
              validationState={
                person_contact_number
                  ? validateFields(person_contact_number)
                  : null
              }
            >
              <ControlLabel>Contacto: </ControlLabel>
              <FormControl
                name="person_contact_number"
                type="text"
                value={person_contact_number}
                onChange={handleChange}
                required
                placeholder="Contacto"
              />
            </FormGroup>
          </Col>
        )}

        {/* <Col xs={12} md={4} lg={4}>
          <FormGroup>
            <ControlLabel>Departamento: </ControlLabel>
            <Select
              options={_.values(states)}
              value={state_id}
              onChange={(value) => {
                handleChangeSelect(value, 'state')
              }}
              labelKey="state_name"
              valueKey="state_id"
              placeholder="Seleccione"
              noResultsText="Sin resultados"
            />
          </FormGroup>
        </Col> */}
        {/*  <Col xs={12} md={4} lg={4}>
          <FormGroup>
            <ControlLabel>Municipio: </ControlLabel>
            <Select
              options={filteredCities}
              placeholder="Seleccione"
              value={city_id}
              onChange={(value) => {
                handleChangeSelect(value, 'city')
              }}
              labelKey="city_name"
              valueKey="city_id"
              noResultsText="Sin resultados"
            />
          </FormGroup>
        </Col> */}
        <Col xs={12} md={4} lg={4}>
          <FormGroup validationState={validatePhone()}>
            <ControlLabel>Teléfono: </ControlLabel>
            <PhoneInputComponent
              phone={person_phone}
              onChange={handlePhoneEdit}
              isError={phoneError}
              setError={setPhoneError}
              countryCode={country_code || workShopCode || localStorage.getItem('workshopCountryCode')}
              required
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={4} lg={4}>
          <FormGroup
            validationState={
              person_email ? validateFields(person_email, 'email') : null
            }
          >
            <ControlLabel>Correo: </ControlLabel>
            <FormControl
              name="person_email"
              type="email"
              value={person_email}
              onChange={handleChange}
              placeholder="email@email.com"
              required={person_email_backup}
            />
            {Object.keys(userApp).length > 0 && editMode && (
              <p style={{ color: '#3c763d', fontSize: '12px' }}>
                Usuario creado.
                {userApp.user_app_active ? (
                  <span style={{ color: '#3c763d' }}> Usuario activado</span>
                ) : (
                  <span style={{ color: '#a94442' }}> Usuario sin activar</span>
                )}
              </p>
            )}
            {Object.keys(userApp).length === 0 && editMode && (
              <p style={{ color: '#a94442', fontSize: '12px' }}>
                No tiene usuario asignado
              </p>
            )}
          </FormGroup>
        </Col>

        {/* <Col xs={12} md={4} lg={4}>
          <FormGroup validationState={getValidationState(document_type_id)}>
            <ControlLabel>Tipo de documento: </ControlLabel>
            <Select
              options={documentTypes}
              value={document_type_id}
              onChange={handleChangeDocumentType}
              labelKey="document_type_name"
              valueKey="document_type_id"
              placeholder="Seleccione"
              noResultsText="Sin resultados"
              required
              clearable={false}
            />
          </FormGroup>
        </Col>

        <Col xs={12} md={4} lg={4}>
          <FormGroup
            validationState={getValidationState(person_document_number)}
          >
            <ControlLabel>
              Número de documento
              {document_type_id &&
                ` (${documentTypes[document_type_id - 1].document_type_name})`}
              :
            </ControlLabel>
            <InputMask
              name="person_document_number"
              type="text"
              value={person_document_number}
              onChange={handleChange}
              required
              placeholder="#"
              className="form-control"
              mask={
                document_type_id
                  ? documentTypes.find(
                      (d) => d.document_type_id === document_type_id
                    ).mask
                  : ''
              }
            />
          </FormGroup>
        </Col> */}

        {isBusiness(person_type) && (
          <Col xs={12} md={4} lg={4}>
            <FormGroup
              validationState={
                contributor_type_id
                  ? validateFields(contributor_type_id, 'select')
                  : null
              }
            >
              <ControlLabel>Tipo de contribuyente: </ControlLabel>
              <Select
                options={_.values(contributorTypes)}
                placeholder="Seleccione"
                value={contributor_type_id}
                onChange={(value) => {
                  handleChangeSelect(value, 'contributor_type')
                }}
                labelKey="contributor_type_name"
                valueKey="contributor_type_id"
                noResultsText="Sin resultados"
                required
                clearable={false}
              />
            </FormGroup>
          </Col>
        )}
        {isBusiness(person_type) && (
          <Col xs={12} md={4} lg={4}>
            <FormGroup>
              <ControlLabel>NRC: </ControlLabel>
              <FormControl
                name="person_nrc"
                type="text"
                value={person_nrc}
                onChange={handleChange}
                placeholder="NRC"
              />
            </FormGroup>
          </Col>
        )}

        {isBusiness(person_type) && (
          <Col xs={12} md={4} lg={4}>
            <FormGroup>
              <ControlLabel>Giro: </ControlLabel>
              <FormControl
                name="person_bussines_activity"
                type="text"
                value={person_bussines_activity}
                onChange={handleChange}
                placeholder="Giro de la entidad"
              />
            </FormGroup>
          </Col>
        )}
      </Row>
    </div>
  )
}


PersonForm.propTypes = {
  editMode: PropTypes.bool.isRequired,
  state: PropTypes.objectOf(PropTypes.any).isRequired,
  /* states: PropTypes.arrayOf(PropTypes.object).isRequired, */
  contributorTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleChangeSelect: PropTypes.func.isRequired,
  // handleChangeDocumentType: PropTypes.func.isRequired,
  handleChangePersonType: PropTypes.func.isRequired,
  handlePhoneEdit: PropTypes.func.isRequired,
  workShopCode: PropTypes.number.isRequired
}

export default PersonForm
