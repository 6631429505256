import axios from 'axios'

export const getContributorTypes = async () => {
  try {
    const response = await axios.get(`api/contributor_types/reception/all`)
    return response.data.data
  } catch (error) {
    throw ('Error cargando lista de contribuidores', error)
  }
}

export const addClient = async (client, vehicleId) => {
  try {
    const body = {
      name: client.clientName || client.bussinesName,
      lastName: client.clientLastName || client.bussinesReason,
      phone: client.clientPhone || client.bussinesPhone,
      countryCode: client.clientCountryCode || client.bussinesCountryCode,
      email: client.clientEmail || client.bussinesEmail,
      type: client.clientType,
      contributorTypeId: client.bussinesContributorType,
      personBusinessActivity: client.bussinesGiro,
      nit: client.bussinessNit,
      vehicleId,
    }

    const response = await axios.post('api/persons/reception/', body)

    return response.data
  } catch (error) {
    if (error.response.status === 404) {
      throw error.response.data.data
    } else {
      throw ('Error al crear el cliente', error)
    }
  }
}
export const replaceOwnerService = async (client, vehicleId) => {
  try {
    const body = {
      vehicleId,
      ownerId: client,
    }

    const response = await axios.post(
      'api/persons/reception/replace-owner/',
      body
    )

    return response.data
  } catch (error) {
    if (error.response.status === 404) {
      throw error.response.data.data
    } else {
      throw ('Error al remplazar cliente', error)
    }
  }
}
