import axios from 'axios'
import _ from 'lodash'

import {
  GET_WORK_ORDER_STATUSES,
  ADD_WORK_ORDER_STATUS,
  DELETE_WORK_ORDER_STATUS,
} from '../actionTypes/WorkOrderStatusTypes'
import { NotificationHandler } from '../Helpers'

const getWorkOrderStatusesAction = (payload) => ({
  type: GET_WORK_ORDER_STATUSES,
  payload,
})

const addWorkOrderAction = (payload) => ({
  type: ADD_WORK_ORDER_STATUS,
  payload,
})

const deleteWorkOrderAction = (payload) => ({
  type: DELETE_WORK_ORDER_STATUS,
  payload,
})

export const getWorkOrderStatuses = (forceFetch) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const quantity = _.size(getState().WorkOrderStatusReducer.workOrderStatuses)
    const workOrdersStatusesQuantity = quantity
    if (workOrdersStatusesQuantity === 0 || forceFetch) {
      axios
        .get('/api/work_order_status')
        .then((response) => {
          if (!response.data.error) {
            const workOrderStatuses = _.keyBy(
              response.data.data,
              'work_order_status_id'
            )
            dispatch(getWorkOrderStatusesAction(workOrderStatuses))
            resolve(response.data)
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al obtener el estado de la orden de trabajo',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al obtener el estado de la orden de trabajo',
            error
          )
        })
    } else {
      resolve({})
    }
  })

export const sendBudget = (budget) =>
  new Promise((resolve, reject) => {
    axios
      .post('/api/budgets', budget)
      .then((response) => {
        if (!response.data.error) {
          const details = []
          _.forEach(budget.budgetDetails, (detail) => {
            details.push({
              budget_id: response.data.data.budget_id,
              budget_detail_item: detail.budget_detail_item,
              budget_detail_item_price: detail.budget_detail_item_price,
              budget_detail_workforce_id: detail.budget_detail_workforce_id,
              budget_detail_workforce_price:
                detail.budget_detail_workforce_price,
              budget_detail_type: detail.budget_detail_type,
              budget_detail_description: detail.budget_detail_description,
            })
          })
          axios
            .post('/api/budget_details', { budget_details: details })
            .then((detailResponse) => {
              if (!detailResponse.data.error) {
                resolve(detailResponse.data)
              } else {
                reject()
              }
            })
        } else {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al agregar el presupuesto',
            response.data.data
          )
        }
      })
      .catch((error) => {
        reject()
        NotificationHandler(
          'Error',
          'error',
          'Error al agregar el presupuesto',
          error
        )
      })
  })

export const addBudget = (budget) => (dispatch, getState) =>
  sendBudget(budget).then((response) => {
    NotificationHandler('Done', 'info', 'presupuesto agregado')
    const budgetToRedux = {
      ...response.data.data,
      reception: getState().CategoryReducerr.receptions[budget.budget_id],
      employee: getState().EmployeeReducer.employees[budget.budget_id],
      package: getState().PackageReducer.packages[budget.budget_id],
    }
    dispatch(addWorkOrderAction(budgetToRedux))
  })

const updateBudgetDetails = (details, budgetId) =>
  new Promise((resolve, reject) => {
    const detailsPromises = []
    let addNewBudgets
    _.forEach(details, (detail) => {
      if (detail.budget_detail_id) {
        detailsPromises.push(
          new Promise((resolveDetail, rejectDetail) => {
            axios
              .put(`/api/budget_details/${detail.budget_detail_id}`, detail)
              .then((detailResponse) => {
                if (!detailResponse.data.error) {
                  resolveDetail(detailResponse.data)
                } else {
                  rejectDetail()
                  NotificationHandler(
                    'Error',
                    'error',
                    'Error al actualizar el presupuesto',
                    detailResponse.data.data
                  )
                }
              })
              .catch((error) => {
                rejectDetail()
                NotificationHandler(
                  'Error',
                  'error',
                  'Error al actualizar el presupuesto',
                  error
                )
              })
          })
        )
      } else {
        const detailsToAdd = []
        detailsToAdd.push({
          budget_id: budgetId,
          budget_detail_item: detail.budget_detail_item,
          budget_detail_item_price: detail.budget_detail_item_price,
          budget_detail_workforce_id: detail.budget_detail_workforce_id,
          budget_detail_workforce_price: detail.budget_detail_workforce_price,
          budget_detail_type: detail.budget_detail_type,
          budget_detail_description: detail.budget_detail_description,
        })
        addNewBudgets = new Promise((resolveAdd, rejectAdd) => {
          axios
            .post('/api/budget_details', { budget_details: detailsToAdd })
            .then((detailResponse) => {
              if (!detailResponse.data.error) {
                resolveAdd()
              } else {
                rejectAdd()
                NotificationHandler(
                  'Error',
                  'error',
                  'Error al actualizar el detalle del presupuesto',
                  detailResponse.data
                )
              }
            })
        })
      }
      Promise.all([...detailsPromises, addNewBudgets])
        .then(() => {
          resolve()
        })
        .catch(() => {
          reject()
        })
    })
  })
export const updateBudget = (budget) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (budget.budget_id) {
      axios
        .put(`/api/budgets/${budget.budget_id}`, budget)
        .then((response) => {
          if (!response.data.error) {
            updateBudgetDetails(budget.budgetDetails, budget.budget_id).then(
              () => {
                dispatch(getWorkOrderStatuses(true))
                resolve()
              }
            )
            resolve(response)
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al actualizar el presupuesto',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al actualizar el presupuesto',
            error
          )
        })
    } else {
      reject()
      NotificationHandler(
        'Error',
        'error',
        `No se proporcionó id a: ${budget.budget_name}`
      )
    }
  })

export const deleteBudget = (budget) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (budget.budget_id) {
      axios
        .delete(`/api/budgets/${budget.Budget_id}`)
        .then((response) => {
          if (!response.data.error) {
            dispatch(deleteWorkOrderAction(budget))
            resolve(response)
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al eliminar el presupuesto',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al eliminar el presupuesto',
            error
          )
        })
    } else {
      reject()
      NotificationHandler(
        'Error',
        'error',
        `No se proporcionó id a: ${budget.Budget_name}`
      )
    }
  })
