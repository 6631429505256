import React, { Component } from 'react'
import {
  Modal,
  Button,
  Form,
  FormControl,
  ControlLabel,
  Row,
  Col,
  FormGroup,
} from 'react-bootstrap'
import PropTypes from 'prop-types'
import { SaveOutlined } from '@ant-design/icons'
import { getValidationState, showConfirmDialog } from '.'
import { AppLoader } from './AppLoader'

class ReusableModal extends Component {
  state = {
    loading: false,
  }

  componentDidUpdate(prevProps) {
    const { selectedObject } = this.props
    const { selectedObject: prevSelectedObject } = prevProps

    if (selectedObject !== prevSelectedObject) {
      this.setReusableModalState()
    }
  }

  setReusableModalState = () => {
    const { fieldName, selectedObject } = this.props
    this.setState({
      [`${fieldName}_name`]:
        selectedObject[`${fieldName}_name`] !== undefined &&
          selectedObject[`${fieldName}_name`] !== null
          ? selectedObject[`${fieldName}_name`]
          : '',
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { state } = this
    const {
      fieldName,
      addObject,
      updateObject,
      confirmUpdate,
      toggleModal,
      selectedObject,
      editMode,
    } = this.props
    const obj = {
      [`${fieldName}_name`]: state[`${fieldName}_name`],
      [`${fieldName}_id`]: selectedObject[`${fieldName}_id`],
    }
    if (editMode) {
      showConfirmDialog(
        confirmUpdate.title,
        confirmUpdate.message,
        updateObject,
        obj,
        () => {
          this.setState({ loading: false })
          toggleModal()
        },
        () => {
          this.setState({ loading: false })
        }
      )
    } else {
      this.setState({ loading: true })
      addObject(obj)
        .then(() => {
          this.setState({ loading: false })
          toggleModal()
        })
        .catch(() => {
          this.setState({ loading: false })
        })
    }
  }

  render() {
    const {
      toggleModal,
      editMode,
      showModal,
      fieldNameTitle,
      pagePermissions,
      fieldName,
    } = this.props
    const { loading } = this.state
    const { state } = this
    let can_edit = 0
    if (pagePermissions) {
      const affordToEdit = pagePermissions.can_edit
      can_edit = affordToEdit
    }

    let title = ''
    if (editMode) {
      if (pagePermissions.can_edit) {
        title = 'Modificar'
      }
    } else {
      title = 'Agregar'
    }

    return (
      <Modal show={showModal} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {title} {fieldNameTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.handleSubmit}>
            <Row>
              <Col md={12}>
                <FormGroup
                  validationState={getValidationState(
                    state[`${fieldName}_name`]
                  )}
                >
                  <ControlLabel>Nombre: </ControlLabel>
                  <FormControl
                    name={`${fieldName}_name`}
                    type="text"
                    value={state[`${fieldName}_name`]}
                    onChange={this.handleChange}
                    required
                    placeholder="Ingresar"
                  />
                </FormGroup>
              </Col>
            </Row>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button type="submit" hidden id="submitModelForm" />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {loading && (
            <div style={{ float: 'left', width: '50px' }}>
              <AppLoader />
            </div>
          )}
          <Button onClick={toggleModal}>Cerrar</Button>
          {!!can_edit && (
            <Button
              disabled={loading}
              bsStyle="primary"
              onClick={() => {
                document.getElementById('submitModelForm').click()
              }}
              className="btn-antd-icon"
            >
              <SaveOutlined />
              {editMode ? ' Modificar' : ' Agregar'}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    )
  }
}

ReusableModal.propTypes = {
  pagePermissions: PropTypes.objectOf(PropTypes.number).isRequired,
  selectedObject: PropTypes.objectOf(PropTypes.any).isRequired,
  fieldName: PropTypes.string.isRequired,
  fieldNameTitle: PropTypes.string.isRequired,
  editMode: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  updateObject: PropTypes.func.isRequired,
  addObject: PropTypes.func.isRequired,
  confirmUpdate: PropTypes.func.isRequired,
}

export default ReusableModal
