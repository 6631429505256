import React from 'react'
import moment from 'moment'
import { FormControl, Alert } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { CalendarFilled, CheckCircleFilled, CreditCardFilled, FolderFilled, ToolFilled, UserOutlined } from '@ant-design/icons'
import PerfectScrollbar from 'react-perfect-scrollbar'
import MotorcycleIcon from '../../../icons/MotorcycleIcon'
import { List, ListItem } from '../Styled/StyledComponents'
import { AppLoader } from '../../Common/AppLoader'


const ReceptionItem = ({ workOrder, setWorkOrderToInvoice }) => {
  const { work_order_date, mechanic, invoice, work_order_id } = workOrder
  const employeeName = `${mechanic.employee_name} ${mechanic.employee_lastname}`

  const {
    vehicle: { vehicle_license_plate, owner, model },
  } = workOrder.budgets.reception
  const { brand } = model
  const { person_firstname, person_lastname } = owner[0]
  const isWorkOrderInvoiced = Object.keys(invoice).length > 0
  const styleCompleted = {
    color: isWorkOrderInvoiced ? 'green' : 'red',
    marginRight: '5px'
  }
  return (
    <ListItem
      onClick={() => setWorkOrderToInvoice(workOrder)}
      data-tip="Facturar"
    >
      <div className="reception-item-header">
        <p>
          <FolderFilled style={{ marginRight: "5px" }} />
          <em>#{work_order_id}</em>
        </p>

        <p>
          <CreditCardFilled style={{ marginRight: "5px" }} />
          {vehicle_license_plate}
        </p>
        <p>
          <CalendarFilled style={{ marginRight: "5px" }} />
          {moment(work_order_date).format('DD-MM-YYYY')}
        </p>
      </div>

      <div className="reception-item-body">
        <p>
          <ToolFilled style={{ marginRight: "5px" }} />
          {employeeName}
        </p>
        <p>
          <UserOutlined style={{ marginRight: "5px" }} />
          Cliente: {`${person_firstname} ${person_lastname}`}
        </p>
        <p className="vehicle-icon-container">
          <MotorcycleIcon />
          {`${brand.brand_name} ${model.model_name}`}
        </p>
        <div style={{ display: 'flex' }}>
          <p>
            <CheckCircleFilled style={styleCompleted} />
            {isWorkOrderInvoiced ? 'Procesada' : 'Sin procesar'}
          </p>
          {isWorkOrderInvoiced && (
            <p>
              <i
                className="fas fa-print"
                style={{ color: 'red', marginLeft: '10px' }}
              />
              Sin imprimir
            </p>
          )}
        </div>
      </div>
    </ListItem>
  )
}

const SaleItem = ({ workOrder, setWorkOrderToInvoice }) => {
  // eslint-disable-next-line
  const {
    work_order_id,
    work_order_date,
    employee,
    person,
    invoice,
    // eslint-disable-next-line
  } = workOrder

  const employeeName = `${employee.employee_name} ${employee.employee_lastname}`

  const { person_firstname, person_lastname } = person

  const isWorkOrderInvoiced = Object.keys(invoice).length > 0
  const styleCompleted = {
    color: isWorkOrderInvoiced ? 'green' : 'red',
    marginRight: '5px'
  }
  return (
    <ListItem
      onClick={() => setWorkOrderToInvoice(workOrder)}
      data-tip="Facturar"
    >
      <div className="reception-item-header">
        <p>
          <FolderFilled style={{ marginRight: "5px" }} />
          <em>#{work_order_id}</em>
        </p>
        <p>
          <CalendarFilled />
          {moment(work_order_date).format('DD-MM-YYYY')}
        </p>
      </div>

      <div className="reception-item-body">
        <p>
          <UserOutlined />
          Vende:
          {employeeName}
        </p>
        <p>
          <UserOutlined />
          Cliente: {`${person_firstname} ${person_lastname}`}
        </p>
        <div style={{ display: 'flex' }}>
          <p>
            <i className="fas fa-check-circle" style={styleCompleted} />
            {isWorkOrderInvoiced ? 'Procesada' : 'Sin procesar'}
          </p>
          {isWorkOrderInvoiced && (
            <p>
              <i
                className="fas fa-print"
                style={{ color: 'red', marginLeft: '10px' }}
              />
              Sin imprimir
            </p>
          )}
        </div>
      </div>
    </ListItem>
  )
}

const RenderWorkOrders = (workOrdersToInvoice, setWorkOrderToInvoice, filter) =>
  workOrdersToInvoice.map((workOrder) => {
    if (workOrder.work_order_type === 1) {
      if (workOrder.budgets) {
        if (workOrder.budgets.reception) {
          if (
            workOrder.budgets.reception.vehicle.vehicle_license_plate
              .toUpperCase()
              .includes(filter.toUpperCase())
          ) {
            return (
              <ReceptionItem
                setWorkOrderToInvoice={setWorkOrderToInvoice}
                key={workOrder.work_order_id}
                workOrder={workOrder}
              />
            )
          }
        }
      }
    }
    return (
      <SaleItem
        setWorkOrderToInvoice={setWorkOrderToInvoice}
        key={workOrder.work_order_id}
        workOrder={workOrder}
      />
    )
  })

const RenderList = (
  workOrdersToInvoice,
  setWorkOrderToInvoice,
  filter,
  noWorkOrdersText
) =>
  // eslint-disable-next-line
  workOrdersToInvoice.length > 0 ? (
    <List>
      <PerfectScrollbar>
        {RenderWorkOrders(workOrdersToInvoice, setWorkOrderToInvoice, filter)}
      </PerfectScrollbar>
    </List>
  ) : (
    <Alert bsStyle="warning">{noWorkOrdersText}</Alert>
  )

ReceptionItem.propTypes = {
  workOrder: PropTypes.objectOf(PropTypes.any).isRequired,
  setWorkOrderToInvoice: PropTypes.func.isRequired,
}

SaleItem.propTypes = {
  workOrder: PropTypes.objectOf(PropTypes.any).isRequired,
  setWorkOrderToInvoice: PropTypes.func.isRequired,
}

const Tab = ({
  filter,
  handleChange,
  noReceptions,
  title,
  placeholder,
  loading,
  workOrdersToInvoice,
  setWorkOrderToInvoice,
  noWorkOrdersText,
}) => (
  <>
    <p className="receptionList-title">{title}</p>
    <div className="input-container">
      <FormControl
        placeholder={placeholder}
        type="text"
        name="filter"
        value={filter}
        onChange={handleChange}
        disabled={noReceptions}
      />
    </div>
    {!loading ? (
      RenderList(
        workOrdersToInvoice,
        setWorkOrderToInvoice,
        filter,
        noWorkOrdersText
      )
    ) : (
      <center>
        <AppLoader />
      </center>
    )}
  </>
)

Tab.propTypes = {
  filter: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  noReceptions: PropTypes.bool,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  workOrdersToInvoice: PropTypes.arrayOf(PropTypes.object).isRequired,
  setWorkOrderToInvoice: PropTypes.func.isRequired,
  noWorkOrdersText: PropTypes.string,
}

Tab.defaultProps = {
  filter: '',
  title: '',
  placeholder: '',
  noWorkOrdersText: '',
  noReceptions: false,
}

export default Tab
