import styled from 'styled-components'

export const InfoDisclaimerContainer = styled.div`
  display: flex;
  gap: 8px;

  padding: 8px 12px;
  border-radius: 5px;

  background-color: #f2faff;

  i.fas.fa-info-circle {
    font-size: 16px;
    display: flex;
    align-items: center;
    color: #2b81dc;
  }

  p {
    font-size: 0.9em;
    margin-bottom: 0;
  }
`
