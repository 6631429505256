import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

import {
  CardContainer,
  CardTitle,
  Card,
  Text,
  WorkedTime,
  Line,
} from './Components'

const LaboralInfo = ({ currentUser }) => {
  return (
    <CardContainer width={45}>
      <CardTitle>Información laboral</CardTitle>
      <Card>
        <Text bold fontSize="16px">
          Período laboral
        </Text>
        <WorkedTime>
          <Text>
            {currentUser.employee_start_date
              ? moment(currentUser.employee_start_date).format('DD/MM/YYYY')
              : ''}
          </Text>
          <Line />
          <Text>~</Text>
        </WorkedTime>
      </Card>
    </CardContainer>
  )
}

LaboralInfo.propTypes = {
  currentUser: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default LaboralInfo
