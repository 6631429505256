import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import styled from 'styled-components'
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { Row, Col } from 'react-bootstrap'
import { PlusCircleFilled } from '@ant-design/icons';
import { Container, BodyContent } from '../../App/Common/index'
import {
    getMechanicScore,
    getMechanicBilling,
    getMechanicEfficiency,
} from '../../../actions/DashboardAction'
import '../Home.css'
import { Body } from '../ChartComponents/ChartUtilComponents'
import MetricModal from './MetricModal'
import { BREAK_POINTS_DESKTOP_FIRST } from '../../../global/css/breakPoints'

const monthLabels = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
]
class Metrics extends Component {
    state = {
        lastMechanicScore: {},
        lastMechanicBilling: {},
        lastMechanicEfficiency: {},
        showMetricModal: false,
        MetricData: {},
        typeId: 0,
    }

    async componentDidMount() {

        const { getMechanicBillingAction, getMechanicScoreAction, getMechanicEfficiencyAction, currentUser } = this.props

        const getMechanicBillingData = await getMechanicBillingAction(currentUser.employee_id)

        const getMechanicScoreData = await getMechanicScoreAction(currentUser.employee_id)

        const getMechanicEfficiencyData = await getMechanicEfficiencyAction(currentUser.employee_id)

        this.setState({
            lastMechanicBilling: getMechanicBillingData.invoiceMonthly.billedMonthly,
            lastMechanicBillingAvg: getMechanicBillingData.avInvoiceMonthly,
            lastMechanicScore: getMechanicScoreData.scoreMon1,
            lastMechanicScoreAvg: getMechanicScoreData.scoreMon2,
            lastMechanicEfficiency: getMechanicEfficiencyData,
        })
    }

    openMetricModal = (n, d) => {
        this.toggleMetricModal()
        this.setState({
            typeId: n,
            MetricData: d
        })
    }

    getEfficiencyEvaluation = (totalRealTime, totalTime) => {
        if (totalTime) {
            if (Math.round((totalRealTime / totalTime) * 100, 2) <= 125 && Math.round((totalRealTime / totalTime) * 100, 2) !== 0) {
                return `${Math.round((totalRealTime / totalTime) * 100, 2)}%`
            }
            return "IRREAL"
        }
        return `0%`
    }


    toggleMetricModal = () => {
        const { showMetricModal } = this.state
        this.setState({ showMetricModal: !showMetricModal })
    }

    render() {
        const { currentUser } = this.props
        const { lastMechanicBilling, lastMechanicScore, lastMechanicScoreAvg, lastMechanicEfficiency, lastMechanicBillingAvg, showMetricModal, typeId, MetricData } = this.state
        const reverselastMechanicBilling = _.reverse(lastMechanicBilling)
        const reverselastMechanicScore = _.reverse(lastMechanicScore)
        const reverselastMechanicScoreAvg = _.reverse(lastMechanicScoreAvg)
        const reverselastMechanicEfficiency = _.reverse(lastMechanicEfficiency)
        const reverselastMechanicBillingAvg = _.reverse(lastMechanicBillingAvg)
        return (
            <Container>
                <BodyContent>
                    <Body>
                        <h1>Historial de metricas</h1>
                    </Body>
                </BodyContent>
                <div className="dashboard-section-container margin-top">
                    {lastMechanicBilling && lastMechanicScore && lastMechanicScoreAvg &&
                        lastMechanicEfficiency && lastMechanicBillingAvg ?
                        <Row className="section-last-ratings section-center without_row">

                            {
                                _.map(reverselastMechanicBilling, (m, i) => {
                                    return (<Col sm={12} md={6}>
                                        <Row className="dashboard-section-container margin-bottom">
                                            <h1 className="dashboard-section-title">{monthLabels[m.month - 1]}</h1>
                                            <Col xs={4} md={4}>
                                                <CircularProgressbarCostum>
                                                    <CircularProgressbarWithChildren
                                                        value={(m.invoiced / currentUser.employee_metric_goal) * 100}
                                                        strokeWidth={7}
                                                        styles={buildStyles({
                                                            pathColor: "#219653",
                                                            trailColor: "rgb(23, 150, 83, 20%)",
                                                        })}
                                                    >
                                                        <h3>Facturación</h3>
                                                        <span>{`${Math.round((m.invoiced / currentUser.employee_metric_goal) * 100, 2)}%`}</span>
                                                    </CircularProgressbarWithChildren>
                                                    <button type="button" className="btn-antd-icon" onClick={() => {
                                                        const data = { invData: reverselastMechanicBilling[i], avInv: reverselastMechanicBillingAvg[i] }
                                                        this.openMetricModal(0, data)
                                                    }}>
                                                        <PlusCircleFilled className="facturation--color" />
                                                    </button>
                                                </CircularProgressbarCostum>
                                            </Col>
                                            <Col xs={4} md={4}>
                                                <CircularProgressbarCostum>
                                                    <CircularProgressbarWithChildren
                                                        value={reverselastMechanicEfficiency[i].totalTime ? Math.round((reverselastMechanicEfficiency[i].totalRealTime / reverselastMechanicEfficiency[i].totalTime) * 100, 2) : 0}
                                                        strokeWidth={7}
                                                        styles={buildStyles({
                                                            pathColor: "#F44336",
                                                            trailColor: "rgb(244, 67, 54, 20%)",
                                                        })}
                                                    >
                                                        <h3>Tiempos</h3>
                                                        <span>{this.getEfficiencyEvaluation(reverselastMechanicEfficiency[i].totalRealTime, reverselastMechanicEfficiency[i].totalTime)}</span>
                                                    </CircularProgressbarWithChildren>
                                                    <button type="button" className="btn-antd-icon" onClick={() => {
                                                        this.openMetricModal(1, reverselastMechanicEfficiency[i])
                                                    }}>
                                                        <PlusCircleFilled className="efficiency--color" />
                                                    </button>
                                                </CircularProgressbarCostum>
                                            </Col>
                                            <Col xs={4} md={4}>
                                                <CircularProgressbarCostum>
                                                    <CircularProgressbarWithChildren
                                                        value={(reverselastMechanicScore[i].score / 5) * 100}
                                                        strokeWidth={7}
                                                        styles={buildStyles({
                                                            pathColor: "#FFCC4D",
                                                            trailColor: "rgb(255, 204, 77, 20%)",
                                                        })}
                                                    >
                                                        <h3>Evaluación</h3>
                                                        <span className={(reverselastMechanicScore[i].score / 5) * 100 !== 0 ? '' : 'noReviews'}>{(reverselastMechanicScore[i].score / 5) * 100 !== 0 ? `${(reverselastMechanicScore[i].score / 5) * 100}%` : "No hay vehiculos evaluados"}</span>
                                                    </CircularProgressbarWithChildren>
                                                    <button type="button" className="btn-antd-icon" onClick={() => {
                                                        const data = {
                                                            score: reverselastMechanicScore[i].score,
                                                            percentage: reverselastMechanicScoreAvg[i].percentage,
                                                            totalWorkOrder: reverselastMechanicScoreAvg[i].totalWorkOrder
                                                        }
                                                        this.openMetricModal(2, data)
                                                    }}>
                                                        <PlusCircleFilled className="evaluation--color" />
                                                    </button>
                                                </CircularProgressbarCostum>
                                            </Col>
                                        </Row>
                                    </Col>)
                                })
                            }
                            <MetricModal
                                modalOpen={showMetricModal}
                                toggleModal={this.toggleMetricModal}
                                typeId={typeId}
                                data={MetricData}
                            />
                        </Row> : <Loader><div className="lds-ripple"> <div /><div /> </div> </Loader>}
                </div>
            </Container>)
    }

}

const CircularProgressbarCostum = styled.div`
    width: 150px;
    height: 150px;
    margin-top: 20px;
    @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
        width: 90px;
        height: 90px;
    }
    h3{
        font-size: 2rem;
        @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
            font-size: 1.3rem;
        }
    }
    span{
    font-size: 2rem;
        @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
            font-size: 1.3rem;
        }
    }
    .noReviews{
        font-size: 1.3rem;
            @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
                font-size: 1rem;
            }
        }
    i{
        position: absolute;
        margin-top: -46px;
        margin-left: 53px;
        font-size: 30px;
        cursor: pointer;
        @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
            margin-left: 35px;
            font-size: 20px;
        }
      }
    
      .evaluation--color{
        color:#FFCC4D;
      }
      .facturation--color{
        color:#219653;
      }
      .efficiency--color{
        color:#F44336;
      }
      button{
        border: none;
        cursor: default;
      }
`

const Loader = styled.div`
text-align: center;
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #000;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}`


Metrics.propTypes = {
    currentUser: PropTypes.objectOf(PropTypes.any).isRequired,
    getMechanicScoreAction: PropTypes.func.isRequired,
    getMechanicBillingAction: PropTypes.func.isRequired,
    getMechanicEfficiencyAction: PropTypes.func.isRequired,
}

const mapStateToProps = ({ AppReducer }) => ({
    currentUser: AppReducer.currentUser,
})

const mapDispatchToProps = () => ({
    getMechanicScoreAction(mechanic_id) {
        return getMechanicScore(mechanic_id)
    },
    getMechanicBillingAction(mechanic_id) {
        return getMechanicBilling(mechanic_id)
    },
    getMechanicEfficiencyAction(mechanic_id) {
        return getMechanicEfficiency(mechanic_id)
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(Metrics)
