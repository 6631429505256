import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import TextArea from 'antd/es/input/TextArea'
import { useAddReceptionContext } from '../../../../../contexts/AddReceptionContext'
import { addReceptionActions } from '../../../../../reducers/AddReceptionReducer'
import GradientTextComponent from '../../../Common/GradientText.js/GradientText'
import { ObservationsStepWrapper } from './Observations.styles'
import PreviousNextButton from '../../../../buttons/PreviousNextButton/PreviousNextButton'
import { getNextButtonText } from '../../../../../Helpers'

const ObservationsStep = ({ handleNext, handlePrevious }) => {
  const { state, dispatch } = useAddReceptionContext()
  const { observations, isEdit } = state
  const [value, setValue] = useState(observations)

  const isSkipButtonStyle = isEdit ? false : !value

  const handleAddObservation = () => {
    dispatch({
      type: addReceptionActions.SET_OBSERVATIONS,
      payload: value,
    })
  }

  return (
    <>
      <main className="new-reception__main">
        <div className="new-reception__main__question">
          <GradientTextComponent text="Observaciones (No visuales)" />
        </div>
        <div className="new-reception__main__content">
          <ObservationsStepWrapper>
            <TextArea
              onChange={(e) => setValue(e.target.value)}
              defaultValue={observations}
              value={value}
              rows={8}
              placeholder="Ejemplo: El vehículo presenta dificultad al encender por las mañanas"
            />
          </ObservationsStepWrapper>
        </div>
      </main>
      <div className="new-reception__navigation-buttons">
        <PreviousNextButton
          isPrevious
          onClickHandler={() => {
            handleAddObservation()
            handlePrevious()
          }}
        />
        <PreviousNextButton
          isPrevious={false}
          nextText={getNextButtonText(value, isEdit)}
          isSkip={isSkipButtonStyle}
          onClickHandler={() => {
            handleAddObservation()
            handleNext()
          }}
        />
      </div>
    </>
  )
}

ObservationsStep.propTypes = {
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
}
export default ObservationsStep
