import React, { Component } from 'react'
import { connect } from 'react-redux'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory from 'react-bootstrap-table2-filter'
import styled from 'styled-components'
import { PropTypes, object, string } from 'prop-types'
import { Panel, Grid, Row, Col, Button, Tab } from 'react-bootstrap'
import { MenuOutlined, PlusCircleFilled } from '@ant-design/icons';
import _ from 'lodash'
import {
  Container,
  ContainerHeader,
  BodyContent,
  showConfirmDialog,
  PanelHeader,
} from '../Common/index'
import WorkOrderModal from './WorkOrderModal'
import getColumns from './columns'
import { BREAK_POINTS_DESKTOP_FIRST } from '../../../global/css/breakPoints'

import {
  getWorkOrders,
  addWorkOrder,
  updateWorkOrder,
  deleteWorkOrder,
  finishWorkOrder,
  setWorkOrderToAdministrate,
  getWorkOrdersQuantityInformation,
} from '../../../actions/WorkOrderAction'
import { getEmployees, getMechanics } from '../../../actions/EmployeeAction'
import { getPackages } from '../../../actions/PackageAction'
import { getWorkOrderStatuses } from '../../../actions/WorkOrderStatusAction'
import {
  getReceptions,
  cleanReceptionAction,
} from '../../../actions/ReceptionAction'
import { getProducts } from '../../../actions/ProductActions'
import { getServiceTypes } from '../../../actions/ServiceTypeAction'
import { getProcesses } from '../../../actions/ProcessAction'

import WorkOrderProces from './WorkOrderProcess'
import { SearchBar } from './WorkOrderProcess/Components'
import { AppLoader } from '../Common/AppLoader'
import { OrdersCard } from './OrdersCard/OrdersCard'
import EmptyState from '../Common/EmptyState/EmptyState'

const CustomNav = styled.div`
  display: flex;
`
const CustomNavItem = styled.p`
  cursor: pointer;
  padding: 10px 20px;
  margin: 0;
  font-weight: ${(props) => (props.$active ? 'bold' : '400')};
  border-bottom: solid 4px ${(props) => (props.$active ? '#8C236A' : '#fff')};

  :hover {
    color: ${(props) => (props.$active ? '' : '#8C236A')};
    font-weight: ${(props) => (props.$active ? '' : 'bold')};
    trasition-duration: 0.3s;
  }

  @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
    padding: 0px 10px 10px;
  }
`

const SCREEN_ID = 29
class WorkOrderContainer extends Component {
  state = {
    loading: true,
    workshopType: 2,
    selectedWorkOrder: {},
    showModal: false,
    activeKey: 2,
    searchBarText: '',
    enProceso: 0,
    enPausa: 0,
    enTotal: 0,
    extraTime: 0,
    inLineTotal: 0,
    selectedType: null,
    especificWorkOrders: null,
    workOrdersQuantityInformationState: {},
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions)
    // to create a work order from reception list
    const { receptionToWorkOrder } = this.props
    this.setState({
      showModal: !!receptionToWorkOrder,
    })
    const {
      getAllEmployees,
      getAllMechanics,
      getAllPackages,
      getAllWorkOrderStatuses,
      getServices,
      getAllProducts,
      getAllReceptions,
      getAllProcesses,
      getAllWorkOrders,
      getWorkOrdersQuantityInformationData,
    } = this.props
    const promises = [
      getAllEmployees(),
      getAllMechanics(),
      getAllPackages(),
      getAllWorkOrderStatuses(),
      getServices(),
      getAllProducts(),
      getAllReceptions(),
      getAllProcesses(),
      getAllWorkOrders(),
      getWorkOrdersQuantityInformationData(),
    ]
    Promise.all(promises)
      .then(() => {
        const {
          location,
          workOrders,
          currentUser,
          workOrdersQuantityInformation,
        } = this.props

        this.setState({ workshopType: currentUser.workshop.workshop_type || 2 })
        this.setState({
          workOrdersQuantityInformationState: workOrdersQuantityInformation,
        })
        if (currentUser.rol.rol_name === 'Mecánico') {
          const only_meca_order = _.filter(workOrders, (e) => {
            return e.mechanic_id === currentUser.employee_id
          })
          this.setState({ loading: false, filteredWorkOrders: only_meca_order })
          this.countProcess(only_meca_order)
        } else {
          this.setState({ loading: false, filteredWorkOrders: workOrders })
          this.countProcess(workOrders)
        }
        if (location.state) {
          this.updateWorkOrder(location.state.selectedWorkOrder)
        }
      })
      .catch(() => {
        this.setState({ loading: false })
      })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ filteredWorkOrders: nextProps.workOrders })
  }

  componentDidUpdate(prevProps, prevState) {
    const { workOrders } = this.props
    const { filteredWorkOrders } = this.state
    const { workOrders: prevWorkOrders } = prevProps
    const { filteredWorkOrders: prevFilteredWorkOrders } = prevState
    if (workOrders !== prevWorkOrders) {
      this.countProcess(workOrders)
    }
    if (filteredWorkOrders !== prevFilteredWorkOrders) {
      this.countProcess(filteredWorkOrders)
    }
  }

  componentWillUnmount() {
    const { clearReception } = this.props
    window.removeEventListener('resize', this.updateDimensions)
    clearReception()
    this.mounted = false
  }

  handleChange = (e) => {
    const value = e.target.value.toUpperCase()
    const { workOrders } = this.props
    this.setState({
      [e.target.name]: value,
      filteredWorkOrders: _.filter(workOrders, (workOrder) => {
        let goingToReturn = false
        if (workOrder.work_order_status_id === 3) {
          goingToReturn = true
        } else {
          goingToReturn = false
        }
        if (workOrder.budgets.reception) {
          if (workOrder.budgets.reception.vehicle) {
            if (
              workOrder.budgets.reception.vehicle.vehicle_license_plate.includes(
                value
              )
            ) {
              goingToReturn = true
            } else {
              goingToReturn = false
            }
          }
        }
        return goingToReturn
      }),
    })
  }

  updateDimensions = () => {
    const { width } = window.screen
    this.setState({ width })
  }

  toggleModal = () => {
    const { showModal } = this.state
    this.setState({
      selectedWorkOrder: {},
      showModal: !showModal,
    })
  }

  setWorkOrderToAdministrate = (workOrder) => {
    const { history, setWorkOrderToAdministrateAction } = this.props
    setWorkOrderToAdministrateAction(workOrder)
    history.push('/administrateWorkOrder')
  }

  addWorkOrder = () => {
    const { showModal } = this.state
    this.setState({
      selectedWorkOrder: {},
      showModal: !showModal,
    })
  }

  updateWorkOrder = (workOrder) => {
    const { showModal } = this.state
    this.setState({
      selectedWorkOrder: workOrder,
      showModal: !showModal,
    })
  }

  deleteWorkOrder = (workOrder) => {
    const { removeWorkOrder } = this.props
    showConfirmDialog(
      'Eliminar',
      '¿Desea eliminar esta orden de trabajo?',
      removeWorkOrder,
      workOrder
    )
  }

  finishWorkOrder = (workOrder) => {
    const { endWorkOrder } = this.props
    showConfirmDialog(
      'Finalizar',
      `¿Desea cerrar esta orden de trabajo? (#${workOrder.work_order_id})`,
      endWorkOrder,
      workOrder
    )
  }

  countProcess = (workOrders) => {
    const incomingCount = _.filter(workOrders, (n) => {
      return n.work_order_status_id === 1
    })

    const pauseCount = _.filter(workOrders, (n) => {
      return n.work_order_status_id === 2
    })

    const extraCount = _.filter(workOrders, (n) => {
      if (n.work_order_start && n.work_order_status_id === 1) {
        const now = new Date().getTime()
        const startDateTime = new Date(n.work_order_start).getTime()
        const difference = startDateTime - now
        let timeReal = Math.abs(difference / 1000)
        timeReal += n.work_order_real_time * 60
        return n.work_order_time * 60 <= timeReal
      }
      return null
    })

    const inlineCount = _.filter(workOrders, (n) => {
      return n.work_order_status_id === 4
    })

    const processCount = _.filter(incomingCount, (n) => {
      return !extraCount.includes(n)
    })

    this.setState({
      enProceso: processCount,
      enPausa: pauseCount,
      enTotal: pauseCount.length + incomingCount.length + inlineCount.length,
      extraTime: extraCount,
      inLineTotal: inlineCount,
    })
  }

  getEspecificOrder = (OrderType) => {
    const { enPausa, inLineTotal, extraTime, enProceso } = this.state
    switch (OrderType) {
      case 1:
        this.setState({ selectedType: 1 })
        this.setState({
          especificWorkOrders: enPausa,
        })
        break
      case 2:
        this.setState({ selectedType: 2 })
        this.setState({
          especificWorkOrders: inLineTotal,
        })
        break
      case 3:
        this.setState({ selectedType: 3 })
        this.setState({
          especificWorkOrders: extraTime,
        })
        break
      case 4:
        this.setState({ selectedType: 4 })
        this.setState({
          especificWorkOrders: enProceso,
        })
        break
      default:
        this.setState({ selectedType: null })
        this.setState({
          especificWorkOrders: null,
        })
        break
    }
  }

  getEspecificOrderString = (OrderType) => {
    switch (OrderType) {
      case 1:
        return 'en pausa'
      case 2:
        return 'en cola'
      case 3:
        return 'en tiempo extra'
      case 4:
        return 'en proceso'
      default:
        return ''
    }
  }

  goToCreateReception = () => {
    const { history } = this.props

    // Saves the value to open the add reception modal in /receptions
    localStorage.setItem('receptionOpen', true)
    history.push('/receptions')
  }

  render() {
    const {
      packages,
      mechanics,
      permissions,
      products,
      services,
      receptions,
      processes,
      receptionToWorkOrder,
      history,
      createWorkOrder,
      modifyWorkOrder,
      employees,
      currentUser,
    } = this.props

    const {
      loading,
      showModal,
      selectedWorkOrder,
      activeKey,
      searchBarText,
      filteredWorkOrders,
      enProceso,
      enPausa,
      enTotal,
      extraTime,
      inLineTotal,
      especificWorkOrders,
      width,
      workOrdersQuantityInformationState,
    } = this.state
    const { can_write, can_edit, can_delete } = permissions[SCREEN_ID]
    const pagePermissions = {
      can_write,
      can_edit,
      can_delete,
    }

    const visibleWorkOrders = especificWorkOrders
      ? _.values(especificWorkOrders).reverse()
      : _.values(filteredWorkOrders).reverse()

    const NavBar = (
      <CustomNav>
        <CustomNavItem
          $active={activeKey === 2}
          eventKey={2}
          onClick={() => {
            this.setState({ activeKey: 2 })
          }}
        >
          <LiveText>
            <span data-tip data-for="global">
              Tu taller en vivo
            </span>
            <i className="fas fa-circle" />
          </LiveText>
        </CustomNavItem>
        <CustomNavItem
          $active={activeKey === 1}
          eventKey={1}
          onClick={() => {
            this.setState({ activeKey: 1 })
          }}
        >
          Administrar
        </CustomNavItem>
      </CustomNav>
    )
    const Search = (
      <SearchBarContainer>
        <SearchBar
          placeholder="Buscar por placa"
          name="searchBarText"
          value={searchBarText}
          onChange={this.handleChange}
        />
      </SearchBarContainer>
    )
    return (
      <div>
        <Container>
          <ContainerHeader
            left
            title="Órdenes de trabajo"
            component={NavBar}
            search={Search}
          />
          <BodyContent>
            <Grid fluid>
              <Tab.Container
                id="idTab"
                activeKey={activeKey}
                onSelect={() => { }}
              >
                <Tab.Content animation>
                  <Tab.Pane eventKey={1}>
                    <Panel>
                      <Panel.Heading bsClass="panel-heading">
                        <Panel.Title>
                          <PanelHeader>
                            <div>
                              <p id="title">
                                <MenuOutlined style={{ marginRight: '7px' }} /> Administrar
                                Órdenes de trabajo
                              </p>
                            </div>
                            <div>
                              {!!can_write && (
                                <Button
                                  onClick={this.addWorkOrder}
                                  bsStyle="success"
                                  className="btn-add btn-antd-icon"
                                >
                                  <PlusCircleFilled />
                                  Agregar registro
                                </Button>
                              )}
                            </div>
                          </PanelHeader>
                        </Panel.Title>
                      </Panel.Heading>
                      <Panel.Body>
                        {loading ? (
                          <AppLoader />
                        ) : (
                          <Row>
                            <Col md={10} />
                            <Col md={12}>
                              <CustomStyledTable>
                                <BootstrapTable
                                  noDataIndication="Sin datos para mostrar"
                                  keyField="work_order_id"
                                  data={_.values(filteredWorkOrders).reverse()}
                                  columns={getColumns(
                                    this.updateWorkOrder,
                                    this.deleteWorkOrder,
                                    this.finishWorkOrder,
                                    this.setWorkOrderToAdministrate,
                                    { can_edit, can_delete, currentUser },
                                    width
                                  )}
                                  filter={filterFactory()}
                                  pagination={paginationFactory()}
                                />
                              </CustomStyledTable>
                            </Col>
                          </Row>
                        )}
                      </Panel.Body>
                    </Panel>
                  </Tab.Pane>
                  <Tab.Pane eventKey={2}>
                    {loading ? (
                      <AppLoader />
                    ) : (
                      <MainContainer>
                        <LiveDataContainer>
                          <InformationText>
                            <p>
                              Órdenes de trabajo en el mes:{' '}
                              <span
                                data-tip
                                data-for="global"
                                style={{ color: '#FF081E' }}
                              >
                                {
                                  workOrdersQuantityInformationState.created_orders
                                }
                              </span>
                              <span
                                style={{ fontWeight: '100', color: '#000' }}
                              >
                                {' '}
                                /{' '}
                                {
                                  workOrdersQuantityInformationState.available_orders
                                }{' '}
                                disponibles
                              </span>
                            </p>
                          </InformationText>
                        </LiveDataContainer>
                        <Summary>
                          <OrdersCard
                            text={
                              currentUser.rol_id === 3
                                ? 'Mis vehículos asignados'
                                : 'Vehículos en taller'
                            }
                            isSelected={this.state.selectedType === null}
                            quantity={enTotal}
                            fill="#0077b6"
                            onClickHandler={() => {
                              this.getEspecificOrder()
                            }}
                            svgType={
                              this.state.workshopType === 1
                                ? 'motorbike'
                                : 'car'
                            }
                          />
                          <OrdersCard
                            text="En proceso"
                            isSelected={this.state.selectedType === 4}
                            quantity={enProceso.length}
                            fill="#06d6a0"
                            onClickHandler={() => {
                              this.getEspecificOrder(4)
                            }}
                            svgType='wrench'
                          />
                          <OrdersCard
                            text="En pausa"
                            isSelected={this.state.selectedType === 1}
                            quantity={enPausa.length}
                            animation={enPausa.length > 0
                              ? 'blinking'
                              : ''
                            }
                            fill="#e63946"
                            onClickHandler={() => {
                              this.getEspecificOrder(1)
                            }}
                            svgType='pause'
                          />
                          <OrdersCard
                            text="En tiempo extra"
                            isSelected={this.state.selectedType === 3}
                            quantity={extraTime.length}
                            fill="#ffba08"
                            onClickHandler={() => {
                              this.getEspecificOrder(3)
                            }}
                            svgType='history'
                          />
                          <OrdersCard
                            text="En cola"
                            isSelected={this.state.selectedType === 2}
                            quantity={inLineTotal.length}
                            fill="#000"
                            onClickHandler={() => {
                              this.getEspecificOrder(2)
                            }}
                            svgType='time'
                          />
                        </Summary>
                        {visibleWorkOrders.length > 0 ? (
                          <WorkOrderProces
                            workOrders={visibleWorkOrders}
                            updateProgress={this.updateProgress}
                            updateWorkOrder={this.updateWorkOrder}
                            deleteWorkOrder={this.deleteWorkOrder}
                            history={history}
                            extraTime={extraTime}
                            currentUser={currentUser}
                          />
                        ) : (
                          <EmptyContainer>
                            <EmptyState
                              text={`No se encontraron órdenes de trabajo ${this.getEspecificOrderString(
                                this.state.selectedType
                              )}`}
                            />
                            <Button
                              onClick={this.goToCreateReception}
                              bsStyle="success btn-create-reception-from-work-orders"
                            >
                              Crear recepción
                            </Button>
                          </EmptyContainer>
                        )}
                      </MainContainer>
                    )}
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Grid>
            <WorkOrderModal
              modalOpen={showModal}
              toggleModal={this.toggleModal}
              editMode={!_.isEmpty(selectedWorkOrder)}
              addWorkOrder={createWorkOrder}
              updateWorkOrder={modifyWorkOrder}
              selectedWorkOrder={selectedWorkOrder}
              employees={_.values(employees)}
              mechanics={_.values(mechanics)}
              packages={packages}
              products={products}
              services={services}
              receptions={receptions}
              processes={processes}
              history={history}
              receptionToWorkOrder={receptionToWorkOrder}
              pagePermissions={pagePermissions}
            />
          </BodyContent>
        </Container>
      </div>
    )
  }
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 8px 20px;

  @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
    padding: 8px 10px;
  }

  @media (${BREAK_POINTS_DESKTOP_FIRST.MOBILE}) {
    padding: 0;
    gap: 1rem;
  }
`

const LiveDataContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 25px;

  @media (${BREAK_POINTS_DESKTOP_FIRST.MOBILE}) {
    gap: 15px;
  }
`

const Summary = styled.div`
  display: flex;
  width: 100%;
  justify-content: stretch;
  padding: 13px 0 0 12px;
  margin-top: 2px;
  gap: 20px;
  overflow-y: none;

  @media (${BREAK_POINTS_DESKTOP_FIRST.MOBILE}) {
    gap: 10px;
  }
`

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;
  gap: 20px;
  align-items: center;
  margin-top: 80px;

  @media (${BREAK_POINTS_DESKTOP_FIRST.MOBILE}) {
    margin-top: 50px;
  }
`

const SearchBarContainer = styled.div`
  position: relative;

  svg {
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .svg_icons {
    transform: scale(1.2);
  }
`

const LiveText = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
    margin-right: 10px;
  }
  i {
    padding-left: 6px;
    color: #8c236a;
    margin-top: 3px;
    animation: blink 1s linear infinite;
  }
  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  span {
    color: #000;
    padding-right: 5px;
  }
  .custom-tooltip {
    border-radius: 15px;
  }
`
const InformationText = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-left: 3px;
  font-weight: bold;
  font-size: 1.75rem;
  cursor: default;

  @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
    margin-right: 10px;
    padding-left: 7px;
  }
  @media (${BREAK_POINTS_DESKTOP_FIRST.MOBILE}) {
    margin-right: 10px;
    font-size: 1.25rem;
  }

  span {
    color: #000;
    padding-right: 5px;
  }

  p {
    margin: 0;
  }
`

const CustomStyledTable = styled.div`
  @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
    table {
      border: none;

      thead {
        tr {
          display: flex;
          flex-direction: column;

          th {
            label {
              width: 100%;
            }
          }

          th {
            border: none;
          }

          th:nth-last-child(1),
          th:nth-last-child(2),
          th:nth-last-child(3),
          th:nth-last-child(4) {
            display: none;
          }
        }
      }

      tbody {
        tr {
          border: 1px solid #ddd;
          display: flex;
          flex-wrap: wrap;
          border-radius: 10px;
          margin: 20px 0px;
          padding-top: 10px;
          padding-bottom: 10px;

          td {
            width: 100%;
            border: 0;
            border-right: 0px !important;
            border-left: 0px !important;
          }

          td:nth-last-child(1),
          td:nth-last-child(2),
          td:nth-last-child(3),
          td:nth-last-child(4) {
            width: 15%;
          }
          td:nth-child(9) {
            width: 100%;
          }
          td::before {
            font-weight: bold;
            margin-right: 0.5rem;
          }

          td:nth-child(1)::before {
            content: 'Orden:';
          }

          td:nth-child(2)::before {
            content: 'Recepción:';
          }

          td:nth-child(3)::before {
            content: 'Placa:';
          }

          td:nth-child(4)::before {
            content: 'Marca:';
          }

          td:nth-child(5)::before {
            content: 'Modelo:';
          }

          td:nth-child(6)::before {
            content: 'Registro:';
          }

          td:nth-child(7)::before {
            content: 'Total:';
          }

          td:nth-child(8)::before {
            content: 'Estado:';
          }

          td:nth-child(9)::before {
            content: 'Porcentaje:';
          }
          td:nth-child(10),
          td:nth-child(11),
          td:nth-child(12) {
            margin: unset !important;
            margin-top: 20px !important;
            width: 33.3% !important;
            button{
              width: 100%;
              display:flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
  }
`

WorkOrderContainer.propTypes = {
  getAllEmployees: PropTypes.func.isRequired,
  getAllMechanics: PropTypes.func.isRequired,
  getAllPackages: PropTypes.func.isRequired,
  getAllWorkOrderStatuses: PropTypes.func.isRequired,
  getServices: PropTypes.func.isRequired,
  getAllProducts: PropTypes.func.isRequired,
  getAllReceptions: PropTypes.func.isRequired,
  getAllProcesses: PropTypes.func.isRequired,
  getAllWorkOrders: PropTypes.func.isRequired,
  getWorkOrdersQuantityInformationData: PropTypes.func.isRequired,
  removeWorkOrder: PropTypes.func.isRequired,
  clearReception: PropTypes.func.isRequired,
  endWorkOrder: PropTypes.func.isRequired,
  createWorkOrder: PropTypes.func.isRequired,
  modifyWorkOrder: PropTypes.func.isRequired,
  setWorkOrderToAdministrateAction: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  workOrders: PropTypes.objectOf(PropTypes.any).isRequired,
  permissions: PropTypes.objectOf(PropTypes.any).isRequired,
  packages: PropTypes.objectOf(PropTypes.object).isRequired,
  mechanics: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.objectOf(PropTypes.any),
  ]).isRequired,
  products: PropTypes.objectOf(PropTypes.any).isRequired,
  services: PropTypes.objectOf(PropTypes.any).isRequired,
  receptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  processes: PropTypes.objectOf(PropTypes.object).isRequired,
  receptionToWorkOrder: PropTypes.oneOfType([object, string]).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  employees: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.objectOf(PropTypes.any),
  ]).isRequired,
  currentUser: PropTypes.objectOf(PropTypes.any).isRequired,
  workOrdersQuantityInformation: PropTypes.objectOf(PropTypes.any).isRequired,
}

const mapStateToProps = (store) => ({
  // elementos padre
  workOrders: store.WorkOrderReducer.workOrders,
  workOrdersQuantityInformation: store.WorkOrderReducer
    .workOrdersQuantityInformation
    ? store.WorkOrderReducer.workOrdersQuantityInformation
    : {},
  workOrderStatuses: store.WorkOrderStatusReducer.workOrderStatuses,
  employees: store.EmployeeReducer.employees,
  mechanics: store.MechanicReducer.mechanics,
  packages: store.PackageReducer.packages,
  products: store.ProductReducer.products,
  services: store.ServiceTypeReducer.serviceTypes
    ? store.ServiceTypeReducer.serviceTypes
    : {},
  receptions: _.filter(
    store.ReceptionReducer.receptions,
    (reception) => reception.reception_state === 1
  ),
  receptionToWorkOrder: store.ReceptionReducer.receptionToWorkOrder
    ? store.ReceptionReducer.receptionToWorkOrder
    : '',
  processes: store.ProcessReducer.processes,
  permissions: store.AppReducer.permissions,
  currentUser: store.AppReducer.currentUser,
})
const mapDispatchToProps = (dispatch) => ({
  getAllWorkOrders() {
    return dispatch(getWorkOrders())
  },
  getAllEmployees() {
    return dispatch(getEmployees())
  },
  getAllMechanics() {
    return dispatch(getMechanics())
  },
  getAllPackages() {
    return dispatch(getPackages())
  },
  getAllWorkOrderStatuses() {
    return dispatch(getWorkOrderStatuses())
  },
  getAllReceptions() {
    return dispatch(getReceptions())
  },
  getAllProducts() {
    return dispatch(getProducts())
  },
  getServices() {
    return dispatch(getServiceTypes())
  },
  getAllProcesses() {
    return dispatch(getProcesses())
  },
  createWorkOrder(workOrder) {
    return dispatch(addWorkOrder(workOrder))
  },
  modifyWorkOrder(workOrder) {
    return dispatch(updateWorkOrder(workOrder))
  },
  removeWorkOrder(workOrder) {
    return dispatch(deleteWorkOrder(workOrder))
  },
  endWorkOrder(workOrder) {
    return dispatch(finishWorkOrder(workOrder))
  },
  clearReception() {
    return dispatch(cleanReceptionAction())
  },
  setWorkOrderToAdministrateAction(workOrder) {
    return dispatch(setWorkOrderToAdministrate(workOrder))
  },
  getWorkOrdersQuantityInformationData() {
    return dispatch(getWorkOrdersQuantityInformation())
  },
})
export default connect(mapStateToProps, mapDispatchToProps)(WorkOrderContainer)
