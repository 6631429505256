import styled, { keyframes } from 'styled-components'
import { Panel } from 'react-bootstrap'

export const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
`
const blinking = keyframes`
  0% {
    opacity: 0.75;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0.75;
  }
`
export const Card = styled(Panel)`
  width: 100%;
  position: relative;
  margin-bottom: 30px;
  background-color: white;
  box-shadow: 5px 5px 5px #ccc;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    transform: scale(1.01);
    transition-duration: 0.3s;
  }

  ${({ loading }) =>
    loading ? `animation: ${blinking} 1s linear infinite` : ''}

   .opacity{
     opacity: 0.5;
   } 

   .paused {
    background-color: #CF0B0B !important;
    color: #fff !important;
   }

   .in-extraTime{
      background-color: #ffb700 !important;
      color: #000 !important;
   }

   .in-progress{
      background-color: #06d6a0 !important;
      color: #fff !important;
   }
`

export const Body = styled(Panel.Body)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export const Title = styled.h4`
  display: inline-block;
  float: ${({ float }) => float}
  margin-right: ${({ marginRight }) => marginRight || '0px'}
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const UpdateIcon = styled.h4`
  display: inline-block;
  float: right;
  &:hover {
    cursor: pointer;
    animation: ${rotate} 2s linear infinite;
    transform: scale(1.2);
  }
`
export const SubTitle = styled.p`
  font-weight: semibold;
  font-size: 15;
  display: inline-block
  float: ${({ float }) => float};
  color: ${({ color }) => color}
`

export const BottomDiv = styled.div`
  margin-bottom: 0px;
  margin-top: 20px;
  float: bottom;
`

export const SearchBar = styled.input`
  width: 100%;
  margin: 0px;
  border-radius: 3px;
  border: 1px solid #C6C6C6;
  font-size: 1em;
  padding: 5px 5px 5px 10px;
  box-shadow: 3px 3px 5px #ccc;

  @media (max-width: 900px) {
    display: block;
    width: 100%;
    margin: 15px 0px;
  }
`
