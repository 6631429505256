import React, { Component } from 'react'
import { connect } from 'react-redux'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory from 'react-bootstrap-table2-filter'
import { Panel, Grid, Row, Col, Button } from 'react-bootstrap'
import _ from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { MenuOutlined, PlusCircleFilled } from '@ant-design/icons';
import {
  Container,
  ContainerHeader,
  BodyContent,
  showConfirmDialog,
  PanelHeader,
} from '../Common/index'
import {
  getProductOrders,
  addProductOrder,
  updateProductOrder,
  deleteProductOrder,
} from '../../../actions/ProductOrderAction'
import { getPersons, addPerson } from '../../../actions/PersonAction'
import { getStates } from '../../../actions/StateAction'
import { getProducts } from '../../../actions/ProductActions'
import { getProcesses } from '../../../actions/ProcessAction'
import { getCities } from '../../../actions/CityAction'
import { getContributors } from '../../../actions/ContributorTypesAction'
import getColumns from './columns'
import { getFullNames } from './functions'
import ProductOrderModal from './ProductsOrderModal'
import { BREAK_POINTS_DESKTOP_FIRST } from '../../../global/css/breakPoints'
import { AppLoader } from '../Common/AppLoader'

const SCREEN_ID = 40

class ProductsOrder extends Component {
  state = {
    showModal: false,
    loading: true,
    selectedOrder: {},
  }

  componentDidMount() {
    const {
      getAllProductOrders,
      getAllProducts,
      getAllPersons,
      getAllCities,
      getAllStates,
      getAllContributors,
      getAllProcesses,
    } = this.props
    const promises = [
      getAllProductOrders(),
      getAllProducts(),
      getAllPersons(),
      getAllStates(),
      getAllCities(),
      getAllContributors(),
      getAllProcesses(),
    ]
    Promise.all(promises)
      .then(() => {
        this.setState({ loading: false })
      })
      .catch(() => {
        this.setState({ loading: false })
      })
  }

  componentWillUnmount() {
    this.mounted = false
  }

  toggleModal = () => {
    const { showModal } = this.state
    this.setState({
      selectedOrder: {},
      showModal: !showModal,
    })
  }

  addOrder = () => {
    const { showModal } = this.state
    this.setState({
      selectedOrder: {},
      showModal: !showModal,
    })
  }

  updateOrder = (productOrder) => {
    const { showModal } = this.state
    this.setState({
      selectedOrder: productOrder,
      showModal: !showModal,
    })
  }

  deleteOrder = (productOrder) => {
    const { removeProductOrder } = this.props
    showConfirmDialog(
      'Eliminar',
      '¿Desea eliminar esta venta?',
      removeProductOrder,
      productOrder
    )
  }

  render() {
    const {
      persons,
      productOrders,
      products,
      permissions,
      processes,
      currentUser,
      states,
      cities,
      contributors,
      addOrder,
      createPerson,
      updateOrder,
    } = this.props
    const { loading, showModal, selectedOrder } = this.state
    const { can_write, can_edit, can_delete } = permissions[SCREEN_ID]
    const pagePermissions = {
      can_write,
      can_edit,
      can_delete,
    }

    return (
      <div>
        <Container>
          <ContainerHeader left title="Ventas" />
          <BodyContent>
            <Grid fluid>
              <Panel>
                <Panel.Heading bsClass="panel-heading">
                  <Panel.Title>
                    <PanelHeader>
                      <div>
                        <p id="title">
                          <MenuOutlined style={{ marginRight: '7px' }} /> Administrar Ventas
                        </p>
                      </div>
                      <div>
                        {!!can_write && (
                          <Button
                            onClick={this.addOrder}
                            bsStyle="success"
                            className="btn-add btn-antd-icon"
                          >
                            <PlusCircleFilled />
                            Agregar registro
                          </Button>
                        )}
                      </div>
                    </PanelHeader>
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  {loading ? (
                    <AppLoader />
                  ) : (
                    <Row>
                      <Col md={10} />
                      <Col md={12}>
                        <CustomStyledTable>
                          <BootstrapTable
                            noDataIndication="Sin datos para mostrar"
                            keyField="work_order_id"
                            data={_.reverse(getFullNames(productOrders))}
                            columns={getColumns(
                              this.updateOrder,
                              this.deleteOrder,
                              { can_edit, can_delete }
                            )}
                            filter={filterFactory()}
                            pagination={paginationFactory()}
                          />
                        </CustomStyledTable>
                      </Col>
                    </Row>
                  )}
                </Panel.Body>
              </Panel>
            </Grid>
            <ProductOrderModal
              modalOpen={showModal}
              toggleModal={this.toggleModal}
              state={this.state}
              permissions={permissions}
              editMode={!_.isEmpty(selectedOrder)}
              addOrder={addOrder}
              addPerson={createPerson}
              employee={currentUser}
              states={states}
              cities={cities}
              contributors={contributors}
              updateOrder={updateOrder}
              selectedOrder={selectedOrder}
              persons={persons}
              products={products}
              processes={processes}
              pagePermissions={pagePermissions}
            />
          </BodyContent>
        </Container>
      </div>
    )
  }
}

const CustomStyledTable = styled.div`
  @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
    table {
      border: none;

      thead {
        tr {
          display: flex;
          flex-direction: column;

          th {
            label {
              width: 100%;
            }
          }

          th {
            border: none;
          }

          th:nth-last-child(1),
          th:nth-last-child(2) {
            display: none;
          }
        }
      }

      tbody {
        tr {
          border: 1px solid #ddd;
          display: flex;
          flex-wrap: wrap;
          border-radius: 10px;
          margin: 20px 0px;
          padding-top: 10px;
          padding-bottom: 10px;

          td {
            width: 100%;
            border: 0;
            border-right: 0px !important;
            border-left: 0px !important;
          }

          td:nth-last-child(1),
          td:nth-last-child(2) {
            width: 40%;
          }

          td::before {
            font-weight: bold;
            margin-right: 0.5rem;
          }

          td:nth-child(1)::before {
            content: 'Número:';
          }

          td:nth-child(2)::before {
            content: 'Fecha:';
          }

          td:nth-child(3)::before {
            content: 'Cliente:';
          }

          td:nth-child(4)::before {
            content: 'Vendedor:';
          }

          td:nth-child(5)::before {
            content: 'Total:';
          }

          td:nth-child(6)::before {
            content: 'Estado:';
          }
        }
      }
    }
  }
`

const mapStateToProps = (store) => ({
  persons: store.PersonReducer.persons,
  productOrders: store.ProductOrderReducer.productOrders,
  products: store.ProductReducer.products,
  permissions: store.AppReducer.permissions,
  processes: store.ProcessReducer.processes,
  states: store.StateReducer.states,
  cities: store.CityReducer.cities,
  contributors: store.ContributorTypeReducer.contributorTypes,
  currentUser: store.AppReducer.currentUser,
})

const mapDispatchToProps = (dispatch) => ({
  getAllPersons() {
    return dispatch(getPersons())
  },
  getAllProductOrders() {
    return dispatch(getProductOrders())
  },
  getAllStates() {
    return dispatch(getStates())
  },
  getAllCities() {
    return dispatch(getCities())
  },
  getAllContributors() {
    return dispatch(getContributors())
  },
  getAllProducts() {
    return dispatch(getProducts())
  },
  getAllProcesses() {
    return dispatch(getProcesses())
  },
  addOrder(productOrder) {
    return dispatch(addProductOrder(productOrder))
  },
  createPerson(person) {
    return dispatch(addPerson(person))
  },
  updateOrder(productOrder) {
    return dispatch(updateProductOrder(productOrder))
  },
  removeProductOrder(productOrder) {
    return dispatch(deleteProductOrder(productOrder))
  },
})

ProductsOrder.propTypes = {
  getAllProductOrders: PropTypes.func.isRequired,
  getAllCities: PropTypes.func.isRequired,
  getAllContributors: PropTypes.func.isRequired,
  getAllProducts: PropTypes.func.isRequired,
  getAllStates: PropTypes.func.isRequired,
  getAllPersons: PropTypes.func.isRequired,
  getAllProcesses: PropTypes.func.isRequired,
  createPerson: PropTypes.func.isRequired,
  addOrder: PropTypes.func.isRequired,
  updateOrder: PropTypes.func.isRequired,
  removeProductOrder: PropTypes.func.isRequired,
  persons: PropTypes.arrayOf(PropTypes.object).isRequired,
  processes: PropTypes.arrayOf(PropTypes.object).isRequired,
  productOrders: PropTypes.arrayOf(PropTypes.object).isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  states: PropTypes.arrayOf(PropTypes.object).isRequired,
  cities: PropTypes.arrayOf(PropTypes.object).isRequired,
  contributors: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentUser: PropTypes.objectOf(PropTypes.any).isRequired,
  permissions: PropTypes.objectOf(PropTypes.bool).isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsOrder)
