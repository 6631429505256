import axios from 'axios'
import _ from 'lodash'

import { GET_CITIES } from '../actionTypes/CityTypes'
import { NotificationHandler } from '../Helpers'

const getCitiesAction = (payload) => ({
  type: GET_CITIES,
  payload,
})

// eslint-disable-next-line import/prefer-default-export
export const getCities = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const citiesQuantity = _.size(getState().CityReducer.cities)
    if (citiesQuantity === 0) {
      axios
        .get('/api/cities')
        .then((response) => {
          if (!response.data.error) {
            const cities = _.keyBy(response.data.data, 'city_id')
            dispatch(getCitiesAction(cities))
            resolve(response.data)
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al obtener las ciudades',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al obtener las ciudades',
            error
          )
        })
    } else {
      resolve({})
    }
  })
