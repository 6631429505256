import React, { Component } from 'react'
import {
  Modal,
  Button,
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
} from 'react-bootstrap'
import swal from 'sweetalert2'
import PropTypes from 'prop-types'
import { SaveOutlined } from '@ant-design/icons'

class BrandModal extends Component {
  state = {
    loading: false,
    editMode: false,
    title: '',
    label: '',
    placeholder: '',
    registerValue: null,
    previousRegisterValue: null,
    registerValueId: null,
    fieldName: null,
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      complements: {
        registerValueId: prevRegisterValueId,
        registerValue: prevRegisterValue,
      },
    } = prevProps

    const { fieldName: prevStateFieldName } = prevState

    const {
      complements: { registerValueId, registerValue, fieldName },
    } = this.props

    if (
      fieldName !== prevStateFieldName ||
      registerValueId !== prevRegisterValueId ||
      registerValue !== prevRegisterValue
    ) {
      this.setDynamicBasicModalState()
    }
  }

  setDynamicBasicModalState() {
    const { editMode, complements } = this.props
    this.setState({
      editMode,
      registerValue: complements.registerValue,
      previousRegisterValue: complements.registerValue,
      registerValueId: complements.registerValueId,
      title: complements.title,
      placeholder: complements.placeholder,
      label: complements.label,
      fieldName: complements.fieldName,
    })
  }

  getInitialState = () => ({
    loading: false,
    editMode: false,
    title: '',
    label: '',
    placeholder: '',
    registerValue: '',
    previousRegisterValue: '',
    registerValueId: '',
    fieldName: '',
  })

  handleInputText = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { editMode } = this.state
    if (!editMode) {
      this.insert()
    } else {
      this.update()
    }
  }

  update() {
    const {
      registerValue,
      previousRegisterValue,
      fieldName,
      title,
      registerValueId,
    } = this.state
    const { complements, update, handleClose } = this.props
    const source = {
      id: registerValueId,
      [fieldName]: registerValue,
    }
    const message = `${previousRegisterValue === registerValue
      ? `'${previousRegisterValue}'`
      : `'${previousRegisterValue}' a '${registerValue}'`
      }`

    const sweet = {
      title: '¿Modificar?',
      text: complements.questionToUpdate(message),
      type: 'warning',
      confirmButtonText: 'Aceptar',
      // showMessageOnCancel: false,
      showCancelButton: true,
      preConfirm: () => {
        this.setState({
          loading: true,
        })
        update(source)
          .then((response) => {
            if (response) {
              swal({
                title: 'Modificado',
                text: `${title}: ${message}`,
                type: 'success',
              })
              handleClose()
              this.setState(this.getInitialState())
            } else {
              swal({
                title: 'Error',
                text: `Error al modificar: ${message}`,
                type: 'error',
              })
              this.setState({ loading: false })
            }
          })
          .catch(() => {
            swal({
              title: 'Error',
              text: `Error al modificar: ${message}`,
              type: 'error',
            })
            this.setState({ loading: false })
          })
      },
    }

    swal(sweet)
  }

  insert() {
    this.setState({
      loading: true,
    })
    const { fieldName, registerValue } = this.state
    const { insert, handleClose } = this.props
    const source = {
      [fieldName]: registerValue,
    }
    insert(source)
      .then((response) => {
        if (response) {
          handleClose()
          this.setState(this.getInitialState())
        } else {
          this.setState({ loading: false })
        }
      })
      .catch(() => {
        this.setState({ loading: false })
      })
  }

  render() {
    const {
      loading,
      editMode,
      title,
      label,
      registerValue,
      previousRegisterValue,
      placeholder,
    } = this.state
    const { pagePermissions, modalState, handleClose } = this.props
    let modalTextHeader = editMode ? 'Modificar' : 'Agregar'
    if (modalTextHeader === 'Modificar')
      modalTextHeader = pagePermissions.can_edit ? 'Modificar' : ''
    let can_edit
    if (pagePermissions) {
      ; ({ can_edit } = pagePermissions)
    }
    return (
      <Modal show={modalState} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {modalTextHeader}
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={(e) => this.handleSubmit(e)}>
            <FormGroup>
              <ControlLabel>{label}</ControlLabel>
              <FormControl
                autoFocus
                required
                autoComplete="off"
                type="text"
                name="registerValue"
                value={registerValue}
                placeholder={placeholder}
                onChange={(e) => this.handleInputText(e)}
              />
              {editMode && (
                <div>
                  <FormControl.Feedback />
                  <HelpBlock>
                    Nombre actual:
                    {previousRegisterValue}
                  </HelpBlock>
                </div>
              )}
            </FormGroup>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button type="submit" id="submitModal" hidden />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Cerrar</Button>
          {!!can_edit && (
            <Button
              onClick={() => {
                document.getElementById('submitModal').click()
              }}
              style={{
                paddingTop: '5px',
                paddingBottom: '5px',
                fontSize: '1.2em',
              }}
              bsStyle="primary"
              type="submit"
              disabled={loading}
              className="btn-antd-icon"
            >
              <SaveOutlined />{!editMode ? 'Agregar' : 'Guardar'}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    )
  }
}

BrandModal.propTypes = {
  update: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  insert: PropTypes.func.isRequired,
  pagePermissions: PropTypes.objectOf(PropTypes.any).isRequired,
  modalState: PropTypes.bool.isRequired,
  editMode: PropTypes.bool.isRequired,
  complements: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default BrandModal
