import React from "react"

const PauseIcon = () => {
  return (
    <svg
      width="63"
      height="63"
      viewBox="0 0 63 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_di_288_108)">
        <path
          d="M31.5 0C16.3085 0 4 12.3085 4 27.5C4 42.6915 16.3085 55 31.5 55C46.6915 55 59 42.6915 59 27.5C59 12.3085 46.6915 0 31.5 0ZM29.7258 36.371C29.7258 37.3468 28.9274 38.1452 27.9516 38.1452H22.629C21.6532 38.1452 20.8548 37.3468 20.8548 36.371V18.629C20.8548 17.6532 21.6532 16.8548 22.629 16.8548H27.9516C28.9274 16.8548 29.7258 17.6532 29.7258 18.629V36.371ZM42.1452 36.371C42.1452 37.3468 41.3468 38.1452 40.371 38.1452H35.0484C34.0726 38.1452 33.2742 37.3468 33.2742 36.371V18.629C33.2742 17.6532 34.0726 16.8548 35.0484 16.8548H40.371C41.3468 16.8548 42.1452 17.6532 42.1452 18.629V36.371Z"
          fill="#FF4444"
        />
      </g>
      <defs>
        <filter
          id="filter0_di_288_108"
          x="0"
          y="0"
          width="63"
          height="63"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_288_108"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_288_108"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_288_108"
          />
        </filter>
      </defs>
    </svg>
  )
}

export default PauseIcon;