import React, { useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Progress, Tooltip } from 'antd'
import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'
import 'react-phone-number-input/style.css'
import StepWithVehicleWrapped from '../StepWithVehicleWrapped/StepWithVehicleWrapped'
import { useAddReceptionContext } from '../../../../contexts/AddReceptionContext'
import ServicesProductsStep from './ServicesProductsStep/ServicesProductsStep'
import { fetchLastFiveWorkOrders } from '../../../../api/newReceptionService'
import LastWorkOrdersStep from './LastWorkOrders/LastWorkOrders'
import AddVehicleForm from './AddVehicleForm.js/AddVehicleForm'
import { getProducts } from '../../../../actions/ProductActions'
import { getProcesses } from '../../../../actions/ProcessAction'
import { NewReceptionContainer } from './AddReception.style'
import AddClientForm from './AddClientForm/AddClientForm'
import AddContactForm from './AddContactForm/AddContactForm'
import OdometerStep from './OdometerStep/OdometerStep'
import GasLevelStep from './GasLevelStep/GasLevelStep'
import ClientStep from './ClientStep/ClientStep'
import PhotoStep from './PhotoStep/PhotoStep'
import VehicleStep from './VehicleStep/VehicleStep'
import {
  addReceptionActions,
  receptionTotalSteps,
} from '../../../../reducers/AddReceptionReducer'
import {
  createReceptionSteps,
  createReceptionSubSteps,
} from '../../../../Constants'
import PackageStep from './PackageStep/PackageStep'
import ObservationsStep from './ObservationsStep/ObservationsStep'
import ResumeStep from './ResumeStep/ResumeStep'

const AddReception = ({ getAllProcesses, getAllProducts, history }) => {
  const { state, dispatch } = useAddReceptionContext()
  const { step, subStep , isEdit} = state

  const completionPercentage = Math.ceil(
    ((step - 1) / (receptionTotalSteps - 1)) * 100
  )

  const getWorkOrders = async () => {
    try {
      if (state.selectedVehicle) {
        const response = await fetchLastFiveWorkOrders(
          state?.selectedVehicle?.licensePlate
        )
        dispatch({
          type: addReceptionActions.SET_LAST_WORK_ORDERS,
          payload: response.data || [],
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleNext = () => {
    dispatch({ type: addReceptionActions.SET_NEXT_STEP })
  }

  const handlePrevious = () => {
    dispatch({ type: addReceptionActions.SET_PREVIOUS_STEP })
  }

  useEffect(() => {
    getAllProcesses()
    getAllProducts()
  }, [])

  const renderStep = () => {
    if (subStep) {
      switch (subStep) {
        case createReceptionSubSteps.ADD_VEHICLE:
          return <AddVehicleForm />
        case createReceptionSubSteps.ADD_CLIENT:
          return <StepWithVehicleWrapped component={<AddClientForm />} />
        case createReceptionSubSteps.ADD_CONTACT:
          return <StepWithVehicleWrapped component={<AddContactForm />} />
        default:
          return <></>
      }
    }

    switch (step) {
      case createReceptionSteps.VEHICLE:
        return <VehicleStep handleNext={handleNext} />
      case createReceptionSteps.CLIENT:
        return (
          <StepWithVehicleWrapped
            component={
              <ClientStep
                handleNext={handleNext}
                handlePrevious={handlePrevious}
              />
            }
          />
        )

      case createReceptionSteps.PHOTOS:
        return (
          <StepWithVehicleWrapped
            component={
              <PhotoStep
                handleNext={handleNext}
                handlePrevious={handlePrevious}
              />
            }
          />
        )
      case createReceptionSteps.VEHICLE_GAS_STATE:
        return (
          <StepWithVehicleWrapped
            component={
              <GasLevelStep
                handleNext={handleNext}
                handlePrevious={handlePrevious}
              />
            }
          />
        )
      case createReceptionSteps.VEHICLE_ODOMETER_STATE:
        return (
          <StepWithVehicleWrapped
            component={
              <OdometerStep
                handleNext={handleNext}
                handlePrevious={handlePrevious}
              />
            }
          />
        )
      case createReceptionSteps.LAST_WORK_ORDERS:
        return (
          <StepWithVehicleWrapped
            component={
              <LastWorkOrdersStep
                handleNext={handleNext}
                handlePrevious={handlePrevious}
              />
            }
          />
        )
      case createReceptionSteps.PACKAGES:
        return (
          <StepWithVehicleWrapped
            component={
              <PackageStep
                handleNext={handleNext}
                handlePrevious={handlePrevious}
              />
            }
          />
        )
      case createReceptionSteps.SERVICES_PRODUCTS:
        return (
          <ServicesProductsStep
            handleNext={handleNext}
            handlePrevious={handlePrevious}
          />
        )
      case createReceptionSteps.OBSERVATIONS:
        return (
          <StepWithVehicleWrapped
            component={
              <ObservationsStep
                handleNext={handleNext}
                handlePrevious={handlePrevious}
              />
            }
          />
        )
      case createReceptionSteps.RECEPTION_SUMMARY:
        return <ResumeStep handlePrevious={handlePrevious} history={history} />
      default:
        return <></>
    }
  }

  const getSubStepName = () => {
    switch (subStep) {
      case createReceptionSubSteps.ADD_VEHICLE:
        return 'Nuevo vehículo'
      case createReceptionSubSteps.ADD_CLIENT:
        return 'Nuevo cliente'
      case createReceptionSubSteps.ADD_CONTACT:
        return 'Nuevo contacto'
      default:
        return <></>
    }
  }

  useEffect(() => {
    if (
      state.step === createReceptionSteps.CLIENT &&
      state.lastWorkOrders === null
    ) {
      getWorkOrders()
    }
  }, [state])

  return (
    <NewReceptionContainer>
      <div className="new-reception">
        <header className="new-reception__title">
          <Tooltip title="Regresar a recepciones">
            <Link to="/receptions">
              <button type="button">
                <i className="fas fa-chevron-left" />
              </button>
            </Link>
          </Tooltip>
          <h1>{ isEdit? 'Editar recepción' : 'Nueva recepción'} {subStep ? `/ ${getSubStepName()}` : ''}</h1>
        </header>
        <div className="new-reception__progress">
          <Progress percent={completionPercentage} strokeColor="#65D951" />
        </div>
        {renderStep()}
      </div>
    </NewReceptionContainer>
  )
}

const mapDispatchToProps = (dispatch) => ({
  getAllProducts() {
    return dispatch(getProducts())
  },
  getAllProcesses() {
    return dispatch(getProcesses())
  },
})

AddReception.propTypes = {
  getAllProducts: PropTypes.func.isRequired,
  getAllProcesses: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default connect(null, mapDispatchToProps)(withRouter(AddReception))

