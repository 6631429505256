import _ from 'lodash'
import {
  GET_BUDGETS,
  ADD_BUDGET,
  UPDATE_BUDGET,
  DELETE_BUDGET,
} from '../actionTypes/BudgetTypes'

export default (
  state = {
    budgets: {},
  },
  action
) => {
  switch (action.type) {
    case GET_BUDGETS: {
      return {
        ...state,
        budgets: action.payload,
      }
    }
    case ADD_BUDGET: {
      return {
        ...state,
        budgets: {
          ...state.budgets,
          [action.payload.budget_id]: action.payload,
        },
      }
    }
    case UPDATE_BUDGET: {
      return {
        ...state,
        budgets: {
          ...state.budgets,
          [action.payload.budget_id]: action.payload,
        },
      }
    }
    case DELETE_BUDGET: {
      const newState = _.cloneDeep(state)
      delete newState.budgets[action.payload.budget_id]
      return newState
    }

    default: {
      return state
    }
  }
}
