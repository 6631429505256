import React, { Component } from 'react'
import { Grid, Row, Col, Panel, Button } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import _ from 'lodash'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory from 'react-bootstrap-table2-filter'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { MenuOutlined, PlusCircleFilled } from '@ant-design/icons';
import {
  Container,
  ContainerHeader,
  BodyContent,
  showConfirmDialog,
  PanelHeader,
} from '../../Common'
import getColumns from './columns'
import ReusableModal from '../../Common/ReusableModal'
import {
  getCategories as getAllCategories,
  addCategory as createCategory,
  updateCategory as modifyCategory,
  deleteCategory as removeCategory,
} from '../../../../actions/CategoryAction'
import { AppLoader } from '../../Common/AppLoader'

const SCREEN_ID = 5

class CategoryContainer extends Component {
  state = {
    loading: true,
    selectedCategory: {},
    showModal: false,
  }

  componentDidMount() {
    const { getCategories } = this.props
    getCategories()
      .then(() => {
        this.setState({ loading: false })
      })
      .catch(() => {
        this.setState({ loading: false })
      })
  }

  toggleModal = () => {
    const { showModal } = this.state
    this.setState({ showModal: !showModal })
  }

  updateCategory = (category) => {
    const { showModal } = this.state
    this.setState({
      selectedCategory: category,
      showModal: !showModal,
    })
  }

  addCategory = () => {
    const { showModal } = this.state
    this.setState({
      showModal: !showModal,
      selectedCategory: {},
    })
  }

  deleteCategory = (category) => {
    const { deleteCategory } = this.props
    showConfirmDialog(
      '¿Eliminar?',
      `¿Eliminar la categoría: ${category.category_name}`,
      deleteCategory,
      category
    )
  }

  render() {
    const { loading, selectedCategory, showModal } = this.state
    const { permissions, categories, addCategory, updateCategory } = this.props
    const { can_write, can_edit, can_delete } = permissions[SCREEN_ID]
    const pagePermissions = {
      can_write,
      can_edit,
      can_delete,
    }
    return (
      <Container>
        <ContainerHeader title="Categorías" />
        <BodyContent>
          <Grid fluid>
            <Row>
              <Col sm={12}>
                <Panel>
                  <Panel.Heading bsClass="panel-heading">
                    <Panel.Title>
                      <PanelHeader>
                        <div>
                          <p id="title">
                            <MenuOutlined style={{ marginRight: '7px' }} /> Administrar Categorías
                          </p>
                        </div>
                        <div>
                          {!!can_write && (
                            <Button
                              onClick={this.addCategory}
                              bsStyle="success"
                              className="btn-add btn-antd-icon"
                            >
                              <PlusCircleFilled />
                              Agregar registro
                            </Button>
                          )}
                        </div>
                      </PanelHeader>
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    {loading ? (
                      <AppLoader />
                    ) : (
                      <Row>
                        <Col md={10} />
                        <Col md={12}>
                          <BootstrapTable
                            noDataIndication="Sin datos para mostrar"
                            keyField="category_id"
                            data={_.values(categories)}
                            columns={getColumns(
                              this.updateCategory,
                              this.deleteCategory,
                              { can_edit, can_delete }
                            )}
                            filter={filterFactory()}
                            pagination={paginationFactory()}
                          />
                        </Col>
                        <ReusableModal
                          editMode={!_.isEmpty(selectedCategory)}
                          selectedObject={selectedCategory}
                          fieldName="category"
                          showModal={showModal}
                          fieldNameTitle="Categoría"
                          toggleModal={this.toggleModal}
                          addObject={addCategory}
                          updateObject={updateCategory}
                          confirmUpdate={{
                            title: '¿Modificar?',
                            message: `¿Modificar la categoría ${selectedCategory.category_name}?`,
                          }}
                          pagePermissions={pagePermissions}
                        />
                      </Row>
                    )}
                  </Panel.Body>
                </Panel>
              </Col>
            </Row>
          </Grid>
        </BodyContent>
      </Container>
    )
  }
}
const mapStateToProps = (store) => ({
  categories: store.CategoryReducer.categories,
  permissions: store.AppReducer.permissions,
})

const mapDispatchToProps = (dispatch) => ({
  getCategories() {
    return dispatch(getAllCategories())
  },
  addCategory(category) {
    return dispatch(createCategory(category))
  },
  updateCategory(category) {
    return dispatch(modifyCategory(category))
  },
  deleteCategory(id) {
    return dispatch(removeCategory(id))
  },
})

CategoryContainer.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  permissions: PropTypes.objectOf(PropTypes.object).isRequired,
  getCategories: PropTypes.func.isRequired,
  addCategory: PropTypes.func.isRequired,
  updateCategory: PropTypes.func.isRequired,
  deleteCategory: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryContainer)
