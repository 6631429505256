import axios from 'axios'

export const getPackagesList = async () => {
  try {
    const response = await axios.get(`api/packages/reception/all`)
    return response.data.data
  } catch (error) {
    throw ('Error cargando lista de paquetes', error)
  }
}

export const getPackageDetail = async (packageId) => {
  try {
    const response = await axios.get(
      `api/packages/reception/detail/${packageId}`
    )
    return response.data.data
  } catch (error) {
    throw ('Error cargando detalles de paquete', error)
  }
}

export const updatePackageProcess = async (packageId, process) => {
  try {
    const response = await axios.patch(
      `/api/packages/reception/detail/${packageId}`,
      process
    )
    return response.data.data
  } catch (error) {
    throw ('Error actualizando el proceso', error)
  }
}
