import React from 'react'
import { FormGroup, ControlLabel, Col, Row } from 'react-bootstrap'
import _ from 'lodash'
import PropTypes from 'prop-types'
import Select from 'react-select'
import WorkOrderDetails from './WorkOrderDetails/WorkOrderDetails'
import { getValidationState } from '../Common'

const addMechanicsName = (mechanics) =>
  _.map(mechanics, (mechanic) => {
    const name = `${mechanic.employee_name} ${mechanic.employee_lastname}`
    return {
      ...mechanic,
      name,
    }
  })

const WorkOrderForm = ({
  packages,
  mechanics,
  state,
  handleChangeSelect,
  products,
  handleChangeDetail,
  deleteWorkOrderDetail,
  addWorkOrderDetail,
  handleChangeSelectDetail,
  processes,
  editMode,
  handleChange,
}) => {
  const {
    package_id,
    mechanic_id,
    workOrderDetails,
    packageDetails,
    package_total,
    work_order_package_discount,
  } = state

  return (
    <div>
      <Row>
        <Col xs={12}>
          <FormGroup validationState={getValidationState('brand', true)}>
            <ControlLabel>Paquete: </ControlLabel>
            <Select
              placeholder="Paquete"
              options={_.values(packages)}
              labelKey="package_name"
              valueKey="package_id"
              value={package_id}
              onChange={(value) => {
                handleChangeSelect(value, 'package')
              }}
              disabled
            />
          </FormGroup>
        </Col>

        <Col xs={12}>
          <FormGroup validationState={getValidationState(mechanic_id, true)}>
            <ControlLabel>Mecánico: </ControlLabel>
            <Select
              placeholder="Mecánico"
              options={addMechanicsName(mechanics)}
              labelKey="name"
              value={mechanic_id}
              valueKey="employee_id"
              onChange={(value) => {
                handleChangeSelect(value, 'mechanic')
              }}
            />
          </FormGroup>
        </Col>
      </Row>

      <div>
        <WorkOrderDetails
          products={products}
          packageTotal={package_total}
          processes={processes}
          services={packages}
          workOrderDetails={workOrderDetails}
          work_order_package_discount={work_order_package_discount}
          packageDetails={_.keyBy(packageDetails, 'process_id')}
          handleChange={handleChange}
          handleChangeDetail={handleChangeDetail}
          deleteWorkOrderDetail={deleteWorkOrderDetail}
          handleChangeSelectDetail={handleChangeSelectDetail}
          addWorkOrderDetail={addWorkOrderDetail}
          editMode={editMode}
        />
      </div>
    </div>
  )
}

WorkOrderForm.propTypes = {
  packages: PropTypes.objectOf(PropTypes.object).isRequired,
  state: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChangeSelect: PropTypes.func.isRequired,
  products: PropTypes.objectOf(PropTypes.object).isRequired,
  processes: PropTypes.objectOf(PropTypes.object).isRequired,
  handleChangeDetail: PropTypes.func.isRequired,
  deleteWorkOrderDetail: PropTypes.func.isRequired,
  addWorkOrderDetail: PropTypes.func.isRequired,
  handleChangeSelectDetail: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  mechanics: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default WorkOrderForm
