import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import _ from 'lodash'
import styled from 'styled-components'
import StarRatingComponent from 'react-star-rating-component'
import { randomRGBString } from '../ChartComponents/ChartUtilComponents'
import { roundNumberTwoDecimals } from '../../../Helpers'

import LineChartComponent from '../ChartComponents/LineChartComponent'


const WorkshopHistoryLastMonthsSectionComponent = ({ title, data, active, infoUser, workOrderScore, goToHistory }) => {
  const titleModified = `${title}`

  const { invoiceMonthly = [] } = data
  const { invoiceDaily = [] } = data
  const { lastMonthsMechanics = [] } = data
  const { lastMonthsWorkshop = [] } = data

  const monthLabels = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ]

  const totalSixMonthsInvoicing = invoiceMonthly.reduce(
    (total, month) => total + roundNumberTwoDecimals(month.invoiced),
    0
  )

  const datasetInvoiceMontly = {
    datasets: [
      {
        data: invoiceMonthly.map((m) => m.invoiced),
        borderColor: 'rgb(251, 102, 103, 1)',
        borderWidth: 1.5,
        fill: false,
      },
    ],
    labels: invoiceMonthly.map((m) => monthLabels[m.month - 1]),
  }

  const datasetInvoiceDaily = {
    datasets: [
      {
        data: invoiceDaily.map((m) => m.invoiced),
        borderColor: 'rgb(251, 102, 103, 1)',
        borderWidth: 1.5,
        fill: false,
      },
    ],
    labels: invoiceDaily.map((m) => `${m.day}`),
  }

  const datasetMechanicScores = {
    datasets: lastMonthsMechanics.map((m) => ({
      label: m.mechanic_name,
      data: m.scores.map((s) => s.score),
      borderColor: randomRGBString(),
      borderWidth: 1.5,
      fill: false,
    })),
    labels:
      lastMonthsMechanics.length > 0
        ? lastMonthsMechanics[0].scores.map((m) => monthLabels[m.month - 1])
        : [],
  }

  const datasetWorkshopScores = {
    datasets: [
      {
        data: lastMonthsWorkshop.map((m) => m.score),
        borderColor: 'rgb(251, 102, 103, 1)',
        borderWidth: 1.5,
        fill: false,
      },
    ],
    labels: lastMonthsWorkshop.map((m) => monthLabels[m.month - 1]),
  }
  if(active){
  return (
    <div className="dashboard-section-container margin-top">
      <h1 className="dashboard-section-title">{titleModified}</h1>
      <Row className="section-stats-body">
        <Col lg={6} md={6} sm={12} className="section-today-graph">
          <h3 className="dashboard-section-subtitle">Facturación Mes A Mes</h3>
          <h3 className="dashboard-section-subtitle month-total">
            Seis meses - ${' '}
            {roundNumberTwoDecimals(totalSixMonthsInvoicing) || 0}
          </h3>
          <LineChartComponent
            datasets={datasetInvoiceMontly.datasets}
            labels={datasetInvoiceMontly.labels}
            scale
          />
        </Col>
        <Col lg={6} md={6} sm={12} className="section-today-graph">
          <h3 className="dashboard-section-subtitle invoice">
            Facturación Diaria En Este Mes
          </h3>
          <h3 className="dashboard-section-subtitle month-total">
            {monthLabels[invoiceMonthly[5].month - 1]} - ${' '}
            {roundNumberTwoDecimals(invoiceMonthly[5].invoiced) || 0}
          </h3>
          <LineChartComponent
            datasets={datasetInvoiceDaily.datasets}
            labels={datasetInvoiceDaily.labels}
            scale
          />
        </Col>
      </Row>
      <Row className="section-stats-body margin-top">
        <Col lg={6} md={6} sm={12} className="section-today-graph">
          <h3 className="dashboard-section-subtitle">
            Calificación Mensual De Mecánicos
          </h3>
          <LineChartComponent
            datasets={datasetMechanicScores.datasets}
            labels={datasetMechanicScores.labels}
            scale
            legend
          />
        </Col>
        <Col lg={6} md={6} sm={12} className="section-today-graph">
          <h3 className="dashboard-section-subtitle invoice">
            Calificación Mensual Del Taller
          </h3>
          <LineChartComponent
            datasets={datasetWorkshopScores.datasets}
            labels={datasetWorkshopScores.labels}
            scale
          />
        </Col>
      </Row>
    </div>
  )}
  
  const getOnlyMechanicScore = _.filter(workOrderScore, (e)=>{
    return e.mechanic_id === infoUser.employee_id && e.work_order_grade !== null
  })

   
  return (
  <div className="dashboard-section-container margin-top">
    <h1 className="dashboard-section-title">{titleModified}</h1>
    <Row className="section-stats-body"> 
      {getOnlyMechanicScore[0] ? 
      <Col md={6} sm={12} className="mb-75px">
        <WorkOrderCardRating>
          <h3>
            {`Orden ${getOnlyMechanicScore[0].budgets.reception.vehicle.vehicle_license_plate} ${getOnlyMechanicScore[0].budgets.reception.vehicle.model.model_name} ${getOnlyMechanicScore[0].budgets.reception.vehicle.vehicle_year}`}
          </h3>
          <span>
            {getOnlyMechanicScore[0].work_order_grade_comment ? getOnlyMechanicScore[0].work_order_grade_comment : 'No se realizado un comentario'}
          </span>  
          <StarRatingComponent
            name="rate"
            editing={false}
            renderStarIcon={() => <span className="fa fa-star green" />}
            renderStarIconHalf={() => (
              <span>
                <span style={{ position: 'absolute' }}>
                  <i className="far fa-star" />
                </span>
                <span>
                  <i className="fas fa-star-half" />
                </span>
              </span>
            )}
            starCount={5}
            value={getOnlyMechanicScore[0].work_order_grade}
            />
        </WorkOrderCardRating>
      </Col>
      :
      <Col sm={12} className="mb-75px"> 
      <h3>No hay ultimas reseñas</h3>
      </Col> }

      {getOnlyMechanicScore[1] ? 
      <Col md={6} sm={12} className="mb-75px">
        <WorkOrderCardRating>
          <h3>
            {`Orden ${getOnlyMechanicScore[1].budgets.reception.vehicle.vehicle_license_plate} ${getOnlyMechanicScore[1].budgets.reception.vehicle.model.model_name} ${getOnlyMechanicScore[1].budgets.reception.vehicle.vehicle_year}`}
          </h3>
          <span>
            {getOnlyMechanicScore[1].work_order_grade_comment ? getOnlyMechanicScore[1].work_order_grade_comment : 'No se realizado un comentario'}
          </span>  
          <StarRatingComponent
            name="rate"
            editing={false}
            renderStarIcon={() => <span className="fa fa-star green" />}
            renderStarIconHalf={() => (
              <span>
                <span style={{ position: 'absolute' }}>
                  <i className="far fa-star" />
                </span>
                <span>
                  <i className="fas fa-star-half" />
                </span>
              </span>
            )}
            starCount={5}
            value={getOnlyMechanicScore[1].work_order_grade}
            />
        </WorkOrderCardRating>
      </Col>
      : '' }

      <Col sm={12} className="mb-75px">
       <RatingButton 
       onClick={()=>{
         goToHistory()
       }}>
         Ver Historial
       </RatingButton>
      </Col>

    </Row>
  </div>
    )
}

const WorkOrderCardRating = styled.div`
  text-align: center;

  h3{
    font-size: 2.5rem;
  }

  span{
    font-size: 1.5rem;
    margin-top: 20px;
    display: block;
  }
  .green{
    color: rgb(61,163,104);
    font-size: 2.5rem;
  }
`

const RatingButton = styled.div`
  align-items: center;
  border-radius: 2px;
  background-color: #071A29;
  border: none;
  outline: none;
  color: #fff;
  font-weight: 700;
  padding: 1rem 1.7rem;
  width: 200px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  }`


WorkshopHistoryLastMonthsSectionComponent.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.objectOf(PropTypes.array).isRequired,
  infoUser: PropTypes.objectOf(PropTypes.any).isRequired,
  workOrderScore: PropTypes.objectOf(PropTypes.array).isRequired,
  active: PropTypes.number.isRequired,
  goToHistory: PropTypes.func.isRequired,
}

export default WorkshopHistoryLastMonthsSectionComponent
