import React, { Component } from 'react'
import {
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Col,
  Button,
  Row,
  Form,
} from 'react-bootstrap'
import PropTypes from 'prop-types'
import { SaveOutlined } from '@ant-design/icons'
import { showConfirmDialog } from '../../../Common'

class SerieModal extends Component {
  state = {
    invoice_type_name: '',
    invoice_type_code: null,
    loading: false,
  }

  componentDidUpdate(prevProps) {
    const { selectedInvoiceType } = this.props
    const { selectedInvoiceType: prevSelectedInvoiceType } = prevProps

    if (selectedInvoiceType !== prevSelectedInvoiceType) {
      const { length } = Object.keys(selectedInvoiceType)
      const id = selectedInvoiceType.invoice_type_id
      if (
        length === 0 ||
        id === undefined ||
        id === null ||
        selectedInvoiceType === undefined ||
        selectedInvoiceType === null
      ) {
        this.clearComponent()
      } else if (length > 0 && id) {
        this.setInvoiceTypeModalState()
      }
    }
  }

  setInvoiceTypeModalState() {
    const { selectedInvoiceType } = this.props
    const { invoice_type_name, invoice_type_code } = selectedInvoiceType
    this.setState({
      invoice_type_name,
      invoice_type_code,
      loading: false,
    })
  }

  clearComponent = () => {
    this.setState({
      invoice_type_name: '',
      invoice_type_code: null,
      loading: false,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const {
      selectedInvoiceType,
      toggleModal,
      updateInvoiceType,
      addInvoiceTypes,
      editMode,
    } = this.props
    const { invoice_type_name, invoice_type_code } = this.state

    const invoiceType = {
      invoice_type_id: selectedInvoiceType.invoice_type_id || null,
      invoice_type_code,
      invoice_type_name,
    }

    if (editMode) {
      toggleModal()
      showConfirmDialog(
        '¿Modificar?',
        `¿Modificar el tipo de factura: ${invoiceType.invoice_type_name}`,
        updateInvoiceType,
        invoiceType,
        () => { },
        toggleModal,
        toggleModal
      )
    } else {
      addInvoiceTypes(invoiceType)
        .then(() => {
          toggleModal()
          this.cleanComponent()
        })
        .catch(() => {
          this.setState({ loading: false })
          toggleModal()
        })
    }
  }

  handleChange = (e) => {
    const { name } = e.target
    const { value } = e.target
    this.setState({
      [name]: value,
    })
  }

  render() {
    const { editMode, modalOpen, toggleModal, pagePermissions } = this.props
    const { invoice_type_name, loading, invoice_type_code } = this.state
    let title = editMode ? 'Modificar' : 'Agregar'
    if (title === 'Modificar')
      title = pagePermissions.can_edit ? 'Modificar' : ''
    return (
      <Modal show={modalOpen} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>{title} tipo de factura</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.handleSubmit}>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <ControlLabel>Nombre: </ControlLabel>
                  <FormControl
                    name="invoice_type_name"
                    type="text"
                    value={invoice_type_name}
                    onChange={this.handleChange}
                    required
                    placeholder="Ingresar"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <ControlLabel>Código: </ControlLabel>
                  <FormControl
                    name="invoice_type_code"
                    type="number"
                    value={invoice_type_code}
                    onChange={this.handleChange}
                    required
                    placeholder="#"
                  />
                </FormGroup>
              </Col>
            </Row>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button type="submit" hidden id="submitModelForm" />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={toggleModal}>Cerrar</Button>
          {!!pagePermissions.can_edit && (
            <Button
              disabled={loading}
              bsStyle="primary"
              onClick={() => {
                document.getElementById('submitModelForm').click()
              }}
              className="btn-antd-icon"
            >
              <SaveOutlined />
              {editMode ? ' Modificar' : ' Agregar'}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    )
  }
}

SerieModal.propTypes = {
  editMode: PropTypes.bool.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  addInvoiceTypes: PropTypes.func.isRequired,
  updateInvoiceType: PropTypes.func.isRequired,
  selectedInvoiceType: PropTypes.objectOf(PropTypes.any).isRequired,
  pagePermissions: PropTypes.objectOf(PropTypes.object).isRequired,
}

export default SerieModal
