import _ from 'lodash'

import {
  GET_PERSONS_WORKSHOP,
  ADD_PERSON_WORKSHOP,
  UPDATE_PERSON_WORKSHOP,
  DELETE_PERSON_WORKSHOP,
} from '../actionTypes/PersonWorkshopTypes'

export default (
  state = {
    persons: {},
  },
  action
) => {
  switch (action.type) {
    case GET_PERSONS_WORKSHOP: {
      return {
        ...state,
        persons: action.payload,
      }
    }
    case ADD_PERSON_WORKSHOP: {
      return {
        ...state,
        persons: {
          ...state.persons,
          [action.payload.person_id]: action.payload,
        },
      }
    }
    case UPDATE_PERSON_WORKSHOP: {
      return {
        ...state,
        persons: {
          ...state.persons,
          [action.payload.person_id]: {
            ...state.persons[action.payload.person_id],
            person: action.payload,
          },
        },
      }
    }
    case DELETE_PERSON_WORKSHOP: {
      const newState = _.cloneDeep(state)
      delete newState.persons[action.payload.person_id]
      return newState
    }
    default: {
      return state
    }
  }
}
