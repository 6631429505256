import React from 'react'
import PropTypes from 'prop-types'
import styles from './Chat.module.css'

function ChatHeader({ receiverName }) {
    const avatarLetters = receiverName?.substring(0, 2).toUpperCase()

    return (
        <div className={styles['chat-header']}>
            <div className={`${styles['no-avatar']}`}>{avatarLetters}</div>
            <div className={styles['receiver-details']}>
                <h6 className={styles['receiver-name']}>{receiverName}</h6>
                {/*  <span>Última vez conectado a las 1:53PM</span> */}
            </div>
        </div>
    )
}

ChatHeader.propTypes = {
    receiverName: PropTypes.string.isRequired
}

export default ChatHeader
