import _ from 'lodash'

import {
  GET_PRODUCTS,
  ADD_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
  MODIFY_STOCK_PRODUCT,
  PRODUCTS_LOADING
} from '../actionTypes/ProductTypes'

export default (
  state = {
    products: {},
    productsLoading: false
  },
  action
) => {
  switch (action.type) {
    case GET_PRODUCTS: {
      return {
        ...state,
        products: action.payload,
      }
    }
    case ADD_PRODUCT: {
      return {
        ...state,
        products: {
          ...state.products,
          [action.payload.product_id]: action.payload,
        },
      }
    }
    case UPDATE_PRODUCT: {
      return {
        ...state,
        products: {
          ...state.products,
          [action.payload.product_id]: action.payload,
        },
      }
    }
    case DELETE_PRODUCT: {
      const newState = _.cloneDeep(state)
      delete newState.products[action.payload.product_id]
      return newState
    }
    case MODIFY_STOCK_PRODUCT: {
      return {
        ...state,
        products: {
          ...state.products,
          [action.payload.product_id]: action.payload,
        },
      }
    }
    case PRODUCTS_LOADING: {
      return {
        ...state,
        productsLoading: action.payload,
      }
    }

    default: {
      return state
    }
  }
}
