import styled from 'styled-components'

export const OdometerInputWrapper = styled.div`
  width: 100%;
  padding: 10px 0;

  display: flex;
  justify-content: center;
  align-items: end;
  gap: 1.5rem;
`
