import styled, { keyframes } from 'styled-components'

export const Body = styled.div`
  margin: 0px auto;
  width: 90%;
`

export const CardsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`
export const CardTitle = styled.div`
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  background-color: #f1f1f1;
  padding: 10px;
  margin: 0px;
`
export const CardBody = styled.div`
  display: flex;
  justify-content: space-around;

  height: 100%;
  width: 100%;
  margin-top: ${({ marginTop }) => `${marginTop || 0}px`};
`

export const CardImage = styled.div`
  width: 40%;
  height: 80%;
  font-size: 75px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const CardData = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Card = styled.div`
  background-color: white;
  box-shadow: 5px 5px 5px #ccc;
  min-height: 200px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid #cccccc;
  margin: 20px;
  &:hover {
    transform: scale(1);

    transition: ease-out 0.5s;
  }
`

export const ChartContainer = styled.div`
  width: 500px;
  background-color: white;
  box-shadow: 5px 5px 5px #ccc;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid #cccccc;
  margin: 20px;
  &:hover {
    transform: scale(1);
    transition: ease-out 0.5s;
  }
`

export const ChartsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-bottom: 20px;
`
const blinking = keyframes`
  0% {opacity: 1;}
  50% {opacity: 0;}
  100% {opacity: 1;}
`
export const ChartPreloader = styled.div`
  font-size: 9em;
  color: lightgray;
  text-align: center;
  animation: ${blinking} 1s infinite;
`

// https://stackoverflow.com/questions/23095637/how-do-you-get-random-rgb-in-javascript
const randomRGBNumber = (min = 0, max = 255) =>
  min + Math.floor(Math.random() * (max - min + 1))

export const randomRGBString = () => {
  const r = randomRGBNumber()
  const g = randomRGBNumber()
  const b = randomRGBNumber()
  return `rgba(${r},${g},${b}, 1)`
}
