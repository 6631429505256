import _ from 'lodash'

import {
  GET_PACKAGES,
  ADD_PACKAGE,
  UPDATE_PACKAGE,
  DELETE_PACKAGE,
  SET_PACKAGES,
} from '../actionTypes/PackageTypes'

export default (
  state = {
    packages: {},
    allPackages: [],
  },
  action
) => {
  switch (action.type) {
    case GET_PACKAGES: {
      return {
        ...state,
        packages: action.payload,
      }
    }
    case SET_PACKAGES: {
      return {
        ...state,
        allPackages: action.payload,
      }
    }
    case ADD_PACKAGE: {
      return {
        ...state,
        packages: {
          ...state.packages,
          [action.payload.package_id]: action.payload,
        },
      }
    }
    case UPDATE_PACKAGE: {
      return {
        ...state,
        packages: {
          ...state.packages,
          [action.payload.package_id]: action.payload,
        },
      }
    }
    case DELETE_PACKAGE: {
      const newState = _.cloneDeep(state)
      delete newState.packages[action.payload.package_id]
      return newState
    }

    default: {
      return state
    }
  }
}
