import PropTypes from 'prop-types'

const AddSomethigModalTypes = {
  isOpen: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  products: PropTypes.any.isRequired,
  processes: PropTypes.arrayOf(PropTypes.any).isRequired,
  workOrderId: PropTypes.number.isRequired,
  setTab: PropTypes.func.isRequired,
  scrollToChat: PropTypes.func.isRequired,
  receptionId: PropTypes.number.isRequired,
  workshopId: PropTypes.number.isRequired,
}

export default AddSomethigModalTypes
