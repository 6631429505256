import styled from 'styled-components'

export const ProcessFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 2rem;

  padding: 1rem 0;
`
