/* eslint-disable import/no-extraneous-dependencies */
import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Image } from 'antd'
import 'react-image-gallery/styles/css/image-gallery.css'
import ReactImageGallery from 'react-image-gallery'
import { AppLoader } from '../../Common/AppLoader'
import PhotosWrapper from './WorkOrderPhotos.styles'
import EmptyState from '../../Common/EmptyState/EmptyState'

/* import FloatingButton from './FloatingButton'; */

function WorkOrderPhotos({ photos, loading, newPhotoAdded }) {
  const galleryRef = useRef(null)

  if (loading) {
    return <AppLoader />
  }

  const photosArray = photos.map((photo) => {
    return {
      original: photo.receptionPhotoUrl,
      thumbnail: photo.receptionPhotoUrl,
      thumbnailHeight: 100,
      thumbnailWidth: 150,
    }
  })

  const customRenderItem = (item) => {
    return (
      <div className="image-gallery-image" key={item.original}>
        <Image src={item.original} alt={item.original} />
      </div>
    )
  }

  return (
    <PhotosWrapper>
      {photos.length ? (
        <ReactImageGallery
          ref={galleryRef}
          items={photosArray}
          showPlayButton={false}
          renderItem={customRenderItem}
          showFullscreenButton={false}
          autoPlay
          slideInterval={2600}
          startIndex={newPhotoAdded ? photos.length - 1 : 0}
          onClick={() => galleryRef.current.pause()}
          onSlide={() => galleryRef.current.play()}
          onTouchMove={() => galleryRef.current.pause()}
          onThumbnailClick={() => galleryRef.current.pause()}
        />
      ) : (
        <EmptyState text="Sin fotografías registradas" />
      )}
    </PhotosWrapper>
  )
}

WorkOrderPhotos.propTypes = {
  loading: PropTypes.bool.isRequired,
  photos: PropTypes.array.isRequired,
  newPhotoAdded: PropTypes.bool,
}
WorkOrderPhotos.defaultProps = {
  newPhotoAdded: false,
}

export default WorkOrderPhotos
