import React from 'react'
import { PropTypes } from 'prop-types'

import { InfoDisclaimerContainer } from './InfoDisclaimer.style'

const InfoDisclaimer = ({ message, children }) => {
  return (
    <InfoDisclaimerContainer className="info-disclaimer">
      <i className="fas fa-info-circle" />
      <p>{message || children}</p>
    </InfoDisclaimerContainer>
  )
}

export default InfoDisclaimer

InfoDisclaimer.defaultProps = {
  children: <span />,
  message: ''
}

InfoDisclaimer.propTypes = {
  message: PropTypes.string,
  children: PropTypes.node,
}
