function countryCodeToLetters(code) {
  const countryCodes = {
    503: 'SV', // El Salvador
    502: 'GT', // Guatemala
    57: 'CO', // Colombia
    58: 'VE', // Venezuela
    52: 'MX', // Mexico
    1: 'US', // United States
  }

  return countryCodes[code] 
}

export default countryCodeToLetters