import styled from 'styled-components'

export const ReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  height: min-content;
  border: 2px solid #e3b719;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  padding: 20px 12px;
  border-radius: 10px;

  .no-review {
    color: #737373;
  }
  p {
    margin: unset;
  }

  .rating {
    display: flex;
    align-items: center;
    display: flex;
    gap: 4px;

    p {
      display: flex;
      svg {
        padding-right: 2px;
        color: #e3b719;
        font-size: 18px;
      }
    }
  }
`
export const Title = styled.h3`
  display: flex;
  align-items: center;
  font-size: 1.1em;
  font-weight: 700;
  margin: unset;
  margin-bottom: 10px;

  i {
    color: #1e1e40;
  }
`
