import styled from 'styled-components';

export const TotalTimeWrapper = styled.div`
display:flex;
align-items: center;
gap:8px;
i{
    font-size: 1.6rem;
}
p{
    margin: unset;
}
`