import React from 'react'
import { PropTypes } from 'prop-types'
import SelectedVehicleCard from '../AddReception/SelectedVehicleCard/SelectedVehicleCard'


const StepWithVehicleWrapped = ({ component }) => {
  return (
    <>
      <SelectedVehicleCard />
      {component}
    </>
  )
}

StepWithVehicleWrapped.propTypes = {
  component: PropTypes.any.isRequired,
}
export default StepWithVehicleWrapped
