import React from 'react'
import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'
import { Controller, useFormContext } from 'react-hook-form'
import {
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel,
} from '@material-ui/core'
import FormControlWrapper from '../TextFormControl/TextFormControl.styles'

const PriceInputControl = ({
  label,
  error,
  name,
  placeholder,
  required,
  requiredMessage,
  pattern,
  minValue,
  patternMessage,
  workshopCountryCode,
}) => {
  const { control, watch } = useFormContext()
  const WorkShopCountryCode = parseInt(
    workshopCountryCode || localStorage.getItem('workshopCountryCode'),
    10
  )
  const fielValue = watch(name)
  return (
    <FormControlWrapper className='price-wrapper'>
      <FormControl variant="standard" error={!!error}>
        <InputLabel
          htmlFor={name}
          className={`label-colored ${!fielValue && 'label-no-value'} ${
            error && 'label-error'
          }`}
        >
          {label}
          {required && !fielValue?.trim() ? '*' : ''}
        </InputLabel>
        <Controller
          name={name}
          defaultValue=""
          control={control}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Input
              id={name}
              placeholder={placeholder}
              inputProps={{
                inputMode: 'numeric',
                numeric: true,
                digitOnly: true,
              }}
              startAdornment={
                <InputAdornment position="start">
                  {WorkShopCountryCode === 57 ? 'COPS' : '$'}
                </InputAdornment>
              }
              type="number"
              aria-describedby={`${name}-text`}
              className="input-colored"
              onChange={onChange}
              onBlur={onBlur}
              inputRef={ref}
              value={value || fielValue}
            />
          )}
          rules={{
            required: {
              value: required,
              message: requiredMessage,
            },
            pattern: {
              value: pattern,
              message: patternMessage,
            },
            min: {
              value: minValue,
              message: `El valor debe ser mayor a ${minValue}`,
            },
          }}
        />
        {!!error && (
          <FormHelperText className="error-message" id={`${name}-text`}>
            {error}
          </FormHelperText>
        )}
      </FormControl>
    </FormControlWrapper>
  )
}
PriceInputControl.defaultProps = {
  required: false,
  requiredMessage: '',
  placeholder: '',
  minValue: 1,
  pattern: undefined,
  patternMessage: '',
  workshopCountryCode: '',
  error:'',
}

PriceInputControl.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  pattern: PropTypes.any,
  placeholder: PropTypes.string,
  minValue: PropTypes.number,
  requiredMessage: PropTypes.string,
  patternMessage: PropTypes.string,
  workshopCountryCode: PropTypes.any,
}

const mapStateToProps = (store) => ({
  categories: store.CategoryReducer.categories,
  workshopCountryCode: store.AppReducer.workshopCountryCode,
})

export default connect(mapStateToProps)(PriceInputControl)
