/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Button } from 'antd'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import { FormProvider, useForm } from 'react-hook-form'
import { AddProviderFormWrapper } from './AddProviderForm.styles'
import TextFormControl from '../../App/Common/TextFormControl/TextFormControl'
import PhoneFormControl from '../../App/Common/PhoneFormControl/PhoneFormControl'
import { ModalButtonsContainer } from '../../App/Reception/AddReception/VehicleStep/VehicleStep.style'
import TextareaFormControl from '../../App/Common/TextareaFormControl/TextareaFormControl'
import { formatPhoneToDB, getCountryCode } from '../../Utilities/formatPhone'
import { createProvider } from '../../../api/apiProviderService'

const AddProviderForm = ({
  children,
  workshopCountryCode,
  setProvider,
  closeModal,
}) => {
  const WorkShopCountryCode = parseInt(
    workshopCountryCode || localStorage.getItem('workshopCountryCode'),
    10
  )
  const methods = useForm({
    mode: 'onChange',
  })
  const {
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = methods

  const resetForm = () => {
    reset({
      providerName: '',
      providerPhone: null,
      providerAddress: '',
    })
  }

  const onSubmit = async (formData) => {
    const provider = {
      providerName: formData.providerName,
      providerPhone: formatPhoneToDB(formData.providerPhone),
      providerCountryCode: getCountryCode(formData.providerPhone),
      providerAddress: formData.providerAddress,
    }
    const response = await createProvider(provider)
    setProvider({
      value: response.data.providerId,
      label: response.data.providerName,
    })
    closeModal()
    resetForm()
  }
  return (
    <FormProvider {...methods}>
      <AddProviderFormWrapper onSubmit={handleSubmit(onSubmit)}>
        <TextFormControl
          label="Nombre"
          isAutoFocus
          name="providerName"
          error={errors?.productName?.message}
          required
          requiredMessage="Ingresa un nombre para el repuesto"
        />
        <PhoneFormControl
          label="Teléfono"
          name="providerPhone"
          required
          error={errors?.phone?.message}
          requiredMessage="El teléfono es un campo requerido."
          workShopCode={WorkShopCountryCode}
        />
        <TextareaFormControl
          label="Dirección"
          name="providerAddress"
          error={errors?.providerAddress?.message}
          required
          requiredMessage="Ingresa la dirección del proveedor"
        />
        <ModalButtonsContainer style={{ marginTop: '1.5rem' }}>
          {children}
          <Button type="primary" htmlType="submit" disabled={!isValid}>
            Agregar
          </Button>
        </ModalButtonsContainer>
      </AddProviderFormWrapper>
    </FormProvider>
  )
}

AddProviderForm.defaultProps = {
  children: <></>,
  workshopCountryCode: '',
}
AddProviderForm.propTypes = {
  children: PropTypes.node,
  workshopCountryCode: PropTypes.any,
  setProvider: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
}
const mapStateToProps = (store) => ({
  workshopCountryCode: store.AppReducer.workshopCountryCode,
})

export default connect(mapStateToProps)(AddProviderForm)
