import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import StarRatingComponent from 'react-star-rating-component'
import { PlusCircleFilled } from '@ant-design/icons';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import styled from 'styled-components'
import RowTableNoData from './Components/RowTableNoData'
import MetricModal from '../Metrics/MetricModal';
import { BREAK_POINTS_DESKTOP_FIRST } from '../../../global/css/breakPoints';

// eslint-disable-next-line react/prefer-stateless-function
class LastWorkOrdersRatingsSectionComponent extends Component {
  state = {
    showMetricModal: false,
    typeId: 0,
    MetricData: {}
  }

  openMetricModalF = () => {
    this.toggleMetricModal()
    const { lastMechanicBilling } = this.props
    this.setState({
      typeId: 0,
      MetricData: lastMechanicBilling
    })
  }

  openMetricModalEF = () => {
    this.toggleMetricModal()
    const { lastMechanicEfficiency } = this.props
    this.setState({
      typeId: 1,
      MetricData: lastMechanicEfficiency
    })
  }

  openMetricModalEV = () => {
    const { lastMechanicScore } = this.props
    this.toggleMetricModal()
    this.setState({
      typeId: 2,
      MetricData: lastMechanicScore
    })
  }

  getEfficiencyEvaluation = (totalRealTime, totalTime) => {
    if (totalRealTime > 0) {
      if (Math.round((totalTime / totalRealTime) * 100, 2) < 100) {
        return `${Math.round((totalTime / totalRealTime) * 100, 2)}%`
      }
      return "IRREAL"
    }
    return `0%`
  }

  toggleMetricModal = () => {
    const { showMetricModal } = this.state
    this.setState({ showMetricModal: !showMetricModal })
  }


  render() {
    const { title, data, infoUser, lastMechanicScore, lastMechanicBilling, goToMetrics, lastMechanicEfficiency } = this.props
    const { showMetricModal, typeId, MetricData } = this.state

    const titleModified = `${title}`
    if (infoUser.rol_id === 1) {
      return (
        <div className="dashboard-section-container margin-top">
          <h1 className="dashboard-section-title">{titleModified}</h1>
          <Row className="section-last-ratings">
            <table className="dashboard-table">
              <thead>
                <tr>
                  <th>Cliente</th>
                  <th>Placa</th>
                  <th>Calificación</th>
                  <th>Mecánico</th>
                  <th className="comment">Comentario</th>
                </tr>
              </thead>
              <tbody>
                {data.length === 0 && (
                  <RowTableNoData text="¡No hay datos aún! Registra tus órdenes de trabajo y comienza a digitalizar tu taller" />
                )}
                {data.map((rating, _index) => (
                  <tr key={`${rating.person_firstname}-${_index + 1}`}>
                    <td>{rating.person_firstname}</td>
                    <td>{rating.vehicle_license_plate}</td>
                    <td>
                      <StarRatingComponent
                        name="rate"
                        starColor="#3DA368"
                        emptyStarColor="#3DA368"
                        editing={false}
                        renderStarIcon={(index, value) => (
                          <span>
                            <i
                              className={
                                index <= value ? 'fas fa-star' : 'far fa-star'
                              }
                            />
                          </span>
                        )}
                        renderStarIconHalf={() => (
                          <span>
                            <span style={{ position: 'absolute' }}>
                              <i className="far fa-star" />
                            </span>
                            <span>
                              <i className="fas fa-star-half" />
                            </span>
                          </span>
                        )}
                        starCount={5}
                        value={rating.work_order_grade}
                      />
                    </td>
                    <td>{rating.employee_name}</td>
                    <td className="comment">{rating.work_order_grade_comment}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Row>
        </div>
      )
    }
    const score = lastMechanicScore.score ? (lastMechanicScore.score / 5) * 100 : 0
    const scoreLabel = lastMechanicScore.score !== undefined ? `${(lastMechanicScore.score / 5) * 100} %` : `0 %`

    const billing = lastMechanicBilling.inv !== undefined ? lastMechanicBilling.inv : 0
    const billingLabel = lastMechanicBilling.inv !== undefined ? `${lastMechanicBilling.inv} %` : `0 %`

    const efficiency = lastMechanicEfficiency.totalTime !== 0 ? Math.round((lastMechanicEfficiency.totalRealTime / lastMechanicEfficiency.totalTime) * 100, 2) : 0
    const labelEfficiency = () => {
      if (lastMechanicEfficiency.totalTime === 0) {
        return "0%"
      }
      if (Math.round((lastMechanicEfficiency.totalRealTime / lastMechanicEfficiency.totalTime) * 100, 2) <= 125 && Math.round((lastMechanicEfficiency.totalRealTime / lastMechanicEfficiency.totalTime) * 100, 2) !== 0) {
        return `${Math.round((lastMechanicEfficiency.totalRealTime / lastMechanicEfficiency.totalTime) * 100, 2)} %`
      }
      return 'IRREAL'
    }

    return (
      <div className="dashboard-section-container margin-top">
        <h1 className="dashboard-section-title">{titleModified}</h1>
        {billingLabel && lastMechanicEfficiency.totalTime >= 0 && scoreLabel ?
          <Row className="section-last-ratings section-center">

            <Col sm={4}>
              <CircularProgressbarCostum>
                <CircularProgressbarWithChildren
                  value={billing}
                  strokeWidth={7}
                  styles={buildStyles({
                    pathColor: "#219653",
                    trailColor: "rgb(23, 150, 83, 20%)",
                  })}
                >
                  <h3>Facturación</h3>
                  <span>{`${billingLabel}`}</span>
                </CircularProgressbarWithChildren>
                <button type="button" className="btn-antd-icon" onClick={this.openMetricModalF}>
                  <PlusCircleFilled className="facturation--color" />
                </button>
              </CircularProgressbarCostum>

              <h3>Facturación</h3>
              <p>Esta métrica mide porcentualmente qué tanto has avanzado en la meta de facturación del presente mes.</p>

            </Col>
            <Col sm={4}>
              <CircularProgressbarCostum>
                <CircularProgressbarWithChildren
                  value={efficiency}
                  strokeWidth={7}
                  styles={buildStyles({
                    pathColor: "#F44336",
                    trailColor: "rgb(244, 67, 54, 20%)",
                  })}
                >
                  <h3>Tiempos</h3>
                  <span>{labelEfficiency()}</span>
                </CircularProgressbarWithChildren>
                <button type="button" className="btn-antd-icon" onClick={this.openMetricModalEF}>
                  <PlusCircleFilled className="efficiency--color" />
                </button>
              </CircularProgressbarCostum>
              <h3>Tiempos</h3>
              <p>Esta métrica mide si reportas de manera oportuna los inicios, pausas y finalizaciones de las órdenes de trabajo desde tu perfil.</p>
            </Col>
            <Col sm={4}>
              <CircularProgressbarCostum>
                <CircularProgressbarWithChildren
                  value={score}
                  strokeWidth={7}
                  styles={buildStyles({
                    pathColor: "#FFCC4D",
                    trailColor: "rgb(255, 204, 77, 20%)",
                  })}
                >
                  <h3>Evaluación</h3>
                  <span className={score !== 0 ? '' : 'noReviews'}>{score !== 0 ? `${scoreLabel}` : "No hay vehiculos evaluados"}</span>
                </CircularProgressbarWithChildren>
                <button type="button" className="btn-antd-icon" onClick={this.openMetricModalEV}>
                  <PlusCircleFilled className="evaluation--color" />
                </button>
              </CircularProgressbarCostum>

              <h3>Evaluación</h3>
              <p>Aquí puedes ver qué opinan los clientes de ti y el servicio que les brindas.</p>

            </Col>
            <Col sm={12}>
              <HistoryBtn onClick={() => {
                goToMetrics()
              }}>
                Ver metricas pasadas
              </HistoryBtn>
            </Col>
            <MetricModal
              modalOpen={showMetricModal}
              toggleModal={this.toggleMetricModal}
              data={MetricData}
              typeId={typeId}
            />
          </Row> : <Loader><div className="lds-ripple"> <div /><div /> </div> </Loader>}

      </div>
    )

  }
}

const CircularProgressbarCostum = styled.div`
    width: 150px;
    height: 150px;
    margin-top: 20px;
    h3{
        font-size: 2rem;
    }
    span{
    font-size: 2rem;
    }
    .noReviews{
        font-size: 1.3rem;
            @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
                font-size: 1.2rem;
                width:90%;
            }
        }
    i{
        position: absolute;
        margin-top: -46px;
        margin-left: 53px;
        font-size: 30px;
        cursor: pointer;
      }
    
      .evaluation--color{
        color:#FFCC4D;
      }
      .facturation--color{
        color:#219653;
      }
      .efficiency--color{
        color:#F44336;
      }
      button{
        border: none;
        cursor: default;
      }
`

const HistoryBtn = styled.div`
  align-items: center;
  border-radius: 2px;
  background-color: #071A29;
  border: none;
  outline: none;
  color: #fff;
  font-weight: 700;
  padding: 1rem 1.7rem;
  cursor: pointer;
  width: 200px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  `

const Loader = styled.div`
text-align: center;
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #000;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}`

LastWorkOrdersRatingsSectionComponent.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  infoUser: PropTypes.arrayOf(PropTypes.object).isRequired,
  lastMechanicBilling: PropTypes.arrayOf(PropTypes.object).isRequired,
  lastMechanicScore: PropTypes.arrayOf(PropTypes.object).isRequired,
  lastMechanicEfficiency: PropTypes.arrayOf(PropTypes.object).isRequired,
  goToMetrics: PropTypes.func.isRequired,
}

export default (LastWorkOrdersRatingsSectionComponent)
