import axios from 'axios'
import _ from 'lodash'

import {
  GET_PACKAGES,
  DELETE_PACKAGE,
  SET_PACKAGES,
} from '../actionTypes/PackageTypes'
import { NotificationHandler } from '../Helpers'
import { getPackagesList } from '../api/apiPackagesService'

const getPackagesAction = (payload) => ({
  type: GET_PACKAGES,
  payload,
})

const deletePackageAction = (payload) => ({
  type: DELETE_PACKAGE,
  payload,
})

const setPackagesAction = (payload) => ({
  type: SET_PACKAGES,
  payload,
})

export const setPackages = () => async (dispatch) => {
  try {
    const response = await getPackagesList()
    dispatch(setPackagesAction(response))
  } catch {
    NotificationHandler('Error', 'error', 'Error al obtener los paquetes')
  }
}

export const getPackages = (forceFetch) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const packagesQuantity = _.size(getState().PackageReducer.packages)
    if (packagesQuantity === 0 || forceFetch) {
      axios
        .get('/api/packages')
        .then((response) => {
          if (!response.data.error) {
            const packages = _.keyBy(response.data.data, 'package_id')
            dispatch(getPackagesAction(packages))
            resolve(response.data)
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al obtener los paquetes',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al obtener los paquetes',
            error
          )
        })
    } else {
      resolve({})
    }
  })

export const addPackage = (_package, processes) => (dispatch) =>
  new Promise((resolve, reject) => {
    // eslint-disable-next-line no-param-reassign
    delete _package.package_id
    axios
      .post('/api/packages', _package)
      .then((response) => {
        if (!response.data.error) {
          const processesToSend = []
          let cont = 0
          _.forEach(processes, (process) => {
            if (process.process_id) {
              cont += 1
              processesToSend.push({
                process_id: process.process_id,
                package_id: response.data.data.package_id,
                process_sequence: cont,
              })
            }
          })
          axios
            .post('/api/package_process', { processes: processesToSend })
            .then((res) => {
              if (!res.data.error) {
                dispatch(getPackages(true))
                resolve(res)
                NotificationHandler('Done', 'info', 'Paquete agregado')
              } else {
                reject()
                NotificationHandler(
                  'Error',
                  'error',
                  'Error al agregar el paquete',
                  res.data.data
                )
              }
            })
            .catch((error) => {
              reject()
              NotificationHandler(
                'Error',
                'error',
                'Error al agregar el paquete',
                error
              )
            })
        } else {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al agregar el paquete',
            response.data.data
          )
        }
      })
      .catch((error) => {
        reject()
        NotificationHandler(
          'Error',
          'error',
          'Error al agregar el paquete',
          error
        )
      })
  })

export const updatePackage = (_package, processes) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (_package.package_id) {
      const { package_id } = _package
      axios
        .put(`/api/packages/${package_id}`, _package)
        .then((response) => {
          if (!response.data.error) {
            const processesToSend = []
            let cont = 0
            _.forEach(processes, (process) => {
              if (process.process_id) {
                cont += 1
                processesToSend.push({
                  process_id: process.process_id,
                  package_id,
                  process_sequence: cont,
                })
              }
            })
            axios
              .put(`/api/package_process/${package_id}`, {
                processes: processesToSend,
              })
              .then((res) => {
                if (!res.data.error) {
                  dispatch(getPackages(true))
                  resolve(res)
                  NotificationHandler('Done', 'info', 'Paquete agregado')
                } else {
                  reject()
                  NotificationHandler(
                    'Error',
                    'error',
                    'Error al agregar el paquete',
                    res.data.data
                  )
                }
              })
              .catch((error) => {
                reject()
                NotificationHandler(
                  'Error',
                  'error',
                  'Error al agregar el paquete',
                  error
                )
              })
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al agregar el paquete',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al actualizar el paquete',
            error
          )
        })
    } else {
      reject()
      NotificationHandler(
        'Error',
        'error',
        `No se proporcionó id a: ${_package.package_name}`
      )
    }
  })

export const deletePackage = (_package) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (_package.package_id) {
      axios
        .delete(`/api/packages/${_package.package_id}`)
        .then((response) => {
          if (!response.data.error) {
            dispatch(deletePackageAction(_package))
            resolve(response)
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al eliminar el paquete',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al eliminar el paquete',
            error
          )
        })
    } else {
      reject()
      NotificationHandler(
        'Error',
        'error',
        `No se proporcionó id a: ${_package.package_name}`
      )
    }
  })
