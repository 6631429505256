import Styled from 'styled-components'

export const InvoiceFrame = Styled.div`
    border-radius: 0px 4px 4px 4px;
    min-height: 650px;
    background-color: #FFF;
    padding: 15px;
    margin-top: 10px;
    background-color: white;
    box-shadow: 5px 5px 5px #ccc;
    display: ${({ showInvoice }) => (showInvoice ? 'flex' : 'none')};
    flex-direction: column;
    justify-content: flex-start;
    border: 1px solid #CCCCCC;
    position: relative;
    
    .serie {
        display: flex;
        flex-direction: column;
        width: 100%;
        p {
            margin: 0px;
            font-size: 13px;
            font-weight: 600;
        }
        .select-container {
            display: flex;
        }
        .select-container .invoiceType {
            border: 1px solid #d9d9d9;
            border-radius: 0px 4px 4px 0px;
            background-color: white;
            width: 36px;
            height: 36px;
            outline: none;
        }
    }

    .showListButton {
        font-size: 16px;
        width: 34px;
        height: 34px;
        border-radius: 2px;
        border: 1px solid #CCCCCC;
        position: absolute;
        left: -34px;
        top: -1px;
        display: ${({ showList }) => (showList ? 'none' : 'block')};
        background-color: white;
    }
`

export const RadioButton = Styled.div`
    border: 1px solid gray;
    display: inline-block;
    padding: 5px 10px;
    border-radius: ${(prop) => {
      if (prop.isFirst) {
        return '5px 0px 0px 5px'
      }
      if (prop.isLast) {
        return '0px 5px 5px 0px'
      }
      return '0px'
    }};
    background-color: ${(prop) => (prop.isActive ? '#424242' : '#eeeeee')};
    color: ${(prop) => (prop.isActive ? 'white' : 'black')};
    margin: ${(prop) => {
      if (prop.isFirst) {
        return '5px 0px 0px 10px'
      }
      if (prop.isLast) {
        return '5px 10px 0px 0px'
      }
      return '0px'
    }};
    width: auto;
    text-align: center;
    box-shadow: 2px 2px 2px #C7C7C7;
    &:hover{
        cursor: pointer;
    }
`

export const InvoiceHeader = Styled.div`
    margin: 25px 10px;
`

export const PriceAndReclaimBox = Styled.div`
    background-color: #fafafa;
    height: 75px;
    width: 99.9%;
    box-shadow: 2px 2px 2px #C7C7C7;

`

export const InvoiceBodyHeader = Styled.div`
    display: flex;
    justify-content: flex-start;
    .div:first-child {
        margin-right: 10px;
    }
    .div {
        width: 200px;
    }
`

export const InvoiceTable = Styled.div`
    display: flex;
    flex-flow: row wrap;
    padding: 0px;
    margin: 0px;
`
export const TableRow = Styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    font-weight: ${(prop) => (prop.fontWeight ? prop.fontWeight : 'normal')};
`

export const Column = Styled.div`
    text-align: ${(prop) => (prop.textAlign ? prop.textAlign : 'center')};
    width: ${(prop) => (prop.width ? `${prop.width}%` : 'auto')};
    margin: ${(prop) => (prop.margin ? `${prop.margin}%` : '0px')};
    ${(prop) => (prop.noBorderBottom ? '' : 'border-bottom: 1px solid black;')}
    ${(prop) => (prop.borderTop ? 'border-top: 1px solid black;' : '')}
    ${(prop) => prop.noBorder && 'border: 0px;'}
    ${(prop) =>
      prop.last
        ? 'border-left: 0px solid black;'
        : 'border-right: 1px solid black;'}
    ${(prop) => prop.marginBottom && 'padding-bottom: 50px;'}
    ${(prop) => prop.padding && `padding:${prop.padding};`}
    ${(prop) => prop.height && `height:${prop.height};`}
    ${(prop) => prop.fontSize && `font-size:${prop.fontSize};`}


`

export const CenteredColumnText = Styled.div`
    margin: 10px 0%;
`

export const AddRow = Styled.img`
    float: left;
    height: 50px;
    margin: 20px 35px;
    &:hover{
        cursor: pointer;
        filter: brightness(200%)
    }
`

export const DetailInput = Styled.input`
    border: 0px solid red !important;
    height: 3em !important;
    font-weight: normal;
    text-align: center;
    margin: 0px !important;
    width: 100%;
    font-size: 1em !important;
`
