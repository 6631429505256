import React from 'react'
import { Select, Spin } from 'antd'
import PropTypes from 'prop-types'

import { SearchBarWrapper, SearchLabel } from './SearchBar.style'
import SelectFooterButton from '../../App/Common/SelectFooterButton/SelectFooterButton'

const SearchBar = ({
  id,
  items = [], // Array of {value: '', label: ''} objects for select
  value,
  isLoading,
  isAutoFocus,
  searchValue,
  addItemText,
  label,
  defaultValue,
  placeholder,
  handleValueChange,
  handleSearch,
  handleOnAdd,
  minChars,
}) => {
  const renderMessage = () => {
    if (minChars > 0 && searchValue && searchValue.length < minChars) {
      return (
        <p
          className="no-results"
          style={{ padding: '6px 12px', color: '#929292' }}
        >
          Ingresa al menos {minChars} caracteres para buscar
        </p>
      )
    }

    if (searchValue && !items.length > 0) {
      return isLoading ? (
        <Spin
          size="small"
          style={{
            margin: '5px auto',
            display: 'flex',
            justifyContent: 'center',
          }}
        />
      ) : (
        <p
          className="no-results"
          style={{ padding: '6px 12px', color: '#929292' }}
        >
          No se encontraron resultados
        </p>
      )
    }
    return null
  }

  return (
    <div>
      {label && <SearchLabel htmlFor={id}>{label}</SearchLabel>}
      <SearchBarWrapper>
        <i className="fas fa-search" />
        <Select
          showSearch
          id={id}
          defaultValue={defaultValue}
          value={value}
          size="large"
          suffixIcon={null}
          loading
          labelInValue
          autoFocus={isAutoFocus}
          filterOption={false}
          onSearch={handleSearch}
          placeholder={placeholder}
          onChange={handleValueChange}
          defaultActiveFirstOption={false}
          allowClear
          placement="bottomLeft"
          style={{ width: '100%' }}
          notFoundContent={renderMessage()}
          listHeight="20vh"
          dropdownRender={(menu) => (
            <>
              {menu}
              {addItemText && (
                <SelectFooterButton
                  buttonText={addItemText}
                  onClickHandler={handleOnAdd}
                />
              )}
            </>
          )}
          options={items}
        />
      </SearchBarWrapper>
    </div>
  )
}
export default SearchBar

SearchBar.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  value: PropTypes.any,
  searchValue: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  addItemText: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.object,
  isAutoFocus: PropTypes.bool,
  minChars: PropTypes.number,
  label: PropTypes.string,
  handleValueChange: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleOnAdd: PropTypes.func,
}

SearchBar.defaultProps = {
  addItemText: '',
  isLoading: false,
  defaultValue: undefined,
  placeholder: '¿Qué necesitas buscar?',
  label: '',
  minChars: 0,
  value: undefined,
  isAutoFocus: false,
  handleOnAdd: () => true,
}
