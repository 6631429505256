import { useEffect, useState } from 'react'

export const useStringLoop = (stringArray, interval = 2, loop = true) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    let timeout

    if (loop || currentIndex < stringArray.length - 1) {
      timeout = setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % stringArray.length)
      }, interval * 1000)
    }

    return () => clearTimeout(timeout)
  }, [currentIndex, interval, loop, stringArray])

  return stringArray[currentIndex]
}
