import React, { Component } from 'react'
import { Modal, Button, Table } from 'react-bootstrap'
import _ from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'

class HistoryModal extends Component {
  state = {
    work_order_id: null,
    work_order_total: 0,
    person: {},
    invoice: {},
    details: [
      {
        work_order_detail_item_price: '',
        work_order_detail_workforce_price: '',
        process: {
          process_name: '',
        },
        product: {
          product_name: '',
        },
      },
    ],
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { selectedHistory } = props

    if (!_.isEmpty(selectedHistory)) {
      this.setState({
        work_order_id: selectedHistory.work_order_id,
        work_order_total: selectedHistory.work_order_total,
        details: selectedHistory.details,
        person: selectedHistory.person,
        invoice: selectedHistory.invoice,
      })
    }
  }

  render() {
    const { modalOpen, toggleModal } = this.props

    const { work_order_total, details, work_order_id, person, invoice } =
      this.state

    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }
    const getDate = (date) => {
      const readableDate = new Date(date)
      return readableDate.toLocaleString('es-ES', options)
    }

    return (
      <Modal show={modalOpen} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>Detalles de la venta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <HistoryContainer>
            <div className="history__section">
              <h3 className="history__section__title">Orden </h3>
              <div className="history__section__content">
                <div>
                  <p className="history__section__label">Número:</p>
                  <p># {work_order_id}</p>
                </div>
                <div>
                  <p className="history__section__label">
                    Fecha de facturación:
                  </p>
                  <p>{getDate(invoice.invoice_date)}</p>
                </div>
                <div>
                  <p className="history__section__label">Total facturado:</p>
                  <p>$ {work_order_total}</p>
                </div>
              </div>
            </div>

            <div className="history__section">
              <h3 className="history__section__title"> Cliente </h3>
              <div className="history__section__content">
                <div>
                  <p className="history__section__label">Nombre:</p>
                  <p>
                    {person.person_firstname} {person.person_lastname}
                  </p>
                </div>
                <div>
                  <p className="history__section__label">No. Documento:</p>
                  <p>{person.person_document_number}</p>
                </div>
                <div>
                  <p className="history__section__label">Teléfono:</p>
                  <p>{person.person_phone}</p>
                </div>
              </div>
            </div>

            <div className="history__section">
              <h3 className="history__section__title">Repuestos</h3>
              <div>
                {details.length <= 0 && (
                  <p className="history__section__label --center">
                    No se agregaron respuestos
                  </p>
                )}
                {details.length > 0 && (
                  <Table responsive bordered condensed>
                    <thead>
                      <tr>
                        <th>Detalle</th>
                        <th>Cantidad</th>
                        <th>Precio U.</th>
                        <th>Sub-total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.map(details, (detail, index) => (
                        <tr key={index}>
                          {detail.product && (
                            <td>
                              {detail.product
                                ? detail.product.product_name
                                : ''}
                            </td>
                          )}

                          {detail.process && (
                            <td>
                              {detail.process
                                ? detail.process.process_name
                                : ''}
                            </td>
                          )}

                          <td>
                            {detail.process
                              ? 1
                              : detail.work_order_detail_item_quantity}
                          </td>

                          {detail.product && (
                            <td>${detail.work_order_detail_item_price || 0}</td>
                          )}

                          {detail.process && (
                            <td>
                              ${detail.work_order_detail_workforce_price || 0}
                            </td>
                          )}

                          <td>
                            $
                            {detail.product
                              ? detail.work_order_detail_item_price *
                                detail.work_order_detail_item_quantity
                              : detail.work_order_detail_workforce_price * 1}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </div>
            </div>
          </HistoryContainer>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={toggleModal}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

const HistoryContainer = styled.div`
  .history__section {
    margin-bottom: 4rem;

    &__title {
      margin-bottom: 1.5rem;
    }

    &__content {
      display: flex;
      flex-wrap: wrap;

      div {
        margin-right: 5rem;
      }
    }

    &__label {
      font-size: 1.7rem;
      color: var(--primary-text-color);
      margin-bottom: 0px;

      &.--center {
        width: 100%;
        text-align: center;
      }

      & + p {
        font-size: 1.7rem;
        color: var(--secondary-text-color);
        font-weight: 700;
      }
    }
  }
`

HistoryModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  selectedHistory: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default HistoryModal
