import React, { useState, useEffect } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactToolTip from 'react-tooltip'
import { connect } from 'react-redux'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar'
import {
  WhatsAppOutlined,
  MenuOutlined,
  LeftOutlined
} from "@ant-design/icons";
import { Tooltip, Button } from 'antd';
import getName from '../../Helpers/getName'
import { LOG_OUT } from '../../actionTypes/AppTypes'
import { updateNotificationCount } from '../../actions/AppAction'
import { menuContent } from '../SideNav/menuContent';
import CredentialsModal from '../Credentials/CredentialsModal';
import { SupportPhoneNumber } from '../../Constants';
import KeyIcon from '../icons/KeyIcon'
import UserIcon from '../icons/UserIcon'
import LogoutIcon from '../icons/LogoutIcon'
import {
  AvatarContainer,
  AvatarPhoto,
  AvatarInformationContainer,
  AvatarNickName,
  AvatarActions,
  ConnectedAvatarNotificationsCount
} from '../Utilities/AvatarElements'


const themes = {
  light: {
    sidebar: {
      backgroundColor: '#1e1e40',
      color: '#ffffff',
    },
    menu: {
      menuContent: '#005cb8',
      icon: '#fff',
      hover: {
        backgroundColor: '#1e1e40',
        color: 'red',
      },
      disabled: {
        color: '#9fb6cf',
      },
    },
  },
  dark: {
    sidebar: {
      backgroundColor: '#0b2948',
      color: '#8ba1b7',
    },
    menu: {
      menuContent: '#082440',
      icon: '#59d0ff',
      hover: {
        backgroundColor: '#00458b',
        color: 'red',
      },
      disabled: {
        color: '#3e5e7e',
      },
    },
  },
}

const theme = 'light'

const menuItemStyles = {
  icon: {
    color: themes[theme].menu.icon,
  },
  hover: {
    color: themes[theme].menu.hover,
  },
  button: {
    [`&.active`]: {
      backgroundColor: '#8c236a33',
      color: '#ffffff',
    },
  },
}

const SidebarHeader = ({ workshop, currentUser, toggleSidebar, collapsed, forceReloadSidebar }) => {
  const [credentialModalOpen, setCredentialModalOpen] = useState(false);

  const name = getName(
    currentUser.employee_name,
    currentUser.employee_lastname
  );

  const goToProfile = () => {
    window.location.href = '/profile'
  }

  const logOut = () => {
    localStorage.removeItem('jwtToken')
    localStorage.removeItem('tokenExpiration')
    window.location.reload()
  }

  const toggleCredentialsModal = () => {
    setCredentialModalOpen(!credentialModalOpen)
  }

  const collapseSidebar = () => {
    toggleSidebar();
  };

  return (
    <div className='sidebar-header'>
      <div className={`brand ${collapsed ? 'collapsed' : ''}`}>
        <button
          type="button"
          className="sidebar-button"
          onClick={collapseSidebar}
        >
          {collapsed ? <MenuOutlined /> : <LeftOutlined />}
        </button>
        {!collapsed && <img src="/logo.png" alt="Logo" className="logo" />}
      </div>
      {!collapsed && (
        <AvatarContainer bgColor="#8c236a" className="user-card">
          <AvatarPhoto
            width={70}
            height={70}
            pathImage={
              currentUser.employee_urlimg
                ? currentUser.employee_urlimg
                : ''
            }
            borderRadius={50}
            onClick={goToProfile}
            title="Perfil"
          />
          <AvatarInformationContainer pRight={0}>
            <AvatarNickName
              onClick={goToProfile}
              style={{ color: "#fff" }}
            >
              {`${name.firstname} ${name.lastname}`}
            </AvatarNickName>
            <AvatarNickName
              onClick={goToProfile}
              style={{ color: "#fff" }}
            >
              {workshop.workshop_name}
            </AvatarNickName>
            <AvatarActions>
              <Tooltip title="Password">
                <Button className='avatar-card-actions' size='small' type='link' onClick={toggleCredentialsModal}>
                  <KeyIcon color='#FFF' />
                </Button>
              </Tooltip>
              <Tooltip title='Profile'>
                <Button className='avatar-card-actions' size='small' type='link' onClick={goToProfile}>
                  <UserIcon color='#FFF' />
                </Button>
              </Tooltip>
              <Tooltip title='Logout'>
                <Button className='avatar-card-actions' size='small' type='link' onClick={logOut}>
                  <LogoutIcon color="#FFF" size="15px" />
                </Button>
              </Tooltip>
              <ReactToolTip />
            </AvatarActions>
            <CredentialsModal
              handleClose={toggleCredentialsModal} // Use the defined function here
              modalState={credentialModalOpen}
            />
          </AvatarInformationContainer>
          <ConnectedAvatarNotificationsCount forceReloadSidebar={forceReloadSidebar} />
        </AvatarContainer>
      )}
    </div>
  );
};

SidebarHeader.propTypes = {
  workshop: PropTypes.objectOf(PropTypes.any).isRequired,
  currentUser: PropTypes.objectOf(PropTypes.any).isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired,
  forceReloadSidebar: PropTypes.func.isRequired
};

const flattenMenu = (menu) => {
  const flattened = []
  const stack = [...menu]

  while (stack.length > 0) {
    const item = stack.pop()

    if (item.subItems) {
      // Push subItems onto the stack for processing
      stack.push(...item.subItems)
    }

    flattened.push(item)
  }

  return flattened
}

const findAncestor = (flattenedMenu, currentAncestorId) => {
  return flattenedMenu.find((parent) =>
    parent.subItems?.some((sub) => sub.id === currentAncestorId)
  )
}

const ProSidebar = ({ currentUser, workshop, location, forceReloadSidebar, getNotificationCount }) => {
  const currentPath = window.location.pathname;
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(true);
  const [notificationsCount] = useState(0);

  const storedOpenSubmenus = JSON.parse(localStorage.getItem('openSubmenus')) || {};
  const [openSubmenus, setOpenSubmenus] = useState(storedOpenSubmenus);

  // Flatten the menu structure
  const flattenedMenu = flattenMenu(menuContent)

  const toggleSidebar = () => {
    setCollapsed((prevCollapsed) => !prevCollapsed)
  }

  const handleMenuItemClick = () => {
    setToggled(false);
  };

  const onBreakPoint = () => {
    setToggled(true);
  };

  useEffect(() => {
    getNotificationCount(currentUser);
  }, [currentPath]);

  useEffect(() => {
    // Find and update open submenus based on the current path
    const updatedOpenSubmenus = { ...openSubmenus }
    flattenedMenu.forEach((item) => {
      if (currentPath.startsWith(`/${item.path}`)) {
        let currentAncestorId = item.id
        while (currentAncestorId) {
          updatedOpenSubmenus[currentAncestorId] = true
          const ancestor = findAncestor(flattenedMenu, currentAncestorId)
          currentAncestorId = ancestor?.id
        }
      }
    })

    // Set the openSubmenus state
    setOpenSubmenus(updatedOpenSubmenus)
  }, [location])

  useEffect(() => {
    const updatedOpenSubmenus = {}
    flattenedMenu.forEach((item) => {
      if (currentPath.startsWith(`/${item.path}`)) {
        let currentAncestorId = item.id
        while (currentAncestorId) {
          updatedOpenSubmenus[currentAncestorId] = true
          const ancestor = findAncestor(flattenedMenu, currentAncestorId)
          currentAncestorId = ancestor?.id
        }
      }
    })
    // Set the openSubmenus state
  }, [forceReloadSidebar])

  useEffect(() => {
    // Save open submenus to local storage whenever it changes
    localStorage.setItem('openSubmenus', JSON.stringify(openSubmenus))
  }, [openSubmenus])

  const renderMenuItem = (item) => {
    if (item.divider) {
      return <hr className="divider" key={`divider-${item.id}`} />
    }

    // Determine if the submenu should be open based on the openSubmenus state and current path
    const isSubMenuOpen =
      openSubmenus[item.id] || currentPath.startsWith(`/${item.path}`)

    if (item.subItems) {
      return (
        <SubMenu
          key={`submenu-${item.id}`}
          label={item.title}
          icon={item.icon && item.icon.name}
          open={isSubMenuOpen}
          onOpenChange={() => {
            setOpenSubmenus((prevOpenSubmenus) => ({
              ...prevOpenSubmenus,
              [item.id]: !isSubMenuOpen, // Toggle the open state
            }))
          }}
        >
          {item.subItems.map((subItem) => renderMenuItem(subItem))}
        </SubMenu>
      )
    }

    return (
      <MenuItem
        icon={item.icon && item.icon.name}
        suffix={
          item.suffix ? <span className="badge badge-primary">Nuevo</span> : ''
        }
        onClick={handleMenuItemClick}
        close
        component={
          <NavLink
            to={item.path}
            className="sidebar-link"
            key={`menuitem-${item.id}`}
          />
        }
      >
        {item.title}
      </MenuItem>
    )
  }

  const openWhatsAppChat = () => {
    const message = `¡Hola! Soy ${currentUser.employee_name}, necesito su apoyo para solventar un problema técnico en el taller: ${workshop.workshop_name}.`
    const phoneNumber = SupportPhoneNumber
    const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`

    window.open(whatsappURL, '_blank')
  }

  return (
    <>
      <Sidebar
        collapsed={collapsed}
        toggled={toggled}
        backgroundColor={themes[theme].sidebar.backgroundColor}
        breakPoint="md"
        onBackdropClick={handleMenuItemClick}
        width="279px"
        onBreakPoint={onBreakPoint}
        className="sidebar-container"
      >
        <SidebarHeader
          currentUser={currentUser}
          workshop={workshop}
          toggleSidebar={toggleSidebar}
          collapsed={collapsed}
          notificationsCount={notificationsCount}
          forceReloadSidebar={forceReloadSidebar}
        />
        <Menu iconShape="circle" menuItemStyles={menuItemStyles}>
          {menuContent.map((item) => renderMenuItem(item))}
          <hr className="divider" key="divider-support" />
          <MenuItem
            onClick={openWhatsAppChat}
            key="menuitem-support"
            icon={<WhatsAppOutlined className='wa-menu-item' />}
          >
            Contáctanos por WhatsApp
          </MenuItem>
        </Menu>
      </Sidebar>
      <button
        type="button"
        className="toggle-button"
        onClick={onBreakPoint}
      >
        {toggled ? (
          <MenuOutlined />
        ) : (
          <MenuOutlined />
        )}
      </button>
    </>
  )
}

ProSidebar.propTypes = {
  currentUser: PropTypes.objectOf(PropTypes.any).isRequired,
  workshop: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.object.isRequired,
  forceReloadSidebar: PropTypes.func.isRequired,
  getNotificationCount: PropTypes.func.isRequired
};

const mapStateToProps = (store) => ({
  currentUser: store.AppReducer.currentUser,
  workshop: store.AppReducer.currentUser.workshop,
  employeeId: store.AppReducer.currentUser.employee_id,
  notificationsCount: store.AppReducer.notificationsCount,
});

const mapDispatchToProps = (dispatch) => ({
  logOut() {
    dispatch({ type: LOG_OUT })
  },
  getNotificationCount(employeeId) {
    return dispatch(updateNotificationCount(employeeId))
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProSidebar)
)
