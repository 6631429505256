import React from 'react'
import { Modal } from 'antd'
import { PropTypes } from 'prop-types'
import { Player } from '@lottiefiles/react-lottie-player'
import successAnimation from '../../../../lotties/success-animation.json'
import { SuccessModalWrapper } from './SuccessModal.style'
import LoaderDynamicText from '../LoaderDynamicText'

const SuccessModal = ({ isModalOpen, closeModal, text, isLoading }) => {
  return (
    <Modal
      style={{ textAlign: 'center', padding: '3rem 1rem' }}
      open={isModalOpen}
      width={325}
      centered
      onCancel={closeModal}
      footer={null}
      closable={!isLoading}
      maskClosable={false}
    >
      <SuccessModalWrapper>
        {isLoading ? (
          <div className="loader">
            <LoaderDynamicText
              textArray={[
                'Cargando datos...',
                'Guardando fotos...',
                'Creando recepción...',
              ]}
              colored
            />
          </div>
        ) : (
          <>
            {' '}
            <Player
              src={successAnimation}
              loop
              autoplay
              style={{ height: '400px' }}
            />
            <p>{text}</p>
          </>
        )}
      </SuccessModalWrapper>
    </Modal>
  )
}

SuccessModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
}

export default SuccessModal
