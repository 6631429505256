import React, { Component } from 'react'
import { connect } from 'react-redux'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory from 'react-bootstrap-table2-filter'
import { Panel, Grid, Row, Col, Button } from 'react-bootstrap'
import PropTypes from 'prop-types'
import _ from 'lodash'
import styled from 'styled-components'
import { MenuOutlined, PlusCircleFilled } from '@ant-design/icons';
import {
  getModels,
  deleteModel,
  addModel,
  updateModel,
} from '../../../../actions/ModelAction'
import { getVehicleTypes } from '../../../../actions/VehicleTypesAction'
import { getBrands } from '../../../../actions/BrandAction'
import {
  Container,
  ContainerHeader,
  BodyContent,
  showConfirmDialog,
  PanelHeader,
} from '../../Common/index'
import ModelModal from './ModelModal'
import getColumns from './columns'
import { BREAK_POINTS_DESKTOP_FIRST } from '../../../../global/css/breakPoints'
import { AppLoader } from '../../Common/AppLoader'

const SCREEN_ID = 14
class ModelsContainer extends Component {
  state = {
    showModal: false,
    loading: true,
    selectedModel: {},
  }

  componentDidMount() {
    const { getAllModels, getAllBrands, getAllVehicleTypes } = this.props

    getAllModels()
      .then(() => {
        this.setState({ loading: false })
      })
      .catch(() => {
        this.setState({ loading: false })
      })
    getAllBrands()
    getAllVehicleTypes()
  }

  componentWillUnmount() {
    this.mounted = false
  }

  toggleModal = () => {
    const { showModal } = this.state
    this.setState({ showModal: !showModal })
  }

  addModel = () => {
    this.setState({ selectedModel: {} })
    this.toggleModal()
  }

  updateModel = (model) => {
    this.setState({ selectedModel: model })
    this.toggleModal()
  }

  deleteModel = (model) => {
    const { removeModel } = this.props
    showConfirmDialog(
      '¿Eliminar?',
      `¿Desea eliminar el modelo ${model.model_name}?`,
      removeModel,
      model
    )
  }

  render() {
    const { showModal, selectedModel, loading } = this.state
    const {
      brands,
      permissions,
      models,
      createModel,
      modifyModel,
      vehicleTypes,
    } = this.props
    const { can_write, can_edit, can_delete } = permissions[SCREEN_ID]
    const pagePermissions = {
      can_write,
      can_edit,
      can_delete,
    }
    return (
      <Container>
        <ContainerHeader title="Modelos" />
        <BodyContent>
          <Grid fluid>
            <Row>
              <Col sm={12}>
                <Panel>
                  <Panel.Heading bsClass="panel-heading">
                    <Panel.Title>
                      <PanelHeader>
                        <div>
                          <p id="title">
                            <MenuOutlined style={{ marginRight: '7px' }} /> Administrar Modelos
                          </p>
                        </div>
                        <div>
                          {!!can_write && (
                            <Button
                              onClick={this.addModel}
                              bsStyle="success"
                              className="btn-add btn-antd-icon"
                            >
                              <PlusCircleFilled />
                              Agregar registro
                            </Button>
                          )}
                        </div>
                      </PanelHeader>
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    {loading ? (
                      <AppLoader />
                    ) : (
                      <Row>
                        <Col md={10} />
                        <Col md={12}>
                          <CustomStyledTable>
                            <BootstrapTable
                              noDataIndication="Sin datos para mostrar"
                              keyField="model_id"
                              data={_.values(models).reverse()}
                              columns={getColumns(
                                this.updateModel,
                                this.deleteModel,
                                {
                                  can_edit,
                                  can_delete,
                                }
                              )}
                              filter={filterFactory()}
                              pagination={paginationFactory()}
                            />
                          </CustomStyledTable>
                        </Col>
                      </Row>
                    )}
                  </Panel.Body>
                </Panel>
              </Col>
            </Row>
          </Grid>
          <ModelModal
            modalOpen={showModal}
            toggleModal={this.toggleModal}
            editMode={!_.isEmpty(selectedModel)}
            addModel={createModel}
            updateModel={modifyModel}
            brands={brands}
            selectedModel={selectedModel}
            pagePermissions={pagePermissions}
            vehicleTypes={vehicleTypes}
          />
        </BodyContent>
      </Container>
    )
  }
}

const CustomStyledTable = styled.div`
  @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
    table {
      border: none;
      padding: 0px 10px;
      thead {
        tr {
          display: flex;
          flex-direction: column;
          th {
            label {
              width: 100%;
            }
          }
          th {
            border: none;
          }
          th:nth-last-child(1),
          th:nth-last-child(2) {
            display: none;
          }
        }
      }
      tbody {
        tr {
          border: 1px solid #ddd;
          display: flex;
          flex-wrap: wrap;
          border-radius: 10px;
          margin: 20px 10px;
          padding-top: 10px;
          padding-bottom: 10px;
          td {
            width: 100%;
            border: 0;
            border-right: 0px !important;
            border-left: 0px !important;
          }
          td:nth-last-child(1),
          td:nth-last-child(2) {
            width: 40%;
          }
          td::before {
            font-weight: bold;
            margin-right: 0.5rem;
          }
          td:nth-child(1)::before {
            content: 'Nombre:';
          }
          td:nth-child(2)::before {
            content: 'Marca:';
          }
        }
      }
    }
  }
`

const mapStateToProps = (store) => ({
  models: store.ModelReducer.models,
  brands: store.BrandReducer.brands,
  permissions: store.AppReducer.permissions,
  vehicleTypes: store.VehicleTypesReducer.vehicleTypes,
})

const mapDispatchToProps = (dispatch) => ({
  getAllModels() {
    return dispatch(getModels())
  },
  getAllVehicleTypes() {
    dispatch(getVehicleTypes())
  },
  createModel(model) {
    return dispatch(addModel(model))
  },
  removeModel(model) {
    return dispatch(deleteModel(model))
  },
  modifyModel(model) {
    return dispatch(updateModel(model))
  },
  getAllBrands() {
    dispatch(getBrands())
  },
})

ModelsContainer.propTypes = {
  permissions: PropTypes.objectOf(PropTypes.object).isRequired,
  getAllVehicleTypes: PropTypes.func.isRequired,
  getAllModels: PropTypes.func.isRequired,
  getAllBrands: PropTypes.func.isRequired,
  createModel: PropTypes.func.isRequired,
  modifyModel: PropTypes.func.isRequired,
  removeModel: PropTypes.func.isRequired,
  models: PropTypes.arrayOf(PropTypes.object).isRequired,
  brands: PropTypes.arrayOf(PropTypes.object).isRequired,
  vehicleTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModelsContainer)
