/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { PropTypes } from 'prop-types'
import { Button, Modal } from 'antd'
import TextFormControl from '../../App/Common/TextFormControl/TextFormControl'
import NumberFormControl from '../../App/Common/NumberFormControl/NumberFormControl'
import { ModalButtonsContainer } from '../../App/Reception/AddReception/VehicleStep/VehicleStep.style'
import { AddProductFormWrapper } from './AddProductForm.styles'
import { getProvidersList } from '../../../api/apiProviderService'
import { NotificationHandler } from '../../../Helpers'
import PriceInputControl from '../../App/Common/PriceInputControl/PriceInputControl'
import { fetchCategories } from '../../../api/newReceptionService'
import { FormModalTitle } from '../../../Components'
import { useModal } from '../../../hooks/useModal'
import AddProviderForm from '../AddProviderForm/AddProviderForm'
import CustomSelect from '../../inputs/CustomSelect/CustomSelect'

const AddProductForm = ({ children, handleFormSubmit }) => {
  const methods = useForm({ mode: 'onChange' })
  const [provider, setProvider] = useState(undefined)
  const [category, setCategory] = useState(undefined)
  const [providersList, setProvidersList] = useState([])
  const [categoriesList, setCategoriesList] = useState([])
  const [isModalOpen, openModal, closeModal] = useModal()
  const {
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = methods
  const isSubmitDisabled = !isValid || !provider || !category

  const resetForm = () => {
    reset({
      productName: '',
      productCode: '',
      productCost: undefined,
      productPrice: undefined,
      productQuantity: undefined,
      productRack: '',
    })
    setProvider(undefined)
    setCategory(undefined)
  }

  useEffect(() => {
    resetForm()
  }, [])

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getProvidersList()
        const mappedResponse = response?.map((item) => {
          return {
            label: item.providerName,
            value: item.providerId,
          }
        })
        setProvidersList(mappedResponse)
      } catch {
        NotificationHandler(
          'Error',
          'error',
          `Error obteniendo listado de proveedores`
        )
      }
    }
    getData()
  }, [provider])

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetchCategories()
        const mappedResponse = response?.map((item) => {
          return {
            label: item.categoryName,
            value: item.categoryId,
          }
        })
        setCategoriesList(mappedResponse)
      } catch {
        NotificationHandler(
          'Error',
          'error',
          `Error obteniendo listado de categorias`
        )
      }
    }
    getData()
  }, [])

  const onSubmit = (data) => {
    if (!isSubmitDisabled) {
      handleFormSubmit({
        productName: data.productName,
        productPrice: data.productPrice,
        productCost: data.productCost,
        productQuantity: data.productQuantity,
        productCode: data.productCode,
        providerId: provider.value,
        categoryId: category.value,
        rack: data.productRack || '',
      })
      resetForm()
    }
  }

  return (
    <>
      <FormProvider {...methods}>
        <AddProductFormWrapper onSubmit={handleSubmit(onSubmit)}>
          <TextFormControl
            label="Nombre"
            isAutoFocus
            name="productName"
            error={errors?.productName?.message}
            required
            requiredMessage="Ingresa un nombre para el repuesto"
          />
          <TextFormControl
            label="Codigo"
            name="productCode"
            error={errors?.productCode?.message}
            required
            requiredMessage="Ingresa el codigo del repuesto"
          />
          <div className="inputs">
            <PriceInputControl
              label="Costo"
              name="productCost"
              error={errors?.productCost?.message}
              required
              requiredMessage="Ingresa el costo del repuesto"
            />
            <PriceInputControl
              label="Precio de venta"
              name="productPrice"
              error={errors?.productPrice?.message}
              required
              requiredMessage="Ingresa el precio de venta del repuesto"
            />
          </div>
          <NumberFormControl
            label="Cantidad disponible"
            name="productQuantity"
            error={errors?.productQuantity?.message}
            required
            requiredMessage="Ingresa la cantidad disponible del repuesto"
          />
          <CustomSelect
            id="provider-select"
            items={providersList}
            value={provider}
            label="Proveedor"
            placeholder="Selecciona un proveedor"
            handleValueChange={setProvider}
            required
            addItemText="Agregar proveedor"
            handleOnAdd={openModal}
          />
          <CustomSelect
            id="category-select"
            items={categoriesList}
            value={category}
            label="Categoria"
            placeholder="Selecciona una categoria"
            handleValueChange={setCategory}
            required
          />
          <TextFormControl
            label="Anaquel"
            name="productRack"
            error={errors?.productRack?.message}
          />
          <ModalButtonsContainer style={{ marginTop: '1.5rem' }}>
            {children}
            <Button
              type="primary"
              htmlType="submit"
              disabled={isSubmitDisabled}
            >
              Agregar
            </Button>
          </ModalButtonsContainer>
        </AddProductFormWrapper>
      </FormProvider>
      <Modal
        title={<FormModalTitle>Agregar proveedor</FormModalTitle>}
        style={{ padding: '3rem 1rem' }}
        open={isModalOpen}
        width={350}
        centered
        onCancel={closeModal}
        footer={null}
      >
        <AddProviderForm setProvider={setProvider} closeModal={closeModal}>
          <Button key="back" onClick={closeModal}>
            Cancelar
          </Button>
        </AddProviderForm>
      </Modal>
    </>
  )
}

AddProductForm.propTypes = {
  children: PropTypes.node,
  handleFormSubmit: PropTypes.func.isRequired,
}

AddProductForm.defaultProps = {
  children: <></>,
}

export default AddProductForm
