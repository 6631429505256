import styled from 'styled-components'
import { BREAK_POINTS_DESKTOP_FIRST } from '../../../global/css/breakPoints'

const HistoryFiltersWrapper = styled.div`
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;

  .workshop-name {
    font-weight: bold;
    margin-bottom: 60px;
  }
  .text-filters-wrapper {
    margin-bottom: 16px;
    display: flex;
    gap: 24px;
    .form-group {
      display: flex;
      gap: 8px;
      align-items: center;
      .control-label {
        font-weight: 400;
      }

      .form-control {
        border: unset;
        border-radius: 8px;
        background-color: #f2f2f2;
        &:focus {
          outline: none;
        }
      }
    }
  }
  .data-btns-filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btns-wrapper {
      display: flex;
      gap: 14px;
      .btn {
        height: min-content;
        font-size: 1.2rem;
        border: unset;
        border-radius: 4px;
        color: white;
        background-color: #1e1e40;
        &:hover {
          opacity: 0.8;
        }
        &:focus{
            outline: none;
        }
      }
      .search {
        background-color: #1e1e40;
      }
      .clear {
        background-color: #929191;
      }
    }
  }

  @media (${BREAK_POINTS_DESKTOP_FIRST.DESKTOP}) {
    .data-btns-filters {
      flex-direction: column;
      justify-content: flex-start;
      gap: 32px;
      .btns-wrapper {
        width: 100%;
        justify-content: flex-end;
      }
    }
  }
  @media (${BREAK_POINTS_DESKTOP_FIRST.MOBILE}) {
    .text-filters-wrapper {
      flex-direction: column;
      gap:16px;
       .form-group {
        .control-label{
            min-width: 55px;
        }
       }
    }
    .data-btns-filters {
        gap:48px;
    }
  }
`
const StarRow = styled.span`
  i {
    color: #E3B719;
    margin-left: 4px;
  }
`
export { HistoryFiltersWrapper, StarRow }
