import styled from 'styled-components'

export const ProductsWrapper = styled.div`
  background-color: #f9f4ff;
  padding: 24px 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`
export const ProductWrapper = styled.div`
  padding: 12px 16px;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;

  .name {
    display: flex;
    flex-direction: column;

    .product-name {
      text-transform: uppercase;
    }
    .product-price {
      margin-top: 4px;
      display: flex;
      align-items: center;

      font-size: 12px;

      span {
        text-align: center;

        color: #e70000;
        border-radius: 10px;
        padding: 0 7px;
      }
    }
    .required {
      margin-top: 8px;
      color: #e70000;
      font-size: 10px;
      font-weight: 500;
      i {
        margin-right: 4px;
      }
    }
  }
  .detail {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 12px;
    .cant {
      width: fit-content;
      margin: unset;
      padding: 0px 10px;
      border-radius: 5px;
      border: 1px solid #b8b8b8;
    }
    .price {
      font-weight: 600;
    }
  }
`
