import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const TimeAgo = ({ inputDate }) => {
    const [timeAgo, setTimeAgo] = useState('');

    useEffect(() => {
        const calculateTimeAgo = () => {
            const serverDate = new Date(inputDate);
            const currentDate = new Date();

            // Calculate the time difference in seconds and exclude the 6-hour difference
            const diffInSeconds = Math.floor((currentDate - serverDate) / 1000) - 6 * 3600;

            if (diffInSeconds < 60) {
                setTimeAgo('hace < 1 minuto');
            } else if (diffInSeconds < 3600) {
                const minutes = Math.floor(diffInSeconds / 60);
                setTimeAgo(minutes === 1 ? '1 minuto' : `${minutes} minutos`);
            } else if (diffInSeconds < 86400) {
                const hours = Math.floor(diffInSeconds / 3600);
                setTimeAgo(hours === 1 ? '1 hora' : `${hours} horas`);
            } else if (diffInSeconds < 604800) {
                const days = Math.floor(diffInSeconds / 86400);
                setTimeAgo(days === 1 ? '1 día' : `${days} días`);
            } else if (diffInSeconds < 2419200) { // 4 weeks (28 days)
                const weeks = Math.floor(diffInSeconds / 604800);
                setTimeAgo(weeks === 1 ? '1 semana' : `${weeks} semanas`);
            } else if (diffInSeconds < 29030400) { // 11 months (approximately 47 weeks)
                const months = Math.floor(diffInSeconds / 2419200);
                setTimeAgo(months === 1 ? '1 mes' : `${months} meses`);
            } else {
                const years = Math.floor(diffInSeconds / 29030400);
                setTimeAgo(years === 1 ? '1 año' : `${years} años`);
            }
        };

        calculateTimeAgo();
    }, [inputDate]);

    return <span>{timeAgo}</span>;
};

TimeAgo.propTypes = {
    inputDate: PropTypes.string.isRequired,
};

export default TimeAgo;
