import React from 'react'
import { PropTypes } from 'prop-types'

const ProcessIcon = ({ className, hexColor }) => (
  <figure className={className}>
    <svg viewBox="0 0 70 67" fill="none">
      <path
        d="M67.9631 56.9644L49.8497 38.8511L42.0764 46.6244L60.1898 64.7377C62.3531 66.9011 65.8364 66.9011 67.9631 64.7377C70.0897 62.5744 70.0897 59.0911 67.9631 56.9644Z"
        fill={hexColor}
      />
      <path
        d="M55.7168 27.6668C62.7935 27.6668 68.5501 21.9102 68.5501 14.8335C68.5501 12.7068 67.9635 10.7268 67.0468 8.96685L57.1468 18.8668L51.6835 13.4035L61.5835 3.50351C59.8235 2.58685 57.8435 2.00018 55.7168 2.00018C48.6401 2.00018 42.8835 7.75685 42.8835 14.8335C42.8835 16.3368 43.1768 17.7668 43.6535 19.0868L36.8701 25.8702L30.3435 19.3435C31.7735 17.9135 31.7735 15.6035 30.3435 14.1735L27.7401 11.5702L35.5135 3.79685C31.2235 -0.493154 24.2568 -0.493154 19.9668 3.79685L9.62681 14.1735C8.19681 15.6035 8.19681 17.9135 9.62681 19.3435L12.2301 21.9468H2.91681C2.22014 21.9468 1.56014 22.2035 1.08348 22.7168C0.0568099 23.7435 0.0568099 25.3568 1.08348 26.3835L10.3968 35.6968C11.4235 36.7235 13.0368 36.7235 14.0635 35.6968C14.5401 35.2202 14.8335 34.5602 14.8335 33.8635V24.5502L17.4001 27.1168C18.8301 28.5468 21.1401 28.5468 22.5701 27.1168L29.0968 33.6435L5.81348 56.9268C3.65014 59.0902 3.65014 62.5735 5.81348 64.7002C7.97681 66.8635 11.4601 66.8635 13.5868 64.7002L51.4268 26.8968C52.7835 27.3735 54.2135 27.6668 55.7168 27.6668Z"
        fill={hexColor}
      />
    </svg>
  </figure>
)

ProcessIcon.propTypes = {
  className: PropTypes.string.isRequired,
  hexColor: PropTypes.string,
}

ProcessIcon.defaultProps = {
  hexColor: '#071A29',
}

export default ProcessIcon
