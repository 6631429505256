import React, { useMemo } from 'react'
import ReactPlayer from 'react-player'
import PropTypes from 'prop-types'
import {
  WarningFilled,
  CheckCircleFilled,
  InfoCircleFilled,
} from '@ant-design/icons'

import { UploadModalMessage, UploadModalWrapper } from './UploadModalBody.style'
import { MAX_VIDEO_DURATION } from '../../../../../Constants'

const UploadModalBody = ({ fileObject, videoDuration }) => {
  const videoURL = useMemo(
    () => URL.createObjectURL(fileObject?.originFileObj),
    []
  )

  const recommendedDuration = Math.ceil(MAX_VIDEO_DURATION / 2)

  const renderMessage = () => {
    const roundedDuration = Math.ceil(videoDuration)
    if (roundedDuration > MAX_VIDEO_DURATION) {
      return (
        <UploadModalMessage>
          <WarningFilled className="message-icon message-icon--warn" />
          <p>El video es demasiado grande, podría tardar un poco</p>
        </UploadModalMessage>
      )
    }

    if (roundedDuration > recommendedDuration) {
      return (
        <UploadModalMessage>
          <InfoCircleFilled className="message-icon message-icon--info" />
          <p>{`Siempre procura que tus videos duren menos de ${recommendedDuration} segundos`}</p>
        </UploadModalMessage>
      )
    }

    return (
      <UploadModalMessage>
        <CheckCircleFilled className="message-icon message-icon--correct" />
        <p>¡Excelente! El tiempo de tu video es perfecto</p>
      </UploadModalMessage>
    )
  }

  return (
    <>
      {fileObject.originFileObj && (
        <UploadModalWrapper>
          {renderMessage()}
          <div className="thumbnail">
            <ReactPlayer
              url={videoURL}
              width="100%"
              height="100%"
              playing={false}
              controls={false}
              style={{ objectFit: 'cover' }}
              muted
            />
          </div>
        </UploadModalWrapper>
      )}
    </>
  )
}

UploadModalBody.propTypes = {
  fileObject: PropTypes.shape({
    type: PropTypes.string,
    percent: PropTypes.number,
    name: PropTypes.string,
    originFileObj: PropTypes.object.isRequired,
  }).isRequired,
  videoDuration: PropTypes.number.isRequired,
}

export default UploadModalBody
