// eslint-disable-next-line import/prefer-default-export
export const Host = process.env.REACT_APP_API_URL
export const MarketplaceService = process.env.REACT_APP_MARKETPLACE_API_URL
export const RegisterEnrollmentURL =
  process.env.REACT_APP_REGISTER_ENROLLMENT_URL
export const ClientsURL = process.env.REACT_APP_TT_CLIENTS_API_URL
export const ClientsUIURL = process.env.REACT_APP_TT_CLIENTS_UI_URL
export const SupportPhoneNumber = process.env.REACT_APP_TT_SUPPORT_PHONE_NUMBER
export const SparePartsPhoneNumber = process.env.REACT_APP_TT_SPARE_PARTS_PHONE_NUMBER
export const MAX_VIDEO_DURATION =
  process.env.REACT_APP_VIDEOS_MAX_DURATION || 60

export const createReceptionSteps = {
  VEHICLE: 1,
  CLIENT: 2,
  PHOTOS: 3,
  VEHICLE_GAS_STATE: 4,
  VEHICLE_ODOMETER_STATE: 5,
  LAST_WORK_ORDERS: 6,
  PACKAGES: 7,
  SERVICES_PRODUCTS: 8,
  OBSERVATIONS: 9,
  RECEPTION_SUMMARY: 10,
}

export const createReceptionSubSteps = {
  ADD_VEHICLE: 1,
  ADD_CLIENT: 2,
  ADD_CONTACT: 3,
}

export const createVehicleSteps = {
  VEHICLE_PLATE: 1,
  VEHICLE_MODEL: 2,
  VEHICLE_DETAILS: 3,
}
export const carColors = [
  '#FFFFFF', // Blanco
  '#000000', // Negro
  '#C0C0C0', // Plateado
  '#808080', // Gris
  '#FF0000', // Rojo
  '#0000FF', // Azul
  '#008000', // Verde
  '#ffeb3b', // Amarillo
  '#A76400', // Café
  '#FFA500', // Naranja
  '#8442AC', // Morado
  '#A3D2F4', // Celeste
  '#008080', // Verde Azulado
  '#800000', // Café Rojizo
  '#FF1493', // Rosa
  '#D7B500', // Dorado
  '#5DD770', // Verde Brillante
]

export const vehicleTypeNames = {
  CAR: 'AUTOMÓVIL',
  MINIBUS: 'MICROBÚS',
  VAN: 'PANEL',
  BUS: 'AUTOBÚS',
  TRUCK: 'CAMIÓN',
  MOTORCYCLE: 'MOTOCICLETA',
  ATV: 'CUADRIMOTO',
  TRICIMOTO: 'TRICIMOTO',
  PICK_UP: 'PICK UP',
  TRAILER: 'REMOLQUE',
}

export const measureUnits = {
  km: 'Kilómetros',
  mile: 'Millas',
}

export const multimediaTabsIds = {
  PHOTOS: 'photos',
  VIDEOS: 'videos',
}

export const multimediaTypes = {
  IMAGE: 'image',
  VIDEO: 'video'
}