import _ from 'lodash'

import {
  GET_VEHICLES,
  ADD_VEHICLE,
  UPDATE_VEHICLE,
  DELETE_VEHICLE,
} from '../actionTypes/VehicleTypes'

export default (
  state = {
    vehicles: {},
  },
  action
) => {
  switch (action.type) {
    case GET_VEHICLES: {
      return {
        ...state,
        vehicles: action.payload,
      }
    }
    case ADD_VEHICLE: {
      return {
        ...state,
        vehicles: {
          ...state.vehicles,
          [action.payload.vehicle_id]: action.payload,
        },
      }
    }
    case UPDATE_VEHICLE: {
      return {
        ...state,
        vehicles: {
          ...state.vehicles,
          [action.payload.vehicle_id]: action.payload,
        },
      }
    }
    case DELETE_VEHICLE: {
      const newState = _.cloneDeep(state)
      delete newState.vehicles[action.payload.vehicle_id]
      return newState
    }

    default: {
      return state
    }
  }
}
