/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { PropTypes } from 'prop-types'

import PreviousNextButton from '../../../../buttons/PreviousNextButton/PreviousNextButton'
import { useAddReceptionContext } from '../../../../../contexts/AddReceptionContext'
import { addReceptionActions } from '../../../../../reducers/AddReceptionReducer'
import GradientTextComponent from '../../../Common/GradientText.js/GradientText'
import NumberFormControl from '../../../Common/NumberFormControl/NumberFormControl'
import { measureUnits } from '../../../../../Constants'
import { OdometerInputWrapper } from './OdometerStep.style'
import { getNextButtonText } from '../../../../../Helpers'

const OdometerStep = ({ handleNext, handlePrevious }) => {
  const { state, dispatch } = useAddReceptionContext()
  const {
    selectedVehicle,
    vehicleState: { vehicleOdometer },
    isEdit,
  } = state

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      vehicleOdometer: vehicleOdometer || '',
    },
  })
  const {
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = methods

  const odometer = watch('vehicleOdometer')
  const isOdometerValid = !odometer || (isValid && odometer >= 0)
  const isSkipButtonStyle = isEdit ? false : !odometer

  const navigateToPackages = () => {
    handleNext()
  }

  const onSubmit = (data) => {
    if (isOdometerValid) {
      dispatch({
        type: addReceptionActions.SET_VEHICLE_STATE,
        payload: { vehicleOdometer: data.vehicleOdometer },
      })
      navigateToPackages()
    }
  }

  const getMeasureName = () => {
    return selectedVehicle?.measureUnit === measureUnits.mile
      ? 'millaje'
      : 'kilometraje'
  }

  return (
    <>
      <main className="new-reception__main">
        <div className="new-reception__main__question">
          <GradientTextComponent
            text={`¿Cuál es el ${getMeasureName()} actual?`}
          />
        </div>
        <div className="new-reception__main__content">
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <OdometerInputWrapper>
                <NumberFormControl
                  label=""
                  minValue={0}
                  placeholder="Ingresa un valor"
                  isAutoFocus
                  name="vehicleOdometer"
                  error={errors?.vehicleOdometer?.message}
                />
                <p>/ {selectedVehicle?.measureUnit}</p>
              </OdometerInputWrapper>
            </form>
          </FormProvider>
        </div>
      </main>
      <div className="new-reception__navigation-buttons">
        <PreviousNextButton isPrevious onClickHandler={handlePrevious} />
        <PreviousNextButton
          isPrevious={false}
          isDisabled={!isOdometerValid}
          nextText={getNextButtonText(odometer, isEdit)}
          isSkip={isSkipButtonStyle}
          onClickHandler={handleSubmit(onSubmit)}
        />
      </div>
    </>
  )
}

export default OdometerStep

OdometerStep.propTypes = {
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
}
