import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Row,
  FormGroup,
  FormControl,
  Col,
  InputGroup,
  Glyphicon,
} from 'react-bootstrap'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

import { logIn } from '../../../actions/AppAction'
import logo from './logo-light.png'
import BREAK_POINTS from '../../../global/css/breakPoints'
import { LoginLoader } from '../Common/LoginLoader'
import { RegisterEnrollmentURL } from '../../../Constants'

class Login extends Component {
  state = {
    validFields: {
      username: null,
      password: null,
    },
    username: '',
    password: '',
    loading: false,
    errorMessage: '',
    passwordShown: false,
  }

  UNSAFE_componentWillMount() {
    const { logged, history } = this.props
    if (logged) {
      history.push('/')
    }
  }

  handleChange = (e) => {
    const { name } = e.target
    let { value } = e.target
    if (name === 'username') {
      value = value.toLowerCase()
    }
    this.setState({
      [name]: value,
      errorMessage: '',
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.setState({
      loading: true,
    })
    const { username, password } = this.state
    const { signIn, history, setStartTokenInterval } = this.props
    signIn(username, password)
      .then(() => {
        history.push('/workOrders')
        this.setState({
          loading: false,
        })
        setStartTokenInterval(true)
      })
      .catch(() => {
        this.setState({
          loading: false,
          validFields: {
            username: null,
            password: null,
          },
          errorMessage: 'Credenciales incorrectas',
        })
      })
  }

  togglePassword = (e) => {
    e.preventDefault()
    this.setState((prevState) => ({
      passwordShown: !prevState.passwordShown,
    }))
  }

  componentWillUmnount() {
    this.mounted = false
  }

  render() {
    const {
      validFields,
      username,
      password,
      loading,
      errorMessage,
      passwordShown,
    } = this.state

    return (
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} md={4} lg={4} xl={3} className="form">
            <Row className="form__logo">
              <Col xs={12} className="img-container">
                <NavLink
                  to={{ pathname: RegisterEnrollmentURL }}
                  target="_blank"
                >
                  <img alt="Das logo" src={logo} />
                </NavLink>
              </Col>
            </Row>
            <Row className="form__form">
              <form onSubmit={this.handleSubmit}>
                <Row>
                  <Col xs={12}>
                    <FormGroup
                      valisationstate={validFields.username}
                      className="flat-form-input"
                    >
                      <FormControl
                        type="text"
                        name="username"
                        value={username}
                        onChange={this.handleChange}
                        placeholder="Usuario"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12}>
                    <FormGroup className="flat-form-input login-form form-input-addon">
                      <InputGroup>
                        <FormControl
                          name="password"
                          type={passwordShown ? 'text' : 'password'}
                          value={password}
                          onChange={this.handleChange}
                          placeholder="Contraseña"
                          required
                        />
                        <InputGroup.Addon onClick={this.togglePassword}>
                          <Glyphicon
                            glyph={passwordShown ? 'eye-open' : 'eye-close'}
                          />
                        </InputGroup.Addon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <button type="submit" id="submit" hidden />
              </form>
            </Row>
            <Row className="form__reset form__buttons">
              <Col xs={12}>
                {loading ? (
                  <LoginLoader />
                ) : (
                  <button
                    type="button"
                    className="button primary-bg-color login-btn"
                    onClick={() => document.getElementById('submit').click()}
                  >
                    Iniciar sesión
                  </button>
                )}
              </Col>
            </Row>
            <Row className="form__buttons form__reset">
              <Col xs={12}>
                <NavLink to="/request-reset-password">
                  ¿Olvidaste tu contraseña? <br /> Da clic aquí
                </NavLink>
              </Col>
              <Col xs={12}>
                <a
                  href={`${RegisterEnrollmentURL}/enrollment`}
                  rel="noreferrer"
                  target="_blank"
                  className="button tertiary-bg-color login-btn-inverse"
                >
                  Registrate
                </a>
              </Col>
            </Row>
            <Row className="form__response">
              <Col xs={12}>
                <span>{errorMessage}</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

const Container = styled.div`
  height: 100%;
  background: rgb(215, 11, 69);
  background: linear-gradient(
    153deg,
    rgb(215, 11, 69) 0%,
    rgb(202, 15, 75) 30%,
    rgb(110, 44, 121) 77%,
    rgb(69, 57, 141) 92%
  );

  .row {
    margin: 0px;
  }

  .justify-content-center {
    display: flex;
    width: 100%;
    margin: 0 auto;
    justify-content: center;
    min-height: 100vh;
    height: 100%;
  }

  .flat-form-input {
    .form-control {
      margin-bottom: 4rem;
      border-bottom-left-radius: 20px;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      font-family: 'Gotham-Book', sans-serif;
      font-style: italic;
      height: 48px;
      padding: 0 20px;
      font-size: 2rem;
    }
  }

  .flat-form-input.form-input-addon {
    .input-group {
      display: flex;
      align-items: center;
    }
    .form-control {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
    .input-group-addon {
      display: flex;
      margin-top: -10px;
      border-bottom-right-radius: 20px;
      border-top-right-radius: 20px;
      border-bottom: 1px solid #000;
      align-items: center;
      justify-content: center;
      height: 48px;
      background: #ffffff;
      width: 15%;
      border: 0;
      border-bottom: 1px solid var(--primary-bg-color);
    }
  }

  .img-container {
    text-align: center;
    img {
      width: 270px !important;
    }
  }

  .message {
    background-color: var(--primary-bg-color);
    color: var(--tertiary-text-color);
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 3rem;

    @media (${BREAK_POINTS.TABLET}) {
      padding: 10rem 0rem;
    }

    @media (${BREAK_POINTS.DESKTOP}) {
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 4rem;
    }

    @media (${BREAK_POINTS.DESKTOP_EXTRA_LARGE}) {
      font-size: 5rem;
    }
  }

  .form {
    margin: 0px;

    @media (${BREAK_POINTS.TABLET}) {
      padding: 0rem 10rem;
    }

    @media (${BREAK_POINTS.DESKTOP}) {
      padding: 0rem 3rem;
    }

    @media (${BREAK_POINTS.DESKTOP_EXTRA_LARGE}) {
      padding: 0rem 7rem;
    }

    &__logo {
      padding: 6rem 0rem;

      @media (${BREAK_POINTS.TABLET}) {
        padding: 8rem 0rem;
      }

      @media (${BREAK_POINTS.DESKTOP}) {
        padding: 10rem 0rem;
      }

      div[class^='col-'] {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      img {
        width: 10rem;

        @media (${BREAK_POINTS.TABLET}) {
          width: 12rem;
        }

        @media (${BREAK_POINTS.DESKTOP}) {
          width: 10rem;
        }

        @media (${BREAK_POINTS.DESKTOP_LARGE}) {
          width: 12rem;
        }

        @media (${BREAK_POINTS.DESKTOP_EXTRA_LARGE}) {
          width: 15rem;
        }
      }
    }
    &__reset {
      text-align: center;
      margin: 5rem 0rem;
      font-size: 2rem;
      a {
        color: var(--white);
        text-decoration: none;
        margin: 20px 0;
        font-family: 'Gotham-Book', sans-serif;
        font-family: 'Gotham-Book', sans-serif;
        padding: 12px 0 !important;
      }
    }

    &__buttons {
      .button {
        margin-bottom: 2rem;
        font-weight: 400;
        color: #fff;
        border-radius: 27px;
        height: 53px;
        padding: 0;
        font-family: 'Gotham-Book', sans-serif;
      }
      .login-btn {
        background-color: #1e1e40;
      }
      .login-btn-inverse {
        background: transparent;
        border: 1px solid var(--white);
      }
    }

    &__response {
      text-align: center;
      color: var(--error-text-color);
      font-weight: 400;

      span {
        text-decoration: underline;
      }
    }
  }
`

const mapStateToProps = (store) => ({
  logged: store.AppReducer.logged,
})

const mapDispatchToProps = (dispatch) => ({
  signIn(user, pass) {
    return dispatch(logIn(user, pass))
  },
})

Login.propTypes = {
  logged: PropTypes.bool.isRequired,
  signIn: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  setStartTokenInterval: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
