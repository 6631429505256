import React from 'react'
import { PropTypes } from 'prop-types'

import PreviousNextButton from '../../../../buttons/PreviousNextButton/PreviousNextButton'
import { useAddReceptionContext } from '../../../../../contexts/AddReceptionContext'
import { addReceptionActions } from '../../../../../reducers/AddReceptionReducer'
import GradientTextComponent from '../../../Common/GradientText.js/GradientText'
import GasLevelSlider from '../../../Common/GasLevelSlider/GasLevelSlider'
import InfoDisclaimer from '../../../Common/InfoDisclaimer/InfoDisclaimer'
import { GasLevelWrapper } from './GasLevelStep.style'
import { getNextButtonText } from '../../../../../Helpers'

const GasLevelStep = ({ handleNext, handlePrevious }) => {
  const { state, dispatch } = useAddReceptionContext()
  const {
    vehicleState: { vehicleGasLevel },
    isEdit,
  } = state

  const isGasLevelValid = Boolean(vehicleGasLevel) || vehicleGasLevel === 0
  const isSkipButtonStyle = isEdit ? false : !vehicleGasLevel

  const handleGasLevelChange = (value) => {
    dispatch({
      type: addReceptionActions.SET_VEHICLE_STATE,
      payload: { vehicleGasLevel: value },
    })
  }

  const navigateToOdometer = () => {
    handleNext()
  }

  return (
    <>
      <main className="new-reception__main">
        <div className="new-reception__main__question">
          <GradientTextComponent text="Combustible" />
        </div>
        <div className="new-reception__main__content">
          <GasLevelWrapper>
            <GradientTextComponent
              text="¿Cuál es la cantidad de combustible actual?"
              style={{ fontSize: '16px', marginBottom: '1rem' }}
            />
            <InfoDisclaimer message="Es importante que registres el valor real para evitar algún disgusto con el cliente. Para registrar el valor puedes desplazar la barra hacia la izquierda y derecha o ingresar el porcentaje" />
            <GasLevelSlider
              value={vehicleGasLevel}
              onValueChange={handleGasLevelChange}
            />
          </GasLevelWrapper>
        </div>
      </main>
      <div className="new-reception__navigation-buttons">
        <PreviousNextButton isPrevious onClickHandler={handlePrevious} />
        <PreviousNextButton
          nextText={getNextButtonText(vehicleGasLevel, isEdit)}
          isSkip={isSkipButtonStyle}
          isPrevious={false}
          isDisabled={!isGasLevelValid}
          onClickHandler={navigateToOdometer}
        />
      </div>
    </>
  )
}

export default GasLevelStep

GasLevelStep.propTypes = {
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
}
