import PropTypes from 'prop-types'

const AddPhotosModalTypes = {
  isOpen: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setTab: PropTypes.func.isRequired,
  setOptionsModal: PropTypes.func,
  receptionId: PropTypes.number.isRequired,
  workshopId: PropTypes.number.isRequired,
}

export default AddPhotosModalTypes
