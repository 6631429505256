import React from 'react'
import { PropTypes } from 'prop-types'
import { WarningDisclaimerAnimatedWrapper } from './WarningDisclaimerAnimated.styles'

const WarningDisclaimerAnimated = ({ message }) => {
  return (
    <WarningDisclaimerAnimatedWrapper className="info-disclaimer">
      <i className="fas fa-exclamation-triangle" />
      <p dangerouslySetInnerHTML={{ __html: message }} />
    </WarningDisclaimerAnimatedWrapper>
  )
}

WarningDisclaimerAnimated.propTypes = {
  message: PropTypes.string.isRequired,
}
export default WarningDisclaimerAnimated
