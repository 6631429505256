import React from 'react'
import PhoneInput from 'react-phone-number-input'
import PhoneInputPropTypes from '../PhoneInput/PhoneInput.types'
import countryCodeToLetters from '../../../Utilities/CountryCodeToLetters'
import { formatInternationalNumber } from '../../../Utilities/formatPhone'

const PhoneInputStandalone = ({
    phone,
    onPhoneChange,
    isError,
    required = false,
    countryCode,
}) => {
    const areaCode = countryCodeToLetters(countryCode)
    const phoneNumber = phone ? formatInternationalNumber(phone, countryCode) : ''

    return (
        <>
            <PhoneInput
                initialValueFormat="national"
                className="phone-input"
                international={false}
                name="employee_phone"
                addInternationalOption={false}
                defaultCountry={areaCode}
                value={phoneNumber}
                onChange={onPhoneChange}
                countries={['SV', 'CO', 'VE', 'MX', 'US', 'GT']}
                required={required}
            />
            {isError && (
                <span style={{ color: '#a94442' }}>
                    Ingresa un número de teléfono válido
                </span>
            )}
        </>
    )
}

PhoneInputStandalone.propTypes = PhoneInputPropTypes

export default PhoneInputStandalone
