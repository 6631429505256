import React, { Component } from 'react'
import _ from 'lodash'
import moment from 'moment'
import 'moment/locale/es'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import ReactTooltip from 'react-tooltip'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { WorkOrderCard } from './WorkOrderCard/WorkOrderCard'
import { CardsContainer } from './Components'
import {
  refreshWorkOrder,
  setWorkOrderToAdministrate,
  updateWorkOrderProcessTimeTracker,
  updateWorkOrderTimeStatus,
} from '../../../../actions/WorkOrderAction'
import { NotificationHandler } from '../../../../Helpers'
import { sendChatMessage } from '../../../../api/apiService'

moment.locale('es')

const Label = ({ text }) => <b>{text}</b>

Label.propTypes = {
  text: PropTypes.string.isRequired,
}

class WorkOrderProcess extends Component {
  state = {
    loadingBoxes: {},
  }

  setWorkOrderToAdministrate = (workOrder, isAddModalOpen) => {
    const { history, setWorkOrderToAdministrateAction } = this.props
    setWorkOrderToAdministrateAction(workOrder, isAddModalOpen)
    history.push('/administrateWorkOrder')
  }

  isAdministrationButtonEnable = (mechanic) => {
    const {
      currentUser: { rol_id: current_rol_id, employee_id: current_employee_id },
    } = this.props
    const { rol_id: mechanic_rol_id, employee_id: assigned_mechanic_id } =
      mechanic

    let isButtonDisabled = true

    if (current_rol_id === 1) {
      isButtonDisabled = false
    } else if (
      mechanic_rol_id === 3 &&
      assigned_mechanic_id === current_employee_id
    ) {
      isButtonDisabled = false
    }

    return isButtonDisabled
  }

  refreshWorkOrder = (workOrder, updateProgress) => {
    const { loadingBoxes } = this.state
    this.setState({
      loadingBoxes: {
        ...loadingBoxes,
        [workOrder.work_order_id]: true,
      },
    })
    const { refreshActualWorkOrder } = this.props
    refreshActualWorkOrder(workOrder)
      .then(() => {
        this.setState({
          loadingBoxes: {
            ...loadingBoxes,
            [workOrder.work_order_id]: false,
          },
        })
        updateProgress()
      })
      .catch(() => {
        this.setState({
          loadingBoxes: {
            ...loadingBoxes,
            [workOrder.work_order_id]: false,
          },
        })
      })
  }

  startTimeTrackerProcess = async (workOrder) => {
    const { timeTracker } = workOrder
    const orderedTimeTracker = _.values(
      _.orderBy(timeTracker, ['time_tracker_sequence'], ['asc'])
    )
    const processes = orderedTimeTracker.filter((time) => time.process)

    const nextInactiveProcess = processes.find(
      (process) => process.process_status === 0
    )

    if (nextInactiveProcess === undefined || nextInactiveProcess === null) {
      return
    }

    const newNextInactiveProcess = {
      ...nextInactiveProcess,
      process_status: 1,
    }

    const { updateWorkOrderProcessTimeTrackerAction } = this.props

    await updateWorkOrderProcessTimeTrackerAction(newNextInactiveProcess)
  }

  startWorkOrder = async (workOrder) => {
    try {
      this.startTimeTrackerProcess(workOrder)

      const { updateWorkOrderTimeStatusAction, currentUser } = this.props

      updateWorkOrderTimeStatusAction({
        work_order_id: workOrder.work_order_id,
        work_order_start: moment(),
        work_order_status_id: 1,
      })

      // Sends message only if order is not started
      if (workOrder.work_order_status_id === 4) {
        await sendChatMessage(
          '¡La orden de trabajo ha sido iniciada!',
          currentUser.employee_id,
          workOrder.work_order_id
        )
      }
    } catch (error) {
      NotificationHandler('Error', 'error', error.message || 'Error')
    }
  }

  render() {
    const { loadingBoxes } = this.state
    const {
      workOrders,
      updateWorkOrder,
      deleteWorkOrder,
      extraTime,
      updateWorkOrderProcessTimeTrackerAction,
    } = this.props
    return (
      <CardsContainer>
        <TransitionGroup className="WorkOrdersProcesses">
          {_.map(workOrders, (workOrder) => {
            return (
              workOrder.mechanic?.employee_id && (
                <CSSTransition
                  key={workOrder.work_order_id}
                  timeout={700}
                  classNames="WorkOrderProcess"
                >
                  <WorkOrderCard
                    workOrder={workOrder}
                    loadingBoxes={loadingBoxes}
                    extraTime={extraTime}
                    setWorkOrderToAdministrate={this.setWorkOrderToAdministrate}
                    updateWorkOrderProcessTimeTrackerAction={
                      updateWorkOrderProcessTimeTrackerAction
                    }
                    isAdministrationButtonEnable={
                      this.isAdministrationButtonEnable
                    }
                    updateWorkOrder={updateWorkOrder}
                    deleteWorkOrder={deleteWorkOrder}
                    startWorkOrder={this.startWorkOrder}
                  />
                </CSSTransition>
              )
            )
          })}
        </TransitionGroup>
        <ReactTooltip html />
      </CardsContainer>
    )
  }
}

WorkOrderProcess.propTypes = {
  refreshActualWorkOrder: PropTypes.func.isRequired,
  setWorkOrderToAdministrateAction: PropTypes.func.isRequired,
  workOrders: PropTypes.arrayOf(PropTypes.any).isRequired,
  extraTime: PropTypes.arrayOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  currentUser: PropTypes.objectOf(PropTypes.any).isRequired,
  updateWorkOrder: PropTypes.func.isRequired,
  deleteWorkOrder: PropTypes.func.isRequired,
  updateWorkOrderProcessTimeTrackerAction: PropTypes.func.isRequired,
  updateWorkOrderTimeStatusAction: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  refreshActualWorkOrder(workOrder) {
    return dispatch(refreshWorkOrder(workOrder))
  },
  setWorkOrderToAdministrateAction(workOrder, isAddModalOpen = false) {
    return dispatch(
      setWorkOrderToAdministrate({ ...workOrder, isAddModalOpen })
    )
  },
  updateWorkOrderProcessTimeTrackerAction(timeTracker) {
    return dispatch(updateWorkOrderProcessTimeTracker(timeTracker))
  },
  updateWorkOrderTimeStatusAction(
    workOrder,
    updateWorkOrderRealTime,
    initiate
  ) {
    return dispatch(
      updateWorkOrderTimeStatus(workOrder, updateWorkOrderRealTime, initiate)
    )
  },
})

export default connect(null, mapDispatchToProps)(WorkOrderProcess)
