import React, { useState, useRef, useMemo, useEffect } from 'react'
import ReactPlayer from 'react-player'
import PropTypes from 'prop-types'

import EmptyState from '../EmptyState/EmptyState'
import { AppLoader } from '../AppLoader'
import { LeftArrowIcon } from '../../../icons/LeftArrowIcon'
import { RightArrowIcon } from '../../../icons/RightArrowIcon'
import {
  ThumbnailWrapper,
  ThumbnailsWrapper,
  VideoGalleryContainer,
  VideoPreviewWrapper,
} from './VideoGallery.style'

const VideoGallery = ({ videos, loading, newVideoAdded }) => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0)
  const thumbnailsRef = useRef(null)

  const mostRecentVideoIndex = useMemo(() => {
    return videos.reduce((maxIndex, video, currentIndex) => {
      return video.receptionPhotoId > videos[maxIndex].receptionPhotoId
        ? currentIndex
        : maxIndex
    }, 0)
  }, [videos])

  useEffect(() => {
    setCurrentVideoIndex(newVideoAdded ? mostRecentVideoIndex : 0)
  }, [mostRecentVideoIndex])

  if (loading) {
    return <AppLoader />
  }

  const handleThumbnailClick = (index) => {
    setCurrentVideoIndex(index)
  }

  const handlePrevVideo = () => {
    setCurrentVideoIndex((prevIndex) =>
      prevIndex === 0 ? videos.length - 1 : prevIndex - 1
    )
  }

  const handleNextVideo = () => {
    setCurrentVideoIndex((prevIndex) =>
      prevIndex === videos.length - 1 ? 0 : prevIndex + 1
    )
  }

  const thumbnailWidth = videos.length <= 4 ? '75px' : '65px'
  const thumbnailHeight = videos.length <= 4 ? '75px' : '70px'

  return videos.length ? (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <VideoPreviewWrapper>
        <button
          type="button"
          onClick={handlePrevVideo}
          className="image-gallery-icon"
        >
          <LeftArrowIcon />
        </button>
        <ReactPlayer
          url={videos[currentVideoIndex].receptionPhotoUrl}
          playing
          muted
          controls
          light={false}
          preload="metadata"
          width="100%"
          height="400px"
        />
        <button
          type="button"
          onClick={handleNextVideo}
          className="image-gallery-icon"
        >
          <RightArrowIcon />
        </button>
      </VideoPreviewWrapper>
      <VideoGalleryContainer ref={thumbnailsRef}>
        <ThumbnailsWrapper currentIndex={currentVideoIndex}>
          {videos.map((video, index) => (
            <ThumbnailWrapper
              key={video.receptionPhotoId}
              active={index === currentVideoIndex}
              index={index}
              currentIndex={currentVideoIndex}
              totalVideos={videos.length}
              onClick={() => handleThumbnailClick(index)}
            >
              <ReactPlayer
                url={video.receptionPhotoUrl}
                playing={false}
                controls={false}
                className="video-wrapper"
                width={thumbnailWidth}
                height={thumbnailHeight}
                style={{ objectFit: 'cover' }}
              />
            </ThumbnailWrapper>
          ))}
        </ThumbnailsWrapper>
      </VideoGalleryContainer>
    </div>
  ) : (
    <EmptyState text="Sin videos registrados" />
  )
}

VideoGallery.propTypes = {
  loading: PropTypes.bool.isRequired,
  videos: PropTypes.array.isRequired,
  newVideoAdded: PropTypes.bool,
}

VideoGallery.defaultProps = {
  newVideoAdded: false,
}

export default VideoGallery
