import React from 'react'
import { EyeOutlined } from '@ant-design/icons'
import ReactPlayer from 'react-player'
import PropTypes from 'prop-types'

const UploadedVideoCard = ({ uploadedVideo, handleVideoPreview }) => {
  return (
    <>
      <div
        className="ant-upload-list-item ant-upload-list-item-done"
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <span className="ant-upload-list-item-actions">
          <button
            type="button"
            onClick={() => handleVideoPreview(uploadedVideo)}
            className="ant-btn ant-btn-text ant-btn-sm ant-btn-icon-only ant-upload-list-item-action anticon"
            style={{ backgroundColor: 'transparent' }}
          >
            <EyeOutlined />
          </button>
        </span>
        <ReactPlayer
          url={uploadedVideo.url}
          width="100%"
          height="100%"
          playing={false}
          controls={false}
          style={{ objectFit: 'cover', overflow: 'hidden' }}
          muted
        />
      </div>
    </>
  )
}

UploadedVideoCard.propTypes = {
  uploadedVideo: PropTypes.object.isRequired,
  handleVideoPreview: PropTypes.func.isRequired,
}

export default UploadedVideoCard
