import styled from 'styled-components'
import BREAK_POINTS from '../../../../../global/css/breakPoints'

export const PackageStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  margin-top: 12px;

  @media (${BREAK_POINTS.TABLET_LARGE}) {
    margin-top: 0;
  }

  .loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }
  .details {
    margin: 24px 0;

    th::before {
      width: unset !important;
    }

    td {
      .edit-btn {
        background: unset;
        border: unset;
        i {
          color: #1e1e40;
        }
      }
    }
    display: flex;
    flex-direction: column;
    gap: 20px;

    h2 {
      font-size: 1em;
      font-family: inherit;
      margin: unset;
    }

    h3 {
      font-size: 1.2em;
      font-family: inherit;
      margin: unset;
    }
    .summary {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .discount-total {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 1.2em;
        padding-bottom: 3px;

        .discountInputWrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .discount {
            display: flex;
            flex-direction: row;
            margin-left: auto;
            justify-content: flex-end;
            align-items: center;
            gap: 1em;

            margin: unset;

            font-weight: 500;
            font-size: 1.4rem;
            span {
              border-color: #acaeb0;
            }
          }
        }

        .total {
          text-align: right;
          font-size: 1.6rem;
        }
      }
    }
  }
`
