import axios from 'axios'
import _ from 'lodash'

import {
  GET_MODELS,
  ADD_MODEL,
  UPDATE_MODEL,
  DELETE_MODEL,
} from '../actionTypes/modelTypes'
import { NotificationHandler } from '../Helpers'
import { createModel } from '../api/newReceptionService'

const getModelsAction = (payload) => ({
  type: GET_MODELS,
  payload,
})

const addModelAction = (payload) => ({
  type: ADD_MODEL,
  payload,
})

const updateModelAction = (payload) => ({
  type: UPDATE_MODEL,
  payload,
})

const deleteModelAction = (payload) => ({
  type: DELETE_MODEL,
  payload,
})

const getModels = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const registersSize = _.size(getState().ModelReducer.models)
    if (registersSize === 0) {
      axios
        .get('/api/models')
        .then((response) => {
          if (response.status === 200 && response.status < 300) {
            const items = _.keyBy(response.data.data, 'model_id')
            dispatch(getModelsAction(items))
            resolve()
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al obtener modelos',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al obtener modelos',
            error
          )
        })
    } else {
      resolve()
    }
  })

const addModel = (model) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { workshop_id } = getState().AppReducer.currentUser
    axios
      .post('/api/models', { ...model, workshop_id })
      .then((response) => {
        dispatch(addModelAction(response.data.data))
        NotificationHandler('Done', 'success', 'Modelo agregado')
        resolve(response)
      })
      .catch((error) => {
        reject()
        NotificationHandler(
          'Error',
          'error',
          'Error al agregar el modelo',
          error
        )
      })
  })

const addReceptionModel = (model) => async (dispatch) => {
  try {
    const response = await createModel(model)

    dispatch(addModelAction(response.data))

    NotificationHandler(
      'Modelo agregado',
      'success',
      `El modelo se guardó correctamente`
    )
    return response.data
  } catch (e) {
    NotificationHandler('Error', 'error', 'Error al agregar el modelo')
    return e
  }
}

const updateModel = (model) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (model.model_id) {
      axios
        .put(`/api/models/${model.model_id}`, model)
        .then((response) => {
          if (response.status === 200 && response.status < 300) {
            dispatch(updateModelAction(response.data.data))
            NotificationHandler('Done', 'success', 'Modelo actualizado')
            resolve(response)
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al actualizar el modelo',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al actualizar el modelo',
            error
          )
        })
    } else {
      reject()
      NotificationHandler(
        'Error',
        'error',
        `No se proporcionó id a: ${model.model_name}`
      )
    }
  })

const deleteModel = (model) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (model.model_id) {
      axios
        .delete(`/api/models/${model.model_id}`)
        .then((response) => {
          if (!response.data.error) {
            dispatch(deleteModelAction(response.data.data))
            resolve()
            NotificationHandler('Done', 'success', 'Modelo eliminado')
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al eliminar el modelo',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al eliminar el modelo',
            error
          )
        })
    } else {
      reject()
      NotificationHandler(
        'Error',
        'error',
        `No se proporcionó id para: ${model.model_name}`
      )
    }
  })

export { getModels, addModel, addReceptionModel, updateModel, deleteModel }
