import React, { Component } from 'react'
import {
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Col,
  Button,
  Row,
  Form,
} from 'react-bootstrap'
import PropTypes from 'prop-types'
import { SaveOutlined } from '@ant-design/icons'
import { getValidationState, showConfirmDialog } from '../../../Common'
import { NotificationHandler } from '../../../../../Helpers'


const validatePercentage = (value) => {
  if (value === '') {
    return null
  }
  if (parseFloat(value) <= 100) {
    return 'success'
  }
  return 'error'
}

class ContributorTypeModal extends Component {
  state = {
    contributor_type_name: '',
    contributor_type_retention_percentage: '',
    loading: false,
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { selectedContributor, editMode } = props
    if (editMode) {
      const { contributor_type_name, contributor_type_retention_percentage } =
        selectedContributor
      this.setState({
        contributor_type_name,
        contributor_type_retention_percentage: `${contributor_type_retention_percentage}`,
        loading: false,
      })
    } else {
      this.cleanComponent()
    }
  }

  cleanComponent = () => {
    this.setState({
      contributor_type_name: '',
      contributor_type_retention_percentage: '',
      loading: false,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const {
      selectedContributor,
      toggleModal,
      updateContributor,
      addContributor,
      editMode,
    } = this.props

    const { contributor_type_name, contributor_type_retention_percentage } =
      this.state

    const contributor = {
      contributor_type_id: selectedContributor.contributor_type_id || null,
      contributor_type_name,
      contributor_type_retention_percentage: parseFloat(
        contributor_type_retention_percentage,
        10
      ),
    }
    if (editMode) {
      if (
        validatePercentage(contributor_type_retention_percentage) === 'success'
      ) {
        toggleModal()
        showConfirmDialog(
          '¿Modificar?',
          `¿Modificar el tipo de contribuyente: ${contributor.contributor_type_name}`,
          updateContributor,
          contributor,
          () => { },
          toggleModal,
          toggleModal
        )
      } else {
        NotificationHandler('Error', 'warning', 'Debe ser un porcentaje válido')
      }
    } else if (
      validatePercentage(contributor_type_retention_percentage) === 'success' &&
      !editMode
    ) {
      addContributor(contributor)
        .then(() => {
          toggleModal()
          this.cleanComponent()
        })
        .catch(() => {
          this.setState({ loading: false })
          toggleModal()
        })
    } else {
      NotificationHandler('Error', 'warning', 'Debe ser un porcentaje válido')
    }
  }

  handleChange = (e) => {
    const { name } = e.target
    let { value } = e.target
    if (e.target.name === 'contributor_type_retention_percentage') {
      value = value.replace(/[^0-9.]/g, '')
    }
    this.setState({
      [name]: value,
    })
  }

  render() {
    const { editMode, modalOpen, toggleModal, pagePermissions } = this.props
    const {
      contributor_type_name,
      contributor_type_retention_percentage,
      loading,
    } = this.state
    let title = editMode ? 'Modificar' : 'Agregar'
    if (title === 'Modificar')
      title = pagePermissions.can_edit ? 'Modificar' : ''
    return (
      <Modal bsSize="lg" show={modalOpen} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {`${title} `}
            Tipo de contribuyente
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.handleSubmit}>
            <Row>
              <Col md={6}>
                <FormGroup
                  validationState={getValidationState(contributor_type_name)}
                >
                  <ControlLabel>Nombre: </ControlLabel>
                  <FormControl
                    name="contributor_type_name"
                    type="text"
                    value={contributor_type_name}
                    onChange={this.handleChange}
                    required
                    placeholder="Ingresar"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup
                  validationState={validatePercentage(
                    contributor_type_retention_percentage
                  )}
                >
                  <ControlLabel>Porcentaje de retención: </ControlLabel>
                  <FormControl
                    name="contributor_type_retention_percentage"
                    type="text"
                    value={contributor_type_retention_percentage}
                    onChange={this.handleChange}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button type="submit" hidden id="submitModelForm" />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={toggleModal}>Cerrar</Button>
          {!!pagePermissions.can_edit && (
            <Button
              disabled={loading}
              bsStyle="primary"
              onClick={() => {
                document.getElementById('submitModelForm').click()
              }}
              className="btn-antd-icon"
            >
              <SaveOutlined />
              {editMode ? ' Modificar' : ' Agregar'}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    )
  }
}

ContributorTypeModal.propTypes = {
  editMode: PropTypes.bool.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  addContributor: PropTypes.func.isRequired,
  updateContributor: PropTypes.func.isRequired,
  selectedContributor: PropTypes.objectOf(PropTypes.any).isRequired,
  pagePermissions: PropTypes.objectOf(PropTypes.object).isRequired,
}

export default ContributorTypeModal
