import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'

import { ClientsURL } from '../../../../Constants'
import ChatMessage from './ChatMessage'
import ChatInput from './ChatInput'
import ChatHeader from './ChatHeader'
import styles from './Chat.module.css'
import LoaderDynamicText from '../../Common/LoaderDynamicText'
/* import FloatingButton from './FloatingButton'; */

function Chat({
  orderId,
  brand,
  model,
  year,
  focused,
  setFocus,
  clientName,
  currentUserId,
}) {
  const [messages, setMessages] = useState([])
  const [currentUser, setCurrentUser] = useState(currentUserId)
  const [receiverName, setReceiverName] = useState()
  const [loading, setLoading] = useState(false)

  const messageEl = useRef(null)

  const toggleLoader = (status) => {
    setLoading(status)
  }

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axios.get(
          `${ClientsURL}/api/client/work-order-detail/${orderId}`
        )
        if (response.status === 200) {
          const { data } = response
          setCurrentUser(currentUserId || data.mechanicId)
          setMessages(data.comments ?? [])
        } else {
          console.log('Failed to fetch messages')
        }
      } catch (error) {
        console.log('Error fetching messages:', error)
      }
    }

    fetchMessages()

    const interval = setInterval(fetchMessages, 3000)

    return () => {
      clearInterval(interval)
    }
  }, [orderId, brand, model, year])

  useEffect(() => {
    setReceiverName(clientName)
    if (messageEl) {
      messageEl.current.addEventListener('DOMNodeInserted', (event) => {
        const { currentTarget: target } = event
        target.scroll({ top: target.scrollHeight, behavior: 'smooth' })
      })
    }
  }, [])

  return (
    <>
      <div className={styles['chat-wrapper']}>
        <ChatHeader receiverName={receiverName} />
        <div ref={messageEl} className={`${styles['chat-container']}`}>
          {loading && (
            <LoaderDynamicText
              textArray={['Obteniendo mensajes...', 'Cargando mensajes...']}
              colored
              interval={1}
              position="sticky"
            />
          )}

          {messages?.length > 0 ? (
            messages.map((message) => (
              <ChatMessage key={message.id} message={message} />
            ))
          ) : (
            <p>Aún no hay mensajes</p>
          )}
        </div>
        <ChatInput
          orderId={orderId}
          currentUser={currentUser}
          focused={focused}
          setFocus={setFocus}
          loading={loading} // Pass the loader state
          toggleLoader={toggleLoader} // Pass the toggleLoader function
        />
      </div>
    </>
  )
}

Chat.propTypes = {
  orderId: PropTypes.number.isRequired,
  brand: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired,
  focused: PropTypes.bool,
  setFocus: PropTypes.func,
  clientName: PropTypes.string.isRequired,
  currentUserId: PropTypes.number,
}

Chat.defaultProps = {
  focused: false,
  setFocus: () => {},
  currentUserId: 0,
}

export default Chat
