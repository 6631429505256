import axios from 'axios'
import _ from 'lodash'

import {
  GET_VEHICLE_TYPES,
  ADD_VEHICLE_TYPE,
  UPDATE_VEHICLE_TYPE,
  DELETE_VEHICLE_TYPE,
} from '../actionTypes/VehicleTypesTypes'
import { NotificationHandler } from '../Helpers'

const getVehicleTypesAction = (payload) => ({
  type: GET_VEHICLE_TYPES,
  payload,
})

const addVehicleTypeAction = (payload) => ({
  type: ADD_VEHICLE_TYPE,
  payload,
})

const updateVehicleTypeAction = (payload) => ({
  type: UPDATE_VEHICLE_TYPE,
  payload,
})

const deleteVehicleTypeAction = (payload) => ({
  type: DELETE_VEHICLE_TYPE,
  payload,
})

const getVehicleTypes = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const registersSize = _.size(getState().VehicleTypesReducer.vehicleTypes)
    if (registersSize === 0) {
      axios
        .get('/api/vehicleTypes')
        .then((response) => {
          if (!response.data.error) {
            const vehicleTypes = _.keyBy(response.data.data, 'vehicle_type_id')
            dispatch(getVehicleTypesAction(vehicleTypes))
            resolve()
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al obtener tipos de vehículos',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject(error.message)
          NotificationHandler(
            'Error',
            'error',
            'Error al obtener tipos de vehículos',
            error
          )
        })
    } else {
      resolve()
    }
  })

const addVehicleType = (vehicleType) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { workshop_id } = getState().AppReducer.currentUser
    axios
      .post('/api/vehicleTypes', { ...vehicleType, workshop_id })
      .then((response) => {
        if (!response.data.error) {
          const vehicleTypeToRedux = {
            ...response.data.data,
          }
          dispatch(addVehicleTypeAction(vehicleTypeToRedux))
          resolve(response)
          NotificationHandler('Done', 'success', 'Tipo de vehículo agregado')
        } else {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al agregar el tipo de vehículo',
            response.data.data
          )
        }
      })
      .catch((error) => {
        reject(error.message)
        NotificationHandler(
          'Error',
          'error',
          'Error al agregar el tipo de vehículo',
          error
        )
      })
  })

const updateVehicleType = (vehicleType) => (dispatch) =>
  new Promise((resolve, reject) => {
    axios
      .put(`/api/vehicleTypes/${vehicleType.id}`, vehicleType)
      .then((response) => {
        if (!response.data.error) {
          const vehicleTypeToRedux = {
            ...response.data.data,
          }
          dispatch(updateVehicleTypeAction(vehicleTypeToRedux))
          resolve(response)
          NotificationHandler('Done', 'success', 'Tipo de vehículo actualizado')
        } else {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al actualizar el tipo de vehículo',
            response.data.data
          )
        }
      })
      .catch((error) => {
        reject()
        NotificationHandler(
          'Error',
          'error',
          'Error al actualizar el tipo de vehículo',
          error
        )
      })
  })

const deleteVehicleType = (vehicleType) => (dispatch) =>
  new Promise((resolve, reject) => {
    axios
      .delete(`/api/vehicleTypes/${vehicleType.id}`)
      .then((response) => {
        if (!response.data.error) {
          dispatch(deleteVehicleTypeAction(response.data.data))
          resolve(response)
          NotificationHandler('Done', 'success', 'Tipo de vehículo eliminado')
        } else {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al eliminar el tipo de vehículo',
            response.data.data
          )
        }
      })
      .catch((error) => {
        reject()
        NotificationHandler(
          'Error',
          'error',
          'Error al eliminar el tipo de vehículo',
          error
        )
      })
  })

export { getVehicleTypes, addVehicleType, updateVehicleType, deleteVehicleType }
