import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import _ from 'lodash'

import {
  getSeries,
  addSeries,
  updateActualNumber,
} from '../../../actions/SeriesAction'
import { getReceptions } from '../../../actions/ReceptionAction'
import { addInvoice } from '../../../actions/InvoiceAction'
import ReceptionList from './ReceptionList'
import Invoice from './InvoiceForm/Invoice'
import './invoiceClasses.css'
import { ContainerHeader } from '../Common'
import { getPaymentConditions } from '../../../actions/PaymentConditionsAction'
import { setWorkOrderInvoiced } from '../../../actions/WorkOrderAction'
import BREAK_POINTS from '../../../global/css/breakPoints'

class InvoicingContainer extends Component {
  state = {
    workOrderToInvoice: {},
    showList: true,
  }

  componentDidMount() {
    const { getAllSeries, workshop } = this.props
    getAllSeries()
    getSeries()
    document.title = `${workshop.workshop_name} | Facturación`
  }

  setWorkOrderToInvoice = ({ budgets = { reception: {} }, ...workOrder }) => {
    const workOrderObj = {
      reception: budgets.reception,
      vehicle: budgets.reception.vehicle,
      ...workOrder,
    }
    this.setState({
      workOrderToInvoice: workOrderObj,
    })
  }

  resetWorkOrder = () => {
    this.setState({ workOrderToInvoice: {} })
  }

  toggleList = () => {
    const { showList } = this.state
    this.setState({ showList: !showList })
  }

  render() {
    const { workOrderToInvoice, showList } = this.state
    const {
      invoiceTypes,
      series,
      createInvoice,
      updateCurrentNumber,
      addSerie,
      currentUser,
      checkActualNumber,
      setWorkOrderByInvoiced,
      workshop,
    } = this.props
    const showInvoice = !_.isEmpty(workOrderToInvoice)
    console.log(showInvoice);
    return (
      <>
        <ContainerHeader title="Facturación" />
        <Container className="invoice-container">
          <div
            className={`invoice-reception-list-container${
              !showList ? '--hide' : ''
            }`}
          >
            <ReceptionList setWorkOrderToInvoice={this.setWorkOrderToInvoice} />
          </div>
          <div className="invoice-form">
            {showInvoice && (
              <Invoice
                showInvoice={showInvoice}
                showList={showList}
                addSerie={addSerie}
                addInvoice={createInvoice}
                updateActualNumber={updateCurrentNumber}
                workOrderToInvoice={workOrderToInvoice}
                invoiceTypes={invoiceTypes}
                series={_.values(series)}
                resetWorkOrder={this.resetWorkOrder}
                toggleList={this.toggleList}
                currentUser={currentUser}
                checkActualNumber={checkActualNumber}
                setWorkOrderInvoiced={setWorkOrderByInvoiced}
                workshop={workshop}
              />
            )}
          </div>
        </Container>
      </>
    )
  }
}

InvoicingContainer.propTypes = {
  updateCurrentNumber: PropTypes.func.isRequired,
  createInvoice: PropTypes.func.isRequired,
  getAllSeries: PropTypes.func.isRequired,
  invoiceTypes: PropTypes.objectOf(PropTypes.object),
  series: PropTypes.objectOf(PropTypes.object).isRequired,
  currentUser: PropTypes.objectOf(PropTypes.any).isRequired,
  addSerie: PropTypes.func,
  setWorkOrderByInvoiced: PropTypes.func.isRequired,
  checkActualNumber: PropTypes.func,
}

InvoicingContainer.defaultProps = {
  invoiceTypes: {},
  addSerie: () => {},
  checkActualNumber: () => {},
}

const Container = styled.div`
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #eee;

  @media (${BREAK_POINTS.TABLET}) {
    flex-direction: row;
  }

  @media (${BREAK_POINTS.DESKTOP_LARGE}) {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .invoice-reception-list-container {
    transition: all 500ms ease-in-out;
    width: 100%;
  }

  .invoice-reception-list-container--hide {
  }

  .invoice-form {
    width: 100%;

    @media (${BREAK_POINTS.TABLET}) {
      margin-left: 10px;
    }
  }
`

const mapStateToProps = (state) => {
  const { receptions } = state.ReceptionReducer
  const receptionsFinishedWorkOrders = _.filter(receptions, (reception) => {
    const [workOrder] = reception.budget.workOrder
    if (workOrder) {
      return (
        workOrder.work_order_status_id === 3 &&
        workOrder.work_order_invoiced === 0
      )
    }
    return false
  })
  return {
    receptionsFinishedWorkOrders,
    invoiceTypes: state.InvoiceTypeReducer.invoiceTypes,
    series: state.SeriesReducer.series,
    currentUser: state.AppReducer.currentUser,
    workshop: state.AppReducer.currentUser.workshop,
  }
}

const mapDispatchToProps = (dispatch) => ({
  fillRecepciones() {
    return dispatch(getReceptions())
  },
  getAllSeries() {
    dispatch(getSeries())
  },
  updateCurrentNumber(serie_id) {
    return dispatch(updateActualNumber(serie_id))
  },
  setWorkOrderByInvoiced(workOrderId) {
    return dispatch(setWorkOrderInvoiced(workOrderId))
  },
  createInvoice(invoice, details) {
    return dispatch(addInvoice(invoice, details))
  },
  addSeries(serie) {
    return dispatch(addSeries(serie))
  },
  getPaymentConditions() {
    return dispatch(getPaymentConditions())
  },
})

InvoicingContainer.propTypes = {
  workshop: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoicingContainer)
