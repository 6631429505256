import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { InfoCardContainer, Title, SeeMoreButton } from './InfoCard.style'


const InfoCard = ({ title, text, limit }) => {
  const [expanded, setExpanded] = useState(false)

  const toggleExpand = () => {
    setExpanded(!expanded)
  }

  return (
    <InfoCardContainer>
      <Title>
        <ExclamationCircleOutlined style={{ marginRight: '7px' }} />
        {title}
      </Title>
      {text ? (
        <p>
          {expanded ? text : `${text.slice(0, limit)}`}
          {text.length > limit && (
            <SeeMoreButton onClick={toggleExpand}>
              {!expanded ? '... Ver más' : '... Ver menos'}
            </SeeMoreButton>
          )}
        </p>
      ) : (
        <p className='no-observations'>No se registraron observaciones.</p>
      )}
    </InfoCardContainer>
  )
}

InfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  limit: PropTypes.number.isRequired,
}

export default InfoCard
