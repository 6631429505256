import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import styled from 'styled-components'
import _ from 'lodash'
import BarPieChartComponent from '../ChartComponents/BarPieChartComponent'
import BREAK_POINTS from '../../../global/css/breakPoints'
import { roundNumberTwoDecimals } from '../../../Helpers'

const TodaySectionComponent = ({ title, data, infoUser, score }) => {
  const titleModified = `${title}`
  if(data){
  const dataVehicles = {
    labels: ['Recibidos', 'Facturados'],
    data: [data.vehicles.received, data.vehicles.billed],
  }

  const dataInvoicing = {
    labels: ['Repuestos', 'Servicios'],
    data: [data.invoiced.products, data.invoiced.services],
  }
  
  return (
    <div className="dashboard-section-container">
      <h1 className="dashboard-section-title">{titleModified}</h1>
      <Row className="section-stats-body">
        <Col lg={4} md={6} className="section-today-graph">
          <h3 className="dashboard-section-subtitle vehicles">Vehículos</h3>
          <BarPieChartComponent
            data={dataVehicles.data}
            labels={dataVehicles.labels}
            type="bar"
            scale
          />
        </Col>
        <Col lg={4} md={12} className="section-today-graph">
          <h3 className="dashboard-section-subtitle">Facturación</h3>
          <Row className="section-today-invoice-container">
            <Col
              lg={6}
              md={6}
              sm={6}
              xs={6}
              className="section-today-invoice-money-group"
            >
              <div>
                <h3 className="section-today-invoice-title">En Servicios</h3>
                <MoneyLabel className="section-today-invoice-money">
                  $ {dataInvoicing.data[1]}
                </MoneyLabel>
              </div>
              <div>
                <h3 className="section-today-invoice-title">En Repuestos</h3>
                <MoneyLabel className="section-today-invoice-money">
                  $ {dataInvoicing.data[0]}
                </MoneyLabel>
              </div>
            </Col>
            <Col lg={6} md={6} sm={6} xs={6}>
              <h3 className="section-today-invoice-title">Total del día</h3>
              <MoneyLabel className="section-today-invoice-money">
                ${' '}
                {roundNumberTwoDecimals(
                  dataInvoicing.data[0] + dataInvoicing.data[1]
                )}
              </MoneyLabel>
            </Col>
          </Row>
        </Col>
        <Col lg={4} md={6} className="section-today-graph pie">
          <BarPieChartComponent
            data={dataInvoicing.data}
            labels={dataInvoicing.labels}
            type="pie"
            legend
          />
        </Col>
      </Row>
    </div>
  )} 
    const nombreMecanico = `${infoUser.employee_name} ${infoUser.employee_lastname}`
    const getMyScore = _.filter(score, (e)=>{
      return e.employee_lastname === infoUser.employee_lastname && e.employee_name === infoUser.employee_name 
    })
    const trunc = (x, position = 0)=>{
      const s = x.toString()
      const decimalLength = s.indexOf('.') + 1
      const numStr = s.substr(0, decimalLength + position)
      return Number(numStr)
    }

    return(
      <div className="dashboard-section-container">
        <h1 className="dashboard-section-title">{titleModified}</h1>
        <Row className="section-stats-body section-center">
          <Col sm={4}>
            <h2>{nombreMecanico}</h2>  
          </Col>
          <Col sm={4}>
            <AvatarImg src={infoUser.employee_urlimg
                    ? infoUser.employee_urlimg
                    : 'https://sunfieldfarm.org/wp-content/uploads/2014/02/profile-placeholder.png'} />     
          </Col>
          
          <Col sm={4}>
              <h1>Puntaje</h1>
              
              <Rating>
                {getMyScore[0] ? `${trunc(getMyScore[0].score, 2).toFixed(2)}` : 'Loading' }
                <i className="fas fa-star" />
              </Rating>

          </Col>

        </Row>
      </div>
    )
  
}

const MoneyLabel = styled.p`
  font-size: 3rem;

  @media (${BREAK_POINTS.TABLET}) {
    font-size: 5rem;
  }

  @media (${BREAK_POINTS.DESKTOP}) {
    font-size: 5rem;
  }

  @media (${BREAK_POINTS.DESKTOP_LARGE}) {
    font-size: 3rem;
  }

  @media (${BREAK_POINTS.DESKTOP_EXTRA_LARGE}) {
    font-size: 4rem;
  }
`

const AvatarImg = styled.img`
  border-radius: 100%;
  width: 55%;
`

const Rating = styled.span`
  font-size: 4rem;
  i{
    color: rgb(61, 163, 104);
    padding-left: 30px;
  }
`


TodaySectionComponent.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  infoUser: PropTypes.objectOf(PropTypes.object).isRequired,
  score: PropTypes.objectOf(PropTypes.object).isRequired,
}

export default TodaySectionComponent
