import styled from 'styled-components'
import BREAK_POINTS from '../../../../../global/css/breakPoints'

export const ClientStepContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2em;

  @media (${BREAK_POINTS.TABLET}) {
    gap: 1.6em;
  }

  .options {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:24px;

    .no-owner, .no-client {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;
    }
  }
`

export const SelectedClient = styled.div`
  width:100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding:3px;

  h4 {
    font-weight: bold;
    font-size: 1em;

    span {
      font-weight: 300;
      color: gray;
    }
  }
`

export const SelectedClientCard = styled.div`
  background-color: #ecf0ff;
  padding: 16px;
  display: flex;
  gap: 20px;
  position: relative;
  box-shadow: 3px 3px 5px #ccc;

  .icon {
    display: flex;
    position: absolute;
    right: -3px;
    font-size: 2.2rem;
    top: -10px;
    background-color: white;
    margin: unset;
    padding: unset;
    border: unset;
    border-radius: 100%;
    height: 2rem;
    color: #bf3d48;
  }

  .client-name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;

    i {
      font-size: 3rem;
    }

    p {
      font-weight: bold;
      font-size: 1.1em;
    }
  }

  .client-details {
    display: flex;
    
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
    width: 100%;

    p {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 4px;
      text-transform: capitalize;
      span {
        font-weight: bold;
      }
      .edit {
        background-color: unset;
        border: unset;
        color: #223eab;
      }
    }
    .edited-phone{
      width: 100%;
    }
  }
`
