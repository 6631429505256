import React from 'react'
import { PropTypes } from 'prop-types'
import TextArea from 'antd/es/input/TextArea'
import { FormControl, FormHelperText } from '@material-ui/core'
import { Controller, useFormContext } from 'react-hook-form'
import FormControlWrapper from '../TextFormControl/TextFormControl.styles'

const TextareaFormControl = ({
  label,
  error,
  name,
  required,
  requiredMessage,
  pattern,
  patternMessage,
  placeholder,
}) => {
  const { control, watch } = useFormContext()
  const fielValue = watch(name)
  return (
    <FormControlWrapper>
      <FormControl variant="standard" error={!!error}>
        <div style={{ position: 'relative' }}>
          <label
            htmlFor={name}
            className={`label-colored ${
              !fielValue ? 'label-no-value' : 'label-value'
            } ${error ? 'label-error' : ''}`}
          >
            {label}
            {required && !fielValue?.trim() ? '*' : ''}
          </label>
        </div>
        <Controller
          name={name}
          defaultValue=""
          control={control}
          render={({ field: { onChange, value, ref } }) => (
            <TextArea
              title={name}
              placeholder={placeholder}
              onChange={(e) => onChange(e.target.value)}
              value={value}
              rows={4}
              ref={ref}
              type="texarea"
            />
          )}
          rules={{
            required: {
              value: required,
              message: requiredMessage,
            },
            pattern: {
              value: pattern,
              message: patternMessage,
            },
          }}
        />
        {!!error && (
          <FormHelperText className="error-message" id={`${name}-text`}>
            {error}
          </FormHelperText>
        )}
      </FormControl>
    </FormControlWrapper>
  )
}

TextareaFormControl.defaultProps = {
  required: false,
  requiredMessage: '',
  placeholder: '',
  pattern: undefined,
  patternMessage: '',
  error: '',
}

TextareaFormControl.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  pattern: PropTypes.any,
  placeholder: PropTypes.string,
  requiredMessage: PropTypes.string,
  patternMessage: PropTypes.string,
}

export default TextareaFormControl
