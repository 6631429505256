import React, { Component } from 'react'
import _ from 'lodash'
import { Modal, Form, Button, Tooltip } from 'react-bootstrap'
import moment from 'moment'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { PlusCircleFilled, SaveOutlined, SyncOutlined } from '@ant-design/icons'
import { CSSTransition } from 'react-transition-group'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { showConfirmDialog } from '../Common'
import PersonForm from './PersonForm'
import SyncClientFormStep from './SyncClientFormStep'
import { emailRegex } from '../../Utilities/validations'
import {
  formatInternationalNumber,
  formatPhoneToDB,
  getCountryCode,
} from '../../Utilities/formatPhone'

const Tool = ({ text }) => <Tooltip id="tooltip">{text}</Tooltip>

Tool.propTypes = {
  text: PropTypes.string,
}

Tool.defaultProps = {
  text: '',
}

class PersonModal extends Component {
  state = {
    loading: false,
    person_id: '',
    person_firstname: '',
    person_lastname: '',
    person_address: '',
    person_phone: '',
    person_phone2: '',
    person_email: '',
    person_email_backup: '',
    person_type: 0,
    person_has_nrc: '',
    person_bussiness_name: '',
    person_bussiness_size: null,
    contributor_type_id: null,
    person_bussines_activity: '',
    person_nit: '',
    person_is_customer: '1',
    person_is_insurance_carrier: false,
    person_is_contact: '',
    person_is_incharge: '',
    city_id: null,
    person_gender: '',
    person_birth_date: moment(),
    state_id: null,
    filteredCities: [],
    person_nrc: '',
    contacts: {},
    person_document_type: null,
    person_document_number: '',
    document_type_id: null,
    personTypes: [
      {
        person_type: 0,
        label: 'Persona Natural',
      },
      {
        person_type: 1,
        label: 'Persona Jurídica',
      },
    ],
    actualPage: 1,
    selectedPersonToSync: {},
    aceptEmptyEmail: false,
    userApp: {},
    person_position: '',
    person_contact_number: '',
    country_code: null,
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedPerson } = this.props
    const { person_id: prevPersonId } = prevState

    if (
      selectedPerson !== undefined &&
      prevPersonId !== undefined &&
      selectedPerson.person_id !== undefined &&
      prevPersonId !== undefined &&
      selectedPerson.person_id !== prevPersonId
    ) {
      const { length } = Object.keys(selectedPerson)
      const id = selectedPerson.person_id

      if (
        length <= 1 ||
        id === undefined ||
        id === null ||
        selectedPerson === undefined ||
        selectedPerson === null
      ) {
        this.clearComponent()
      } else if (length > 0 && id) {
        this.setPersonModalState()
      }
    }
  }

  setPersonModalState() {
    const { selectedPerson } = this.props
    const {
      person_id,
      person_firstname,
      person_lastname,
      person_phone,
      person_phone2,
      person_email,
      person_type,
      person_bussiness_name,
      contributor_type_id,
      contacts,
      person_is_insurance_carrier,
      person_nit,
      person_bussines_activity,
      city_id,
      person_gender,
      person_birth_date,
      person_bussiness_size,
      person_nrc,
      person_document_type,
      person_document_number,
      person_address,
      userApp,
      person_contact_number,
      person_position,
      country_code,
    } = selectedPerson

    const state = {
      person_id,
      person_firstname,
      person_lastname,
      person_phone,
      person_phone2,
      person_address,
      person_email,
      person_email_backup: person_email,
      person_type: person_type || 0,
      person_has_nrc: !!person_nrc,
      person_nrc,
      contacts: _.keyBy(contacts, 'person_id'),
      person_nit,
      person_bussines_activity,
      person_bussiness_size,
      person_bussiness_name,
      contributor_type_id,
      person_is_insurance_carrier: person_is_insurance_carrier === 1,
      city_id,
      person_gender,
      person_birth_date: moment(person_birth_date),
      document_type_id: person_document_type,
      person_document_number,
      actualPage: 3,
      aceptEmptyEmail: false,
      userApp,
      person_contact_number,
      person_position,
      country_code,
    }

    if (selectedPerson.city) {
      state.state_id = selectedPerson.city.state.state_id
      state.filteredCities = this.getFilteredCities(state.state_id)
    }
    this.setState(state)
  }

  clearComponent = () => {
    this.setState({
      loading: false,
      person_id: '',
      person_firstname: '',
      person_lastname: '',
      person_phone: '',
      person_address: '',
      person_phone2: '',
      person_email: '',
      person_email_backup: '',
      person_type: 0,
      person_has_nrc: '',
      person_bussiness_name: '',
      person_bussiness_size: null,
      contributor_type_id: null,
      person_is_customer: '1',
      person_is_insurance_carrier: false,
      person_is_contact: '',
      person_is_incharge: '',
      city_id: null,
      person_nit: '',
      person_bussines_activity: '',
      person_gender: '',
      person_birth_date: moment(),
      state_id: null,
      filteredStates: [],
      filteredCities: [],
      person_nrc: '',
      contacts: {},
      person_document_type: null,
      person_document_number: '',
      document_type_id: null,
      actualPage: 1,
      selectedPersonToSync: {},
      userApp: {},
      person_contact_number: '',
      person_position: '',
      country_code: null,
    })
  }

  getFilteredCities = (state_id) => {
    const { cities } = this.props
    return _.filter(cities, this.byState(state_id))
  }

  handlePhoneEdit = (value) => {
    this.setState({
      ...this.prevState,
      person_phone: value,
      country_code: value ? getCountryCode(value) : null,
    })
  }

  handleChange = (e) => {
    const { state } = this
    const { name } = e.target
    let { value } = e.target
    if (name === 'person_nrc') {
      value = value.replace(/[^0-9-]/g, '')
    }
    if (
      name === 'person_firstname' ||
      name === 'person_lastname' ||
      name === 'person_bussiness_name'
    ) {
      value = value.replace(/[0-9]/g, '')
    }
    if (e.target.type === 'checkbox') {
      value = !state[name]
    }
    this.setState({
      [name]: value,
    })
  }

  byState = (id) => (city) => city.state_id === id

  handleChangeSelect = (value, field) => {
    const { cities } = this.props
    if (value) {
      const { filteredCities } = this.state

      const newFilteredCities =
        field === 'state'
          ? _.filter(cities, this.byState(value.state_id))
          : filteredCities

      this.setState({
        [`${field}_id`]: value[`${field}_id`],
        filteredCities: newFilteredCities,
      })
    } else {
      const { state_id } = this.state
      let { city_id, filteredCities } = this.state

      if (field === 'state') {
        filteredCities = []
        city_id = null
      }

      this.setState({
        filteredCities,
        state_id,
        city_id,
        [`${field}_id`]: null,
      })
    }
  }

  handleChangeDate = (value) => {
    this.setState({ person_birth_date: value })
  }

  handleChangePersonType = ({ person_type }) => {
    this.setState({ person_type })
  }

  handleChangeDocumentType = ({ document_type_id }) => {
    this.setState({ document_type_id })
  }

  handleChangeContributorType = ({ contributor_type_id: id }) => {
    this.setState({ contributor_type_id: id })
  }

  addContactItem = () => {
    const { contacts } = this.state
    let key = _.findLastKey(contacts)
    if (!key) {
      key = key || 0
    } else {
      key = parseInt(key, 10) + 1
    }
    this.setState({
      contacts: {
        ...contacts,
        [key]: {
          id: key,
          person_firstname: '',
          person_lastname: '',
          person_phone: '',
          person_phone2: '',
          person_email: '',
          person_position: '',
          isNew: true,
        },
      },
    })
  }

  deleteContactItem = () => {
    const { contacts } = this.state
    const lastKey = _.findLastKey(contacts)
    const newContacts = {
      ...contacts,
    }
    delete newContacts[lastKey]
    this.setState({
      contacts: newContacts,
    })
  }

  handleChangeContact = (e, id) => {
    const { contacts } = this.state
    const { name } = e.target
    let { value } = e.target
    if (name === 'person_phone' || name === 'person_phone2') {
      value = value.replace(/[^0-9-]/g, '')
    }
    this.setState({
      contacts: {
        ...contacts,
        [id]: {
          ...contacts[id],
          [name]: value,
        },
      },
    })
  }

  changePage = (page = 'home') => {
    let newActualPage = 1
    switch (page) {
      case 'home':
        newActualPage = 1
        break
      case 'search':
        newActualPage = 2
        break
      case 'new':
        newActualPage = 3
        break
      default:
        newActualPage = 1
    }
    this.setState({ actualPage: newActualPage })
  }

  handleChangeSyncSelect = (valueObject) => {
    if (!valueObject) {
      this.setState({ selectedPersonToSync: {} })
      return
    }

    this.setState({ selectedPersonToSync: valueObject })
  }

  handleSearchPersons = async (query) => {
    if (!query) {
      return Promise.resolve({ options: [] })
    }

    const { getPersonsToSync } = this.props

    // https://github.com/JedWatson/react-select/blob/v1.x/examples/src/components/GithubUsers.js
    const options = await getPersonsToSync(query) // options is a attribute required

    return Promise.resolve({ options }) // The result should be a Promise
  }

  handleSyncPerson = async () => {
    const { syncPersonWorkshop, toggleModal } = this.props
    const { selectedPersonToSync } = this.state

    await syncPersonWorkshop(selectedPersonToSync)
    toggleModal()
  }

  updateAceptEmailState = () => {
    const { aceptEmptyEmail } = this.state
    this.setState({ aceptEmptyEmail: !aceptEmptyEmail })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const {
      editMode,
      addPerson,
      updatePerson,
      updatePersonWorkshop,
      toggleModal,
      selectedPerson,
    } = this.props
    const {
      person_firstname,
      person_lastname,
      person_phone,
      person_email,
      person_type,
      person_bussiness_name,
      contributor_type_id,
      person_bussines_activity,
      person_is_insurance_carrier,
      city_id,
      person_gender,
      person_birth_date,
      person_nrc,
      document_type_id,
      person_document_number,
      contacts,
      person_nit,
      person_address,
      person_position,
      person_contact_number,
      country_code,
    } = this.state
    let person = {}
    person = {
      ...person,
      person_firstname,
      person_lastname,
      person_phone: formatPhoneToDB(person_phone),
      person_phone2: '',
      person_email,
      person_address,
      person_business_activity: person_bussines_activity,
      person_nit,
      person_type: parseInt(person_type, 10),
      person_has_nrc: person_nrc ? 1 : 0,
      person_nrc,
      person_bussiness_name,
      person_bussiness_size: parseInt(contributor_type_id, 10),
      contributor_type_id: parseInt(contributor_type_id, 10),
      person_is_costumer: 1,
      person_is_insurance_carrier: person_is_insurance_carrier ? 1 : 0,
      city_id: parseInt(city_id, 10),
      person_gender,
      person_birth_date,
      person_document_type: parseInt(document_type_id, 10),
      person_document_number,
      person_position,
      person_contact_number,
      country_code,
    }

    this.setState({ loading: true })
    if (editMode) {
      person.person_id = selectedPerson.person_id
      showConfirmDialog(
        'Modificar',
        `¿Modificar Cliente: ${person.person_firstname} ${person.person_lastname} ?`,
        updatePerson,
        person,
        () => {
          toggleModal()
          this.setState({
            loading: false,
          })
          this.clearComponent()
        },
        null,
        () => {
          this.setState({ loading: false })
        },
        contacts,
        updatePersonWorkshop,
        true
      )
    } else {
      addPerson(person, contacts)
        .then(() => {
          this.setState({ loading: false })
          toggleModal()
          this.clearComponent()
        })
        .catch(() => {
          this.setState({ loading: false })
          toggleModal()
        })
    }
  }

  render() {
    const {
      modalOpen,
      editMode,
      toggleModal,
      pagePermissions,
      permissions,
      states,
      cities,
      contributorTypes,
      workshopCountryCode,
    } = this.props

    let title = editMode ? 'Modificar' : 'Registrar'
    if (title === 'Modificar')
      title = pagePermissions.can_edit ? 'Modificar' : ''
    const { can_edit } = permissions[20]

    const {
      loading,
      actualPage,
      selectedPersonToSync,
      aceptEmptyEmail,
      person_email,
      person_phone,
      person_firstname,
      person_lastname,
      country_code,
    } = this.state

    const workShopCode = workshopCountryCode
    const areaCode = country_code || workShopCode
    const phone = formatInternationalNumber(person_phone, areaCode)
    const isEmailValid = emailRegex.test(person_email)
    const isPhoneValid = isValidPhoneNumber(phone)

    return (
      <Modal
        bsSize={actualPage === 3 && 'lg'}
        show={modalOpen}
        onHide={toggleModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>{`${title} Cliente`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.handleSubmit} className="person-modal">
            {actualPage === 1 && (
              <CSSTransition timeout={500}>
                <div className="select-type-process-form">
                  <Button
                    bsStyle="success"
                    onClick={() => this.changePage('new')}
                    className="type-button btn-antd-icon"
                  >
                    <PlusCircleFilled />
                    Nuevo cliente
                  </Button>
                  <Button
                    bsStyle="success"
                    onClick={() => this.changePage('search')}
                    className="type-button btn-antd-icon"
                  >
                    <SyncOutlined />
                    Sincronizar cliente existente
                  </Button>
                </div>
              </CSSTransition>
            )}
            {actualPage === 2 && (
              <CSSTransition timeout={500}>
                <div className="sync-client-form">
                  <SyncClientFormStep
                    selectedPersonToSync={selectedPersonToSync}
                    handleChangeSyncSelect={this.handleChangeSyncSelect}
                    handleSearchPersons={this.handleSearchPersons}
                    handleSyncPerson={this.handleSyncPerson}
                  />
                </div>
              </CSSTransition>
            )}
            {actualPage === 3 && (
              <CSSTransition timeout={500}>
                <div>
                  <PersonForm
                    state={this.state}
                    handleChange={this.handleChange}
                    handlePhoneEdit={this.handlePhoneEdit}
                    handleChangeSelect={this.handleChangeSelect}
                    states={states}
                    cities={cities}
                    handleChangeDate={this.handleChangeDate}
                    contributorTypes={contributorTypes}
                    handleChangeDocumentType={this.handleChangeDocumentType}
                    editMode={editMode}
                    handleChangePersonType={this.handleChangePersonType}
                    workshopCountryCode={workshopCountryCode}
                  />
                </div>
              </CSSTransition>
            )}
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button type="submit" hidden id="submitPersonModal" />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {actualPage === 1 && (
            <div style={{ float: 'right' }}>
              <Button onClick={toggleModal}>Cerrar</Button>
            </div>
          )}
          {actualPage === 2 && (
            <div>
              <div style={{ float: 'left' }}>
                <Button
                  disabled={loading}
                  bsStyle="primary"
                  onClick={() => this.changePage('prev')}
                >
                  <i className="fas fa-arrow-left prefix" />
                  {' Volver'}
                </Button>
              </div>
              <div style={{ float: 'right' }}>
                <Button onClick={toggleModal}>Cerrar</Button>
              </div>
            </div>
          )}
          {actualPage === 3 && (
            <div className="person-form-step-buttons">
              {!person_email && (
                <div className="acept-checkbox-container">
                  <label
                    htmlFor="acept-empty-email"
                    className="acept-checkbox-label"
                  >
                    <input
                      type="checkbox"
                      id="acept-empty-email"
                      className="acept-checkbox"
                      onChange={() => this.updateAceptEmailState()}
                      checked={aceptEmptyEmail}
                    />
                    Acepta registrar cliente sin email. En el futuro el cliente
                    no podrá ver avances, historial, etc.
                  </label>
                </div>
              )}
              <div style={{ float: 'left' }}>
                <Button
                  disabled={loading}
                  bsStyle="primary"
                  onClick={() => this.changePage('prev')}
                >
                  <i className="fas fa-arrow-left prefix" />
                  {' Volver'}
                </Button>
              </div>
              <div style={{ float: 'right' }}>
                <Button onClick={toggleModal}>Cerrar</Button>
                {!!can_edit && (
                  <Button
                    disabled={
                      (!person_email && !aceptEmptyEmail) ||
                      (!aceptEmptyEmail && !isEmailValid) ||
                      loading ||
                      !isPhoneValid ||
                      !person_firstname ||
                      !person_lastname
                    }
                    bsStyle="primary"
                    onClick={() => {
                      document.getElementById('submitPersonModal').click()
                    }}
                    className="btn-antd-icon"
                  >
                    <SaveOutlined />
                    {editMode ? 'Modificar' : 'Agregar'}
                  </Button>
                )}
              </div>
            </div>
          )}
        </Modal.Footer>
      </Modal>
    )
  }
}

const mapStateToProps = (store) => ({
  permissions: store.AppReducer.permissions,
  workshopCountryCode: store.AppReducer.workshopCountryCode
})

PersonModal.propTypes = {
  addPerson: PropTypes.func.isRequired,
  updatePerson: PropTypes.func.isRequired,
  updatePersonWorkshop: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  getPersonsToSync: PropTypes.func.isRequired,
  selectedPerson: PropTypes.objectOf(PropTypes.any).isRequired,
  syncPersonWorkshop: PropTypes.objectOf(PropTypes.any).isRequired,
  editMode: PropTypes.bool.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  pagePermissions: PropTypes.objectOf(PropTypes.bool).isRequired,
  permissions: PropTypes.objectOf(PropTypes.bool).isRequired,
  states: PropTypes.arrayOf(PropTypes.object).isRequired,
  cities: PropTypes.arrayOf(PropTypes.object).isRequired,
  contributorTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  workshopCountryCode: PropTypes.string.isRequired
}

export default connect(mapStateToProps)(PersonModal)