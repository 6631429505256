import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { EditFilled } from '@ant-design/icons';
import {
  Container,
  Card,
  HeaderContainer,
  HeaderText,
  CardsContainer,
  FlexWrap,
  ProfileImg,
} from './Components'
import LaboralInfo from './LaboralInfo'
import BasicInfoForm from './BasicInfoForm'
import { ContainerHeader } from '../App/Common/index'
import { updateProfile, updatePhoto } from '../../actions/EmployeeAction'
import getName from '../../Helpers/getName'


const TopCard = ({
  employee_name,
  employee_lastname,
  rol,
  workshop,
  employee_urlimg,
  handleChange,
  loading
}) => {
  const name = getName(employee_name, employee_lastname)
  return (
    <Card>
      <FlexWrap justifyContent="space-between">
        <ProfileImg
          src={
            employee_urlimg ||
            'https://sunfieldfarm.org/wp-content/uploads/2014/02/profile-placeholder.png'
          }
        />
        <FileCustom>
          <EditFilled style={{ fontSize: '3rem' }} />
          <input type='file' name="mechanicPhoto" onChange={handleChange} />
          {loading ? <Loader> <div className="lds-facebook"><div /><div /><div /> Loading</div></Loader> : ''}
        </FileCustom>
        <HeaderContainer style={{ paddingLeft: "12%" }}>
          <HeaderText fontSize={30}>
            {`${name.firstname} ${name.lastname} `}
          </HeaderText>
          <HeaderText fontSize={20}>{rol.rol_name}</HeaderText>
          <HeaderText fontSize={25}>{workshop.workshop_name}</HeaderText>
        </HeaderContainer>
      </FlexWrap>
    </Card>
  )
}

const FileCustom = styled.label`
    border: 1px solid #ccc;
    display: inline-block;
    padding: 0 12px;
    cursor: pointer;
    margin-right: auto;
    border: hidden;
    align-self: end;
    margin-bottom: 0;

    input[type="file"] {
      display: none;
    } 

    i{
      font-size: 3rem;
      padding-left: 28px;
    }

`
const Loader = styled.div`
text-align: center;
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #000;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}
`

TopCard.propTypes = {
  employee_urlimg: PropTypes.string.isRequired,
  employee_name: PropTypes.string.isRequired,
  employee_lastname: PropTypes.string.isRequired,
  rol: PropTypes.objectOf(PropTypes.any).isRequired,
  workshop: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
}

class Profile extends Component {
  state = {
    employee_name: '',
    employee_lastname: '',
    employee_phone: '',
    employee_phone2: '',
    employee_email: '',
    employee_address: '',
    loading: false
  }

  componentDidMount() {
    this.setStateFromProps()
  }

  setStateFromProps = () => {
    const { currentUser } = this.props
    const {
      employee_address,
      employee_email,
      employee_lastname,
      employee_name,
      employee_phone,
      employee_phone2,
    } = currentUser
    this.setState({
      employee_name,
      employee_lastname,
      employee_email,
      employee_address,
      employee_phone,
      employee_phone2,
    })
  }

  handleChange = async (e) => {
    const { updateUserPhoto, currentUser } = this.props
    const { target } = e
    if (target.name === 'employee_urlimg') {
      this.setState({
        employee_urlimg2: target.files[0],
      })
    } else if (target.name === 'mechanicPhoto') {
      this.setState({
        loading: true
      })
      const file = new FormData()
      file.append("mechanic_photo", target.files[0])
      await updateUserPhoto(currentUser.employee_id, file)
      this.setState({
        loading: false
      })
    } else {
      this.setState({
        [target.name]: target.value,
      })
    }

  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { currentUser, updateUserProfile } = this.props
    const {
      employee_address,
      employee_email,
      employee_lastname,
      employee_name,
      employee_phone,
      employee_phone2,
      employee_urlimg2
    } = this.state
    const employee = {
      employee_id: currentUser.employee_id,
      employee_name,
      employee_lastname,
      employee_phone,
      employee_phone2,
      employee_address,
      employee_email,
    }
    const file = new FormData()
    file.append("mechanic_photo", employee_urlimg2)
    updateUserProfile(employee, employee_urlimg2 ? file : null)
  }

  render() {
    const { currentUser } = this.props
    const { workshop, rol, employee_urlimg } = currentUser
    const { loading } = this.state
    return (
      <>
        <ContainerHeader title="Perfil" />
        <Container>
          <TopCard
            workshop={workshop}
            rol={rol}
            employee_name={currentUser.employee_name}
            employee_lastname={currentUser.employee_lastname}
            employee_urlimg={employee_urlimg}
            handleChange={this.handleChange}
            loading={loading}
          />
          <CardsContainer>
            <BasicInfoForm
              state={this.state}
              currentUser={currentUser}
              handleChange={this.handleChange}
              handleSubmit={this.handleSubmit}
            />
            <LaboralInfo currentUser={currentUser} />
          </CardsContainer>
        </Container>
      </>
    )
  }
}
const mapStateToProps = (store) => ({
  currentUser: store.AppReducer.currentUser,
})

const mapDispatchToProps = (dispatch) => ({
  updateUserProfile(employee, employee_urlimg2) {
    return dispatch(updateProfile(employee, employee_urlimg2))
  },
  updateUserPhoto(id, file) {
    return dispatch(updatePhoto(id, file))
  },
})

Profile.propTypes = {
  currentUser: PropTypes.objectOf(PropTypes.any).isRequired,
  updateUserProfile: PropTypes.func.isRequired,
  updateUserPhoto: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
