import axios from 'axios'
import _ from 'lodash'

import {
  GET_CONTRIBUTOR_TYPES,
  ADD_CONTRIBUTOR_TYPE,
  UPDATE_CONTRIBUTOR_TYPE,
  DELETE_CONTRIBUTOR_TYPE,
} from '../actionTypes/ContributorTypesTypes'
import { NotificationHandler } from '../Helpers'

const getContributorsAction = (payload) => ({
  type: GET_CONTRIBUTOR_TYPES,
  payload,
})

const addContributorAction = (payload) => ({
  type: ADD_CONTRIBUTOR_TYPE,
  payload,
})

const updateContributorAction = (payload) => ({
  type: UPDATE_CONTRIBUTOR_TYPE,
  payload,
})

const deleteContributorAction = (payload) => ({
  type: DELETE_CONTRIBUTOR_TYPE,
  payload,
})

export const getContributors = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const contributorsQuantity = _.size(
      getState().ContributorTypeReducer.contributorTypes
    )
    if (contributorsQuantity === 0) {
      axios
        .get('/api/contributor_types')
        .then((response) => {
          if (!response.data.error) {
            const contributors = _.keyBy(
              response.data.data,
              'contributor_type_id'
            )
            dispatch(getContributorsAction(contributors))
            resolve(response.data)
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al obtener tipos de contribuyentes',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al obtener tipos de contribuyentes',
            error
          )
        })
    } else {
      resolve({})
    }
  })

export const addContributor = (contributor) => (dispatch) =>
  new Promise((resolve, reject) => {
    axios
      .post('/api/contributor_types', contributor)
      .then((response) => {
        if (!response.data.error) {
          dispatch(addContributorAction(response.data.data))
          resolve(response)
          NotificationHandler('Done', 'info', 'Tipo de contribuyente agregado')
        } else {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al agregar el tipo de contribuyente',
            response.data.data
          )
        }
      })
      .catch((error) => {
        reject()
        NotificationHandler(
          'Error',
          'error',
          'Error al agregar el tipo de contribuyente',
          error
        )
      })
  })

export const updateContributor = (contributor) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (contributor.contributor_type_id) {
      axios
        .put(
          `/api/contributor_types/${contributor.contributor_type_id}`,
          contributor
        )
        .then((response) => {
          if (!response.data.error) {
            dispatch(updateContributorAction(response.data.data))
            resolve(response)
            NotificationHandler(
              'Done',
              'info',
              'Tipo de contribuyente actualizado',
              ''
            )
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al actualizar el tipo de contribuyente',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al actualizar el tipo de contribuyente',
            error
          )
        })
    } else {
      reject()
      NotificationHandler(
        'Error',
        'error',
        `No se proporcionó id a: ${contributor.contributor_type_name}`
      )
    }
  })

export const deleteContributorType = (contributor) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (contributor.contributor_type_id) {
      axios
        .delete(`/api/contributor_types/${contributor.contributor_type_id}`)
        .then((response) => {
          if (!response.data.error) {
            dispatch(deleteContributorAction(contributor))
            resolve(response)
            NotificationHandler(
              'Done',
              'success',
              'Tipo de contribuyente eliminado',
              ''
            )
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al eliminar el tipo de contribuyente',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al eliminar el tipo de contribuyente',
            error
          )
        })
    } else {
      reject()
      NotificationHandler(
        'Error',
        'error',
        `No se proporcionó id a: ${contributor.contributor_type_name}`
      )
    }
  })
