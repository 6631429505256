import _ from 'lodash'

import {
  GET_INVOICES_TYPES,
  ADD_INVOICES_TYPES,
  UPDATE_INVOICES_TYPES,
  DELETE_INVOICES_TYPES,
} from '../actionTypes/InvoiceTypesTypes'

export default (
  state = {
    invoiceTypes: {},
  },
  action
) => {
  switch (action.type) {
    case GET_INVOICES_TYPES: {
      return {
        ...state,
        invoiceTypes: action.payload,
      }
    }
    case ADD_INVOICES_TYPES: {
      return {
        ...state,
        invoiceTypes: {
          ...state.invoiceTypes,
          [action.payload.invoice_type_id]: action.payload,
        },
      }
    }
    case UPDATE_INVOICES_TYPES: {
      return {
        ...state,
        invoiceTypes: {
          ...state.invoiceTypes,
          [action.payload.invoice_type_id]: action.payload,
        },
      }
    }
    case DELETE_INVOICES_TYPES: {
      const newState = _.cloneDeep(state)
      delete newState.invoiceTypes[action.payload.invoice_type_id]
      return newState
    }

    default: {
      return state
    }
  }
}
