import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Col, Tab, Tabs } from 'react-bootstrap'
import { PlusCircleFilled } from '@ant-design/icons';
import _ from "lodash"
import styled from 'styled-components'
import { connect } from 'react-redux'
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { getMechanicBilling, getMechanicEfficiency, getMechanicScore } from '../../../actions/DashboardAction'
import { getUser } from '../../../actions/AppAction'
import { BREAK_POINTS_DESKTOP_FIRST } from '../../../global/css/breakPoints'
import MetricModal from './MetricModal'



const backTitle = [
  { 'background-color': '#000' },
  { 'background-color': '#F44336' },
  { 'background-color': '#FFCC4D' },
]

const monthLabels = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
]

class MetricModalByMechanic extends Component {

  state = {
    lastMechanicScore: {},
    lastMechanicBilling: {},
    lastMechanicEfficiency: {},
    showMetricModal: false,
    MetricData: {},
    typeId: 0,
  }

  componentDidMount() {
    this.initializeModal()
  }

  componentDidUpdate(prevProps) {
    const { mechanicId } = this.props
    const { mechanicId: prevMechanicId } = prevProps

    if (mechanicId !== prevMechanicId) {
      this.initializeModal()
    }

  }

  initializeModal = async () => {
    const { getMechanicBillingAction, getMechanicScoreAction, getMechanicEfficiencyAction, mechanicId, getUserAction } = this.props

    const getMechanicBillingData = await getMechanicBillingAction(mechanicId)

    const getMechanicScoreData = await getMechanicScoreAction(mechanicId)

    const getMechanicEfficiencyData = await getMechanicEfficiencyAction(mechanicId)

    const getMechanicInformation = await getUserAction(mechanicId)

    this.setState({
      lastMechanicBilling: getMechanicBillingData.invoiceMonthly.billedMonthly,
      lastMechanicBillingAvg: getMechanicBillingData.avInvoiceMonthly,
      lastMechanicScore: getMechanicScoreData.scoreMon1,
      lastMechanicScoreAvg: getMechanicScoreData.scoreMon2,
      lastMechanicEfficiency: getMechanicEfficiencyData,
      lastMechanicName: `${getMechanicInformation.data.data.employee_name} ${getMechanicInformation.data.data.employee_lastname}`,
      lastEmployeeMetricGoal: getMechanicInformation.data.data.employee_metric_goal
    })
  }

  openMetricModal = (n, d) => {
    this.toggleMetricModal()
    this.setState({
      typeId: n,
      MetricData: d
    })
  }

  getEfficiencyEvaluation = (totalRealTime, totalTime) => {
    if (totalTime) {
      if (Math.round((totalRealTime / totalTime) * 100, 2) <= 125 && Math.round((totalRealTime / totalTime) * 100, 2) !== 0) {
        return `${Math.round((totalRealTime / totalTime) * 100, 2)}%`
      }
      return "IRREAL"
    }
    return `0%`
  }


  toggleMetricModal = () => {
    const { showMetricModal } = this.state
    this.setState({ showMetricModal: !showMetricModal })
  }


  render() {
    const { modalOpen, toggleModal } = this.props
    const { lastMechanicBilling, lastMechanicName, lastMechanicScore, lastMechanicScoreAvg, lastMechanicEfficiency, lastMechanicBillingAvg, showMetricModal, typeId, MetricData, lastEmployeeMetricGoal } = this.state
    const reverselastMechanicBilling = _.reverse(lastMechanicBilling)
    const reverselastMechanicScore = _.reverse(lastMechanicScore)
    const reverselastMechanicScoreAvg = _.reverse(lastMechanicScoreAvg)
    const reverselastMechanicEfficiency = _.reverse(lastMechanicEfficiency)
    const reverselastMechanicBillingAvg = _.reverse(lastMechanicBillingAvg)
    return (
      <Modal show={modalOpen} onHide={toggleModal}>
        <Modal.Header closeButton style={backTitle[0]}>
          <Modal.Title>
            Metricas de {lastMechanicName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {lastMechanicEfficiency && lastMechanicScore && lastMechanicBilling && lastMechanicName ? <Tabs defaultActiveKey={0} id="uncontrolled-tab-example" className="mb-3">
            {
              _.map(reverselastMechanicBilling, (m, i) => {
                return (
                  <Tab eventKey={i} title={monthLabels[m.month - 1]}>
                    <Col sm={12}>
                      <Row className="dashboard-section-container margin-bottom">
                        <h1 className="dashboard-section-title">{monthLabels[m.month - 1]}</h1>
                        <Col xs={4} md={4}>
                          <CircularProgressbarCostum>
                            <CircularProgressbarWithChildren
                              value={(m.invoiced / lastEmployeeMetricGoal) * 100}
                              strokeWidth={7}
                              styles={buildStyles({
                                pathColor: "#219653",
                                trailColor: "rgb(23, 150, 83, 20%)",
                              })}
                            >
                              <h3>Facturación</h3>
                              <span>{`${Math.round((m.invoiced / lastEmployeeMetricGoal) * 100, 2)}%`}</span>
                            </CircularProgressbarWithChildren>
                            <button type="button" className='btn-antd-icon' onClick={() => {
                              const data = { invData: reverselastMechanicBilling[i], avInv: reverselastMechanicBillingAvg[i], metric: lastEmployeeMetricGoal }
                              this.openMetricModal(0, data)
                            }}>
                              <PlusCircleFilled className="facturation--color" />
                            </button>
                          </CircularProgressbarCostum>
                        </Col>
                        <Col xs={4} md={4}>
                          <CircularProgressbarCostum>
                            <CircularProgressbarWithChildren
                              value={reverselastMechanicEfficiency[i].totalTime ? Math.round((reverselastMechanicEfficiency[i].totalRealTime / reverselastMechanicEfficiency[i].totalTime) * 100, 2) : 0}
                              strokeWidth={7}
                              styles={buildStyles({
                                pathColor: "#F44336",
                                trailColor: "rgb(244, 67, 54, 20%)",
                              })}
                            >
                              <h3>Tiempos</h3>
                              <span>{this.getEfficiencyEvaluation(reverselastMechanicEfficiency[i].totalRealTime, reverselastMechanicEfficiency[i].totalTime)}</span>
                            </CircularProgressbarWithChildren>
                            <button type="button" className='btn-antd-icon' onClick={() => {
                              this.openMetricModal(1, reverselastMechanicEfficiency[i])
                            }}>
                              <PlusCircleFilled className="efficiency--color" />
                            </button>
                          </CircularProgressbarCostum>
                        </Col>
                        <Col xs={4} md={4}>
                          <CircularProgressbarCostum>
                            <CircularProgressbarWithChildren
                              value={(reverselastMechanicScore[i].score / 5) * 100}
                              strokeWidth={7}
                              styles={buildStyles({
                                pathColor: "#FFCC4D",
                                trailColor: "rgb(255, 204, 77, 20%)",
                              })}
                            >
                              <h3>Evaluación</h3>
                              <span className={(reverselastMechanicScore[i].score / 5) * 100 !== 0 ? '' : 'noReviews'}>{(reverselastMechanicScore[i].score / 5) * 100 !== 0 ? `${(reverselastMechanicScore[i].score / 5) * 100}%` : "No hay vehiculos evaluados"}</span>
                            </CircularProgressbarWithChildren>
                            <button type="button" className='btn-antd-icon' onClick={() => {
                              const data = {
                                score: reverselastMechanicScore[i].score,
                                percentage: reverselastMechanicScoreAvg[i].percentage,
                                totalWorkOrder: reverselastMechanicScoreAvg[i].totalWorkOrder
                              }
                              this.openMetricModal(2, data)
                            }}>
                              <PlusCircleFilled className="evaluation--color" />
                            </button>
                          </CircularProgressbarCostum>
                        </Col>
                      </Row>
                    </Col>
                  </Tab>)
              })
            }
          </Tabs> : <Loader>  <div className="lds-ripple"><div /><div /></div> <span>Cargando...</span> </Loader>}
          <MetricModal
            modalOpen={showMetricModal}
            toggleModal={this.toggleMetricModal}
            typeId={typeId}
            data={MetricData}
          />
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    )
  }
}


const CircularProgressbarCostum = styled.div`
    width: 150px;
    height: 150px;
    margin-top: 20px;
    @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
        width: 90px;
        height: 90px;
    }
    h3{
        font-size: 2rem;
        @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
            font-size: 1.3rem;
        }
    }
    span{
    font-size: 2rem;
        @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
            font-size: 1.3rem;
        }
    }
    .noReviews{
        font-size: 1.3rem;
            @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
                font-size: 1rem;
            }
        }
    .anticon {
        position: absolute;
        margin-top: -46px;
        margin-left: 53px;
        font-size: 30px;
        cursor: pointer;
        @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
            margin-left: 35px;
            font-size: 20px;
        }
      }
    
      .evaluation--color{
        color:#FFCC4D;
      }
      .facturation--color{
        color:#219653;
      }
      .efficiency--color{
        color:#F44336;
      }
      button{
        border: none;
        cursor: default;
      }
`
const Loader = styled.div`
text-align: center;
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #000;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
  
}
span{
  display: flex;
  place-content: center;
  font-size: 2rem;
}
`

MetricModalByMechanic.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  mechanicId: PropTypes.objectOf(PropTypes.any).isRequired,
  getMechanicScoreAction: PropTypes.func.isRequired,
  getMechanicBillingAction: PropTypes.func.isRequired,
  getMechanicEfficiencyAction: PropTypes.func.isRequired,
  getUserAction: PropTypes.func.isRequired
}

const mapDispatchToProps = () => ({
  getMechanicScoreAction(mechanic_id) {
    return getMechanicScore(mechanic_id)
  },
  getMechanicBillingAction(mechanic_id) {
    return getMechanicBilling(mechanic_id)
  },
  getMechanicEfficiencyAction(mechanic_id) {
    return getMechanicEfficiency(mechanic_id)
  },
  getUserAction(mechanic_id) {
    return getUser(mechanic_id)
  },
})


export default connect(mapDispatchToProps)(MetricModalByMechanic)