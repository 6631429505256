/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { PropTypes } from 'prop-types'
import { Button } from 'antd'

import { ModalButtonsContainer } from '../../App/Reception/AddReception/VehicleStep/VehicleStep.style'
import NumberFormControl from '../../App/Common/NumberFormControl/NumberFormControl'
import TextFormControl from '../../App/Common/TextFormControl/TextFormControl'
import { ProcessFormContainer } from './AddProcessForm.style'
import PriceInputControl from '../../App/Common/PriceInputControl/PriceInputControl'

const AddProcessForm = ({ children, handleFormSubmit }) => {
  const methods = useForm({ mode: 'onChange' })
  const {
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = methods

  const isSubmitDisabled = !isValid

  const resetForm = () => {
    reset({ processName: '', processTime: '', processPrice: '' })
  }

  const onSubmit = (data) => {
    if (!isSubmitDisabled) {
      handleFormSubmit({
        processName: data.processName,
        processTime: data.processTime,
        processPrice: data.processPrice,
      })

      resetForm()
    }
  }

  useEffect(() => {
    resetForm()
  }, [])

  return (
    <FormProvider {...methods}>
      <ProcessFormContainer onSubmit={handleSubmit(onSubmit)}>
        <TextFormControl
          label="Nombre"
          isAutoFocus
          name="processName"
          error={errors?.processName?.message}
          required
          requiredMessage="Ingresa un nombre para el proceso"
        />
        <NumberFormControl
          label="Tiempo en minutos"
          name="processTime"
          error={errors?.processTime?.message}
          required
          requiredMessage="Ingresa el tiempo en minutos"
        />
        <PriceInputControl
          label="Precio"
          name="processPrice"
          error={errors?.processPrice?.message}
          required
          requiredMessage="Ingresa el precio"
        />
        <ModalButtonsContainer style={{ marginTop: '1.5rem' }}>
          {children}
          <Button type="primary" htmlType="submit" disabled={isSubmitDisabled}>
            Agregar
          </Button>
        </ModalButtonsContainer>
      </ProcessFormContainer>
    </FormProvider>
  )
}

AddProcessForm.propTypes = {
  children: PropTypes.node,
  handleFormSubmit: PropTypes.func.isRequired,
}

AddProcessForm.defaultProps = {
  children: <></>,
}

export default AddProcessForm
