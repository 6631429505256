import styled from 'styled-components'

export const GasLevelSliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;

  padding: 1rem;

  div.gas-inputs {
    display: flex;
    align-items: center;
    gap: 1rem;

    margin-top: 8px;
  }

  div:nth-child(2) {
    align-self: center;
  }
`
