import styled, { keyframes } from 'styled-components'

import { Host } from './Constants'

const blink = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
`

const Loader = styled.div`
  margin: 0px auto;
  margin-top: 200px;
  height: 300px;
  width: 300px;
  background: #ffffff url(${Host}/public/images/logoBlack.png) no-repeat right
    top;
  background-size: contain;
  animation: ${blink} 1s linear infinite;
`

export default Loader

export const FormModalTitle = styled.h2`
  font-size: 1.3em;
  font-weight: 700;

  margin: 0;
`
