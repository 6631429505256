import {
  formatPhoneNumber,
  isValidPhoneNumber,
  parsePhoneNumber,
} from 'react-phone-number-input'

function formatInternationalNumber(number, areaCode) {
  if (number && number.includes('+')) {
    return number
  }
  return `+${areaCode}${number}`
}

function formatPhoneToDB(number) {
  const formatedNumber = number.startsWith('+')
    ? formatPhoneNumber(number)
    : number
  return formatedNumber.replace(/[() -]/g, '')
}

function getCountryCode(number) {
  const formattedPhone = formatInternationalNumber(number)
  if (typeof formattedPhone === 'string') {
    if (isValidPhoneNumber(formattedPhone)) {
      const parsedPhone = parsePhoneNumber(formattedPhone)
      return parseInt(parsedPhone.countryCallingCode, 10)
    }
  }

  return null
}

export { formatInternationalNumber, formatPhoneToDB, getCountryCode }
