import React, { Component } from 'react'
import { connect } from 'react-redux'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory from 'react-bootstrap-table2-filter'
import { Panel, Grid, Row, Col, Button } from 'react-bootstrap'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { MenuOutlined, PlusCircleFilled } from '@ant-design/icons';
import {
  getContributors,
  addContributor,
  updateContributor,
  deleteContributorType,
} from '../../../../../actions/ContributorTypesAction'
import {
  Container,
  ContainerHeader,
  BodyContent,
  showConfirmDialog,
  PanelHeader,
} from '../../../Common/index'
import ContributorTypeModal from './ContributorTypeModal'
import getColumns from './columns'
import { AppLoader } from '../../../Common/AppLoader'

const SCREEN_ID = 7
class ModelsContainer extends Component {
  state = {
    showModal: false,
    loading: true,
    selectedContributor: {},
  }

  componentDidMount() {
    const { getAllContributors } = this.props
    getAllContributors()
      .then(() => {
        this.setState({ loading: false })
      })
      .catch(() => {
        this.setState({ loading: false })
      })
  }

  toggleModal = () => {
    const { showModal } = this.state
    this.setState({ showModal: !showModal })
  }

  addContributor = () => {
    this.setState({ selectedContributor: {} })
    this.toggleModal()
  }

  updateContributor = (contributor) => {
    this.setState({ selectedContributor: contributor })
    this.toggleModal()
  }

  deleteContributor = (contributor) => {
    const { removeContributor } = this.props
    showConfirmDialog(
      '¿Eliminar?',
      `¿Desea eliminar el tipo de contribuyente ${contributor.contributor_type_name}?`,
      removeContributor,
      contributor
    )
  }

  render() {
    const { showModal, selectedContributor, loading } = this.state
    const {
      contributorTypes,
      permissions,
      addNewContributor,
      modifyContributor,
    } = this.props
    const { can_write, can_edit, can_delete } = permissions[SCREEN_ID]
    const pagePermissions = {
      can_write,
      can_edit,
      can_delete,
    }
    return (
      <Container>
        <ContainerHeader title="Tipos de contribuyentes" />
        <BodyContent>
          <Grid fluid>
            <Row>
              <Col sm={12}>
                <Panel>
                  <Panel.Heading bsClass="panel-heading">
                    <Panel.Title>
                      <PanelHeader>
                        <div>
                          <p id="title">
                            <MenuOutlined style={{ marginRight: '7px' }} /> Administrar
                            Contribuyentes
                          </p>
                        </div>
                        <div>
                          {!!can_write && (
                            <Button
                              onClick={this.addContributor}
                              bsStyle="success"
                              className="btn-add btn-antd-icon"
                            >
                              <PlusCircleFilled />
                              Agregar registro
                            </Button>
                          )}
                        </div>
                      </PanelHeader>
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    {loading ? (
                      <AppLoader />
                    ) : (
                      <Row>
                        <Col md={10} />
                        <Col md={12}>
                          <BootstrapTable
                            noDataIndication="Sin datos para mostrar"
                            keyField="contributor_type_id"
                            data={_.values(contributorTypes).reverse()}
                            columns={getColumns(
                              this.updateContributor,
                              this.deleteContributor,
                              {
                                can_edit,
                                can_delete,
                              }
                            )}
                            filter={filterFactory()}
                            pagination={paginationFactory()}
                          />
                        </Col>
                      </Row>
                    )}
                  </Panel.Body>
                </Panel>
              </Col>
            </Row>
          </Grid>
          <ContributorTypeModal
            modalOpen={showModal}
            toggleModal={this.toggleModal}
            editMode={!_.isEmpty(selectedContributor)}
            addContributor={addNewContributor}
            updateContributor={modifyContributor}
            selectedContributor={selectedContributor}
            pagePermissions={pagePermissions}
          />
        </BodyContent>
      </Container>
    )
  }
}

const mapStateToProps = (store) => ({
  contributorTypes: store.ContributorTypeReducer.contributorTypes,
  permissions: store.AppReducer.permissions,
})

const mapDispatchToProps = (dispatch) => ({
  getAllContributors() {
    return dispatch(getContributors())
  },
  addNewContributor(contributor) {
    return dispatch(addContributor(contributor))
  },
  modifyContributor(contributor) {
    return dispatch(updateContributor(contributor))
  },
  removeContributor(contributor) {
    return dispatch(deleteContributorType(contributor))
  },
})

ModelsContainer.propTypes = {
  permissions: PropTypes.objectOf(PropTypes.object).isRequired,
  getAllContributors: PropTypes.func.isRequired,
  addNewContributor: PropTypes.func.isRequired,
  modifyContributor: PropTypes.func.isRequired,
  removeContributor: PropTypes.func.isRequired,
  contributorTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModelsContainer)
