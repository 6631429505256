import axios from 'axios'
import _ from 'lodash'

import {
  GET_PRODUCT_ORDER,
  DELETE_PRODUCT_ORDER,
} from '../actionTypes/ProductOrderTypes'
import { NotificationHandler } from '../Helpers'
import { getProducts } from './ProductActions'

const getProductOrderAction = (payload) => ({
  type: GET_PRODUCT_ORDER,
  payload,
})

const deleteProductOrderAction = (payload) => ({
  type: DELETE_PRODUCT_ORDER,
  payload,
})

export const getProductOrders = () => (dispatch) =>
  new Promise((resolve, reject) => {
    axios
      .get('/api/product_orders')
      .then((response) => {
        if (!response.data.error) {
          const productOrders = _.keyBy(response.data.data, 'work_order_id')
          dispatch(getProductOrderAction(productOrders))
          resolve(response.data)
        } else {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al obtener las ordenes de venta.',
            response.data.data
          )
        }
      })
      .catch((error) => {
        reject()
        NotificationHandler(
          'Error',
          'error',
          'Error al obtener las ordenes de venta.',
          error
        )
      })
  })

export const addProductOrder = (productOrder) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { workshop_id } = getState().AppReducer.currentUser
    axios
      .post('/api/product_orders', { ...productOrder, workshop_id })
      .then((response) => {
        if (!response.data.error) {
          if (productOrder.workOrderDetails[0]) {
            const details = []
            _.forEach(productOrder.workOrderDetails, (detail) => {
              details.push({
                work_order_id: response.data.data.work_order_id,
                work_order_detail_item: detail.work_order_detail_item,
                work_order_detail_item_quantity: parseFloat(
                  detail.work_order_detail_item_quantity || 0
                ),
                work_order_detail_discount: parseFloat(
                  detail.work_order_detail_discount
                ),
                work_order_detail_item_price: parseFloat(
                  detail.work_order_detail_item_price
                ),
              })
            })
            axios
              .post('/api/work_order_details', { work_order_details: details })
              .then((detailResponse) => {
                if (!detailResponse.data.error) {
                  resolve(detailResponse.data)
                  dispatch(getProductOrders(true))
                  dispatch(getProducts())
                  NotificationHandler(
                    'Ok.',
                    'success',
                    'Orden de repuesto generada.'
                  )
                  window.location.reload()
                } else {
                  reject()
                  NotificationHandler(
                    'Error',
                    'error',
                    'Error al agregar la orden.',
                    response.data.data
                  )
                }
              })
              .catch((error) => {
                reject()
                NotificationHandler(
                  'Error',
                  'error',
                  'Error al agregar la orden',
                  error
                )
              })
          } else {
            resolve(response.data)
            dispatch(getProductOrders(true))
            dispatch(getProducts())
            NotificationHandler('Ok.', 'success', 'Orden de repuesto generada.')
            window.location.reload()
          }
        } else {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al agregar la orden.',
            response.data.data
          )
        }
      })
      .catch((error) => {
        reject()
        NotificationHandler(
          'Error',
          'error',
          'Error al agregar la orden.',
          error
        )
      })
  })

const updateWorkOrderDetails = (details, workOrderId) =>
  new Promise((resolve, reject) => {
    const detailsPromises = []
    let addNewWorkOrder
    _.forEach(details, (detail) => {
      if (detail.work_order_detail_id) {
        if (!detail.isDeleted) {
          detailsPromises.push(
            new Promise((resolveDetail, rejectDetail) => {
              axios
                .put(
                  `/api/work_order_details/${detail.work_order_detail_id}`,
                  detail
                )
                .then((detailResponse) => {
                  if (!detailResponse.data.error) {
                    resolveDetail(detailResponse.data)
                  } else {
                    rejectDetail()
                    NotificationHandler(
                      'Error',
                      'error',
                      'Error al actualizar la orden de venta',
                      detailResponse.data.data
                    )
                  }
                })
                .catch((error) => {
                  rejectDetail()
                  NotificationHandler(
                    'Error',
                    'error',
                    'Error al actualizar la orden de venta',
                    error
                  )
                })
            })
          )
        } else {
          detailsPromises.push(
            new Promise((resolveDetail, rejectDetail) => {
              axios
                .delete(
                  `/api/work_order_details/${detail.work_order_detail_id}`,
                  detail
                )
                .then((detailResponse) => {
                  if (!detailResponse.data.error) {
                    resolveDetail(detailResponse.data)
                  } else {
                    rejectDetail()
                    NotificationHandler(
                      'Error',
                      'error',
                      'Error al actualizar la orden de venta',
                      detailResponse.data.data
                    )
                  }
                })
                .catch((error) => {
                  rejectDetail()
                  NotificationHandler(
                    'Error',
                    'error',
                    'Error al actualizar la orden de venta',
                    error
                  )
                })
            })
          )
        }
      } else {
        const detailsToAdd = []
        detailsToAdd.push({
          work_order_id: workOrderId,
          work_order_detail_item: detail.work_order_detail_item,
          work_order_detail_item_price: parseFloat(
            detail.work_order_detail_item_price
          ),
          work_order_detail_discount: parseFloat(
            detail.work_order_detail_discount
          ),
          work_order_detail_item_quantity: parseFloat(
            detail.work_order_detail_item_quantity || 0
          ),
        })
        addNewWorkOrder = new Promise((resolveAdd, rejectAdd) => {
          axios
            .post('/api/work_order_details', {
              work_order_details: detailsToAdd,
            })
            .then((detailResponse) => {
              if (!detailResponse.data.error) {
                resolveAdd()
              } else {
                rejectAdd()
                NotificationHandler(
                  'Error',
                  'error',
                  'Error al actualizar la orden de venta',
                  detailResponse.data
                )
              }
            })
        })
      }
    })
    Promise.all([...detailsPromises, addNewWorkOrder])
      .then(() => {
        resolve()
      })
      .catch(() => {
        reject()
      })
  })

export const updateProductOrder = (productOrder) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (productOrder.work_order_id && !productOrder.work_order_invoiced) {
      axios
        .put(`/api/product_orders/${productOrder.work_order_id}`, productOrder)
        .then((response) => {
          if (!response.data.error) {
            updateWorkOrderDetails(
              productOrder.workOrderDetails,
              productOrder.work_order_id
            ).then(() => {
              dispatch(getProductOrders(true))
              resolve(response)
              NotificationHandler(
                'Ok.',
                'success',
                'Orden de respuestos modificada'
              )
            })
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al modificar la orden.',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al modificar la orden.',
            error
          )
        })
    } else {
      reject()
      NotificationHandler('Error', 'error', 'La orden no puede ser modificada.')
    }
  })

export const deleteProductOrder = (productOrder) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (productOrder.work_order_id) {
      axios
        .delete(`/api/product_orders/${productOrder.work_order_id}`)
        .then((response) => {
          if (!response.data.error) {
            dispatch(deleteProductOrderAction(productOrder))
            NotificationHandler(
              'Ok.',
              'success',
              'Orden eliminada exitosamente.',
              response.data.data
            )
            resolve(response)
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al eliminar la orden.',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al eliminar la orden.',
            error
          )
        })
    } else {
      reject()
      NotificationHandler('Error', 'error', 'La orden no puede ser eliminada.')
    }
  })
