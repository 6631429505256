/* eslint-disable no-return-assign */
import { FormGroup, FormControl } from 'react-bootstrap'
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { sendChatMessage } from '../../../../api/apiService'
import styles from './Chat.module.css'

function ChatInput({
  orderId,
  currentUser,
  focused,
  setFocus,
  toggleLoader,
  loading,
}) {
  const [inputValue, setInputValue] = useState('')
  const inputRef = useRef(null)
  const [rows, setRows] = useState(1)

  useEffect(() => {
    if (focused && inputRef.current) {
      inputRef.current.focus()
    }
  }, [focused])

  const handleInputChange = (e) => {
    const textareaLineHeight = 24 // Adjust this value according to your line height
    const previousRows = e.target.rows
    e.target.rows = 1 // Reset number of rows in textarea

    const currentRows = Math.floor(e.target.scrollHeight / textareaLineHeight)

    if (currentRows === previousRows) {
      e.target.rows = currentRows
    }

    if (currentRows >= 10) {
      e.target.rows = 10
      e.target.scrollTop = e.target.scrollHeight
    }

    setInputValue(e.target.value)
    setRows(currentRows < 10 ? currentRows : 10)
  }

  const handleSendMessage = async () => {
    try {
      if (inputValue.trim() === '') {
        // Input value is empty, so don't send the message
        return
      }

      // Show loader when sending a message
      toggleLoader(true)

      await sendChatMessage(inputValue, currentUser, orderId, { notify: true })

      setInputValue('')
      setRows(1)

      if (focused) {
        setFocus(false)
        inputRef.current.blur()
      }
      setTimeout(() => {
        toggleLoader(false)
      }, 2000)
    } catch (error) {
      console.error('Error sending message:', error)
      toggleLoader(false) // Hide the loader in case of an error
    }
  }

  return (
    <>
      <div className={styles['chatbox-container']}>
        <FormGroup
          className={styles['msg-input-wrapper']}
          controlId="formControlsTextarea"
        >
          <FormControl
            rows={rows}
            type="textarea"
            className={styles['msg-textarea']}
            componentClass="textarea"
            as="textarea"
            placeholder="Escribe tu mensaje"
            value={inputValue}
            onChange={handleInputChange}
            inputRef={(input) => (inputRef.current = input)}
            onBlur={() => setFocus(false)}
          />
        </FormGroup>
        <button
          className={`btn ${styles['btn-chat']}`}
          type="button"
          onClick={handleSendMessage}
          disabled={loading}
        >
          <span className={styles['chat-icon']}>Enviar</span>
        </button>
      </div>
      <div className={styles['disclaimer-wrapper']}>
        <div className={`btn-antd-icon ${styles.disclaimer}`}>
          <ExclamationCircleOutlined style={{ color: '#2b81dc' }} />
          <p>Después de escribir tu mensaje presiona el botón Enviar</p>
        </div>
      </div>
    </>
  )
}

ChatInput.propTypes = {
  orderId: PropTypes.number.isRequired,
  currentUser: PropTypes.string.isRequired,
  focused: PropTypes.bool,
  setFocus: PropTypes.func,
  toggleLoader: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

ChatInput.defaultProps = {
  focused: false,
  setFocus: () => {},
}

export default ChatInput
