const columnStyle = () => ({
  borderRight: '1px solid #FFF',
  borderLeft: '1px solid #FFF',
})

const columnHeaderStyle = () => ({
  borderRight: '1px solid #FFF',
  borderLeft: '1px solid #FFF',
  fontSize: '13px',
  padding: '5px',
  margin: '0px 10px',
})

const OptionsColumnStyle = () => ({
  minWidth:'156px',
  borderRight: '1px solid #FFF',
  borderLeft: '1px solid #FFF',
  fontSize: '13px',
  padding: '5px', 
})

const textFilterStyle = () => ({
  margin: '10px 0 0 0',
  padding: '20px 2%',
})

const formatterHeaderStyle = () => ({
  textAlign: 'center',
  width: '10%',
  padding: '-15px 0 0 0',
  borderRight: '1px solid #FFF',
  borderLeft: '1px solid #FFF',
})

const formatterHeaderStyleActions = () => ({
  textAlign: 'center',
  width: '2%',
  padding: '-15px 0 0 0',
  borderRight: '1px solid #FFF',
  borderLeft: '1px solid #FFF',
})

function isHidden(width, maxWidth = 600) {
  const willHide = width < maxWidth
  return willHide
}

export {
  columnStyle,
  columnHeaderStyle,
  textFilterStyle,
  formatterHeaderStyle,
  formatterHeaderStyleActions,
  isHidden,
  OptionsColumnStyle,
}
