import React from 'react'
import { Button } from 'react-bootstrap'
import ReactToolTip from 'react-tooltip'
import { textFilter } from 'react-bootstrap-table2-filter'
import { EditFilled, EyeFilled, DeleteFilled } from '@ant-design/icons';
import {
  formatterHeaderStyle,
  columnStyle,
  columnHeaderStyle,
  isHidden,
} from '../../../Utilities/TableStyles'

const styleIcons = {
  fontSize: '17px',
}

const editButton = (editProvider, can_edit) => (cell, row) =>
(
  <Button
    onClick={() => {
      editProvider(row)
    }}
    bsStyle="link"
    bsSize="small"
    data-tip={can_edit ? 'Modificar' : 'Ver'}
  >
    {can_edit ? <EditFilled style={styleIcons} /> : <EyeFilled style={styleIcons} />}
    <ReactToolTip />
  </Button>
)

const deleteButton = (deleteProvider) => (cell, row) =>
(
  <Button
    onClick={() => deleteProvider(row)}
    bsStyle="link"
    bsSize="small"
    style={{ color: 'red' }}
    data-tip="Eliminar"
  >
    <DeleteFilled style={styleIcons} />
    <ReactToolTip />
  </Button>
)

const getColumns = (
  editProvider,
  deleteProvider,
  { can_edit, can_delete },
  width = window.screen.width
) => {
  const columns = [
    {
      dataField: 'provider_name',
      text: 'Nombre',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: textFilter({
        delay: 100,
        style: {
          margin: '10px 0 0 0',
          padding: '20px 2%',
        },
        placeholder: 'Buscar',
      }),
    },
    {
      dataField: 'provider_adress',
      text: 'Dirección',
      style: columnStyle,
      hidden: isHidden(width, 500),
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: textFilter({
        delay: 100,
        style: {
          margin: '10px 0 0 0',
          padding: '20px 2%',
        },
        placeholder: 'Buscar',
      }),
    },
    {
      dataField: 'provider_phone',
      text: 'Teléfono',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: textFilter({
        delay: 100,
        style: {
          margin: '10px 0 0 0',
          padding: '20px 2%',
        },
        placeholder: 'Buscar',
      }),
    },
    {
      dataField: 'id',
      className: 'test',
      text: '',
      headerStyle: formatterHeaderStyle,
      style: columnHeaderStyle({ textAlign: 'center' }),
      formatter: editButton(editProvider, can_edit),
    },
  ]
  if (can_delete) {
    columns.push({
      dataField: 'id2',
      text: '',
      headerStyle: formatterHeaderStyle,
      style: columnHeaderStyle({ textAlign: 'center' }),
      formatter: deleteButton(deleteProvider),
    })
  }
  return columns
}

export default getColumns
