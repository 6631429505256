import { Player } from '@lottiefiles/react-lottie-player'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'
import React from 'react'

import loaderAnimation from '../../../lotties/custom-loader-login.json'
import loaderAnimationColored from '../../../lotties/custom-loader.json'
import { useStringLoop } from '../../../hooks/useStringLoop'

const LoaderDynamicText = ({ textArray, colored, interval = 2, position }) => {
  const loaderText = useStringLoop(textArray, interval, false)
  return (
    <>
      <LoaderContainer style={{ position, width: position ? '100%' : 'auto', height: position ? '350px' : '', left: position ? 0 : '', top: position ? '-20px' : '', bottom: position ? '0' : '', background: position === 'inherit' ? 'transparent' : '#FFFFFF' }}>
        <Player
          src={colored ? loaderAnimationColored : loaderAnimation}
          loop
          autoplay
          style={{ width: '23%', maxWidth: '400px' }}
        />
        <LoaderText colored={colored}>{loaderText}</LoaderText>
      </LoaderContainer>

    </>
  )
}

export default LoaderDynamicText

LoaderDynamicText.propTypes = {
  textArray: PropTypes.arrayOf(PropTypes.string).isRequired,
  colored: PropTypes.bool,
  interval: PropTypes.number,
  position: PropTypes.string
}

LoaderDynamicText.defaultProps = {
  colored: false,
  interval: 2,
  position: 'inherit'
}

const LoaderContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 70px;
  align-items: center;
`

const LoaderText = styled.div`
  color: ${(props) => (props.colored ? '#1e1e40' : '#fff')};
  text-align: center;
`
