import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import PropTypes from 'prop-types'

import ReactWhatsapp from 'react-whatsapp'
import { NotificationHandler } from '../../../Helpers'
import EvaluateModalWrapper from './evaluateModal.styles'


// eslint-disable-next-line react/prefer-stateless-function
class EvaluateModal extends Component {
  render() {
    const { modalOpen, toggleModal, personPhone, evaluateUrl } = this.props

    const pasteClipboard = () => {
      navigator.clipboard.writeText(evaluateUrl)
      NotificationHandler(
        'Success',
        'success',
        'Link copiado en tu portapapeles'
      )
    }

    return (
      <Modal show={modalOpen} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>Calificar Orden de trabajo</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <EvaluateModalWrapper>
            <h1>Comparte link de calificación a tus clientes: </h1>

            <div className="btns-wrapper">
              <ReactWhatsapp
                number={personPhone}
                message={evaluateUrl}
                className="btn btn-whatsapp"
              >
                <i className="fab fa-whatsapp" />
                {/*  https://wa.me/1XXXXXXXXXX?text=Me%20interesa%20el%20auto%20que%20estás%20vendiendo */}
                Compartir vía WhatsApp
              </ReactWhatsapp>

              <button
                className="btn btn-clipboard"
                type="button"
                onClick={() => pasteClipboard()}
              >
                <i className="fas fa-link" />
                Copiar al portapapeles
              </button>
            </div>
          </EvaluateModalWrapper>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={toggleModal}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

EvaluateModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  personPhone: PropTypes.string.isRequired,
  evaluateUrl: PropTypes.string.isRequired,
}

export default EvaluateModal
