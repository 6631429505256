import styled from 'styled-components'
import { BREAK_POINTS_DESKTOP_FIRST } from '../../../global/css/breakPoints'

const HistoryContainer = styled.div`
  padding: 16px;
  .history__section {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 48px;
      &__title {
        h3 {
          font-weight: bolder;
        }
        p {
          font-size: 1.5rem;
        }
      }
      &__rating {
        display: flex;
        flex-direction: column;
        gap: 12px;

        h4 {
          padding: 4px 8px;
          border-radius: 10px;
          border: 1px solid black;
          font-size: 1.8rem;
          margin: unset;
          text-align: center;
          span {
            font-weight: bold;
          }
        }

        .rating {
          display: flex;
          gap: 4px;
          align-items: center;
          svg {
            color: #e3b719;
            font-size: 2.5rem;
          }
          p {
            font-size: 1.5rem;
            margin: unset;
          }
        }
      }
    }
    &__info {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 24px;
      &__wrapper {
        display: flex;
        gap: 12px;
        align-items: center;
        color: #1e1e40;
        svg {
          font-size: 3.5rem;
        }
        .info {
          display: flex;
          flex-direction: column;
          h4,
          p {
            margin: 0;
          }

          h4 {
            font-size: 1.6rem;
            font-weight: bold;
            margin-bottom: 8px;
          }
          p {
            font-size: 1.4rem;
          }
          .name {
            color: black;
            font-weight: 500;
          }
          .number {
            font-style: italic;
            color: #404040;
          }
        }
      }
    }
    &__vehicle {
      display: flex;
      gap: 12px;
      align-items: center;
      svg {
        font-size: 3.5rem;
        color: #1e1e40;
      }
      .info {
        h4 {
          font-size: 1.6rem;
          font-weight: bold;
          margin-bottom: 8px;
          color: #1e1e40;
        }
        .specs {
          display: flex;
          gap: 12px;
          flex-wrap: wrap;
          font-size: 1.4rem;

          p {
            color: black;
            margin: 0;
          }

          span {
            font-weight: bold;
          }
        }
      }
    }
    &__photos {
      margin-top: 48px;
      h4 {
        font-weight: bold;
        font-size: 2rem;
        span {
          background-color: #06d6a0;
        }
      }
    }
    &__table {
      display: flex;
      flex-direction: column;
      gap: 16px;
      h4 {
        font-weight: bold;
        font-size: 2rem;
      }
      .package {
        font-size: 1.6rem;
      }
      p {
        color: black;
        font-size: 1.4rem;
        span {
          font-weight: bolder;
        }
      }
      .prices {
        display: flex;
        justify-content: space-between;
        gap: 14px;
        flex-wrap: wrap;
      }

      .table {
        .table-title {
          font-size: 1.5rem;
        }
      }
    }
    &__observations {
      margin-bottom: 32px;
    }
    &__chat {
      h4 {
        font-weight: bold;
        font-size: 2rem;
        margin-bottom: 24px;
      }
    }
  }
  @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
    padding: unset;
  }
`
export default HistoryContainer
