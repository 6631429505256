import React from 'react'
import { ChevronRight } from '@material-ui/icons'
import PropTypes from 'prop-types'

import { ArrowButton, PrimaryArrowButton } from './PreviousNextButton.style'

const PreviousNextButton = ({
  isPrevious,
  isDisabled,
  prevText,
  nextText,
  isSkip,
  buttonType,
  onClickHandler,
}) => {
  return isPrevious ? (
    <ArrowButton
      type={buttonType}
      onClick={onClickHandler}
      disabled={isDisabled}
    >
      <i className="fas fa-chevron-left" />
      {prevText}
    </ArrowButton>
  ) : (
    <PrimaryArrowButton
      type="primary"
      className={isSkip ? 'omit-button' : ''}
      onClick={onClickHandler}
      disabled={isDisabled}
      icon={<ChevronRight />}
    >
      {nextText}
      <i className="fas fa-chevron-right" />
    </PrimaryArrowButton>
  )
}

export default PreviousNextButton

PreviousNextButton.propTypes = {
  isPrevious: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  prevText: PropTypes.string,
  nextText: PropTypes.string,
  buttonType: PropTypes.string,
  onClickHandler: PropTypes.func.isRequired,
  isSkip: PropTypes.bool,
}

PreviousNextButton.defaultProps = {
  isDisabled: false,
  prevText: 'Anterior',
  nextText: 'Siguiente',
  buttonType: 'button',
  isSkip: false,
}
