import { GET_SCREENS } from '../actionTypes/ScreenTypes'

export default (
  state = {
    screens: {},
  },
  action
) => {
  switch (action.type) {
    case GET_SCREENS: {
      return {
        ...state,
        screens: action.payload,
      }
    }

    default: {
      return state
    }
  }
}
