import styled from 'styled-components'

export const InfoCardContainer = styled.div`
  width: 100%;
  height: min-content;
  border: 2px solid #d60e46;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  padding: 10px 12px;
  border-radius: 10px;

  .no-observations {
    color: #737373;
  }
`

export const Title = styled.h3`
  display: flex;
  margin-top: 10px;
  align-items: center;
  font-size: 1.1em;
  font-weight: 700;

  svg {
    color: #d60e46;
  }
`

export const SeeMoreButton = styled.span`
  background: none;
  border: none;
  color: #898989;
  cursor: pointer;
`
