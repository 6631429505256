import {
  GET_HISTORY,
  GET_SALES_HISTORY,
  CLEAR_HISTORY,
} from '../actionTypes/HistoryActionTypes'

const initialState = {
  history: {
    page: 0,
    totalPages: 1,
    totalRecords: 10,
    data: [],
  },
  salesHistory: {
    page: 0,
    totalPages: 1,
    totalRecords: 10,
    data: [],
  },
}

export default (
  state = {
    history: {
      page: 0,
      totalPages: 1,
      totalRecords: 10,
      data: [],
    },
    salesHistory: {
      page: 0,
      totalPages: 1,
      totalRecords: 10,
      data: [],
    },
  },
  action
) => {
  switch (action.type) {
    case GET_HISTORY: {
      return {
        ...state,
        history: {
          data: action.payload.data,
          totalPages: action.payload.meta.totalPages,
          totalRecords: action.payload.meta.totalRecords,
          page: parseInt(action.payload.meta.page, 10),
        },
      }
    }
    case GET_SALES_HISTORY: {
      return {
        ...state,
        salesHistory: {
          data: action.payload.data,
          totalPages: action.payload.meta.totalPages,
          totalRecords: action.payload.meta.totalRecords,
          page: parseInt(action.payload.meta.page, 10),
        },
      }
    }
    case CLEAR_HISTORY:
      return {
        ...state,
        history: {
          ...initialState.history,
        },
      }

    default: {
      return state
    }
  }
}
