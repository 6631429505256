import React from 'react'

export const AddVideoIcon = () => {
  return (
    <svg
      width="44"
      height="41"
      viewBox="0 0 44 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 11C11.5523 11 12 10.5523 12 10C12 9.44772 11.5523 9 11 9C10.4477 9 10 9.44772 10 10C10 10.5523 10.4477 11 11 11Z"
        fill="#9AC9E3"
      />
      <path
        d="M36 34H4C1.8 34 0 32.2 0 30V4C0 1.8 1.8 0 4 0H36C38.2 0 40 1.8 40 4V30C40 32.2 38.2 34 36 34Z"
        fill="#8CBCD6"
      />
      <path
        d="M21.3125 7H9.28125C7.42187 7 6 8.42187 6 10.2812V21.2188C6 23.0781 7.42187 24.5 9.28125 24.5H21.3125C23.1719 24.5 24.5938 23.0781 24.5938 21.2188V10.2812C24.5938 8.42187 23.1719 7 21.3125 7Z"
        fill="#9AC9E3"
      />
      <path
        d="M32.7969 7.16406C32.4687 6.94531 32.0313 6.94531 31.7031 7.16406L26.2344 10.4453C25.9062 10.6641 25.6875 10.9922 25.6875 11.4297V20.1797C25.6875 20.6172 25.9062 20.9453 26.2344 21.1641L31.7031 24.4453C31.8125 24.5547 32.0312 24.5547 32.25 24.5547C32.4688 24.5547 32.5781 24.5547 32.7969 24.4453C33.125 24.2266 33.3438 23.8984 33.3438 23.4609V8.14844C33.3438 7.71094 33.125 7.38281 32.7969 7.16406Z"
        fill="#B3DDF5"
      />
      <path
        d="M34 41C39.5228 41 44 36.5228 44 31C44 25.4772 39.5228 21 34 21C28.4772 21 24 25.4772 24 31C24 36.5228 28.4772 41 34 41Z"
        fill="#43A047"
      />
      <path d="M36 25H32V37H36V25Z" fill="white" />
      <path d="M40 29H28V33H40V29Z" fill="white" />
      <rect x="9" y="9" width="8" height="3" rx="1.5" fill="#B3DDF5" />
    </svg>
  )
}
