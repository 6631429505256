import React from 'react'
import { Dropdown, Menu } from 'antd'
import { PropTypes } from 'prop-types'

export const DropdownDots = ({ items }) => {
  const menu = (
    <Menu>
      {items.map((item) => (
        <Menu.Item key={item.key} onClick={item.onClickHandler}>
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Dropdown overlay={menu} trigger={['click']} style={{ cursor: 'pointer' }}>
      <svg style={{ cursor: 'pointer', fontSize: '2rem' }} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M328 256c0 39.8-32.2 72-72 72s-72-32.2-72-72 32.2-72 72-72 72 32.2 72 72zm104-72c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72zm-352 0c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72z" /></svg>
    </Dropdown>
  )
}

export const dropdownItemShape = PropTypes.shape({
  key: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  onClickHandler: PropTypes.func.isRequired,
})

DropdownDots.propTypes = {
  items: PropTypes.arrayOf(dropdownItemShape).isRequired,
}
