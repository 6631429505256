import axios from 'axios'
import _ from 'lodash'

import {
  GET_SERVICE_TYPES,
  ADD_SERVICE_TYPE,
  UPDATE_SERVICE_TYPE,
  DELETE_SERVICE_TYPE,
} from '../actionTypes/serviceTypeTypes'
import { NotificationHandler } from '../Helpers'

const getServiceTypeAction = (payload) => ({
  type: GET_SERVICE_TYPES,
  payload,
})

const addServiceTypeAction = (payload) => ({
  type: ADD_SERVICE_TYPE,
  payload,
})

const updateServiceTypeAction = (payload) => ({
  type: UPDATE_SERVICE_TYPE,
  payload,
})

const deleteServiceTypeAction = (payload) => ({
  type: DELETE_SERVICE_TYPE,
  payload,
})

const getServiceTypes = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const registersSize = _.size(getState().ServiceTypeReducer.service_types)
    if (registersSize === 0) {
      axios
        .get('/api/service_types')
        .then((response) => {
          if (!response.data.error) {
            const service_types = _.keyBy(response.data.data, 'service_type_id')
            dispatch(getServiceTypeAction(service_types))
            resolve()
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al obtener el área de servicio',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al obtener el área de servicio',
            error
          )
        })
    } else {
      resolve()
    }
  })

const addServiceType = (service_type) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { workshop_id } = getState().AppReducer.currentUser

    axios
      .post('/api/service_types', { ...service_type, workshop_id })
      .then((response) => {
        if (!response.data.error) {
          dispatch(addServiceTypeAction(response.data.data))
          resolve(response)
          NotificationHandler(
            'Agregada',
            'success',
            `Area de servicio ${response.data.data.service_type_name} agregada`
          )
        } else {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al agregar el área de servicio',
            response.data.data
          )
        }
      })
      .catch((error) => {
        reject()
        NotificationHandler(
          'Error',
          'error',
          'Error al agregar el área de servicio',
          error
        )
      })
  })

const updateServiceType = (service_type) => (dispatch) =>
  new Promise((resolve, reject) => {
    axios
      .put(`/api/service_types/${service_type.id}`, service_type)
      .then((response) => {
        if (!response.data.error) {
          dispatch(updateServiceTypeAction(response.data.data))
          resolve(response)
        } else {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al actualizar el área de servicio',
            response.data.data
          )
        }
      })
      .catch((error) => {
        reject(error.message)
        NotificationHandler(
          'Error',
          'error',
          'Error al actualizar el área de servicio',
          error
        )
      })
  })

const deleteServiceType = (service) => (dispatch) =>
  new Promise((resolve, reject) => {
    axios
      .delete(`/api/service_types/${service.id}`)
      .then((response) => {
        if (!response.data.error) {
          dispatch(deleteServiceTypeAction(response.data.data))
          resolve(response)
        } else {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al actualizar el área de servicio',
            response.data.data
          )
        }
      })
      .catch((error) => {
        reject(error.message)
        NotificationHandler(
          'Error',
          'error',
          'Error al actualizar el área de servicio',
          error
        )
      })
  })

export { getServiceTypes, addServiceType, updateServiceType, deleteServiceType }
