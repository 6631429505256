import styled from 'styled-components'

import BREAK_POINTS from '../../../../../global/css/breakPoints'

export const VehicleStepContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.3em;

  @media (${BREAK_POINTS.TABLET}) {
    gap: 1.6em;
  }

  .add-vehicle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 14px;
  }
`

export const SelectedVehicle = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3px;
  gap: 5px;

  h4 {
    font-weight: bold;
    font-size: 1em;
  }
`

export const SelectedVehicleCard = styled.div`
position:relative;
  background-color: #ecf0ff;
  padding: 16px;
  display: flex;
  gap: 20px;

  box-shadow: 3px 3px 5px #ccc;

  .icon {
    display: flex;
    position: absolute;
    right: -3px;
    font-size: 2.2rem;
    top: -10px;
    background-color: white;
    margin: unset;
    padding: unset;
    border: unset;
    border-radius: 100%;
    height: 2rem;
    color: #bf3d48;
  }

  .vehicle-plate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;

    i {
      font-size: 2.5rem;
    }

    p {
      font-weight: bold;
      font-size: 1.1em;
    }
  }

  .vehicle-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;

    p {
      text-transform: capitalize;
      span {
        font-weight: bold;
      }
    }
  }
`

export const PhoneValidation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;

  div.input-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p.phone-input-error {
      color: #bc0000;
      margin-top: 5px;
    }
  }

  @media (${BREAK_POINTS.TABLET}) {
    padding: 0 5rem;
  }

  p {
    text-align: center;
    span {
      font-weight: bold;
    }
  }
`

export const ModalButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ModalBodyContainer = styled.div`
  i {
    color: #efc900;
    font-size: 4rem;
    margin-bottom: 2rem;
  }

  p {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 2rem;
  }
`

export const CheckedClient = styled.p`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  font-size: 14px;
  font-weight: 600;
  padding-top: 3rem;

  i {
    color: #69dd56;
    font-size: 3rem;
  }
`
