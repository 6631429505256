import {
  GET_VEHICLES_USER_APP,
  UPDATE_VEHICLE_USER_APP,
} from '../actionTypes/VehicleUserAppTypes'

const VehicleUserAppReducer = (
  state = {
    vehiclesUserApp: {},
  },
  action
) => {
  switch (action.type) {
    case GET_VEHICLES_USER_APP: {
      return {
        ...state,
        vehiclesUserApp: action.payload,
      }
    }
    case UPDATE_VEHICLE_USER_APP: {
      return {
        ...state,
        vehiclesUserApp: {
          ...state.vehiclesUserApp,
          [action.payload.user_app_vehicle_id]: action.payload,
        },
      }
    }
    default: {
      return state
    }
  }
}

export default VehicleUserAppReducer
