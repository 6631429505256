import React, { useMemo } from 'react'
import { TotalTimeWrapper } from './TotalTime.styles'
import { useAddReceptionContext } from '../../../../../../contexts/AddReceptionContext'

const TotalTime = () => {
  const { state } = useAddReceptionContext()
  const { selectedServices, packageDetails } = state
  const totalProcesses = useMemo(() => {
    return [...selectedServices, ...(packageDetails?.processes || [])]
  }, [selectedServices, packageDetails])

  const totalTime = useMemo(() => {
    return totalProcesses.reduce((accumulator, currentObject) => {
      return accumulator + currentObject?.processTime
    }, 0)
  }, [totalProcesses])

  const hours = Math.floor(totalTime / 60)
    const minutes = totalTime % 60
    

  return (
    <TotalTimeWrapper>
      <i className="fas fa-clock" />
      <p>
        <b>Tiempo total:</b>{' '}
        {hours > 0 ? `${hours} hora${hours > 1 ? 's' : ''}` : ''}
        {hours > 0 && minutes > 0 ? ', ' : ''}
        {minutes > 0 ? `${minutes} min` : ''}
        {minutes === 0 && hours === 0 ? '0 min' : ''}
      </p>
    </TotalTimeWrapper>
  )
}

export default TotalTime
