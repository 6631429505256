import {
  GET_INVOICE,
  ADD_INVOICE,
  SET_WORK_ORDERS_TO_INVOICE,
} from '../actionTypes/InvoicingTypes'

export default (
  state = {
    invoices: {},
    workOrdersToInvoice: [],
  },
  action
) => {
  switch (action.type) {
    case GET_INVOICE: {
      return {
        ...state,
        invoices: action.payload,
      }
    }
    case ADD_INVOICE: {
      return {
        ...state,
        invoices: {
          ...state.invoices,
          [action.payload.invoice_id]: action.payload,
        },
      }
    }
    case SET_WORK_ORDERS_TO_INVOICE: {
      return {
        ...state,
        workOrdersToInvoice: action.payload,
      }
    }

    default: {
      return state
    }
  }
}
