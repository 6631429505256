/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react'
import { PropTypes } from 'prop-types'

import PreviousNextButton from '../../../../buttons/PreviousNextButton/PreviousNextButton'
import GradientTextComponent from '../../../Common/GradientText.js/GradientText'
import { useAddReceptionContext } from '../../../../../contexts/AddReceptionContext'
import { addReceptionActions } from '../../../../../reducers/AddReceptionReducer'
import CustomTabs from '../../../Common/CustomTabs/CustomTabs'
import { NotificationHandler, getNextButtonText } from '../../../../../Helpers'
import ProductsTab from './ProductsTab/ProductsTab'
import AddServiceTab from './ServicesTab/ServicesTab'
import {
  fetchProcesses,
  fetchProducts,
} from '../../../../../api/newReceptionService'

const ServicesProductsStep = ({ handleNext, handlePrevious }) => {
  const { state, dispatch } = useAddReceptionContext()
  const { selectedServices, selectedProducts, isEdit } = state

  const [activeTabKey, setActiveTabKey] = useState('1')

  const handleTabChange = (key) => {
    setActiveTabKey(key)
  }
  const isElementSelected =
    selectedServices?.length > 0 || selectedProducts?.length > 0

  const isSkipButtonStyle = isEdit ? false : !isElementSelected
  
  const getAllProcesses = async () => {
    try {
      const response = await fetchProcesses()
      dispatch({
        type: addReceptionActions.SET_PROCESSES,
        payload: response.data,
      })
    } catch (e) {
      NotificationHandler(
        'Error al obtener lista de servicios',
        'error',
        'Recargar la página podría solucionarlo.'
      )
    }
  }

  const getAllProducts = async () => {
    try {
      const response = await fetchProducts()
      dispatch({
        type: addReceptionActions.SET_PRODUCTS,
        payload: response.data,
      })
    } catch (e) {
      NotificationHandler(
        'Error al obtener lista de respuestos',
        'error',
        'Recargar la página podría solucionarlo.'
      )
    }
  }

  useEffect(() => {
    getAllProcesses()
    getAllProducts()
  }, [])

  const tabs = [
    {
      key: '1',
      label: (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <i className="fas fa-cogs prefix" /> Servicios
        </span>
      ),
      children: <AddServiceTab />,
    },
    {
      key: '2',
      label: (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <i className="fas fa-box-open prefix" /> Repuestos
        </span>
      ),
      children: <ProductsTab />,
    },
  ]

  return (
    <>
      <main className="new-reception__main">
        <div className="new-reception__main__question">
          <GradientTextComponent text="Elige servicios y respuestos adicionales" />
        </div>
        <div
          className="new-reception__main__content"
          style={{ marginTop: '16px' }}
        >
          <CustomTabs
            activeTabKey={activeTabKey}
            handleTabChange={handleTabChange}
            tabs={tabs}
          />
        </div>
      </main>
      <div className="new-reception__navigation-buttons">
        <PreviousNextButton isPrevious onClickHandler={handlePrevious} />
        <PreviousNextButton
          isPrevious={false}
          nextText={getNextButtonText(isElementSelected, isEdit)}
          isSkip={isSkipButtonStyle}
          onClickHandler={handleNext}
        />
      </div>
    </>
  )
}

ServicesProductsStep.propTypes = {
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
}

export default ServicesProductsStep
