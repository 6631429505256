import _ from 'lodash'

import {
  GET_WORK_ORDER_STATUSES,
  ADD_WORK_ORDER_STATUS,
  UPDATE_WORK_ORDER_STATUS,
  DELETE_WORK_ORDER_STATUS,
} from '../actionTypes/WorkOrderStatusTypes'

export default (
  state = {
    workOrderStatuses: {},
  },
  action
) => {
  switch (action.type) {
    case GET_WORK_ORDER_STATUSES: {
      return {
        ...state,
        workOrderStatuses: action.payload,
      }
    }
    case ADD_WORK_ORDER_STATUS: {
      return {
        ...state,
        workOrderStatuses: {
          ...state.workOrderStatuses,
          [action.payload.work_order_status_id]: action.payload,
        },
      }
    }
    case UPDATE_WORK_ORDER_STATUS: {
      return {
        ...state,
        workOrderStatuses: {
          ...state.workOrderStatuses,
          [action.payload.work_order_status_id]: action.payload,
        },
      }
    }
    case DELETE_WORK_ORDER_STATUS: {
      const newState = _.cloneDeep(state)
      delete newState.workOrderStatuses[action.payload.work_order_status_id]
      return newState
    }

    default: {
      return state
    }
  }
}
