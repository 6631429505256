import axios from 'axios'
import _ from 'lodash'

import {
  GET_VEHICLES_USER_APP,
  UPDATE_VEHICLE_USER_APP,
} from '../actionTypes/VehicleUserAppTypes'
import { NotificationHandler } from '../Helpers'

const getVehiclesUserAppAction = (payload) => ({
  type: GET_VEHICLES_USER_APP,
  payload,
})

const updateVehicleUserAppAction = (payload) => ({
  type: UPDATE_VEHICLE_USER_APP,
  payload,
})

export const getVehiclesUserApp = (userApp) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (userApp.user_app_id) {
      axios
        .get(`/api/userAppVehicle/userApp/${userApp.user_app_id}`)
        .then((response) => {
          if (!response.data.error) {
            const vehiclesUserApp = _.keyBy(
              response.data.data,
              'user_app_vehicle_id'
            )
            dispatch(getVehiclesUserAppAction(vehiclesUserApp))
            resolve(response.data)
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error getting vehicles of user',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error getting vehicles of user',
            error
          )
        })
    } else {
      resolve({})
    }
  })

export const updateVehicleUserApp = (vehicle) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (vehicle.vehicle_id) {
      axios
        .put(`/api/userAppVehicle/${vehicle.user_app_vehicle_id}`, vehicle)
        .then((response) => {
          if (!response.data.error) {
            dispatch(updateVehicleUserAppAction(response.data.data))
            resolve(response.data.data)
          } else {
            reject(response)
          }
        })
        .catch((error) => {
          reject(error)
        })
    } else {
      reject()
    }
  })
