import React, { Component } from 'react'
import { connect } from 'react-redux'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory from 'react-bootstrap-table2-filter'
import { Panel, Grid, Row, Col, Button } from 'react-bootstrap'
import _ from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import "intro.js/introjs.css";
import { MenuOutlined, PlusCircleFilled } from '@ant-design/icons';
import {
  Container,
  ContainerHeader,
  BodyContent,
  showConfirmDialog,
  PanelHeader,
} from '../../Common/index'
import ProductModal from './ProductModal'
import ModifyStockProductModal from './ModifyStockProductModal'
import getColumns from './columns'
import {
  addProduct,
  getProducts,
  updateProduct,
  deleteProduct,
  modifyStockProduct,
  fullSearch
} from '../../../../actions/ProductActions'
import { getCategories } from '../../../../actions/CategoryAction'
import { getProviders } from '../../../../actions/ProviderAction'
import { getModels } from '../../../../actions/ModelAction'
import { BREAK_POINTS_DESKTOP_FIRST } from '../../../../global/css/breakPoints'
import { AppLoader } from '../../Common/AppLoader'

const SCREEN_ID = 26

class ProductsContainer extends Component {
  state = {
    showModal: false,
    showModalModifyStock: false,
    loading: true,
    selectedProduct: {},
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions)
    const { getAllProducts, getAllCategories, getAllModels, getAllProviders } = this.props
    getAllProducts()
      .then(() => {
        this.setState({ loading: false })
      })
      .catch(() => {
        this.setState({ loading: false })
      })
    getAllCategories()
    getAllModels()
    getAllProviders()
    this.toggleSteps();
  }



  toggleSteps = () => {
    this.setState(prevState => ({ stepsEnabled: !prevState.stepsEnabled }));
  };

  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
  };

  /*   componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions)
    } */

  updateDimensions = () => {
    const { width } = window.screen
    this.setState({ width })
  }

  toggleModal = () => {
    const { showModal } = this.state
    this.setState({ showModal: !showModal })
  }

  toggleModalModifyStock = () => {
    const { showModalModifyStock } = this.state
    this.setState({ showModalModifyStock: !showModalModifyStock })
  }

  addProduct = () => {
    this.setState({ selectedProduct: {} })
    this.toggleModal()
  }

  updateProduct = (product) => {
    this.setState({ selectedProduct: product })
    this.toggleModal()
  }

  deleteProduct = (product) => {
    const { removeProduct } = this.props
    showConfirmDialog(
      '¿Eliminar?',
      `¿Desea eliminar el repuesto ${product.product_name}?`,
      removeProduct,
      product
    )
  }

  addStockProduct = (product) => {
    this.setState({ selectedProduct: product, modifyStockMode: "I" })
    this.toggleModalModifyStock()
  }

  removeStockProduct = (product) => {
    this.setState({ selectedProduct: product, modifyStockMode: "O" })
    this.toggleModalModifyStock()
  }

  fullSearch = (e) => {
    const { getFullSearch } = this.props
    const { value } = e.target
    getFullSearch(value.toLowerCase())
  }


  render() {
    const { showModal, showModalModifyStock, selectedProduct, loading, width, modifyStockMode } = this.state // Extract state to const to use in render
    const { categories, models, products, permissions, modifyProduct, createProduct, modifyStockProduct1, providers } =
      this.props
    const { can_write, can_edit, can_delete } = permissions[SCREEN_ID]
    const pagePermissions = {
      can_write,
      can_edit,
      can_delete,
    }
    const modelsToArray = Array.from(Object.entries(models))
    const modelsToMultiSelect = modelsToArray.map((a) => {
      return {
        label: a[1].model_name,
        value: a[1].model_id
      }
    })

    return (
      <Container>
        <ContainerHeader title="Inventario" />
        <BodyContent>
          <Grid fluid>
            <Row>
              <Col sm={12}>
                <Panel>
                  <Panel.Heading bsClass="panel-heading">
                    <Panel.Title>
                      <PanelHeader>
                        <div>
                          <p id="title">
                            <MenuOutlined style={{ marginRight: '7px' }} /> Administrar
                          </p>
                        </div>
                        <div>
                          {!!can_write && (
                            <Button
                              onClick={this.addProduct}
                              bsStyle="success"
                              className="btn-add btn-antd-icon step1"
                            >
                              <PlusCircleFilled />
                              Agregar repuesto
                            </Button>
                          )}
                        </div>
                      </PanelHeader>
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    {loading ? (
                      <AppLoader />
                    ) : (
                      <>
                        <input
                          type="text"
                          placeholder="Puedes buscar por nombre, modelos compatibles y/o código"
                          className="search-products step2"
                          onChange={(e) => this.fullSearch(e)}
                        />
                        <Row>
                          <Col md={10} />
                          <Col md={12}>
                            <CustomStyledTable>
                              <BootstrapTable
                                noDataIndication="Sin datos para mostrar"
                                keyField="product_id"
                                data={_.values(products).map((element) => {
                                  return {
                                    ...element,
                                    product_price: `$${element.product_price}`,
                                  }
                                })}
                                columns={getColumns(
                                  this.updateProduct,
                                  this.deleteProduct,
                                  this.addStockProduct,
                                  this.removeStockProduct,
                                  {
                                    can_edit,
                                    can_delete,
                                  },
                                  width
                                )}
                                filter={filterFactory()}
                                pagination={paginationFactory()}
                              />
                            </CustomStyledTable>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Panel.Body>
                </Panel>
              </Col>
            </Row>
          </Grid>
          <ProductModal
            modalOpen={showModal}
            toggleModal={this.toggleModal}
            editMode={!_.isEmpty(selectedProduct)}
            categories={categories}
            models={modelsToMultiSelect}
            providers={providers}
            addProduct={createProduct}
            updateProduct={modifyProduct}
            selectedProduct={selectedProduct}
            pagePermissions={pagePermissions}
          />
          <ModifyStockProductModal
            modalOpen={showModalModifyStock}
            toggleModal={this.toggleModalModifyStock}
            modifyStockMode={modifyStockMode}
            modifyStockProduct={modifyStockProduct1}
            selectedProduct={selectedProduct}
            pagePermissions={pagePermissions}
          />
        </BodyContent>
      </Container>
    )
  }
}

const CustomStyledTable = styled.div`
  @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
    table {
      border: none;
      padding: 0px 10px;

      thead {
        tr {
          display: flex;
          flex-direction: column;

          th {
            label {
              width: 100%;
            }
          }

          th {
            border: none;
          }

          th:nth-last-child(1),
          th:nth-last-child(2) {
            display: none;
          }
        }
      }

      tbody {
        tr {
          border: 1px solid #ddd;
          display: flex;
          flex-wrap: wrap;
          border-radius: 10px;
          margin: 20px 10px;
          padding-top: 10px;
          padding-bottom: 10px;

          td {
            width: 100%;
            border: 0;
            border-right: 0px !important;
            border-left: 0px !important;
          }

          td:nth-last-child(1),
          td:nth-last-child(2) {
            width: 40%;
          }

          td::before {
            font-weight: bold;
            margin-right: 0.5rem;
          }

          td:nth-child(1)::before {
            content: 'Nombre:';
          }

          td:nth-child(2)::before {
            content: 'Modelos compatibles:';
          }

          td:nth-child(3)::before {
            content: 'Código:';
          }

          td:nth-child(4)::before {
            content: 'Precio:';
          }

          td:nth-child(5) {
            display: none !important;
          }

          td:nth-child(6)::before {
            content: 'Editar';
          }

          td:nth-child(7)::before {
            content: 'Agregar stock';
          }

          td:nth-child(8)::before {
            content: 'Remover stock';
          }

          td:nth-child(8) {
            width: 100%;
          }

          td:nth-child(9)::before {
            content: 'Eliminar';
          }
        }
      }
    }
  }
`

ProductsContainer.propTypes = {
  permissions: PropTypes.objectOf(PropTypes.object).isRequired,
}

const mapStateToProps = (store) => ({
  products: store.ProductReducer.products,
  categories: store.CategoryReducer.categories,
  models: store.ModelReducer.models,
  providers: store.ProviderReducer.providers, // mapeo por el state del reducer
  permissions: store.AppReducer.permissions,
  productsLoading: store.ProductReducer.productsLoading,
})

const mapDispatchToProps = (dispatch) => ({
  getAllProducts() {
    return dispatch(getProducts())
  },
  createProduct(product) {
    return dispatch(addProduct(product))
  },
  modifyProduct(product) {
    return dispatch(updateProduct(product))
  },
  removeProduct(product) {
    return dispatch(deleteProduct(product))
  },
  getAllCategories() {
    dispatch(getCategories())
  },
  getAllModels() {
    dispatch(getModels())
  },
  modifyStockProduct1(product) {
    dispatch(modifyStockProduct(product))
  },
  getAllProviders() {
    dispatch(getProviders())
  },
  getFullSearch(search) {
    return dispatch(fullSearch(search))
  },
})

ProductsContainer.propTypes = {
  getAllProducts: PropTypes.func.isRequired,
  createProduct: PropTypes.func.isRequired,
  modifyProduct: PropTypes.func.isRequired,
  removeProduct: PropTypes.func.isRequired,
  getAllCategories: PropTypes.func.isRequired,
  getAllModels: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  models: PropTypes.arrayOf(PropTypes.object).isRequired,
  modifyStockProduct1: PropTypes.func.isRequired,
  getAllProviders: PropTypes.func.isRequired,
  providers: PropTypes.arrayOf(PropTypes.object).isRequired,
  getFullSearch: PropTypes.func.isRequired,
  // productsLoading: PropTypes.bool.isRequired,
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductsContainer)
