import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { DatePicker, Modal, Button } from 'antd'
import PropTypes from 'prop-types'

import PreviousNextButton from '../../../../../buttons/PreviousNextButton/PreviousNextButton'
import { useAddReceptionContext } from '../../../../../../contexts/AddReceptionContext'
import GradientTextComponent from '../../../../Common/GradientText.js/GradientText'
import { addReceptionActions } from '../../../../../../reducers/AddReceptionReducer'
import { ModalTitle, VehicleModelFormWrapper } from './VehicleModelForm.style'
import AddModelForm from '../../../../../forms/AddModelForm/AddModelForm'
import { addReceptionBrand } from '../../../../../../actions/BrandAction'
import AddBrandForm from '../../../../../forms/AddBrandForm/AddBrandForm'
import ImageSelect from '../../../../../inputs/ImageSelect/ImageSelect'
import CustomSelect from '../../../../../inputs/CustomSelect/CustomSelect'
import { addModel } from '../../../../../../actions/ModelAction'
import { useModal } from '../../../../../../hooks/useModal'
import { createOption } from '../../../../../../Helpers'

const VehicleModelForm = ({
  brands,
  models,
  workshopId,
  vehicleTypes,
  createModel,
  createBrand,
  handleNext,
  handlePrevious,
}) => {
  const { state, dispatch } = useAddReceptionContext()
  const { newVehicle } = state
  const { selectedBrand, selectedModel, selectedYearMomentObj } = newVehicle

  const [isModelModalOpen, openModelModal, closeModelModal] = useModal()
  const [isBrandModalOpen, openBrandModal, closeBrandModal] = useModal()

  const brandsArray = Object.values(brands)
  const modelsArray = Object.values(models)
  const vehicleTypesArray = Object.values(vehicleTypes)

  const nextButtonDisabled =
    !selectedBrand || !selectedModel || !selectedYearMomentObj

  const brandsOptions = useMemo(() => {
    return brandsArray.map((brand) => {
      return {
        ...createOption(brand.brand_id, brand.brand_name, brand),
        // labelName is used for correct functionality of ImageSelect
        labelName: brand.brand_name,
      }
    })
  }, [brands, selectedBrand])

  const vehicleTypesOptions = useMemo(() => {
    return vehicleTypesArray.map((type) =>
      createOption(type.vehicle_type_id, type.vehicle_type_name)
    )
  }, [vehicleTypes])

  const modelsOptions = useMemo(() => {
    let options = modelsArray

    if (selectedBrand) {
      const filtered = options.filter((model) => {
        return model?.brand?.brand_id === selectedBrand.value
      })

      options = [...filtered]
    }

    return options.map((model) =>
      createOption(model.model_id, model.model_name, model)
    )
  }, [models, selectedBrand])

  const updateNewVehicleData = (newData) => {
    dispatch({
      type: addReceptionActions.SET_NEW_VEHICLE,
      payload: {
        ...newVehicle,
        ...newData,
      },
    })
  }

  const handleSelectedBrand = (_, option) => {
    updateNewVehicleData({
      selectedBrand: { ...option, labelName: option?.customData?.brand_name },
    })
  }

  const handleSelectedModel = (_, option) => {
    updateNewVehicleData({
      selectedModel: option,
    })
  }

  const handleYearChange = (date) => {
    updateNewVehicleData({
      selectedYearMomentObj: date,
    })
  }

  const submit = (e) => {
    e.preventDefault()

    if (selectedBrand && selectedModel && selectedYearMomentObj) {
      handleNext()
    }
  }

  const addVehicleBrand = async (formData) => {
    try {
      const brand = await createBrand(formData)

      updateNewVehicleData({
        selectedBrand: createOption(brand.brand_id, brand.brand_name, brand),
      })
      closeBrandModal()

      openModelModal()
    } catch (e) {
      console.error(e)
    }
  }

  const addVehicleModel = async (formData) => {
    try {
      const response = await createModel(formData)
      const model = response.data.data
      const { brand } = response.data.data

      updateNewVehicleData({
        selectedBrand: createOption(brand.brand_id, brand.brand_name, brand),
        selectedModel: createOption(model.model_id, model.model_name, model),
      })

      closeModelModal()
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    // Remove selected model if brand is changed
    if (
      selectedModel &&
      selectedBrand.customData.brand_id !==
        selectedModel.customData.brand.brand_id
    )
      handleSelectedModel({
        selectedModel: undefined,
      })
  }, [selectedBrand])

  return (
    <>
      <main className="new-reception__main">
        <div className="new-reception__main__question">
          <GradientTextComponent text="Datos del vehículo *" />
        </div>
        <div className="new-reception__main__content">
          <VehicleModelFormWrapper>
            <ImageSelect
              id="brand"
              isAutoFocus
              items={brandsOptions}
              value={selectedBrand}
              imgPropName="brand_logo_url"
              addItemText="Agregar marca"
              label="Marca *"
              placeholder="Selecciona una marca"
              handleValueChange={handleSelectedBrand}
              handleOnAdd={openBrandModal}
            />
            <CustomSelect
              id="model"
              items={modelsOptions}
              value={selectedModel}
              disabled={!selectedBrand}
              addItemText={`Agregar modelo de ${selectedBrand?.labelName}`}
              label="Modelo *"
              placeholder="Selecciona un modelo"
              handleValueChange={handleSelectedModel}
              handleOnAdd={openModelModal}
            />
            <div className="date-input">
              <label htmlFor="vehicle-year">Año *</label>
              <DatePicker
                id="vehicle-year"
                size="large"
                allowClear={false}
                onChange={handleYearChange}
                placeholder="Selecciona el año"
                picker="year"
                defaultValue={selectedYearMomentObj}
              />
            </div>
          </VehicleModelFormWrapper>
        </div>
      </main>
      <div className="new-reception__navigation-buttons">
        <PreviousNextButton isPrevious onClickHandler={handlePrevious} />
        <PreviousNextButton
          isPrevious={false}
          onClickHandler={submit}
          isDisabled={nextButtonDisabled}
        >
          Siguiente
        </PreviousNextButton>
      </div>
      <Modal
        title={<ModalTitle>Agregar marca</ModalTitle>}
        style={{ padding: '3rem 1rem' }}
        open={isBrandModalOpen}
        width={400}
        centered
        onCancel={closeBrandModal}
        footer={null}
      >
        <AddBrandForm
          workshopId={workshopId}
          handleModelFormSubmit={addVehicleBrand}
        >
          <Button key="back" onClick={closeBrandModal}>
            Cancelar
          </Button>
        </AddBrandForm>
      </Modal>
      <Modal
        title={<ModalTitle>Agregar modelo</ModalTitle>}
        style={{ padding: '3rem 1rem' }}
        open={isModelModalOpen}
        width={400}
        centered
        onCancel={closeModelModal}
        footer={null}
      >
        <AddModelForm
          workshopId={workshopId}
          brandsOptions={brandsOptions}
          handleModelFormSubmit={addVehicleModel}
          vehicleTypesOptions={vehicleTypesOptions}
          selectedBrand={selectedBrand}
        >
          <Button key="back" onClick={closeModelModal}>
            Cancelar
          </Button>
        </AddModelForm>
      </Modal>
    </>
  )
}

VehicleModelForm.propTypes = {
  brands: PropTypes.object.isRequired,
  models: PropTypes.object.isRequired,
  vehicleTypes: PropTypes.object.isRequired,
  workshopId: PropTypes.number.isRequired,
  createModel: PropTypes.func.isRequired,
  createBrand: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
}

const mapStateToProps = (store) => ({
  vehicleTypes: store.VehicleTypesReducer.vehicleTypes,
  brands: store.BrandReducer.brands,
  models: store.ModelReducer.models,
  workshopId: store.AppReducer.workshopId,
})

const mapDispatchToProps = (dispatch) => ({
  createModel(model) {
    return dispatch(addModel(model))
  },
  createBrand(brand) {
    return dispatch(addReceptionBrand(brand))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(VehicleModelForm)
