import { GET_DASHBOARD_TODAY } from '../actionTypes/DashboardTypes'

export default (
  state = {
    today: {},
  },
  action
) => {
  switch (action.type) {
    case GET_DASHBOARD_TODAY: {
      return {
        ...state,
      }
    }
    default: {
      return state
    }
  }
}
