import styled from 'styled-components'
import BREAK_POINTS from '../../../../../../global/css/breakPoints'

export const PlateForm = styled.form`
  height: 100%;

  .new-reception__navigation-buttons {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    padding: 8px;

    margin-top: auto;

    @media (${BREAK_POINTS.TABLET}) {
      align-self: center;
      margin-bottom: 0;
    }
  }
`
