import React, { Component } from 'react'
import {
  Modal,
  Row,
  Col,
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
  InputGroup,
} from 'react-bootstrap'
import Select from 'react-select'
import _ from 'lodash'
import InputMask from 'react-input-mask'
import PropTypes from 'prop-types'
import { SaveOutlined } from '@ant-design/icons'
import { vehiclePlateRegex } from '../../../Utilities/validations'
import PersonModal from '../../Persons/PersonModal'
import { showConfirmDialog } from '../../Common'

const getfixedPersons = (persons) => {
  const personsArray = [
    {
      person_id: 'add',
      fullName: 'Agregar Cliente',
    },
  ]

  const personArray = _.values(persons)
  _.remove(personArray, (p) => p.person_is_costumer !== 1)

  const people = _.map(personArray, (person) => {
    const aux = _.cloneDeep(person)
    aux.fullName = `${aux.person_document_number} - ${aux.person_firstname} ${aux.person_lastname}`
    return aux
  })
  return [...personsArray, ...people]
}

class VehicleModal extends Component {
  state = {
    loading: false,
    vehicle_license_plate: '',
    vehicle_year: '',
    color_id: '',
    measure_unit_id: '',
    model_id: '',
    person_id: '',
    filteredModels: {},
    showPersonModal: false,
    vehicleUserApp: null,
    userApp: null,
    vehicle_license_plate_error: '',
  }

  isFormValid = !this.vehicle_license_plate_error

  componentDidUpdate(prevProps) {
    const { selectedVehicle } = this.props
    const { selectedVehicle: prevSelectedVehicle } = prevProps
    if (selectedVehicle.vehicle_id !== prevSelectedVehicle.vehicle_id) {
      const { length } = Object.keys(selectedVehicle)
      const id = selectedVehicle.vehicle_id

      if (
        length === 0 ||
        id === undefined ||
        id === null ||
        selectedVehicle === undefined ||
        selectedVehicle === null
      ) {
        this.cleanComponent()
      } else if (length > 0 && id) {
        this.setVehicleModalState()
      }
    }
  }

  getVehiclePlateError = (vehiclePlate) => {
    const isValid = vehiclePlateRegex.test(vehiclePlate)
    return isValid ? '' : 'Solo se permiten letras y números sin espacios'
  }

  setVehicleModalState() {
    const { selectedVehicle, models } = this.props

    const {
      vehicle_license_plate,
      vehicle_year,
      color_id,
      measure_unit_id,
      model_id,
      model,
      owner,
    } = selectedVehicle

    this.setState({
      vehicle_license_plate,
      // Set invalid license plate error in edit mode
      vehicle_license_plate_error: this.getVehiclePlateError(
        vehicle_license_plate
      ),
      vehicle_year,
      color_id,
      measure_unit_id,
      model_id,
      brand_id: model.brand_id,
      person_id: owner[0].person_id,
      filteredModels: _.filter(
        models,
        this.byBrand({ brand_id: model.brand_id })
      ),
    })
  }

  handleVehiclePlateChange = (e) => {
    const { name } = e.target
    let { value } = e.target

    if (name === 'vehicle_license_plate') {
      value = value.toUpperCase()

      this.setState({
        [name]: value,
        vehicle_license_plate_error: this.getVehiclePlateError(value),
      })
    } else {
      this.setState({ [name]: value })
    }
  }

  handleChange = (e) => {
    const { name } = e.target

    let { value } = e.target
    if (name === 'vehicle_year') {
      value = value.replace(/[^0-9]/g, '')
    } else if (name === 'vehicle_license_plate') {
      value = value.toUpperCase()
    }
    this.setState({ [name]: value })
  }

  byBrand = (value) => (brand) => brand.brand_id === value.brand_id

  byModel = (value) => (model) => model.model_id === value.model_id

  handleChangeSelect = (value, field) => {
    if (value) {
      if (value.person_id === 'add') {
        this.setState({
          showPersonModal: true,
        })
        return
      }
      const { models } = this.props
      const { filteredModels } = this.state

      const newFilteredModels =
        field === 'brand'
          ? _.filter(models, this.byBrand(value))
          : filteredModels

      this.setState({
        [`${field}_id`]: value[`${field}_id`],
        filteredModels: newFilteredModels,
      })
    } else {
      const { filteredModels, model_id } = this.state
      const newFilteredModels = field === 'brand' ? [] : filteredModels

      const newModel_id = field === 'brand' ? null : model_id

      this.setState({
        model_id: newModel_id,
        [`${field}_id`]: null,
        filteredModels: newFilteredModels,
      })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const {
      toggleModal,
      updateVehicle,
      addVehicle,
      selectedVehicle,
      editMode,
      modifyVehicleUserApp,
      modifyUserApp,
    } = this.props
    const {
      vehicle_license_plate,
      vehicle_license_plate_error,
      vehicle_year,
      color_id,
      measure_unit_id,
      model_id,
      person_id,
      vehicleUserApp,
      userApp,
    } = this.state

    const vehicle = {
      vehicle_id: selectedVehicle.vehicle_id || null,
      vehicle_license_plate: vehicle_license_plate.trim(),
      vehicle_year,
      color_id,
      measure_unit_id,
      model_id,
      person_id,
    }

    if (!vehicle_license_plate_error) {
      if (editMode) {
        showConfirmDialog(
          '¿Modificar?',
          `¿Modificar el vehículo: ${vehicle.vehicle_license_plate}`,
          updateVehicle,
          vehicle,
          () => {},
          toggleModal,
          toggleModal
        )
      } else {
        addVehicle(vehicle)
          .then(
            (resVehicle) =>
              new Promise((resolve, reject) => {
                if (vehicleUserApp !== null) {
                  const {
                    data: {
                      data: { vehicle_id },
                    },
                  } = resVehicle
                  vehicleUserApp.vehicle_id = vehicle_id
                  modifyVehicleUserApp(vehicleUserApp)
                    .then((response) => {
                      if (userApp !== null && !(+userApp.person_id > 0)) {
                        userApp.person_id = person_id
                        modifyUserApp(userApp)
                          .then((res) => resolve(res))
                          .catch((err) => reject(err))
                      } else {
                        resolve(response)
                      }
                    })
                    .catch((error) => reject(error))
                }
                this.cleanComponent()
              })
          )
          .catch(() => {
            this.cleanComponent()
            this.setState({ loading: false })
          })
      }
      toggleModal()
      return true
    }

    return false
  }

  cleanComponent = () => {
    this.setState({
      loading: false,
      vehicle_license_plate: '',
      vehicle_license_plate_error: '',
      vehicle_year: '',
      color_id: '',
      measure_unit_id: '',
      model_id: '',
      person_id: '',
      brand_id: '',
      filteredModels: {},
      vehicleUserApp: null,
      userApp: null,
    })
  }

  togglePersonModal = () => {
    const { showPersonModal } = this.state
    this.setState({
      showPersonModal: !showPersonModal,
    })
  }

  handleSetStateVehicleExtract = (vehicle, userApp) => {
    const { models } = this.props
    this.setState({
      vehicle_year: vehicle.users_app_vehicle_year,
      model_id: vehicle.model_id,
      brand_id: vehicle.model.brand_id,
      filteredModels: _.filter(
        models,
        this.byBrand({ brand_id: vehicle.model.brand_id })
      ),
      vehicleUserApp: vehicle,
      userApp,
    })
  }

  render() {
    const {
      editMode,
      modalOpen,
      toggleModal,
      brands,
      measureUnits,
      colors,
      persons,
      pagePermissions,
      personPermissions,
      states,
      cities,
      contributorTypes,
      addPerson,
      // usersApp,
    } = this.props
    const {
      loading,
      vehicle_license_plate,
      vehicle_year,
      color_id,
      measure_unit_id,
      model_id,
      brand_id,
      person_id,
      filteredModels,
      showPersonModal,
      vehicle_license_plate_error,
      // vehiclesUserApp,
    } = this.state
    let can_edit
    if (pagePermissions) {
      ;({ can_edit } = pagePermissions)
    }

    return (
      <Modal bsSize="large" show={modalOpen} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {!editMode ? 'Agregar' : 'Modificar'} Vehículo
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={(e) => this.handleSubmit(e)} id="vehicleForm">
            <Row>
              <Col xs={12} md={8}>
                <FormGroup>
                  <ControlLabel>Placa: </ControlLabel>
                  <InputGroup>
                    <FormControl
                      type="text"
                      value={vehicle_license_plate}
                      onChange={this.handleVehiclePlateChange}
                      name="vehicle_license_plate"
                      required
                      placeholder="######"
                    />
                  </InputGroup>
                  {vehicle_license_plate_error && (
                    <p style={{ color: 'red', marginTop: '5px' }}>
                      {vehicle_license_plate_error}
                    </p>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={4} lg={4}>
                <FormGroup>
                  <ControlLabel>Marca: </ControlLabel>
                  <Select
                    value={brand_id}
                    labelKey="brand_name"
                    valueKey="brand_id"
                    onChange={(v) => {
                      this.handleChangeSelect(v, 'brand')
                    }}
                    options={_.values(brands)}
                    placeholder="Seleccione"
                    noResultsText="Sin resultados"
                    required
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={4} lg={4}>
                <FormGroup>
                  <ControlLabel>Modelo: </ControlLabel>
                  <Select
                    value={model_id}
                    labelKey="model_name"
                    valueKey="model_id"
                    onChange={(v) => {
                      this.handleChangeSelect(v, 'model')
                    }}
                    options={_.values(filteredModels)}
                    placeholder="Seleccione"
                    noResultsText="Sin resultados"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={4} lg={4}>
                <FormGroup>
                  <ControlLabel>Año: </ControlLabel>
                  <InputMask
                    type="text"
                    value={vehicle_year}
                    onChange={this.handleChange}
                    name="vehicle_year"
                    required
                    placeholder="0000"
                    className="form-control"
                    mask="9999"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={4} lg={4}>
                <FormGroup>
                  <ControlLabel>Color: </ControlLabel>
                  <Select
                    value={color_id}
                    labelKey="color_name"
                    valueKey="color_id"
                    onChange={(v) => {
                      this.handleChangeSelect(v, 'color')
                    }}
                    options={_.values(colors)}
                    placeholder="Seleccione"
                    noResultsText="Sin resultados"
                    required
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={4} lg={4}>
                <FormGroup>
                  <ControlLabel>Unidad: </ControlLabel>
                  <Select
                    value={measure_unit_id}
                    labelKey="measure_unit_name"
                    valueKey="measure_unit_id"
                    onChange={(v) => {
                      this.handleChangeSelect(v, 'measure_unit')
                    }}
                    options={_.values(measureUnits)}
                    placeholder="Seleccione"
                    noResultsText="Sin resultados"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4} lg={4}>
                <FormGroup>
                  <ControlLabel>Dueño: </ControlLabel>
                  <Select
                    value={person_id}
                    labelKey="fullName"
                    valueKey="person_id"
                    onChange={(v) => {
                      this.handleChangeSelect(v, 'person')
                    }}
                    options={getfixedPersons(persons)}
                    placeholder="Seleccione"
                    noResultsText="Sin resultados"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button type="submit" hidden id="submitVehicleForm" />
          </form>
          <PersonModal
            modalOpen={showPersonModal}
            toggleModal={this.togglePersonModal}
            addPerson={addPerson}
            states={states}
            cities={cities}
            contributorTypes={contributorTypes}
            pagePermissions={personPermissions}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={toggleModal}>Cerrar</Button>
          {!!can_edit && (
            <Button
              type="button"
              disabled={loading}
              bsStyle="primary"
              onClick={() => {
                if (this.isFormValid) {
                  document.getElementById('submitVehicleForm').click()
                }
              }}
              className="btn-antd-icon"
            >
              <SaveOutlined />
              {editMode ? ' Modificar' : ' Agregar'}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    )
  }
}

VehicleModal.propTypes = {
  models: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.objectOf(PropTypes.any),
  ]).isRequired,
  brands: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.objectOf(PropTypes.any),
  ]).isRequired,
  measureUnits: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.objectOf(PropTypes.any),
  ]).isRequired,
  selectedVehicle: PropTypes.objectOf(PropTypes.any).isRequired,
  persons: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.objectOf(PropTypes.any),
  ]).isRequired,
  modalOpen: PropTypes.bool.isRequired,
  colors: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.objectOf(PropTypes.any),
  ]).isRequired,
  addVehicle: PropTypes.func.isRequired,
  updateVehicle: PropTypes.oneOfType([PropTypes.func, PropTypes.any])
    .isRequired,
  addPerson: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  modifyVehicleUserApp: PropTypes.oneOfType([PropTypes.func, PropTypes.any])
    .isRequired,
  modifyUserApp: PropTypes.bool.isRequired,
  states: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.objectOf(PropTypes.any),
  ]).isRequired,
  cities: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.objectOf(PropTypes.any),
  ]).isRequired,
  contributorTypes: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.objectOf(PropTypes.any),
  ]).isRequired,
  pagePermissions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.objectOf(PropTypes.any),
  ]).isRequired,
  personPermissions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.objectOf(PropTypes.any),
  ]).isRequired,
}

export default VehicleModal
