import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import debounce from 'lodash/debounce'
import { Divider, Input, Button, Modal } from 'antd'

import PreviousNextButton from '../../../../buttons/PreviousNextButton/PreviousNextButton'
import GradientTextComponent from '../../../Common/GradientText.js/GradientText'
import { useAddReceptionContext } from '../../../../../contexts/AddReceptionContext'
import { addReceptionActions } from '../../../../../reducers/AddReceptionReducer'
import { AdditionalActionButton } from '../AddReception.style'
import SearchBar from '../../../../inputs/SearchBar/SearchBar'
import { useModal } from '../../../../../hooks/useModal'
import {
  createReceptionSteps,
  createReceptionSubSteps,
} from '../../../../../Constants'
import {
  NotificationHandler,
  sanitizeVehiclePlateSearch,
} from '../../../../../Helpers'
import {
  checkClientPhone,
  fetchVehiclesByLicensePlate,
} from '../../../../../api/apiService'
import {
  VehicleStepContainer,
  SelectedVehicle,
  SelectedVehicleCard,
  PhoneValidation,
  ModalButtonsContainer,
  ModalBodyContainer,
  CheckedClient,
} from './VehicleStep.style'

const SEARCH_MIN_CHARS = 3

const VehicleStep = ({ handleNext, workshopType, workshopCountryCode }) => {
  const { state, dispatch } = useAddReceptionContext()
  const {
    selectedVehicle,
    vehicleValue: value,
    client,
    isClientValidated,
    isVehicleSaved,
  } = state

  const [isModalOpen, openModal, closeModal] = useModal()

  const [vehiclesData, setVehiclesData] = useState([])
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const [phoneInputValue, setPhoneInputValue] = useState('')
  const [phoneError, setPhoneError] = useState('')

  const isNewVehicle = selectedVehicle?.ownerId === null

  const fetchSearchResults = async (signal) => {
    const sanitizedValue = sanitizeVehiclePlateSearch(searchValue)
    setIsDataLoading(true)

    try {
      if (sanitizedValue) {
        const data = await fetchVehiclesByLicensePlate(
          sanitizedValue,
          workshopCountryCode || localStorage.getItem('workshopCountryCode'),
          signal
        )

        setVehiclesData(data?.data)
      } else {
        setVehiclesData([])
      }
    } catch (e) {
      NotificationHandler(
        'Error',
        'error',
        'Error al obtener resultados de búsqueda.'
      )
    } finally {
      setIsDataLoading(false)
    }
  }

  const handleSearch = debounce((newValue) => {
    setSearchValue(newValue || '')
  }, 300)

  const handleChange = (newValue) => {
    const selected = vehiclesData.filter(
      (vehicle) => vehicle.vehicleId === newValue?.value
    )

    dispatch({
      type: addReceptionActions.SELECT_VEHICLE,
      payload: {
        selectedVehicle: selected.length > 0 ? selected[0] : null,
        vehicleValue: newValue,
      },
    })

    dispatch({
      type: addReceptionActions.SELECT_CLIENT,
      payload: {
        client: null,
        clientValue: null,
      },
    })

    dispatch({
      type: addReceptionActions.SET_CLIENT_VALIDATED,
      payload: false,
    })

    setVehiclesData([...selected])
  }

  const handleAddVehicle = () => {
    dispatch({
      type: addReceptionActions.SET_SUB_STEP,
      payload: createReceptionSubSteps.ADD_VEHICLE,
    })
    dispatch({
      type: addReceptionActions.SET_VEHICLE_SEARCH_PLATE,
      payload: searchValue.toUpperCase(),
    })
  }

  const handleNewOwner = () => {
    dispatch({
      type: addReceptionActions.SET_STEP,
      payload: createReceptionSteps.CLIENT,
    })
    dispatch({
      type: addReceptionActions.SET_REPLACE_OWNER,
      payload: true,
    })
    dispatch({
      type: addReceptionActions.SET_VEHICLE_SAVED,
      payload: true,
    })
    dispatch({
      type: addReceptionActions.SET_VEHICLE_SAVED,
      payload: true,
    })
  }

  const handlePhoneInputChange = (val) => {
    if (/^\d{0,3}$/.test(val)) {
      setPhoneInputValue(val)
    }
  }

  const validateNumber = async () => {
    if (isVehicleSaved) {
      handleNext()
      return
    }

    if (isNewVehicle || client ) {
      dispatch({
        type: addReceptionActions.SET_VEHICLE_SAVED,
        payload: true,
      })
      handleNext()
    }

    if (selectedVehicle && phoneInputValue.length === 3) {
      const { ownerId } = selectedVehicle
      try {
        const response = await checkClientPhone(ownerId, phoneInputValue)
        const invalid = response.error

        if (invalid) {
          setPhoneError('Los dígitos no coinciden con el teléfono registrado')
        } else {
          dispatch({
            type: addReceptionActions.SELECT_CLIENT,
            payload: {
              client: response.data.length > 0 ? response.data[0] : null,
              clientValue: null,
            },
          })
          dispatch({
            type: addReceptionActions.SET_CLIENT_VALIDATED,
            payload: true,
          })
          dispatch({
            type: addReceptionActions.SET_VEHICLE_SAVED,
            payload: true,
          })
          dispatch({
            type: addReceptionActions.SET_VEHICLE_SAVED,
            payload: true,
          })
          handleNext()
        }
      } catch (e) {
        NotificationHandler(
          'Error',
          'error',
          'No se pudo validar el número de teléfono.'
        )
      }
    }
  }
  const handledRemoveSelected = () => {
    setSearchValue('')
    dispatch({
      type: addReceptionActions.SELECT_VEHICLE,
      payload: {
        selectedVehicle: null,
        vehicleValue: null,
      },
    })
    dispatch({
      type: addReceptionActions.SELECT_CLIENT,
      payload: {
        client: null,
        clientValue: null,
      },
    })
    dispatch({
      type: addReceptionActions.SET_REPLACE_OWNER,
      payload: false,
    })
    dispatch({
      type: addReceptionActions.SET_CLIENT_VALIDATED,
      payload: false,
    })
    setVehiclesData([])
  }

  const continueWithNoClient = () => {
    dispatch({
      type: addReceptionActions.SET_VEHICLE_SAVED,
      payload: true,
    })
    dispatch({
      type: addReceptionActions.SET_VEHICLE_SAVED,
      payload: true,
    })
    handleNext()
  }

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller

    if (searchValue && searchValue.length >= SEARCH_MIN_CHARS) {
      fetchSearchResults(signal)
    } else {
      setVehiclesData([])
    }

    return () => {
      controller.abort()
    }
  }, [searchValue])

  useEffect(() => {
    setPhoneError('')
  }, [phoneInputValue])

  useEffect(() => {
    setPhoneInputValue('')
  }, [value])

  const transformedVehicles = vehiclesData?.map((vehicle, index) => {
    return {
      key: `${vehicle.vehicleId}-${index}`,
      value: vehicle.vehicleId,
      label: vehicle.vehicleName,
    }
  })

  const isNextButtonDisabled = () => {
    if (isVehicleSaved) return false
    if (selectedVehicle) {
      if (!isNewVehicle) {
        return (phoneInputValue.length < 3 || !!phoneError) && !client
      }
      return false
    }
    return true
  }

  return (
    <>
      <main className="new-reception__main">
        <div className="new-reception__main__question">
          <GradientTextComponent text="Selecciona el vehículo *" />
          <i
            className={workshopType === 1 ? 'fas fa-motorcycle' : 'fas fa-car'}
          />
        </div>
        <div className="new-reception__main__content">
          <VehicleStepContainer>
            {!isVehicleSaved && (
              <div>
                <SearchBar
                  id="car-plate"
                  searchValue={searchValue}
                  items={transformedVehicles || []}
                  isLoading={isDataLoading}
                  defaultValue={value}
                  value={value}
                  addItemText="Registrar nuevo vehículo"
                  placeholder="Ingresa la placa del vehículo"
                  label="Busca por placa:"
                  handleValueChange={handleChange}
                  handleSearch={handleSearch}
                  handleOnAdd={handleAddVehicle}
                  minChars={SEARCH_MIN_CHARS}
                />
              </div>
            )}
            {selectedVehicle?.vehicleId ? (
              <>
                <SelectedVehicle>
                  <h4>Vehículo seleccionado:</h4>
                  <SelectedVehicleCard>
                    {!isVehicleSaved && (
                      <button
                        className="icon"
                        type="button"
                        onClick={() => handledRemoveSelected()}
                      >
                        <i className="fas fa-times-circle" />
                      </button>
                    )}
                    <div className="vehicle-plate">
                      <i className="fas fa-motorcycle" />
                      <p>{selectedVehicle?.licensePlate}</p>
                    </div>
                    <div className="vehicle-details">
                      <p>
                        <span>Marca: </span>
                        {selectedVehicle?.brandName}
                      </p>
                      <p>
                        <span>Modelo: </span>
                        {selectedVehicle?.modelName}
                      </p>
                      <p>
                        <span>Año: </span>
                        {selectedVehicle?.vehicleYear}
                      </p>
                    </div>
                  </SelectedVehicleCard>
                </SelectedVehicle>
                {!isNewVehicle && !isClientValidated && !isVehicleSaved ? (
                  <>
                    <Divider style={{ margin: '6px auto' }} />
                    <PhoneValidation>
                      <p>
                        Para continuar, ingresa los{' '}
                        <span>últimos 3 dígitos del número de teléfono</span>{' '}
                        del dueño del vehículo:
                      </p>
                      <div className="input-container">
                        <Input
                          size="large"
                          value={phoneInputValue}
                          status={phoneError ? 'error' : ''}
                          onChange={(e) =>
                            handlePhoneInputChange(e.target.value)
                          }
                          maxLength={3}
                          placeholder="Ingresa los últimos 3 dígitos"
                          style={{
                            margin: '2px',
                            textAlign: 'center',
                            maxWidth: '300px',
                          }}
                        />
                        {phoneError && (
                          <p className="phone-input-error">
                            <i className="fas fa-exclamation-triangle" />{' '}
                            {phoneError}
                          </p>
                        )}
                        <AdditionalActionButton onClick={openModal}>
                          No tengo este dato
                        </AdditionalActionButton>
                      </div>
                      <div>
                        <p>¿Nuevo dueño?</p>
                        <AdditionalActionButton onClick={handleNewOwner}>
                          <p>
                            <i className="fas fa-arrow-right" /> Registrar
                          </p>
                        </AdditionalActionButton>
                      </div>
                    </PhoneValidation>
                  </>
                ) : (
                  !isNewVehicle &&
                  isClientValidated && (
                    <CheckedClient>
                      <i className="fas fa-check-circle" />
                      Cliente validado correctamente
                    </CheckedClient>
                  )
                )}
              </>
            ) : (
              <div className="add-vehicle">
                o
                <AdditionalActionButton onClick={handleAddVehicle}>
                  Agrega un nuevo vehículo
                </AdditionalActionButton>
              </div>
            )}
            <Modal
              className="warn-modal"
              style={{ textAlign: 'center', padding: '3rem 1rem' }}
              open={isModalOpen}
              width={325}
              centered
              onOk={continueWithNoClient}
              onCancel={closeModal}
              okText="Aceptar"
              cancelText="Cancelar"
              footer={[
                <ModalButtonsContainer key="footer">
                  <Button key="back" onClick={closeModal}>
                    Cancelar
                  </Button>
                  <Button
                    key="submit"
                    type="primary"
                    onClick={continueWithNoClient}
                  >
                    Aceptar y continuar
                  </Button>
                </ModalButtonsContainer>,
              ]}
            >
              <ModalBodyContainer>
                <i className="fas fa-exclamation-triangle" />
                <p>
                  Esta búsqueda será registrada para una verificación posterior
                </p>
              </ModalBodyContainer>
            </Modal>
          </VehicleStepContainer>
        </div>
      </main>
      <div className="new-reception__navigation-buttons">
        <div />
        <PreviousNextButton
          isPrevious={false}
          isDisabled={isNextButtonDisabled()}
          onClickHandler={validateNumber}
        />
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  workshopType: state.AppReducer.workshopType,
  workshopCountryCode: state.AppReducer.workshopCountryCode,
})
VehicleStep.defaultProps = {
  workshopCountryCode: undefined,
}

VehicleStep.propTypes = {
  handleNext: PropTypes.func.isRequired,
  workshopType: PropTypes.number.isRequired,
  workshopCountryCode: PropTypes.number,
}

export default connect(mapStateToProps)(VehicleStep)
