import _ from 'lodash'

import {
  GET_PROVIDERS,
  ADD_PROVIDERS,
  UPDATE_PROVIDERS,
  DELETE_PROVIDERS,
} from '../actionTypes/ProviderTypes'

export default (
  state = {
    providers: {},
  },
  action
) => {
  switch (action.type) {
    case GET_PROVIDERS: {
      return {
        ...state,
        providers: action.payload,
      }
    }
    case ADD_PROVIDERS: {
      return {
        ...state,
        providers: {
          ...state.providers,
          [action.payload.provider_id]: action.payload,
        },
      }
    }
    case UPDATE_PROVIDERS: {
      return {
        ...state,
        providers: {
          ...state.providers,
          [action.payload.provider_id]: action.payload,
        },
      }
    }
    case DELETE_PROVIDERS: {
      const newState = _.cloneDeep(state)
      delete newState.providers[action.payload.provider_id]
      return newState
    }

    default: {
      return state
    }
  }
}
