import styled from 'styled-components'

export const Container = styled.div`
  width: 95%;
  margin: 2.5%;
`

export const CardContainer = styled.div`
  width: ${({ width }) => width || '100'}%;
  margin: 0px;
  @media (max-width: 700px) {
    width: 100%;
  }
`
export const CardsContainer = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`
export const CardTitle = styled.div`
  font-size: 20px;
`

export const Card = styled.div`
  border: 3px solid #fafafa;
  padding: 20px;
  width: 100%;
  background-color: #fefefe;
  border-radius: 2px;
  box-shadow: 5px 5px 5px #ccc;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-right: 50px;
  @media (max-width: 850px) {
    margin-right: 0px;
  }
`

export const HeaderText = styled.p`
  font-size: ${({ fontSize }) => fontSize || '20'}px;
  font-weight: ${({ bold }) => bold || 'none'};
  margin: 0px;
`

export const RatingContainer = styled.div`
  width: 30%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  color: orange;
  margin-bottom: 20px;
  &:hover {
    opacity: 0.8;
  }
  @media (max-width: 700px) {
    width: 75%;
  }
`

export const Star = styled.i``

export const Text = styled.p`
  font-size: ${({ fontSize }) => fontSize || '14px'};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'none')};
  margin: 0px auto;
`

export const WorkedTime = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 25px;
  padding: 20px;
`

export const Line = styled.div`
  width: 70%;
  height: 3px;
  margin: 0px 5%;
  background-color: #cccccc;
`
export const FlexWrap = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: ${({ justifyContent }) => justifyContent || 'space-around'};
`

export const ProfileImg = styled.img`
  height: 100%;
  width: 200px;
  vertical-align: bottom;
`
