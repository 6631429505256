import styled from 'styled-components'

export const PackageProcessWrapper = styled.div`
  .ant-collapse {
    border-radius: 10px;
    .ant-collapse-item {
      .ant-collapse-content {
        .ant-collapse-content-box {
          padding: unset;
        }
      }
      .ant-collapse-header {
        .ant-collapse-header-text {
          .label {
            font-weight: 600;
            color: black;
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            span {
              display: flex;
              align-items: center;
              gap: 8px;
            }
            i {
              font-size: 2.3rem;
              color: #e6316b;
            }
          }
        }
      }
    }
    .ant-collapse-item-disabled {
      border-radius: 0px 0px 10px 10px;
      background: linear-gradient(180deg, #45398d 0%, #583484 100%);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      .ant-collapse-header {
        .ant-collapse-header-text {
          color: #ffffff;
          .label-total {
            font-weight: 600;
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
  }
`
export const ExpandIconWrapper = styled.div`
  border-radius: 100%;
  padding: 2px 7px;
  margin-top: 8px;

  i {
    font-size: 1.6rem;
    color: #8d2866;
    transition: transform 0.3s;
  }
  &.no-active {
    background-color: #f7f0ff;
    padding-top: 4px;
  }
  &.active {
    background: linear-gradient(180deg, #ba144d 0%, #583484 100%);
    padding-bottom: 4px;
    i {
      color: #ffffff;
      transform: rotate(180deg);
    }
  }
`
