import React from 'react'
import { Avatar } from 'antd'
import { MechanicAsignationWrapper } from './MechanicAsignation.styles'
import { useModal } from '../../../../../../hooks/useModal'
import AssingMechanicModal from './AssingMechanicModal/AssingMechanicModal'
import { useAddReceptionContext } from '../../../../../../contexts/AddReceptionContext'
import { addReceptionActions } from '../../../../../../reducers/AddReceptionReducer'

const MechanicAsigantion = () => {
  const { state, dispatch } = useAddReceptionContext()
  const { mechanic } = state
  const [isModalOpen, openModal, closeModal] = useModal()
  const defaultImg = '/images/default-user.png'

  const removeSelected = () => {
    dispatch({
      type: addReceptionActions.SET_MECHANIC,
      payload: undefined,
    })
    openModal()
  }
  return (
    <>
      <MechanicAsignationWrapper>
        {!mechanic ? (
          <div className="no-mechanic">
            <button type="button" className="assign-btn" onClick={openModal}>
              <i className="fas fa-wrench" /> Asignar mecánico
            </button>
            <p>
              <i className="fas fa-info-circle" />
              Puedes asignar el mecánico en otro momento
            </p>
          </div>
        ) : (
          <div className="selected-mechanic">
            <p className="header">
              <i className="fas fa-wrench" />
              Mecánico asignado
            </p>
            <div className="avatar-wrapper">
              <div className="avatar">
                <Avatar src={mechanic?.photo || defaultImg} size={40} />
                <p className="mechanic-name">{mechanic.mechanicName}</p>
              </div>
            </div>
            <button type="button" onClick={removeSelected}>
              <i className="fas fa-user-minus" />
              Cambiar Mecánico
            </button>
          </div>
        )}
      </MechanicAsignationWrapper>
      <AssingMechanicModal isModalOpen={isModalOpen} closeModal={closeModal} />
    </>
  )
}

export default MechanicAsigantion
