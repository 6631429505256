import React, { Component } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import DatePicker from 'react-datepicker'
import filterFactory from 'react-bootstrap-table2-filter'
import paginationFactory from 'react-bootstrap-table2-paginator'
import PropTypes from 'prop-types'
import { FormControl } from 'react-bootstrap'
import _ from 'lodash'
import moment from 'moment'
import styled from 'styled-components'
import Select from 'react-select'

import { BREAK_POINTS_DESKTOP_FIRST } from '../../../global/css/breakPoints'

class BootstrapTableWithDynamicPagination extends Component {
  state = {
    currentPagePagination: 1,
    nextPagePagination: 2,
    prevPagePagination: 1,
    size: 10,
    initDate: null,
    formatInitDate: '',
    endDate: null,
    formatEndDate: '',
    query: '',
    sortBy: '',
    orderBy: '',
    person_query: '',
    employee_query: '',
  }

  getStates() {
    const {
      query,
      sortBy,
      orderBy,
      currentPagePagination,
      nextPagePagination,
      prevPagePagination,
      formatInitDate,
      formatEndDate,
      size,
    } = this.state
    return {
      query,
      sortBy,
      orderBy,
      currentPagePagination,
      nextPagePagination,
      prevPagePagination,
      size,
      formatInitDate,
      formatEndDate,
    }
  }

  validateDate = (date, init = true) => {
    if (date === '' || date === null || date === undefined) {
      if (!init) {
        return moment()
      }
      return moment().subtract(30, 'days')
    }
    return date
  }

  handlerInitDateChange = (date) => {
    const validDate = this.validateDate(date)
    this.setState({
      initDate: validDate,
      formatInitDate: moment(validDate).format('YYYY-MM-DD'),
    })
  }

  handlerEndDateChange = (date) => {
    const validDate = this.validateDate(date, false)
    this.setState({
      endDate: validDate,
      formatEndDate: moment(validDate).format('YYYY-MM-DD'),
    })
  }

  handleSortByChange = (type) => {
    const { value } = type
    this.setState({
      sortBy: value,
    })
  }

  handleOrderByChange = (type) => {
    const { value } = type
    this.setState({
      orderBy: value,
    })
  }

  handleFilter = async () => {
    const { size, formatInitDate, formatEndDate, query, sortBy, orderBy } =
      this.state
    const { getPaginatedList } = this.props
    await this.updatePageStates(1)

    getPaginatedList(
      query,
      sortBy,
      orderBy,
      1,
      size,
      formatInitDate,
      formatEndDate
    )
  }

  setFilters = () => {
    const { getPaginatedList } = this.props
    const { size } = this.state

    this.setState({
      query: '',
      sortBy: '',
      orderBy: '',
      initDate: null,
      formatInitDate: '',
      endDate: null,
      formatEndDate: '',
      person_query: '',
      employee_query: '',
    })

    getPaginatedList('', '', '', 1, size, '', '')
  }

  handleQueryChange = (e) => {
    const { value, name } = e.target
    this.setState({
      [name]: value,
      query: value,
    })
  }

  handleOnKeypressEnter = (e) => {
    if (e.key) {
      if (e.key.toLowerCase() === 'enter') {
        this.handleFilter()
      }
    }
  }

  updatePageStates(current, title = '') {
    const {
      data: { totalPages },
    } = this.props
    const { nextPagePagination, prevPagePagination } = this.state

    let currentPage = 1
    let next = 2
    let prev = 1

    switch (title) {
      case 'next page':
        currentPage = nextPagePagination
        next = currentPage === totalPages ? totalPages : currentPage + 1
        prev = currentPage === 1 ? 1 : currentPage - 1
        break
      case 'previous page':
        currentPage = prevPagePagination
        next = currentPage === totalPages ? totalPages : currentPage + 1
        prev = currentPage === 1 ? 1 : currentPage - 1
        break
      case 'first page':
        currentPage = 1
        next = currentPage === totalPages ? totalPages : currentPage + 1
        prev = currentPage
        break
      case 'last page':
        currentPage = totalPages
        next = currentPage
        prev = currentPage === 1 ? 1 : currentPage - 1
        break
      default:
        currentPage = Number.isNaN(current) ? 1 : current
        next = currentPage === totalPages ? totalPages : currentPage + 1
        prev = currentPage === 1 ? 1 : currentPage - 1
        break
    }

    this.setState({
      currentPagePagination: currentPage,
      nextPagePagination: next,
      prevPagePagination: prev,
    })
  }

  render() {
    const {
      keyField,
      data,
      columns,
      optionsPagination,
      getPaginatedList,
      filterByDate,
    } = this.props

    const { initDate, endDate, person_query, employee_query, sortBy, orderBy } =
      this.state

    const customTotal = () => {
      const {
        data: { totalRecords, totalPages },
      } = this.props
      const { currentPagePagination } = this.state

      return (
        <span className="react-bootstrap-table-pagination-total">
          {`Total ${totalRecords} registros. Página ${currentPagePagination} de ${Math.max(
            totalPages,
            1
          )}`}
        </span>
      )
    }

    const pageButtonRenderer = ({ page, title }) => {
      const handleClick = async (e) => {
        e.preventDefault()
        await this.updatePageStates(page, title)
        const {
          query,
          sortBy: paginatedSortBy,
          orderBy: paginatedOrderBy,
          currentPagePagination,
          size,
          formatInitDate,
          formatEndDate,
        } = this.getStates()
        await getPaginatedList(
          query,
          paginatedSortBy,
          paginatedOrderBy,
          currentPagePagination,
          size,
          formatInitDate,
          formatEndDate
        )
      }

      const background = () => {
        const { currentPagePagination } = this.getStates()
        return currentPagePagination === page ? 'rgb(57, 66, 85)' : ''
      }

      const linkStyle = {
        position: 'relative',
        float: 'left',
        padding: '6px 12px',
        marginLeft: '-1px',
        lineHeight: '1.42857143',
        textDecoration: 'none',
        border: '1px solid #ddd',
        backgroundColor: background(),
        color: '#337ab7',
      }

      return (
        <li className="page-item" key={page}>
          <button type="button" style={linkStyle} onClick={handleClick}>
            {page}
          </button>
        </li>
      )
    }

    const options = {
      paginationSize: Math.max(data.totalPages, 1),
      pageStartIndex: 1,
      alwaysShowAllBtns: true,
      showTotal: true,
      paginationTotalRenderer: customTotal,
      pageButtonRenderer,
      hideSizePerPage: true,
      totalSize: data.totalRecords,
      ...optionsPagination,
    }

    const dateStyle = {
      display: 'flex',
      justifyContent: 'flex-end',
      margin: '0px 20px 20px 0px',
    }

    const sortByOptions = [
      {
        label: 'Primer nombre cliente',
        value: 'person_firstname',
      },
      {
        label: 'Segundo nombre cliente',
        value: 'person_lastname',
      },
      {
        label: 'Primer nombre empledo',
        value: 'employee_name',
      },
      {
        label: 'Número de orden',
        value: 'work_order_id',
      },
    ]

    const orderByOptions = [
      {
        label: 'Descendente',
        value: 'desc',
      },
      {
        label: 'Ascendente',
        value: 'asc',
      },
    ]

    return (
      <div>
        {filterByDate && (
          <CustomStyledFilters style={dateStyle}>
            <Select
              value={sortBy}
              onChange={this.handleSortByChange}
              labelKey="label"
              valueKey="value"
              options={sortByOptions}
              placeholder="Ordenar por"
              isClearable={false}
              noResultsText="Sin resultados"
            />
            <Select
              value={orderBy}
              onChange={this.handleOrderByChange}
              labelKey="label"
              valueKey="value"
              options={orderByOptions}
              placeholder="Orden"
              isClearable={false}
              noResultsText="Sin resultados"
            />
            <FormControl
              required
              type="text"
              placeholder="Cliente"
              className="form-control"
              value={person_query}
              name="person_query"
              onChange={this.handleQueryChange}
              onKeyPress={this.handleOnKeypressEnter}
            />
            <FormControl
              required
              type="text"
              placeholder="Empleado"
              className="form-control"
              value={employee_query}
              name="employee_query"
              onChange={this.handleQueryChange}
              onKeyPress={this.handleOnKeypressEnter}
            />
            <DatePicker
              placeholderText="Fecha inicial"
              dateFormat="DD-MM-YYYY"
              selected={initDate}
              className="form-control"
              isClearable
              onChange={(date) => this.handlerInitDateChange(date)}
            />
            <DatePicker
              placeholderText="Fecha final"
              dateFormat="DD-MM-YYYY"
              selected={endDate}
              className="form-control"
              isClearable
              onChange={(date) => this.handlerEndDateChange(date)}
            />
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.setFilters}
              style={{ marginLeft: '10px' }}
            >
              Limpiar filtros
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.handleFilter}
              style={{ marginLeft: '10px' }}
            >
              Buscar
            </button>
          </CustomStyledFilters>
        )}
        <BootstrapTable
          noDataIndication="Sin datos para mostrar"
          keyField={keyField}
          data={_.values(data.data)}
          columns={columns}
          filter={filterFactory()}
          pagination={paginationFactory(options)}
        />
      </div>
    )
  }
}

const CustomStyledFilters = styled.div`
  input {
    max-width: 100px;
    margin-left: 5px;
  }

  .Select {
    width: 120px;
    margin-left: 5px;
  }

  @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
    display: flex;
    flex-direction: column;
    margin: 0px !important;
    padding: 1rem;

    div,
    input.form-control,
    button {
      max-width: 100%;
      width: 100%;
      margin-left: 0px !important;
    }

    .Select {
      width: 100%;
    }

    input,
    .Select,
    button {
      margin-bottom: 1rem;
    }
  }
`

BootstrapTableWithDynamicPagination.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  columns: PropTypes.arrayOf(PropTypes.any).isRequired,
  keyField: PropTypes.string.isRequired,
  getPaginatedList: PropTypes.func.isRequired,
  optionsPagination: PropTypes.func.isRequired,
  filterByDate: PropTypes.func.isRequired,
}

export default BootstrapTableWithDynamicPagination
