import React, { useState, useEffect } from 'react';
import {
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Col,
  Button,
  Row,
  Form,
  HelpBlock,
} from 'react-bootstrap';
import _ from 'lodash';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import PropTypes from 'prop-types';
import generatePass from 'generate-password';
import InputMask from 'react-input-mask';
import ReactToolTip from 'react-tooltip';
import { SaveOutlined } from '@ant-design/icons'
import { isValidPhoneNumber } from 'react-phone-number-input'
import KeyIcon from '../../../icons/KeyIcon'

import PhoneInputStandalone from '../../Common/PhoneInputStandalone/PhoneInputStandalone'
import { getCountryCode, formatPhoneToDB } from '../../../Utilities/formatPhone'


import { getValidationState, showConfirmDialog } from '../../Common';
import { usernameRegex } from '../../../Utilities/validations';
import { NotificationHandler } from '../../../../Helpers';

function EmployeeModal({
  editMode,
  modalOpen,
  toggleModal,
  roles,
  pagePermissions,
  currentUser,
  workshopCountryCode,
  selectedEmployee,
  addEmployee,
  updateEmployee,
  workshop_id,
}) {
  const [state, setState] = useState({
    employee_name: '',
    employee_lastname: '',
    employee_phone: '',
    employee_phone2: '',
    employee_email: '',
    employee_address: '',
    employee_dui: '',
    employee_nit: '',
    employee_code: '',
    employee_start_date: moment(),
    position_id: '',
    employee_username: '',
    employee_password: '',
    employee_active: '',
    rol_id: '',
    employee_metric_goal: '',
    loading: false,
    phoneError: false,
    usernameError: false,
    country_code: null
  });


  const setEmployeeModalState = () => {
    setState({
      ...state,
      employee_name: selectedEmployee.employee_name,
      employee_lastname: selectedEmployee.employee_lastname,
      employee_phone: selectedEmployee.employee_phone,
      country_code: selectedEmployee.country_code,
      employee_phone2: selectedEmployee.employee_phone2,
      employee_email: selectedEmployee.employee_email,
      employee_dui: selectedEmployee.employee_dui,
      employee_nit: selectedEmployee.employee_nit,
      employee_code: selectedEmployee.employee_code,
      employee_address: selectedEmployee.employee_address,
      employee_start_date: moment(selectedEmployee.employee_start_date),
      position_id: selectedEmployee.position_id,
      employee_username: selectedEmployee.employee_username,
      employee_password: selectedEmployee.employee_password,
      employee_active: selectedEmployee.employee_active,
      employee_metric_goal: selectedEmployee.employee_metric_goal,
      rol_id: selectedEmployee.rol_id,
      loading: false,
    });
  };

  const clearComponent = () => {
    setState({
      ...state,
      employee_name: '',
      employee_lastname: '',
      employee_phone: '',
      country_code: '',
      employee_phone2: '',
      employee_email: '',
      employee_address: '',
      employee_dui: '',
      employee_nit: '',
      employee_code: '',
      employee_start_date: moment(),
      position_id: '',
      employee_username: '',
      employee_password: '',
      employee_active: '',
      rol_id: '',
      employee_metric_goal: '',
      loading: false,
    });
  };

  useEffect(() => {
    if (selectedEmployee !== null && selectedEmployee !== undefined) {
      const { length } = Object.keys(selectedEmployee);
      const id = selectedEmployee.employee_id;
      if (length === 0 || id === undefined || id === null) {
        clearComponent();
      } else if (length > 0 && id) {
        setEmployeeModalState();
      }
    }
  }, [selectedEmployee]);

  const handleSubmit = async (e) => {
    e.preventDefault()

    // Use the updated state within this async function
    const updatedState = await new Promise((resolve) => {
      setState((prevState) => {
        const {
          employee_name,
          employee_lastname,
          employee_phone,
          employee_phone2,
          employee_email,
          employee_address,
          employee_dui,
          employee_nit,
          employee_code,
          employee_start_date,
          position_id,
          employee_username,
          employee_password,
          employee_active,
          employee_metric_goal,
          rol_id,
          employee_urlimg,
          country_code,
          usernameError,
          phoneError,
        } = prevState

        const file = new FormData()
        file.append('mechanic_photo', employee_urlimg)

        const employee = {
          employee_id: selectedEmployee?.employee_id || null,
          employee_name,
          employee_lastname,
          employee_phone: formatPhoneToDB(employee_phone),
          employee_phone2,
          employee_email,
          employee_address,
          employee_dui,
          employee_nit,
          employee_code,
          employee_start_date,
          position_id: parseInt(position_id, 10),
          employee_username,
          employee_password,
          employee_active,
          employee_metric_goal,
          rol_id: parseInt(rol_id, 10),
          workshop_id,
          file: employee_urlimg ? file : null,
          country_code,
        }

        if (!usernameError && !phoneError) {
          try {
            if (editMode) {
              showConfirmDialog(
                'Modificar',
                `¿Modificar el empleado: ${employee.employee_name}?`,
                updateEmployee,
                employee,
                () => {},
                toggleModal
              )
            } else {
              clearComponent()
              resolve({ ...prevState, loading: false })
              addEmployee(employee, prevState.employee_urlimg ? file : null)
              // Additional processing if needed
            }
            toggleModal()
          } catch (error) {
            console.error('Error:', error)
          }
        } else {
          NotificationHandler(
            'Formulario inválido',
            'error',
            'Verifica que todos los campos sean válidos e intenta nuevamente.'
          )
        }

        // Return the updated state
        return { ...prevState, loading: false }
      })
    })

    // Now you can use updatedState to access the latest state values.
    // For example, updatedState.employee_phone will have the latest phone value.
    console.log(updatedState)
  }

  const handleChangeSelect = (value, field) => {
    if (value) {
      setState({ ...state, [field]: value[`${field}`] });
    } else {
      setState({ ...state, [field]: null });
    }
  };

  const handlePhoneEdit = (value) => {
    const countryCode = getCountryCode(value);
    setState({
      ...state,
      employee_phone: value,
      country_code: countryCode || '',
      phoneError: value ? !isValidPhoneNumber(value) : null, // Validate phone number here
    });
  };

  const handleUsernameChange = (e) => {
    const { value } = e.target;
    
      setState({
        ...state,
        employee_username: value.toLowerCase(),
        usernameError: !usernameRegex.test(value),
      });
  }

  const handleChange = (e, type) => {
    const { name } = e.target;
    let { value } = e.target;
    const { target } = e;

    if (type === 'double') {
      value = value.replace(/[^0-9.]/g, '');
    } else if (type === 'integer') {
      value = value.replace(/[^0-9]/g, '');
    }

    if (name === 'employee_username') {
      value = value.toLowerCase();
    }

    if (name === 'employee_urlimg') {
      setState({
        ...state,
        employee_urlimg: target.files[0],
      });
    } else {
      setState({
        ...state,
        [name]: value,
      });
    }
  };

  const handleChangeDate = (value) => {
    setState({
      ...state,
      employee_start_date: value,
    });
  };

  let title = '';
  if (editMode && pagePermissions.can_edit) {
    title = 'Modificar';
  } else {
    title = 'Agregar';
  }

  const {
    employee_name,
    employee_lastname,
    employee_phone,
    employee_email,
    employee_address,
    employee_dui,
    employee_code,
    employee_start_date,
    employee_username,
    employee_password,
    employee_metric_goal,
    rol_id,
    phoneError,
    country_code,
    usernameError
  } = state;

  const validatePhone = () => (phoneError ? 'error' : null);

  return (
    <Modal show={modalOpen} onHide={toggleModal}>
      <Modal.Header closeButton>
        <Modal.Title>{title} Empleado</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <FormGroup validationState={getValidationState(employee_name)}>
                <ControlLabel>Nombre: </ControlLabel>
                <FormControl
                  autoFocus
                  name="employee_name"
                  type="text"
                  value={employee_name}
                  onChange={(e) => handleChange(e)}
                  required
                  placeholder="Ingresar"
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup validationState={getValidationState(employee_lastname)}>
                <ControlLabel>Apellidos: </ControlLabel>
                <FormControl
                  name="employee_lastname"
                  type="text"
                  value={employee_lastname}
                  onChange={(e) => handleChange(e)}
                  required
                  placeholder="Ingresar"
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup validationState={getValidationState(employee_dui)}>
                <ControlLabel>Documento de Identidad: </ControlLabel>
                <InputMask
                  name="employee_dui"
                  type="text"
                  value={employee_dui}
                  onChange={(e) => handleChange(e)}
                  className="form-control"
                  required
                  placeholder="Ingresa el # de documento"
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup validationState={validatePhone()}>
                <ControlLabel>Teléfono: </ControlLabel>
                <PhoneInputStandalone
                  name="employee_phone"
                  phone={employee_phone}
                  onPhoneChange={(value) => handlePhoneEdit(value)}
                  setError={(error) => setState({ ...state, phoneError: error })}
                  isError={phoneError}
                  countryCode={country_code || workshopCountryCode || localStorage.getItem('workshopCountryCode')}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup
                validationState={getValidationState(employee_address)}
              >
                <ControlLabel>Dirección: </ControlLabel>
                <FormControl
                  name="employee_address"
                  type="text"
                  value={employee_address}
                  onChange={handleChange}
                  required
                  placeholder="Ingresar"
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup validationState={getValidationState(employee_email)}>
                <ControlLabel>E-mail: </ControlLabel>
                <FormControl
                  name="employee_email"
                  type="email"
                  value={employee_email}
                  onChange={handleChange}
                  placeholder="email@email.com"
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup
                validationState={getValidationState(
                  employee_start_date,
                  true
                )}
              >
                <ControlLabel>Fecha de inicio: </ControlLabel>
                <DatePicker
                  style={{ width: '100%' }}
                  selected={employee_start_date}
                  onChange={handleChangeDate}
                  dateFormat="LLL"
                  className="form-control"
                  required
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup
                validationState={getValidationState(employee_metric_goal)}
              >
                <ControlLabel>Meta mensual: </ControlLabel>
                <FormControl
                  name="employee_metric_goal"
                  type="text"
                  value={employee_metric_goal}
                  onChange={handleChange}
                  required
                  placeholder="$"
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup validationState={getValidationState(rol_id, true)}>
                <ControlLabel>Rol: </ControlLabel>
                <Select
                  placeholder="Roles"
                  options={_.values(roles)}
                  valueKey="rol_id"
                  labelKey="rol_name"
                  value={rol_id}
                  onChange={(value) => {
                    handleChangeSelect(value, 'rol_id')
                  }}
                  // If the selectedEmployee is the same that the user logged, it's not
                  // possible to change his Role
                  disabled={
                    selectedEmployee.employee_id === currentUser.employee_id
                  }
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <ControlLabel>Código (Uso Contable): </ControlLabel>
                <FormControl
                  name="employee_code"
                  type="text"
                  value={employee_code}
                  onChange={handleChange}
                  placeholder="#####"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={5}>
              <FormGroup
                validationState={getValidationState(
                  employee_username,
                  false,
                  'employee_username'
                )}
              >
                <ControlLabel>Usuario: </ControlLabel>
                <FormControl
                  name="employee_username"
                  type="text"
                  value={employee_username}
                  onChange={handleUsernameChange}
                  placeholder="Ingresar"
                  required
                />
                {employee_username.length > 0 && usernameError && (
                  <HelpBlock>
                    Tu usuario debe contener al menos 3 letras sin
                    caracteres especiales ni espacios
                  </HelpBlock>
                )}
                <FormControl.Feedback />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <ControlLabel>Contraseña: </ControlLabel>
                <FormControl
                  name="employee_password"
                  type="text"
                  value={employee_password}
                  onChange={handleChange}
                  placeholder="Ingresar"
                />
              </FormGroup>
            </Col>
            {!!pagePermissions.can_edit && (
              <Col md={3}>
                <FormGroup>
                  <Button
                    onClick={() => {
                      const password = generatePass.generate({
                        length: 10,
                        numbers: true,
                      });
                      setState({
                        ...state,
                        employee_password: password,
                      });
                    }}
                    style={{
                      marginTop: '20px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      fontSize: '1.3rem',
                    }}
                    data-tip="Sugerir contraseña"
                    bsStyle="info"
                    type="button"
                  >
                    <KeyIcon /> Sugerir
                    <ReactToolTip />
                  </Button>
                </FormGroup>
              </Col>

            )}
            <Col sm={12}>
              <FormGroup>
                <ControlLabel>Foto de perfil: </ControlLabel>
                <FormControl
                  name="employee_urlimg"
                  type="file"
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <button type="submit" hidden id="submitEmployeeForm" />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={toggleModal}>Cerrar</Button>
        {pagePermissions.can_edit && (
          <Button
            disabled={state.loading}
            bsStyle="primary"
            onClick={() => {
              document.getElementById('submitEmployeeForm').click();
            }}
            className="btn-antd-icon"
          >
            <SaveOutlined />
            {editMode ? ' Modificar' : ' Agregar'}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

EmployeeModal.propTypes = {
  pagePermissions: PropTypes.objectOf(PropTypes.any).isRequired,
  editMode: PropTypes.bool.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  workshop_id: PropTypes.number.isRequired,
  selectedEmployee: PropTypes.objectOf(PropTypes.any).isRequired,
  currentUser: PropTypes.objectOf(PropTypes.any).isRequired,
  workshopCountryCode: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(PropTypes.object).isRequired,
  toggleModal: PropTypes.func.isRequired,
  addEmployee: PropTypes.func.isRequired,
  updateEmployee: PropTypes.func.isRequired,
};

export default EmployeeModal;
