/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { connect } from 'react-redux'
import { NotificationManager } from 'react-notifications'
import io from 'socket.io-client'
import PropTypes from 'prop-types'

import { NotificationHandler } from './Helpers'
import { Host } from './Constants'
import store from './store'

// eslint-disable-next-line func-names
export default function (ComposedComponent) {
  class Authenticate extends React.Component {
    UNSAFE_componentWillMount() {
      const { logged } = this.props
      const { router } = this.context
      if (!logged) {
        router.history.push('/login')
      }
    }

    componentDidMount() {
      this.setSocket()
    }

    UNSAFE_componentWillUpdate(nextProps) {
      if (!nextProps.logged) {
        const { router } = this.context
        router.history.push('/login')
        NotificationManager.success('Sesión cerrada', 'Usted ha cerrado sesión')
      }
    }

    setSocket = () => {
      const { currentUser } = this.props
      const { router } = this.context
      let { socket } = store.getState().AppReducer
      if (!socket) {
        socket = io.connect(Host)
        socket.on('message', (data) => {
          const action = {
            label: 'Ver',
            callback: () => {
              router.history.push({
                pathname: data.page,
                state: { selectedWorkOrder: data.workOrder },
              })
            },
          }
          NotificationHandler(
            'Notificacion',
            'success',
            data.message,
            '',
            action,
            60
          )
        })
        store.dispatch({
          type: 'SET_SOCKET',
          payload: socket,
        })
      }
      if (currentUser.rol_id === 1) {
        socket.emit('suscribe', `Admin${currentUser.workshop_id}`)
      }
    }

    componentWillUmnount() {
      this.mounted = false
    }

    render() {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <ComposedComponent {...this.props} />
    }
  }

  Authenticate.contextTypes = {
    router: PropTypes.objectOf(PropTypes.any).isRequired,
  }

  Authenticate.propTypes = {
    logged: PropTypes.bool.isRequired,
    currentUser: PropTypes.objectOf(PropTypes.any).isRequired,
  }

  const mapStateToProps = ({ AppReducer }) => ({
    logged: AppReducer.logged,
    currentUser: AppReducer.currentUser,
  })

  return connect(mapStateToProps)(Authenticate)
}
