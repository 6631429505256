import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, FormGroup, FormControl, ControlLabel, Col } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import dasLogo from './das_logo.png'
import BREAK_POINTS from '../../../global/css/breakPoints'
import { requestResetPassword } from '../../../actions/EmployeeAction'

class RequestResetPassword extends Component {
  state = {
    email: '',
    loading: false,
    success: false,
    errorMessage: '',
  }

  UNSAFE_componentWillMount() {
    const { logged, history } = this.props
    if (logged) {
      history.push('/')
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value.trim(),
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.setState({ loading: true })

    const { email } = this.state
    const { requestResetPasswordAction } = this.props

    requestResetPasswordAction(email)
      .then(() => {
        this.setState({
          loading: false,
          success: true,
        })
      })
      .catch((error) => {
        this.setState({
          loading: false,
          errorMessage: error.message,
        })
      })
  }

  componentWillUmnount() {
    this.mounted = false
  }

  render() {
    const { email, errorMessage, loading, success } = this.state
    return (
      <Container>
        <Row>
          <Col xs={12} md={4} className="form">
            <Row className="form__logo">
              <Col xs={12}>
                <img alt="Das logo" src={dasLogo} />
              </Col>
            </Row>
            <Row className="form__header">
              <Col xs={12}>
                {!success && (
                  <p>
                    Si este correo corresponde con el que te registraste te
                    enviaremos un link para reestablecer tu contraseña.
                  </p>
                )}
              </Col>
            </Row>
            {!success && (
              <Row className="form__form">
                <form onSubmit={this.handleSubmit}>
                  <Row>
                    <Col xs={12}>
                      <FormGroup className="flat-form-input">
                        <FormControl
                          type="email"
                          name="email"
                          value={email}
                          onChange={this.handleChange}
                          required
                        />
                        <ControlLabel>Correo</ControlLabel>
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <button type="submit" id="submit" hidden />
                </form>
              </Row>
            )}
            {!success && (
              <Row className="form__buttons">
                <Col xs={12}>
                  <button
                    type="button"
                    disabled={loading}
                    className="button tertiary-bg-color"
                    onClick={() => document.getElementById('submit').click()}
                  >
                    Enviar
                  </button>
                </Col>
              </Row>
            )}
            {success && (
              <Row className="form__success">
                <Col xs={12}>
                  ¡Listo! Se le ha enviado un correo con las intrucciones para
                  configurar su nueva contraseña
                  <p>
                    <NavLink to="/login">Regresar al inicio de sesión</NavLink>
                  </p>
                </Col>
              </Row>
            )}
            <Row className="form__error">
              <Col xs={12}>
                <span>{errorMessage}</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

const Container = styled.div`
  height: 100vh;

  &:nth-child(1) {
    @media (${BREAK_POINTS.TABLET}) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .row {
    margin: 0px;
  }

  .form {
    margin: 0px;
    border-radius: 4px;

    @media (${BREAK_POINTS.TABLET}) {
      width: 42rem;
      padding: 4rem;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    @media (${BREAK_POINTS.DESKTOP_LARGE}) {
      width: 55rem;
    }

    &__header {
      margin-bottom: 2.5rem;

      p {
        font-size: 1.2rem;
        color: var(--secondary-text-color);
      }
    }

    &__logo {
      padding: 6rem 0rem;

      @media (${BREAK_POINTS.TABLET}) {
        padding: 0rem 0rem 4rem 0rem;
      }

      div[class^='col-'] {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      img {
        width: 10rem;

        @media (${BREAK_POINTS.TABLET}) {
          width: 12rem;
        }

        @media (${BREAK_POINTS.DESKTOP}) {
          width: 10rem;
        }

        @media (${BREAK_POINTS.DESKTOP_LARGE}) {
          width: 12rem;
        }

        @media (${BREAK_POINTS.DESKTOP_EXTRA_LARGE}) {
          width: 15rem;
        }
      }
    }

    &__form {
      .flat-form-input {
        margin-bottom: 4rem;
      }
    }

    &__buttons {
      margin-top: 2rem;

      .button {
        margin-bottom: 2rem;
        text-transform: uppercase;
        font-weight: 700;
      }
    }

    &__success {
      text-align: center;
      font-weight: 700;
      padding: 6rem 0rem;
    }

    &__error {
      text-align: center;
      color: var(--error-text-color);
      font-weight: 700;

      span {
        text-decoration: underline;
      }
    }
  }
`

const mapStateToProps = (store) => ({
  logged: store.AppReducer.logged,
})

const mapDispatchToProps = () => ({
  requestResetPasswordAction(email) {
    return requestResetPassword(email)
  },
})

RequestResetPassword.propTypes = {
  logged: PropTypes.bool.isRequired,
  history: PropTypes.objectOf(PropTypes.object).isRequired,
  requestResetPasswordAction: PropTypes.func.isRequired,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestResetPassword)
