// import React from "react";
import swal from 'sweetalert2'

const deleteRegister = (props) => {
  const sweet = {
    title: '¿Eliminar?',
    text: props.messageToDelete(props.row.name),
    type: 'warning',
    confirmButtonText: 'Aceptar',
    showCancelButton: true,
    showLoaderOnConfirm: true,
    preConfirm: () =>
      new Promise((resolve, reject) => {
        props
          .delete(props.row)
          .then((response) => {
            if (response) {
              resolve()
            } else {
              reject()
            }
          })
          .catch(() => {
            reject()
          })
      }),
  }

  swal(sweet)
    .then((response) => {
      if (!response.dismiss) {
        swal({
          title: 'Eliminado',
          text: props.messageOnDeleted(props.row.name),
          type: 'success',
        })
      }
    })
    .catch(() => {
      swal({
        title: 'Error',
        text: `Error al eliminar: ${props.row.name}`,
        type: 'error',
      })
    })
}

export default deleteRegister
