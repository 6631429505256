import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Chart from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'

class ChartComponent extends Component {
  state = {
    idCanvas: `chart-${(Math.random() * 10000).toFixed(0)}`,
  }

  componentDidMount() {
    this.renderChart()
  }

  renderChart = () => {
    const { idCanvas } = this.state
    const { data, labels, type, legend, scale } = this.props

    const plugins = type === 'pie' ? [ChartDataLabels] : []

    const ctx = document.getElementById(idCanvas).getContext('2d')

    // eslint-disable-next-line
    const myChart = new Chart(ctx, {
      type: type || 'bar',
      data: {
        labels: labels || ['Red', 'Blue'],
        datasets: [
          {
            barPercentage: 0.2,
            data: data || [12, 9],
            backgroundColor: ['rgb(5, 13, 13)', 'rgb(250, 80, 81)'],
            borderWidth: 1,
          },
        ],
      },
      plugins,
      options: {
        plugins: {
          datalabels: {
            color: '#FFFF',
            font: {
              weight: 'bold',
            },
            // https://github.com/jtblin/angular-chart.js/issues/597#issuecomment-410932115
            // http://www.java2s.com/example/javascript/chart.js/chartjs-and-data-labels-to-show-percentage-value-in-pie-piece.html
            formatter: (value, ctx_) => {
              const {
                chart: {
                  data: { datasets },
                },
              } = ctx_
              if (datasets.indexOf(ctx_.dataset) === datasets.length - 1) {
                const sum = datasets[0].data.reduce((a, b) => a + b, 0)
                return `${Math.round((value / sum) * 100)}%`
              }
              return ''
            },
          },
        },
        responsive: true,
        pointRadius: 20,
        legend: {
          display: legend,
        },
        scales: scale
          ? {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            }
          : {},
      },
    })
  }

  render() {
    const { idCanvas } = this.state

    return <canvas id={idCanvas} />
  }
}

ChartComponent.defaultProps = {
  legend: false,
  scale: false,
}

ChartComponent.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  legend: PropTypes.bool,
  scale: PropTypes.bool,
}

export default ChartComponent
