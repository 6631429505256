import React, { Component } from 'react'
import { Modal, Button, Row, Col, FormControl} from 'react-bootstrap'
import Select from 'react-select'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { NotificationHandler } from '../../../Helpers'



const backTitle = {
 'background-color' : '#e63946'
}

const styleIcon ={
    'padding-left': '10px',
    'margin-top': '8px',
    'margin-left': '5px',
}

const options = [
    { value: '1', label: 'Busqueda de Respuesto(s)' },
    { value: '2', label: 'Respuesto(s) Equivocado(s)' },
    { value: '3', label: 'Generando Cotización' },
    { value: '4', label: 'Análisis e Investigación Técnica' },
    { value: '5', label: 'Confirmación de precios con proveedor' },
    { value: '6', label: 'Esperando Confirmación del Cliente' },
    { value: '7', label: 'Pieza en Torno' },
    { value: '8', label: 'Almuerzo' },
    { value: '9', label: 'Receso' },
    { value: '10', label: 'Otro' },
  ]

// eslint-disable-next-line react/prefer-stateless-function
class PauseWorkOrderModal extends Component {
    state = {
        pause_reason_id: '',
        comment: '',
    }

    handleInputText = (e) => {
      this.setState({
        comment : e.target.value,
      })
    }

    onChangeSelect = (value) => this.setState({ pause_reason_id: value.value })

    pauseWorkOrder = () =>{
      const {pause_reason_id, comment} = this.state

      const {pauseWorkOrder, toggleModal} = this.props

      if(pause_reason_id !== '' && comment !== '' ){
        pauseWorkOrder(pause_reason_id , comment)
        toggleModal()
      }else if(pause_reason_id !== '' && comment === ''){
        pauseWorkOrder(pause_reason_id , null)
        toggleModal()
      }else{
        NotificationHandler('Error', 'error', 'Tiene que escoger una causa de la pausa')
      }
      
    }

  render() {
    const { modalOpen, toggleModal} = this.props

    const { comment, pause_reason_id } = this.state

    return (
      <Modal show={modalOpen} onHide={toggleModal}>
        <Modal.Header closeButton style={backTitle}>
          <Modal.Title>
            Estas seguro de pausar la orden de trabajo?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
            <PauseContainer>
              <Row>
                  <h2>
                      Pausar Orden de trabajo
                  </h2>

                  <Col xs={12} className='select-custom'>
                    <Select
                        placeholder="Causa de la pausa"
                        options={options}
                        onChange={this.onChangeSelect}
                        value={pause_reason_id}
                        noResultsText="Sin resultados"
                    />    
                  </Col>
                  <Col xs={12} className='select-custom'>
                    <FormControl
                        placeholder="Comentario (Opcional)"
                        type="text"
                        name="comentario"
                        className="text-center"
                        value={comment}
                        onChange={(e) => this.handleInputText(e)}
                    />
                  </Col>
                  <Col xs={12} className="btn-center btn-large">
                    <button
                    onClick={()=>this.pauseWorkOrder()}
                    type="button" 
                    className="red-color-custom">
                        Pausar
                        <i className="fas fa-pause-circle" style={styleIcon} />
                    </button>
                  </Col>
                  <Col xs={12} className="select-custom">
                    <span className="span-text">
                    ADVERTENCIA: El administrador de tu taller y el cliente que estás
                    atendiendo serán notificados de la pausa y no podras continuar otros
                    procesos en esta Órden de Trabajo hasta que explícitamente continues.
                    </span>
                  </Col>
              </Row>
            </PauseContainer>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={toggleModal}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

const PauseContainer = styled.div`
  .btn-down {
    margin-top: 60px;
  }
  .btn-large{
    font-size: 3rem;
    margin-top: 50px;
  }
  .btn-up{
    margin-top: 10px;
  }
  .select-custom{
    padding: 40px 80px 10px 80px;
  }
  .span-text{
      color: #e63946;
  }
  button {
    border-radius: 2px;
    background-color: var(--primary-bg-color);
    border: none;
    outline: none;
    color: var(--tertiary-text-color);
    font-weight: 700;
    padding: 1rem 1.7rem;
    display: flex;
    justify-content: center;
  }
  .btn-center{
    text-align: -webkit-center;
  }
  .red-color-custom{
    background-color: #e63946 !important;
  }
  .border-red-table{
    margin-top: 10px;
    border-color: #e63946;
    border-style: solid;
    margin-left: 30px;
    width: 90%;
    border-radius: 15px;
    text-align: left;
    color: #e63946;
  }
`

PauseWorkOrderModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  pauseWorkOrder:PropTypes.func.isRequired,
}

export default PauseWorkOrderModal
