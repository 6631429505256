import React from 'react'
import _ from 'lodash'

export default ({ packageDetails }) => {
  let contador = 0
  return _.map(packageDetails, (packageDetail, index) => {
    contador += 1
    return (
      <tr key={index}>
        <td>{contador}</td>
        <td>{packageDetail.process_name}</td>
        <td>{packageDetail.process_price}</td>
        <td>{packageDetail.process_estimated_time} mins.</td>
      </tr>
    )
  })
}
