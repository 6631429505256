import React, { Component } from 'react'
import {
  Modal,
  FormGroup,
  ControlLabel,
  Col,
  Button,
  Row,
  Form,
} from 'react-bootstrap'
import _ from 'lodash'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { PropTypes, object, string } from 'prop-types'
import { SaveOutlined } from '@ant-design/icons'
import { getValidationState } from '../Common'
import WorkOrderForm from './WorkOrderForm'
import {
  getPackageDetailsTimeTotal,
  getWorkOrderTotal,
  getTotalTime,
} from './WorkOrderDetails/functions'
import { NotificationHandler } from '../../../Helpers'

class WorkOrderModal extends Component {
  state = {
    loading: false,
    reception_id: null,
    package_id: null,
    package_total: 0,
    employee_id: null,
    mechanic_id: null,
    work_order_time: 0,
    work_order_date: moment(),
    alreadyHadProcessesOnRender: false,
    work_order_type: 1,
    work_order_claim: null,
    work_order_package_discount: 0,
    workOrderDetails: {
      0: {
        work_order_detail_item: null,
        work_order_detail_item_price: 0,
        work_order_detail_workforce_id: null,
        work_order_detail_workforce_price: 0,
        work_order_detail_estimated_time: '',
      },
    },
    processes: {},
    setReceptionIsDone: false,
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedWorkOrder, receptionToWorkOrder, packages, processes } =
      this.props

    const { selectedWorkOrder: prevSelectedWorkOrder } = prevProps

    // console.log(">>", selectedWorkOrder);

    if (
      !_.isEmpty(packages) &&
      !_.isEmpty(processes) &&
      receptionToWorkOrder !== null &&
      receptionToWorkOrder !== undefined &&
      Object.keys(receptionToWorkOrder).length > 0 &&
      prevState.reception_id !== receptionToWorkOrder.reception_id
    ) {
      const { length } = Object.keys(receptionToWorkOrder)
      const id = receptionToWorkOrder.reception_id
      if (length > 0 && id) {
        this.setReception(receptionToWorkOrder)
      }
    } else if (selectedWorkOrder !== prevSelectedWorkOrder) {
      const { length } = Object.keys(selectedWorkOrder)
      const id = selectedWorkOrder.work_order_id
      if (
        length === 0 ||
        id === undefined ||
        id === null ||
        selectedWorkOrder === undefined ||
        selectedWorkOrder === null
      ) {
        this.clearComponent()
      } else if (length > 0 && id) {
        this.setWorkOrderModalState()
      }
    }
  }

  setWorkOrderModalState = () => {
    const { selectedWorkOrder } = this.props

    const {
      package_id,
      budgets: budget,
      work_order_time,
      work_order_real_time,
      mechanic_id,
      work_order_date,
      employee_id,
      budget_id,
      details,
      work_order_package_discount,
      person_id,
      work_order_claim,
      package: work_order_package,
    } = selectedWorkOrder

    let processesOBJ = {}

    const { packages, processes } = this.props
    if (package_id) {
      processesOBJ = this.getPackageProcesses(packages, package_id)
    }

    let newState = {}
    if (work_order_claim) {
      newState = {
        reception_id: budget.reception_id,
        person_id,
        work_order_date: moment(work_order_date),
        work_order_real_time,
        work_order_time,
        mechanic_id,
        processes: processesOBJ.processes,
        employee_id,
        budget_id,
        workOrderDetails: this.getWorkOrderDetails(
          selectedWorkOrder,
          details,
          processes,
          true
        ),
      }
    } else {
      newState = {
        reception_id: budget.reception_id,
        person_id,
        work_order_package_discount,
        work_order_date: moment(work_order_date),
        work_order_real_time,
        work_order_time,
        mechanic_id,
        package_id,
        packageDetails: processesOBJ.packageDetails,
        processes: processesOBJ.processes,
        employee_id,
        budget_id,
        workOrderDetails: this.getWorkOrderDetails(
          selectedWorkOrder,
          details,
          processes,
          true
        ),
      }
      if (work_order_package) {
        newState.package_total = work_order_package.package_price
      } else {
        newState.package_total = ''
      }
    }

    this.setState(newState)
  }

  clearComponent = () => {
    this.setState({
      reception_id: '',
      work_order_date: moment(),
      work_order_real_time: '',
      package_total: 0,
      work_order_package_discount: 0,
      work_order_time: '',
      mechanic_id: '',
      package_id: '',
      package_price: '',
      packageDetails: '',
      processes: [],
      employee_id: '',
      budget_id: '',
      workOrderDetails: {},
      person_id: null,
    })
  }

  handleChangeSelect = (value, field) => {
    if (field === 'reception' && value) {
      this.setReception(value)
    }
    if (value) {
      if (`${field}_id` === 'employee_id') {
        this.setState({ employee_id: value[`${field}_id`] })
      } else if (`${field}_id` === 'mechanic_id') {
        this.setState({ mechanic_id: value.employee_id })
      } else {
        this.setState({ [`${field}_id`]: value[`${field}_id`] })
      }
    } else {
      this.setState({ [`${field}_id`]: null })
    }
  }

  handleChange = (e, type) => {
    const { name } = e.target
    let { value } = e.target
    if (type === 'double') {
      value = value.replace(/[^0-9.]/g, '')
    } else if (type === 'integer') {
      value = value.replace(/[^0-9]/g, '')
    }

    if (name.includes('discount')) {
      value = value.replace(/[^0-9-]/g, '')
      if (value > 100) {
        value = 100
      }
    }

    this.setState({
      [name]: value,
    })
  }

  handleChangeDate = (value) => {
    this.setState({
      product_expire_date: value,
    })
  }

  handleDateInput = (event, date) => {
    this.setState({
      work_order_date: date,
    })
  }

  parseReceptions = (receptions) =>
    _.map(receptions, (reception) => {
      const {
        reception_id,
        vehicle: {
          vehicle_license_plate,
          model: {
            model_name,
            brand: { brand_name },
          },
        },
      } = reception

      return {
        ...reception,
        full: `#${reception_id} - ${vehicle_license_plate} - ${brand_name} - ${model_name}`,
        plate: vehicle_license_plate,
      }
    })

  getPackageProcesses = (packages, package_id) => {
    let processesArray = []
    const packageDetails = []
    const { processes } = this.state
    if (packages[package_id]) {
      let valueIDS = packages[package_id].packageProcess.map(
        (packageP) => packageP.process_id
      )
      processesArray = _.map(processes, (process) => {
        const aux = _.cloneDeep(process)
        if (_.includes(valueIDS, process.process_id)) {
          aux.isUsed = true
          valueIDS = valueIDS.filter((id) => !(id === process.process_id))
        } else {
          aux.isUsed = false
        }
        return aux
      })
      return {
        packageDetails: packages[package_id].packageProcess.map(
          (packageP) => packageP.process
        ),
        processes: _.keyBy(processesArray, 'process_id'),
      }
    }
    return {
      packageDetails,
      processes: _.keyBy(processesArray, 'process_id'),
    }
  }

  getWorkOrderDetails = (workOrder, details, processes, editMode) => {
    const workOrderDetails = {}
    // eslint-disable-next-line consistent-return
    _.forEach(details, (detail, index) => {
      try {
        if (!editMode) {
          workOrderDetails[index] = {
            work_order_detail_id: detail.budget_detail_id,
            work_order_detail_item: detail.budget_detail_item,
            work_order_detail_item_price: detail.budget_detail_item_price,
            recommended_work_order_detail_item_price:
              detail.budget_detail_item_price,
            work_order_detail_workforce_id: detail.budget_detail_workforce_id,
            work_order_detail_workforce_price:
              detail.budget_detail_workforce_price,
            recommended_work_order_detail_workforce_price:
              detail.budget_detail_workforce_price,
            work_order_detail_item_quantity: parseFloat(
              detail.budget_detail_item_quantity
            ),
            work_order_detail_discount: detail.budget_detail_discount,
            work_order_detail_discount_workforce:
              detail.budget_detail_discount_workforce,
            budget_detail_type: detail.budget_detail_type,
            // used to display the item card type in the budget form step
            // the item card type if used in the <BudgetDetail /> component
            type_card:
              detail.budget_detail_workforce_id !== null &&
                detail.budget_detail_workforce_id !== undefined
                ? 'service'
                : 'product',
            ableToEdit: true,
          }
          if (processes[detail.budget_detail_workforce_id]) {
            workOrderDetails[index].work_order_detail_estimated_time =
              processes[
                detail.budget_detail_workforce_id
              ].process_estimated_time
          }
        } else {
          const checkStatusProcess = workOrder.timeTracker.filter((e) => {
            // "e: ", e.process.process_id
            // "detail wfid: ", detail.work_order_detail_workforce_id
            return e.process_id === detail.work_order_detail_workforce_id // utilicé workOrder.timeTracker.process.process_id porque workOrder.timeTracker.work_order_detail_id no se guarda al agregar un servicio editando la orden, esto es un error de antes
          })

          const typeCardService =
            detail.work_order_detail_workforce_id !== null &&
            detail.work_order_detail_workforce_id !== undefined // (expr) ? true : false
          let ableToEdit = true
          if (typeCardService) {
            ableToEdit = checkStatusProcess[0].process_status
              ? checkStatusProcess[0].process_status === 0
              : true
          }
          workOrderDetails[index] = {
            work_order_detail_id: detail.work_order_detail_id,
            work_order_detail_item: detail.work_order_detail_item,
            work_order_detail_item_price: detail.work_order_detail_item_price,
            recommended_work_order_detail_item_price:
              detail.work_order_detail_item_price,
            work_order_detail_workforce_id:
              detail.work_order_detail_workforce_id,
            work_order_detail_workforce_price:
              detail.work_order_detail_workforce_price,
            recommended_work_order_detail_workforce_price:
              detail.work_order_detail_workforce_price,
            work_order_detail_item_quantity:
              detail.work_order_detail_item_quantity,
            work_order_detail_discount: detail.work_order_detail_discount,
            work_order_detail_discount_workforce:
              detail.work_order_detail_discount_workforce,
            budget_detail_type: 0,
            isDeleted: false,
            // used to display the item card type in the budget form step
            // the item card type if used in the <WorkOrderDetail /> component
            type_card: typeCardService ? 'service' : 'product',
            ableToEdit,
          }
          if (processes[detail.work_order_detail_workforce_id]) {
            workOrderDetails[index].work_order_detail_estimated_time =
              processes[
                detail.work_order_detail_workforce_id
              ].process_estimated_time
          }
          // console.log("Salida: ",workOrderDetails);
        }
      } catch (error) {
        // console.log("Error: ", error);
        return error
      }
    })
    return workOrderDetails
  }

  setReception = (reception) => {
    const { budget, reception_payor, reception_id } = reception

    const { packages, processes } = this.props
    if (budget) {
      const state = {}
      state.package_id = budget.package_id
      state.work_order_package_discount = budget.budget_package_discount
      if (budget.package_id) {
        state.package_total = budget.package.package_price
      }
      const processesOBJ = this.getPackageProcesses(packages, state.package_id)
      state.reception_id = reception_id
      if (packages[state.package_id]) {
        state.package_price = packages[state.package_id].package_price
      }
      state.packageDetails = processesOBJ.packageDetails
      state.processes = processesOBJ.processes
      state.employee_id = budget.employee_id
      state.person_id = reception_payor
      state.budget_id = budget.budget_id
      state.workOrderDetails = {}
      if (_.size(processes) > 0) {
        state.workOrderDetails = this.getWorkOrderDetails(
          null, // BE
          budget.details,
          processes
        )
      }
      this.setState(state)
    }
  }

  handleChangeDetail = (e, key, max_quantity) => {
    const { name } = e.target
    let { value } = e.target
    if (
      name === 'work_order_detail_item_quantity' ||
      name === 'work_order_detail_discount' ||
      name === 'work_order_detail_discount_workforce'
    ) {
      value = value.replace(/[^0-9-]/g, '')
      value = parseFloat(value || 0)
      if (name.includes('discount')) {
        if (value > 100) {
          value = 100
        }
      }

      if (name === 'work_order_detail_item_quantity') {
        if (value > max_quantity) {
          value = max_quantity
        }

        /*  if(value == ''){
            value = 1
          }
  
          if(value == 0){
            value = 1
          } */
      }
    }

    /**
     * to remove negatives or extreme decimals
     *
     * zero: 0, 0.0
     * negatives: -10, -1, -0.001111
     * extreme decimals: 0.0000001, 0.001, 0.01232135
     *
     * the minimum price should be 0.01
     *
     * tests --> https://regexr.com/5pqt2
     */
    if (name.includes('price')) {
      if (parseFloat(value) < 0) {
        value = 0.01
      }

      value = `${value}`.replace(
        /(?:^-)|(?:^\d{1,})\.((?:\d{3,})|(?:0{1}\d{2,}))/g,
        0.01
      )
    }

    const { workOrderDetails } = this.state
    this.setState({
      workOrderDetails: {
        ...workOrderDetails,
        [key]: {
          ...workOrderDetails[key],
          [name]: value,
        },
      },
    })
  }

  handleChangeSelectDetail = (value, field, key) => {
    const state = _.cloneDeep(this.state)
    if (!value) {
      state.workOrderDetails[key][field] = null
      if (field === 'work_order_detail_item') {
        state.workOrderDetails[key].work_order_detail_item_quantity = ''
        state.workOrderDetails[key].work_order_detail_item_price = ''
        state.workOrderDetails[key].recommended_work_order_detail_item_price =
          ''
      }
      if (field === 'work_order_detail_workforce_id') {
        state.workOrderDetails[key].work_order_detail_workforce_price = ''
        state.workOrderDetails[key].work_order_detail_estimated_time = ''
      }
    } else if (field === 'work_order_detail_item') {
      state.workOrderDetails[key][field] = value.product_id
      state.workOrderDetails[key].work_order_detail_item_price =
        value.product_price
      state.workOrderDetails[key].recommended_work_order_detail_item_price =
        value.product_price
      state.workOrderDetails[key].work_order_detail_item_quantity = 1
      state.workOrderDetails[key].work_order_detail_item_max_quantity =
        value.product_quantity
    } else if (field === 'work_order_detail_workforce_id') {
      state.workOrderDetails[key][field] = value.process_id
      state.workOrderDetails[key].work_order_detail_workforce_price =
        value.process_price
      state.workOrderDetails[
        key
      ].recommended_work_order_detail_workforce_price = value.process_price
      state.workOrderDetails[key].work_order_detail_estimated_time =
        value.process_estimated_time
    } else if (field === 'budget_detail_type') {
      state.workOrderDetails[key][field] = value.budget_detail_type_id
    }
    this.setState(state)
  }

  addWorkOrderDetail = (typeCard = 'product') => {
    const { workOrderDetails } = this.state

    const lastKey = _.findLastKey(workOrderDetails)
    let newKey
    if (Number.isNaN(lastKey) || lastKey === undefined) {
      newKey = 0
    } else {
      newKey = parseInt(lastKey, 10) + 1
    }
    const state = {
      ...this.state,
    }

    state.workOrderDetails[newKey] = {
      work_order_detail_item: null,
      work_order_detail_item_price: 0,
      recommended_work_order_detail_item_price: 0,
      work_order_detail_workforce_id: null,
      work_order_detail_item_quantity: 0,
      work_order_detail_discount: 0,
      work_order_detail_workforce_price: 0,
      recommended_work_order_detail_workforce_price: 0,
      work_order_detail_estimated_time: '',
      type_card: typeCard,
      ableToEdit: true, // is new register
    }
    // console.log(state.workOrderDetails[newKey]);
    this.setState(state)
  }

  deleteWorkOrderDetail = (key) => {
    const state = _.cloneDeep(this.state)
    if (state.workOrderDetails[key].work_order_detail_id) {
      state.workOrderDetails[key].isDeleted = true
    } else {
      delete state.workOrderDetails[key]
    }
    this.setState(state)
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.setState({ loading: false })
    const {
      selectedWorkOrder,
      toggleModal,
      updateWorkOrder,
      editMode,
      addWorkOrder,
    } = this.props

    const {
      employee_id,
      mechanic_id,
      work_order_date,
      work_order_claim,
      work_order_type,
      budget_id,
      packageDetails,
      workOrderDetails,
      package_total,
      work_order_package_discount,
      reception_id,
      person_id,
    } = this.state

    let { package_id } = this.state

    if (package_id === '') {
      package_id = null
    }

    if (!mechanic_id) {
      NotificationHandler('Error', 'error', 'Falta agregar mecánico')
      return
    }

    const workOrder = {
      work_order_id: selectedWorkOrder.work_order_id || null,
      package_id,
      employee_id,
      work_order_date: moment(work_order_date),
      work_order_time:
        parseFloat(getPackageDetailsTimeTotal(packageDetails, true)) +
        parseFloat(getTotalTime(workOrderDetails)),
      work_order_claim,
      mechanic_id,
      budget_id,
      work_order_type,
      work_order_package_discount: parseFloat(work_order_package_discount || 0),
      work_order_total: parseFloat(
        getWorkOrderTotal(
          workOrderDetails,
          packageDetails,
          true,
          package_total,
          work_order_package_discount
        ) || 0
      ),
      work_order_status_id: 1,
      workOrderDetails,
      reception_id,
      person_id,
    }

    if (!editMode) {
      addWorkOrder({
        ...workOrder,
        work_order_invoiced: 0,
        work_order_status_id: 4,
      })
        .then(() => {
          this.setState({
            loading: false,
          })
        })
        .catch(() => {
          this.setState({
            loading: false,
          })
        })
    } else if (
      selectedWorkOrder.work_order_total > workOrder.work_order_total
    ) {
      updateWorkOrder({ ...workOrder })
        .then(() => {
          this.setState({ loading: false })
        })
        .catch(() => {
          this.setState({ loading: false })
        })
    } else {
      updateWorkOrder({
        ...workOrder,
        work_order_status_id:
          selectedWorkOrder.work_order_status_id === 4
            ? 4
            : selectedWorkOrder.work_order_status_id,
      })
        .then(() => {
          this.setState({ loading: false })
        })
        .catch(() => {
          this.setState({ loading: false })
        })
    }

    toggleModal()
  }

  render() {
    const {
      modalOpen,
      toggleModal,
      editMode,
      packages,
      employees,
      products,
      receptions,
      pagePermissions,
      selectedWorkOrder: { work_order_id },
      mechanics,
      processes,
    } = this.props
    const { loading, reception_id, work_order_date } = this.state

    let can_edit
    if (pagePermissions) {
      ; ({ can_edit } = pagePermissions)
    }

    // Only assignment receptions but without a workOrder
    const filteredReceptions = _.filter(
      receptions,
      (r) => r.budget.workOrder.length === 0
    )

    if (editMode) {
      const receptionToEdit = _.find(
        receptions,
        (r) => r.reception_id === reception_id
      )

      if (receptionToEdit) {
        filteredReceptions.push(receptionToEdit)
      }
    }

    return (
      <Modal show={modalOpen} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode ? 'Modificar' : 'Agregar'} Orden de Trabajo
            {editMode ? ` (#${work_order_id})` : ''}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.handleSubmit}>
            <div>
              <FormGroup validationState={getValidationState('brand', true)}>
                <ControlLabel>Fecha: </ControlLabel>
                <DatePicker
                  selected={work_order_date}
                  dateFormat="LL"
                  className="form-control"
                  readOnly
                  onChange={(date) => this.handleDateInput(date)}
                />
              </FormGroup>
            </div>
            {!editMode && (
              <Row>
                <Col md={12}>
                  <FormGroup
                    validationState={getValidationState('reception', true)}
                  >
                    <ControlLabel>Recepción: </ControlLabel>
                    <Select
                      placeholder="Recepción"
                      options={this.parseReceptions(
                        filteredReceptions.reverse()
                      )}
                      labelKey="full"
                      valueKey="reception_id"
                      value={reception_id}
                      onChange={(value) => {
                        this.handleChangeSelect(value, 'reception')
                      }}
                      required
                      disabled={editMode}
                    />
                  </FormGroup>
                </Col>
              </Row>
            )}

            <WorkOrderForm
              state={this.state}
              handleChangeSelect={this.handleChangeSelect}
              packages={packages}
              employees={employees}
              mechanics={mechanics}
              products={products}
              addWorkOrderDetail={this.addWorkOrderDetail}
              deleteWorkOrderDetail={this.deleteWorkOrderDetail}
              handleChangeDetail={this.handleChangeDetail}
              handleChangeSelectDetail={this.handleChangeSelectDetail}
              handleChange={this.handleChange}
              processes={processes}
              receptions={receptions}
              editMode={editMode}
            />
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button type="submit" hidden id="submitWorkOrderForm" />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={loading} onClick={toggleModal}>
            Cerrar
          </Button>
          {!!can_edit && (
            <Button
              disabled={loading}
              bsStyle="primary"
              onClick={() => {
                document.getElementById('submitWorkOrderForm').click()
              }}
              className="btn-antd-icon"
            >
              <SaveOutlined />
              {editMode ? 'Modificar' : ' Agregar'}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    )
  }
}

WorkOrderModal.propTypes = {
  editMode: PropTypes.bool.isRequired,
  selectedWorkOrder: PropTypes.shape({
    work_order_id: PropTypes.number,
    work_order_date: PropTypes.string,
    package_id: PropTypes.number,
    budgets: PropTypes.objectOf(PropTypes.any),
    work_order_time: PropTypes.number,
    work_order_real_tim: PropTypes.string,
    mechanic_id: PropTypes.number,
    employee_id: PropTypes.number,
    budget_id: PropTypes.number,
    details: PropTypes.arrayOf(PropTypes.object),
    work_order_package_discount: PropTypes.number,
    person_id: PropTypes.number,
    work_order_claim: PropTypes.number,
    package: PropTypes.objectOf(PropTypes.any),
    work_order_status_id: PropTypes.number,
    work_order_total: PropTypes.number,
    work_order_real_time: PropTypes.number,
  }).isRequired,
  packages: PropTypes.objectOf(PropTypes.any).isRequired,
  processes: PropTypes.objectOf(PropTypes.any).isRequired,
  receptionToWorkOrder: PropTypes.oneOfType([object, string]).isRequired,
  toggleModal: PropTypes.func.isRequired,
  addWorkOrder: PropTypes.func.isRequired,
  updateWorkOrder: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  employees: PropTypes.arrayOf(PropTypes.object).isRequired,
  products: PropTypes.objectOf(PropTypes.any).isRequired,
  receptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  pagePermissions: PropTypes.objectOf(PropTypes.any).isRequired,
  mechanics: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default WorkOrderModal
