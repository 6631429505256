import React, { Component } from 'react'
import {
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Col,
  Button,
  Row,
  Form,
} from 'react-bootstrap'
import _ from 'lodash'
import Select from 'react-select'
import PropTypes from 'prop-types'
import { SaveOutlined } from '@ant-design/icons'
import { getValidationState, showConfirmDialog } from '../../Common'

class ModelModal extends Component {
  state = this.getInitialState()

  getInitialState() {
    return {
      model_name: '',
      model_cylinder_capacity: '',
      brand: '',
      loading: false,
      vehicle_type: null,
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedModel } = this.props
    const { selectedModel: prevSelectedModel } = prevProps

    if (selectedModel !== prevSelectedModel) {
      const { length } = Object.keys(selectedModel)
      const id = selectedModel.model_id
      if (
        length === 0 ||
        id === undefined ||
        id === null ||
        selectedModel === undefined ||
        selectedModel === null
      ) {
        this.clearComponent()
      } else if (length > 0 && id) {
        this.setModelModalState()
      }
    }
  }

  setModelModalState() {
    const { selectedModel } = this.props

    this.setState({
      model_name: selectedModel.model_name,
      model_cylinder_capacity: selectedModel.model_cylinder_capacity,
      brand: selectedModel.brand_id,
      vehicle_type: selectedModel.vehicleType.vehicle_type_id,
    })
  }

  clearComponent = () => {
    this.setState({
      model_name: '',
      model_cylinder_capacity: '',
      brand: '',
      loading: false,
      vehicle_type: null,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { selectedModel, toggleModal, updateModel, editMode, addModel } =
      this.props

    const { model_name, model_cylinder_capacity, brand, vehicle_type } =
      this.state

    const model = {
      model_id: selectedModel.model_id || null,
      model_name,
      model_cylinder_capacity: parseInt(model_cylinder_capacity, 10),
      brand_id: parseInt(brand, 10),
      vehicle_type_id: vehicle_type,
    }

    toggleModal()
    if (editMode) {
      showConfirmDialog(
        '¿Modificar?',
        `¿Modificar el modelo: ${model.model_name}`,
        updateModel,
        model,
        () => { },
        toggleModal,
        toggleModal
      )
    } else {
      addModel(model)
        .then(() => {
          this.clearComponent()
        })
        .catch(() => {
          this.setState({ loading: false })
          toggleModal()
        })
    }
  }

  handleChangeSelect = (value, field) => {
    if (value) {
      this.setState({ [field]: value[`${field}_id`] })
    } else {
      this.setState({ [field]: null })
    }
  }

  handleChange = (e) => {
    const { name } = e.target
    let { value } = e.target
    if (e.target.name === 'model_cylinder_capacity') {
      value = value.replace(/[^0-9]/g, '')
    }
    this.setState({
      [name]: value,
    })
  }

  render() {
    const {
      editMode,
      modalOpen,
      toggleModal,
      brands,
      pagePermissions,
      vehicleTypes,
    } = this.props
    const {
      model_name,
      model_cylinder_capacity,
      brand,
      loading,
      vehicle_type,
    } = this.state
    let can_edit = 0
    if (pagePermissions) {
      const affordToEdit = pagePermissions.can_edit
      can_edit = affordToEdit
    }
    return (
      <Modal bsSize="lg" show={modalOpen} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode ? 'Modificar ' : 'Agregar '}
            Modelo
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.handleSubmit}>
            <Row>
              <Col md={6}>
                <FormGroup validationState={getValidationState(model_name)}>
                  <ControlLabel>Nombre: </ControlLabel>
                  <FormControl
                    name="model_name"
                    type="text"
                    value={model_name}
                    onChange={this.handleChange}
                    required
                    placeholder="Ingresar"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup validationState={getValidationState(brand, true)}>
                  <ControlLabel>Marca: </ControlLabel>
                  <Select
                    placeholder="Marca"
                    options={_.values(brands)}
                    labelKey="brand_name"
                    valueKey="brand_id"
                    value={brand}
                    onChange={(value) => {
                      this.handleChangeSelect(value, 'brand')
                    }}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <ControlLabel>Cilindraje: </ControlLabel>
                  <FormControl
                    name="model_cylinder_capacity"
                    type="text"
                    value={model_cylinder_capacity}
                    onChange={this.handleChange}
                    placeholder="####"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={6} lg={6}>
                <FormGroup>
                  <ControlLabel>Tipo de vehículo: </ControlLabel>
                  <Select
                    value={vehicle_type}
                    labelKey="vehicle_type_name"
                    valueKey="vehicle_type_id"
                    onChange={(value) => {
                      this.handleChangeSelect(value, 'vehicle_type')
                    }}
                    options={_.values(vehicleTypes)}
                    placeholder="Seleccione"
                    noResultsText="Sin resultados"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button type="submit" hidden id="submitModelForm" />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={toggleModal}>Cerrar</Button>
          {!!can_edit && (
            <Button
              disabled={loading}
              bsStyle="primary"
              onClick={() => {
                document.getElementById('submitModelForm').click()
              }}
              className="btn-antd-icon"
            >
              <SaveOutlined />
              {editMode ? ' Modificar' : ' Agregar'}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    )
  }
}

ModelModal.propTypes = {
  editMode: PropTypes.bool.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  addModel: PropTypes.func.isRequired,
  updateModel: PropTypes.func.isRequired,
  pagePermissions: PropTypes.objectOf(PropTypes.number).isRequired,
  brands: PropTypes.arrayOf(PropTypes.object).isRequired,
  vehicleTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedModel: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default ModelModal
