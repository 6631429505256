import React, { useState } from 'react'
import AddPhotosModalStyled from './AddPhotosModal.styles'
import MultiplePhotoInput from '../../MultiplePhotoInput/MultiplePhotoInput'
import AddPhotosModalTypes from './AddPhotosModal.types'
import { NotificationHandler } from '../../../../Helpers'
import { addMultimediaService } from '../../../../api/apiAddPhotosService'
import LoaderDynamicText from '../LoaderDynamicText'
import { multimediaTabsIds } from '../../../../Constants'

const AddPhotoModal = ({
  isOpen,
  setOpen,
  setOptionsModal = null,
  setTab,
  receptionId,
  workshopId,
}) => {
  const [photos, setPhotos] = useState([])
  const [loading, setLoading] = useState(false)

  const resetValues = () => {
    setLoading(false)
    setOpen(false)
    setPhotos([])
  }

  const handleCancel = () => {
    if (setOptionsModal) {
      setOptionsModal(true)
    }
    resetValues()
  }
  const handleOk = async () => {
    try {
      setLoading(true)
      await addMultimediaService(receptionId, workshopId, photos)
      await setTab('3', multimediaTabsIds.PHOTOS)
      resetValues()
      NotificationHandler('¡Listo!', 'info', 'Fotos agregadas')
    } catch (error) {
      NotificationHandler(
        'Error',
        'error',
        'No se pudieron agregar las fotografías'
      )
    }
  }
  return (
    <AddPhotosModalStyled
      title="Agregar Fotografías"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      className="add-photos"
      okText="Agregar"
      okButtonProps={{
        style: {
          backgroundColor: photos.length <= 0 ? '#eeeeee' : '#1E1E40',
          display: loading ? 'none' : 'inline-block',
        },
        disabled: photos.length <= 0,
      }}
      cancelButtonProps={{
        style: {
          display: loading ? 'none' : 'inline-block',
        },
      }}
    >
      {loading && (
        <div className="loader">
          <LoaderDynamicText
            textArray={[
              'Cargando fotos...',
              'Guardando fotos...',
              'Actualizando orden de trabajo...',
            ]}
            colored
            interval={1}
          />
        </div>
      )}
      {!loading && (
        <MultiplePhotoInput photos={photos} handleAddPhoto={setPhotos} />
      )}
    </AddPhotosModalStyled>
  )
}

AddPhotoModal.propTypes = AddPhotosModalTypes
export default AddPhotoModal
