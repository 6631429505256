import React from 'react'
import { Button } from 'react-bootstrap'
import ReactToolTip from 'react-tooltip'
// import { textFilter } from 'react-bootstrap-table2-filter'
import { EditFilled, EyeFilled, DeleteFilled, PlusCircleFilled, MinusCircleFilled } from '@ant-design/icons';
import {
  // formatterHeaderStyle,
  formatterHeaderStyleActions,
  columnStyle,
  columnHeaderStyle,
} from '../../../Utilities/TableStyles'

const styleIcons = {
  fontSize: '17px',
}

const editButton = (editProduct, can_edit) => (cell, row) =>
(
  <Button
    onClick={() => {
      editProduct(row)
    }}
    bsStyle="link"
    bsSize="small"
    data-tip={can_edit ? 'Modificar' : 'Ver'}
  >
    {can_edit ? <EditFilled style={styleIcons} /> : <EyeFilled style={styleIcons} />}
    <ReactToolTip />
  </Button>
)

const deleteButton = (deleteProduct) => (cell, row) =>
(
  <Button
    onClick={() => deleteProduct(row)}
    bsStyle="link"
    bsSize="small"
    style={{ color: 'red' }}
    data-tip="Eliminar"
  >
    <DeleteFilled style={styleIcons} />
    <ReactToolTip />
  </Button>
)

const addStockButton = (addStockProduct) => (cell, row) =>
(
  <Button
    onClick={() => addStockProduct(row)}
    bsStyle="link"
    bsSize="small"
    style={{ color: 'green' }}
    data-tip="Agregar stock"
  >
    <PlusCircleFilled style={styleIcons} />
    <ReactToolTip />
  </Button>
)

const removeStockButton = (removeStockProduct) => (cell, row) =>
(
  <Button
    onClick={() => removeStockProduct(row)}
    bsStyle="link"
    bsSize="small"
    style={{ color: 'red' }}
    data-tip="Remover stock"
  >
    <MinusCircleFilled style={styleIcons} />
    <ReactToolTip />
  </Button>
)

const getColumns = (editProduct, deleteProduct, addStockProduct, removeStockProduct, { can_edit, can_delete }) => {
  const columns = [
    {
      dataField: 'product_name',
      text: 'Nombre',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: false /* textFilter({
        delay: 100,
        style: {
          margin: '10px 0 0 0',
          padding: '20px 2%',
        },
        placeholder: 'Buscar',
      }), */
    },
    {
      dataField: 'product_bar_code',
      text: 'Código',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: false /* textFilter({
        delay: 100,
        style: {
          margin: '10px 0 0 0',
          padding: '20px 2%',
        },
        placeholder: 'Buscar',
      }), */
    },
    {
      dataField: 'rack',
      text: 'Anaquel',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: false
    },
    {
      dataField: 'product_price',
      text: 'Precio ($)',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: false,
      formatter: (cell, row) => {
        const price = parseFloat(row.product_price.replace('$', '').replace(',', '.'));
        if (Number.isNaN(price)) {
          return '';
        }
        const formattedPrice = price.toFixed(2);
        return `$${formattedPrice.replace('.', ',')}`;
      },
    },
    {
      dataField: 'product_quantity',
      text: 'Cantidad',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: false /* textFilter({
        delay: 100,
        style: {
          margin: '10px 0 0 0',
          padding: '20px 2%',
        },
        placeholder: 'Buscar',
      }), */
    },
    /* {
      dataField: 'category.category_name',
      text: 'Categoría',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: textFilter({
        delay: 100,
        style: {
          margin: '10px 0 0 0',
          padding: '20px 2%',
        },
        placeholder: 'Buscar',
      }),
    },
    {
      dataField: 'product_cost',
      text: 'Costo ($)',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: textFilter({
        delay: 100,
        style: {
          margin: '10px 0 0 0',
          padding: '20px 2%',
        },
        placeholder: 'Buscar',
      }),
    }, */
    {
      dataField: 'id1',
      className: 'test',
      text: '',
      headerStyle: formatterHeaderStyleActions,
      style: columnHeaderStyle({ textAlign: 'center' }),
      formatter: editButton(editProduct, can_edit),
    },
    {
      dataField: 'id3',
      className: 'test',
      text: '',
      headerStyle: formatterHeaderStyleActions,
      style: columnHeaderStyle({ textAlign: 'center' }),
      formatter: addStockButton(addStockProduct),
    },
    {
      dataField: 'id4',
      className: 'test',
      text: '',
      headerStyle: formatterHeaderStyleActions,
      style: columnHeaderStyle({ textAlign: 'center' }),
      formatter: removeStockButton(removeStockProduct),
    },
  ]
  if (can_delete) {
    columns.push({
      dataField: 'id2',
      text: '',
      headerStyle: formatterHeaderStyleActions,
      style: columnHeaderStyle({ textAlign: 'center' }),
      formatter: deleteButton(deleteProduct),
    })
  }
  return columns
}

export default getColumns
