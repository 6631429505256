import _ from 'lodash'

import {
  GET_BRANDS,
  ADD_BRAND,
  UPDATE_BRAND,
  DELETE_BRAND,
} from '../actionTypes/brandTypes'

const BrandReducer = (
  state = {
    brands: {},
  },
  action
) => {
  if (action.type === GET_BRANDS) {
    return {
      ...state,
      brands: action.payload,
    }
  }
  if (action.type === ADD_BRAND || action.type === UPDATE_BRAND) {
    return {
      ...state,
      brands: {
        ...state.brands,
        [action.payload.brand_id]: action.payload,
      },
    }
  }
  if (action.type === DELETE_BRAND) {
    const newState = _.cloneDeep(state)
    delete newState.brands[action.payload.brand_id]
    return newState
  }
  return state
}

export default BrandReducer
