import styled from 'styled-components'

const AddClientFormWrapper = styled.div`
  height: 100%;

  form {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .switch-wrapper {
      align-self: flex-end;
      display: flex;
      align-items: center;
      gap: 12px;
    }
    .phone-wrapper {
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      .info-disclaimer {
        width: fit-content;
        align-self: center;
      }
    }
    .custom-select {
      margin-top: 8px;
      label {
        font-family: 'Lato', Helvetica, Arial, sans-serif;
        font-weight: 600;
        font-size: 15px;
        background-image: linear-gradient(to right, #1e1e40, #b80667);
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
      }
    }
  }
`
export default AddClientFormWrapper
