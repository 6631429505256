import moment from 'moment'
import _ from 'lodash'
import JsPDF from 'jspdf'
import writtenNumbers from 'written-number'
import { numberFormatter } from '../../../../Helpers'

writtenNumbers.defaults.lang = 'es'

const toMoneyFix = (n = 0) => Math.round(n * 100) / 100

export const getTotalWithoutTaxes = (details = [], pack = {}) => {
  let sums = 0
  sums += pack.totalAffectSale || 0
  details.forEach(
    ({ totalAffectSale }) => {
      sums += totalAffectSale || 0
    }
  )
  return { sums }
}

export const applyWithoutTaxes = ({
  pack = {},
  details = [],
  work_order_package_discount = 0,
}) => {
  let footerDetailsWithoutTaxes = {}

  let newPackage = {}
  if (!_.isEmpty(pack)) {
    const quantity = 1
    const originalPrice = toMoneyFix(pack.package_price)
    const discountPercentage = (work_order_package_discount || 0) / 100
    const totalDiscount = toMoneyFix(originalPrice * discountPercentage)
    const originalPriceWithDiscount = toMoneyFix(originalPrice - totalDiscount)
    const totalAffectSale = toMoneyFix(
      originalPriceWithDiscount * quantity
    )

    newPackage = {
      quantity: 1,
      description: pack.package_name,
      originalPrice,
      discountPercentage,
      totalDiscount,
      originalPriceWithDiscount,
      totalAffectSale,
    }
  }

  const newDetails = []
  if (details.length > 0) {

    let index = 0
    details.forEach((detail) => {
      const {
        process,
        product,
        work_order_detail_item_quantity = 1,
        work_order_detail_discount = 0,
        work_order_detail_item_price = 0,
        work_order_detail_discount_workforce = 0,
        work_order_detail_workforce_price = 0,
      } = detail

      if (product) {
        const quantity = work_order_detail_item_quantity || 1
        const originalPrice = toMoneyFix(work_order_detail_item_price)
        const discountPercentage =
          (toMoneyFix(work_order_detail_discount) || 0) / 100
        const totalDiscount = toMoneyFix(originalPrice * discountPercentage)
        const originalPriceWithDiscount = toMoneyFix(
          originalPrice - totalDiscount
        )

        const totalAffectSale = toMoneyFix(
          originalPriceWithDiscount * quantity
        )

        const newDetail = {
          product_id: product.product_id,
          quantity,
          description: product.product_name,
          originalPrice,
          discountPercentage,
          totalDiscount,
          originalPriceWithDiscount,
          totalAffectSale,
        }
        newDetails[index] = newDetail
        index += 1
      }

      if (process) {
        const quantity = 1
        const originalPrice = toMoneyFix(work_order_detail_workforce_price)
        const discountPercentage =
          (toMoneyFix(work_order_detail_discount_workforce) || 0) / 100
        const totalDiscount = toMoneyFix(originalPrice * discountPercentage)
        const originalPriceWithDiscount = toMoneyFix(
          originalPrice - totalDiscount
        )

        const totalAffectSale = toMoneyFix(
          originalPriceWithDiscount * quantity
        )
        const newDetail = {
          process_id: process.process_id,
          quantity,
          description: process.process_name,
          originalPrice,
          discountPercentage,
          totalDiscount,
          originalPriceWithDiscount,
          totalAffectSale,
        }
        newDetails[index] = newDetail
        index += 1
      }
    })
  }

  const { sums } = getTotalWithoutTaxes(newDetails, newPackage)

  footerDetailsWithoutTaxes = {
    total: sums
  }

  const bodyFieldsWithoutTaxes = {
    pack: newPackage,
    details: newDetails
  }
  return {
    bodyFieldsWithoutTaxes,
    footerDetailsWithoutTaxes,
  }
}

export const updateInvoiceWithoutTaxes = (workOrderToInvoice) => {
    const {
      person: payorTmp,
      details,
      work_order_package_discount,
    } = workOrderToInvoice

    const pack = workOrderToInvoice.package
    let payor = payorTmp
    if (Object.keys(payorTmp).length === 0) {
      payor = workOrderToInvoice.client
    }

    let headerFieldsWithoutTaxes = {}
    let bodyFieldsWithoutTaxes = {}
    let footerDetailsWithoutTaxes = {}

    let retention = 0
    if (payor) {
      const {
        contributors,
        person_address,
        person_firstname,
        person_lastname,
        person_nit,
        person_bussines_activity,
        person_nrc,
        person_type,
        conditions,
      } = payor

      retention = contributors
        ? contributors.contributor_type_retention_percentage
        : 0
      const isBusiness = person_type === 1

      const date = moment().format('DD-MM-YYYY')
      headerFieldsWithoutTaxes = {
        person_name: `${person_firstname} ${person_lastname}`,
        person_address: person_address || '',
        person_nit: person_nit || '',
        contributor_name:
          isBusiness && contributors ? contributors.contributor_type_name : '',
        date,
        person_business_activity: person_bussines_activity || '',
        person_nrc: person_nrc || '',
        condition_name: conditions ? conditions.payment_condition_name : '',
        isBusiness,
      }
    } else {
      const date = moment().format('DD-MM-YYYY')
      headerFieldsWithoutTaxes = {
        person_name: '',
        person_address: '',
        person_nit: '',
        state_name: '',
        contributor_name: '',
        date,
        person_business_activity: '',
        person_nrc: '',
        condition_name: '',
        isBusiness: false,
      }
    }

    ;({ bodyFieldsWithoutTaxes, footerDetailsWithoutTaxes } = applyWithoutTaxes({
      pack,
      details,
      retention,
      work_order_package_discount,
    }))

    return {
      headerFieldsWithoutTaxes,
      bodyFieldsWithoutTaxes,
      footerDetailsWithoutTaxes
    }
  
}

// 'cf' Credito Fiscal || 'f' Factura || 'e' Exportacion
export const printInvoicePDFWithoutTaxes = (headFields, bodyFields) => {
  console.log(headFields);
  console.log(bodyFields);
  const doc = new JsPDF({ orientation: 'p', unit: 'pt', format: 'a4' })
  const { details, pack } = bodyFields
  const { person_name, person_address, person_nit, person_dui } = headFields
  doc.setFontSize(9)
  doc.text(85, 125, person_name)
  doc.text(87, 140, person_address)

  if (person_nit) {
    doc.text(420, 150, person_nit)
  } else if (person_dui) {
    doc.text(person_dui)
  } else {
    doc.text(420, 150, '')
  }

  doc.text(415, 125, moment().format('DD-MM-YYYY'))
  if (!_.isEmpty(pack)) {
    doc.setFontSize(8)
    doc.text(125, 205, pack.description)
    doc.text(342, 205, `${pack.quantity}`)
    doc.text(
      380,
      205,
      numberFormatter.format(pack.originalPrice),
      'right'
    )
    doc.text(400, 205, numberFormatter.format(pack.totalDiscount))
    doc.text(493, 205, numberFormatter.format(pack.totalAffectSale), 'right')
  }
  let contadorPT = 215
  _.forEach(details, (detail) => {
    doc.setFontSize(8)
    doc.text(342, contadorPT, `${detail.quantity}`)
    doc.text(125, contadorPT, detail.description)
    doc.text(
      380,
      contadorPT,
      numberFormatter.format(detail.originalPrice),
      'right'
    )
    doc.text(400, contadorPT, numberFormatter.format(detail.totalDiscount))
    doc.text(
      493,
      contadorPT,
      numberFormatter.format(detail.totalAffectSale),
      'right'
    )
    contadorPT += 10
  })

  doc.setFontSize(7)

  const { sums } = getTotalWithoutTaxes(details, pack)
  const total = sums
  const dollars = Math.floor(parseFloat(total, 10))
  const cents = Math.round((total - dollars) * 100)

  doc.text(
    70,
    355,
    `${writtenNumbers(
      dollars
    ).toUpperCase()} DÓLARES CON ${cents}/100 CENTAVOS.`
  )

  doc.setFontSize(8)

  doc.text(493, 355, numberFormatter.format(sums), { align: 'right' })


  return doc.output('datauristring')
}
