import React from 'react'
import PropTypes from 'prop-types'
import styles from './Chat.module.css'
import TimeAgo from '../../../Utilities/TimeAgo'
import TimeAgoClients from '../../../Utilities/TimeAgoClients'

function ChatMessage({ message }) {
  const renderTimeAgo = () => {
    if (message.userType === 2) {
      // Render the TimeAgoClients component for userType 2
      return <TimeAgoClients inputDate={message.commentDate} />
    }
    // Render the regular TimeAgo component for other user types
    return <TimeAgo inputDate={message.commentDate} />
  }

  return (
    <div
      className={`${styles['chat-message']} ${styles['row-modern']} ${
        message.userType === 2
          ? styles['justify-content-start']
          : styles['justify-content-end']
      }`}
    >
      <div
        className={`${styles['col-auto']} ${
          message.userType === 2 ? styles.sender : styles.receiver
        }`}
      >
        <p style={{ whiteSpace: 'pre-line' }}>
          {' '}
          {/* Maintain formatting */}
          {message.comment}
        </p>
        <div
          className={`${styles['msg-details']} ${
            message.userType === 2
              ? styles['justify-content-start']
              : styles['justify-content-end']
          }`}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
          </svg>
          {renderTimeAgo()}
        </div>
      </div>
    </div>
  )
}

ChatMessage.propTypes = {
  message: PropTypes.shape({
    comment: PropTypes.string.isRequired,
    userType: PropTypes.oneOf([2, null]).isRequired,
    commentDate: PropTypes.string.isRequired
  }).isRequired
}

export default ChatMessage
