/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { useForm, FormProvider } from 'react-hook-form'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'

import PreviousNextButton from '../../../../buttons/PreviousNextButton/PreviousNextButton'
import GradientTextComponent from '../../../Common/GradientText.js/GradientText'
import { addReceptionActions } from '../../../../../reducers/AddReceptionReducer'
import { useAddReceptionContext } from '../../../../../contexts/AddReceptionContext'
import TextFormControl from '../../../Common/TextFormControl/TextFormControl'
import PhoneFormControl from '../../../Common/PhoneFormControl/PhoneFormControl'
import InfoDisclaimer from '../../../Common/InfoDisclaimer/InfoDisclaimer'
import { stringRestrictedRegext } from '../../../../Utilities/validations'
import SuccessModal from '../../../Common/SuccessModal/SuccessModal'
import AddClientFormWrapper from './AddContactForm.style'
import { useModal } from '../../../../../hooks/useModal'
import { createReceptionSteps } from '../../../../../Constants'
import {
  formatPhoneToDB,
  getCountryCode,
} from '../../../../Utilities/formatPhone'

const AddContactForm = ({ workshopCountryCode }) => {
  const [isModalOpen, openModal, closeModal] = useModal()
  const [formError, setFormError] = useState(false)

  const { dispatch } = useAddReceptionContext()

  const methods = useForm({ mode: 'onChange' })

  const {
    handleSubmit,
    watch,
    formState: { errors },
  } = methods

  const phoneValue = watch('contactPhone')

  const WorkShopCountryCode =
    workshopCountryCode || localStorage.getItem('workshopCountryCode')

  const navigateToSelectClient = () => {
    dispatch({ type: addReceptionActions.SET_SUB_STEP, payload: null })
  }

  const navigateToClient = () => {
    dispatch({ type: addReceptionActions.SET_SUB_STEP, payload: null })
    dispatch({
      type: addReceptionActions.SET_STEP,
      payload: createReceptionSteps.CLIENT,
    })
  }

  const handleCloseSuccessModal = () => {
    navigateToClient()
    closeModal()
  }

  const onSubmit = async (data) => {
    if (!formError) {
      dispatch({
        type: addReceptionActions.SET_CONTACT,
        payload: {
          ...data,
          contactPhone: formatPhoneToDB(data.contactPhone),
          contactCountryCode: getCountryCode(data.contactPhone),
        },
      })
      openModal()
      setTimeout(() => {
        handleCloseSuccessModal()
      }, 2000)
    }
  }

  const validateForm = () => {
    if (phoneValue && isValidPhoneNumber(phoneValue)) {
      setFormError(false)
    } else {
      setFormError(true)
    }
  }

  useEffect(() => {
    validateForm()
  }, [phoneValue])

  return (
    <>
      <main className="new-reception__main">
        <div className="new-reception__main__question">
          <GradientTextComponent text="Contacto" />
          <InfoDisclaimer message="Para enviar mensajes de seguimiento de la orden de trabajo por WhatsApp." />
        </div>
        <div className="new-reception__main__content">
          <AddClientFormWrapper>
            <FormProvider {...methods}>
              <form>
                <TextFormControl
                  label="Nombre y apellido"
                  name="contactName"
                  error={errors?.contactName?.message}
                  required
                  requiredMessage="El nombre es un campo requerido."
                  pattern={stringRestrictedRegext}
                  patternMessage="Ingrese un nombre valido."
                />
                <div className="phone-wrapper">
                  <PhoneFormControl
                    label="Teléfono"
                    name="contactPhone"
                    required
                    error={errors?.contactPhone?.message}
                    requiredMessage="El teléfono es un campo requerido."
                    workShopCode={WorkShopCountryCode}
                  />
                </div>
              </form>
            </FormProvider>
          </AddClientFormWrapper>
        </div>
      </main>
      <div className="new-reception__navigation-buttons">
        <PreviousNextButton
          isPrevious
          onClickHandler={navigateToSelectClient}
        />
        <PreviousNextButton
          isPrevious={false}
          nextText="Guardar"
          onClickHandler={handleSubmit(onSubmit)}
        />
      </div>
      <SuccessModal
        isModalOpen={isModalOpen}
        closeModal={handleCloseSuccessModal}
        text="¡Contacto guardado!"
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  workshopCountryCode: state.AppReducer.workshopCountryCode,
})

AddContactForm.defaultProps = {
  workshopCountryCode: '',
}

AddContactForm.propTypes = {
  workshopCountryCode: PropTypes.any,
}

export default connect(mapStateToProps)(AddContactForm)
