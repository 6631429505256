import styled from 'styled-components'
import { BREAK_POINTS_DESKTOP_FIRST } from '../../../../global/css/breakPoints'

const DateRangeWrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-start;
  align-items: center;

  .icon {
    font-size: 2.5rem;
    color: #bdbdbd;
  }
  .calendar {
    position: relative;
    display: flex;
    gap: 8px;
    align-items: center;
    .guion {
      height: 0.3rem;
      width: 2rem;
      background-color: #bdbdbd;
    }

    .react-datepicker-popper {
      .react-datepicker__year-dropdown {
        .react-datepicker__year-option {
          padding: 8px 0;
          .react-datepicker__navigation--years-upcoming {
            top: -4px;
          }
          .react-datepicker__navigation--years-previous{
            top: 4px;
          }
        }
      }
      .react-datepicker {
        .react-datepicker__month-container {
          .react-datepicker__month {
            .react-datepicker__week {
              .react-datepicker__day--in-selecting-range,
              .react-datepicker__day--in-range {
                background-color: #1e1e40;
              }
              .react-datepicker__day--selected {
                background-color: rgb(140, 35, 106);
              }
              .react-datepicker__day {
                padding: 4px 6px;
                width: 2.6rem;
              }
            }
          }
        }
      }
    }
  }

  input {
    border-radius: 8px;
    border: 0.8px solid #c7c7c7;
    padding: 4px 8px;
    &:focus {
      outline: none;
    }
  }
  @media (${BREAK_POINTS_DESKTOP_FIRST.DESKTOP}) {
    width: 100%;
  }

  @media (${BREAK_POINTS_DESKTOP_FIRST.MOBILE}) {
    gap: 8px;

    .icon {
      font-size: 3rem;
      min-width: 55px;
    }
    .calendar {
      width: 100%;
      flex-direction: column;
      .guion {
        display: none;
      }
      .react-datepicker-wrapper,
      .react-datepicker__input-container,
      .react-datepicker__input-container input {
        display: block;
        width: 100%;
        min-width: unset;
      }
    }
  }
`

export default DateRangeWrapper
