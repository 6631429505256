/* eslint-disable consistent-return */
import { Host, multimediaTypes } from '../Constants'

const createFormData = (receptionId, workshopId, photos, type) => {
  const formData = new FormData()
  formData.append('receptionId', receptionId)
  formData.append('workshopId', workshopId)
  formData.append('type', type)
  const photosList = photos.map((image) => image.originFileObj)
  photosList.forEach((photo) => {
    formData.append('files', photo)
  })

  return formData
}

export const addMultimediaService = async (
  receptionId,
  workshopId,
  multimediaArray,
  isReception = false,
  type = multimediaTypes.IMAGE,
  signal = undefined
) => {
  try {
    const formData = createFormData(
      receptionId,
      workshopId,
      multimediaArray,
      type
    )
    const token = localStorage.getItem('jwtToken')
    const url = `${Host}api/reception_photos${isReception ? '/create' : ''}`

    const response = await fetch(url, {
      method: 'POST',
      signal,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })

    const data = await response.json()
    return data
  } catch (error) {
    if (error.name !== 'AbortError') {
      throw error
    }
    throw ('Error agregando multimedia', error)
  }
}
