import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, FormGroup, FormControl, ControlLabel, Col } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import queryString from 'query-string'

import dasLogo from './das_logo.png'
import BREAK_POINTS from '../../../global/css/breakPoints'
import { resetPassword } from '../../../actions/EmployeeAction'

class ResetPassword extends Component {
  state = {
    password: '',
    confirm_password: '',
    token: '',
    passwords_not_match: true,
    loading: false,
    success: false,
    errorMessage: '',
  }

  UNSAFE_componentWillMount() {
    const { logged, history, location } = this.props
    const parsed = queryString.parse(location.id)

    if (Object.keys(parsed).length > 0) {
      this.setState({
        token: parsed.token,
      })
    }

    if (logged) {
      history.push('/')
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value.trim() }, () => {
      if (this.passwordsNotMatch()) {
        this.setState({
          passwords_not_match: true,
          errorMessage: 'Las contraseñas no coinciden',
        })
      } else {
        this.setState({
          passwords_not_match: false,
          errorMessage: '',
        })
      }
    })
  }

  passwordsNotMatch = () => {
    const { password, confirm_password } = this.state
    return password !== confirm_password
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.setState({ loading: true })

    const { password, token } = this.state
    const { resetPasswordAction } = this.props

    resetPasswordAction(password, token)
      .then(() => {
        this.setState({
          loading: false,
          success: true,
        })
      })
      .catch(() => {
        this.setState({
          loading: false,
          errorMessage: '',
        })
      })
  }

  componentWillUmnount() {
    this.mounted = false
  }

  render() {
    const {
      password,
      confirm_password,
      errorMessage,
      passwords_not_match,
      loading,
      success,
    } = this.state
    return (
      <Container>
        <Row>
          <Col xs={12} md={4} className="form">
            <Row className="form__logo">
              <Col xs={12}>
                <img alt="Das logo" src={dasLogo} />
              </Col>
            </Row>
            <Row className="form__header">
              <Col xs={12}>
                <h1>Reestablecer Contraseña</h1>
                {!success && (
                  <p>Ingresa tu nueva contraseña y luego repítela</p>
                )}
              </Col>
            </Row>
            {!success && (
              <Row className="form__form">
                <form onSubmit={this.handleSubmit}>
                  <Row>
                    <Col xs={12}>
                      <FormGroup className="flat-form-input">
                        <FormControl
                          type="text"
                          name="password"
                          value={password}
                          onChange={this.handleChange}
                          required
                        />
                        <ControlLabel>Nueva Contraseña</ControlLabel>
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <FormGroup className="flat-form-input">
                        <FormControl
                          name="confirm_password"
                          type="text"
                          value={confirm_password}
                          onChange={this.handleChange}
                          required
                        />
                        <ControlLabel>Repetir Contraseña</ControlLabel>
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <button type="submit" id="submit" hidden />
                </form>
              </Row>
            )}
            {!success && (
              <Row className="form__buttons">
                <Col xs={12}>
                  <button
                    type="button"
                    disabled={passwords_not_match || loading}
                    className="button tertiary-bg-color"
                    onClick={() => document.getElementById('submit').click()}
                  >
                    Guardar
                  </button>
                </Col>
              </Row>
            )}
            {success && (
              <Row className="form__success">
                <Col xs={12}>
                  ¡Listo! Su contraseña ha sido reestrablecida
                  <p>
                    <NavLink to="/login">Iniciar sesión de nuevo</NavLink>
                  </p>
                </Col>
              </Row>
            )}
            <Row className="form__error">
              <Col xs={12}>
                <span>{errorMessage}</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

const Container = styled.div`
  height: 100vh;

  &:nth-child(1) {
    @media (${BREAK_POINTS.TABLET}) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .row {
    margin: 0px;
  }

  .form {
    margin: 0px;
    border-radius: 4px;

    @media (${BREAK_POINTS.TABLET}) {
      width: 42rem;
      padding: 4rem;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    @media (${BREAK_POINTS.DESKTOP_LARGE}) {
      width: 55rem;
    }

    &__header {
      margin-bottom: 2.5rem;

      h1 {
        font-size: 2rem;
        font-weight: 700;
        color: var(--primary-text-color);
      }

      p {
        font-size: 1.2rem;
        color: var(--secondary-text-color);
      }
    }

    &__logo {
      padding: 6rem 0rem;

      @media (${BREAK_POINTS.TABLET}) {
        padding: 0rem 0rem 4rem 0rem;
      }

      div[class^='col-'] {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      img {
        width: 10rem;

        @media (${BREAK_POINTS.TABLET}) {
          width: 12rem;
        }

        @media (${BREAK_POINTS.DESKTOP}) {
          width: 10rem;
        }

        @media (${BREAK_POINTS.DESKTOP_LARGE}) {
          width: 12rem;
        }

        @media (${BREAK_POINTS.DESKTOP_EXTRA_LARGE}) {
          width: 15rem;
        }
      }
    }

    &__form {
      .flat-form-input {
        margin-bottom: 4rem;
      }
    }

    &__buttons {
      margin-top: 2rem;

      .button {
        margin-bottom: 2rem;
        text-transform: uppercase;
        font-weight: 700;
      }
    }

    &__success {
      text-align: center;
      font-weight: 700;
      padding: 6rem 0rem;
    }

    &__error {
      text-align: center;
      color: var(--error-text-color);
      font-weight: 700;

      span {
        text-decoration: underline;
      }
    }
  }
`

const mapStateToProps = (store) => ({
  logged: store.AppReducer.logged,
})

const mapDispatchToProps = () => ({
  resetPasswordAction(newPassword, token) {
    return resetPassword(newPassword, token)
  },
})

ResetPassword.propTypes = {
  logged: PropTypes.bool.isRequired,
  history: PropTypes.objectOf(PropTypes.object).isRequired,
  location: PropTypes.objectOf(PropTypes.object).isRequired,
  resetPasswordAction: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
