import _ from 'lodash'

export const getPackageDetailsTotal = (
  packageDetails,
  returnAsFloat,
  packageTotal,
  discount
) => {
  let total = 0
  if (packageTotal) {
    total = packageTotal
    if (discount) {
      total -= total * (discount / 100)
    }
    return total.toLocaleString('en', { style: 'currency', currency: 'USD' })
  }
  _.forEach(packageDetails, (detail) => {
    total += parseFloat(detail.process_price || 0)
  })
  if (returnAsFloat) {
    return total
  }
  return total.toLocaleString('en', { style: 'currency', currency: 'USD' })
}

export const getWorkOrderTotal = (
  workOrderDetail,
  packageDetails,
  returnAsFloat,
  packageTotal,
  discount
) => {
  let total = 0
  let totalProducts = 0
  let totalWorkforce = 0
  _.forEach(workOrderDetail, (detail) => {
    if (!detail.isDeleted) {
      totalProducts = parseFloat(
        detail.work_order_detail_item_price *
        detail.work_order_detail_item_quantity
      )

      if (detail.work_order_detail_discount) {
        totalProducts -=
          totalProducts * (detail.work_order_detail_discount / 100)
      }

      totalWorkforce = parseFloat(detail.work_order_detail_workforce_price || 0)

      if (detail.work_order_detail_discount_workforce) {
        totalWorkforce -=
          totalWorkforce * (detail.work_order_detail_discount_workforce / 100)
      }

      total += parseFloat(totalProducts || 0) + parseFloat(totalWorkforce || 0)
    }
  })

  if (packageTotal) {
    let totalPackage = packageTotal
    if (discount) {
      totalPackage -= packageTotal * (discount / 100)
    }
    total += totalPackage
  }

  if (returnAsFloat) {
    return total
  }

  return total.toLocaleString('en', { style: 'currency', currency: 'USD' })
}

// eslint-disable-next-line no-unused-vars
const toHours = (tiempo) => {
  let hours = 0
  let minutos = 0
  hours = parseInt(tiempo / 60, 10)
  minutos = tiempo - hours * 60
  return `${hours > 9 ? hours : `0${hours}`}:${minutos > 9 ? minutos : `0${minutos}`
    } horas`
}

export const getTotalTime = (workOrderDetail) => {
  let total = 0
  _.forEach(workOrderDetail, (detail) => {
    if (!detail.isDeleted) {
      total += parseInt(detail.work_order_detail_estimated_time || 0, 10)
    }
  })

  return parseInt(total, 10)
}

export const getFormatTime = (time) => {
  const seconds = Number(time * 60);
  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor(seconds % (3600 * 24) / 3600);
  const m = Math.floor(seconds % 3600 / 60);
  const s = Math.floor(seconds % 60);

  const dDisplay = d > 0 ? d + (d === 1 ? " día, " : " días, ") : "";
  const hDisplay = h > 0 ? h + (h === 1 ? " hora, " : " horas, ") : "";
  const mDisplay = m > 0 ? m + (m === 1 ? " minuto " : " minutos ") : "";
  const sDisplay = s > 0 ? s + (s === 1 ? " segundo" : " segundos") : "";
  return dDisplay + hDisplay + mDisplay + sDisplay;
}

export const getPackageDetailsTimeTotal = (
  packageDetails,
  returnJustNumber = false
) => {
  let time = 0
  _.forEach(packageDetails, (detail) => {
    time += parseInt(detail.process_estimated_time, 10) || 0
  })
  if (returnJustNumber) {
    return `${time}`
  }
  return `${parseInt(time, 10)}`
}

export const getTotalOrder = (packageTotal, detailsTotal, discount) => {
  let totalDiscount = packageTotal
  if (discount) {
    totalDiscount -= totalDiscount * (parseFloat(discount) / 100)
  }
  const total =
    parseFloat(totalDiscount || 0) +
    parseFloat(String(detailsTotal)?.replace(/[^0-9-.]/g, '') || 0)
  return total.toLocaleString('en', { style: 'currency', currency: 'USD' })
}