import axios from 'axios'
import _ from 'lodash'

import { addMultimediaService } from '../api/apiAddPhotosService'
import { sendBudget, updateBudget } from './BudgetActions'
import { NotificationHandler } from '../Helpers'
import {
  GET_RECEPTIONS,
  CLEAN_RECEPTION,
  UPDATE_RECEPTION,
  DELETE_RECEPTION,
  DELETE_RECEPTION_IMAGES,
  SET_RECEPTION_TO_WORK_ORDER,
  SET_LOADING,
} from '../actionTypes/ReceptionTypes'

const getReceptionsAction = (payload) => ({
  type: GET_RECEPTIONS,
  payload,
})

const updateReceptionAction = (payload) => ({
  type: UPDATE_RECEPTION,
  payload,
})

export const deleteReceptionAction = (payload) => ({
  type: DELETE_RECEPTION,
  payload,
})

export const cleanReceptionAction = () => ({
  type: CLEAN_RECEPTION,
})

export const setReceptionToWorkOrder = (payload) => ({
  type: SET_RECEPTION_TO_WORK_ORDER,
  payload,
})

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
})

const sendItems = (items, receptionId) =>
  new Promise((resolve, reject) => {
    const itemsQuantity = _.size(items)
    let sentItems = 0
    if (itemsQuantity === 0) {
      resolve()
    }
    _.forEach(items, (item) => {
      axios
        .post('/api/item_values', {
          item_id: item.item_id,
          item_value_comment: item.comment,
          reception_id: receptionId,
        })
        .then((itemResponse) => {
          if (!itemResponse.data.error) {
            sentItems += 1
            if (sentItems === itemsQuantity) {
              resolve()
            }
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al cargar la foto de recepción',
              itemResponse.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al cargar la foto de recepción',
            error
          )
        })
    })
  })

const sendPhotos = async (receptionId, workShopId, photos) => {
  try {
    await addMultimediaService(receptionId, workShopId, photos, true)
  } catch {
    NotificationHandler(
      'Problema al cargar fotos',
      'warning',
      `Hubo problemas cargando las fotos en la recepción #${receptionId}, puedes intentarlo nuevamente al crear la orden.`,
      null,
      6
    )
  }
}
export const getReceptions = (forceFetch) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const receptionsQuantity = _.size(getState().ReceptionReducer.receptions)
    if (receptionsQuantity === 0 || forceFetch) {
      axios
        .get('/api/receptions')
        .then((response) => {
          if (!response.data.error) {
            const receptions = _.keyBy(response.data.data, 'reception_id')
            dispatch(getReceptionsAction(receptions))
            resolve(response.data)
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al obtener recepciones',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al obtener recepciones',
            error
          )
        })
    } else {
      resolve({})
    }
  })

export const addReception =
  (reception, items, budget, files) => async (dispatch, getState) => {
    dispatch(setLoading(true))

    try {
      const { workshop_id, employee_id } = getState().AppReducer.currentUser
      const formData = new FormData()
      formData.append('data', JSON.stringify({ ...reception, workshop_id }))

      const response = await axios.post('/api/receptions', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })

      if (!response.data.error) {
        const filteredItems = _.filter(items, (item) => item.checked)
        const receptionId = response.data.data.reception_id
        const workShopId = response.data.data.workshop_id

        await Promise.all([
          sendItems(filteredItems, receptionId),
          sendBudget({ ...budget, reception_id: receptionId, employee_id }),
        ])

        if (files.length > 0) {
          sendPhotos(receptionId, workShopId, files)
        }
        NotificationHandler('¡Listo!', 'success', 'Recepción creada.')
      } else {
        NotificationHandler(
          'Error',
          'error',
          'Hubo problemas creando la recepción, intenta nuevamente.',
          response.data.data
        )
      }
    } catch (error) {
      NotificationHandler(
        'Error',
        'error',
        'Hubo problemas creando la recepción, intenta nuevamente.',
        error
      )
    } finally {
      dispatch(getReceptions(true))
      dispatch(setLoading(false))
    }
  }

const updateItems = (items) =>
  new Promise((resolve, reject) => {
    const itemPromises = []
    _.forEach(items, (item) => {
      if (!item.toDelete) {
        itemPromises.push(
          new Promise((resolvePut, rejectPut) => {
            axios
              .put(`/api/item_values/${item.item_value_id}`, {
                item_value_comment: item.comment,
              })
              .then((response) => {
                if (!response.data.error) {
                  resolvePut()
                } else {
                  rejectPut()
                  NotificationHandler(
                    'Error',
                    'error',
                    'Error al actualizar el ítem value',
                    response.data.data
                  )
                }
              })
              .catch((error) => {
                rejectPut()
                NotificationHandler(
                  'Error',
                  'error',
                  'Error al actualizar el ítem value',
                  error
                )
              })
          })
        )
      } else {
        itemPromises.push(
          new Promise((resolveDelete, rejectDelete) => {
            axios
              .delete(`/api/item_values/${item.item_value_id}`)
              .then((response) => {
                if (!response.data.error) {
                  resolveDelete()
                } else {
                  rejectDelete()
                  NotificationHandler(
                    'Error',
                    'error',
                    'Error al actualizar el ítem value',
                    response.data.data
                  )
                }
              })
              .catch((error) => {
                rejectDelete()
                NotificationHandler(
                  'Error',
                  'error',
                  'Error al actualizar el ítem value',
                  error
                )
              })
          })
        )
      }
    })
    Promise.all(itemPromises)
      .then(() => {
        resolve()
      })
      .catch((error) => {
        reject()
        NotificationHandler(
          'Error',
          'error',
          'Error al actualizar el ítem value',
          error
        )
      })
  })
export const updateReception =
  ({ reception, budget }, receptionItems) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const { workshop_id } = getState().AppReducer.currentUser
      if (reception.reception_id) {
        axios
          .put(`/api/receptions/${reception.reception_id}`, {
            ...reception,
            workshop_id,
          })
          .then((response) => {
            if (!response.data.error) {
              const items = {
                itemsToAdd: [],
                itemsToUpdate: [],
              }
              _.forEach(receptionItems, (item) => {
                if (item.checked && item.item_value_id) {
                  items.itemsToUpdate.push(item)
                } else if (item.checked && !item.item_value_id) {
                  items.itemsToAdd.push(item)
                } else if (item.item_value_id && !item.checked) {
                  items.itemsToUpdate.push({ ...item, toDelete: true })
                }
              })

              Promise.all([
                sendItems.length > 0
                  ? sendItems(items.itemsToAdd, reception.reception_id)
                  : null,
                updateItems(items.itemsToUpdate),
                dispatch(updateBudget(budget)),
              ])
                .then(() => {
                  dispatch(getReceptions(true))
                  resolve(response)
                  NotificationHandler(
                    '¡Listo!',
                    'success',
                    'Recepción actualizada'
                  )
                })
                .catch((error) => {
                  reject()
                  NotificationHandler(
                    'Error',
                    'error',
                    'Error al actualizar la recepción',
                    error
                  )
                })
            } else {
              reject()
              NotificationHandler(
                'Error',
                'error',
                'Error al actualizar la recepción',
                response.data.data
              )
            }
          })
          .catch((error) => {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al actualizar la recepción',
              error
            )
          })
      } else {
        reject()
        NotificationHandler(
          'Error',
          'error',
          `No se proporcionó id a: ${reception.reception_name}`
        )
      }
    })

const deleteImage = (image) =>
  new Promise((resolve, reject) => {
    axios
      .delete(`/api/reception_photos/${image.reception_photo_id}`)
      .then((response) => {
        if (!response.data.error) {
          resolve()
        } else {
          reject()
        }
      })
      .catch((error) => {
        reject()
        NotificationHandler(
          'Error',
          'error',
          'Error al eliminar la foto',
          error
        )
      })
  })
export const deleteImages = (images, receptionId) => (dispatch) =>
  new Promise((resolve, reject) => {
    const selectedImages = images.filter((img) => img.isSelected)
    const ids = selectedImages.map((image) => image.reception_photo_id)
    Promise.all(_.map(selectedImages, (image) => deleteImage(image)))
      .then(() => {
        resolve()
        dispatch({
          type: DELETE_RECEPTION_IMAGES,
          payload: {
            ids,
            receptionId,
          },
        })
        NotificationHandler('¡Listo!', 'success', 'Fotos borradas')
      })
      .catch((error) => {
        reject()
        NotificationHandler(
          'Error',
          'error',
          'Error al eliminar la foto',
          error
        )
      })
  })

export const updateReceptionStatus = (reception, newStatus) => (dispatch) =>
  new Promise((resolve, reject) => {
    axios
      .put(`/api/receptions/updateStatus/${reception.reception_id}`, {
        reception_state: newStatus,
      })
      .then((response) => {
        if (!response.data.error) {
          resolve()
          NotificationHandler('¡Listo!', 'success', 'Estado actualizado')
          dispatch(
            updateReceptionAction({
              ...reception,
              reception_state: newStatus,
            })
          )
        } else {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al actualizar el estado de la recepción',
            response.data.data
          )
        }
      })
      .catch((error) => {
        reject()
        NotificationHandler(
          'Error',
          'error',
          'Error al eliminar la foto',
          error
        )
      })
  })

export const deleteReception = (reception) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (reception.reception_id) {
      axios
        .delete(`/api/receptions/${reception.reception_id}`)
        .then((response) => {
          if (!response.data.error) {
            dispatch(deleteReceptionAction(reception))
            resolve(response)
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al eliminar la recepción',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al eliminar la recepción',
            error
          )
        })
    } else {
      reject()
      NotificationHandler(
        'Error',
        'error',
        `No se proporcionó id a: ${reception.reception_name}`
      )
    }
  })

export const fillRecepciones = (forceFill) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const size = getState().ReceptionReducer.recepciones.length
    if (size === 0 || forceFill) {
      axios
        .get('/api/receptions')
        .then((response) => {
          if (response.data.error) {
            NotificationHandler('Error', 'error', 'Recepciones')
            reject(response.data)
          } else if (response.data.length === 0) {
            dispatch({ type: 'NO RECEPCIONES' })
            resolve(response.data)
          } else {
            resolve(response.data)
            dispatch({
              type: 'GET_RECEPTIONS',
              recepciones: response.data.data,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    } else {
      resolve({ error: false, data: {} })
    }
  })
