import React, { useMemo } from 'react'
import { PropTypes } from 'prop-types'
import { Collapse } from 'antd'
import {
  ExpandIconWrapper,
  PackageProcessWrapper,
} from './PackageProcess.styles'
import Processes from './Processes/Processes'
import { useAddReceptionContext } from '../../../../../../contexts/AddReceptionContext'
import Products from './Products/Products'
import { getTotalWithDiscount } from '../../../../../Utilities/totalWithDiscount'

const CustomExpandIcon = ({ isActive }) => {
  return (
    <ExpandIconWrapper className={isActive ? 'active' : 'no-active'}>
      <i className="fas fa-chevron-down" />
    </ExpandIconWrapper>
  )
}

CustomExpandIcon.propTypes = {
  isActive: PropTypes.bool.isRequired,
}

const PackageProcess = () => {
  const { state } = useAddReceptionContext()
  const { selectedServices, packageDetails, selectedProducts } = state

  const totalServicesPrice = useMemo(() => {
    const servicesTotal = selectedServices.reduce((accumulator, current) => {
      return (
        accumulator +
        getTotalWithDiscount(
          current?.process_placed_price,
          current?.process_placed_discount
        )
      )
    }, 0)
    return (
      servicesTotal +
      (getTotalWithDiscount(
        packageDetails?.packagePrice,
        packageDetails?.packageDiscount
      ) || 0)
    )
  }, [selectedServices, packageDetails])

  const totalProductsPrice = useMemo(() => {
    const productsTotal = selectedProducts.reduce((accumulator, current) => {
      return (
        accumulator +
        getTotalWithDiscount(
          current?.product_placed_price,
          current?.product_placed_discount
        ) *
          current?.product_asked_quantity
      )
    }, 0)
    return productsTotal
  }, [selectedServices, packageDetails])

  const totalReception = totalProductsPrice + totalServicesPrice

  const items = [
    {
      key: '1',
      label: (
        <div className="label">
          <span>
            <i className="fas fa-wrench" />
            Mano de obra
          </span>
          <span>${totalServicesPrice.toFixed(2)}</span>
        </div>
      ),
      children: <Processes />,
      expandIconPosition: 'end',
    },
    {
      key: '2',
      label: (
        <div className="label">
          <span>
            <i className="fas fa-box-open" />
            Repuestos
          </span>
          <span>${totalProductsPrice.toFixed(2)}</span>
        </div>
      ),
      children: <Products />,
      expandIconPosition: 'end',
    },
    {
      key: '3',
      label: (
        <div className="label-total">
          <span>Total</span>
          <span>${totalReception.toFixed(2)}</span>
        </div>
      ),
      showArrow: false,
      collapsible: 'disabled',
    },
  ]

  return (
    <PackageProcessWrapper>
      <Collapse
        items={items}
        expandIconPosition="end"
        expandIcon={({ isActive }) => <CustomExpandIcon isActive={isActive} />}
      />
    </PackageProcessWrapper>
  )
}

export default PackageProcess
