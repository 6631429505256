import React from 'react'
import { Button } from 'react-bootstrap'
import ReactToolTip from 'react-tooltip'
import {
  formatterHeaderStyle,
  columnStyle,
  columnHeaderStyle,
  OptionsColumnStyle,
} from '../../Utilities/TableStyles'
import { StarRow } from './History.styles'

const styleIcons = {
  fontSize: '17px',
}

const seeOptions =
  (seeData, onlyPrintButtonFunction, evaluateLinkFunction) => (cell, row) =>
  (
    <>
      <Button
        key={1}
        onClick={() => {
          seeData(row)
        }}
        bsStyle="link"
        bsSize="small"
        style={{ color: '#76C4F0', outline: 'none' }}
        data-tip="Ver orden"
      >
        <i className="fas fa-eye" style={styleIcons} />
        <ReactToolTip />
      </Button>
      <Button
        key={2}
        onClick={() => {
          onlyPrintButtonFunction(row)
        }}
        bsStyle="link"
        style={{ color: '#545384', outline: 'none' }}
        bsSize="small"
        data-tip="Imprimir factura"
      >
        <i className="fas fa-file-alt" style={styleIcons} />
        <ReactToolTip />
      </Button>
      <Button
        key={3}
        onClick={() => {
          evaluateLinkFunction(row)
        }}
        style={{ color: '#7D7B7B', outline: 'none' }}
        bsStyle="link"
        bsSize="small"
        data-tip="Compartir link"
      >
        <i className="fas fa-link" style={styleIcons} />
        <ReactToolTip />
      </Button>
    </>
  )

/* const reclaimButton = (showModal) => (cell, row) => {
  const date = moment()
  const diff = date.diff(row.invoice.invoice_date, 'days')
  if (diff > 15 && !row.work_order_claim) {
    return (
      <Button
        onClick={() => {}}
        bsStyle="link"
        bsSize="small"
        data-tip="No se puede generar reclamo después de 15 días"
      >
        <i className="far fa-times-circle" style={styleIcons} />
        <ReactToolTip />
      </Button>
    )
  }

  if (row.work_order_claim) {
    return (
      <Button
        onClick={() => {}}
        bsStyle="link"
        bsSize="small"
        data-tip="No se puede crear reclamo de un reclamo"
      >
        <i className="far fa-times-circle" style={styleIcons} />
        <ReactToolTip />
      </Button>
    )
  }

  return (
    <Button
      onClick={() => {
        showModal(row)
      }}
      bsStyle="link"
      bsSize="small"
      data-tip="Abrir Reclamo"
    >
      <i className="fas fa-exclamation-circle" style={styleIcons} />
      <ReactToolTip />
    </Button>
  )
} */

const options = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}

const getDate = (date) => {
  const readableDate = new Date(date)
  return readableDate.toLocaleString('es-ES', options)
}

const startComponent = (grade) => (
  <StarRow>
    <i className="fas fa-star prefix" />
    {grade || 'Sin calificar'}
  </StarRow>
)

const getColumns = (seeData, onlyPrintFunction, evaluateLinkFunction) => {
  const columns = [
    {
      dataField: 'work_order_id',
      text: 'Orden',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: false,
      formatter: (id) => `#${id}`,
    },
    {
      dataField: 'budgets.reception.vehicle.vehicle_license_plate',
      text: 'Placa',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: false,
    },
    {
      dataField: 'client_fullname',
      text: 'Cliente',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: false,
    },
    {
      dataField: 'mechanic_fullname',
      text: 'Mécanico',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: false,
    },
    {
      dataField: 'work_order_finished_date',
      text: 'Finalización',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      formatter: getDate,
    },
    {
      dataField: 'work_order_grade',
      className: 'test',
      text: 'Calificación',
      headerStyle: columnHeaderStyle,
      style: columnStyle,
      formatter: startComponent,
    },
    {
      dataField: 'invoice.invoice_total',
      text: 'Total',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: false,
      formatter: (m) => `$ ${m.toFixed(2)}`,
    },
    {
      dataField: 'seeOptions',
      className: 'see-icon',
      text: '',
      headerStyle: formatterHeaderStyle,
      style: OptionsColumnStyle,
      formatter: seeOptions(seeData, onlyPrintFunction, evaluateLinkFunction),
    },
  ]
  return columns
}

export default getColumns
