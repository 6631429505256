import React, { useEffect } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { Button, Input, Modal, Select } from 'antd'

import SelectFooterButton from '../../../../Common/SelectFooterButton/SelectFooterButton'
import { addReceptionActions } from '../../../../../../reducers/AddReceptionReducer'
import { useAddReceptionContext } from '../../../../../../contexts/AddReceptionContext'
import AddProcessForm from '../../../../../forms/AddProcessForm/AddProcessForm'
import { createProcess } from '../../../../../../api/newReceptionService'
import { useServicesTab } from '../../../../../../hooks/useServicesTab'
import { NotificationHandler, getTimeString } from '../../../../../../Helpers'
import { AddServiceFormWrapper } from './ServicesTab.style'
import { FormModalTitle } from '../../../../../../Components'
import { useModal } from '../../../../../../hooks/useModal'

const { Option } = Select

const AddServiceTab = () => {
  const { state, dispatch } = useAddReceptionContext()
  const { selectedServices, processes } = state

  const {
    totalEstimatedTime,
    value,
    selectedServices: selectedProcesses,
    discount,
    openOptions,
    selectNewService,
    selectCustomFilter,
    setOpenOptions,
    getTotal,
    getTotalWithDiscount,
    deleteService,
    handlers,
  } = useServicesTab(processes, selectedServices, true)

  const { handleChange, handlePriceChange, handleDiscountChange } = handlers

  const [isServiceModalOpen, openServiceModal, closeServiceModal] = useModal()

  const updateSelected = () => {
    dispatch({
      type: addReceptionActions.SET_ADITIONAL_SERVICES,
      payload: [...selectedProcesses],
    })
  }

  const handleAddService = () => {
    setOpenOptions(false)
    openServiceModal()
  }

  const createNewProcess = async (formData) => {
    try {
      const newProcess = await createProcess(formData)

      dispatch({
        type: addReceptionActions.SET_PROCESSES,
        payload: [...processes, newProcess.data],
      })
      selectNewService(newProcess.data)

      closeServiceModal()
    } catch (e) {
      NotificationHandler(
        'No se pudo crear el servicio',
        'error',
        'Intenta de nuevo más tarde'
      )
    }
  }

  useEffect(() => {
    updateSelected()
  }, [selectedProcesses])

  const selectProps = {
    mode: 'multiple',
    style: {
      width: '100%',
    },
    value,
    options: processes.map((process) => ({
      label: `${process.processName}`,
      value: process.processId,
    })),
    onChange: handleChange,
    placeholder: '¿Qué servicios desea agregar?',
    maxTagCount: 'responsive',
    filterOption: selectCustomFilter,
  }

  return (
    <AddServiceFormWrapper>
      <div className="main-inputs-wrapper">
        <Select
          className="select"
          mode={selectProps.mode}
          style={selectProps.style}
          value={selectProps.value}
          options={selectProps.options}
          onChange={selectProps.onChange}
          placeholder={selectProps.placeholder}
          maxTagCount={selectProps.maxTagCount}
          filterOption={selectProps.filterOption}
          open={openOptions}
          listHeight="20vh"
          onDropdownVisibleChange={(visible) => setOpenOptions(visible)}
          dropdownRender={(menu) => (
            <>
              {menu}
              <SelectFooterButton
                buttonText="Agregar nuevo servicio"
                onClickHandler={handleAddService}
              />
            </>
          )}
        >
          {processes?.map((option) => (
            <Option key={option.processId} value={option.processId}>
              {`${option.processName}`}
            </Option>
          ))}
        </Select>
        {selectedServices?.length === 1 && (
          <div className="disclaimer">
            <i className="fas fa-info-circle" />
            <p>Puedes seleccionar más de un servicio</p>
          </div>
        )}
      </div>
      {selectedServices?.length > 0 && (
        <>
          {' '}
          <h2>
            Servicios a agregar:{' '}
            <span>
              <i className="fas fa-clock" /> {getTimeString(totalEstimatedTime)}
            </span>
          </h2>
          <div className="selectedOptions">
            {selectedServices?.map((process) => (
              <div className="process" key={process.processId}>
                <button
                  type="button"
                  className="delete"
                  onClick={() => deleteService(process.processId)}
                >
                  <i className="fas fa-trash" />
                </button>
                <div className="process-name-wrapper">
                  <h4>{process.processName}</h4>
                </div>
                <div className="process-information">
                  <div>
                    <p>
                      <b>Precio actual: </b>${process.processPrice.toFixed(2)}
                    </p>
                    <p>
                      <b>Tiempo estimado: </b> {process.processTime} minutos
                    </p>
                  </div>
                  <div className="secondary-inputs-wrapper">
                    <label htmlFor="price" className="price">
                      Precio:
                      <CurrencyInput
                        id="price"
                        name="price"
                        min={0}
                        prefix="$"
                        decimalSeparator="."
                        step={0.01}
                        groupSeparator=","
                        defaultValue={parseFloat(process.processPrice).toFixed(
                          2
                        )}
                        allowNegativeValue={false}
                        value={process.process_placed_price}
                        decimalsLimit={2}
                        onValueChange={(price) =>
                          handlePriceChange(price, process.processId)
                        }
                      />
                    </label>
                  </div>
                </div>
                <p className="subtotal-wrapper">
                  Sub-Total: ${' '}
                  {getTotalWithDiscount(
                    process.process_placed_price,
                    process.process_placed_discount
                  ).toFixed(2)}
                </p>
              </div>
            ))}
          </div>
          <div className="discountTotal">
            <div className="discountInputWrapper">
              <label htmlFor="discount" className="discount">
                Descuento:
                <Input
                  suffix="%"
                  min={1}
                  type="number"
                  max={100}
                  inputMode="numeric"
                  numeric="true"
                  digitonly="true"
                  itemID="discount"
                  itemType="number"
                  defaultValue={0}
                  value={discount}
                  onChange={(e) => handleDiscountChange(e.target.value)}
                />
              </label>
            </div>
            <p className="total">
              <b>Total: </b> ${getTotal()}
            </p>
          </div>
        </>
      )}
      <Modal
        title={<FormModalTitle>Agregar servicio</FormModalTitle>}
        style={{ padding: '3rem 1rem' }}
        open={isServiceModalOpen}
        width={400}
        centered
        onCancel={closeServiceModal}
        footer={null}
      >
        <AddProcessForm handleFormSubmit={createNewProcess}>
          <Button key="back" onClick={closeServiceModal}>
            Cancelar
          </Button>
        </AddProcessForm>
      </Modal>
    </AddServiceFormWrapper>
  )
}

export default AddServiceTab
