import React from 'react'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import PhoneInputPropTypes from './PhoneInput.types'
import countryCodeToLetters from '../../../Utilities/CountryCodeToLetters'
import { formatInternationalNumber } from '../../../Utilities/formatPhone'
import PhoneInputWrapper from './PhoneInput.styles'
import 'react-phone-number-input/style.css'

const PhoneInputComponent = ({
  phone,
  onChange,
  showError = true,
  isError,
  setError,
  required = false,
  countryCode,
  phoneRef = undefined,
  handleFocus = () => {},
}) => {
  const areaCode = countryCodeToLetters(countryCode)
  const phoneNumber = phone ? formatInternationalNumber(phone, countryCode) : ''

  return (
    <PhoneInputWrapper>
      <PhoneInput
        initialValueFormat="national"
        className="phone-input"
        international={false}
        addInternationalOption={false}
        defaultCountry={areaCode}
        value={phoneNumber}
        onChange={(value) => {
          if (typeof value === 'string') {
            onChange(value)
            setError(!isValidPhoneNumber(value))
          }
        }}
        onFocus={() => handleFocus(true)}
        ref={phoneRef}
        countries={['SV', 'CO', 'VE', 'MX', 'US', 'GT']}
        required={required}
      />
      {isError && showError && (
        <span style={{ color: '#a94442' }}>
          Ingresa un número de teléfono válido
        </span>
      )}
    </PhoneInputWrapper>
  )
}
PhoneInputComponent.propTypes = PhoneInputPropTypes

export default PhoneInputComponent