import React from 'react'
import { useAddReceptionContext } from '../../../../../../contexts/AddReceptionContext'
import { CardWrapper } from './Card.styles'
import { getVehicleTypeIcon } from '../../../../../../Helpers/getVehicleTypeIcon'

const Card = () => {
  const { state } = useAddReceptionContext()
  const { selectedVehicle, client, vehicleState, contact } = state

  return (
    <CardWrapper>
      <div className="selected-vehicle-details">
        <i className={getVehicleTypeIcon(selectedVehicle?.vehicleTypeName)} />
        <div className="details">
          <p>{`${selectedVehicle?.brandName} ${selectedVehicle?.modelName}`}</p>
          <p className="license-plate">{selectedVehicle?.licensePlate}</p>
        </div>
      </div>
      <div className="vehicle-state">
        <p>
          <span>Año</span>
          <b>{selectedVehicle?.vehicleYear || 'No registrado'}</b>
        </p>

        <p>
          <span>Combustible </span>
          <b>{`${
            vehicleState?.vehicleGasLevel
              ? `${vehicleState.vehicleGasLevel}%`
              : 'No registrado'
          }`}</b>
        </p>

        <p>
          <span>Odómetro</span>
          <b>
            {`${
              vehicleState?.vehicleOdometer
                ? `${vehicleState.vehicleOdometer} ${selectedVehicle?.measureUnit}`
                : 'No registrado'
            }`}
          </b>
        </p>
      </div>
      <div className="clients">
        <div className="client">
          <p className="client-name">
            <b>Cliente: </b> {client?.clientName}
          </p>
          <p className="client-phone">
            <i className="fas fa-phone" />
            <span>{client?.phone}</span>
          </p>
        </div>
        {contact?.contactName && (
          <div className="client">
            <p className="client-name">
              <b>Contacto: </b> {contact.contactName}
            </p>
            <p className="client-phone">
              <i className="fas fa-phone" />
              <span>{contact.contactPhone}</span>
            </p>
          </div>
        )}
      </div>
    </CardWrapper>
  )
}

export default Card
