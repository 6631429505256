import { vehicleTypeNames } from '../Constants'

export const getVehicleTypeIcon = (type) => {
  switch (type) {
    case vehicleTypeNames.CAR:
      return 'fas fa-car'
    case vehicleTypeNames.BUS:
      return 'fas fa-bus-alt'
    case vehicleTypeNames.MINIBUS:
      return 'fas fa-bus'
    case vehicleTypeNames.MOTORCYCLE:
      return 'fas fa-motorcycle'
    case vehicleTypeNames.PICK_UP:
      return 'fas fa-truck-pickup'
    case vehicleTypeNames.TRAILER:
      return 'fas fa-trailer'

    case vehicleTypeNames.TRUCK:
      return 'fas fa-truck'
    case vehicleTypeNames.VAN:
      return 'fas fa-shuttle-van'
    default:
      return 'fas fa-car'
  }
}
