import React from 'react'
import _ from 'lodash'
import InputMask from 'react-input-mask'
import {
  Panel,
  Col,
  ControlLabel,
  FormControl,
  PanelGroup,
  Row,
  Button,
} from 'react-bootstrap'
import Styled from 'styled-components'
import PropTypes from 'prop-types'
import { MinusCircleFilled } from '@ant-design/icons'

export const FormRow = Styled(Row)`
  margin: 10px;
`

const ContactPanel = (props) => {
  const { title, handleChangeContact, contact, handleRemoveContact } = props
  const {
    provider_contact_name,
    provider_contact_position,
    provider_contact_phone,
    provider_contact_phone2,
  } = contact

  const { provider_contact_id } = contact
  return (
    <Panel>
      <Panel.Heading
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Panel.Title toggle>{title}</Panel.Title>
        <Button
          onClick={(e) => handleRemoveContact(e, provider_contact_id)}
          type="button"
          bsStyle="danger"
          style={{ padding: '0px 5px' }}
          className="btn-antd-icon"
        >
          <MinusCircleFilled />
        </Button>
      </Panel.Heading>
      <Panel.Collapse>
        <Panel.Body>
          <FormRow>
            <Col xs={12} sm={6} m={6} l={6}>
              <ControlLabel>Nombre :</ControlLabel>
              <FormControl
                name="provider_contact_name"
                type="text"
                onChange={(e) => handleChangeContact(e, provider_contact_id)}
                value={provider_contact_name}
                required
                placeholder="Ingresar"
              />
            </Col>
            <Col xs={12} sm={6} m={6} l={6}>
              <ControlLabel>Cargo: </ControlLabel>
              <FormControl
                name="provider_contact_position"
                type="text"
                onChange={(e) => handleChangeContact(e, provider_contact_id)}
                value={provider_contact_position}
                placeholder="Ingresar"
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col xs={12} sm={6} md={6} lg={6}>
              <ControlLabel>Teléfono: </ControlLabel>
              <InputMask
                onChange={(e) => handleChangeContact(e, provider_contact_id)}
                name="provider_contact_phone"
                pattern="[0-9]{4}[\-][0-9]{4}"
                value={provider_contact_phone}
                placeholder="0000-0000"
                mask="9999-9999"
                className="form-control"
              />
            </Col>
            <Col xs={12} sm={6} md={6} lg={6}>
              <ControlLabel>Teléfono Opcional: </ControlLabel>
              <InputMask
                onChange={(e) => handleChangeContact(e, provider_contact_id)}
                name="provider_contact_phone2"
                pattern="[0-9]{4}[\-][0-9]{4}"
                value={provider_contact_phone2}
                placeholder="0000-0000"
                className="form-control"
                mask="9999-9999"
              />
            </Col>
          </FormRow>
        </Panel.Body>
      </Panel.Collapse>
    </Panel>
  )
}

ContactPanel.propTypes = {
  title: PropTypes.string.isRequired,
  contact: PropTypes.objectOf(PropTypes.object).isRequired,
  handleChangeContact: PropTypes.func.isRequired,
  handleRemoveContact: PropTypes.func.isRequired,
}

const Contacts = ({
  contactsList,
  handleChangeContact,
  handleRemoveContact,
}) => (
  <PanelGroup id="id" accordion>
    {_.map(contactsList, (contact, key) => {
      const addNew = contact.isNew !== undefined
      const active =
        contact.provider_active !== undefined && contact.provider_active !== 0

      // Only display:
      // - When a created contact is active
      // - When an contact is new
      return (
        (addNew || active) && (
          <ContactPanel
            key={key}
            title="Contacto"
            handleChangeContact={handleChangeContact}
            handleRemoveContact={handleRemoveContact}
            contact={contact}
          />
        )
      )
    })}
  </PanelGroup>
)

Contacts.propTypes = {
  contactsList: PropTypes.objectOf(PropTypes.object).isRequired,
  handleChangeContact: PropTypes.func.isRequired,
  handleRemoveContact: PropTypes.func.isRequired,
}

export default Contacts
