/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { Fragment } from 'react'
import writtenNumbers from 'written-number'
import PropTypes from 'prop-types'
import { numberFormatter } from '../../../../Helpers'

writtenNumbers.defaults.lang = 'es'
export default function InvoiceFooterWithoutTaxes({ footerDetails }) {
  const { total } = footerDetails
  const dollars = Math.floor(parseFloat(total, 10))
  const cents = Math.round((total - dollars) * 100)
  return (
    <>
      <tr>
        <td colSpan="2" rowSpan="4">
          {`${writtenNumbers(
            dollars
          ).toUpperCase()} DÓLARES CON ${cents}/100 CENTAVOS.`}
        </td>
      </tr>
      <tr>
        <td>Total</td>
        <td />
        <td className="text-right">{numberFormatter.format(total)}</td>
      </tr>
    </>
  )
}

InvoiceFooterWithoutTaxes.propTypes = {
  footerDetails: PropTypes.objectOf(PropTypes.any).isRequired,
}
