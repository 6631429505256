import React, { useEffect } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { Button, Input, Modal, Select } from 'antd'

import SelectFooterButton from '../../../../Common/SelectFooterButton/SelectFooterButton'
import { useAddReceptionContext } from '../../../../../../contexts/AddReceptionContext'
import { addReceptionActions } from '../../../../../../reducers/AddReceptionReducer'
import { useProductsTab } from '../../../../../../hooks/useProductsTab'
import AddProductFormWrapper from './ProductsTab.style'
import { FormModalTitle } from '../../../../../../Components'
import { useModal } from '../../../../../../hooks/useModal'
import { NotificationHandler } from '../../../../../../Helpers'
import AddProductForm from '../../../../../forms/AddProductsForm/AddProductForm'
import { createProduct } from '../../../../../../api/newReceptionService'

const { Option } = Select

const ProductsTab = () => {
  const { state, dispatch } = useAddReceptionContext()
  const { selectedProducts, products } = state

  const {
    productsArray,
    value,
    selectedProducts: currentSelectedProducts,
    discount,
    openOptions,
    customFilter,
    setOpenOptions,
    getTotal,
    getTotalWithDiscount,
    deleteProduct,
    handlers,
    selectNewProduct,
  } = useProductsTab(products, selectedProducts, true)

  const {
    handleChange,
    handlePriceChange,
    handleDiscountChange,
    handleQuantityChange,
  } = handlers

  const [isProductModalOpen, openProductModal, closeProductModal] = useModal()

  const updateSelected = () => {
    dispatch({
      type: addReceptionActions.SET_ADITIONAL_PRODUCTS,
      payload: [...currentSelectedProducts],
    })
  }

  const handleAddProduct = () => {
    setOpenOptions(false)
    openProductModal()
  }

  const createNewProduct = async (formData) => {
    try {
      const newProduct = await createProduct(formData)
      dispatch({
        type: addReceptionActions.SET_PRODUCTS,
        payload: [...products, newProduct.data],
      })
      selectNewProduct(newProduct.data)
      closeProductModal()
    } catch (e) {
      NotificationHandler(
        'No se pudo crear el repuesto',
        'error',
        'Intenta de nuevo más tarde'
      )
    }
  }

  useEffect(() => {
    updateSelected()
  }, [currentSelectedProducts])

  const selectProps = {
    mode: 'multiple',
    style: {
      width: '100%',
    },
    value,
    options: productsArray.map((product) => ({
      label: `${product.productName} ${product.productCode} (cant: ${product.productQuantity})`,
      value: product.productId,
      disabled: product.productQuantity <= 0,
    })),
    onChange: handleChange,
    placeholder: '¿Qué respuestos desea agregar?',
    maxTagCount: 'responsive',
    filterOption: customFilter,
  }

  return (
    <AddProductFormWrapper>
      <div className="main-inputs-wrapper">
        <Select
          className="select"
          mode={selectProps.mode}
          style={selectProps.style}
          value={selectProps.value}
          options={selectProps.options}
          onChange={selectProps.onChange}
          placeholder={selectProps.placeholder}
          maxTagCount={selectProps.maxTagCount}
          filterOption={selectProps.filterOption}
          open={openOptions}
          listHeight="20vh"
          onDropdownVisibleChange={(visible) => setOpenOptions(visible)}
          dropdownRender={(menu) => (
            <>
              {menu}
              <SelectFooterButton
                buttonText="Agregar nuevo repuesto"
                onClickHandler={handleAddProduct}
              />
            </>
          )}
        >
          {productsArray?.map((option) => (
            <Option
              key={option.productId}
              value={option.productId}
              disabled={option.productQuantity < 1}
            >
              {`${option.productCode} - ${option.productName} `}
            </Option>
          ))}
        </Select>
        {selectedProducts?.length === 1 && (
          <div className="disclaimer">
            <i className="fas fa-info-circle prefix" />
            <p>Puedes seleccionar más de un repuesto </p>
          </div>
        )}
      </div>

      {selectedProducts?.length > 0 && (
        <>
          <h2>Repuestos a agregar: </h2>
          <div className="selectedOptions">
            {selectedProducts?.map((product) => (
              <div className="product" key={product.productId}>
                <button
                  type="button"
                  className="delete"
                  onClick={() => deleteProduct(product.productId)}
                >
                  <i className="fas fa-trash" />
                </button>
                <div className="product-name-wrapper">
                  <h4>{product.productName}</h4>
                </div>
                <p className="product-current-price">
                  <b>Precio actual: </b>${product.productPrice.toFixed(2)}
                </p>
                <div className="secondary-inputs-wrapper">
                  <label htmlFor="quantity" className="quantity">
                    Cantidad:
                    <Input
                      itemID="quantity"
                      type="number"
                      value={product.product_asked_quantity}
                      defaultValue={1}
                      inputMode="numeric"
                      numeric="true"
                      digitonly="true"
                      min={1}
                      max={product.productQuantity}
                      onChange={(e) =>
                        handleQuantityChange(e, product.productId)
                      }
                    />
                  </label>
                  <label htmlFor="price" className="price">
                    Precio:
                    <CurrencyInput
                      id="price"
                      name="price"
                      min={0}
                      prefix="$"
                      decimalSeparator="."
                      step={0.01}
                      groupSeparator=","
                      defaultValue={parseFloat(product.productPrice).toFixed(2)}
                      allowNegativeValue={false}
                      value={product.product_placed_price}
                      decimalsLimit={2}
                      onValueChange={(price) =>
                        handlePriceChange(price, product.productId)
                      }
                    />
                  </label>
                </div>

                <p className="subtotal-wrapper">
                  Sub-Total: ${' '}
                  {getTotalWithDiscount(
                    product.product_asked_quantity,
                    product.product_placed_price,
                    product.product_placed_discount
                  ).toFixed(2)}{' '}
                </p>
              </div>
            ))}
          </div>
          <div className="discountTotal">
            <div className="discountInputWrapper">
              <label htmlFor="discount" className="discount">
                Descuento:
                <Input
                  suffix="%"
                  min={1}
                  type="number"
                  max={100}
                  inputMode="numeric"
                  numeric="true"
                  digitonly="true"
                  itemID="discount"
                  itemType="number"
                  defaultValue={0}
                  value={discount}
                  onChange={(e) => handleDiscountChange(e.target.value)}
                />
              </label>
            </div>
            <p className="total">
              <b>Total: </b> ${getTotal()}
            </p>
          </div>
        </>
      )}
      <Modal
        title={<FormModalTitle>Agregar repuesto</FormModalTitle>}
        style={{ padding: '3rem 1rem' }}
        open={isProductModalOpen}
        width={400}
        centered
        onCancel={closeProductModal}
        footer={null}
      >
        <AddProductForm handleFormSubmit={createNewProduct}>
          <Button key="back" onClick={closeProductModal}>
            Cancelar
          </Button>
        </AddProductForm>
      </Modal>
    </AddProductFormWrapper>
  )
}

export default ProductsTab
