import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

export class RedirectToLogin extends Component {
  UNSAFE_componentWillMount() {
    const { logged, history } = this.props
    if (!logged) {
      history.push('/login')
    }
  }

  render() {
    return <div>asd</div>
  }
}

const mapStateToProps = (state) => ({
  logged: state.AppReducer.logged,
})

RedirectToLogin.propTypes = {
  logged: PropTypes.bool.isRequired,
  history: PropTypes.objectOf(PropTypes.object).isRequired,
}

export default withRouter(connect(mapStateToProps)(RedirectToLogin))
