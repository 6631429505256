import styled from 'styled-components'
import BREAK_POINTS from '../../../../../../global/css/breakPoints'

export const DetailsInputs = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: min-content;
  grid-column-gap: 1rem;
  grid-row-gap: 4rem;

  padding: 0.75rem;

  .units-label {
    grid-area: 1 / 1 / 2 / 2;

    font-weight: bold;
    font-size: 1.1em;
    margin-right: 0.8;
    white-space: nowrap;
  }
  .units-input {
    grid-area: 1 / 2 / 2 / 7;
  }
  .colors-label {
    grid-area: 2 / 1 / 3 / 2;

    display: flex;
    flex-direction: column;
    font-weight: bold;
    font-size: 1.1em;
    white-space: nowrap;

    margin-right: 0.8;

    span {
      font-size: 0.8em;
      color: #949494;
    }
  }
  .colors-input {
    grid-area: 2 / 2 / 3 / 7;

    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }
`

export const SummaryContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  p.summary__title {
    font-weight: bold;
    font-size: 1em;
  }

  @media (${BREAK_POINTS.TABLET}) {
    width: auto;
  }
`
