import React, { Component } from 'react'
import { NotificationManager } from 'react-notifications'
import 'react-select/dist/react-select.css'
import { connect } from 'react-redux'
import swal from 'sweetalert2'
import {
  Modal,
  Button,
  FormGroup,
  ControlLabel,
  FormControl,
  Row,
  Col,
} from 'react-bootstrap'
import PropTypes from 'prop-types'
import { SaveOutlined } from '@ant-design/icons'
import {
  addVehicleType,
  updateVehicleType,
} from '../../../../actions/VehicleTypesAction'

class VehicleTypesModal extends Component {
  state = {
    editMode: false,
    vehicleTypeName: '',
  }

  componentDidUpdate(prevProps) {
    const { selectedVehicleType } = this.props
    const { selectedVehicleType: prevSelectedVehicleType } = prevProps

    if (selectedVehicleType !== prevSelectedVehicleType) {
      this.setVehicleTypesModalState()
    }
  }

  setVehicleTypesModalState = () => {
    const {
      selectedVehicleType: { vehicle_type_name },
      editMode,
    } = this.props

    this.setState({
      vehicleTypeName: vehicle_type_name,
      editMode,
    })
  }

  getInitialState = () => ({
    editMode: false,
    vehicleTypeName: '',
  })

  handleInputText = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { editMode } = this.state
    if (!editMode) {
      this.insert()
    } else {
      this.update()
    }
  }

  handleInputText = (e) => {
    const { value, name } = e.target
    this.setState({
      [name]: value,
    })
  }

  insert() {
    const { vehicleTypeName } = this.state
    const { createVehicleType, handleClose } = this.props

    const source = {
      vehicle_type_name: vehicleTypeName,
    }

    createVehicleType(source)
      .then((response) => {
        if (response) {
          NotificationManager.success(
            `${vehicleTypeName} agregado`,
            'Información'
          )
          handleClose()
          this.setState(this.getInitialState())
        } else {
          NotificationManager.error('Error agregando registro', '')
        }
      })
      .catch((error) => {
        NotificationManager.error(error, 'Error agregando registro')
      })
  }

  update() {
    const { vehicleTypeName } = this.state
    const { selectedVehicleType, modifyVehicleType, handleClose } = this.props
    const source = {
      id: selectedVehicleType.vehicle_type_id,
      vehicle_type_name: vehicleTypeName,
    }

    const sweet = {
      title: '¿Modificar?',
      text: '¿Desea modificar el tipo de vehículo?',
      type: 'warning',
      confirmButtonText: 'Aceptar',
      showCancelButton: true,
      preConfirm: () => {
        modifyVehicleType(source)
          .then((response) => {
            if (response) {
              swal({
                title: 'Modificado',
                text: '',
                type: 'success',
              })
              handleClose()
              this.setState(this.getInitialState())
            } else {
              swal({
                title: 'Error',
                text: 'Error al modificar',
                type: 'error',
              })
            }
          })
          .catch(() => {
            swal({
              title: 'Error',
              text: 'Error al modificar',
              type: 'error',
            })
          })
      },
    }

    swal(sweet)
  }

  render() {
    const { editMode, vehicleTypeName } = this.state
    const { pagePermissions, modalState, handleClose } = this.props
    let can_edit
    if (pagePermissions) {
      ; ({ can_edit } = pagePermissions)
    }
    return (
      <Modal show={modalState} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {!editMode ? 'Agregar ' : 'Modificar '}
            tipo de vehículo
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={(e) => this.handleSubmit(e)}>
            <Row>
              <Col xs={12}>
                <FormGroup>
                  <ControlLabel>Nombre:</ControlLabel>
                  <FormControl
                    autoFocus
                    required
                    autoComplete="off"
                    type="text"
                    name="vehicleTypeName"
                    value={vehicleTypeName}
                    onChange={(e) => this.handleInputText(e)}
                  />
                </FormGroup>
              </Col>
            </Row>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button type="submit" id="submitModal" hidden />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Cerrar</Button>
          {!!can_edit && (
            <Button
              style={{
                paddingTop: '5px',
                paddingBottom: '5px',
                borderRadius: '1px',
                fontSize: '1.2em',
              }}
              bsStyle="primary"
              onClick={() => {
                document.getElementById('submitModal').click()
              }}
              type="submit"
              className="btn-antd-icon"
            >
              <SaveOutlined /> {!editMode ? 'Agregar' : 'Guardar'}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  createVehicleType(model) {
    return dispatch(addVehicleType(model))
  },
  modifyVehicleType(model) {
    return dispatch(updateVehicleType(model))
  },
})

VehicleTypesModal.propTypes = {
  editMode: PropTypes.bool.isRequired,
  modalState: PropTypes.bool.isRequired,
  selectedVehicleType: PropTypes.objectOf(PropTypes.any).isRequired,
  pagePermissions: PropTypes.objectOf(PropTypes.any).isRequired,
  createVehicleType: PropTypes.func.isRequired,
  modifyVehicleType: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default connect(null, mapDispatchToProps)(VehicleTypesModal)
