import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import { Avatar, Button, Modal } from 'antd'
import { debounce } from 'lodash'
import { AssignMechanicModalWrapper } from './AssingMechanicModal.styles'
import { FormModalTitle } from '../../../../../../../Components'
import { ModalButtonsContainer } from '../../../VehicleStep/VehicleStep.style'
import { getMechanicList } from '../../../../../../../api/apiMechanicService'
import { NotificationHandler } from '../../../../../../../Helpers'
import SearchWithAvatar from '../../../../../../inputs/SearchWithAvatar/SearchWithAvatar'
import { addReceptionActions } from '../../../../../../../reducers/AddReceptionReducer'
import { useAddReceptionContext } from '../../../../../../../contexts/AddReceptionContext'

const AssingMechanicModal = ({ isModalOpen, closeModal }) => {
  const { dispatch } = useAddReceptionContext()
  const [mechanicsList, setMechanicsList] = useState([])
  const [selected, setSelected] = useState(null)
  const [filteredMechanics, setFilteredMechanics] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [value, setValue] = useState(null)
  const defaultImg = '/images/default-user.png'
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getMechanicList()
        setMechanicsList(response)
      } catch (e) {
        NotificationHandler(
          'Error',
          'error',
          'Error al obtener listado de mecánicos'
        )
      }
    }
    getData()
  }, [])

  const handleChange = async (newValue) => {
    if (!newValue) {
      setValue(null)
      dispatch({
        type: addReceptionActions.SET_MECHANIC,
        payload: undefined,
      })
      return
    }

    const selectedMechanic = mechanicsList.find(
      (item) => item.mechanicId === newValue?.value
    )
    setSelected(selectedMechanic)
    setValue({
      label: newValue?.label,
      value: newValue?.value,
    })
    setSearchValue('')
  }

  const handleSearch = debounce((searchText) => {
    setSearchValue(searchText)
    const searchResults = mechanicsList.filter((item) => {
      const name = item.mechanicName.toLowerCase().replace(/\s+/g, ' ')
      const search = searchText.toLowerCase().replace(/\s+/g, ' ')
      return name.includes(search)
    })
    setFilteredMechanics(searchResults)
  }, 300)

  const transformMechanics = (list) =>
    list.map((item) => {
      return {
        label: item?.mechanicName,
        value: item?.mechanicId,
        src: item?.photo || defaultImg,
      }
    })

  const addMechanic = () => {
    dispatch({
      type: addReceptionActions.SET_MECHANIC,
      payload: selected,
    })
    closeModal()
    setValue(null)
    setSelected(null)
  }

  const removeSelected = () => {
    setValue(null)
    setSelected(null)
  }
  return (
    <Modal
      title={<FormModalTitle>Asignar mecánico</FormModalTitle>}
      style={{ padding: '3rem 1rem' }}
      open={isModalOpen}
      width={350}
      centered
      onCancel={closeModal}
      footer={null}
    >
      <AssignMechanicModalWrapper>
        <SearchWithAvatar
          id="client-name"
          searchValue={searchValue}
          items={
            searchValue
              ? transformMechanics(filteredMechanics)
              : transformMechanics(mechanicsList)
          }
          defaultValue={value}
          value={value}
          placeholder="Ingresa el nombre del mecánico"
          label="Busca por nombre:"
          handleValueChange={handleChange}
          handleSearch={handleSearch}
        />
        {selected && (
          <div className="selected-mechanic">
            <p className="selected-label">Mecánico seleccionado:</p>
            <div className="avatar-wrapper">
              <div className="avatar">
                <Avatar src={selected?.photo || defaultImg} />
                <p className="mechanic-name">{selected.mechanicName}</p>
              </div>
              <button type="button" onClick={removeSelected}>
                <i className="fas fa-times" />
              </button>
            </div>
          </div>
        )}
        <ModalButtonsContainer
          style={{ marginTop: '1.5rem' }}
          className="btn-wrappes"
        >
          <Button key="back" onClick={closeModal}>
            Cancelar
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!selected}
            onClick={addMechanic}
          >
            Asignar
          </Button>
        </ModalButtonsContainer>
      </AssignMechanicModalWrapper>
    </Modal>
  )
}

AssingMechanicModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
}
export default AssingMechanicModal
