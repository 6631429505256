import styled from 'styled-components'

export const LastWorkOrderCard = styled.article`
  display: flex;
  flex-direction: column;

  border-radius: 10px;
  border: solid #cacaca 1px;

  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);

  cursor: pointer;
  transition: 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
  }

  .work-order-card__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-radius: 10px 10px 0 0;
    padding: 3px 14px;

    background-color: #f4f4f4;

    h4 {
      font-size: 1.1em;
      font-weight: bold;
    }

    span {
      font-size: 0.9em;
      font-weight: 400;
      color: #666666;
    }
  }

  .work-order-card__body {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    padding: 12px 14px 0;
  }

  .work-order-card__footer {
    display: flex;
    justify-content: flex-end;

    padding: 0 12px 14px 0;

    position: relative;
  }
`

export const LastWorkOrderInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  i {
    color: #e70000;
  }

  p {
    margin: 0;
    span {
      font-weight: bold;
    }
  }
`
