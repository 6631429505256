import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { List, Avatar, Badge, Row, Col, Space, Empty, Button, Tag, Radio, Input } from 'antd';
import {
    NotificationTwoTone,
    ClockCircleTwoTone,
    MessageTwoTone,
    PlusCircleTwoTone,
    HeartTwoTone,
    DashboardTwoTone,
    CameraTwoTone,
    PlayCircleTwoTone,
    PauseCircleTwoTone,
    InteractionTwoTone,
    CheckCircleTwoTone,
    ReconciliationTwoTone
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import {
    Container,
    ContainerHeader,
    BodyContent,
} from '../../Common/index';
import { updateNotificationCount } from '../../../../actions/AppAction'
import TimeAgo from '../../../Utilities/TimeAgo';
import { Host } from '../../../../Constants';

import './NotificationList.scss';

const IconText = ({ icon: IconComponent, text, iconTwoTone }) => (
    <Space size="small">
        {iconTwoTone ? (
            <IconComponent twoToneColor={iconTwoTone} />
        ) : (
            React.createElement(IconComponent)
        )}
        {text}
    </Space>
);

IconText.propTypes = {
    icon: PropTypes.object.isRequired,
    text: PropTypes.element.isRequired,
    iconTwoTone: PropTypes.string.isRequired,
};

// ... (previous imports and component code)

const NotificationList = ({ employeeId, getNotificationCount, currentUser }) => {
    const [notifications, setNotifications] = useState([]);
    const [filter, setFilter] = useState('all'); // Initialize with 'all'
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        if (employeeId) {
            const fetchNotifications = async () => {
                try {
                    const response = await axios.get(
                        `${Host}api/work-order-follow-configuration/notifications/${employeeId}`
                    );

                    // Sort notifications by viewed status and then by work_order_notification_id (DESC)
                    const sortedNotifications = response.data.data.sort((a, b) => {
                        if (a.viewed === b.viewed) {
                            // If viewed status is the same, compare by work_order_notification_id (DESC)
                            return b.work_order_notification_id - a.work_order_notification_id;
                        }
                        return a.viewed ? 1 : -1;
                    });

                    setNotifications(sortedNotifications);
                } catch (error) {
                    console.error('Error fetching notifications:', error);
                }
            };


            fetchNotifications();
        }
    }, [employeeId]);

    const getAvatarIcon = (notificationType) => {
        // Define a mapping between notification types and icons
        const iconMappings = {
            'new-work-order': <Avatar icon={<ReconciliationTwoTone twoToneColor="#8c236a" />} />,
            'work-order-rating': <Avatar icon={<HeartTwoTone twoToneColor="#8c236a" />} />,
            'new-product': <Avatar icon={<PlusCircleTwoTone twoToneColor="#8c236a" />} />,
            'new-process': <Avatar icon={<DashboardTwoTone twoToneColor="#8c236a" />} />,
            'new-photo': <Avatar icon={<CameraTwoTone twoToneColor="#8c236a" />} />,
            'new-comment': <Avatar icon={<MessageTwoTone twoToneColor="#8c236a" />} />,
            'started': <Avatar icon={<PlayCircleTwoTone twoToneColor="#8c236a" />} />,
            'paused': <Avatar icon={<PauseCircleTwoTone twoToneColor="#8c236a" />} />,
            'resumed': <Avatar icon={<InteractionTwoTone twoToneColor="#8c236a" />} />,
            'finalized': <Avatar icon={<CheckCircleTwoTone twoToneColor="#8c236a" />} />,
        };

        return iconMappings[notificationType] || <Avatar icon={<NotificationTwoTone />} />;
    };

    const getTypeDisplayName = (notificationType) => {
        // Define a mapping between notification types and display names
        const typeDisplayNames = {
            'new-work-order': 'Orden de trabajo creada',
            'work-order-rating': 'Orden de trabajo calificada',
            'new-product': 'Producto añadido',
            'new-process': 'Proceso añadido',
            'new-photo': 'Foto añadida',
            'new-comment': 'Nuevo comentario',
            'started': 'Orden de trabajo iniciada',
            'paused': 'Orden de trabajo pausada',
            'resumed': 'Orden de trabajo reanudada',
            'finalized': 'Orden de trabajo finalizada',
        };

        return typeDisplayNames[notificationType] || notificationType;
    };


    const markNotification = (notificationId, viewed) => {
        // Send a request to mark the notification as viewed or not viewed
        axios
            .post(
                `${Host}api/work-order-follow-configuration/notification/viewed/${notificationId}/${employeeId}`,
                { viewed }
            )
            .then((response) => {
                if (response.data.error === false) {
                    // Mark the notification accordingly in the state only if it's not already viewed
                    const updatedNotifications = notifications.map((notification) => {
                        if (notification.work_order_notification_id === notificationId && !notification.viewed) {
                            return { ...notification, viewed };
                        }
                        return notification;
                    });
                    setNotifications(updatedNotifications);
                    getNotificationCount(currentUser);
                } else {
                    console.error(`Error marking notification as ${viewed ? 'viewed' : 'not viewed'}`);
                }
            })
            .catch((error) => {
                console.error(`Error marking notification as ${viewed ? 'viewed' : 'not viewed'}:`, error);
            });
    };


    if (notifications.length === 0) {
        return (
            <Container>
                <ContainerHeader left title="Notificaciones generales" />
                <BodyContent>
                    <Empty description="Aún no tienes notificaciones" />
                </BodyContent>
            </Container>
        );
    }

    const filteredNotifications = notifications.filter((item) => {
        const isViewed = item.viewed;
        const isSearched = item.vehicle.toLowerCase().includes(searchText.toLowerCase());

        if (filter === 'all') {
            return isSearched;
        } if (filter === 'viewed') {
            return isViewed && isSearched;
        } if (filter === 'notViewed') {
            return !isViewed && isSearched;
        }
        return false;
    });

    return (
        <Container>
            <ContainerHeader left title="Notificaciones generales" />
            <BodyContent>
                <Row gutter={[24, 24]} justify="center" className="content-spacing">
                    <Col xs={20} sm={20} md={20} lg={12} xl={12} xxl={8} className="list-container">
                        <div className="filter-search-container">
                            <Radio.Group value={filter} onChange={(e) => setFilter(e.target.value)}>
                                <Radio.Button value="all">Todas</Radio.Button>
                                <Radio.Button value="viewed">Vistas</Radio.Button>
                                <Radio.Button value="notViewed">No vistas</Radio.Button>
                            </Radio.Group>
                            <Input.Search
                                placeholder="Buscar por vehículo"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                style={{ padding: '7px 0' }}
                            />
                        </div>
                        <List
                            itemLayout="vertical"
                            dataSource={filteredNotifications}
                            pagination={{
                                pageSize: 25,
                            }}
                            renderItem={(item) => (
                                <Link
                                    to={item.type === 'finalized' ? '/invoices' : `/administrateWorkOrder/${item.work_order_id}`}
                                    onClick={() => markNotification(item.work_order_notification_id, !item.viewed)}
                                    key={item.work_order_notification_id}>
                                    <List.Item
                                        actions={[
                                            <IconText
                                                icon={ClockCircleTwoTone}
                                                iconTwoTone="#cccccc"
                                                text={<span>Hace <TimeAgo inputDate={item.date} /></span>}
                                                key="list-interaction-two-tone"
                                            />,
                                            <Button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    markNotification(item.work_order_notification_id, true);
                                                    e.preventDefault();
                                                }}
                                                type={item.viewed ? 'default' : 'primary'}
                                                disabled={item.viewed}
                                                size='small'
                                                className='btn-deepblue'
                                            >
                                                {item.viewed ? 'Visto' : 'Marcar como visto'}
                                            </Button>
                                        ]}
                                    >
                                        <List.Item.Meta
                                            avatar={
                                                <Badge dot={!item.viewed}>
                                                    {getAvatarIcon(item.type)}
                                                </Badge>
                                            }
                                            title={item.vehicle}
                                            description={<div> <Tag color="default" style={{ marginBottom: '10px' }}>{getTypeDisplayName(item.type)}</Tag> <br /><strong> {item.notification}</strong></div>}
                                        />
                                    </List.Item>
                                </Link>
                            )}
                        />
                    </Col>
                </Row>
            </BodyContent>
        </Container>
    );
};

NotificationList.propTypes = {
    employeeId: PropTypes.number.isRequired,
    currentUser: PropTypes.objectOf(PropTypes.any).isRequired,
    getNotificationCount: PropTypes.func.isRequired
};

const mapStateToProps = (store) => ({
    currentUser: store.AppReducer.currentUser,
    employeeId: store.AppReducer.currentUser.employee_id
});

const mapDispatchToProps = (dispatch) => ({
    getNotificationCount(employeeId) {
        return dispatch(updateNotificationCount(employeeId))
    },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotificationList));
