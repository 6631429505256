import React, { Component } from 'react'
import {
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Col,
  Button,
  Row,
  Form,
  Tooltip,
} from 'react-bootstrap'
import PropTypes from 'prop-types'
import { SaveOutlined } from '@ant-design/icons'
import { getValidationState, showConfirmDialog } from '../../Common'
import { roundNumberTwoDecimals } from '../../../../Helpers'

const Tool = ({ text }) => <Tooltip id="tooltip">{text}</Tooltip>

Tool.propTypes = {
  text: PropTypes.string,
}

Tool.defaultProps = {
  text: '',
}

class ProcessModal extends Component {
  state = {
    process_name: '',
    process_estimated_time: '',
    process_price: '',
    loading: false,
  }

  componentDidUpdate(prevProps) {
    const { selectedProcess } = this.props
    const { selectedProcess: prevSelectedProcess } = prevProps

    if (selectedProcess !== prevSelectedProcess) {
      const { length } = Object.keys(selectedProcess)
      const id = selectedProcess.process_id
      if (
        length === 0 ||
        id === undefined ||
        id === null ||
        selectedProcess === undefined ||
        selectedProcess === null
      ) {
        this.clearComponent()
      } else if (length > 0 && id) {
        this.setProcessModalState()
      }
    }
  }

  setProcessModalState() {
    const { selectedProcess } = this.props
    this.setState({
      process_name: selectedProcess.process_name,
      process_estimated_time: selectedProcess.process_estimated_time,
      process_price: selectedProcess.process_price,
      loading: false,
    })
  }

  clearComponent = () => {
    this.setState({
      process_name: '',
      process_estimated_time: '',
      process_price: '',
      loading: false,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const {
      selectedProcess,
      toggleModal,
      addProcess,
      editMode,
      updateProcess,
      workshop_id,
    } = this.props

    toggleModal()

    const { process_name, process_estimated_time, process_price } = this.state

    const process = {
      process_id: selectedProcess.process_id || null,
      process_name,
      process_estimated_time: parseInt(process_estimated_time, 10),
      process_price: roundNumberTwoDecimals(parseFloat(process_price)) || 0,
      workshop_id: parseInt(workshop_id, 10),
    }

    if (editMode) {
      showConfirmDialog(
        'Modificar',
        `¿Modificar el proceso: ${process.process_name}?`,
        updateProcess,
        process,
        () => { },
        toggleModal,
        () => { }
      )
    } else {
      addProcess(process)
        .then(() => {
          this.clearComponent()
        })
        .catch(() => {
          this.setState({ loading: false })
          toggleModal()
        })
    }
  }

  handleChangeSelect = (value, field) => {
    if (value) {
      this.setState({ [field]: value[`${field}`] })
    } else {
      this.setState({ [field]: null })
    }
  }

  handleChange = (e, type) => {
    const { name } = e.target
    let { value } = e.target
    if (type === 'double') {
      value = value.replace(/[^0-9.]/g, '')
    } else if (type === 'integer') {
      value = value.replace(/[^0-9]/g, '')
    }
    this.setState({
      [name]: value,
    })
  }

  render() {
    const { editMode, modalOpen, toggleModal, permissions, pagePermissions } =
      this.props

    const { can_write } = permissions[1]

    const { process_name, process_estimated_time, process_price, loading } =
      this.state

    let can_editProcess = 0

    if (pagePermissions) {
      can_editProcess = pagePermissions.can_edit
    }

    return (
      <Modal show={modalOpen} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode ? 'Modificar ' : 'Agregar '}
            Proceso
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.handleSubmit}>
            <Row>
              <Col md={12}>
                <FormGroup validationState={getValidationState(process_name)}>
                  <ControlLabel>Nombre: </ControlLabel>
                  <FormControl
                    autoFocus
                    name="process_name"
                    type="text"
                    value={process_name}
                    onChange={this.handleChange}
                    required
                    placeholder="Ingresar"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup
                  validationState={getValidationState(process_estimated_time)}
                >
                  <ControlLabel>Tiempo en minutos: </ControlLabel>
                  <FormControl
                    name="process_estimated_time"
                    type="text"
                    value={process_estimated_time}
                    onChange={(e) => {
                      this.handleChange(e, 'integer')
                    }}
                    placeholder="0000"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup validationState={getValidationState(process_price)}>
                  <ControlLabel>Precio: </ControlLabel>
                  <FormControl
                    name="process_price"
                    type="text"
                    defaultValue={0}
                    value={roundNumberTwoDecimals(process_price)}
                    onChange={(e) => {
                      this.handleChange(e, 'double')
                    }}
                    placeholder="00.00"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button type="submit" hidden id="submitProcessForm" />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={toggleModal}>Cerrar</Button>
          {(!!can_editProcess || !!can_write) && (
            <Button
              disabled={loading}
              bsStyle="primary"
              onClick={() => {
                document.getElementById('submitProcessForm').click()
              }}
              className="btn-antd-icon"
            >
              <SaveOutlined />
              {editMode ? ' Modificar' : ' Agregar'}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    )
  }
}

ProcessModal.propTypes = {
  permissions: PropTypes.objectOf(PropTypes.object).isRequired,
  pagePermissions: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedProcess: PropTypes.objectOf(PropTypes.any).isRequired,
  workshop_id: PropTypes.number.isRequired,
  editMode: PropTypes.bool.isRequired,
  modalOpen: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  addProcess: PropTypes.func.isRequired,
  updateProcess: PropTypes.func.isRequired,
}

export default ProcessModal
