import React, { createContext, useContext, useReducer } from 'react'
import PropTypes from 'prop-types'

import {
  AddReceptionReducer,
  addReceptionInitialState,
} from '../reducers/AddReceptionReducer'

export const AddReceptionContext = createContext()

export const useAddReceptionContext = () => {
  return useContext(AddReceptionContext)
}

export const AddReceptionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    AddReceptionReducer,
    addReceptionInitialState
  )

  return (
    <AddReceptionContext.Provider value={{ state, dispatch }}>
      {children}
    </AddReceptionContext.Provider>
  )
}

AddReceptionProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
