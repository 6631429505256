import React, { Component } from 'react'
import {
  Modal,
  Button,
  Row,
  Col,
  FormControl,
  FormGroup,
  ControlLabel,
  InputGroup,
} from 'react-bootstrap'
import { ClipLoader } from 'react-spinners'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { SaveOutlined, UserOutlined } from '@ant-design/icons'
import KeyIcon from '../icons/KeyIcon'
import { NotificationHandler } from '../../Helpers'
import {
  comparePassword,
  updateCredentials,
} from '../../actions/EmployeeAction'

class UpdateCredentials extends Component {
  state = {
    loading: false,
    password: '',
    newPassword: '',
    newPasswordState: true,
    username: '',
  }

  UNSAFE_componentWillMount() {
    const { currentUser } = this.props
    this.setState({ username: currentUser.employee_username })
  }

  UNSAFE_componentWillReceiveProps() {
    const { currentUser } = this.props
    this.setState({ username: currentUser.employee_username })
  }

  getInitialState = () => ({
    loading: false,
    previousUsername: '',
    password: '',
    newPassword: '',
    newPasswordState: true,
    currentUser: {},
  })

  handleSubmit = (e) => {
    e.preventDefault()
    this.update()
  }

  handleInputText = (e) => {
    const { name, value } = e.target
    this.setState({
      [name]: value,
    })
  }

  handlePasswordChange = (e) => {
    const { name, value } = e.target
    this.setState(
      {
        [name]: value,
      },
      () => {
        const { newPassword } = this.state
        if (newPassword.length >= 8) {
          this.setState({
            newPasswordState: true,
          })
        } else {
          this.setState({
            newPasswordState: false,
          })
        }
      }
    )
  }

  update() {
    this.setState({ loading: true })
    const { username, password, newPassword } = this.state
    const {
      currentUser,
      compareUserPassword,
      updateUserCredentials,
      handleClose,
    } = this.props
    let employee = {
      id: currentUser.employee_id,
      password,
    }
    compareUserPassword(employee)
      .then((response) => {
        if (response.matched) {
          employee = {
            ...employee,
            employee_username: username,
            employee_password: newPassword,
            row: currentUser,
          }
          updateUserCredentials(employee)
          NotificationHandler(
            'Done',
            'info',
            'Su contraseña ha sido modificada'
          )
          handleClose()
          this.setState(this.getInitialState())
        } else {
          NotificationHandler('Error', 'error', 'Contraseña actual incorrecta')
          this.setState({ loading: false })
        }
      })
      .catch(() => {
        this.setState({ loading: false })
        NotificationHandler('Error', 'error', 'No se modificó la contraseña')
      })
  }

  render() {
    const { loading, username, password, newPassword, newPasswordState } =
      this.state
    const { handleClose, modalState } = this.props
    return (
      <Modal bsSize="sm" onHide={handleClose} show={modalState}>
        <Modal.Header closeButton>
          <Modal.Title>Cambiar Contraseña</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={(e) => this.handleSubmit(e)}>
            <Row>
              <Col xs={12}>
                <FormGroup>
                  <ControlLabel>Usuario:</ControlLabel>
                  <InputGroup>
                    <InputGroup.Addon>
                      <UserOutlined />
                    </InputGroup.Addon>
                    <FormControl
                      required
                      autoComplete="off"
                      type="text"
                      name="username"
                      value={username}
                      readOnly
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup>
                  <ControlLabel>Contraseña Actual:</ControlLabel>
                  <InputGroup>
                    <InputGroup.Addon>
                      <KeyIcon color="#555" />
                    </InputGroup.Addon>
                    <FormControl
                      autoFocus
                      required
                      autoComplete="off"
                      type="password"
                      name="password"
                      value={password}
                      onChange={(e) => this.handleInputText(e)}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup
                  validationState={newPasswordState ? 'success' : 'error'}
                >
                  <ControlLabel>Nueva Contraseña:</ControlLabel>
                  <InputGroup>
                    <InputGroup.Addon>
                      <KeyIcon color="#3c763d" />
                    </InputGroup.Addon>
                    <FormControl
                      required
                      minLength={8}
                      autoComplete="off"
                      type="password"
                      name="newPassword"
                      value={newPassword}
                      onChange={(e) => this.handlePasswordChange(e)}
                    />
                  </InputGroup>
                  <FormControl.Feedback />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                {loading ? (
                  <ClipLoader
                    style={{ marginLeft: '25px' }}
                    size={22}
                    color="#4E5555"
                    loading
                  />
                ) : (
                  <Button
                    style={{
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      fontSize: '1.2em',
                    }}
                    bsStyle="primary"
                    type="submit"
                    className="btn-antd-icon"
                  >
                    <SaveOutlined /> Guardar
                  </Button>
                )}
              </Col>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    )
  }
}

UpdateCredentials.propTypes = {
  currentUser: PropTypes.objectOf(PropTypes.any).isRequired,
  modalState: PropTypes.bool.isRequired,
  compareUserPassword: PropTypes.func.isRequired,
  updateUserCredentials: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
}

const mapStateToProps = (store) => ({
  currentUser: store.AppReducer.currentUser,
  employees: store.EmployeeReducer.employees,
})

const mapDispatchToProps = (dispatch) => ({
  compareUserPassword(employee) {
    return comparePassword(employee)
  },
  updateUserCredentials(employee) {
    return dispatch(updateCredentials(employee))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateCredentials)
