import _ from 'lodash'

import {
  GET_WORK_ORDERS,
  ADD_WORK_ORDER,
  UPDATE_WORK_ORDER,
  DELETE_WORK_ORDER,
  FINISH_WORK_ORDER,
  SET_WORK_ORDER_INVOICED,
  SET_WORK_ORDER_TO_ADMINISTRATE,
  SET_WORK_ORDER_QUANTITY_INFORMATION,
  GET_COMMENTS_WORK_ORDER
} from '../actionTypes/workOrderTypes'

export default (
  state = {
    workOrders: {},
    workOrderToAdministrate: {},
    commentsWorkOrder: []
  },
  action
) => {
  switch (action.type) {
    case GET_WORK_ORDERS: {
      return {
        ...state,
        workOrders: action.payload,
      }
    }
    case ADD_WORK_ORDER: {
      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          [action.payload.work_order_id]: action.payload,
        },
      }
    }
    case UPDATE_WORK_ORDER: {
      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          [action.payload.work_order_id]: action.payload,
        },
      }
    }
    case DELETE_WORK_ORDER: {
      const newState = _.cloneDeep(state)
      delete newState.workOrders[action.payload.work_order_id]
      return newState
    }
    case FINISH_WORK_ORDER: {
      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          [action.payload.work_order_id]: action.payload,
        },
      }
    }
    case SET_WORK_ORDER_INVOICED: {
      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          [action.payload]: {
            ...state.workOrders[action.payload],
            work_order_invoiced: 1,
          },
        },
      }
    }
    case SET_WORK_ORDER_TO_ADMINISTRATE: {
      return {
        ...state,
        workOrderToAdministrate: {
          ...action.payload,
        },
      }
    }
    case SET_WORK_ORDER_QUANTITY_INFORMATION: {
      return {
        ...state,
        workOrdersQuantityInformation: action.payload,
      }
    }
    case GET_COMMENTS_WORK_ORDER: {
      return {
        ...state,
        commentsWorkOrder: action.payload,
      }
    }

    default: {
      return state
    }
  }
}
