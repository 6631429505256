import React, { Component } from 'react'
import { connect } from 'react-redux'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory from 'react-bootstrap-table2-filter'
import { Panel, Grid, Row, Col, Button } from 'react-bootstrap'
import _ from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { MenuOutlined, PlusCircleFilled } from '@ant-design/icons';
import {
  getSeries,
  addSeries,
  updateSeries,
  deleteSeries,
} from '../../../../../actions/SeriesAction'
import {
  Container,
  ContainerHeader,
  BodyContent,
  showConfirmDialog,
  PanelHeader,
} from '../../../Common/index'
import SeriesModal from './SeriesModal'
import getColumns from './columns'
import { getInvoiceType } from '../../../../../actions/InvoiceTypeAction'
import { BREAK_POINTS_DESKTOP_FIRST } from '../../../../../global/css/breakPoints'
import { AppLoader } from '../../../Common/AppLoader'

const SCREEN_ID = 39

class ModelsContainer extends Component {
  state = {
    showModal: false,
    loading: true,
    selectedSerie: {},
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions)
    const { getAllSeries, getInvoiceTypes } = this.props
    getInvoiceTypes()
    getAllSeries()
      .then(() => {
        this.setState({ loading: false })
      })
      .catch(() => {
        this.setState({ loading: false })
      })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  updateDimensions = () => {
    const { width } = window.screen
    this.setState({ width })
  }

  toggleModal = () => {
    const { showModal } = this.state
    this.setState({ showModal: !showModal })
  }

  addSerie = () => {
    this.setState({ selectedSerie: {} })
    this.toggleModal()
  }

  updateSerie = (serie) => {
    this.setState({ selectedSerie: serie })
    this.toggleModal()
  }

  deleteSerie = (serie) => {
    const { deleteSerie } = this.props
    showConfirmDialog(
      '¿Eliminar?',
      `¿Desea eliminar el número de serie ${serie.serie_number}?`,
      deleteSerie,
      serie
    )
  }

  render() {
    const { showModal, selectedSerie, loading, width } = this.state
    const { series, permissions, addSerie, updateSerie, invoiceTypes } =
      this.props
    const { can_write, can_edit, can_delete } = permissions[SCREEN_ID]
    const pagePermissions = {
      can_write,
      can_edit,
      can_delete,
    }
    return (
      <Container>
        <ContainerHeader title="Series" />
        <BodyContent>
          <Grid fluid>
            <Row>
              <Col sm={12}>
                <Panel>
                  <Panel.Heading bsClass="panel-heading">
                    <Panel.Title>
                      <PanelHeader>
                        <div>
                          <p id="title">
                            <MenuOutlined style={{ marginRight: '7px' }} /> Administrar Series
                          </p>
                        </div>
                        <div>
                          {!!can_write && (
                            <Button
                              onClick={this.addSerie}
                              bsStyle="success"
                              className="btn-add btn-antd-icon"
                            >
                              <PlusCircleFilled />
                              Agregar registro
                            </Button>
                          )}
                        </div>
                      </PanelHeader>
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    {loading ? (
                      <AppLoader />
                    ) : (
                      <Row>
                        <Col md={10} />
                        <Col md={12}>
                          <CustomStyledTable>
                            <BootstrapTable
                              noDataIndication="Sin datos para mostrar"
                              keyField="serie_id"
                              data={_.values(series).reverse()}
                              columns={getColumns(
                                this.updateSerie,
                                this.deleteSerie,
                                { can_edit, can_delete },
                                width
                              )}
                              filter={filterFactory()}
                              pagination={paginationFactory()}
                            />
                          </CustomStyledTable>
                        </Col>
                      </Row>
                    )}
                  </Panel.Body>
                </Panel>
              </Col>
            </Row>
          </Grid>
          <SeriesModal
            modalOpen={showModal}
            toggleModal={this.toggleModal}
            editMode={!_.isEmpty(selectedSerie)}
            addSerie={addSerie}
            invoiceTypes={invoiceTypes}
            updateSerie={updateSerie}
            selectedSerie={selectedSerie}
            pagePermissions={pagePermissions}
          />
        </BodyContent>
      </Container>
    )
  }
}

const CustomStyledTable = styled.div`
  @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
    table {
      border: none;
      padding: 0px 10px;

      thead {
        tr {
          display: flex;
          flex-direction: column;

          th {
            label {
              width: 100%;
            }
          }

          th {
            border: none;
          }

          th:nth-last-child(1),
          th:nth-last-child(2) {
            display: none;
          }
        }
      }

      tbody {
        tr {
          border: 1px solid #ddd;
          display: flex;
          flex-wrap: wrap;
          border-radius: 10px;
          margin: 20px 10px;
          padding-top: 10px;
          padding-bottom: 10px;

          td {
            width: 100%;
            border: 0;
            border-right: 0px !important;
            border-left: 0px !important;
          }

          td:nth-last-child(1),
          td:nth-last-child(2) {
            width: 40%;
          }

          td::before {
            font-weight: bold;
            margin-right: 0.5rem;
          }

          td:nth-child(1)::before {
            content: 'Número de serie:';
          }

          td:nth-child(2)::before {
            content: 'Inicio de serie:';
          }

          td:nth-child(3)::before {
            content: 'Fin de serie:';
          }

          td:nth-child(4)::before {
            content: 'Número actual:';
          }

          td:nth-child(5)::before {
            content: 'Longitud de serie:';
          }

          td:nth-child(6)::before {
            content: 'Tipo de facturación:';
          }
        }
      }
    }
  }
`

const mapStateToProps = (store) => ({
  series: store.SeriesReducer.series,
  permissions: store.AppReducer.permissions,
  invoiceTypes: store.InvoiceTypeReducer.invoiceTypes,
})

const mapDispatchToProps = (dispatch) => ({
  getAllSeries() {
    return dispatch(getSeries())
  },
  addSerie(serie) {
    return dispatch(addSeries(serie))
  },
  updateSerie(serie) {
    return dispatch(updateSeries(serie))
  },
  deleteSerie(serie) {
    return dispatch(deleteSeries(serie))
  },
  getInvoiceTypes() {
    return dispatch(getInvoiceType())
  },
})

ModelsContainer.propTypes = {
  getAllSeries: PropTypes.func.isRequired,
  getInvoiceTypes: PropTypes.func.isRequired,
  addSerie: PropTypes.func.isRequired,
  updateSerie: PropTypes.func.isRequired,
  deleteSerie: PropTypes.func.isRequired,
  series: PropTypes.arrayOf(PropTypes.object).isRequired,
  invoiceTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  permissions: PropTypes.objectOf(PropTypes.object).isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModelsContainer)
