import React, { Component } from 'react'
import writtenNumbers from 'written-number'
import Select from 'react-select'
import styled from 'styled-components'
import moment from 'moment'
import { Button } from 'react-bootstrap'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { FolderFilled, PrinterFilled, ExclamationCircleFilled } from '@ant-design/icons'
import { Row, FormControl, ControlLabel } from '../../../GlobalComponents/Utils'
import { InvoiceFrame } from '../Styled/InvoiceStyledComponents'
import Header from './InvoiceHeader'
import InvoiceDetails from './InvoiceDetails'
import InvoiceDetailsWithoutTaxes from './InvoiceDetailsWithoutTaxes'
import { NotificationHandler } from '../../../../Helpers'
import { updateInvoice, printInvoicePDF, COUNTRY_TAX } from './InvoiceFunctions'
import { printInvoicePDFWithoutTaxes, updateInvoiceWithoutTaxes } from './InvoiceFunctionsWithoutTaxes'
import printTicketPDF from './TicketFunctions'
import printTicketPDFWithoutTaxes from './TicketFunctionsWithoutTaxes'
import PrintModal from './PrintModal'


writtenNumbers.defaults.lang = 'es'

class Invoice extends Component {
  state = this.getInitialState()

  getInitialState() {
    return {
      confirmModalOpen: false,
      selectedInvoiceType: null,
      invoiceDetails: {},
      modalOpen: false,
      headerFields: {
        date: moment().format('DD-MM-YYYY'),
        person_name: '',
        person_address: '',
        person_nit: '',
        state_name: '',
        contributor_name: '',
        person_business_activity: '',
        person_nrc: '',
      },
      bodyFields: {},
      footerDetails: {},
      headerFieldsWithoutTaxes: {},
      bodyFieldsWithoutTaxes: {},
      footerDetailsWithoutTaxes: {},
      serie: {},
      pdfUrl: '',
      isTicket: false,
      generatingPdf: false,
      invoicedWorkOrder: null,
      loadingInvoicingWorkOrder: false,
      countryTax: COUNTRY_TAX, // El Salvador tax
      invoiceId: null,
    }
  }

  componentDidMount() {
    const { workOrderToInvoice } = this.props
    const { countryTax: tax } = this.state

    const { headerFields, bodyFields, footerDetails, countryTax } =
      updateInvoice(workOrderToInvoice, tax)

    const { headerFieldsWithoutTaxes, bodyFieldsWithoutTaxes, footerDetailsWithoutTaxes } =
      updateInvoiceWithoutTaxes(workOrderToInvoice)

    this.setState({
      headerFields,
      bodyFields,
      footerDetails,
      headerFieldsWithoutTaxes,
      bodyFieldsWithoutTaxes,
      footerDetailsWithoutTaxes,
      countryTax,
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { workOrderToInvoice } = nextProps
    const { countryTax: tax } = this.state

    const { headerFields, bodyFields, footerDetails, countryTax } =
      updateInvoice(workOrderToInvoice, tax)

    const { headerFieldsWithoutTaxes, bodyFieldsWithoutTaxes, footerDetailsWithoutTaxes } =
      updateInvoiceWithoutTaxes(workOrderToInvoice)

    this.setState({
      headerFields,
      bodyFields,
      footerDetails,
      headerFieldsWithoutTaxes,
      bodyFieldsWithoutTaxes,
      footerDetailsWithoutTaxes,
      countryTax,
    })
  }

  handleChange = (e) => {
    const { name, value } = e.target
    this.setState({
      [name]: value,
    })
  }

  toggleModal = () => {
    const { modalOpen } = this.state
    this.setState({ modalOpen: !modalOpen })
  }

  toggleModalCloseButton = () => {
    const { modalOpen } = this.state
    const { resetWorkOrder } = this.props
    resetWorkOrder()
    this.setState({ modalOpen: !modalOpen })
  }

  getSelectedSerieIcon = (serie = {}) => {
    if (serie.invoiceTypes) {
      switch (serie.invoiceTypes.invoice_type_id) {
        case 1:
          return <i className="fas fa-file-alt" />
        case 2:
          return <i className="fas fa-file-invoice" />
        case 3:
          return <i className="fas fa-truck" />
        case 4:
          return <i className="fas fa-receipt" />

        default:
          return <i className="fas fa-receipt" />
      }
    }
    return <i className="fas fa-file-alt" />
  }

  handleChangeSelect = (field, value) => {
    const { countryTax: tax } = this.state
    if (!value) {
      this.setState({ [field]: {} })
    } else {
      this.setState({ [field]: value }, () => {
        if (field === 'serie') {
          const { workOrderToInvoice } = this.props

          const { headerFields, bodyFields, footerDetails, countryTax } =
            updateInvoice(workOrderToInvoice, tax)

          const { headerFieldsWithoutTaxes, bodyFieldsWithoutTaxes, footerDetailsWithoutTaxes } =
            updateInvoiceWithoutTaxes(workOrderToInvoice)

          this.setState({
            headerFields,
            bodyFields,
            footerDetails,
            headerFieldsWithoutTaxes,
            bodyFieldsWithoutTaxes,
            footerDetailsWithoutTaxes,
            countryTax,
          })
        }
      })
    }
  }

  invoiceWorkOrder = async () => {
    this.setState({ loadingInvoicingWorkOrder: true })
    const { invoicedWorkOrder } = this.state
    const { setWorkOrderInvoiced, resetWorkOrder } = this.props
    try {
      await setWorkOrderInvoiced(invoicedWorkOrder)
      this.toggleModal()
      resetWorkOrder()
      NotificationHandler(
        'Orden facturada',
        'info',
        'Orden de trabajo facturada'
      )
    } catch (error) {
      NotificationHandler('Error', 'error', error.message)
    }
  }

  getInvoiceType = () => {
    const { serie } = this.state
    const { invoiceTypes } = serie
    if (invoiceTypes) {
      return invoiceTypes.invoice_type_id
    }
    return null
  }

  printInvoice = async () => {
    this.setState({ generatingPdf: true })
    const { updateActualNumber, workOrderToInvoice, addInvoice } = this.props
    if (Object.keys(workOrderToInvoice.invoice).length > 0) {
      this.onlyPrint()
      return
    }

    const { work_order_id, workshop, person, include_taxes } = workOrderToInvoice

    const { serie, headerFields, bodyFields, footerDetails, countryTax, headerFieldsWithoutTaxes, bodyFieldsWithoutTaxes, footerDetailsWithoutTaxes } =
      this.state

    if (Object.keys(serie).length === 0) {
      NotificationHandler(
        'Error',
        'error',
        'Seleccione la serie del comprobante'
      )
      this.setState({ generatingPdf: false })
      return
    }

    const { sums, total, totalTax, retention } = footerDetails
    const dollars = Math.floor(parseFloat(total, 10))
    const cents = Math.round((total - dollars) * 100)

    const { serie_actual_number } = serie
    const invoice = {
      invoice_number: serie_actual_number,
      serie_id: serie.serie_id,
      person_id: person.person_id,
      invoice_is_cancelled: 1,
      invoice_actual_tax: countryTax,
      invoice_is_exempt: 0,
      invoice_amount: sums,
      invoice_total_tax: totalTax,
      invoice_retention: retention,
      invoice_total: total,
      invoice_date: moment(),
      invoice_total_in_words: `${writtenNumbers(
        dollars
      ).toUpperCase()} DÓLARES CON ${cents}/100 CENTAVOS.`,
      work_order_id,
      payment_type_id: 1, // Contado. TABLE: PAYMENT_TYPES
    }
    const packDetail = {
      invoice_detail_quantity: bodyFields.pack.quantity,
      invoice_detail_unit_price:
        bodyFields.pack.originalPriceWithDiscountLessTaxTotal,
      invoice_detail_parcial_total:
        bodyFields.pack.originalPriceWithDiscount * bodyFields.pack.quantity,
      invoice_detail_taxed: bodyFields.pack.taxTotalOriginalPriceWithDiscount,
      invoice_detail_description: bodyFields.pack.description,
      invoice_detail_discount: bodyFields.pack.totalDiscount,
      invoice_detail_unitprice_notaxed:
        bodyFields.pack.originalPriceWithDiscount,
    }
    const details = _.map(bodyFields.details, (dt) => ({
      invoice_detail_quantity: dt.quantity,
      invoice_detail_unit_price: dt.originalPriceWithDiscountLessTaxTotal,
      invoice_detail_parcial_total: dt.originalPriceWithDiscount * dt.quantity,
      invoice_detail_taxed: dt.taxTotalOriginalPriceWithDiscount,
      invoice_detail_description: dt.description,
      invoice_detail_discount: dt.totalDiscount,
      invoice_detail_unitprice_notaxed: dt.originalPriceWithDiscount,
      product_id: dt.product_id,
      process_id: dt.process_id,
    }))

    // validation to avoid a empty package detail in the invoice details
    let invoiceDetails = []
    if (
      packDetail.invoice_detail_quantity === undefined ||
      packDetail.invoice_detail_quantity === null
    ) {
      invoiceDetails = [...details]
    } else {
      invoiceDetails = [packDetail, ...details]
    }

    let invoiceId
    let pdfUrl = null
    try {
      const addedInvoice = await addInvoice(invoice, invoiceDetails)
      await updateActualNumber(serie.serie_id)
      invoiceId = addedInvoice.data.invoice_id

      const {
        invoiceTypes: { invoice_type_code },
      } = serie

      if (include_taxes) {
        if (invoice_type_code === 1) {
          pdfUrl = await printTicketPDF(
            workshop,
            serie_actual_number,
            bodyFields,
            footerDetails,
            person
          )
        } else {
          pdfUrl = await printInvoicePDF(headerFields, bodyFields)
        }
      } else {
        console.log("Printing without taxes");
        if (invoice_type_code === 1) {
          pdfUrl = await printTicketPDFWithoutTaxes(
            workshop,
            serie_actual_number,
            bodyFieldsWithoutTaxes,
            footerDetailsWithoutTaxes,
            person
          )
        } else {
          pdfUrl = await printInvoicePDFWithoutTaxes(headerFieldsWithoutTaxes, bodyFieldsWithoutTaxes)
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      // console.log(error)
    }

    this.setState({
      pdfUrl,
      modalOpen: true,
      generatingPdf: false,
      invoicedWorkOrder: work_order_id,
      invoiceId,
    })
  }

  onlyPrint = async () => {
    const { workOrderToInvoice } = this.props
    const { headerFields, bodyFields, footerDetails, headerFieldsWithoutTaxes, bodyFieldsWithoutTaxes, footerDetailsWithoutTaxes } = this.state
    const invoiceId = workOrderToInvoice.invoice.invoice_id

    const {
      work_order_id,
      person,
      invoice: {
        invoice_number,
        serie: {
          invoiceTypes: { invoice_type_code },
        },
      },
      workshop,
      include_taxes
    } = workOrderToInvoice

    let pdfUrl = null

    if (include_taxes) {
      if (invoice_type_code === 1) {
        pdfUrl = await printTicketPDF(
          workshop,
          invoice_number,
          bodyFields,
          footerDetails,
          person
        )
      } else {
        pdfUrl = await printInvoicePDF(headerFields, bodyFields)
      }
    } else {
      console.log("Printing without taxes");
      if (invoice_type_code === 1) {
        pdfUrl = await printTicketPDFWithoutTaxes(
          workshop,
          invoice_number,
          bodyFieldsWithoutTaxes,
          footerDetailsWithoutTaxes,
          person
        )
      } else {
        pdfUrl = await printInvoicePDFWithoutTaxes(headerFieldsWithoutTaxes, bodyFieldsWithoutTaxes)
      }
    }

    this.setState({
      pdfUrl,
      modalOpen: true,
      generatingPdf: false,
      invoicedWorkOrder: work_order_id,
      invoiceId,
    })
  }

  render() {
    const {
      serie,
      selectedInvoiceType,
      headerFields,
      bodyFields,
      footerDetails,
      headerFieldsWithoutTaxes,
      bodyFieldsWithoutTaxes,
      footerDetailsWithoutTaxes,
      pdfUrl,
      modalOpen,
      generatingPdf,
      loadingInvoicingWorkOrder,
      invoiceId,
    } = this.state
    const { toggleList, series, workOrderToInvoice, showList, showInvoice } =
      this.props
    const { invoice, work_order_id, include_taxes } = workOrderToInvoice

    console.log("WorkorderToInvoice", workOrderToInvoice);
    console.log("WorkorderId", work_order_id);
    console.log("Include taxes: ", include_taxes);

    const isWorkOrderInvoiced = Object.keys(invoice).length > 0
    const formattedSeries = series.map((s) => ({
      ...s,
      serie_text_option: `(${s.serie_actual_number}) ${s.serie_number} - ${s.invoiceTypes.invoice_type_name}`,
    }))

    return (
      <InvoiceFrame showInvoice={showInvoice} id="hello">
        {!showList && (
          <button
            type="button"
            onClick={toggleList}
            className="showListButton button"
          >
            <i className="fas fa-th-list" />
          </button>
        )}
        <div className="serie">
          <Row justifyContent="space-between">
            <div>
              <FolderFilled style={{ marginRight: "5px" }} />
              <em>Orden #{work_order_id}</em>
            </div>
          </Row>
          <Row>
            <div>
              <ControlLabel>Serie y comprobante:</ControlLabel>
              <div>
                <Select
                  className="invoice-select"
                  clearable={false}
                  options={formattedSeries}
                  labelKey="serie_text_option"
                  value={!isWorkOrderInvoiced ? serie : invoice.serie_id}
                  valueKey="serie_id"
                  placeholder="Serie"
                  required
                  disabled={isWorkOrderInvoiced}
                  onChange={(v) => this.handleChangeSelect('serie', v)}
                />
              </div>
            </div>
            <div style={{ marginLeft: '5px' }}>
              <ControlLabel>Fecha: </ControlLabel>
              <FormControl type="text" readOnly value={headerFields.date} />
            </div>
          </Row>
        </div>
        <div id="invoice">
          <Header
            selectedInvoiceType={selectedInvoiceType || ''}
            workOrderToInvoice={workOrderToInvoice}
            fields={include_taxes ? headerFields : headerFieldsWithoutTaxes}
          />
          {include_taxes ? (
            <>
              <InvoiceDetails
                bodyFields={bodyFields}
                footerDetails={footerDetails}
              />
            </>
          )
            : (
              <>
                <InvoiceDetailsWithoutTaxes
                  bodyFields={bodyFieldsWithoutTaxes}
                  footerDetails={footerDetailsWithoutTaxes}
                />
              </>
            )}
        </div>
        <Row>
          <PrintBox>
            <Button
              disabled={generatingPdf}
              bsStyle="primary"
              onClick={this.printInvoice}
              className="btn-antd-icon"
            >
              {isWorkOrderInvoiced ? (<PrinterFilled />) : (<ExclamationCircleFilled />)}
              {isWorkOrderInvoiced ? 'Imprimir' : 'Procesar'}
            </Button>
          </PrintBox>
        </Row>
        <PrintModal
          modalOpen={modalOpen}
          toggleModal={this.toggleModal}
          toggleModalCloseButton={this.toggleModalCloseButton}
          disabled
          src={pdfUrl}
          invoiceId={invoiceId}
          invoiceWorkOrder={this.invoiceWorkOrder}
          loadingInvoicingWorkOrder={loadingInvoicingWorkOrder}
          confirmModalProps={{
            serie,
          }}
        />
      </InvoiceFrame>
    )
  }
}

const PrintBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

Invoice.propTypes = {
  workOrderToInvoice: PropTypes.objectOf(PropTypes.any).isRequired,
  series: PropTypes.arrayOf(PropTypes.object).isRequired,
  toggleList: PropTypes.func.isRequired,
  showInvoice: PropTypes.func.isRequired,
  showList: PropTypes.func.isRequired,
  updateActualNumber: PropTypes.func.isRequired,
  addInvoice: PropTypes.func.isRequired,
  resetWorkOrder: PropTypes.func.isRequired,
  setWorkOrderInvoiced: PropTypes.func.isRequired,
}

export default Invoice
