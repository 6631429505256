import * as React from 'react'
import ReactDOM from 'react-dom'
import { Modal, Button } from 'react-bootstrap'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { usernameRegex } from '../../Utilities/validations'
import {
  SearchbarContainer,
  HeaderItemsContainer,
  ComponentContainer,
  TitleContainer,
} from './Components'
import { AppLoader } from './AppLoader'

export const PanelHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  div p {
    margin: 0px;
  }
  div:first-child {
    margin-right: 10px;
  }
`

export const Container = ({ children }) => (
  <div className="component-content">{children}</div>
)

Container.propTypes = {
  children: PropTypes.node.isRequired,
}

export const ContainerHeader = ({ title, component, search }) => (
  <div className="content-header">
    <div className="header-section-nav">
      <HeaderItemsContainer left>
        <TitleContainer>
          <h1>{title}</h1>
        </TitleContainer>
        {search && <SearchbarContainer>{search}</SearchbarContainer>}
      </HeaderItemsContainer>
      <ComponentContainer>{component}</ComponentContainer>
    </div>
  </div>
)

export const BodyContent = ({ children }) => (
  <div id="body-content">{children}</div>
)

BodyContent.propTypes = {
  children: PropTypes.node.isRequired,
}

const LoaderContainer = styled.div`
  float: left;
  width: 50px;
`
class ConfirmDialog extends React.Component {
  state = {
    show: true,
    loading: false,
  }

  onCancel = () => {
    const { onCancel } = this.props
    this.toggleModal()
    if (onCancel) {
      onCancel()
    }
  }

  unmountModal = () => {
    setTimeout(() => {
      ReactDOM.unmountComponentAtNode(document.getElementById('confirmDialog'))
    }, 500)
  }

  toggleModal = () => {
    const { show } = this.state
    this.setState({ show: !show })
    const { onCancel } = this.props
    if (onCancel) {
      onCancel()
    }
    this.unmountModal()
  }

  process = () => {
    const {
      method,
      obj,
      optionalObj,
      optionalCallBack,
      onSuccess,
      onError,
      passResMethodToOptionalCallBack,
    } = this.props
    this.setState({ loading: true })
    method(obj, optionalObj, optionalCallBack)
      .then((response) => {
        this.setState({ loading: false, show: false })
        if (optionalCallBack) {
          if (optionalObj !== null) {
            if (passResMethodToOptionalCallBack) {
              optionalCallBack(response)
            } else {
              optionalCallBack(optionalObj)
            }
          } else {
            optionalCallBack()
          }
        }
        if (onSuccess) {
          onSuccess()
        }
        this.unmountModal()
      })
      .catch(() => {
        this.setState({ loading: false })
        if (onError) {
          onError()
        }
      })
  }

  render() {
    const { title, message } = this.props
    const { show, loading } = this.state
    return (
      <Modal show={show} onHide={this.toggleModal} className='text-center'>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <center>
            <h4>{message}</h4>
          </center>
        </Modal.Body>
        <Modal.Footer style={{ textAlign: 'center' }}>
          {loading && (
            <LoaderContainer>
              <AppLoader />
            </LoaderContainer>
          )}
          <Button disabled={loading} bsStyle="danger" onClick={this.onCancel}>
            <i className="fas fa-times prefix" /> Cancelar
          </Button>
          <Button disabled={loading} bsStyle="success" onClick={this.process}>
            <i className="fas fa-check" /> Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export const showConfirmDialog = (
  title,
  message,
  method,
  obj,
  onSuccess,
  onError,
  onCancel,
  optionalObj,
  optionalCallBack,
  passResMethodToOptionalCallBack
) => {
  ReactDOM.render(
    <ConfirmDialog
      title={title}
      method={method}
      message={message}
      obj={obj}
      onSuccess={onSuccess}
      onError={onError}
      onCancel={onCancel}
      optionalObj={optionalObj}
      optionalCallBack={optionalCallBack}
      passResMethodToOptionalCallBack={passResMethodToOptionalCallBack}
    />,
    document.getElementById('confirmDialog')
  )
}

export const getValidationCilinder = (x, y) => {
  if (+x > +y) {
    return 'success'
  }
  return 'error'
}

export const getValidationState = (inputValue, isSelect, field) => {
  if (field === 'employee_username') {
    if (inputValue === '') {
      return ''
    }

    if (inputValue.length >= 3 && usernameRegex.test(inputValue)) {
      return 'success'
    }
    return 'error'
  }

  if (field === 'person_email') {
    if (inputValue.includes('@') && inputValue.includes('.')) {
      return 'success'
    }
    return 'error'
  }
  if (
    field === 'famili_cilinder_capacity_up' ||
    field === 'family_cilinder_capacity_from'
  ) {
    if (inputValue > 0) {
      return 'success'
    }
    return 'error'
  }

  if (isSelect) {
    if (inputValue === '' || inputValue === null) {
      return 'error'
    }
    return 'success'
  }
  if (inputValue === '') {
    return 'error'
  }
  if (inputValue === null || inputValue === undefined) {
    return 'error'
  }
  if (inputValue !== '') {
    return 'success'
  }

  return 'error'
}

ConfirmDialog.defaultProps = {
  onSuccess: () => { },
  onError: () => { },
  onCancel: () => { },
  optionalObj: {},
  optionalCallBack: () => { },
  passResMethodToOptionalCallBack: false,
}

ConfirmDialog.propTypes = {
  method: PropTypes.func.isRequired,
  obj: PropTypes.objectOf(PropTypes.any).isRequired,
  optionalObj: PropTypes.objectOf(PropTypes.any),
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  onCancel: PropTypes.func,
  optionalCallBack: PropTypes.func,
  passResMethodToOptionalCallBack: PropTypes.func,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
}

ContainerHeader.propTypes = {
  title: PropTypes.string.isRequired,
  component: PropTypes.node,
  search: PropTypes.node,
}

ContainerHeader.defaultProps = {
  search: <div />,
  component: <div />,
}
