import styled from 'styled-components'

export const AssignMechanicModalWrapper = styled.div`
  min-height: 260px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 24px;

  .selected-mechanic {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .selected-label {
      font-size: 1em;
      font-weight: 600;
    }
    .avatar-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .avatar {
        display: flex;
        align-items: center;
        gap: 8px;

        p {
          font-weight: 500;
          margin: unset;
        }
      }
      button {
        background-color: unset;
        border: unset;
        font-size: 1.5rem;
        color: #d80000;
        margin-top: 4px;
      }
    }
  }
`
