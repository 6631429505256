import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import { Divider, Radio } from 'antd'
import PropTypes from 'prop-types'

import { useAddReceptionContext } from '../../../../../../contexts/AddReceptionContext'
import GradientTextComponent from '../../../../Common/GradientText.js/GradientText'
import PreviousNextButton from '../../../../../buttons/PreviousNextButton/PreviousNextButton'
import VehicleSummaryCard from '../VehicleSummaryCard/VehicleSummaryCard'
import MultiColorPicker from '../../../../Common/MultiColorPicker/MultiColorPicker'
import { DetailsInputs, SummaryContainer } from './VehicleDetails.style'
import { addReceptionActions } from '../../../../../../reducers/AddReceptionReducer'
import InfoDisclaimer from '../../../../Common/InfoDisclaimer/InfoDisclaimer'
import { getVehicleTypeIcon } from '../../../../../../Helpers/getVehicleTypeIcon'
import {
  getContrastingColor,
  isColorVisibleOnWhite,
} from '../../../../../../Helpers'

const VehicleDetailsForm = ({
  isCreationLoading,
  measureUnits,
  handlePrevious,
  addVehicle,
}) => {
  const { state, dispatch } = useAddReceptionContext()
  const { newVehicle } = state
  const { colors, measureUnitId } = newVehicle

  const measureUnitsArray = Object.values(measureUnits)

  const isCreateButtonDisabled =
    !measureUnitId || colors.length <= 0 || isCreationLoading
  const iconColor = colors.length > 0 ? colors[colors.length - 1] : '#000'
  const vehicleType =
    newVehicle.selectedModel.customData?.vehicleType?.vehicle_type_name

  const measureUnitsOptions = useMemo(() => {
    return measureUnitsArray.map((unit) => {
      return {
        value: unit.measure_unit_id,
        label: unit.measure_unit_name,
        customData: unit,
      }
    })
  }, [measureUnits])

  const onChange = (e) => {
    dispatch({
      type: addReceptionActions.SET_NEW_VEHICLE,
      payload: {
        ...newVehicle,
        measureUnitId: e.target.value,
      },
    })
  }

  const updateSelectedColors = (selectedColor) => {
    dispatch({
      type: addReceptionActions.SET_NEW_VEHICLE,
      payload: {
        ...newVehicle,
        colors: [...selectedColor],
      },
    })
  }

  return (
    <>
      <div className="new-reception__summary">
        <SummaryContainer>
          <p className="summary__title">Resumen de nuevo vehículo:</p>
          <VehicleSummaryCard
            icon={
              <i
                className={getVehicleTypeIcon(vehicleType)}
                style={{
                  color: iconColor,
                  backgroundColor: !isColorVisibleOnWhite(iconColor)
                    ? getContrastingColor(iconColor)
                    : '',
                  padding: '3px 5px',
                  borderRadius: '5px',
                }}
              />
            }
          />
        </SummaryContainer>
      </div>
      <Divider style={{ margin: '5px auto' }} />
      <main className="new-reception__main">
        <div className="new-reception__main__question">
          <GradientTextComponent text="Agrega los últimos detalles *" />
        </div>
        <div className="new-reception__main__content">
          <DetailsInputs>
            <p className="units-label">Unidad *</p>
            <Radio.Group
              onChange={onChange}
              value={measureUnitId}
              className="units-input"
            >
              {measureUnitsOptions.map((unit) => (
                <Radio key={unit.value} value={unit.value}>
                  {unit.label}
                </Radio>
              ))}
            </Radio.Group>
            <p className="colors-label">Colores *</p>
            <div className="colors-input">
              <InfoDisclaimer message="Puedes seleccionar más de un color y dar click sobre ellos para eliminarlos" />
              <MultiColorPicker
                selectedColors={colors}
                setSelectedColors={updateSelectedColors}
              />
            </div>
          </DetailsInputs>
        </div>
      </main>
      <div className="new-reception__navigation-buttons">
        <PreviousNextButton isPrevious onClickHandler={handlePrevious} />
        <Button
          bsStyle="success"
          onClick={addVehicle}
          disabled={isCreateButtonDisabled}
        >
          Crear vehículo
        </Button>
      </div>
    </>
  )
}

VehicleDetailsForm.propTypes = {
  isCreationLoading: PropTypes.bool.isRequired,
  measureUnits: PropTypes.object.isRequired,
  handlePrevious: PropTypes.func.isRequired,
  addVehicle: PropTypes.func.isRequired,
}

const mapStateToProps = (store) => ({
  measureUnits: store.MeasureUnitReducer.measureUnits,
})

export default connect(mapStateToProps)(VehicleDetailsForm)
