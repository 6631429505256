import _ from 'lodash'

import {
  GET_VEHICLE_TYPES,
  ADD_VEHICLE_TYPE,
  UPDATE_VEHICLE_TYPE,
  DELETE_VEHICLE_TYPE,
} from '../actionTypes/VehicleTypesTypes'

const VehicleTypeReducer = (
  state = {
    vehicleTypes: {},
  },
  action
) => {
  if (action.type === GET_VEHICLE_TYPES) {
    return {
      ...state,
      vehicleTypes: action.payload,
    }
  }
  if (action.type === ADD_VEHICLE_TYPE || action.type === UPDATE_VEHICLE_TYPE) {
    return {
      ...state,
      vehicleTypes: {
        ...state.vehicleTypes,
        [action.payload.vehicle_type_id]: action.payload,
      },
    }
  }
  if (action.type === DELETE_VEHICLE_TYPE) {
    const newState = _.cloneDeep(state)
    delete newState.vehicleTypes[action.payload.vehicle_type_id]
    return newState
  }

  return state
}

export default VehicleTypeReducer
