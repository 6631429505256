import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Input, Form, Alert } from 'antd'
import PropTypes from 'prop-types'

import PreviousNextButton from '../../../../../buttons/PreviousNextButton/PreviousNextButton'
import { useAddReceptionContext } from '../../../../../../contexts/AddReceptionContext'
import GradientTextComponent from '../../../../Common/GradientText.js/GradientText'
import { addReceptionActions } from '../../../../../../reducers/AddReceptionReducer'
import { vehiclePlateRegex } from '../../../../../Utilities/validations'
import { checkVehiclePlate } from '../../../../../../api/apiService'
import { NotificationHandler } from '../../../../../../Helpers'
import { PlateForm } from './VehiclePlateForm.style'

const VehiclePlateForm = ({ handleNext, navigateToSelectVehicle }) => {
  const { state, dispatch } = useAddReceptionContext()
  const { newVehicle, vehicleSearchPlate } = state

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    setValue,
  } = useForm({ mode: 'onChange' })

  const [isPlateDuplicated, setPlateDuplicated] = useState(false)

  const licensePlateDefault =
    vehicleSearchPlate || newVehicle.vehicleLicensePlate
  const nextButtonDisabled =
    !(isValid || licensePlateDefault) || isPlateDuplicated

  const checkLicencePlateValid = async (licensePlate) => {
    try {
      const response = await checkVehiclePlate(licensePlate)
      return response.data.isValid
    } catch (e) {
      NotificationHandler('Error al validar la placa', 'error')
      return false
    }
  }

  const onChange = (name) => (value) => {
    if (vehicleSearchPlate) {
      dispatch({
        type: addReceptionActions.SET_VEHICLE_SEARCH_PLATE,
        payload: '',
      })
    }
    setValue(name, value)
    setPlateDuplicated(false)
  }

  const onSubmit = async (data) => {
    const plateValid = await checkLicencePlateValid(data?.vehicleLicensePlate)

    if (data?.vehicleLicensePlate) {
      if (!plateValid) {
        setPlateDuplicated(true)
      } else {
        setPlateDuplicated(false)
        dispatch({
          type: addReceptionActions.SET_NEW_VEHICLE,
          payload: {
            ...newVehicle,
            vehicleLicensePlate: data.vehicleLicensePlate.trim(),
          },
        })

        handleNext()
      }
    }
  }

  const handleExternalSubmit = () => {
    handleSubmit(onSubmit)()
  }

  useEffect(() => {
    setValue('vehicleLicensePlate', licensePlateDefault)
  }, [newVehicle])

  return (
    <>
      <main className="new-reception__main">
        <div className="new-reception__main__question">
          <GradientTextComponent text="¿Cuál es la placa del nuevo vehículo? *" />
        </div>
        <PlateForm
          onSubmit={handleSubmit(onSubmit)}
          className="new-reception__main__content"
        >
          <Controller
            name="vehicleLicensePlate"
            control={control}
            rules={{
              required: 'La placa del vehículo es requerida',
              maxLength: {
                value: 20,
                message: 'No puede tener más de 20 caracteres',
              },
              pattern: {
                value: vehiclePlateRegex,
                message: 'Solo se permiten letras y números sin espacios',
              },
            }}
            render={({ field }) => (
              <Form.Item
                validateStatus={errors?.vehicleLicensePlate ? 'error' : ''}
                style={{ padding: '3px' }}
                help={errors?.vehicleLicensePlate?.message}
              >
                <Input
                  name={field.name}
                  autoFocus
                  onBlur={field.onBlur}
                  onChange={(e) => {
                    field.onChange(e)
                    onChange(field.name)(e.target.value.toUpperCase())
                  }}
                  value={field.value}
                  defaultValue={licensePlateDefault}
                  placeholder="Ej. P456324"
                  size="large"
                  style={{ textAlign: 'center' }}
                />
              </Form.Item>
            )}
          />
          {isPlateDuplicated && (
            <Alert
              message="Ya existe un vehículo con esta placa"
              type="warning"
              showIcon
            />
          )}
        </PlateForm>
      </main>
      <div className="new-reception__navigation-buttons">
        <PreviousNextButton
          prevText="Salir"
          isPrevious
          onClickHandler={navigateToSelectVehicle}
        />
        <PreviousNextButton
          isPrevious={false}
          isDisabled={nextButtonDisabled}
          onClickHandler={handleExternalSubmit}
        >
          Siguiente
        </PreviousNextButton>
      </div>
    </>
  )
}

export default VehiclePlateForm

VehiclePlateForm.propTypes = {
  handleNext: PropTypes.func.isRequired,
  navigateToSelectVehicle: PropTypes.func.isRequired,
}
