import React from 'react'
import Select from 'react-select'
import { FormGroup, ControlLabel, Col, Row, Button } from 'react-bootstrap'
import PropTypes from 'prop-types'

const SyncClientFormStep = (props) => {
  const {
    selectedPersonToSync,
    handleChangeSyncSelect,
    handleSearchPersons,
    handleSyncPerson,
  } = props
  return (
    <div>
      <Row>
        <Col xs={12}>
          <FormGroup>
            <ControlLabel>Buscar: </ControlLabel>
            {/* https://github.com/JedWatson/react-select/blob/v1.x/examples/src/components/GithubUsers.js */}
            {/* https://github.com/JedWatson/react-select/tree/v1.x */}
            {/* the labelKey is the attribute that is searched in the api results */}
            <Select.Async
              name="person_firstname"
              value={
                Object.keys(selectedPersonToSync).length < 1
                  ? null
                  : selectedPersonToSync
              }
              onChange={handleChangeSyncSelect}
              required
              placeholder="Nombres, correo, documento"
              loadOptions={handleSearchPersons}
              labelKey="full"
              valueKey="id"
              noResultsText="Sin resultados"
              searchPromptText="Buscar..."
              loadingPlaceholder="Buscando..."
            />
          </FormGroup>
        </Col>
      </Row>
      {Object.keys(selectedPersonToSync).length > 0 && (
        <Row className="person-to-sync-container">
          <Col xs={12}>
            <h4 className="title">Cliente a sincronizar</h4>
          </Col>
          <Col xs={12}>
            <p className="sync-label">Nombre:</p>
            <p>
              -{selectedPersonToSync.person_firstname}{' '}
              {selectedPersonToSync.person_lastname}
            </p>
          </Col>
          <Col xs={12}>
            <p className="sync-label">Correo electrónico:</p>
            <p>-{selectedPersonToSync.person_email}</p>
          </Col>
          <Col xs={12}>
            <p className="sync-label">Documento:</p>
            <p>-{selectedPersonToSync.person_document_number}</p>
          </Col>
          <Col xs={12}>
            <Button
              bsStyle="success"
              style={{ float: 'right' }}
              onClick={handleSyncPerson}
            >
              <i className="fas fa-check" />
              Sincronizar
            </Button>
          </Col>
        </Row>
      )}
    </div>
  )
}

/**
    selectedPersonToSync,
    handleChangeSyncSelect,
    handleSearchPersons,
    handleSyncPerson,

* 
 */

SyncClientFormStep.propTypes = {
  selectedPersonToSync: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChangeSyncSelect: PropTypes.func.isRequired,
  handleSearchPersons: PropTypes.func.isRequired,
  handleSyncPerson: PropTypes.func.isRequired,
}

export default SyncClientFormStep
