import axios from 'axios'
import _ from 'lodash'

import {
  GET_CATEGORIES,
  ADD_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
} from '../actionTypes/CategoryTypes'
import { NotificationHandler } from '../Helpers'

const getCategoryAction = (payload) => ({
  type: GET_CATEGORIES,
  payload,
})

const addCategoryAction = (payload) => ({
  type: ADD_CATEGORY,
  payload,
})

const updateCategoryAction = (payload) => ({
  type: UPDATE_CATEGORY,
  payload,
})

const deleteCategoryAction = (payload) => ({
  type: DELETE_CATEGORY,
  payload,
})

export const getCategories = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const categoriesQuantity = _.size(getState().CategoryReducer.categories)
    if (categoriesQuantity === 0) {
      axios
        .get('/api/category')
        .then((response) => {
          if (!response.data.error) {
            const categories = _.keyBy(response.data.data, 'category_id')
            dispatch(getCategoryAction(categories))
            resolve(response.data)
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al obtener las categorías',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al obtener las categorías',
            error
          )
        })
    } else {
      resolve({})
    }
  })

export const addCategory = (category) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { workshop_id } = getState().AppReducer.currentUser
    axios
      .post('/api/category', { ...category, workshop_id })
      .then((response) => {
        if (!response.data.error) {
          dispatch(addCategoryAction(response.data.data))
          resolve(response)
          NotificationHandler('Done', 'info', 'Categoría agregada')
        } else {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al agregar la categoría',
            response.data.data
          )
        }
      })
      .catch((error) => {
        reject()
        NotificationHandler(
          'Error',
          'error',
          'Error al agregar la categoría',
          error
        )
      })
  })

export const updateCategory = (category) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (category.category_id) {
      axios
        .put(`/api/category/${category.category_id}`, category)
        .then((response) => {
          if (!response.data.error) {
            dispatch(updateCategoryAction(response.data.data))
            resolve(response)
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al actualizar la categoría',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al actualizar la categoría',
            error
          )
        })
    } else {
      reject()
      NotificationHandler(
        'Error',
        'error',
        `No se proporcionó id a: ${category.category_name}`
      )
    }
  })

export const deleteCategory = (category) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (category.category_id) {
      axios
        .delete(`/api/category/${category.category_id}`)
        .then((response) => {
          if (!response.data.error) {
            dispatch(deleteCategoryAction(category))
            resolve(response)
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al eliminar la categoría',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al eliminar la categoría',
            error
          )
        })
    } else {
      reject()
      NotificationHandler(
        'Error',
        'error',
        `No se proporcionó id a: ${category.category_name}`
      )
    }
  })
