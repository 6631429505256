import React, { useState } from 'react'
import { Select, Spin } from 'antd'
import PropTypes from 'prop-types'

import SelectFooterButton from '../../App/Common/SelectFooterButton/SelectFooterButton'

const ImageSelect = ({
  id,
  items, // Array of {value: '', label: '', customData} objects for select
  value,
  isLoading,
  imgPropName,
  isAutoFocus,
  addItemText,
  label,
  defaultValue,
  placeholder,
  disabled,
  handleValueChange,
  handleOnAdd,
  required,
}) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false)

  const filterOption = (input, option) => {
    return option?.labelName?.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  const handleDropdownVisibleChange = (open) => {
    setDropdownOpen(open)
  }

  const handleAddElement = () => {
    setDropdownOpen(false)
    handleOnAdd()
  }

  const renderOption = (option) => {
    return (
      <div
        style={{
          display: 'flex',
          gap: '8px',
          alignItems: 'center',
          minHeight: '33px',
        }}
      >
        {option.customData[imgPropName] && (
          <img
            src={option.customData[imgPropName]}
            alt={option.label}
            loading="lazy"
            style={{ width: '30px', objectFit: 'contain' }}
          />
        )}
        <span>{option.label}</span>
      </div>
    )
  }

  return (
    <div className="custom-select">
      {label && (
        <label htmlFor={id} className="custom-select__label">
          {label}
          {required && !value.value ? '*' : ''}
        </label>
      )}
      <Select
        id={id}
        size="large"
        value={value}
        disabled={disabled}
        defaultValue={defaultValue}
        onDropdownVisibleChange={handleDropdownVisibleChange}
        open={isDropdownOpen}
        placeholder={placeholder}
        style={{ width: '100%', fontSize: '14px' }}
        onChange={handleValueChange}
        defaultActiveFirstOption={false}
        showSearch
        filterOption={filterOption}
        placement="bottomLeft"
        notFoundContent={isLoading ? <Spin size="small" /> : <></>}
        labelInValue
        autoFocus={isAutoFocus}
        listHeight="20vh"
        dropdownRender={(menu) => (
          <>
            {menu}
            {addItemText && (
              <SelectFooterButton
                buttonText={addItemText}
                onClickHandler={handleAddElement}
              />
            )}
          </>
        )}
      >
        {items.map((item) => (
          <Select.Option
            key={item.value}
            value={item.value}
            labelName={item.label}
            customData={item?.customData}
          >
            {renderOption(item)}
          </Select.Option>
        ))}
      </Select>
    </div>
  )
}

export default ImageSelect

ImageSelect.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  imgPropName: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([undefined])]),
  isLoading: PropTypes.bool,
  addItemText: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.object,
  isAutoFocus: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  handleValueChange: PropTypes.func.isRequired,
  handleOnAdd: PropTypes.func,
  required: PropTypes.bool,
}

ImageSelect.defaultProps = {
  addItemText: '',
  isLoading: false,
  placeholder: 'Selecciona una opción',
  label: '',
  defaultValue: undefined,
  isAutoFocus: false,
  disabled: false,
  handleOnAdd: () => true,
  value: { value: '', label: '' },
  required: false,
}
