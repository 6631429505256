import moment from 'moment'
import _ from 'lodash'
import JsPDF from 'jspdf'
import writtenNumbers from 'written-number'
import { numberFormatter } from '../../../../Helpers'

writtenNumbers.defaults.lang = 'es'

export const COUNTRY_TAX = 0.13

export const getTotal = (details = [], pack = {}) => {
  let sums = 0
  let taxes = 0
  sums += pack.originalPriceWithDiscountLessTaxTotal || 0
  taxes += pack.taxTotalOriginalPriceWithDiscount || 0
  details.forEach(
    ({ totalAffectSale, taxTotalOriginalPriceWithDiscount, quantity }) => {
      sums += totalAffectSale || 0
      taxes += taxTotalOriginalPriceWithDiscount * quantity
    }
  )
  return { sums, taxes }
}

const toMoneyFix = (n = 0) => Math.round(n * 100) / 100

export const applyTax = ({
  pack = {},
  details = [],
  countryTax = 0,
  retention = 0,
  work_order_package_discount = 0,
}) => {
  let footerDetails = {}

  let newPackage = {}
  if (!_.isEmpty(pack)) {
    const quantity = 1
    const originalPrice = toMoneyFix(pack.package_price)
    const discountPercentage = (work_order_package_discount || 0) / 100
    const totalDiscount = toMoneyFix(originalPrice * discountPercentage)
    const originalPriceWithDiscount = toMoneyFix(originalPrice - totalDiscount)

    const originalPriceWithDiscountLessTaxTotal = toMoneyFix(
      originalPriceWithDiscount / (countryTax + 1) // 0.13 + 1 = 1.13
    )

    const taxTotalOriginalPriceWithDiscount = toMoneyFix(
      originalPriceWithDiscount - originalPriceWithDiscountLessTaxTotal
    )

    const totalAffectSale = toMoneyFix(
      originalPriceWithDiscountLessTaxTotal * quantity
    )

    newPackage = {
      quantity: 1,
      description: pack.package_name,
      originalPrice,
      discountPercentage,
      totalDiscount,
      originalPriceWithDiscount,
      taxTotalOriginalPriceWithDiscount,
      originalPriceWithDiscountLessTaxTotal,
      totalAffectSale,
    }
  }

  const newDetails = []
  if (details.length > 0) {

    let index = 0
    details.forEach((detail) => {
      const {
        process,
        product,
        work_order_detail_item_quantity = 1,
        work_order_detail_discount = 0,
        work_order_detail_item_price = 0,
        work_order_detail_discount_workforce = 0,
        work_order_detail_workforce_price = 0,
      } = detail

      if (product) {
        const quantity = work_order_detail_item_quantity || 1
        const originalPrice = toMoneyFix(work_order_detail_item_price)
        const discountPercentage =
          (toMoneyFix(work_order_detail_discount) || 0) / 100
        const totalDiscount = toMoneyFix(originalPrice * discountPercentage)
        const originalPriceWithDiscount = toMoneyFix(
          originalPrice - totalDiscount
        )

        const originalPriceWithDiscountLessTaxTotal = toMoneyFix(
          originalPriceWithDiscount / (countryTax + 1) // 0.13 + 1 = 1.13
        )
        // console.log("product tax", originalPriceWithDiscountLessTaxTotal);
        const taxTotalOriginalPriceWithDiscount = toMoneyFix(
          originalPriceWithDiscount - originalPriceWithDiscountLessTaxTotal
        )

        const totalAffectSale = toMoneyFix(
          originalPriceWithDiscountLessTaxTotal * quantity
        )

        const newDetail = {
          product_id: product.product_id,
          quantity,
          description: product.product_name,
          originalPrice,
          discountPercentage,
          totalDiscount,
          originalPriceWithDiscount,
          taxTotalOriginalPriceWithDiscount,
          originalPriceWithDiscountLessTaxTotal,
          totalAffectSale,
        }
        newDetails[index] = newDetail
        index += 1
      }

      if (process) {
        const quantity = 1
        const originalPrice = toMoneyFix(work_order_detail_workforce_price)
        const discountPercentage =
          (toMoneyFix(work_order_detail_discount_workforce) || 0) / 100
        const totalDiscount = toMoneyFix(originalPrice * discountPercentage)
        const originalPriceWithDiscount = toMoneyFix(
          originalPrice - totalDiscount
        )

        const originalPriceWithDiscountLessTaxTotal = toMoneyFix(
          originalPriceWithDiscount / (countryTax + 1) // 0.13 + 1 = 1.13
        )
        // console.log("process tax", originalPriceWithDiscountLessTaxTotal);

        const taxTotalOriginalPriceWithDiscount = toMoneyFix(
          originalPriceWithDiscount - originalPriceWithDiscountLessTaxTotal
        )

        const totalAffectSale = toMoneyFix(
          originalPriceWithDiscountLessTaxTotal * quantity
        )
        const newDetail = {
          process_id: process.process_id,
          quantity,
          description: process.process_name,
          originalPrice,
          discountPercentage,
          totalDiscount,
          originalPriceWithDiscount,
          taxTotalOriginalPriceWithDiscount,
          originalPriceWithDiscountLessTaxTotal,
          totalAffectSale,
        }
        newDetails[index] = newDetail
        index += 1
      }
    })
  }
  const { sums, taxes } = getTotal(newDetails, newPackage, countryTax)
  footerDetails = {
    sums,
    subTotal: sums,
    totalTax: taxes,
    total: sums + taxes,
  }
  footerDetails.retention = retention !== 0 ? sums * (retention / 100) : 0
  footerDetails.total = parseFloat(
    footerDetails.subTotal + footerDetails.totalTax
  )

  const bodyFields = {
    pack: newPackage,
    details: newDetails,
    countryTax,
  }
  return {
    bodyFields,
    footerDetails,
  }
}

// eslint-disable-next-line consistent-return
export const updateInvoice = (workOrderToInvoice, tax) => {
  let countryTax = tax

  const { person } = workOrderToInvoice

  if (person.contributor_type_id) {
    const {
      contributors: { contributor_type_retention_percentage },
    } = person

    let contributorTaxPercentage = 0
    if (contributor_type_retention_percentage >= 1) {
      contributorTaxPercentage = toMoneyFix(
        contributor_type_retention_percentage / 100
      )
    } else if (contributorTaxPercentage < 1) {
      contributorTaxPercentage = contributor_type_retention_percentage
    }

    countryTax = contributorTaxPercentage
  } else {
    countryTax = COUNTRY_TAX
  }

  if (!_.isEmpty(workOrderToInvoice)) {
    const {
      person: payorTmp,
      details,
      work_order_package_discount,
    } = workOrderToInvoice

    const pack = workOrderToInvoice.package
    let payor = payorTmp
    if (Object.keys(payorTmp).length === 0) {
      payor = workOrderToInvoice.client
    }

    let headerFields = {}
    let bodyFields = {}
    let footerDetails = {}

    let retention = 0
    if (payor) {
      const {
        contributors,
        person_address,
        person_firstname,
        person_lastname,
        person_nit,
        person_bussines_activity,
        person_nrc,
        person_type,
        conditions,
      } = payor

      retention = contributors
        ? contributors.contributor_type_retention_percentage
        : 0
      const isBusiness = person_type === 1

      const date = moment().format('DD-MM-YYYY')
      headerFields = {
        person_name: `${person_firstname} ${person_lastname}`,
        person_address: person_address || '',
        person_nit: person_nit || '',
        contributor_name:
          isBusiness && contributors ? contributors.contributor_type_name : '',
        date,
        person_business_activity: person_bussines_activity || '',
        person_nrc: person_nrc || '',
        condition_name: conditions ? conditions.payment_condition_name : '',
        isBusiness,
      }
    } else {
      const date = moment().format('DD-MM-YYYY')
      headerFields = {
        person_name: '',
        person_address: '',
        person_nit: '',
        state_name: '',
        contributor_name: '',
        date,
        person_business_activity: '',
        person_nrc: '',
        condition_name: '',
        isBusiness: false,
      }
    }

    ;({ bodyFields, footerDetails } = applyTax({
      pack,
      details,
      countryTax,
      retention,
      work_order_package_discount,
    }))

    return {
      headerFields,
      bodyFields,
      footerDetails,
      countryTax,
    }
  }
}

// 'cf' Credito Fiscal || 'f' Factura || 'e' Exportacion
export const printInvoicePDF = (headFields, bodyFields) => {
  const doc = new JsPDF({ orientation: 'p', unit: 'pt', format: 'a4' })
  const { details, pack } = bodyFields
  const { person_name, person_address, person_nit, person_dui } = headFields
  doc.setFontSize(9)
  doc.text(85, 125, person_name)
  doc.text(87, 140, person_address)

  if (person_nit) {
    doc.text(420, 150, person_nit)
  } else if (person_dui) {
    doc.text(person_dui)
  } else {
    doc.text(420, 150, '')
  }

  doc.text(415, 125, moment().format('DD-MM-YYYY'))
  if (!_.isEmpty(pack)) {
    doc.setFontSize(8)
    doc.text(125, 205, pack.description)
    doc.text(342, 205, `${pack.quantity}`)
    doc.text(
      380,
      205,
      numberFormatter.format(pack.originalPriceWithDiscountLessTaxTotal),
      'right'
    )
    doc.text(493, 205, numberFormatter.format(pack.totalAffectSale), 'right')
  }
  let contadorPT = 215
  _.forEach(details, (detail) => {
    doc.setFontSize(8)
    doc.text(342, contadorPT, `${detail.quantity}`)
    doc.text(125, contadorPT, detail.description)
    doc.text(
      380,
      contadorPT,
      numberFormatter.format(detail.originalPriceWithDiscountLessTaxTotal),
      'right'
    )
    doc.text(
      493,
      contadorPT,
      numberFormatter.format(detail.totalAffectSale),
      'right'
    )
    contadorPT += 10
  })

  doc.setFontSize(7)

  const { sums, taxes } = getTotal(details, pack)
  const total = sums + taxes
  const dollars = Math.floor(parseFloat(total, 10))
  const cents = Math.round((total - dollars) * 100)

  doc.text(
    70,
    355,
    `${writtenNumbers(
      dollars
    ).toUpperCase()} DÓLARES CON ${cents}/100 CENTAVOS.`
  )

  doc.setFontSize(8)

  doc.text(493, 355, numberFormatter.format(sums), { align: 'right' })
  doc.text(493, 365, numberFormatter.format(taxes), { align: 'right' })
  doc.text(493, 375, numberFormatter.format(total), { align: 'right' })
  doc.text(493, 408, numberFormatter.format(total), 'right')

  return doc.output('datauristring')
}
