import React, { Component } from 'react'
import { connect } from 'react-redux'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory from 'react-bootstrap-table2-filter'
import { Panel, Grid, Row, Col, Button } from 'react-bootstrap'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { MenuOutlined, PlusCircleFilled } from '@ant-design/icons';
import {
  Container,
  ContainerHeader,
  BodyContent,
  showConfirmDialog,
  PanelHeader,
} from '../../Common/index'
import ProviderModal from './ProviderModal'
import getColumns from './columns'
import {
  addProvider,
  getProviders,
  updateProvider,
  deleteProvider,
} from '../../../../actions/ProviderAction'
import { AppLoader } from '../../Common/AppLoader'

const SCREEN_ID = 32

class ProvidersContainer extends Component {
  state = {
    showModal: false,
    loading: true,
    selectedProvider: {},
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions)
    const { getAllProviders } = this.props
    getAllProviders()
      .then(() => {
        this.setState({ loading: false })
      })
      .catch(() => {
        this.setState({ loading: false })
      })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  updateDimensions = () => {
    const { width } = window.screen
    this.setState({ width })
  }

  toggleModal = () => {
    const { showModal } = this.state
    this.setState({ showModal: !showModal })
  }

  addProvider = () => {
    this.setState({ selectedProvider: {} })
    this.toggleModal()
  }

  updateProvider = (provider) => {
    this.setState({ selectedProvider: provider })
    this.toggleModal()
  }

  deleteProvider = (provider) => {
    const { removeProvider } = this.props
    showConfirmDialog(
      '¿Eliminar?',
      `¿Desea eliminar el proveedor ${provider.provider_name}?`,
      removeProvider,
      provider
    )
  }

  render() {
    const { showModal, selectedProvider, loading, width } = this.state
    const { permissions, providers, createProvider, modifyProvider, workshopCountryCode } =
      this.props
    const { can_write, can_edit, can_delete } = permissions[SCREEN_ID]
    const pagePermissions = {
      can_write,
      can_edit,
      can_delete,
    }
    return (
      <Container>
        <ContainerHeader title="Proveedores" />
        <BodyContent>
          <Grid fluid>
            <Row>
              <Col sm={12}>
                <Panel>
                  <Panel.Heading bsClass="panel-heading">
                    <Panel.Title>
                      <PanelHeader>
                        <div>
                          <p id="title">
                            <MenuOutlined style={{ marginRight: '7px' }} /> Administrar
                            Proveedores
                          </p>
                        </div>
                        <div>
                          {!!can_write && (
                            <Button
                              onClick={this.addProvider}
                              bsStyle="success"
                              className="btn-add btn-antd-icon"
                            >
                              <PlusCircleFilled />
                              Agregar registro
                            </Button>
                          )}
                        </div>
                      </PanelHeader>
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    {loading ? (
                      <AppLoader />
                    ) : (
                      <Row>
                        <Col md={10} />
                        <Col md={12}>
                          <BootstrapTable
                            noDataIndication="Sin datos para mostrar"
                            keyField="provider_id"
                            data={_.values(providers)}
                            columns={getColumns(
                              this.updateProvider,
                              this.deleteProvider,
                              {
                                can_edit,
                                can_delete,
                              },
                              width
                            )}
                            filter={filterFactory()}
                            pagination={paginationFactory()}
                          />
                        </Col>
                      </Row>
                    )}
                  </Panel.Body>
                </Panel>
              </Col>
            </Row>
          </Grid>
          <ProviderModal
            modalOpen={showModal}
            toggleModal={this.toggleModal}
            editMode={!_.isEmpty(selectedProvider)}
            addProvider={createProvider}
            updateProvider={modifyProvider}
            selectedProvider={selectedProvider}
            pagePermissions={pagePermissions}
            workshopCountryCode={workshopCountryCode}
          />
        </BodyContent>
      </Container>
    )
  }
}

const mapStateToProps = (store) => ({
  providers: store.ProviderReducer.providers,
  permissions: store.AppReducer.permissions,
  workshopCountryCode: store.AppReducer.workshopCountryCode
})

const mapDispatchToProps = (dispatch) => ({
  getAllProviders() {
    return dispatch(getProviders())
  },
  createProvider(provider, contacts) {
    return dispatch(addProvider(provider, contacts))
  },
  modifyProvider(provider, contacts) {
    return dispatch(updateProvider(provider, contacts))
  },
  removeProvider(provider) {
    return dispatch(deleteProvider(provider))
  },
})

ProvidersContainer.propTypes = {
  permissions: PropTypes.objectOf(PropTypes.object).isRequired,
  providers: PropTypes.arrayOf(PropTypes.object).isRequired,
  getAllProviders: PropTypes.func.isRequired,
  createProvider: PropTypes.func.isRequired,
  modifyProvider: PropTypes.func.isRequired,
  removeProvider: PropTypes.func.isRequired,
  workshopCountryCode: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProvidersContainer)
