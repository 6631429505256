/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useRef, useState } from 'react'

import Upload from 'antd/es/upload/Upload'
import { Modal } from 'antd'

import MultiplePhotoInputWrapper from '../../MultiplePhotoInput/MultiplePhotoInput.styles'
import MultiplePhotoInputPropTypes from '../../MultiplePhotoInput/MultiplePhotoInput.types'
import getBase64 from '../../../Utilities/getBase64'
import { createPhotoObject } from '../../../../Helpers'
import useWindowSize from '../../../../hooks/useWindowSize'
import { AddPhotoIcon } from '../../../icons/AddPhotoIcon'

const PhotoInputCameraOpen = ({ handleAddPhoto, photos, maxPhotosAllowed }) => {
  const windowSize = useWindowSize()

  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')

  const buttonRef = useRef(null)
  const cameraInputRef = useRef(null)

  const onChange = ({ fileList: newFileList }) => {
    handleAddPhoto(newFileList)
  }

  const onChangeOpen = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0]
      const photoObject = createPhotoObject(file)

      handleAddPhoto([...photos, photoObject])
    }
  }

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok')
    }, 0)
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      // eslint-disable-next-line no-param-reassign
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage(file.url || file.preview)
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    )
    setPreviewOpen(true)
  }

  const handleCancel = () => setPreviewOpen(false)

  const openCamera = () => {
    // This click enables iOS to detect the following input click
    buttonRef.current.click()

    // The camera opens automatically only on mobile and tablets
    if (windowSize.width < 992 && cameraInputRef.current) {
      cameraInputRef.current.click()
    }
  }

  useEffect(() => {
    const timerId = setTimeout(() => {
      openCamera()
    }, 200)

    return () => {
      clearTimeout(timerId)
    }
  }, [])

  return (
    <MultiplePhotoInputWrapper>
      <p className="title">
        <span>Fotografías</span>
        {photos.length > 0 && ` (${photos.length})`}
      </p>
      <Upload
        accept="image/*"
        name="images"
        listType="picture-card"
        className="content"
        fileList={photos}
        onChange={onChange}
        customRequest={dummyRequest}
        onPreview={handlePreview}
      >
        {photos.length + 1 <= maxPhotosAllowed && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <div className="upload">
            <AddPhotoIcon />
          </div>
        )}
      </Upload>
      <Modal
        open={previewOpen}
        footer={null}
        onCancel={handleCancel}
        zIndex={1100}
        title={previewTitle}
        centered
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '24px',
          }}
        >
          <img
            alt="example"
            style={{ width: '90%', height: '90%', objectFit: 'contain' }}
            src={previewImage}
          />
        </div>
      </Modal>
      <div>
        <input
          type="file"
          accept="image/*"
          capture="environment"
          id="cameraInput"
          ref={cameraInputRef}
          onChange={onChangeOpen}
          style={{ cursor: 'pointer', display: 'none' }}
        />
        <button type="button" ref={buttonRef} style={{ display: 'none' }}>
          Dummy button
        </button>
      </div>
    </MultiplePhotoInputWrapper>
  )
}

PhotoInputCameraOpen.propTypes = MultiplePhotoInputPropTypes

PhotoInputCameraOpen.defaultProps = {
  maxPhotosAllowed: 5,
}

export default PhotoInputCameraOpen
