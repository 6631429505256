import React, { Component } from 'react'
import { Modal, Button, Table } from 'react-bootstrap'
import _ from 'lodash'
import PropTypes from 'prop-types'
import {
  CarFilled,
  StarFilled,
  ToolFilled,
  UserOutlined,
} from '@ant-design/icons'
import { connect } from 'react-redux'
import axios from 'axios'
import { addComment } from '../../../actions/WorkOrderAction'
import HistoryContainer from './historyModal.styles'
import WorkOrderPhotos from '../MechanicWorkOrder/photos/WorkOrderPhotos'
import Chat from '../MechanicWorkOrder/chat/Chat'
import VideoGallery from '../Common/VideosGallery/VideosGallery'

import { ClientsURL } from '../../../Constants'
import InfoCard from '../Common/InfoCard/InfoCard'
import ReviewCard from '../Common/ReviewCard/ReviewCard'

class HistoryModal extends Component {
  state = {
    work_order_grade: '',
    work_order_id: null,
    work_order_grade_comment: '',
    work_order_finished_date: '',
    work_order_package_discount: 0,
    // photos:[],
    budgets: {
      budget_date: '',
      budget_package_price: '',
      budget_total: '',
      budget_mandatory_total: '',
      reception: {
        reception_delivery_date: '',
        payor: {
          person_firstname: '',
          person_lastname: '',
          person_document_number: '',
        },
        vehicle: {
          vehicle_license_plate: '',
          vehicle_year: '',
          model: {
            model_name: '',
            brand: {
              brand_name: '',
            },
          },
          owner: [
            {
              person_firstname: '',
              person_lastname: '',
              person_document_number: '',
            },
          ],
        },
      },
    },
    mechanic: {
      employee_name: '',
      employee_lastname: '',
    },
    packages: {
      package_name: '',
      package_price: '',
      process: [
        {
          process_name: '',
          process_price: '',
          process_estimated_time: '',
        },
      ],
    },
    details: [
      {
        work_order_detail_item_price: '',
        work_order_detail_workforce_price: '',
        process: {
          process_name: '',
        },
        product: {
          product_name: '',
        },
      },
    ],
    comment: null,
    invoice: null,
    loading: false,
    photos: [],
    videos: [],
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { selectedHistory } = props

    if (!_.isEmpty(selectedHistory)) {
      this.setState({
        work_order_id: selectedHistory.work_order_id,
        work_order_grade: selectedHistory.work_order_grade,
        work_order_grade_comment: selectedHistory.work_order_grade_comment,
        work_order_finished_date: selectedHistory.work_order_finished_date,
        work_order_package_discount:
          selectedHistory.work_order_package_discount,
        budgets: selectedHistory.budgets,
        mechanic: selectedHistory.mechanic,
        details: selectedHistory.details,
        invoice: selectedHistory.invoice,
        // photos: selectedHistory.photos,
      })

      if (selectedHistory.package_id) {
        this.setState({
          packages: selectedHistory.package,
        })
      } else {
        this.setState({
          packages: {
            packageProcess: [],
          },
        })
      }
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { work_order_id } = this.state
    if (work_order_id !== prevState.work_order_id) {
      await this.fetchPhotos()
    }
  }

  onChangeComment = (e) => {
    this.setState({ comment: e.target.value })
  }

  addCommentOnEnter = (e) => {
    if (e.keyCode === 13) {
      this.addComment()
    }
  }

  fetchPhotos = async () => {
    try {
      const { work_order_id } = this.state
      if (!work_order_id) return

      this.setState({ loading: true })

      const response = await axios.get(
        `${ClientsURL}/api/client/work-order-detail/${work_order_id}`
      )

      if (response.status === 200) {
        const { data } = response

        // Filter photos and videos based on the `type` property
        const photos = data.receptionPhotos.filter(
          (photo) => photo.type === 'image'
        )
        const videos = data.receptionPhotos.filter(
          (photo) => photo.type === 'video'
        )

        this.setState({ photos, videos })
      } else {
        console.log('Failed to fetch photos')
      }
    } catch (error) {
      console.log('Error fetching photos:', error)
    } finally {
      this.setState({ loading: false })
    }
  }

  addComment = () => {
    const { addCommentFn, currentUser, selectedHistory } = this.props
    const { comment } = this.state
    this.setState({ comment: '' })
    const principalProcess = 'Desde historial'
    const commentJson = {
      userId: currentUser.employee_id,
      workOrderId: selectedHistory.work_order_id,
      comment,
      principalProcess,
      associatedProducts: '',
    }
    addCommentFn(commentJson)
  }

  // ----------------------------------------------------------------------------------------------

  render() {
    const { modalOpen, toggleModal } = this.props

    const {
      work_order_grade,
      work_order_grade_comment,
      work_order_finished_date,
      work_order_package_discount,
      budgets,
      mechanic,
      packages,
      details,
      work_order_id,
      budgets: {
        reception: { /* reception_description , */ reception_millage },
      },
      invoice,
      loading,
      photos,
      videos,
    } = this.state

    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }

    const getDate = (date) => {
      const readableDate = new Date(date)
      return readableDate.toLocaleString('es-ES', options)
    }

    return (
      <Modal
        show={modalOpen}
        onHide={toggleModal}
        style={{ zIndex: 900 }}
        backdropStyle={{ zIndex: 850 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Detalles de la orden</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <HistoryContainer>
            <div className="history__section">
              <div className="history__section__header">
                <div className="history__section__header__title">
                  <h3>Orden #{work_order_id}</h3>
                  <p>{getDate(work_order_finished_date)}</p>
                </div>
                <div className="history__section__header__rating">
                  <h4>
                    <span>Total: </span>$
                    {invoice?.invoice_total.toFixed(2) || 0.0}
                  </h4>
                  <div className="rating">
                    <StarFilled />
                    <p>{parseFloat(work_order_grade) || 'Sin calificación'}</p>
                  </div>
                </div>
              </div>
              <div className="history__section__info">
                <div className="history__section__info__wrapper">
                  <UserOutlined />
                  <div className="info">
                    <h4>Cliente</h4>
                    <p className="name">
                      {' '}
                      {budgets.reception.payor.person_firstname}{' '}
                      {budgets.reception.payor.person_lastname}
                    </p>
                    <p className="number">
                      {budgets.reception.payor.person_phone}
                    </p>
                  </div>
                </div>
                <div className="history__section__info__wrapper">
                  <ToolFilled />
                  <div className="info">
                    <h4>Mecanico</h4>
                    <p className="name">
                      {mechanic.employee_name} {mechanic.employee_lastname}
                    </p>
                  </div>
                </div>
              </div>
              <div className="history__section__vehicle">
                <CarFilled />
                <div className="info">
                  <h4>Vehiculo</h4>
                  <div className="specs">
                    <p>
                      <span>Placa: </span>
                      {budgets.reception.vehicle.vehicle_license_plate}
                    </p>
                    <p>
                      <span>Marca: </span>
                      {budgets.reception.vehicle.model.brand.brand_name}
                    </p>
                    <p>
                      <span>Kilometraje: </span>
                      {reception_millage > 0
                        ? `${reception_millage}km`
                        : 'No registrado'}
                    </p>
                    <p>
                      <span>Año: </span>
                      {budgets.reception.vehicle.vehicle_year}
                    </p>
                    <p>
                      <span>Modelo: </span>
                      {budgets.reception.vehicle.model.model_name}
                    </p>
                  </div>
                </div>
              </div>
              <div className="history__section__photos">
                <h4>
                  Fotografías <span className="badge">Nuevo</span>
                </h4>
                <WorkOrderPhotos photos={photos} loading={loading} />
              </div>

              <div className="history__section__photos">
                <h4>
                  Videos <span className="badge">Nuevo</span>
                </h4>
                <VideoGallery videos={videos} loading={loading} />
              </div>

              {packages.package_name && (
                <div className="history__section__table">
                  <h4>Paquete aplicado</h4>
                  <p className="package">{packages.package_name}</p>
                  <div className="prices">
                    <p className="price">
                      <span>Precio: </span>$ {packages.package_price.toFixed(2)}
                    </p>
                    {!!work_order_package_discount && (
                      <p className="price">
                        <span>Descuento: </span>%
                        {work_order_package_discount.toFixed(2)}
                      </p>
                    )}
                    <p className="price">
                      <span>Sub-Total: </span> $
                      {(
                        packages.package_price -
                        packages.package_price *
                          (work_order_package_discount / 100)
                      ).toFixed(2)}
                    </p>
                  </div>

                  {packages.packageProcess.length > 0 && (
                    <div className="table">
                      <p className="table-title">Detalles: </p>
                      <Table bordered condensed responsive>
                        <thead>
                          <tr>
                            <th>Proceso</th>
                            <th>Precio</th>
                          </tr>
                        </thead>
                        <tbody>
                          {_.map(packages.packageProcess, (detail, index) => (
                            <tr key={index}>
                              <td>{detail.process.process_name}</td>
                              <td>
                                $
                                {detail.process.process_price !== null
                                  ? detail.process.process_price.toFixed(2)
                                  : '0.00'}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  )}
                </div>
              )}
              {details.length > 0 && (
                <div className="history__section__table">
                  <h4>Repuestos y procesos adicionales</h4>
                  <div className="table">
                    <Table responsive bordered condensed>
                      <thead>
                        <tr>
                          <th>Detalle</th>
                          <th>Cantidad</th>
                          <th>Precio U.</th>
                          <th>Sub-total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {_.map(details, (detail, index) => (
                          <tr key={index}>
                            {detail.product && (
                              <td>
                                {detail.product
                                  ? detail.product.product_name
                                  : ''}
                              </td>
                            )}

                            {detail.process && (
                              <td>
                                {detail.process
                                  ? detail.process.process_name
                                  : ''}
                              </td>
                            )}

                            <td>
                              {detail.process
                                ? 1
                                : detail.work_order_detail_item_quantity}
                            </td>

                            {detail.product && (
                              <td>
                                ${detail.work_order_detail_item_price || 0}
                              </td>
                            )}

                            {detail.process && (
                              <td>
                                ${detail.work_order_detail_workforce_price || 0}
                              </td>
                            )}

                            <td>
                              $
                              {detail.product
                                ? detail.work_order_detail_item_price *
                                  detail.work_order_detail_item_quantity
                                : detail.work_order_detail_workforce_price * 1}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              )}
              <div className="history__section__observations">
                <InfoCard
                  title="Observaciones"
                  limit={125}
                  text={budgets.reception.reception_description}
                />
              </div>
              <div className="history__section__observations">
                <ReviewCard
                  limit={125}
                  score={work_order_grade}
                  text={work_order_grade_comment}
                />
              </div>

              <div className="history__section__chat">
                <h4>Historial de conversación con el cliente</h4>
                <div className="chat">
                  <Chat
                    orderId={work_order_id}
                    brand={budgets.reception.vehicle.model.brand.brand_name}
                    model={budgets.reception.vehicle.model.model_name}
                    year={budgets.reception.vehicle.vehicle_year}
                  />
                </div>
              </div>
            </div>

            {/* <div className="history__section">
              <h3 className="history__section__title">
                {' '}
                Observaciones y comentarios{' '}
              </h3>
              <div className="history__section__content">
                <div>
                  <p className="history__section__label">
                    Observaciones de recepción:
                  </p>
                  <p>{reception_description || 'Sin observaciones'}</p>
                </div>
                <div>
                  <p className="history__section__label">
                    Comentarios del Cliente:
                  </p>
                  <p>{work_order_grade_comment || 'Sin comentarios'}</p>
                </div>
              </div>
            </div>

            <div className="history__section">
              <h3 className="history__section__title">
                Comentarios en orden de trabajo
              </h3>
              <div>
                <textarea
                  className="textarea-comment"
                  value={comment}
                  onChange={this.onChangeComment}
                  onKeyDown={this.addCommentOnEnter}
                />
                <br />
                <div style={{ textAlign: 'right', width: '97%' }}>
                  <Button onClick={() => this.addComment()} bsStyle="success">
                    Agregar comentario
                  </Button>
                </div>
              </div>
            </div> */}
          </HistoryContainer>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={toggleModal}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

const mapStateToProps = (store) => ({
  currentUser: store.AppReducer.currentUser,
})

const mapDispatchToProps = (dispatch) => ({
  addCommentFn(comment) {
    return dispatch(addComment(comment))
  },
})

HistoryModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  selectedHistory: PropTypes.objectOf(PropTypes.any).isRequired,
  addCommentFn: PropTypes.func.isRequired,
  currentUser: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoryModal)
