import styled from 'styled-components'
import BREAK_POINTS from '../../../../global/css/breakPoints'

export const NewReceptionContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 92vh;

  display: flex;
  flex-direction: column;
  gap: 2rem;

  padding: 10px 16px 16px;
  padding-top: 40px;

  @media (${BREAK_POINTS.TABLET}) {
    max-width: 750px;
    padding-top: 30px;
    padding: 16px;

    margin: auto;
  }

  @media (${BREAK_POINTS.DESKTOP}) {
    max-width: 1000px;
  }

  p,
  h3 {
    margin: 0;
  }

  .new-reception {
    height: 100%;
    padding-top: 5px;

    padding-bottom: 50px;

    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 1rem;

    @media (${BREAK_POINTS.TABLET}) {
      font-size: 1.5rem;
      margin-top: 20px;
      gap: 1.5rem;
      justify-content: center;
      padding-bottom: 0;
    }

    &__title {
      display: flex;
      align-items: center;
      align-self: flex-start;

      gap: 1rem;

      h1 {
        margin: 0;
        font-size: 16px;
        font-weight: bold;

        @media (${BREAK_POINTS.TABLET}) {
          font-size: 18px;
        }
      }

      button {
        border: none;
        margin: 0;
        padding: 0;
        width: auto;
        overflow: visible;
        background: transparent;

        font-size: 16px;
        transition-duration: 0.3s;

        &:hover {
          transform: scale(1.2);
          transform: translateX(-3px);
        }

        i {
          color: black;
        }
      }
    }

    &__progress {
      width: 100%;

      display: flex;
      justify-content: center;
    }

    &__summary {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 4px 8px;

      @media (${BREAK_POINTS.TABLET_LARGE}) {
        margin: 0 auto;
      }
    }

    &__main {
      width: 100%;
      max-width: 400px;
      padding: 8px;

      display: flex;
      flex-direction: column;
      gap: 1.4rem;

      overflow-y: auto;

      @media (${BREAK_POINTS.TABLET}) {
        padding-bottom: 8px;
        gap: 3rem;
      }

      &__question {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        i {
          font-size: 7rem;
        }

        @media (${BREAK_POINTS.TABLET}) {
          i {
            font-size: 8rem;
          }
        }
      }

      @media (${BREAK_POINTS.TABLET}) {
        margin-bottom: 0;
        margin-top: 0;
      }

      @media (${BREAK_POINTS.TABLET_LARGE}) {
        width: 100%;
        max-width: 1000px;
        align-self: center;
        padding: 1rem 8px;
        gap: 2rem;

        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-auto-rows: min-content;

        margin-bottom: 0;

        &__question {
          grid-area: 1 / 1 / 2 / 3;
          display: flex;
          padding-right: 20px;

          align-self: flex-start;
          position: sticky;
          top: 0;
          z-index: 100;
        }

        &__content {
          grid-area: 1 / 3 / 2 / 6;
          height: 100%;
          display: flex;
          flex-direction: column;

          overflow-y: auto;
        }
      }

      @media (${BREAK_POINTS.TABLET}) {
        min-height: 35%;
      }

      ::-webkit-scrollbar {
        width: 10px;
      }

      ::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      ::-webkit-scrollbar-thumb {
        background: rgb(140, 35, 106);
        background: linear-gradient(
          353deg,
          rgba(140, 35, 106, 0.8) 40%,
          rgba(80, 52, 127, 0.8) 100%
        );
        border-radius: 10px;
      }

      ::-webkit-scrollbar-thumb:hover {
        background: rgb(140, 35, 106);
        background: linear-gradient(
          353deg,
          rgba(140, 35, 106) 40%,
          rgba(80, 52, 127) 100%
        );
      }
    }

    &__navigation-buttons {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 6px 8px 14px;
      padding-left: 16px;
      gap: 12px;

      background-color: #fff;

      position: fixed;
      bottom: 0;
      right: 0;

      @media (${BREAK_POINTS.TABLET}) {
        margin-bottom: 0;
        margin-top: auto;
        
        padding: 8px;
        padding-bottom: 16px;
        position: static;

        background-color: transparent;
      }
    }
  }
`

export const AdditionalActionButton = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;

  margin-top: 5px;

  display: flex;
  align-items: center;
  gap: 6px;

  font-weight: 600;
  color: #7a7a7a;
  text-decoration: ${(props) =>
    props.$textDeco ? props.$textDeco : 'underline'};

  opacity: 0.8;
  transition-duration: 0.3s;

  &:hover {
    opacity: 1;
    font-weight: 700;
  }
`
