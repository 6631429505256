import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import _ from 'lodash'
import PropTypes from 'prop-types'

import Home from '../Home/Home'
import Metrics from '../Home/Metrics/MetricsSection'
import Brands from '../App/Catalogue/Brands/Brands'
import Items from '../App/Catalogue/Items/Items'
import ServiceTypes from '../App/Catalogue/ServiceTypes/ServiceTypes'
import ProductOrder from '../App/ProductsOrder/ProductsOrder'
import Models from '../App/Catalogue/Models/Models'
import VehicleTypes from '../App/Catalogue/VehicleTypes/VehicleTypes'
import CategoryContainer from '../App/Catalogue/Categories/CategoryContainer'
import Product from '../App/Catalogue/Product/Product'
import Vehicle from '../App/Reception/Vehicle/Vehicle'
import Person from '../App/Persons/Person'
import Receptions from '../App/Reception/Reception/Reception'
import Process from '../App/Production/Process/Process'
import Employee from '../App/Employee/Employee/Employee'
import Package from '../App/Catalogue/Packages/Package'
import WorkOrder from '../App/WorkOrder/WorkOrder'
import Provider from '../App/Catalogue/Providers/providers'
/* import ScreenRol from '../App/Catalogue/ScreenRols/ScreenRol' */
import Profile from '../Profile/Profile'
import ForbiddenPage from '../ErrorPages/ForbiddenPage'
import Page404 from '../ErrorPages/Page404'
import Series from '../App/Catalogue/Invoicing/Series/Series'
import InvoiceType from '../App/Catalogue/Invoicing/InvoiceTypes/InvoiceTypes'
import History from '../App/History/History'
import SalesHistory from '../App/SalesHistory/SalesHistory'
import InvoiceContainer from '../App/Invoice/InvoiceContainer'
import AdministrateWorkOrder from '../App/MechanicWorkOrder/AdministrateWorkOrder'
import ContributorTypes from '../App/Catalogue/Invoicing/ContributorTypes/Contributors'
import Marketplace from '../App/Marketplace/Marketplace'
import ProductsMovements from '../App/Catalogue/Product/ProductsMovements'
import WorkOrderList from '../App/Notifications/WorkOrders/WorkOrderList'
import NotificationList from '../App/Notifications/NotificationList/NotificationList'
import AddReception from '../App/Reception/AddReception/AddReception'

const pages = {
  Brands: { component: Brands, id: 3, path: '/brands' },
  Items: { component: Items, id: 13, path: '/items' },
  Models: { component: Models, id: 14, path: '/models' },
  VehicleTypes: { component: VehicleTypes, id: 25, path: '/vehicleTypes' },
  ServiceTypes: { component: ServiceTypes, id: 31, path: '/serviceTypes' },
  CategoryContainer: {
    component: CategoryContainer,
    id: 5,
    path: '/categories',
  },
  Product: { component: Product, id: 26, path: '/products' },
  Process: { component: Process, id: 18, path: '/processes' },
  Package: { component: Package, id: 17, path: '/packages' },
  Provider: { component: Provider, id: 32, path: '/providers' },
  Vehicle: { component: Vehicle, id: 23, path: '/vehicles' },
  Persons: { component: Person, id: 20, path: '/customers' },
  Receptions: { component: Receptions, id: 28, path: '/receptions' },
  NewReception: {
    component: AddReception,
    id: 56,
    path: '/newReception',
  },
  WorkOrder: { component: WorkOrder, id: 29, path: '/workOrders' },
  Employee: { component: Employee, id: 9, path: '/employees' },
  /* ScreenRol: { component: ScreenRol, id: 27, path: '/permissions' }, */
  Profile: { component: Profile, id: 37, path: '/profile' },
  Series: { component: Series, id: 39, path: '/series' },
  InvoiceType: { component: InvoiceType, id: 12, path: '/invoiceType' },
  Invoice: { component: InvoiceContainer, id: 35, path: '/Invoices' },
  ProductOrder: { component: ProductOrder, id: 40, path: '/ProductOrders' },
  Metrics: { component: Metrics, id: 50, path: '/metrics' },
  AdministrateWorkOrder: { component: AdministrateWorkOrder, id: 50, path: '/administrateWorkOrder/:id?', },
  marketPlace: { component: Marketplace, id: 52, path: '/marketplace' },
  productsMovements: {
    component: ProductsMovements,
    id: 522,
    path: '/products-movements',
  }, // TODO: asignar id correcto
  history: { component: History, id: 49, path: '/history' },
  Saleshistory: { component: SalesHistory, id: 51, path: '/salesHistory' },
  ContributorTypes: {
    component: ContributorTypes,
    id: 7,
    path: '/contributorTypes',
  },
  WorkOrderList: { component: WorkOrderList, id: 54, path: '/workOrderList' },
  NotificationList: {
    component: NotificationList,
    id: 55,
    path: '/notifications',
  },
  Home: { component: Home, id: 30, path: '/finances' },
  Main: { component: Home, id: 30, path: '/' },
}
const renderRoute = ({ path, id, component, isExact }, permissions) => {
  // Validate if exist permissions and if the pageComponent exists into the permissions
  const permissionsPageComponent = _.find(
    permissions,
    (p) => p.screen_id === id
  )
  if (Object.keys(permissions).length > 0 && permissionsPageComponent) {
    if (permissions[id].can_read) {
      return (
        <Route path={path} key={id} component={component} exact={isExact} />
      )
    }
  }
  return <Route path={path} key={id} component={ForbiddenPage} />
}

const Full = ({ permissions }) => (
  <Switch>
    {_.map(pages, (page) => renderRoute(page, permissions))}
    <Route path="/" component={Page404} />
  </Switch>
)

const mapStateToProps = (store) => ({
  permissions: store.AppReducer.permissions,
})

Full.propTypes = {
  permissions: PropTypes.objectOf(PropTypes.object).isRequired,
}

export default connect(mapStateToProps)(Full)
