/* eslint-disable import/prefer-default-export */
import { Player } from '@lottiefiles/react-lottie-player'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import React from 'react'

import loaderAnimation from '../../../lotties/custom-loader.json'

export const AppLoader = ({ text , width }) => {
  return (
    <>
      <LoaderContainer>
        <Player
          src={loaderAnimation}
          loop
          autoplay
          style={{ width, maxWidth: '400px' }}
        />
      </LoaderContainer>
      <LoaderText>{text}</LoaderText>
    </>
  )
}

AppLoader.propTypes = {
  text: PropTypes.string,
  width: PropTypes.string,
}
AppLoader.defaultProps = {
  text: 'Cargando información',
  width: '23%',
}


const LoaderContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
`

const LoaderText = styled.div`
  color: #1e1e40;
  text-align: center;
  margin-bottom: 70px;
`

