import axios from 'axios'
import _ from 'lodash'

import {
  GET_PROCESSES,
  ADD_PROCESS,
  UPDATE_PROCESS,
  DELETE_PROCESS,
} from '../actionTypes/ProcessTypes'
import { NotificationHandler } from '../Helpers'
import { getPackages } from './PackageAction'

const getProcessAction = (payload) => ({
  type: GET_PROCESSES,
  payload,
})

const addProcessAction = (payload) => ({
  type: ADD_PROCESS,
  payload,
})

const updateProcessAction = (payload) => ({
  type: UPDATE_PROCESS,
  payload,
})

const deleteProcessAction = (payload) => ({
  type: DELETE_PROCESS,
  payload,
})

export const getProcesses = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const registersSize = _.size(getState().ProcessReducer.processes)
    if (registersSize === 0) {
      axios
        .get('/api/process')
        .then((response) => {
          if (!response.data.error) {
            const processes = _.keyBy(response.data.data, 'process_id')
            dispatch(getProcessAction(processes))
            resolve(response.data)
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al obtener procesos',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error: No se puedieron obtener los procesos',
            error
          )
        })
    } else {
      resolve()
    }
  })

export const addProcess = (process) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { workshop_id } = getState().AppReducer.currentUser

    axios
      .post('/api/process', { ...process, workshop_id })
      .then((responseProcess) => {
        if (!responseProcess.data.error) {
          const processToRedux = {
            ...responseProcess.data.data,
          }
          dispatch(addProcessAction(processToRedux))
          resolve(responseProcess)
          NotificationHandler(
            'Hecho:',
            'info',
            'Proceso registrado exitosamente'
          )
        } else {
          reject()
          NotificationHandler('Error', 'error', 'Error: Proceso no creado')
        }
      })
      .catch(() => {
        reject()
        NotificationHandler('Error', 'error', 'Error: Proceso no creado')
      })
  })

export const updateProcess = (process) => (dispatch) =>
  new Promise((resolve, reject) => {
    const { process_id } = process
    if (process_id) {
      axios
        .put(`/api/process/${process_id}`, process)
        .then((responseProcess) => {
          if (!responseProcess.data.error) {
            const processToRedux = {
              ...responseProcess.data.data,
            }
            dispatch(getPackages(true))
            dispatch(updateProcessAction(processToRedux))
            resolve(responseProcess)
            NotificationHandler(
              'Hecho:',
              'info',
              'Proceso actualizado correctamente'
            )
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al actualizar el proceso',
              responseProcess.data.data
            )
          }
        })
        .catch(() => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al actualizar el proceso'
          )
        })
    } else {
      reject()
      NotificationHandler(
        'Error',
        'error',
        `No se proporcionó id a: ${process.process_name}`
      )
    }
  })

export const deleteProcess = (process) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (process.process_id) {
      axios
        .delete(`/api/process/${process.process_id}`)
        .then((response) => {
          if (!response.data.error) {
            dispatch(deleteProcessAction(process))
            resolve(response)
            NotificationHandler('Hecho:', 'info', 'Proceso eliminado')
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error: Proceso no eliminado intentelo de nuevo'
            )
          }
        })
        .catch(() => {
          reject()
          NotificationHandler('Error', 'error', 'Error al eliminar el proceso')
        })
    } else {
      reject()
      NotificationHandler(
        'Error',
        'error',
        `No se proporcionó id a: ${process.process_name}`
      )
    }
  })
