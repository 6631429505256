import { GET_STATES } from '../actionTypes/StateTypes'

const StateReducer = (
  state = {
    states: {},
  },
  action
) => {
  switch (action.type) {
    case GET_STATES: {
      return {
        ...state,
        states: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export default StateReducer
