import axios from 'axios'
import _ from 'lodash'

import {
  GET_PERSONS_WORKSHOP,
  ADD_PERSON_WORKSHOP,
  UPDATE_PERSON_WORKSHOP,
  DELETE_PERSON_WORKSHOP,
} from '../actionTypes/PersonWorkshopTypes'
import { NotificationHandler } from '../Helpers'

const getPersonsWorkshopAction = (payload) => ({
  type: GET_PERSONS_WORKSHOP,
  payload,
})

const addPersonWorkshopAction = (payload) => ({
  type: ADD_PERSON_WORKSHOP,
  payload,
})

const updatePersonWorkshopAction = (payload) => ({
  type: UPDATE_PERSON_WORKSHOP,
  payload,
})

const deletePersonWorkshopAction = (payload) => ({
  type: DELETE_PERSON_WORKSHOP,
  payload,
})

export const getPersonsWorkshop = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const personsQuantity = _.size(getState().PersonWorkshopReducer.persons)
    const { workshop_id } = getState().AppReducer.currentUser
    if (personsQuantity === 0) {
      axios
        .get(`/api/person_workshops/workshop/${workshop_id}`)
        .then((response) => {
          if (!response.data.error) {
            const persons = _.keyBy(response.data.data, 'person_id')
            dispatch(getPersonsWorkshopAction(persons))
            resolve(response.data)
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al obtener los clientes del taller',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al obtener los clientes del taller',
            error
          )
        })
    } else {
      resolve({})
    }
  })

export const addPersonWorkshop = (person) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { workshop_id } = getState().AppReducer.currentUser
    axios
      .post('/api/person_workshops', { ...person, workshop_id })
      .then((response) => {
        if (!response.data.error) {
          const res = response.data.data
          if (person.personWorkshops === undefined) {
            // eslint-disable-next-line no-param-reassign
            person = {
              ...person,
              personWorshops: res,
            }
          } else {
            person.personWorkshops.push(res)
          }
          resolve({ ...res, person })
          dispatch(addPersonWorkshopAction({ ...res, person }))
          NotificationHandler('Done', 'success', 'Cliente agregado al taller')
        } else {
          reject()
          NotificationHandler('Error', 'error', response.data.data)
        }
      })
  })

export const updatePersonWorkshop = (person) => (dispatch) =>
  new Promise((resolve) => {
    dispatch(updatePersonWorkshopAction(person))
    resolve()
  })

export const deletePersonWorkshop = (person) => (dispatch) =>
  new Promise((resolve) => {
    dispatch(deletePersonWorkshopAction(person))
    resolve()
  })
