export const GET_WORK_ORDERS = 'GET_WORK_ORDERS'
export const ADD_WORK_ORDER = 'ADD_WORK_ORDER'
export const UPDATE_WORK_ORDER = 'UPDATE_WORK_ORDER'
export const DELETE_WORK_ORDER = 'DELETE_WORK_ORDER'
export const FINISH_WORK_ORDER = 'FINISH_WORK_ORDER'
export const SET_WORK_ORDER_INVOICED = 'SET_WORK_ORDER_INVOICED'
export const SET_WORK_ORDER_TO_ADMINISTRATE = 'SET_WORK_ORDER_TO_ADMINISTRATE'
export const SET_WORK_ORDER_QUANTITY_INFORMATION = 'SET_WORK_ORDER_QUANTITY_INFORMATION'
export const GET_COMMENTS_WORK_ORDER = 'GET_COMMENTS_WORK_ORDER'
export const GET_WORK_ORDER= 'GET_WORK_ORDER'
