import styled from 'styled-components'
import BREAK_POINTS from '../../../../global/css/breakPoints'

export const ColorPickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .custom-color-picker {
    display: flex;
    align-items: center;
    gap: 5px;
    position: relative;
  }
`

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;

  padding: 3px;
`

export const ColorSquare = styled.div`
  width: 45px;
  height: 45px;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${(props) => props.color};
  border: solid ${(props) => (props.selected ? '2px #E7E7E7' : '1px #E7E7E7')};
  transition: border 0.2s ease;

  &:hover {
    box-shadow: 0 0 1px 1px ${(props) => props.color};
  }

  box-shadow: ${(props) => (props.selected ? '0 0 2px 2px' : '0')}
    rgba(
      ${(props) => parseInt(props.color?.slice(1, 3), 16)},
      ${(props) => parseInt(props.color?.slice(3, 5), 16)},
      ${(props) => parseInt(props.color?.slice(5, 7), 16)},
      0.5
    );

  &&.white-square {
    border: solid 1px #697689;
    box-shadow: ${(props) => (props.selected ? '0 0 2px 2px' : '0')}
      rgba(213, 213, 213, 0.8);
  }

  @media (${BREAK_POINTS.MOBILE}) {
    width: 40px;
    height: 40px;
  }
`

export const Summary = styled.div`
  font-weight: bold;
  font-size: 1.1em;
  position: relative;

  p {
    margin: 1rem auto 1.3rem;
  }
`
