import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import Axios from 'axios'
import _ from 'lodash'
import { BrowserRouter } from 'react-router-dom'

import 'rc-slider/assets/index.css'
import 'react-datepicker/dist/react-datepicker.css'
import './public/index.scss'
import './public/style.css'
import './public/transitions.css'
import App from './App'
import { Host } from './Constants'
import store from './store'
import {
  checkValidToken,
  NotificationHandler,
  setAuthorizationToken,
} from './Helpers'
import {
  LOG_IN,
  LOG_OUT,
  SET_CURRENT_USER,
  SET_USER_PERMISSIONS,
} from './actionTypes/AppTypes'
import Loader from './Components'
import { getScreens } from './actions/ScreenAction'
import './fonts/gotham/Gotham-Bold.otf'
import './fonts/gotham/Gotham-Book.otf'

Axios.defaults.baseURL = Host

const token = localStorage.getItem('jwtToken')
const expirationTime = localStorage.getItem('tokenExpiration')

ReactDOM.render(<Loader />, document.getElementById('preLoader'))

if (module.hot) {
  module.hot.accept()
}

checkValidToken(token)
  .then(({ logged, user }) => {
    if (logged) {
      setAuthorizationToken(token, expirationTime)
      store.dispatch({
        type: SET_USER_PERMISSIONS,
        payload: _.keyBy(user.screens, 'screen_id'),
      })
      store.dispatch({ type: SET_CURRENT_USER, payload: user })
      store.dispatch({ type: LOG_IN })
      ReactDOM.unmountComponentAtNode(document.getElementById('preLoader'))
      ReactDOM.render(
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>,
        document.getElementById('root')
      )
      store.dispatch(getScreens())
    } else {
      store.dispatch({ type: LOG_OUT })
      ReactDOM.unmountComponentAtNode(document.getElementById('preLoader'))
      ReactDOM.render(
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>,
        document.getElementById('root')
      )
    }
  })
  .catch((error) => {
    ReactDOM.unmountComponentAtNode(document.getElementById('preLoader'))
    ReactDOM.render(
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>,
      document.getElementById('root'),
      () => {
        NotificationHandler(error.title, error.type, error.message)
      }
    )
  })
