import axios from 'axios'

export const updateWorkOrderProcess = async (work_order_id, process) => {
  try {
    const processPayload = {
      workOrderId: work_order_id,
      processId: process.process_id,
      processPrice: process.process_placed_price,
      processTime: process.process_estimated_time,
      processDiscount: process.process_placed_discount || 0,
    }

    const response = await axios.post(
      'api/work_order_details/process',
      processPayload
    )

    if (response.error) {
      throw response.error
    }
    return response
  } catch (error) {
    throw ('Error actualizando procesos', error)
  }
}
