import React, { useState, useEffect } from 'react';
import { Table, Switch, Empty } from 'antd';
import axios from 'axios';
import { connect } from 'react-redux'
import {
    Container,
    ContainerHeader,
    BodyContent
} from '../../Common/index';

import { Host } from '../../../../Constants'

import './WorkOrderList.scss';

const WorkOrderList = (employeeId) => {
    const [workOrders, setWorkOrders] = useState([]);
    const [empty] = React.useState(false);

    useEffect(() => {
        if (employeeId) {
            axios.get(`${Host}api/work-order-follow-configuration/list/${employeeId.employeeId}`)
                .then((response) => {
                    const { data } = response;
                    if (data.error === false) {
                        setWorkOrders(data.data);
                    } else {
                        console.error('Error fetching work orders');
                    }
                })
                .catch((error) => {
                    console.error('Error fetching work orders:', error);
                });
        }
    }, [employeeId])

    const handleFollow = (workOrderId) => {
        const updatedWorkOrders = workOrders.map((order) => {
            if (order.work_order_id === workOrderId) {
                const updatedOrder = { ...order };
                updatedOrder.follow_enabled = !order.follow_enabled;

                axios.patch(`${Host}api/work-order-follow-configuration/${order.work_order_follow_configuration_id}`, { follow: updatedOrder.follow_enabled })
                    .then((response) => {
                        const { data } = response;
                        if (data.error === false) {
                            // Successfully updated the follow_enabled status
                            setWorkOrders(updatedWorkOrders);
                        } else {
                            console.error('Error updating follow status');
                        }
                    })
                    .catch((error) => {
                        console.error('Error updating follow status:', error);
                    });

                return updatedOrder;
            }
            return order;
        });

        setWorkOrders(updatedWorkOrders);
    };

    const formatTotal = (value) => {
        if (value > 0) {
            return `$${value.toFixed(2)}`;
        }
        return '-';
    };

    const locale = {
        emptyText: (
            <Empty description="Aún no existen órdenes de trabajo" />
        )
    };

    const columns = [
        {
            title: 'Orden #',
            dataIndex: 'work_order_id',
            key: 'work_order_id',
            ellipsis: true
        },
        {
            title: 'Vehículo',
            dataIndex: 'vehicle',
            key: 'vehicle',
            ellipsis: true
        },
        {
            title: 'Mecánico',
            dataIndex: 'mechanic',
            key: 'mechanic',
            ellipsis: true
        },
        {
            title: 'Total',
            dataIndex: 'work_order_total',
            key: 'work_order_total',
            ellipsis: true,
            render: (text, record) => formatTotal(record.work_order_total) // Format the total
        },
        {
            title: 'Recibir notificaciones',
            key: 'actions',
            ellipsis: true,
            align: 'center',
            render: (text, record) => (
                <Switch
                    checked={record.follow_enabled}
                    onChange={() => handleFollow(record.work_order_id)}
                    disabled={record.blocked}
                    checkedChildren="Sí"
                    unCheckedChildren="No"
                />
            )
        },
    ];

    return (

        <Container>
            <ContainerHeader left title="Configuración" />
            <BodyContent>
                <h3 className='work-order-list-subtitle'>Recibir notificaciones de ordenes de trabajo </h3>
                <Table
                    locale={locale}
                    className="work-order-list-table"
                    dataSource={empty ? [] : workOrders}
                    columns={columns}
                    rowKey="work_order_id"
                    pagination="bottomCenter"
                    tableLayout="fixed"
                />
            </BodyContent>
        </Container>
    );
};

const mapStateToProps = (store) => ({
    employeeId: store.AppReducer.currentUser.employee_id,
})

export default connect(mapStateToProps)(WorkOrderList)


