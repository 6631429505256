import React from "react"
import { PropTypes } from 'prop-types'

const GasPumpIcon = ({ color, size }) => {
  return (
    <svg fontSize={size} style={{ margin: '10px' }}
      fill={color} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M336 448H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h320c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm157.2-340.7l-81-81c-6.2-6.2-16.4-6.2-22.6 0l-11.3 11.3c-6.2 6.2-6.2 16.4 0 22.6L416 97.9V160c0 28.1 20.9 51.3 48 55.2V376c0 13.2-10.8 24-24 24s-24-10.8-24-24v-32c0-48.6-39.4-88-88-88h-8V64c0-35.3-28.7-64-64-64H96C60.7 0 32 28.7 32 64v352h288V304h8c22.1 0 40 17.9 40 40v27.8c0 37.7 27 72 64.5 75.9 43 4.3 79.5-29.5 79.5-71.7V152.6c0-17-6.8-33.3-18.8-45.3zM256 192H96V64h160v128z" /></svg>
  );
}

GasPumpIcon.propTypes = {
  color: PropTypes.string.isRequired,
  size: PropTypes.string
}

GasPumpIcon.defaultProps = {
  size: '13px'
}

export default GasPumpIcon;
