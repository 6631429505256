import React from 'react'
import { Button } from 'react-bootstrap'
import ReactToolTip from 'react-tooltip'
// import { textFilter } from 'react-bootstrap-table2-filter'
import {
  EditFilled,
  EyeOutlined,
  DeleteFilled
} from '@ant-design/icons';
// import _ from 'lodash'
import moment from 'moment'
import { columnStyle, columnHeaderStyle } from '../../../Utilities/TableStyles'

const styleIcons = {
  fontSize: '17px',
}

/* const receptionStatus = {
  0: {
    id: 0,
    name: 'Sin asignar',
    color: 'default',
  },
  1: {
    id: 1,
    name: 'Aprobada',
    color: 'success',
  },
  2: {
    id: 2,
    name: 'No aprobada',
    color: 'danger',
  },
} */

const checkDisableToWorkOrderButton = (row) =>
  row.reception_state === 0 ||
  row.reception_state === 2 ||
  row.budget.workOrder.length > 0

const receptionHasWorkOrder = (row) => row.budget.workOrder.length > 0

const editButton = (editReception, can_edit) => (cell, row) =>
(
  <Button
    onClick={() => {
      editReception(row)
    }}
    bsStyle="link"
    bsSize="small"
    style={{color:'#1e1e40' }}
    data-tip={can_edit ? 'Modificar' : 'Ver'}
    disabled={receptionHasWorkOrder(row)}
  >

    {can_edit ? <EditFilled style={styleIcons} /> : <EyeOutlined style={styleIcons} />}

    <ReactToolTip />
  </Button>
)

/* const renderDropdown = (updateStatus) => (cell, row) => {
  const { reception_state } = row
  return (
    <center style={{ marginBottom: '10px', display: 'flex' }}>
      <DropdownButton
        disabled={receptionHasWorkOrder(row)}
        bsStyle={receptionStatus[reception_state].color}
        title={receptionStatus[reception_state].name}
        key={receptionStatus[reception_state].id}
        id={`dropdown-basic-${receptionStatus[reception_state].id}`}
      >
        {_.map(receptionStatus, (status) => (
          <MenuItem
            key={status.id}
            onClick={() => {
              updateStatus(row, status.id)
            }}
            eventKey={status.id}
          >
            {status.name}
          </MenuItem>
        ))}
      </DropdownButton>
    </center>
  )
} */
const deleteButton = (deleteReception) => (cell, row) =>
(
  <Button
    onClick={() => deleteReception(row)}
    bsStyle="link"
    bsSize="small"
    style={{ color: 'red' }}
    data-tip="Eliminar"
  >
    <DeleteFilled style={styleIcons} />
    <ReactToolTip />
  </Button>
)
/* const filterOptions = {
  delay: 100,
  style: {
    margin: '10px 0 0 0',
    padding: '20px 2%',
  },
  placeholder: 'Buscar',
} */

const getDate = (date) => moment(date).format('dddd D MMMM YYYY')

const renderMessageTooltipCreateWorkOrder = (row) => {
  if (row.reception_state === 0) {
    return 'La recepción debe de ser asignada'
  }
  if (row.reception_state === 2 || row.budget.workOrder.length > 0) {
    return 'Orden de trabajo procesada'
  }
  return ''
}

const renderToWorkOrderButton = (toWorkOrder) => (cell, row) =>
  (
    <Button
      onClick={() => toWorkOrder(row)}
      bsStyle="ligth"
      bsSize="small"
      style={{
        color: checkDisableToWorkOrderButton(row) ? 'gray' : 'black',
      }}
      data-tip={
        row.reception_state !== 1
          ? renderMessageTooltipCreateWorkOrder(row)
          : null
      }
      disabled={checkDisableToWorkOrderButton(row)}
    >
      <div
        style={{
          display: 'flex',
          gap: '8px',
          alignItems: 'center',
          fontSize: '14px',
        }}
      >
        <i className="fas fa-wrench" /> Asignar mecánico
      </div>
    </Button>
  )

const getColumns = (
  editReception,
  deleteReception,
  updateStatus,
  state,
  toWorkOrder,
  { can_edit, can_delete },
  // width = window.screen.width
) => {
  const columns = [
    {
      dataField: 'reception_id',
      text: 'Número',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      /* sort: true,
      filter: textFilter(filterOptions), */
      formatter: (id) => `#${id}`,
    },
    {
      dataField: 'reception_date_received',
      text: 'Recibido',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      /* sort: true,
      filter: textFilter(filterOptions), */
      formatter: getDate,
    },
    {
      dataField: 'vehicle.vehicle_license_plate',
      text: 'Placa',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      /* sort: true,
      filter: textFilter(filterOptions), */
    },
    {
      dataField: 'vehicle.model.brand.brand_name',
      text: 'Marca',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      /* sort: true,
      filter: textFilter(filterOptions), */
    },
    {
      dataField: 'vehicle.model.model_name',
      text: 'Modelo',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      /* sort: true,
      filter: textFilter(filterOptions), */
    },
   /* {
      dataField: 'reception_state',
      text: 'Estado',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
       sort: true,
      filter: textFilter(filterOptions), 
      formatter: renderDropdown(updateStatus, state, width),
    }, */
    {
      dataField: 'id3',
      text: '',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      formatter: renderToWorkOrderButton(toWorkOrder),
    },
    {
      dataField: 'id',
      text: '',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      formatter: editButton(editReception, can_edit),
    },
  ]
  if (can_delete) {
    columns.push({
      dataField: 'id2',
      text: '',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      formatter: deleteButton(deleteReception),
    })
  }
  return columns
}

export default getColumns
