/* eslint no-underscore-dangle: 0 */
import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import BrandReducer from './reducers/BrandReducer'
import ItemReducer from './reducers/ItemReducer'
import ServiceTypeReducer from './reducers/ServiceTypeReducer'
import ModelReducer from './reducers/ModelReducer'
import VehicleTypesReducer from './reducers/VehicleTypesReducer'
import CountryReducer from './reducers/CountryReducer'
import AppReducer from './reducers/AppReducer'
import CategoryReducer from './reducers/CategoryReducer'
import ProductReducer from './reducers/ProductReducer'
import ContributorTypeReducer from './reducers/ContributorTypeReducer'
import ColorReducer from './reducers/ColorReducer'
import VehicleReducer from './reducers/VehicleReducer'
import MeasureUnitReducer from './reducers/MeasureUnitReducer'
import PersonReducer from './reducers/PersonReducer'
import StateReducer from './reducers/StateReducer'
import CityReducer from './reducers/CityReducer'
import ReceptionReducer from './reducers/ReceptionReducer'
import ProcessReducer from './reducers/ProcessReducer'
import BudgetReducer from './reducers/BudgetReducer'
import WorkOrderReducer from './reducers/WorkOrderReducer'
import MechanicReducer from './reducers/MechanicReducer'
import WorkOrderStatusReducer from './reducers/WorkOrderStatusReducer'
import EmployeeReducer from './reducers/EmployeeReducer'
import RolReducer from './reducers/RolReducer'
import PackageReducer from './reducers/PackageReducer'
import ProviderReducer from './reducers/ProviderReducer'
import ScreenReducer from './reducers/ScreenReducer'
import SeriesReducer from './reducers/SeriesReducer'
import InvoiceTypeReducer from './reducers/InvoiceTypeReducer'
import HistoryReducer from './reducers/HistoryReducer'
import InvoiceReducer from './reducers/InvoiceReducer'
import ProductOrderReducer from './reducers/ProductOrderReducer'
import FamilyModelReducer from './reducers/FamilyModelReducer'
import UserAppReducer from './reducers/UserAppReducer'
import VehicleUserAppReducer from './reducers/VehicleUserAppReducer'
import PersonWorkshopReducer from './reducers/PersonWorkshopReducer'
import DashboardReducer from './reducers/DashboardReducer'

export default createStore(
  combineReducers({
    BrandReducer,
    ItemReducer,
    ServiceTypeReducer,
    ModelReducer,
    VehicleTypesReducer,
    CountryReducer,
    AppReducer,
    CategoryReducer,
    ProductReducer,
    ContributorTypeReducer,
    ColorReducer,
    MeasureUnitReducer,
    VehicleReducer,
    PersonReducer,
    StateReducer,
    CityReducer,
    ReceptionReducer,
    EmployeeReducer,
    RolReducer,
    PackageReducer,
    ProcessReducer,
    BudgetReducer,
    WorkOrderReducer,
    WorkOrderStatusReducer,
    MechanicReducer,
    ProviderReducer,
    ScreenReducer,
    SeriesReducer,
    InvoiceTypeReducer,
    HistoryReducer,
    InvoiceReducer,
    ProductOrderReducer,
    FamilyModelReducer,
    UserAppReducer,
    VehicleUserAppReducer,
    PersonWorkshopReducer,
    DashboardReducer,
  }),
  compose(
    applyMiddleware(thunk),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f
  )
)
