import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Button } from 'react-bootstrap'
import StarRatingComponent from 'react-star-rating-component'
import RowTableNoData from './Components/RowTableNoData'

const TopCitiesAverageScoresHistorySectionComponent = ({ data, active, toggleModal, setMechanicId }) => {
  const { averageMechanicScores = [] } = data
  const { averageWorkshopScores = {} } = data
  const { topTenCities = [] } = data
  if(active){
  return (
    <div className="dashboard-section-container margin-top margin-bottom">
      <Row>
        <Col lg={6} md={6} sm={12}>
          <h3 className="dashboard-section-subtitle">Calificaciones y Metricas</h3>
          <div className="scores-history-container">
            <div className="mechanics-scores">
              {averageMechanicScores.map((m, i) => (
                <div
                  className="mechanic-item"
                  key={`${m.employee_name}-${i + 1}`}
                >
                  <img
                    src={
                      m.employee_urlimg
                        ? m.employee_urlimg
                        : 'https://sunfieldfarm.org/wp-content/uploads/2014/02/profile-placeholder.png'
                    }
                    alt="Profile"
                    className="mechanic-item-image"
                  />
                  <p className="mechanic-item-name">{m.employee_name}</p>
                  <p className="mechanic-item-position">Mecánico</p>
                  <StarRatingComponent
                    name="rate"
                    className="mechanic-item-score"
                    emptyStarColor="rgb(255, 180, 0)"
                    editing={false}
                    renderStarIcon={(index, value) => (
                      <span>
                        <i
                          className={
                            index <= value ? 'fas fa-star' : 'far fa-star'
                          }
                        />
                      </span>
                    )}
                    renderStarIconHalf={() => (
                      <span>
                        <span style={{ position: 'absolute' }}>
                          <i className="far fa-star" />
                        </span>
                        <span>
                          <i className="fas fa-star-half" />
                        </span>
                      </span>
                    )}
                    starCount={5}
                    value={m.score}
                  />
                  <Button
                  style={{marginTop:"10px"}} 
                  bsStyle="danger" onClick={()=>{
                    toggleModal()
                    setMechanicId(m.employee_id)
                  }}>Ver metricas</Button>
                </div>
              ))}
            </div>
            <div className="workshop-score">
              <div className="workshop-score-media">
                <img
                  src={
                    averageWorkshopScores.workshop_urlimag
                      ? averageWorkshopScores.workshop_urlimag
                      : 'https://user-images.githubusercontent.com/13499566/107727739-9fbe6b80-6cb1-11eb-9a46-0577bc1c0e89.png'
                  }
                  alt="Workshop logo"
                  className="workshop-score-image"
                />
              </div>
              <div className="workshop-score-data">
                <p className="workshop-data-name">
                  {averageWorkshopScores.workshop_name}
                </p>
                <StarRatingComponent
                  name="rate"
                  className="workshop-data-score"
                  emptyStarColor="rgb(255, 180, 0)"
                  editing={false}
                  renderStarIcon={(index, value) => (
                    <span>
                      <i
                        className={
                          index <= value ? 'fas fa-star' : 'far fa-star'
                        }
                      />
                    </span>
                  )}
                  renderStarIconHalf={() => (
                    <span>
                      <span style={{ position: 'absolute' }}>
                        <i className="far fa-star" />
                      </span>
                      <span>
                        <i className="fas fa-star-half" />
                      </span>
                    </span>
                  )}
                  starCount={5}
                  value={averageWorkshopScores.score}
                />
              </div>
            </div>
          </div>
        </Col>
        <Col lg={4} md={4} sm={12}>
          <h3 className="dashboard-section-subtitle">Mapa De Ubicaciones</h3>
          <table className="dashboard-table">
            <thead>
              <tr>
                <th>Municipio</th>
                <th>Departamento</th>
                <th className="center">Cantidad</th>
              </tr>
            </thead>
            <tbody>
              {topTenCities.length === 0 && (
                <RowTableNoData text="No existen datos para mostrar" />
              )}
              {topTenCities.map((c, _index) => (
                <tr key={`${c.city_name}-${_index + 1}`}>
                  <td>{c.city_name}</td>
                  <td>{c.state_name}</td>
                  <td className="center">{c.quantity}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      </Row>
    </div>
  )}
  return (<></>)
}

TopCitiesAverageScoresHistorySectionComponent.propTypes = {
  data: PropTypes.objectOf(PropTypes.array).isRequired,
  active: PropTypes.number.isRequired,
  toggleModal: PropTypes.func.isRequired,
  setMechanicId: PropTypes.func.isRequired
}

export default TopCitiesAverageScoresHistorySectionComponent
