import axios from 'axios'
import _ from 'lodash'

import { GET_USERS_APP, UPDATE_USER_APP } from '../actionTypes/UserAppTypes'
import { NotificationHandler } from '../Helpers'

const getUsersAppAction = (payload) => ({
  type: GET_USERS_APP,
  payload,
})

const updateUserAppAction = (payload) => ({
  type: UPDATE_USER_APP,
  payload,
})

export const getUsersApp = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const usersQuantity = _.size(getState().UserAppReducer.usersApp)
    if (usersQuantity === 0) {
      axios
        .get('/api/userApp')
        .then((response) => {
          if (!response.data.error) {
            const usersApp = _.keyBy(response.data.data, 'user_app_id')
            dispatch(getUsersAppAction(usersApp))
            resolve(response.data)
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al obtener usuarios',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al obtener usuarios',
            error
          )
        })
    } else {
      resolve({})
    }
  })

export const updateUserApp = (user) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (user.user_app_id) {
      axios
        .put(`/api/userApp/${user.user_app_id}`, user)
        .then((response) => {
          if (!response.data.error) {
            dispatch(updateUserAppAction(response.data.data))
            resolve(response.data.data)
          } else {
            reject(response)
          }
        })
        .catch((error) => {
          reject(error)
        })
    } else {
      reject()
    }
  })
