import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import { debounce } from 'lodash'
import { Input, Table } from 'antd'
import { connect } from 'react-redux'
import PreviousNextButton from '../../../../buttons/PreviousNextButton/PreviousNextButton'
import GradientTextComponent from '../../../Common/GradientText.js/GradientText'
import { PackageStepWrapper } from './PackageStep.styles'
import InfoDisclaimer from '../../../Common/InfoDisclaimer/InfoDisclaimer'
import SearchBar from '../../../../inputs/SearchBar/SearchBar'
import { getPackageDetail } from '../../../../../api/apiPackagesService'
import { AppLoader } from '../../../Common/AppLoader'
import EmptyState from '../../../Common/EmptyState/EmptyState'
// import { useModal } from '../../../../../hooks/useModal'
// import EditProcessModal from './EditProcessModal/EditProcessModal'
import { useAddReceptionContext } from '../../../../../contexts/AddReceptionContext'
import { addReceptionActions } from '../../../../../reducers/AddReceptionReducer'
import { setPackages as setAllPackages } from '../../../../../actions/PackageAction'
import { getTotalWithDiscount } from '../../../../Utilities/totalWithDiscount'
import { getNextButtonText } from '../../../../../Helpers'

const PackageStep = ({
  handleNext,
  handlePrevious,
  allPackages,
  setPackages,
}) => {
  const { dispatch, state } = useAddReceptionContext()
  const { packageValue, packageDetails, isEdit } = state
  const [searchValue, setSearchValue] = useState('')
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [value, setValue] = useState(null)
  const [packageSelected, setPackageSelected] = useState(null)
  const [filteredPackages, setFilteredPackages] = useState([])
  const [processList, setProcessList] = useState([])
  const [packagePrice, setPackagePrice] = useState(0)
  const [packagePriceWithDiscount, setPackagePriceWithDiscount] = useState(
    packageDetails?.packagePriceWithDiscount || 0
  )
  const [packageTime, setPackageTime] = useState(
    packageDetails?.packageTotalTime || 0
  )
  /* const [isModalOpen, openModal, closeModal] = useModal()
  const [processToEdit, setProcessToEdit] = useState(null) */
  const [discount, setDiscount] = useState(packageDetails?.packageDiscount || 0)

  const packagePriceTotal =
    discount > 0 ? packagePriceWithDiscount : packagePrice?.toFixed(2)

  const isSkipButtonStyle = isEdit ? false : !packageSelected

  useEffect(() => {
    if (allPackages.length < 1) {
      setPackages()
    }
  }, [])

  useEffect(() => {
    if (packageValue) {
      const selected = allPackages.find(
        (item) => item.packageId === packageValue?.value
      )
      setPackageSelected(selected)
      setValue({
        label: packageValue.label,
        value: packageValue.value,
      })
      setProcessList(packageDetails?.processes)
      setPackagePrice(packageDetails?.packagePrice)
      setPackageTime(packageDetails?.packageTotalTime)
    }
  }, [allPackages])

  const transformPackages = (packages) => {
    const response = packages.map((item) => ({
      value: item.packageId,
      label: item.packageName,
    }))

    return response
  }

  const transformProcesses = () => {
    const transformedProcesses = processList.map((process) => ({
      ...process,
      key: process.processId,
    }))
    return transformedProcesses
  }

  const handleSearch = debounce((searchText) => {
    setSearchValue(searchText)
    const searchResults = allPackages.filter((item) => {
      const name = item.packageName.toLowerCase().replace(/\s+/g, ' ')
      const search = searchText.toLowerCase().replace(/\s+/g, ' ')
      return name.includes(search)
    })
    setFilteredPackages(searchResults)
  }, 300)

  const handleChange = async (newValue) => {
    setIsDataLoading(true)

    if (!newValue) {
      setPackageSelected(null)
      setPackagePrice(null)
      setValue(null)
      setIsDataLoading(false)
      dispatch({
        type: addReceptionActions.SET_PACKAGE,
        payload: undefined,
      })
      dispatch({
        type: addReceptionActions.SET_PACKAGE_DETAILS,
        payload: undefined,
      })
      return
    }

    const selected = allPackages.find(
      (item) => item.packageId === newValue?.value
    )
    setPackageSelected(selected)

    setValue({
      label: newValue.label,
      value: newValue.value,
    })
    setSearchValue('')
    setDiscount(0)

    const processes = await getPackageDetail(selected.packageId)
    setProcessList(processes.processes)
    setPackagePrice(processes.packagePrice)
    setPackageTime(processes.packageTotalTime)
    setIsDataLoading(false)

    dispatch({
      type: addReceptionActions.SET_PACKAGE_DETAILS,
      payload: processes,
    })

    dispatch({
      type: addReceptionActions.SET_PACKAGE,
      payload: {
        label: newValue.label,
        value: newValue.value,
      },
    })
  }

  /* const handleOpen = (process) => {
    setProcessToEdit(process)
    openModal()
  } */

  const handleChangeDiscount = (discountValue) => {
    const validateDiscount = Math.min(100, Math.max(0, discountValue))
    const price = getTotalWithDiscount(packagePrice, validateDiscount)
    dispatch({
      type: addReceptionActions.SET_PACKAGE_DETAILS,
      payload: {
        ...packageDetails,
        packageDiscount: validateDiscount,
        packagePriceWithDiscount: price,
      },
    })

    setDiscount(validateDiscount)
    setPackagePriceWithDiscount(price)
  }

  const columns = [
    {
      title: '#',
      dataIndex: 'processSequence',
      key: 'processSequence',
    },
    {
      title: 'Proceso',
      dataIndex: 'processName',
      key: 'processName',
    },
    {
      title: 'Precio',
      dataIndex: 'processPrice',
      key: 'processPrice',
      render: (text) => {
        return `$${text.toFixed(2)}`
      },
    },
    {
      title: 'Tiempo',
      dataIndex: 'processTime',
      key: 'processTime',
      render: (text) => {
        return `${text} min`
      },
    },
    /* {
      title: ' ',
      dataIndex: 'edit',
      key: 'edit',
      render: (_, record) => (
        <button
          type="button"
          className="edit-btn"
          onClick={() => handleOpen(record)}
        >
          <i className="fas fa-pencil-alt" />
        </button>
      ),
    }, */
  ]

  const locale = {
    emptyText: <EmptyState text="Sin procesos registrados" />,
  }

  return (
    <>
      <main className="new-reception__main">
        <div className="new-reception__main__question">
          <GradientTextComponent text="Selecciona un paquete" />
        </div>
        <div className="new-reception__main__content">
          <PackageStepWrapper>
            <InfoDisclaimer
              message={
                packageSelected
                  ? 'Puedes cambiar el paquete selecionado buscando nuevamente por el nombre.'
                  : ''
              }
            >
              <span>
                Puedes{' '}
                <button
                  onClick={() => handleNext()}
                  type="button"
                  style={{
                    background: 'unset',
                    border: 'unset',
                    margin: 'unset',
                    padding: 'unset',
                  }}
                >
                  <b>
                    <u>omitir este paso</u>
                  </b>
                </button>{' '}
                si solamente necesitas añadir servicios o respuestos.
              </span>
            </InfoDisclaimer>
            <SearchBar
              id="client-name"
              searchValue={searchValue}
              items={
                searchValue
                  ? transformPackages(filteredPackages)
                  : transformPackages(allPackages)
              }
              defaultValue={value}
              value={value}
              placeholder="Ingresa el nombre del paquete"
              label="Busca por nombre:"
              handleValueChange={handleChange}
              handleSearch={handleSearch}
            />
            {packageSelected &&
              (isDataLoading ? (
                <div className="loader">
                  <AppLoader text="Cargando detalles del paquete..." />
                </div>
              ) : (
                <div className="details">
                  <h2>
                    <b>Paquete seleccionado:</b>
                  </h2>
                  <Table
                    locale={locale}
                    dataSource={transformProcesses()}
                    columns={columns}
                    rowKey="process_id"
                    pagination={false}
                  />
                  <div className="summary">
                    <p>
                      <b>Tiempo estimado:</b> {packageTime} min
                    </p>
                    <div className="discount-total">
                      <div className="discountInputWrapper">
                        <label htmlFor="discount" className="discount">
                          Descuento:
                          <Input
                            suffix="%"
                            min={1}
                            type="number"
                            max={100}
                            inputMode="numeric"
                            numeric="true"
                            digitonly="true"
                            itemID="discount"
                            itemType="number"
                            defaultValue={0}
                            value={discount}
                            onChange={(e) =>
                              handleChangeDiscount(e.target.value)
                            }
                          />
                        </label>
                      </div>
                      <p className="total">
                        <b>Total:</b> ${packagePriceTotal}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
          </PackageStepWrapper>
        </div>
      </main>
      <div className="new-reception__navigation-buttons">
        <PreviousNextButton isPrevious onClickHandler={handlePrevious} />
        <PreviousNextButton
          isPrevious={false}
          onClickHandler={handleNext}
          nextText={getNextButtonText(packageSelected, isEdit)}
          isSkip={isSkipButtonStyle}
        />
      </div>
      {/* isModalOpen && (
        <EditProcessModal
          className="edit"
          process={processToEdit}
          setProcesses={setProcessList}
          processes={processList}
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          packageId={packageSelected.packageId}
          setPackageTime={setPackageTime}
          setPackagePrice={setPackagePrice}
          setDiscount={setDiscount}
          setLoading={setIsDataLoading}
        />
      )  */}
    </>
  )
}

PackageStep.propTypes = {
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
  allPackages: PropTypes.any.isRequired,
  setPackages: PropTypes.func.isRequired,
}
const mapDispatchToProps = (dispatch) => ({
  setPackages() {
    dispatch(setAllPackages())
  },
})
const mapStateToProps = (store) => ({
  allPackages: store.PackageReducer.allPackages,
})

export default connect(mapStateToProps, mapDispatchToProps)(PackageStep)
