import React, { Component } from 'react'
import { connect } from 'react-redux'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory from 'react-bootstrap-table2-filter'
import { Panel, Grid, Row, Col, Button } from 'react-bootstrap'
import _ from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { MenuOutlined, PlusCircleFilled } from '@ant-design/icons';
import {
  getInvoiceType,
  addInvoiceTypes,
  updateInvoiceType,
  deleteInvoiceType,
} from '../../../../../actions/InvoiceTypeAction'
import {
  Container,
  ContainerHeader,
  BodyContent,
  showConfirmDialog,
  PanelHeader,
} from '../../../Common/index'
import InvoiceTypeModal from './InvoiceTypeModal'
import getColumns from './columns'
import { BREAK_POINTS_DESKTOP_FIRST } from '../../../../../global/css/breakPoints'
import { AppLoader } from '../../../Common/AppLoader'

const SCREEN_ID = 35

class ModelsContainer extends Component {
  state = {
    showModal: false,
    loading: true,
    selectedInvoiceType: {},
  }

  componentDidMount() {
    const { getInvoiceTypes } = this.props
    getInvoiceTypes()
      .then(() => {
        this.setState({ loading: false })
      })
      .catch(() => {
        this.setState({ loading: false })
      })
  }

  toggleModal = () => {
    const { showModal } = this.state
    this.setState({ showModal: !showModal })
  }

  addInvoiceType = () => {
    this.setState({ selectedInvoiceType: {} })
    this.toggleModal()
  }

  updateInvoiceType = (invoiceType) => {
    this.setState({ selectedInvoiceType: invoiceType })
    this.toggleModal()
  }

  deleteInvoiceType = (invoiceType) => {
    const { removeInvoiceType } = this.props
    showConfirmDialog(
      '¿Eliminar?',
      `¿Desea eliminar el tipo de factura ${invoiceType.invoice_type_name}?`,
      removeInvoiceType,
      invoiceType
    )
  }

  render() {
    const { showModal, selectedInvoiceType, loading } = this.state
    const { permissions, invoiceTypes, addInvoiceType, modifyInvoiceType } =
      this.props
    const { can_write, can_edit, can_delete } = permissions[SCREEN_ID]
    const pagePermissions = {
      can_write,
      can_edit,
      can_delete,
    }
    return (
      <Container>
        <ContainerHeader title="Tipos de facturas" />
        <BodyContent>
          <Grid fluid>
            <Row>
              <Col sm={12}>
                <Panel>
                  <Panel.Heading bsClass="panel-heading">
                    <Panel.Title>
                      <PanelHeader>
                        <div>
                          <p id="title">
                            <MenuOutlined style={{ marginRight: '7px' }} /> Administrar Tipos de
                            Factura
                          </p>
                        </div>
                        <div>
                          {!!can_write && (
                            <Button
                              onClick={this.addInvoiceType}
                              bsStyle="success"
                              className="btn-add btn-antd-icon"
                            >
                              <PlusCircleFilled />
                              Agregar registro
                            </Button>
                          )}
                        </div>
                      </PanelHeader>
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    {loading ? (
                      <AppLoader />
                    ) : (
                      <Row>
                        <Col md={10} />
                        <Col md={12}>
                          <CustomStyledTable>
                            <BootstrapTable
                              noDataIndication="Sin datos para mostrar"
                              keyField="invoice_type_id"
                              data={_.values(invoiceTypes).reverse()}
                              columns={getColumns(
                                this.updateInvoiceType,
                                this.deleteInvoiceType,
                                {
                                  can_edit,
                                  can_delete,
                                }
                              )}
                              filter={filterFactory()}
                              pagination={paginationFactory()}
                            />
                          </CustomStyledTable>
                        </Col>
                      </Row>
                    )}
                  </Panel.Body>
                </Panel>
              </Col>
            </Row>
          </Grid>
          <InvoiceTypeModal
            modalOpen={showModal}
            toggleModal={this.toggleModal}
            editMode={!_.isEmpty(selectedInvoiceType)}
            addInvoiceTypes={addInvoiceType}
            updateInvoiceType={modifyInvoiceType}
            selectedInvoiceType={selectedInvoiceType}
            pagePermissions={pagePermissions}
          />
        </BodyContent>
      </Container>
    )
  }
}

const CustomStyledTable = styled.div`
  @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
    table {
      border: none;
      padding: 0px 10px;

      thead {
        tr {
          display: flex;
          flex-direction: column;

          th {
            label {
              width: 100%;
            }
          }

          th {
            border: none;
          }

          th:nth-last-child(1),
          th:nth-last-child(2) {
            display: none;
          }
        }
      }

      tbody {
        tr {
          border: 1px solid #ddd;
          display: flex;
          flex-wrap: wrap;
          border-radius: 10px;
          margin: 20px 10px;
          padding-top: 10px;
          padding-bottom: 10px;

          td {
            width: 100%;
            border: 0;
            border-right: 0px !important;
            border-left: 0px !important;
          }

          td:nth-last-child(1),
          td:nth-last-child(2) {
            width: 40%;
          }

          td::before {
            font-weight: bold;
            margin-right: 0.5rem;
          }

          td:nth-child(1)::before {
            content: 'Nombre:';
          }

          td:nth-child(2)::before {
            content: 'Código:';
          }
        }
      }
    }
  }
`

const mapStateToProps = (store) => ({
  permissions: store.AppReducer.permissions,
  invoiceTypes: store.InvoiceTypeReducer.invoiceTypes,
})

const mapDispatchToProps = (dispatch) => ({
  getInvoiceTypes() {
    return dispatch(getInvoiceType())
  },
  addInvoiceType(invoiceType) {
    return dispatch(addInvoiceTypes(invoiceType))
  },
  modifyInvoiceType(invoiceType) {
    return dispatch(updateInvoiceType(invoiceType))
  },
  removeInvoiceType(invoiceType) {
    return dispatch(deleteInvoiceType(invoiceType))
  },
})

ModelsContainer.propTypes = {
  permissions: PropTypes.objectOf(PropTypes.object).isRequired,
  getInvoiceTypes: PropTypes.func.isRequired,
  addInvoiceType: PropTypes.func.isRequired,
  modifyInvoiceType: PropTypes.func.isRequired,
  removeInvoiceType: PropTypes.func.isRequired,
  invoiceTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModelsContainer)
