import React from 'react'
import { Table, Button, Alert, FormControl } from 'react-bootstrap'
import _ from 'lodash'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { EyeFilled, PlusCircleFilled } from '@ant-design/icons';
import WorkOrderDetail from './WorkOrderDetail'
import PackageDetails from './PackageDetails'
import {
  getWorkOrderTotal,
  getTotalOrder,
  getTotalTime,
  getFormatTime,
  getPackageDetailsTimeTotal,
  getPackageDetailsTotal,
} from './functions'

const WorkOrderDetails = ({
  workOrderDetails,
  products,
  handleChangeDetail,
  deleteWorkOrderDetail,
  addWorkOrderDetail,
  handleChangeSelectDetail,
  processes,
  packageDetails,
  editMode,
  packageTotal,
  work_order_package_discount,
  handleChange,
}) => {
  return (
    <DetailsContainer>
      <div className="container-package-discount">
        <Table>
          <thead>
            <tr>
              <th>Descuento Paquete (%)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <FormControl
                  type="text"
                  name="work_order_package_discount"
                  value={work_order_package_discount || ''}
                  placeholder="Descuento"
                  onChange={(e) => handleChange(e)}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="container-package-total">
        <Table>
          <thead>
            <tr>
              <th>#</th>
              <th>Proceso</th>
              <th>Precio proceso</th>
              <th>Tiempo estimado</th>
            </tr>
          </thead>
          <tbody>
            <PackageDetails
              packageDetails={packageDetails}
              processes={processes}
            />
            <tr>
              <td colSpan="2">
                <b>Sub-total: </b>
              </td>
              <td>
                <b>
                  {getPackageDetailsTotal(
                    packageDetails,
                    false,
                    packageTotal,
                    work_order_package_discount
                  )}
                </b>
              </td>
              <td>
                <b>{getFormatTime(getPackageDetailsTimeTotal(packageDetails))}</b>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      {((editMode && !_.isEmpty(workOrderDetails)) || !editMode) && (
        <>
          <h3>Repuestos y servicios adicionales</h3>
          <div style={{ textAlign: 'right', marginBottom: '10px' }}>
            <span style={{ fontSize: '12px' }}>
              Para una búsqueda más exacta puede ir a al Inventario y copiar el
              código del repuesto que deseas agregar
            </span>
            <br />
            <Button
              onClick={() => {
                window.open(
                  '/products',
                  '_blank' // <- This is what makes it open in a new window.
                )
              }} // no refresh page
              bsStyle="success"
              className="btn-add btn-antd-icon"
            >
              <EyeFilled />
              Ir al Inventario
            </Button>
          </div>
          <Table className="compact">
            <tbody>
              {_.map(workOrderDetails, (detail, key) => {
                if (!detail.isDeleted) {
                  return (
                    <React.Fragment key={key}>
                      <React.StrictMode>
                        <WorkOrderDetail
                          detail={detail}
                          packageDetails={packageDetails}
                          workOrderDetails={workOrderDetails}
                          products={products}
                          processes={processes}
                          handleChangeDetail={handleChangeDetail}
                          handleChangeSelectDetail={handleChangeSelectDetail}
                          key={key}
                          id={key}
                          deleteWorkOrderDetail={deleteWorkOrderDetail}
                          editMode={editMode}
                        />
                      </React.StrictMode>
                    </React.Fragment>
                  )
                }
                return null
              })}
            </tbody>
          </Table>
        </>
      )}
      {!editMode && (
        <div className="container-add-aditional-buttons">
          <Button onClick={() => addWorkOrderDetail('product')} bsStyle="success" className='btn-antd-icon'>
            <PlusCircleFilled />
            Agregar repuesto
          </Button>
          <Button onClick={() => addWorkOrderDetail('service')} bsStyle="success" className='btn-antd-icon'>
            <PlusCircleFilled />
            Agregar servicio
          </Button>
        </div>
      )}

      <div className="container-aditional-subtotal">
        <p>
          Sub-total servicios adicionales:{' '}
          <b>{getWorkOrderTotal(workOrderDetails)}</b>
        </p>
        <p>
          Sub-total tiempo: <b>{getFormatTime(getTotalTime(workOrderDetails))}</b>
        </p>
      </div>

      <h3>Totales</h3>
      <div className="container-final-total">
        <Alert bsStyle="info">
          <p>
            Tiempo total:{' '}
            <b>
              {getFormatTime(
                parseInt(getPackageDetailsTimeTotal(packageDetails), 10) +
                parseInt(getTotalTime(workOrderDetails), 10)
              )}{' '}
            </b>
          </p>
          <hr />
          <p>
            Sub-total paquete:{' '}
            <b>{`${getTotalOrder(
              packageTotal,
              '0',
              work_order_package_discount
            )}`}</b>
          </p>
          <p>
            Sub-total adicionales: <b>{getWorkOrderTotal(workOrderDetails)}</b>
          </p>
          <hr />
          <p>
            Total de presupuesto:{' '}
            <b>
              {getTotalOrder(
                packageTotal,
                getWorkOrderTotal(workOrderDetails),
                work_order_package_discount
              )}
            </b>
          </p>
        </Alert>
      </div>
    </DetailsContainer>
  )
}

WorkOrderDetails.propTypes = {
  editMode: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleChangeSelectDetail: PropTypes.func.isRequired,
  addWorkOrderDetail: PropTypes.func.isRequired,
  deleteWorkOrderDetail: PropTypes.func.isRequired,
  handleChangeDetail: PropTypes.func.isRequired,
  workOrderDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  products: PropTypes.objectOf(PropTypes.any).isRequired,
  processes: PropTypes.objectOf(PropTypes.any).isRequired,
  packageDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  packageTotal: PropTypes.number.isRequired,
  work_order_package_discount: PropTypes.number.isRequired,
}

const DetailsContainer = styled.div`
  .container-package-discount,
  .container-package-total,
  .container-aditional-subtotal,
  .container-final-total {
    border: 1px solid #ddd;
    border-radius: 10px;
  }

  .container-package-discount {
    margin-bottom: 2rem;
  }

  .container-package-total {
    margin-bottom: 6rem;
  }

  .container-add-aditional-buttons {
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;

    button {
      border-radius: 4px !important;

      &:nth-child(1) {
        margin-right: 1.5rem;
      }
    }
  }

  .container-aditional-subtotal,
  .container-final-total {
    padding: 1rem;
  }

  .container-aditional-subtotal {
    margin-bottom: 5rem;
  }

  table.compact {
    margin-top: 2rem;

    tbody {
      tr {
        margin-bottom: 4rem;
        border: 1px solid #9b9a9a;
        border-radius: 10px;
        display: flex;
        flex-direction: column;

        td {
          border: 0;
          width: 100%;
        }

        td:nth-child(1) {
          text-align: center;
          font-weight: bold;
        }
      }
    }
  }
`

export default WorkOrderDetails
