import React, { Component } from 'react'
import { connect } from 'react-redux'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import { Panel, Grid, Row, Col, Button } from 'react-bootstrap'
import _ from 'lodash'
import ReactToolTip from 'react-tooltip'
import { NotificationManager } from 'react-notifications'
import PropTypes from 'prop-types'
import { MenuOutlined, EditFilled, EyeFilled, DeleteFilled, PlusCircleFilled } from '@ant-design/icons';
import {
  formatterHeaderStyle,
  columnStyle,
  columnHeaderStyle,
} from '../../../Utilities/TableStyles'
import {
  getItems,
  addItem,
  updateItem,
  deleteItem,
} from '../../../../actions/ItemAction'
import deleteRegister from '../../deleteRegister'
import ItemModal from '../../DynamicBasicModal'
import { PanelHeader } from '../../Common'
import { AppLoader } from '../../Common/AppLoader'

const SCREEN_ID = 13

const styleIcons = {
  fontSize: '17px',
}


class Items extends Component {
  state = {
    items: [],
    show: false,
    loading: true,
    editMode: false,
    complements: {
      title: ' Item',
      label: 'Nombre del item',
      registerValue: '',
      registerValueId: '',
      fieldName: 'item_name',
      placeholder: 'Ingresar',
      questionToUpdate: (message) => `¿Desea modificar el item '${message}'?`,
      messageOnDeleted: (name) => `Item: '${name}' eliminado`,
      messageToDelete: (name) => `¿Desea eliminar el item '${name}'?`,
    },
  }

  UNSAFE_componentWillMount() {
    const { getAllItems } = this.props
    getAllItems()
      .then(() => {
        const { items } = this.props
        this.setState(
          {
            items: _.values(items).reverse(),
          },
          () => {
            this.setState({
              loading: false,
            })
          }
        )
      })
      .catch((error) => {
        NotificationManager.error(error, 'Error')
        this.setState({
          loading: false,
        })
      })
  }

  componentDidUpdate(prevProps) {
    const { items } = this.props
    const { items: prevItems } = prevProps

    if (items !== prevItems) {
      this.setItemsState()
    }
  }

  componentWillUnmount() {
    this.mounted = false
  }

  setItemsState() {
    const { items } = this.props

    this.setState({
      items: _.values(items).reverse(),
    })
  }

  getInitialState = () => ({
    items: [],
    show: false,
    loading: true,
    editMode: false,
    complements: {
      title: ' Item',
      label: 'Nombre del item',
      registerValue: '',
      registerValueId: '',
      fieldName: 'item_name',
      placeholder: 'Ingresar',
      questionToUpdate: (message) => `¿Desea modificar el item '${message}'?`,
      messageOnDeleted: (name) => `Item: '${name}' eliminado`,
      messageToDelete: (name) => `¿Desea eliminar el item '${name}'?`,
    },
  })

  toggleModal = () => {
    const { show } = this.state
    this.setState({ show: !show })
  }

  addItem = () => {
    this.setState(
      {
        complements: { ...this.getInitialState().complements },
        editMode: false,
      },
      this.toggleModal
    )
  }

  editItem = (row) => {
    const { complements } = this.state
    const newComplements = {
      ...complements,
      registerValue: row.item_name,
      registerValueId: row.item_id,
    }
    this.setState(
      {
        complements: {
          ...complements,
          ...newComplements,
        },
        editMode: true,
      },
      this.toggleModal
    )
  }

  deleteItem(row) {
    const { removeItem } = this.props
    const { complements } = this.state
    const source = {
      row: {
        id: row.item_id,
        name: row.item_name,
      },
      messageToDelete: (name) => complements.messageToDelete(name),
      messageOnDeleted: (name) => complements.messageOnDeleted(name),
      delete: (item) => removeItem(item),
    }
    deleteRegister(source)
  }

  render() {
    const { permissions, createItem, modifyItem } = this.props
    const { complements, loading, items, show, editMode } = this.state
    const { can_write, can_edit, can_delete } = permissions[SCREEN_ID]

    const deleteButton = (cell, row) => (
      <Button
        onClick={() => this.deleteItem(row)}
        bsStyle="link"
        bsSize="small"
        style={{ color: 'red' }}
        data-tip="Eliminar"
      >
        <DeleteFilled style={styleIcons} />
        <ReactToolTip />
      </Button>
    )

    const columns = [
      {
        dataField: 'item_name',
        text: 'Nombre',
        style: columnStyle,
        headerStyle: columnHeaderStyle,
        sort: true,
        filter: textFilter({
          delay: 100,
          style: {
            margin: '10px 0 0 0',
            padding: '20px 2%',
          },
          placeholder: 'Buscar',
        }),
      },
      {
        dataField: 'id',
        className: 'test',
        text: '',
        headerStyle: formatterHeaderStyle,
        style: columnHeaderStyle({ textAlign: 'center' }),
        formatter: (cell, row) => (
          <Button
            onClick={() => this.editItem(row)}
            bsStyle="link"
            bsSize="small"
            data-tip={can_edit ? 'Modificar' : 'Ver'}
          >
            {can_edit ? <EditFilled style={styleIcons} /> : <EyeFilled style={styleIcons} />}
            <ReactToolTip />
          </Button>
        ),
      },
    ]
    if (can_delete) {
      columns.push({
        dataField: 'id2',
        text: '',
        headerStyle: formatterHeaderStyle,
        style: columnHeaderStyle({ textAlign: 'center' }),
        formatter: deleteButton,
      })
    }
    const pagePermissions = {
      can_write,
      can_edit,
      can_delete,
    }
    return (
      <div className="component-content">
        <div className="content-header">
          <div className="header-section">
            <h1>
              <i className="fas fa-share-alt" />
              Items
              <br />
            </h1>
          </div>
        </div>
        <div id="body-content">
          <Grid fluid>
            <Row>
              <Col sm={12} xs={12} md={12}>
                <Panel>
                  <Panel.Heading bsClass="panel-heading">
                    <Panel.Title>
                      <PanelHeader>
                        <div>
                          <p id="title">
                            <MenuOutlined style={{ marginRight: '7px' }} /> Administrar Items
                          </p>
                        </div>
                        <div>
                          {!!can_write && (
                            <Button
                              onClick={this.addItem}
                              bsStyle="success"
                              className="btn-add btn-antd-icon"
                            >
                              <PlusCircleFilled />
                              Agregar registro
                            </Button>
                          )}
                        </div>
                      </PanelHeader>
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    {loading ? (
                      <AppLoader />
                    ) : (
                      <Row>
                        <Col md={10} />
                        <Col md={12}>
                          <BootstrapTable
                            noDataIndication="Sin datos para mostrar"
                            keyField="item_id"
                            data={items}
                            columns={columns}
                            filter={filterFactory()}
                            pagination={paginationFactory()}
                          />
                        </Col>
                      </Row>
                    )}
                  </Panel.Body>
                </Panel>
              </Col>
            </Row>
          </Grid>
          <ItemModal
            modalState={show}
            handleClose={this.toggleModal}
            editMode={editMode}
            complements={complements}
            insert={(model) => createItem(model)}
            update={(model) => modifyItem(model)}
            pagePermissions={pagePermissions}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (store) => ({
  items: store.ItemReducer.items,
  permissions: store.AppReducer.permissions,
})

const mapDispatchToProps = (dispatch) => ({
  getAllItems() {
    return dispatch(getItems())
  },
  createItem(item) {
    return dispatch(addItem(item))
  },
  modifyItem(item) {
    return dispatch(updateItem(item))
  },
  removeItem(item) {
    return dispatch(deleteItem(item))
  },
})

Items.propTypes = {
  permissions: PropTypes.objectOf(PropTypes.object).isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  removeItem: PropTypes.func.isRequired,
  getAllItems: PropTypes.func.isRequired,
  createItem: PropTypes.func.isRequired,
  modifyItem: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Items)
