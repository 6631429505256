import React, { Component } from 'react'
import {
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Col,
  Button,
  Row,
  Form,
} from 'react-bootstrap'
import _ from 'lodash'
import Select from 'react-select'
import MultiSelect from "@khanacademy/react-multi-select";
import moment from 'moment'
import PropTypes from 'prop-types'
import { SaveOutlined } from '@ant-design/icons'
import { getValidationState, showConfirmDialog } from '../../Common'
import { roundNumberTwoDecimals } from '../../../../Helpers'

// import Html5QrcodePlugin from '../../../Utilities/Html5QrcodePlugin';

class ProductModal extends Component {
  state = {
    product_name: '',
    product_price: '',
    product_quantity: '',
    product_expire_date: moment(),
    product_guarantee: '',
    product_bar_code: '',
    product_cost: '',
    category_id: '',
    provider_id: '',
    rack: '',
    loading: false,
    selected: [],
  }

  /* constructor(props) {
    super(props);
    //this.onNewScanResult = this.onNewScanResult.bind(this);
  } */

  /* onNewScanResult(decodedText, decodedResult) {
    this.setState({
      product_bar_code: decodedText
    })
  } */

  componentDidUpdate = (prevProps) => {
    const { selectedProduct } = this.props
    const { selectedProduct: prevSelectProduct } = prevProps

    if (selectedProduct !== prevSelectProduct) {
      this.setProductModalState()
    }
  }

  setProductModalState() {
    const { selectedProduct } = this.props
    this.setState({
      product_name: selectedProduct.product_name,
      product_price: (selectedProduct.product_price) ? selectedProduct.product_price.replace('$', '') : selectedProduct.product_price,
      product_quantity: selectedProduct.product_quantity,
      product_expire_date: moment(selectedProduct.product_expire_date),
      product_guarantee: selectedProduct.product_guarantee,
      product_cost: selectedProduct.product_cost,
      product_bar_code: selectedProduct.product_bar_code,
      category_id: selectedProduct.category_id,
      provider_id: selectedProduct.provider_id,
      rack: selectedProduct.rack,
      selected: (selectedProduct.models) ? JSON.parse(selectedProduct.models).map(a => a.value) : [],
      loading: false,
    })
  }

  clearComponent = () => {
    this.setState({
      product_name: '',
      product_price: '',
      product_bar_code: '',
      product_quantity: '',
      product_expire_date: moment(),
      product_guarantee: '',
      product_cost: '',
      category_id: '',
      rack: '',
      loading: false,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const {
      selectedProduct,
      toggleModal,
      updateProduct,
      editMode,
      addProduct,
    } = this.props

    toggleModal()

    const {
      product_name,
      product_price,
      product_quantity,
      product_expire_date,
      product_guarantee,
      product_bar_code,
      product_cost,
      category_id,
      provider_id,
      rack
    } = this.state

    const { models } = this.props
    const { selected } = this.state
    const modelsJson = models.filter((x) => {
      return selected.includes(x.value)
    })
    const metadata = (`${product_name} ${JSON.stringify(modelsJson.map(a => a.label))} ${product_bar_code}`).toLowerCase()

    const product = {
      product_id: selectedProduct.product_id || null,
      product_name,
      product_bar_code,
      product_price: roundNumberTwoDecimals(parseFloat(product_price)),
      product_quantity: parseInt(product_quantity, 10),
      // eslint-disable-next-line no-underscore-dangle
      product_expire_date: product_expire_date._d,
      product_guarantee,
      product_cost: parseFloat(product_cost),
      category_id: parseInt(category_id, 10),
      rack,
      // model_id: parseInt(model_id, 10),
      product_active: 1,
      models: JSON.stringify(modelsJson),
      provider_id: parseInt(provider_id, 10),
      metadata,
    }

    if (editMode) {
      showConfirmDialog(
        '¿Modificar?',
        `¿Modificar el repuesto: ${product.product_name}`,
        updateProduct,
        product,
        () => { },
        toggleModal,
        toggleModal
      )
    } else {
      addProduct(product)
        .then(() => {
          this.clearComponent()
        })
        .catch(() => {
          this.setState({ loading: false })
          toggleModal()
        })
    }
  }

  handleChangeSelect = (value, field) => {
    if (value) {
      this.setState({ [field]: value[`${field}`] })
    } else {
      this.setState({ [field]: null })
    }
  }

  handleChange = (e, type) => {
    const { name } = e.target
    let { value } = e.target
    if (type === 'double') {
      value = value.replace(/[^0-9.]/g, '')
    } else if (type === 'integer') {
      value = value.replace(/[^0-9]/g, '')
    }
    this.setState({
      [name]: value,
    })
  }

  handleChangeDate = (value) => {
    this.setState({
      product_expire_date: value,
    })
  }

  render() {
    const { editMode, modalOpen, toggleModal, categories, models, pagePermissions, providers } =
      this.props
    const {
      product_name,
      product_bar_code,
      product_price,
      product_quantity,
      product_expire_date,
      product_guarantee,
      product_cost,
      category_id,
      provider_id,
      rack,
      loading,
    } = this.state
    let can_edit
    if (pagePermissions) {
      ; ({ can_edit } = pagePermissions)
    }

    const { selected } = this.state;
    return (
      <Modal bsSize="lg" show={modalOpen} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode ? 'Modificar ' : 'Agregar '}
            Repuesto
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.handleSubmit}>
            <Row>
              <Col md={6}>
                <FormGroup validationState={getValidationState(product_name)}>
                  <ControlLabel>Nombre: </ControlLabel>
                  <FormControl
                    name="product_name"
                    type="text"
                    value={product_name}
                    onChange={this.handleChange}
                    required
                    placeholder="Ingresar"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup
                  validationState={getValidationState(category_id, true)}
                >
                  <ControlLabel>Categoría: </ControlLabel>
                  <Select
                    placeholder="Categoría"
                    options={_.values(categories)}
                    labelKey="category_name"
                    valueKey="category_id"
                    value={category_id}
                    onChange={(value) => {
                      this.handleChangeSelect(value, 'category_id')
                    }}
                    required
                  />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup
                  validationState={getValidationState(provider_id, true)}
                >
                  <ControlLabel>Proveedor: </ControlLabel>
                  <Select
                    placeholder="Proveedor"
                    options={_.values(providers)}
                    labelKey="provider_name"
                    valueKey="provider_id"
                    value={provider_id}
                    onChange={(value) => {
                      this.handleChangeSelect(value, 'provider_id')
                    }}
                    required
                  />
                </FormGroup>
                <FormGroup validationState={getValidationState(product_price)}>
                  <ControlLabel>Precio de venta: </ControlLabel>
                  <FormControl
                    name="product_price"
                    type="text"
                    value={product_price}
                    onChange={(e) => {
                      this.handleChange(e, 'double')
                    }}
                    placeholder="$"
                    required
                  />
                </FormGroup>
                <FormGroup
                  validationState={getValidationState(product_guarantee)}
                >
                  <ControlLabel>Garantía (en meses): </ControlLabel>
                  <FormControl
                    name="product_guarantee"
                    type="text"
                    value={product_guarantee}
                    onChange={this.handleChange}
                    required
                    placeholder="Ingresar"
                  />
                </FormGroup>
                <FormGroup
                  validationState={getValidationState(selected, true)}
                >
                  <ControlLabel>Modelos compatibles: </ControlLabel>
                  <MultiSelect
                    placeholder="Modelos"
                    options={_.values(models)}
                    selected={selected}
                    onSelectedChanged={selectedModel => {
                      this.setState({ selected: selectedModel })
                    }}
                    renderHeader={false}
                    overrideStrings={{
                      selectSomeItems: "Selecciona los modelos compatibles",
                      allItemsAreSelected: "Todos los elementos seleccionados",
                      selectAll: "Seleccionar todos",
                      search: "Buscar modelos compatibles",
                    }}
                  />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup
                  validationState={getValidationState(product_quantity)}
                >
                  <ControlLabel>Cantidad: </ControlLabel>
                  <FormControl
                    name="product_quantity"
                    type="text"
                    value={product_quantity}
                    onChange={(e) => {
                      this.handleChange(e, 'integer')
                    }}
                    required
                    placeholder="###"
                    disabled={editMode}
                  />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup validationState={getValidationState(product_cost)}>
                  <ControlLabel>Costo: </ControlLabel>
                  <FormControl
                    name="product_cost"
                    type="text"
                    value={product_cost}
                    onChange={(e) => {
                      this.handleChange(e, 'double')
                    }}
                    placeholder="$"
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup
                  validationState={getValidationState(
                    product_expire_date,
                    true
                  )}
                >
                  <ControlLabel>Anaquel: </ControlLabel>
                  <br />
                  <FormControl
                    name="rack"
                    type="text"
                    value={rack}
                    onChange={this.handleChange}
                    placeholder="Anaquel"

                  />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup
                  validationState={getValidationState(product_bar_code)}
                >
                  <ControlLabel>Código: </ControlLabel>
                  <FormControl
                    name="product_bar_code"
                    type="text"
                    value={product_bar_code || ''}
                    onChange={this.handleChange}
                    required
                    placeholder="#######"
                  />
                </FormGroup>

                {/* <Html5QrcodePlugin
                  fps={10}
                  qrbox={250}
                  disableFlip={false}
                  qrCodeSuccessCallback={this.onNewScanResult} /> */}

              </Col>

            </Row>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button type="submit" hidden id="submitCategoryForm" />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={toggleModal}>Cerrar</Button>
          {!!can_edit && (
            <Button
              disabled={loading}
              bsStyle="primary"
              onClick={() => {
                document.getElementById('submitCategoryForm').click()
              }}
              className="btn-antd-icon"
            >
              <SaveOutlined />
              {editMode ? ' Modificar' : ' Agregar'}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    )
  }
}

ProductModal.propTypes = {
  editMode: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  addProduct: PropTypes.func.isRequired,
  updateProduct: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  models: PropTypes.arrayOf(PropTypes.object).isRequired,
  providers: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedProduct: PropTypes.objectOf(PropTypes.string).isRequired,
  pagePermissions: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default ProductModal
