import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, Select, Spin } from 'antd'
import { SearchBarWrapper, SearchLabel } from '../SearchBar/SearchBar.style'
import SelectFooterButton from '../../App/Common/SelectFooterButton/SelectFooterButton'
import { AvatarWrapper } from './SearchWithAvatar.styles'

const SearchWithAvatar = ({
  id,
  items, // Array of {value: '', label: '', src:''} objects for select
  value,
  isLoading,
  searchValue,
  addItemText,
  label,
  defaultValue,
  placeholder,
  handleValueChange,
  handleSearch,
  handleOnAdd,
}) => {
  const renderMessage = () => {
    if (searchValue && !items.length > 0) {
      return isLoading ? (
        <Spin
          size="small"
          style={{
            margin: 'auto',
            display: 'flex',
            justifyContent: 'center',
          }}
        />
      ) : (
        <p
          className="no-results"
          style={{ padding: '6px 12px', color: '#929292' }}
        >
          No se encontraron resultados
        </p>
      )
    }
    return null
  }
  return (
    <div>
      {label && <SearchLabel htmlFor={id}>{label}</SearchLabel>}
      <SearchBarWrapper>
        <i className="fas fa-search" />
        <Select
          showSearch={!value}
          id={id}
          defaultValue={defaultValue}
          value={value}
          size="large"
          suffixIcon={null}
          loading
          labelInValue
          filterOption={false}
          onSearch={handleSearch}
          placeholder={placeholder}
          onChange={handleValueChange}
          defaultActiveFirstOption={false}
          allowClear
          placement="bottomLeft"
          style={{ width: '100%' }}
          notFoundContent={renderMessage()}
          listHeight="20vh"
          optionLabelProp="label"
          dropdownRender={(menu) => (
            <>
              {menu}
              {addItemText && (
                <SelectFooterButton
                  buttonText={addItemText}
                  onClickHandler={handleOnAdd}
                />
              )}
            </>
          )}
        >
          {items.map((item) => (
            <Select.Option key={item?.value} value={item?.value} label={item?.label}>
              <AvatarWrapper>
                <Avatar src={item?.src} />
                <p>{item?.label}</p>
              </AvatarWrapper>
            </Select.Option>
          ))}
        </Select>
      </SearchBarWrapper>
    </div>
  )
}

SearchWithAvatar.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  value: PropTypes.any,
  searchValue: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  addItemText: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.object,
  label: PropTypes.string,
  handleValueChange: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleOnAdd: PropTypes.func,
}

SearchWithAvatar.defaultProps = {
  addItemText: '',
  isLoading: false,
  defaultValue: undefined,
  placeholder: '¿Qué necesitas buscar?',
  label: '',
  value: undefined,
  handleOnAdd: () => true,
}

export default SearchWithAvatar
