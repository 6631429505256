import { GET_COUNTRIES } from '../actionTypes/countryTypes'

const CountryReducer = (
  state = {
    countries: {},
  },
  action
) => {
  if (action.type === GET_COUNTRIES) {
    return {
      ...state,
      countries: action.payload,
    }
  }
  return state
}

export default CountryReducer
