import React, { Component } from 'react'
import { connect } from 'react-redux'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory from 'react-bootstrap-table2-filter'
import { Panel, Grid, Row, Col, Button } from 'react-bootstrap'
import _ from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { MenuOutlined, PlusCircleFilled } from '@ant-design/icons';
import { getContributors } from '../../../actions/ContributorTypesAction'
import getFullName from './personFunctions'
import { getStates } from '../../../actions/StateAction'
import { getCities } from '../../../actions/CityAction'
import {
  Container,
  ContainerHeader,
  BodyContent,
  showConfirmDialog,
  PanelHeader,
} from '../Common/index'
import PersonModal from './PersonModal'
import getColumns from './columns'
import {
  getPersons,
  addPerson,
  deletePerson,
  deletePersonState,
  updatePerson,
  getPersonsToSync,
  syncPersonWorkshop,
} from '../../../actions/PersonAction'
import { BREAK_POINTS_DESKTOP_FIRST } from '../../../global/css/breakPoints'
import './PersonFormSteps.css'
import { AppLoader } from '../Common/AppLoader'

// SCREEN ID FOR PERMISSIONS
const SCREEN_ID = 20

class PersonContainer extends Component {
  state = {
    loading: true,
    selectedPerson: {},
    showModal: false,
    showSynModal: false,
    editMode: false,
  }

  componentDidMount() {
    const { getAllPersons, getContributorTypes, getAllStates, getAllCities } =
      this.props
    window.addEventListener('resize', this.updateDimensions)
    getAllPersons()
      .then(() => {
        this.setState({ loading: false })
        getContributorTypes()
        getAllStates()
        getAllCities()
      })
      .catch(() => {
        this.setState({ loading: false })
      })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  updateDimensions = () => {
    const { width } = window.screen
    this.setState({ width })
  }

  toggleModal = () => {
    const { showModal } = this.state
    this.setState({
      showModal: !showModal,
    })
  }

  openModal = () => {
    const { showModal } = this.state
    this.setState({
      showModal: !showModal,
    })
  }

  showSynModal = () => {
    const { showSynModal } = this.state
    this.setState({ showSynModal: !showSynModal })
  }

  addPerson = () => {
    this.setState(
      { selectedPerson: { person_id: '' }, editMode: false },
      this.openModal
    )
  }

  updatePerson = (person) => {
    this.setState({ selectedPerson: person, editMode: true }, this.openModal)
  }

  deletePerson = (person) => {
    const { removePerson } = this.props
    showConfirmDialog(
      'Eliminar',
      `¿Eliminar el cliente ${person.person_firstname} ${person.person_lastname || ''
      }?`,
      removePerson,
      person,
      null,
      null,
      null,
      person
    )
  }

  render() {
    const {
      persons,
      permissions,
      contributorTypes,
      states,
      cities,
      contributors,
      createPerson,
      modifyPerson,
      getPersonsToSyncAction,
      syncPersonWorkshopAction,
    } = this.props
    const { loading, showModal, selectedPerson, width, editMode } = this.state
    const { can_write, can_edit, can_delete } = permissions[SCREEN_ID]
    const pagePermissions = { can_write, can_edit, can_delete }
    return (
      <Container>
        <ContainerHeader title="Clientes" />
        <BodyContent>
          <Grid fluid>
            <Row>
              <Col sm={12}>
                <Panel>
                  <Panel.Heading bsClass="panel-heading">
                    <Panel.Title>
                      <PanelHeader>
                        <div>
                          <p id="title">
                            <MenuOutlined style={{ marginRight: '7px' }} /> Administrar Clientes
                          </p>
                        </div>
                        <div>
                          {!!can_write && (
                            <Button
                              onClick={this.addPerson}
                              bsStyle="success"
                              className="btn-add btn-antd-icon"
                            >
                              <PlusCircleFilled />
                              Agregar registro
                            </Button>
                          )}
                        </div>
                      </PanelHeader>
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    {loading ? (
                      <AppLoader />
                    ) : (
                      <Row>
                        <Col md={10} />
                        <Col md={12}>
                          <CustomStyledTable>
                            <BootstrapTable
                              noDataIndication="Sin datos para mostrar"
                              keyField="person_id"
                              order
                              data={getFullName(
                                _.size(persons) > 0
                                  ? _.values(persons)
                                    .map((person) => person)
                                    .reverse()
                                  : []
                              )}
                              columns={getColumns(
                                this.updatePerson,
                                this.deletePerson,
                                { can_edit, can_delete },
                                width
                              )}
                              filter={filterFactory()}
                              pagination={paginationFactory()}
                            />
                          </CustomStyledTable>
                        </Col>
                      </Row>
                    )}
                  </Panel.Body>
                </Panel>
              </Col>
            </Row>
          </Grid>
          <PersonModal
            modalOpen={showModal}
            toggleModal={this.toggleModal}
            editMode={editMode}
            addPerson={createPerson}
            updatePerson={modifyPerson}
            selectedPerson={selectedPerson}
            states={states}
            cities={cities}
            contributors={contributors}
            pagePermissions={pagePermissions}
            contributorTypes={contributorTypes}
            getPersonsToSync={getPersonsToSyncAction}
            syncPersonWorkshop={syncPersonWorkshopAction}
          />
        </BodyContent>
      </Container>
    )
  }
}

const CustomStyledTable = styled.div`
  @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
    table {
      border: none;

      thead {
        tr {
          display: flex;
          flex-direction: column;

          th {
            label {
              width: 100%;
            }
          }

          th {
            border: none;
          }

          th:nth-last-child(1),
          th:nth-last-child(2) {
            display: none;
          }
        }
      }

      tbody {
        tr {
          border: 1px solid #ddd;
          display: flex;
          flex-wrap: wrap;
          border-radius: 10px;
          margin: 20px 0px;
          padding-top: 10px;
          padding-bottom: 10px;

          td {
            width: 100%;
            border: 0;
            border-right: 0px !important;
            border-left: 0px !important;
          }

          td:nth-last-child(1),
          td:nth-last-child(2) {
            width: 40%;
          }

          td::before {
            font-weight: bold;
            margin-right: 0.5rem;
          }
          td:nth-child(1)::before {
            content: 'País:';
          }

          td:nth-child(2)::before {
            content: 'Nombre:';
          }

          td:nth-child(3)::before {
            content: 'Teléfono:';
          }
        }
      }
    }
  }
`

const mapStateToProps = (store) => ({
  persons: store.PersonReducer.persons,
  contributors: store.ContributorTypeReducer.contributorTypes,
  states: store.StateReducer.states,
  cities: store.CityReducer.cities,
  permissions: store.AppReducer.permissions,
  contributorTypes: store.ContributorTypeReducer.contributorTypes,
  workshop_id: store.AppReducer.currentUser.workshop_id
})

const mapDispatchToProps = (dispatch) => ({
  getAllPersons() {
    return dispatch(getPersons())
  },

  getContributorTypes() {
    return dispatch(getContributors())
  },

  getAllStates() {
    return dispatch(getStates())
  },

  getAllCities() {
    return dispatch(getCities())
  },

  getPersonsToSyncAction(query) {
    return dispatch(getPersonsToSync(query))
  },

  createPerson(person, contacts) {
    return dispatch(addPerson(person, contacts, true))
  },

  removePerson(person) {
    return dispatch(deletePerson(person))
  },

  removePersonState(person) {
    return dispatch(deletePersonState(person))
  },

  modifyPerson(person, contacts) {
    return dispatch(updatePerson(person, contacts))
  },

  syncPersonWorkshopAction(person) {
    return dispatch(syncPersonWorkshop(person))
  },
})

PersonContainer.propTypes = {
  persons: PropTypes.arrayOf(PropTypes.object).isRequired,
  states: PropTypes.arrayOf(PropTypes.object).isRequired,
  cities: PropTypes.arrayOf(PropTypes.object).isRequired,
  contributors: PropTypes.arrayOf(PropTypes.object).isRequired,
  contributorTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  permissions: PropTypes.objectOf(PropTypes.bool).isRequired,
  createPerson: PropTypes.func.isRequired,
  modifyPerson: PropTypes.func.isRequired,
  getPersonsToSyncAction: PropTypes.func.isRequired,
  syncPersonWorkshopAction: PropTypes.func.isRequired,
  removePerson: PropTypes.func.isRequired,
  getAllPersons: PropTypes.func.isRequired,
  getContributorTypes: PropTypes.func.isRequired,
  getAllStates: PropTypes.func.isRequired,
  getAllCities: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonContainer)
