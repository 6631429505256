import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-bootstrap'

import RowTableNoData from './Components/RowTableNoData'

const TopTenSectionComponent = ({ title, data, active }) => {
  const titleModified = `${title}`

  const { topTenProcess = [] } = data
  const { topTenProducts = [] } = data
  const { topTenVehicles = [] } = data
  if(active){
  return (
    <div className="dashboard-section-container margin-top">
      <h1 className="dashboard-section-title">{titleModified}</h1>
      <Row>
        <Col lg={4} md={4} sm={12}>
          <h3 className="dashboard-section-subtitle top-ten">
            Top 10 Servicios
          </h3>
          <table className="dashboard-table top-ten">
            <thead>
              <tr>
                <th>Nombre</th>
                <th className="center">Cantidad</th>
                <th className="center">Precio</th>
              </tr>
            </thead>
            <tbody>
              {topTenProcess.length === 0 && (
                <RowTableNoData text="No existen datos para mostrar" />
              )}
              {topTenProcess.map((p, _index) => (
                <tr key={`${p.process_name}-${_index + 1}`}>
                  <td>{p.process_name}</td>
                  <td className="center">{p.quantity}</td>
                  <td className="center">${p.process_price}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
        <Col lg={4} md={4} sm={12}>
          <h3 className="dashboard-section-subtitle top-ten">
            Top 10 Repuestos
          </h3>
          <table className="dashboard-table top-ten">
            <thead>
              <tr>
                <th>Nombre</th>
                <th className="center">Cantidad</th>
                <th className="center">Precio</th>
              </tr>
            </thead>
            <tbody>
              {topTenProducts.length === 0 && (
                <RowTableNoData text="No existen datos para mostrar" />
              )}
              {topTenProducts.map((p, _index) => (
                <tr key={`${p.product_name}-${_index + 1}`}>
                  <td>{p.product_name}</td>
                  <td className="center">{p.quantity}</td>
                  <td className="center">${p.product_price}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
        <Col lg={4} md={4} sm={12}>
          <h3 className="dashboard-section-subtitle top-ten">
            Top 10 Vehículos
          </h3>
          <table className="dashboard-table top-ten">
            <thead>
              <tr>
                <th>Modelo</th>
                <th className="center">Marca</th>
                <th className="center">Cantidad</th>
              </tr>
            </thead>
            <tbody>
              {topTenVehicles.length === 0 && (
                <RowTableNoData text="No existen datos para mostrar" />
              )}
              {topTenVehicles.map((v, _index) => (
                <tr key={`${v.model_name}-${_index + 1}`}>
                  <td>{v.model_name}</td>
                  <td>{v.brand_name}</td>
                  <td className="center">{v.quantity}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      </Row>
    </div>
  )}
  return(<></>)
}

TopTenSectionComponent.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  active: PropTypes.number.isRequired,
}

export default TopTenSectionComponent
