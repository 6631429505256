import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'react-bootstrap'

const PrintModal = ({
  src,
  modalOpen,
  toggleModal,
  toggleModalCloseButton,
  invoiceWorkOrder,
  closeButton,
  backdrop,
  keyboard,
  displayFinishButton,
  invoiceId,
}) => (
  <>
    <Modal
      bsSize="lg"
      onHide={toggleModal}
      show={modalOpen}
      backdrop={backdrop}
      keyboard={keyboard}
    >
      <Modal.Header closeButton={closeButton}>
        <Modal.Title>Factura Generada</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <object id="invoice-pdf">
          <embed width="100%" height="600px" src={src} />
        </object>
      </Modal.Body>
      <Modal.Footer>
        <Button>
          <a href={src} download={`invoice-${invoiceId}.pdf`}>
            Descargar
          </a>
        </Button>
        {displayFinishButton && (
          <Button bsStyle="primary" onClick={invoiceWorkOrder}>
            <i className="fas fa-check" /> Finalizar
          </Button>
        )}

        <Button bsStyle="" onClick={toggleModalCloseButton}>
          {' '}
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  </>
)

PrintModal.propTypes = {
  src: PropTypes.string.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  closeButton: PropTypes.bool,
  backdrop: PropTypes.string || PropTypes.bool,
  keyboard: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
  invoiceWorkOrder: PropTypes.func.isRequired,
  toggleModalCloseButton: PropTypes.func.isRequired,
  displayFinishButton: PropTypes.bool,
  invoiceId: PropTypes.number.isRequired,
}

PrintModal.defaultProps = {
  closeButton: false,
  backdrop: 'static',
  keyboard: false,
  displayFinishButton: true,
}

export default PrintModal
