import axios from 'axios'
import moment from 'moment'

import {
  GET_HISTORY,
  GET_SALES_HISTORY,
  CLEAR_HISTORY,
} from '../actionTypes/HistoryActionTypes'
import { NotificationHandler } from '../Helpers'

const getHistoryAction = (payload) => ({
  type: GET_HISTORY,
  payload,
})
const clearHistoryAction = () => ({
  type: CLEAR_HISTORY,
})

const getSalesHistoryAction = (payload) => ({
  type: GET_SALES_HISTORY,
  payload,
})

const getHistory =
  (client = '', placa = '', page = 1, size = 10, init = '', end = '') =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      axios
        .get(
          `/api/work_orders_history?client=${client.toLowerCase()}&placa=${placa.toLowerCase()}&pageSize=${size}&initialDate=${init}&finalDate=${end}&page=${page}`
        )
        .then((response) => {
          if (!response.data.error) {
            dispatch(getHistoryAction(response.data))
            resolve(response.data)
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al obtener el historial',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al obtener el historial',
            error
          )
        })
    })

const getSalesHistory =
  (
    query = '',
    sortBy = 'work_order_id',
    orderBy = 'desc',
    page = 1,
    size = 10,
    init,
    end
  ) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      let i = init
      let e = end
      if (init === undefined || init === null || init === '') {
        i = moment().subtract(30, 'days').format('YYYY-MM-DD')
      }

      if (end === undefined || end === null || end === '') {
        e = moment().format('YYYY-MM-DD')
      }

      axios
        .get(
          `/api/work_orders_history/sales?query=${query}&page=${page}&pageSize=${size}&init=${i}&end=${e}&sortBy=${sortBy}&orderBy=${orderBy}`
        )
        .then((response) => {
          if (!response.data.error) {
            dispatch(getSalesHistoryAction(response.data))
            resolve(response.data)
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al obtener el historial',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al obtener el historial',
            error
          )
        })
    })

// eslint-disable-next-line import/prefer-default-export
export { getHistory, getSalesHistory, clearHistoryAction }
