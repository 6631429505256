import {
  Grid,
  Row,
  Col,
  Panel,
  FormGroup,
  ControlLabel,
  FormControl,
} from 'react-bootstrap'
import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import {
  Container,
  BodyContent,
  PanelHeader,
  ContainerHeader,
} from '../Common/index'
import BootstrapTableWithDynamicPagination from '../Common/BootstrapTableWithDynamicPagination'
import { BREAK_POINTS_DESKTOP_FIRST } from '../../../global/css/breakPoints'

import getColumns from './Columns'
import { getHistory } from '../../../actions/HistoryAction'
import { getPackages } from '../../../actions/PackageAction'
import { getProducts } from '../../../actions/ProductActions'
import { getProcesses } from '../../../actions/ProcessAction'
import { getMechanics } from '../../../actions/EmployeeAction'
import { getReceptions } from '../../../actions/ReceptionAction'
import { addWorkOrder } from '../../../actions/WorkOrderAction'
import { getWorkOrderStatuses } from '../../../actions/WorkOrderStatusAction'
import { getServiceTypes } from '../../../actions/ServiceTypeAction'
import HistoryModal from './historyModal'
import ClaimModal from '../WorkOrderClaim/ClaimModal'
import PrintModal from '../Invoice/InvoiceForm/PrintModal'
import {
  printInvoicePDF,
  updateInvoice,
} from '../Invoice/InvoiceForm/InvoiceFunctions'
import printTicketPDF from '../Invoice/InvoiceForm/TicketFunctions'
import EvaluateModal from './evaluateModal'
import { AppLoader } from '../Common/AppLoader'
import DateRangePicker from '../Common/DateRangePicker/DateRangePicker'
import { HistoryFiltersWrapper } from './History.styles'
import { ClientsUIURL } from '../../../Constants'

const SCREEN_ID = 49

class HistoryContainer extends Component {
  state = {
    loading_data: false,
    showModal: false,
    showClaimModal: false,
    loading: true,
    selectedHistory: {},
    selectedWorkOrder: {},
    showModalInvoice: false,
    showModalEvaluate: false,
    pdfUrl: '',
    link: '',
    personPhone: '',
    client: '',
    placa: '',
    startDateFilter: '',
    size: 10,
    page: 1,
    endDateFilter: '',
    clearDateRange: false,
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions)
    const {
      size,
      startDateFilter = '',
      endDateFilter = '',
      client = '',
      placa = '',
      page,
    } = this.state

    const {
      getActualHistory,
      getAllMechanics,
      getServices,
      getAllProcesses,
      getAllReceptions,
      getAllPackages,
      getAllProducts,
      getAllWorkOrderStatuses,
    } = this.props
    getAllMechanics()
    getAllPackages()
    getAllProducts()
    getAllWorkOrderStatuses()
    getAllReceptions()
    getAllProcesses()
    getServices()
    getActualHistory(
      client,
      placa,
      page,
      size,
      startDateFilter,
      endDateFilter
    ).then(() => {
      this.setState({ loading: false })
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  updateDimensions = () => {
    const { width } = window.screen
    this.setState({ width })
  }

  setStartDate = (startDate) => {
    this.setState({ startDateFilter: startDate })
  }

  setEndDate = (endDate) => {
    this.setState({ endDateFilter: endDate })
  }

  searchFilters = async () => {
    const {
      size,
      startDateFilter = '',
      endDateFilter = '',
      client = '',
      placa = '',
      page,
    } = this.state

    const { getActualHistory } = this.props
    this.setState({ loading_data: true, page: 1 })
    getActualHistory(
      client,
      placa,
      page,
      size,
      startDateFilter,
      endDateFilter
    ).then(() => this.setState({ loading_data: false }))
    this.setState({ loading_data: false })
  }

  changePage = async (newPage) => {
    const { getActualHistory } = this.props
    this.setState({ page: newPage })
    const {
      size,
      startDateFilter = '',
      endDateFilter = '',
      client = '',
      placa = '',
    } = this.state
    this.setState({ loading_data: true })
    getActualHistory(
      client,
      placa,
      newPage,
      size,
      startDateFilter,
      endDateFilter
    ).then(() => this.setState({ loading_data: false }))
  }

  clearFilters = () => {
    const { getActualHistory } = this.props
    const { size } = this.state
    this.setState({ loading_data: true })
    this.setState({
      startDateFilter: '',
      endDateFilter: '',
      client: '',
      placa: '',
      page: '1',
      clearDateRange: true,
    })
    getActualHistory('', '', 1, size, '', '').then(() =>
      this.setState({
        loading_data: false,
        clearDateRange: false,
      })
    )
  }

  handleQueryChange = (e) => {
    const { value, name } = e.target
    this.setState({
      [name]: value,
    })
  }

  handleOnKeypressEnter = (e) => {
    if (e.key) {
      if (e.key.toLowerCase() === 'enter') {
        this.handleFilter()
      }
    }
  }

  toggleModal = () => {
    const { showModal } = this.state
    this.setState({ showModal: !showModal })
  }

  toggleClaimModal = () => {
    const { showClaimModal } = this.state
    this.setState({ showClaimModal: !showClaimModal })
  }

  seeData = (history) => {
    /* .then(() => {
        console.log("Comentarios obtenidos");
      })
      .catch((error) => {
        console.log("Error al obtener los comentarios");
        console.log(error);
      }) */

    this.setState({
      selectedHistory: history,
    })
    this.toggleModal()
  }

  evaluateLink = (workOrder) => {
    const url = `${ClientsUIURL}/work-orders/work-order-detail/${workOrder.work_order_id}?cid=${workOrder.person.person_id}`
    const phone = `503${workOrder.person.person_phone}`
    this.setState({ link: url, personPhone: phone })
    this.toggleEvaluateModal()
  }

  toggleEvaluateModal = () => {
    const { showModalEvaluate } = this.state
    this.setState({ showModalEvaluate: !showModalEvaluate })
  }

  addReclaim = (workOrder) => {
    this.setState({ selectedWorkOrder: workOrder })
    this.toggleClaimModal()
  }

  toggleModalInvoice = () => {
    const { showModalInvoice } = this.state
    this.setState({ showModalInvoice: !showModalInvoice })
  }

  onlyPrint = async (workOrderToInvoice) => {
    const { headerFields, bodyFields, footerDetails } = updateInvoice(
      workOrderToInvoice,
      0.13
    )

    const invoiceId = workOrderToInvoice.invoice.invoice_id

    const {
      budgets: {
        reception: { payor },
      },
      invoice: {
        invoice_number,
        serie: {
          invoiceTypes: { invoice_type_code },
        },
      },
      workshop,
    } = workOrderToInvoice

    let pdfUrl = null
    if (invoice_type_code === 1) {
      pdfUrl = await printTicketPDF(
        workshop,
        invoice_number,
        bodyFields,
        footerDetails,
        payor
      )
    } else {
      pdfUrl = await printInvoicePDF(headerFields, bodyFields)
    }

    this.setState({
      pdfUrl,
      showModalInvoice: true,
      invoiceId,
    })
  }

  render() {
    const {
      loading,
      selectedHistory,
      showModal,
      showClaimModal,
      selectedWorkOrder,
      width,
      showModalInvoice,
      showModalEvaluate,
      pdfUrl,
      invoiceId,
      link,
      personPhone,
      client,
      placa,
      loading_data,
      clearDateRange,
    } = this.state

    const {
      history,
      permissions,
      createWorkOrder,
      workOrderStatuses,
      mechanics,
      packages,
      products,
      services,
      receptions,
      processes,
      currentUser,
    } = this.props

    const { can_write, can_edit, can_delete } = permissions[SCREEN_ID]

    const pagePermissions = {
      can_write,
      can_edit,
      can_delete,
    }

    return (
      <Container>
        <ContainerHeader title="Historial de reparaciones" />
        <BodyContent key="cont">
          <Grid fluid>
            <Row>
              <Col sm={12} key="k-22">
                <HistoryFiltersWrapper>
                  <h1 className="workshop-name">
                    {currentUser.workshop.workshop_name}
                  </h1>
                  <div className="text-filters-wrapper">
                    <FormGroup>
                      <ControlLabel>Cliente: </ControlLabel>
                      <FormControl
                        required
                        type="text"
                        placeholder="Cliente"
                        className="form-control"
                        value={client}
                        name="client"
                        onChange={this.handleQueryChange}
                        onKeyPress={this.handleOnKeypressEnter}
                      />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>Placa: </ControlLabel>
                      <FormControl
                        required
                        type="text"
                        placeholder="Placa"
                        className="form-control"
                        value={placa}
                        name="placa"
                        onChange={this.handleQueryChange}
                        onKeyPress={this.handleOnKeypressEnter}
                      />
                    </FormGroup>
                  </div>
                  <div className="data-btns-filters">
                    <DateRangePicker
                      setStartDateGlobal={this.setStartDate}
                      setEndDateGlobal={this.setEndDate}
                      clearIntervals={clearDateRange}
                    />
                    <div className="btns-wrapper">
                      <button
                        className="btn search"
                        type="button"
                        onClick={this.searchFilters}
                      >
                        <i className="fas fa-search prefix" />
                        BUSCAR
                      </button>
                      <button
                        className="btn clear"
                        type="button"
                        onClick={this.clearFilters}
                      >
                        <i className="fas fa-times prefix" />
                        LIMPIAR FILTROS
                      </button>
                    </div>
                  </div>
                </HistoryFiltersWrapper>
                <Panel>
                  <Panel.Heading bsClass="panel-heading">
                    <Panel.Title>
                      <PanelHeader>
                        <div>
                          <p id="title">
                            <i className="fas fa-history prefix" /> Historial
                          </p>
                        </div>
                      </PanelHeader>
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    {loading ? (
                      <AppLoader />
                    ) : (
                      <Row>
                        <Col m={12}>
                          <CustomStyledTable>
                            <BootstrapTableWithDynamicPagination
                              keyField="work_order_id"
                              data={history}
                              columns={getColumns(
                                this.seeData,
                                this.onlyPrint,
                                this.evaluateLink,
                                width
                              )}
                              loadingData={loading_data}
                              state={this.state}
                              changePage={this.changePage}
                            />
                          </CustomStyledTable>
                        </Col>
                      </Row>
                    )}
                  </Panel.Body>
                </Panel>
              </Col>
            </Row>
          </Grid>
          <HistoryModal
            modalOpen={showModal}
            toggleModal={this.toggleModal}
            selectedHistory={selectedHistory}
            fieldName={selectedHistory.work_order_number}
            fieldNameTitle="Empleado"
          />
          <ClaimModal
            editMode={!_.isEmpty(selectedWorkOrder)}
            modalOpen={showClaimModal}
            toggleModal={this.toggleClaimModal}
            claimMode
            addWorkOrder={createWorkOrder}
            selectedWorkOrder={selectedWorkOrder}
            workOrderStatuses={workOrderStatuses}
            employees={_.values(mechanics)}
            currentUser={currentUser}
            packages={packages}
            products={products}
            services={services}
            receptions={receptions}
            processes={processes}
            history={history}
            pagePermissions={pagePermissions}
          />
        </BodyContent>
        <PrintModal
          modalOpen={showModalInvoice}
          toggleModal={this.toggleModalInvoice}
          toggleModalCloseButton={this.toggleModalInvoice}
          disabled
          src={pdfUrl}
          invoiceId={invoiceId}
          invoiceWorkOrder={this.invoiceWorkOrder}
          loadingInvoicingWorkOrder={false}
          closeButton
          keyboard
          backdrop
          displayFinishButton={false}
        />
        <EvaluateModal
          modalOpen={showModalEvaluate}
          toggleModal={this.toggleEvaluateModal}
          evaluateUrl={link}
          personPhone={personPhone}
        />
      </Container>
    )
  }
}

const CustomStyledTable = styled.div`
  @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
    table {
      border: none;
      padding: 0px 10px;

      thead {
        display: none;
      }

      tbody {
        tr {
          border: 1px solid #ddd;
          display: flex;
          flex-wrap: wrap;
          border-radius: 10px;
          margin: 20px 10px;
          padding-top: 10px;
          padding-bottom: 10px;

          td {
            border: 0;
            border-right: 0px !important;
            border-left: 0px !important;
          }

          td::before {
            font-weight: bold;
            margin-right: 0.5rem;
          }

          td:nth-child(1)::before {
            content: 'Orden:';
          }

          td:nth-child(2)::before {
            content: 'Placa:';
          }

          td:nth-child(2),
          td:nth-child(7) {
            display: flex;
            flex: 1;
            justify-content: flex-end;
          }
          td:nth-child(7) {
            font-weight: bold;
          }

          td:nth-child(1),
          td:nth-child(2) {
            width: 50%;
          }

          td:nth-child(3),
          td:nth-child(4),
          td:nth-child(5),
          td:nth-child(8),
          td:nth-child(6) {
            width: 100%;
          }

          td:nth-child(3)::before {
            content: 'Cliente:';
          }

          td:nth-child(4)::before {
            content: 'Mecánico:';
          }

          td:nth-child(5)::before {
            content: 'Finalización:';
          }

          td:nth-child(6)::before {
            content: 'Calificación:';
          }

          td:nth-child(7)::before {
            content: 'Total:';
          }
          td:nth-child(8) {
            margin-top: 24px;
            display: flex;
            justify-content: space-around;

            i {
              font-size: 2rem !important;
            }
          }
        }
      }
    }
  }
`

const mapStateToProps = (store) => ({
  permissions: store.AppReducer.permissions,
  currentUser: store.AppReducer.currentUser,
  history: store.HistoryReducer.history,
  packages: store.PackageReducer.packages,
  products: store.ProductReducer.products,
  mechanics: store.MechanicReducer.mechanics,
  processes: store.ProcessReducer.processes,
  workOrderStatuses: store.WorkOrderStatusReducer.workOrderStatuses,
  services: store.ServiceTypeReducer.serviceTypes,
  receptions: _.filter(
    store.ReceptionReducer.receptions,
    (reception) => reception.reception_state === 1
  ),
})

const mapDispatchToProps = (dispatch) => ({
  getActualHistory(client, placa, page, size, init, end) {
    return dispatch(getHistory(client, placa, page, size, init, end))
  },
  getAllPackages() {
    return dispatch(getPackages())
  },
  getAllProducts() {
    return dispatch(getProducts())
  },
  getAllProcesses() {
    return dispatch(getProcesses())
  },
  getAllWorkOrderStatuses() {
    return dispatch(getWorkOrderStatuses())
  },
  getAllMechanics() {
    return dispatch(getMechanics())
  },
  getAllReceptions() {
    return dispatch(getReceptions())
  },
  getServices() {
    return dispatch(getServiceTypes())
  },
  createWorkOrder(workOrder) {
    return dispatch(addWorkOrder(workOrder))
  },
})

HistoryContainer.propTypes = {
  getActualHistory: PropTypes.func.isRequired,
  getServices: PropTypes.func.isRequired,
  getAllProcesses: PropTypes.func.isRequired,
  getAllWorkOrderStatuses: PropTypes.func.isRequired,
  getAllMechanics: PropTypes.func.isRequired,
  getAllReceptions: PropTypes.func.isRequired,
  createWorkOrder: PropTypes.func.isRequired,
  getAllPackages: PropTypes.func.isRequired,
  getAllProducts: PropTypes.func.isRequired,
  services: PropTypes.arrayOf(PropTypes.object).isRequired,
  receptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  mechanics: PropTypes.arrayOf(PropTypes.object).isRequired,
  workOrderStatuses: PropTypes.arrayOf(PropTypes.object).isRequired,
  processes: PropTypes.arrayOf(PropTypes.object).isRequired,
  history: PropTypes.objectOf(PropTypes.object).isRequired,
  products: PropTypes.objectOf(PropTypes.object).isRequired,
  packages: PropTypes.objectOf(PropTypes.object).isRequired,
  permissions: PropTypes.objectOf(PropTypes.object).isRequired,
  currentUser: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoryContainer)
