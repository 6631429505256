import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import { Button } from 'react-bootstrap'
import { Spin } from 'antd'
import {
  formatPhoneToDB,
  getCountryCode,
} from '../../../../../Utilities/formatPhone'
import { NotificationHandler } from '../../../../../../Helpers'
import { updatePhone } from '../../../../../../api/apiService'
import PhoneInputComponent from '../../../../Common/PhoneInput/PhoneInput'
import EditPhoneInputWrapper from './EditPhoneInput.styles'


const EditPhoneInput = ({
  phone,
  personId,
  countryCode,
  workshopCountryCode,
  setIsPhoneEditing,
  handlePhoneEdited,
}) => {
  const [editedPhone, setEditedPhone] = useState(phone)
  const [editAreaCode, setEditAreaCode] = useState(countryCode)
  const [phoneError, setPhoneError] = useState(false)
  const [loading, setLoading] = useState(false)

  const handlePhoneCancel = () => {
    setIsPhoneEditing(false)
    setEditedPhone('')
    setEditAreaCode('')
  }

  const handlePhoneSave = async () => {
    try {
      setLoading(true)
      const response = await updatePhone(
        personId,
        formatPhoneToDB(editedPhone),
        editAreaCode
      )
      handlePhoneEdited(response.data.person_phone, response.data.country_code)
      setLoading(false)
      NotificationHandler('¡Listo!', 'success', 'Telefono actualizado')
    } catch {
      NotificationHandler('Error', 'error', 'Error al editar telefono')
    }
  }

  const handlePhoneInputChange = (value) => {
    const countryCodeSelected = getCountryCode(value)
    setEditedPhone(value)
    setEditAreaCode(countryCodeSelected || '')
  }

  return (
    <EditPhoneInputWrapper>
      <div className="input-wrapper">
        <PhoneInputComponent
          phone={phone}
          onChange={handlePhoneInputChange}
          isError={phoneError}
          setError={setPhoneError}
          countryCode={
            countryCode ||
            workshopCountryCode ||
            localStorage.getItem('workshopCountryCode')
          }
          required
        />
      </div>
      <div className="edit-actions">
        {loading && (
          <div className='loader'>
            {' '}
            <Spin
              size="small"
              style={{
                margin: 'auto',
              }}
            />{' '}
            Actualizando datos...
          </div>
        )}
        {!loading && (
          <>
            <Button
              bsStyle="success"
              className="button-size btn-save-client-phone-number"
              onClick={handlePhoneSave}
              disabled={phoneError}
            >
              Save
            </Button>
            <Button
              bsStyle="default"
              className="button-size"
              onClick={handlePhoneCancel}
            >
              Cancel
            </Button>
          </>
        )}
      </div>
    </EditPhoneInputWrapper>
  )
}

EditPhoneInput.propTypes = {
  phone: PropTypes.string.isRequired,
  countryCode: PropTypes.number.isRequired,
  personId: PropTypes.number.isRequired,
  workshopCountryCode: PropTypes.any.isRequired,
  setIsPhoneEditing: PropTypes.func.isRequired,
  handlePhoneEdited: PropTypes.func.isRequired,
}
export default EditPhoneInput
