import styled from 'styled-components'

import { BREAK_POINTS_DESKTOP_FIRST } from '../../../../global/css/breakPoints'

export const OrderCardContainer = styled.div`
  position: relative;

  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 10px;
  margin: 0;
  border-radius: 5px;

  text-align: center;
  font-size: 1.6rem;

  background-color: #ffffff;
  border-bottom: solid 7px ${(props) => props.$borderColor || '#BF4F74'};
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;

  opacity: 0.9;
  cursor: pointer;

  @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
    font-size: 1.25rem;
  }

  @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
    min-width: 0;
    font-size: 1rem;
  }

  svg {
    position: absolute;
    top: -12px;
    left: -12px;
    font-size: 4.5rem;

    @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
      font-size: 3.5rem;
    }
    @media (${BREAK_POINTS_DESKTOP_FIRST.MOBILE}) {
      top: -8px;
      left: -8px;
      font-size: 2.5rem;
    }
  }

  :hover {
    font-weight: bold;
    transform: scale(1.01);
    transition-duration: 0.3s;
    background-color: #FBFBFB;
    opacity: 1;
  }

  .blinking {
    animation: blink 1.2s linear infinite;
  }
  @keyframes blink {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0.3;
    }
    50% {
      opacity: 0.5;
    }
    75% {
      opacity: 0.7;
    }
    100% {
      opacity: 1;
    }
  }

  p {
    margin: 0;
  }
`

export const CardNumber = styled.p`
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 3.5rem;

  @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
    font-size: 2.5rem;
  }
`
