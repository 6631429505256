import styled from 'styled-components'

import BREAK_POINTS from '../../../../global/css/breakPoints'

export const GradientText = styled.div`
  background-image: linear-gradient(to right, #1e1e40, #b80667);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
  font-size: 18px;
  font-weight: bold;

  @media (${BREAK_POINTS.TABLET}) {
    text-align: left;
    font-size: 20px;
  }

  @media (${BREAK_POINTS.DESKTOP}) {
    text-align: left;
    font-size: 26px;
  }
`
