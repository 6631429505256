import { Modal } from '@material-ui/core'
import styled from 'styled-components'
import { BREAK_POINTS_DESKTOP_FIRST } from '../../../../global/css/breakPoints'

const AddSomethingModalCustom = styled(Modal)`
  height: 100%;
  min-height: -webkit-fill-available;
  &.add-modal {
    position: relative;
    top: 0;
    padding: 0;
    width: 100vw !important;
    height: 100%;
    min-height: -webkit-fill-available;
    max-width: unset !important;
    color: white;
    background-color: rgba(0, 0, 0, 0.76);
    display: flex;
    justify-content: center;
    .content {
      max-width: 670px;
      display: flex;
      flex-direction: column;
      gap: 60px;
      align-items: center;
      justify-content: center;
      button {
        background-color: unset;
        border: unset;
        &:hover {
          color: #d00f48;
        }
      }
      .close-btn {
        position: absolute;
        top: 50px;
        right: 60px;
        i {
          font-size: 3.5rem;
        }
      }
      h1 {
        text-align: center;
        font-family: inherit;
        font-weight: 600;
        font-size: 3rem;
      }

      .options {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 48px;
        padding-left: unset;
        li {
          button {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 24px;
            font-family: inherit;
            font-weight: 700;
            font-size: 2.5rem;
            i {
              font-size: 3rem;
              &::before {
                display: flex;
                width: 40px;
                height: 30px;
              }
            }
          }
        }
      }
    }
  }
  @media (${BREAK_POINTS_DESKTOP_FIRST.MOBILE}) {
    &.add-modal {
      padding: 16px;
      .content {
        max-width: unset;
        margin-top: -30px;
        .close-btn {
          right: 16px;
          top: 16px;
        }
        h1 {
          font-size: 2.5rem;
        }
        .options {
          li {
            button {
              display: flex;
              align-items: center;

              font-size: 2rem;
            }
          }
        }
      }
    }
  }
`

export default AddSomethingModalCustom
