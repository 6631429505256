import React from 'react'
import Speedometer from 'react-d3-speedometer'
import { Input, Slider } from 'antd'
import PropTypes from 'prop-types'

import { GasLevelSliderWrapper } from './GasLevelSlider.style'

function GasLevelSlider({ value, onValueChange }) {
  const handleInputValueChange = (e) => {
    const inputValue = e.target.value

    if (inputValue >= 0 && inputValue <= 100) {
      onValueChange(parseFloat(inputValue))
    }
  }

  return (
    <GasLevelSliderWrapper>
      <div className="gas-inputs">
        <Slider
          min={0}
          max={100}
          autoFocus
          style={{ width: '100%' }}
          styles={{
            track: { height: '8px' },
            rail: { height: '8px', backgroundColor: '#dedede' },
            handle: { height: '14px', top: '2px' },
          }}
          onChange={onValueChange}
          value={value}
        />
        <Input
          type="number"
          min={0}
          defaultValue={0}
          style={{ width: '80px' }}
          max={100}
          onChange={handleInputValueChange}
          value={value}
        />
      </div>
      <Speedometer
        maxValue={100}
        height={130}
        width={180}
        paddingHorizontal={5}
        value={value}
        currentValueText=""
        needleColor="black"
        onValueChange={onValueChange}
      />
    </GasLevelSliderWrapper>
  )
}

GasLevelSlider.propTypes = {
  value: PropTypes.number.isRequired,
  onValueChange: PropTypes.func.isRequired,
}

export default GasLevelSlider
