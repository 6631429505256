import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'
import { debounce } from 'lodash'
import { Button, Modal } from 'antd'
import PreviousNextButton from '../../../../buttons/PreviousNextButton/PreviousNextButton'
import { useAddReceptionContext } from '../../../../../contexts/AddReceptionContext'
import { addReceptionActions } from '../../../../../reducers/AddReceptionReducer'
import GradientTextComponent from '../../../Common/GradientText.js/GradientText'
import { createReceptionSubSteps } from '../../../../../Constants'
import { NotificationHandler } from '../../../../../Helpers'
import SearchBar from '../../../../inputs/SearchBar/SearchBar'
import { AdditionalActionButton } from '../AddReception.style'
import EditPhoneInput from './EditPhoneInput/EditPhoneInput'
import { fetchClient } from '../../../../../api/apiService'
import {
  ClientStepContainer,
  SelectedClient,
  SelectedClientCard,
} from './ClientStep.styles'
import { replaceOwnerService } from '../../../../../api/apiAddClientService'
import {
  ModalBodyContainer,
  ModalButtonsContainer,
} from '../VehicleStep/VehicleStep.style'
import { createReserveReception } from '../../../../../api/newReceptionService'

const ClientStep = ({ handleNext, handlePrevious, workshopCountryCode }) => {
  const { state, dispatch } = useAddReceptionContext()
  const {
    client,
    clientValue,
    contact,
    replaceOwner,
    lastOwner,
    selectedLastOwner,
    isClientValidated,
    selectedVehicle,
    receptionId,
    photos,
    newVehicle,
    isClientSaved,
  } = state

  const [clientsData, setClientsData] = useState([])
  const [ownersData, setOwnersData] = useState([])
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [searchOwnerValue, setSearchOwnerValue] = useState()
  const [isEditingPhone, setEditingPhone] = useState(false)
  const [isNoValidOwnerModal, setIsNoValidOwnerModal] = useState(false)
  const WorkShopCountryCode =
    workshopCountryCode || localStorage.getItem('workshopCountryCode')

  const isContactRegistered = Boolean(contact?.contactName)
  const renderNewClient =
    !isContactRegistered && !isClientValidated && replaceOwner

  const fetchSearchResults = async () => {
    setIsDataLoading(true)
    try {
      const data = await fetchClient(searchValue, WorkShopCountryCode)
      setClientsData(data.data)
    } catch (e) {
      NotificationHandler(
        'Error',
        'error',
        'Error al obtener resultados de búsqueda'
      )
    } finally {
      setIsDataLoading(false)
    }
  }

  const fetchOwnersSearchResults = async () => {
    setIsDataLoading(true)
    try {
      const data = await fetchClient(searchOwnerValue, WorkShopCountryCode)
      setOwnersData(data.data)
    } catch (e) {
      NotificationHandler(
        'Error',
        'error',
        'Error al obtener resultados de búsqueda'
      )
    } finally {
      setIsDataLoading(false)
    }
  }

  const handleSearch = debounce((newValue) => {
    setSearchValue(newValue)
  }, 300)

  const handleSearchOwner = debounce((newValue) => {
    setSearchOwnerValue(newValue)
  }, 300)

  const handleChange = (newValue) => {
    const selected = clientsData.filter(
      (clientSelected) => clientSelected.ownerId === newValue?.value
    )

    dispatch({
      type: addReceptionActions.SELECT_CLIENT,
      payload: {
        client: selected.length > 0 ? selected[0] : null,
        clientValue: newValue || null,
      },
    })

    setClientsData([...selected])
  }

  const handleOwnerChange = (newValue) => {
    const selected = ownersData.filter(
      (clientSelected) => clientSelected.ownerId === newValue?.value
    )

    dispatch({
      type: addReceptionActions.SET_LAST_OWNER,
      payload: {
        lastOwner: selected.length > 0 ? selected[0] : null,
        selectedLastOwner: newValue,
      },
    })

    setOwnersData([...selected])
  }

  const handlePhoneEdited = (newPhone, newCountryCode) => {
    const newClient = {
      ownerId: client?.ownerId,
      phone: newPhone,
      clientName: client?.clientName,
      countryCode: newCountryCode,
    }

    dispatch({
      type: addReceptionActions.SELECT_CLIENT,
      payload: {
        client: newClient,
        clientValue,
      },
    })
    setEditingPhone(false)
  }

  const handledRemoveSelected = () => {
    setSearchValue('')
    dispatch({
      type: addReceptionActions.SELECT_CLIENT,
      payload: {
        client: null,
        clientValue: null,
      },
    })
    setClientsData([])
  }

  const handledRemoveContact = () => {
    dispatch({
      type: addReceptionActions.RESET_CONTACT,
    })
  }

  const handleAddClient = () => {
    dispatch({
      type: addReceptionActions.SET_SUB_STEP,
      payload: createReceptionSubSteps.ADD_CLIENT,
    })
  }

  const handledAddContact = () => {
    dispatch({
      type: addReceptionActions.SET_SUB_STEP,
      payload: createReceptionSubSteps.ADD_CONTACT,
    })
  }

  const handlePreviousStep = () => {
    handlePrevious()
  }

  const handleNextStep = async () => {
    try {
      // to check if the selected client is the vehicle owner
      if (
        !replaceOwner &&
        !newVehicle.vehicleLicensePlate &&
        selectedVehicle.ownerId !== client?.ownerId && !isClientSaved
      ) {
        setIsNoValidOwnerModal(true)
        return
      }

      // to asign the new owner
      if (replaceOwner) {
        if (
          !newVehicle.vehicleLicensePlate &&
          selectedVehicle.ownerId !== lastOwner?.ownerId
        ) {
          setIsNoValidOwnerModal(true)
          return
        }
        await replaceOwnerService(client?.ownerId, selectedVehicle.vehicleId)
      }

      if (!receptionId) {
        // reserve reception
        const reception = await createReserveReception(
          client.ownerId,
          selectedVehicle.vehicleId,
          contact
        )
        dispatch({
          type: addReceptionActions.SET_RECEPTION_ID,
          payload: reception.data,
        })
      }

      dispatch({
        type: addReceptionActions.SET_CLIENT_SAVED,
        payload: true,
      })

      handleNext()
    } catch {
      NotificationHandler('Error', 'error', 'Error registrando cliente')
    }
  }

  useEffect(() => {
    if (searchValue) {
      fetchSearchResults()
    } else {
      setClientsData([])
    }
  }, [searchValue])

  useEffect(() => {
    if (searchOwnerValue) {
      fetchOwnersSearchResults()
    } else {
      setOwnersData([])
    }
  }, [searchOwnerValue])

  const transformedClients = clientsData?.map((clientSelected) => {
    return {
      value: clientSelected.ownerId,
      label: `${clientSelected.clientName} - ${clientSelected.phone}`,
    }
  })

  const transformedOwners = ownersData?.map((clientSelected) => {
    return {
      value: clientSelected.ownerId,
      label: `${clientSelected.clientName} - ${clientSelected.phone}`,
    }
  })

  const transformedDefaultClient = {
    value: client?.ownerId,
    label: client?.clientName,
  }

  const transformedDefaultOwner = {
    value: lastOwner?.ownerId,
    label: lastOwner?.clientName,
  }

  const renderContact = () => {
    if (isContactRegistered) {
      return (
        <SelectedClient>
          <h4>Contacto que recibirá notificaciones:</h4>
          <SelectedClientCard>
            <button
              className="icon"
              type="button"
              onClick={() => handledRemoveContact()}
            >
              <i className="fas fa-times-circle" />
            </button>
            <div className="client-name">
              <i className="fas fa-address-book" />
            </div>
            <div className="client-details">
              <p>
                <span>Nombre: </span>
                {contact?.contactName}
              </p>
              <div>
                <p>
                  <span>Teléfono: </span> {contact?.contactPhone}
                </p>
              </div>
            </div>
          </SelectedClientCard>
        </SelectedClient>
      )
    }

    return (
      !replaceOwner &&
      !isClientValidated &&
      !newVehicle.vehicleLicensePlate && (
        <div className="no-owner">
          <p>
            ¿<b>No eres el dueño</b> del vehículo?
          </p>
          <AdditionalActionButton onClick={handledAddContact}>
            <p>
              <i className="fas fa-arrow-right" /> Registrar como contacto
            </p>
          </AdditionalActionButton>
        </div>
      )
    )
  }

  return (
    <>
      <main className="new-reception__main">
        <div className="new-reception__main__question">
          <GradientTextComponent text="Selecciona el cliente *" />
          <i className="far fa-user" />
        </div>
        <div className="new-reception__main__content">
          <ClientStepContainer>
            {replaceOwner &&
              !newVehicle.vehicleLicensePlate &&
              !isClientSaved && (
                <SearchBar
                  id="owner-name"
                  searchValue={searchOwnerValue}
                  items={transformedOwners}
                  isLoading={isDataLoading}
                  defaultValue={selectedLastOwner || transformedDefaultOwner}
                  value={selectedLastOwner}
                  placeholder="Ingresa el nombre del dueño anterior"
                  label="Dueño anterior:"
                  handleValueChange={handleOwnerChange}
                  handleSearch={handleSearchOwner}
                />
              )}
            {!isClientValidated && !isClientSaved && (
              <SearchBar
                id="client-name"
                searchValue={searchValue}
                items={transformedClients}
                isLoading={isDataLoading}
                defaultValue={clientValue || transformedDefaultClient}
                value={clientValue}
                addItemText="Agregar nuevo cliente"
                placeholder="Ingresa el nombre del cliente"
                label={
                  replaceOwner && !newVehicle.vehicleLicensePlate
                    ? 'Nuevo dueño:'
                    : 'Busca por nombre:'
                }
                handleValueChange={handleChange}
                handleSearch={handleSearch}
                handleOnAdd={handleAddClient}
              />
            )}
            {(client?.ownerId || isClientSaved) && (
              <SelectedClient>
                <h4>
                  {replaceOwner && !newVehicle.vehicleLicensePlate
                    ? 'Nuevo dueño seleccionado:'
                    : 'Cliente seleccionado:'}
                </h4>
                <SelectedClientCard>
                  {!isClientValidated && !isClientSaved && (
                    <button
                      className="icon"
                      type="button"
                      onClick={() => handledRemoveSelected()}
                    >
                      <i className="fas fa-times-circle" />
                    </button>
                  )}
                  <div className="client-name">
                    <i className="fas fa-user" />
                  </div>
                  <div className="client-details">
                    <p>
                      <span>Nombre: </span>
                      {client?.clientName}
                    </p>

                    <div>
                      {!isEditingPhone ? (
                        <p>
                          <span>Teléfono: </span> {client?.phone}
                          <button
                            type="button"
                            className="edit"
                            onClick={() => setEditingPhone(true)}
                          >
                            <i className="fa fa-edit" />
                          </button>
                        </p>
                      ) : (
                        <div className="edited-phone">
                          <p>
                            <span>Teléfono: </span>{' '}
                          </p>{' '}
                          <EditPhoneInput
                            phone={client?.phone}
                            personId={client?.ownerId}
                            countryCode={client?.countryCode}
                            workshopCountryCode={WorkShopCountryCode}
                            setIsPhoneEditing={setEditingPhone}
                            handlePhoneEdited={handlePhoneEdited}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </SelectedClientCard>
              </SelectedClient>
            )}
            <div className="options">{renderContact()}</div>
            {!client?.ownerId && !isClientSaved && (
              <div className="options">
                {renderNewClient && (
                  <div className="no-client">
                    <p>
                      ¿Eres un cliente <b>nuevo</b>?
                    </p>
                    <AdditionalActionButton onClick={handleAddClient}>
                      <p>
                        <i className="fas fa-arrow-right" /> Crear cliente
                      </p>
                    </AdditionalActionButton>
                  </div>
                )}
              </div>
            )}
          </ClientStepContainer>
        </div>
      </main>
      <div className="new-reception__navigation-buttons">
        <PreviousNextButton isPrevious onClickHandler={handlePreviousStep} />
        <PreviousNextButton
          isPrevious={false}
          nextText={
            photos.length > 0 ? 'Siguiente' : 'Fotografiar vehículo'
          }
          isDisabled={!client}
          onClickHandler={handleNextStep}
        />
      </div>
      <Modal
        className="warn-modal"
        style={{ textAlign: 'center', padding: '3rem 1rem' }}
        open={isNoValidOwnerModal}
        width={325}
        centered
        onOk={() => setIsNoValidOwnerModal(false)}
        onCancel={() => setIsNoValidOwnerModal(false)}
        okText="Aceptar"
        cancelText="Cancelar"
        footer={[
          <ModalButtonsContainer style={{ justifyContent: 'flex-end' }}>
            <Button
              key="back"
              type="primary"
              onClick={() => setIsNoValidOwnerModal(false)}
            >
              Entendido
            </Button>
          </ModalButtonsContainer>,
        ]}
      >
        <ModalBodyContainer>
          <i className="fas fa-exclamation-triangle" />
          <p>
            {replaceOwner
              ? 'El dueño anterior no coincide con el dueño del vehículo'
              : 'El cliente seleccionado no coincide con el dueño del vehículo'}
          </p>
        </ModalBodyContainer>
      </Modal>
    </>
  )
}

const mapStateToProps = (state) => ({
  workshopCountryCode: state.AppReducer.workshopCountryCode,
})

ClientStep.defaultProps = {
  workshopCountryCode: undefined,
}

ClientStep.propTypes = {
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
  workshopCountryCode: PropTypes.number,
}

export default connect(mapStateToProps)(ClientStep)
