import React from 'react'
import { Button } from 'react-bootstrap'
import ReactToolTip from 'react-tooltip'
import moment from 'moment'
import { textFilter } from 'react-bootstrap-table2-filter'
import { EditFilled, EyeFilled, DeleteFilled } from '@ant-design/icons';
import {
  formatterHeaderStyle,
  columnHeaderStyle,
  columnStyle,
} from '../../Utilities/TableStyles'

const styleIcons = {
  fontSize: '17px',
  '&:hover': {
    background: 'green',
  },
}

/*
  const manageWorkOrder = (currentUser, administrateWorkOrder) => (cell, row) => {
  // the button will be available only for administrators and for the current user
  // with a mechanic role, also the mechanic needs to be assigned to the work order
  const {
    rol_id: current_rol_id,
    employee_id: current_employee_id,
  } = currentUser

  const {
    mechanic: { rol_id: mechanic_rol_id, employee_id: assigned_mechanic_id },
  } = row

  let isButtonDisabled = true

  if (current_rol_id === 1) {
    isButtonDisabled = false
  } else if (
    mechanic_rol_id === 3 &&
    assigned_mechanic_id === current_employee_id
  ) {
    isButtonDisabled = false
  }

  return (
    <Button
      onClick={() => {
        administrateWorkOrder(row)
      }}
      bsStyle="link"
      bsSize="small"
      data-tip="Administrar"
      disabled={isButtonDisabled}
    >
      <i className="fa fa-tasks" style={styleIcons} />
      <ReactToolTip />
    </Button>
  )
}
*/

const editButton = (update, can_edit) => (cell, row) =>
(
  <Button
    onClick={() => {
      update(row)
    }}
    bsStyle="link"
    bsSize="small"
    data-tip={can_edit ? 'Modificar' : 'Ver'}
  >
    {can_edit ? <EditFilled style={styleIcons} /> : <EyeFilled style={styleIcons} />}
    <ReactToolTip />
  </Button>
)

const deleteButton = (_delete) => (cell, row) =>
(
  <Button
    onClick={() => _delete(row)}
    bsStyle="link"
    bsSize="small"
    style={{ color: 'red' }}
    data-tip="Eliminar"
  >
    <DeleteFilled style={styleIcons} />
    <ReactToolTip />
  </Button>
)

const finishButton = (finish) => (cell, row) =>
(
  <Button
    onClick={() => finish(row)}
    bsStyle="link"
    bsSize="small"
    style={{ color: 'gray' }}
    data-tip="Finalizar"
  >
    <i
      className="fas fa-check-circle"
      style={{ fontSize: '22px', color: 'rgb(61, 163, 104)' }}
    />
    <ReactToolTip />
  </Button>
)

const texFilterOption = {
  delay: 100,
  style: {
    margin: '10px 0 0 0',
    padding: '20px 2%',
  },
  placeholder: 'Buscar',
}

const getDate = (date) => moment(date).format('dddd D MMMM YYYY')

const renderProcess = (cell, row) => {
  let percentage = 0

  const { timeTracker } = row
  const filteredTimeTracker = timeTracker.filter((time) => time.process) || []
  if (filteredTimeTracker.length > 0) {
    const finishedProcesses =
      filteredTimeTracker.filter((process) => process.process_status === 3) ||
      []
    percentage = finishedProcesses.length / filteredTimeTracker.length
  }

  return <span>{Math.round((percentage || 0) * 100)} %</span>
}

const getColumns = (
  update,
  _delete,
  finish,
  administrateWorkOrder,
  { can_edit, can_delete /* , currentUser */ }
) => {
  const columns = [
    {
      dataField: 'work_order_id',
      text: 'Orden',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: textFilter(texFilterOption),
      formatter: (id) => `#${id}`,
    },
    {
      dataField: 'budgets.reception.reception_id',
      text: 'Recepción',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: textFilter(texFilterOption),
      formatter: (id) => `#${id}`,
    },
    {
      dataField: 'budgets.reception.vehicle.vehicle_license_plate',
      text: 'Placa',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: textFilter(texFilterOption),
    },
    {
      dataField: 'budgets.reception.vehicle.model.brand.brand_name',
      text: 'Marca',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: textFilter(texFilterOption),
    },
    {
      dataField: 'budgets.reception.vehicle.model.model_name',
      text: 'Modelo',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: textFilter(texFilterOption),
    },
    {
      dataField: 'work_order_date',
      text: 'Registro',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: textFilter(texFilterOption),
      formatter: getDate,
    },
    {
      dataField: 'work_order_total',
      text: 'Total',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: textFilter(texFilterOption),
      formatter: (total) => `$ ${total}`,
    },
    {
      dataField: 'workOrderStatus.work_order_status_name',
      text: 'Estado',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: textFilter(texFilterOption),
    },
    {
      dataField: 'work_order_progress',
      text: 'Porcentaje',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: textFilter(texFilterOption),
      formatter: renderProcess,
    },
    /* {
      dataField: '1',
      className: 'test',
      text: '',
      headerStyle: { ...formatterHeaderStyle(), width: 'auto!important' },
      style: columnHeaderStyle({ textAlign: 'center' }),
      formatter: manageWorkOrder(currentUser, administrateWorkOrder),
    }, */
    {
      dataField: '1',
      className: 'test',
      text: '',
      headerStyle: { ...formatterHeaderStyle(), width: 'auto!important' },
      style: columnHeaderStyle({ textAlign: 'center' }),
      formatter: editButton(update, can_edit),
    },
    {
      dataField: '2',
      className: 'test',
      text: '',
      headerStyle: { ...formatterHeaderStyle(), width: 'auto!important' },
      style: columnHeaderStyle({ textAlign: 'center' }),
      formatter: finishButton(finish),
    },
  ]
  if (can_delete) {
    columns.push({
      dataField: '3',
      text: '',
      headerStyle: { ...formatterHeaderStyle(), width: 'auto!important' },
      style: columnHeaderStyle({ textAlign: 'center' }),
      formatter: deleteButton(_delete),
    })
  }
  return columns
}

export default getColumns
