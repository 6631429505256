import paginationFactory from 'react-bootstrap-table2-paginator'
import { Grid, Row, Col, Panel, Button } from 'react-bootstrap'
import filterFactory from 'react-bootstrap-table2-filter'
import BootstrapTable from 'react-bootstrap-table-next'
import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { MenuOutlined, PlusCircleFilled } from '@ant-design/icons';
import {
  Container,
  ContainerHeader,
  BodyContent,
  showConfirmDialog,
  PanelHeader,
} from '../../Common'
import getColumns from './processColumns'
import ProcessModal from './ProcessModal'
import { BREAK_POINTS_DESKTOP_FIRST } from '../../../../global/css/breakPoints'

import {
  getProcesses,
  addProcess,
  updateProcess,
  deleteProcess,
} from '../../../../actions/ProcessAction'
import { AppLoader } from '../../Common/AppLoader'

const SCREEN_ID = 18
class ProcessContainer extends Component {
  state = {
    showModal: false,
    loading: true,
    selectedProcess: {},
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions)
    const { getProcess } = this.props
    getProcess()
      .then(() => {
        this.setState({ loading: false })
      })
      .catch(() => {
        this.setState({ loading: false })
      })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  updateDimensions = () => {
    const { width } = window.screen
    this.setState({ width })
  }

  toggleModal = () => {
    const { showModal } = this.state
    this.setState({ showModal: !showModal })
  }

  addProcess = () => {
    this.setState({ selectedProcess: {} })
    this.toggleModal()
  }

  updateProcess = (process) => {
    this.setState({ selectedProcess: process })
    this.toggleModal()
  }

  deleteProcess = (process) => {
    const { removeProcess } = this.props
    showConfirmDialog(
      'Eliminar',
      `¿Desea Eliminar el proceso: ${process.process_name}?`,
      removeProcess,
      process
    )
  }

  render() {
    const { loading, selectedProcess, showModal, width } = this.state

    const {
      processes,
      permissions,
      createProcess,
      modifyProcess,
      workshop_id,
    } = this.props

    const { can_write, can_edit, can_delete } = permissions[SCREEN_ID]

    const pagePermissions = {
      can_write,
      can_edit,
      can_delete,
    }

    return (
      <Container>
        <ContainerHeader title="Procesos" />
        <BodyContent>
          <Grid fluid>
            <Row>
              <Col sm={12}>
                <Panel>
                  <Panel.Heading bsClass="panel-heading">
                    <Panel.Title>
                      <PanelHeader>
                        <div>
                          <p id="title">
                            <MenuOutlined style={{ marginRight: '7px' }} /> Administrar Procesos
                          </p>
                        </div>
                        <div>
                          {!!can_write && (
                            <Button
                              onClick={this.addProcess}
                              bsStyle="success"
                              className="btn-add btn-antd-icon"
                            >
                              <PlusCircleFilled />
                              Agregar registro
                            </Button>
                          )}
                        </div>
                      </PanelHeader>
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    {loading ? (
                      <AppLoader />
                    ) : (
                      <Row>
                        <Col md={10} />
                        <Col m={12}>
                          <CustomStyledTable>
                            <BootstrapTable
                              noDataIndication="Sin datos para mostrar"
                              keyField="process_id"
                              data={_.values(processes).reverse()}
                              columns={getColumns(
                                this.updateProcess,
                                this.deleteProcess,
                                { can_edit, can_delete },
                                width
                              )}
                              filter={filterFactory()}
                              pagination={paginationFactory()}
                            />
                          </CustomStyledTable>
                        </Col>
                      </Row>
                    )}
                  </Panel.Body>
                </Panel>
              </Col>
            </Row>
          </Grid>
          <ProcessModal
            modalOpen={showModal}
            toggleModal={this.toggleModal}
            editMode={!_.isEmpty(selectedProcess)}
            selectedProcess={selectedProcess}
            fieldName="process"
            fieldNameTitle="Proceso"
            addProcess={createProcess}
            updateProcess={modifyProcess}
            permissions={permissions}
            pagePermissions={pagePermissions}
            workshop_id={workshop_id}
          />
        </BodyContent>
      </Container>
    )
  }
}

const CustomStyledTable = styled.div`
  @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
    table {
      border: none;
      padding: 0px 10px;

      thead {
        tr {
          display: flex;
          flex-direction: column;

          th {
            label {
              width: 100%;
            }
          }

          th {
            border: none;
          }

          th:nth-last-child(1),
          th:nth-last-child(2) {
            display: none;
          }
        }
      }

      tbody {
        tr {
          border: 1px solid #ddd;
          display: flex;
          flex-wrap: wrap;
          border-radius: 10px;
          margin: 20px 10px;
          padding-top: 10px;
          padding-bottom: 10px;

          td {
            width: 100%;
            border: 0;
            border-right: 0px !important;
            border-left: 0px !important;
          }

          td:nth-last-child(1),
          td:nth-last-child(2) {
            width: 40%;
          }

          td::before {
            font-weight: bold;
            margin-right: 0.5rem;
          }

          td:nth-child(1)::before {
            content: 'Nombre:';
          }

          td:nth-child(2)::before {
            content: 'Tiempo estimado (mins):';
          }

          td:nth-child(3)::before {
            content: 'Costo:';
          }
        }
      }
    }
  }
`
const mapStateToProps = (store) => ({
  processes: store.ProcessReducer.processes,
  permissions: store.AppReducer.permissions,
  workshop_id: store.AppReducer.currentUser.workshop_id,
})

const mapDispatchToProps = (dispatch) => ({
  getProcess() {
    return dispatch(getProcesses())
  },
  createProcess(process) {
    return dispatch(addProcess(process))
  },
  modifyProcess(process) {
    return dispatch(updateProcess(process))
  },
  removeProcess(process) {
    return dispatch(deleteProcess(process))
  },
})

ProcessContainer.propTypes = {
  processes: PropTypes.arrayOf(PropTypes.object).isRequired,
  permissions: PropTypes.objectOf(PropTypes.bool).isRequired,
  workshop_id: PropTypes.number.isRequired,
  getProcess: PropTypes.func.isRequired,
  createProcess: PropTypes.func.isRequired,
  modifyProcess: PropTypes.func.isRequired,
  removeProcess: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcessContainer)
