import axios from 'axios'

import { ClientsURL, Host } from '../Constants'

export const sendChatMessage = async (
  comment,
  currentUser,
  orderId,
  options
) => {
  try {
    // Prepare the message payload
    const notify = options && options.notify === true
    const messagePayload = {
      comment,
      associatedProducts: '',
      principalProcess: '',
      userId: currentUser,
      workOrderId: orderId,
      notify,
    }

    // Send the message to the API
    const response = await axios.post(
      'api/work_orders/comments',
      messagePayload
    )

    if (response.status === 200 || response.status === 201) {
      console.log('Message sent successfully')
      // Handle success case (optional)
    } else {
      console.log('Failed to send message')
      // Handle error case (optional)
    }
  } catch (error) {
    console.log('Error sending message:', error)
    // Handle error case (optional)
  }
}

export const fetchWorkOrderBudget = async (orderId) => {
  try {
    // Send the message to the API
    const response = await axios.get(
      `${ClientsURL}/api/client/work-order-invoice-detail/${orderId}`
    )

    return response.data
  } catch (error) {
    console.error('Error fetching budget:', error)
  }

  return []
}

// Made using fetch API because abortController did not work with axios
export const fetchVehiclesByLicensePlate = async (
  licensePlate,
  countryCode,
  signal = undefined
) => {
  const token = localStorage.getItem('jwtToken')
  const url = `${Host}api/vehicles/country/search/${countryCode}?licensePlate=${licensePlate}`

  try {
    const response = await fetch(url, {
      signal,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    const data = await response.json()

    return data
  } catch (error) {
    if (error.name !== 'AbortError') {
      throw error
    }
  }
  return []
}

export const fetchClient = async (name, countryCode) => {
  const response = await axios.get(
    `api/persons/search/${countryCode}?name=${name}`
  )
  return response.data
}

export const checkClientPhone = async (ownerId, lastDigits) => {
  const response = await axios.get(
    `api/receptions/check-phone/${ownerId}?lastDigits=${lastDigits}`
  )

  return response.data
}

export const checkVehiclePlate = async (licensePlate) => {
  const response = await axios.get(
    `api/vehicles/verify-license-plate/${licensePlate}`
  )

  return response.data
}

export const updatePhone = async (id, phone, countryCode) => {
  const response = await axios.patch(`/api/persons/${id}/phone`, {
    person_phone: phone,
    country_code: countryCode,
  })

  return response.data
}
