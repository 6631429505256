import React from 'react'
import { Container, Text } from './Components'

const ForbiddenPage = () => (
  <Container>
    <Text>Prohibido</Text>
    <Text fontSize="20">
      Usted no tiene permisos para entrar a esta página.
    </Text>
  </Container>
)

export default ForbiddenPage
