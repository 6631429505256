import React from 'react'
import { PropTypes } from 'prop-types'
import { ProcessWrapper, ProcessesWrapper } from './Processes.styles'
import { useAddReceptionContext } from '../../../../../../../contexts/AddReceptionContext'
import EmptyState from '../../../../../Common/EmptyState/EmptyState'
import { getTotalWithDiscount } from '../../../../../../Utilities/totalWithDiscount'

const Process = ({ name, price, originalPrice, discount }) => {
  return (
    <ProcessWrapper>
      <div className="name">
        <p className="process-name">{name}</p>
        {parseFloat(price, 10).toFixed(2) !==
          parseFloat(originalPrice, 10).toFixed(2) && (
          <p className="process-price">
            Precio: ${parseFloat(originalPrice, 10).toFixed(2)}{' '}
            {discount > 0 && <span>-{discount}%</span>}
          </p>
        )}
        <p className="required">
          <i className="fas fa-exclamation-circle" />
          Obligatorio
        </p>
      </div>
      <p className="price">${parseFloat(price, 10).toFixed(2)}</p>
    </ProcessWrapper>
  )
}
Process.propTypes = {
  name: PropTypes.string.isRequired,
  price: PropTypes.any.isRequired,
  discount: PropTypes.number.isRequired,
  originalPrice: PropTypes.any.isRequired,
}

const Processes = () => {
  const { state } = useAddReceptionContext()
  const { selectedServices, packageDetails, packageValue } = state

  const packageProcess = packageDetails?.processes || []

  const getTotalServices = (services) => {
    return services.reduce((accumulator, current) => {
      return (
        accumulator +
        getTotalWithDiscount(
          current?.process_placed_price,
          current?.process_placed_discount
        )
      )
    }, 0)
  }

  if (!packageDetails && selectedServices.length < 1) {
    return (
      <ProcessesWrapper>
        <EmptyState text="Sin procesos registrados" />
      </ProcessesWrapper>
    )
  }

  return (
    <ProcessesWrapper>
      {packageDetails && (
        <div className="process-group">
          <div className="header">
            <h2>Paquete</h2>
            <p>
              $
              {getTotalWithDiscount(
                packageDetails.packagePrice,
                packageDetails.packageDiscount
              ).toFixed(2)}
            </p>
          </div>
          <p>{packageValue.label}</p>
          <div className="group">
            {packageProcess.length > 0 ? (
              packageProcess.map((item) => (
                <Process
                  name={item?.processName}
                  price={getTotalWithDiscount(
                    item?.processPrice,
                    packageDetails.packageDiscount
                  )}
                  originalPrice={item?.processPrice}
                  key={item?.processName}
                />
              ))
            ) : (
              <EmptyState text="Sin procesos registrados" />
            )}
          </div>
        </div>
      )}
      {selectedServices.length > 0 && (
        <div className="process-group">
          <div className="header">
            <h2>Servicios Adicionales</h2>
            <p>${getTotalServices(selectedServices).toFixed(2)}</p>
          </div>
          <div className="group">
            {selectedServices.length > 0 &&
              selectedServices.map((item) => (
                <Process
                  name={item.processName}
                  discount={item.process_placed_discount}
                  price={getTotalWithDiscount(
                    item.process_placed_price,
                    item.process_placed_discount
                  )}
                  originalPrice={item.process_placed_price}
                  key={item?.processName}
                />
              ))}
          </div>
        </div>
      )}
    </ProcessesWrapper>
  )
}

export default Processes
