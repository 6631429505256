import React from 'react'
import PropTypes from 'prop-types'

import { GradientText } from './GradientText.style'

const GradientTextComponent = ({ text, style }) => {
  return <GradientText style={{ ...style }}>{text}</GradientText>
}

GradientTextComponent.propTypes = {
  text: PropTypes.string.isRequired,
  style: PropTypes.object,
}

GradientTextComponent.defaultProps = {
  style: {},
}

export default GradientTextComponent
