import styled from 'styled-components'

export const UploadModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 16px;

  .thumbnail {
    width: 160px;
    height: 160px;
    display: flex;
    margin: auto;
    border: none;
  }
`

export const UploadModalMessage = styled.div`
  font-size: 1.1em;
  font-weight: 600;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  .message-icon {
    font-size: 24px;
    margin-bottom: 5px;

    &--warn {
      color: #b50012;
      animation: warnColorAnimation 1s linear infinite;

      @keyframes warnColorAnimation {
        0% {
          color: transparent;
        }
        50% {
          color: #b50012;
        }
        100% {
          color: transparent;
        }
      }
    }

    &--info {
      color: #0d72ff;
      animation: infoColorAnimation 1s linear infinite;

      @keyframes infoColorAnimation {
        0% {
          color: transparent;
        }
        50% {
          color: #0d72ff;
        }
        100% {
          color: transparent;
        }
      }
    }

    &--correct {
      color: #04d91a;
      animation: correctColorAnimation 1s linear infinite;

      @keyframes correctColorAnimation {
        0% {
          color: transparent;
        }
        50% {
          color: #04d91a;
        }
        100% {
          color: transparent;
        }
      }
    }
  }

  p {
    margin: 0;
    text-align: center;
  }
`
