import { GET_USERS_APP, UPDATE_USER_APP } from '../actionTypes/UserAppTypes'

const UserAppReducer = (
  state = {
    usersApp: {},
  },
  action
) => {
  switch (action.type) {
    case GET_USERS_APP: {
      return {
        ...state,
        usersApp: action.payload,
      }
    }
    case UPDATE_USER_APP: {
      return {
        ...state,
        usersApp: {
          ...state.usersApp,
          [action.payload.user_app_id]: action.payload,
        },
      }
    }
    default: {
      return state
    }
  }
}

export default UserAppReducer
