import { Modal } from 'antd'
import styled from 'styled-components'

const AddProcessModalWrapper = styled(Modal)`
  &.add-process {
    .ant-modal-content {
      .ant-modal-header {
        .ant-modal-title {
          font-size: 1.8rem;
        }
      }
      .ant-modal-body {
        margin: 20px 0 34px 0;
        .main-inputs-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 12px;
          .ant-select-selection-item {
            max-width: 90px;
          }
          .ant-select-selector,
          .ant-select-arrow {
            border-color: #acaeb0;
            color: black;
            span {
              color: black;
            }
          }
          .disclaimer {
            width: fit-content;
            padding: 4px 8px;
            border-radius: 4px;
            margin-bottom: 12px;
            display: flex;

            background-color: #eeeeee;

            align-items: center;
            p {
              font-size: 12px;
              margin: unset;
            }
          }
          .discount {
            margin: unset;
            width: 35%;
            font-weight: 500;
            font-size: 1.4rem;
            span {
              border-color: #acaeb0;
            }
          }
        }
        .selectedOptions {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .loader {
            padding: 20px 0;
          }
          h2 {
            font-family: inherit;
            font-size: 1.6rem;
          }
          .process {
            position: relative;
            padding: 12px 8px;
            border-radius: 8px;
            border: #acaeb0 solid 1px;
            display: flex;
            flex-direction: column;
            gap: 8px;

            .delete {
              position: absolute;
              right: -5px;
              top: 2px;
              background: unset;
              margin: unset;
              border: unset;
              font-size: 1.6rem;
              color: #e33244;
              &:hover {
                opacity: 0.7;
              }
            }

            .process-name-wrapper {
              display: flex;
              flex-direction: column;
              gap: 8px;
              h4,
              p {
                margin: unset;
              }

              h4 {
                font-size: 1.6rem;
                max-width: 80%;
                margin-bottom: 1rem;
              }
              p {
                margin-top: 16px;
                font-size: 1.4rem;
              }
            }

            div.process-information {
              width: 100%;
              display: flex;
              gap: 8px;
              justify-content: space-between;

              align-items: start;
              flex-wrap: wrap;

              div {
                p {
                  margin-bottom: 8px;
                }
              }
            }

            .secondary-inputs-wrapper {
              display: inline-flex;
              max-width: 100px;
              label {
                font-size: 1.3rem;
                font-weight: 500;

                input {
                  border: #acaeb0 solid 1px;
                  width: 100%;
                  border-radius: 4px;
                  padding: 4px 8px;
                  background-color: #eeeeee;
                }
              }
            }

            .subtotal-wrapper {
              text-align: right;
              font-weight: 500;
              margin: unset;
              margin-top: 8px;
            }
          }
        }

        .totals-wrapper {
          display: flex;
          flex-direction: column;
          gap: 10px;
          justify-content: right;
          margin-top: 24px;
          .total {
            text-align: right;
            font-size: 1.6rem;
            margin: 0;
          }
        }
      }
    }
  }
`

export default AddProcessModalWrapper
