import React, { useMemo, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { Select, Input } from 'antd'
import PropTypes from 'prop-types'
import { DeleteFilled, ExclamationCircleOutlined } from '@ant-design/icons';
import { updateWorkOrderProcess } from '../../../../api/apiAddProcessService'
import { getTimeString, NotificationHandler } from '../../../../Helpers'
import { useServicesTab } from '../../../../hooks/useServicesTab'
import AddProcessModalWrapper from './index.style'
import { AppLoader } from '../AppLoader'

const { Option } = Select

const AddProcessModal = ({
  isOpen,
  setOpen,
  setTab,
  workOrderId,
  // Only used when this modal is opened from "Add something" modal
  setOptionsModal = null,
  processes,
}) => {
  const processesArray = useMemo(() => Object.values(processes), [processes])

  const {
    totalEstimatedTime,
    value,
    selectedServices: selectedProcesses,
    discount,
    openOptions,
    selectCustomFilter,
    setOpenOptions,
    getTotal,
    getTotalWithDiscount,
    resetValues,
    deleteService,
    handlers,
  } = useServicesTab(processesArray)

  const { handleChange, handlePriceChange, handleDiscountChange } = handlers

  const [loading, setLoading] = useState(false)

  const resetAllValues = () => {
    resetValues()
    setOpen(false)
    setLoading(false)
  }

  const handleOk = async () => {
    try {
      setLoading(true)

      // if we use .map() the promises do not execute in order
      const addProcess = async (index) => {
        if (index >= selectedProcesses.length) {
          await setTab('1')
          resetAllValues()
          NotificationHandler('¡Listo!', 'success', 'Procesos agregados')
          return
        }

        const product = selectedProcesses[index]
        await updateWorkOrderProcess(workOrderId, product)

        await addProcess(index + 1)
      }

      await addProcess(0)
    } catch (error) {
      NotificationHandler(
        'Error',
        'error',
        'No se pudieron agregar los procesos'
      )
    }
  }

  const handleCancel = () => {
    if (setOptionsModal) {
      setOptionsModal(true)
    }
    resetAllValues()
  }

  const selectProps = {
    mode: 'multiple',
    style: {
      width: '100%',
    },
    value,
    options: processesArray.map((process) => ({
      label: `${process.process_name}`,
      value: process.process_id,
    })),
    onChange: handleChange,
    placeholder: '¿Qué procesos desea agregar?',
    maxTagCount: 'responsive',
    filterOption: selectCustomFilter,
  }

  return (
    <AddProcessModalWrapper
      title="Agregar Proceso"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      className="add-process"
      okText="Agregar"
      cancelText="Cancelar"
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.76)' }}
      okButtonProps={{
        style: {
          backgroundColor:
            selectedProcesses.length <= 0 ? '#eeeeee' : '#1E1E40',
          display: loading ? 'none' : 'inline-block',
        },
        disabled: selectedProcesses.length <= 0,
      }}
      cancelButtonProps={{
        style: {
          display: loading ? 'none' : 'inline-block',
        },
      }}
    >
      <div className="main-inputs-wrapper">
        <Select
          className="select"
          mode={selectProps.mode}
          style={selectProps.style}
          value={selectProps.value}
          options={selectProps.options}
          onChange={selectProps.onChange}
          placeholder={selectProps.placeholder}
          maxTagCount={selectProps.maxTagCount}
          filterOption={selectProps.filterOption}
          open={openOptions}
          onDropdownVisibleChange={(visible) => setOpenOptions(visible)}
        >
          {processesArray?.map((option) => (
            <Option key={option.process_id} value={option.process_id}>
              {`${option.process_name}`}
            </Option>
          ))}
        </Select>
        {selectedProcesses.length === 1 && (
          <div className="disclaimer">
            <ExclamationCircleOutlined style={{ paddingRight: '7px' }} />
            <p>Puedes seleccionar más de un proceso</p>
          </div>
        )}
        <label htmlFor="discount" className="discount">
          Descuento:
          <Input
            suffix="%"
            min={1}
            type="number"
            max={100}
            inputMode="numeric"
            numeric="true"
            digitonly="true"
            itemID="discount"
            itemType="number"
            defaultValue={0}
            value={discount}
            onChange={(e) => handleDiscountChange(e.target.value)}
          />
        </label>
      </div>
      {selectedProcesses.length > 0 && (
        <div className="selectedOptions">
          <h2>Procesos a agregar: </h2>
          {loading && (
            <div className="loader">
              <AppLoader text="Agregando procesos" />
            </div>
          )}
          {!loading &&
            selectedProcesses?.map((process) => (
              <div className="process" key={process.process_id}>
                <button
                  type="button"
                  className="delete btn-antd-icon"
                  onClick={() => deleteService(process.process_id)}
                >
                  <DeleteFilled />
                </button>
                <div className="process-name-wrapper">
                  <h4>{process.process_name}</h4>
                </div>
                <div className="process-information">
                  <div>
                    <p>
                      <b>Precio actual: </b>${process.process_price.toFixed(2)}
                    </p>
                    <p>
                      <b>Tiempo estimado: </b> {process.process_estimated_time}{' '}
                      minutos
                    </p>
                  </div>
                  <div className="secondary-inputs-wrapper">
                    <label htmlFor="price" className="price">
                      Precio:
                      <CurrencyInput
                        id="price"
                        name="price"
                        min={0}
                        prefix="$"
                        decimalSeparator="."
                        step={0.01}
                        groupSeparator=","
                        defaultValue={parseFloat(process.process_price).toFixed(
                          2
                        )}
                        allowNegativeValue={false}
                        value={process.process_placed_price}
                        decimalsLimit={2}
                        onValueChange={(price) =>
                          handlePriceChange(price, process.process_id)
                        }
                      />
                    </label>
                  </div>
                </div>
                <p className="subtotal-wrapper">
                  Sub-Total: ${' '}
                  {getTotalWithDiscount(
                    process.process_placed_price,
                    process.process_placed_discount
                  ).toFixed(2)}
                </p>
              </div>
            ))}
          <div className="totals-wrapper">
            <p className="total">
              <b>Tiempo total: </b> {getTimeString(totalEstimatedTime)}
            </p>
            <p className="total">
              <b>Total: </b> ${getTotal()}
            </p>
          </div>
        </div>
      )}
    </AddProcessModalWrapper>
  )
}

export default AddProcessModal

const processType = PropTypes.shape({
  activities: PropTypes.arrayOf(PropTypes.any).isRequired,
  created_at: PropTypes.any,
  isUsed: PropTypes.bool.isRequired,
  process_active: PropTypes.number.isRequired,
  process_estimated_time: PropTypes.number.isRequired,
  process_id: PropTypes.number.isRequired,
  process_name: PropTypes.string.isRequired,
  process_price: PropTypes.number.isRequired,
  updated_at: PropTypes.any,
  workshop_id: PropTypes.number.isRequired,
})

AddProcessModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setTab: PropTypes.func.isRequired,
  setOptionsModal: PropTypes.func,
  processes: PropTypes.arrayOf(processType).isRequired,
  workOrderId: PropTypes.number.isRequired,
}

AddProcessModal.defaultProps = {
  setOptionsModal: null,
}