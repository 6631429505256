import React from 'react'

export const AddPhotoIcon = () => {
  return (
    <svg
      width="44"
      height="41"
      viewBox="0 0 44 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36 34H4C1.8 34 0 32.2 0 30V4C0 1.8 1.8 0 4 0H36C38.2 0 40 1.8 40 4V30C40 32.2 38.2 34 36 34Z"
        fill="#8CBCD6"
      />
      <path
        d="M31 12C32.6569 12 34 10.6569 34 9C34 7.34315 32.6569 6 31 6C29.3431 6 28 7.34315 28 9C28 10.6569 29.3431 12 31 12Z"
        fill="#B3DDF5"
      />
      <path d="M16 9L5 25H27L16 9Z" fill="#9AC9E3" />
      <path d="M27 15L19 25H35L27 15Z" fill="#B3DDF5" />
      <path
        d="M34 41C39.5228 41 44 36.5228 44 31C44 25.4772 39.5228 21 34 21C28.4772 21 24 25.4772 24 31C24 36.5228 28.4772 41 34 41Z"
        fill="#43A047"
      />
      <path d="M36 25H32V37H36V25Z" fill="white" />
      <path d="M40 29H28V33H40V29Z" fill="white" />
    </svg>
  )
}
