import _ from 'lodash'

import {
  GET_ITEMS,
  ADD_ITEM,
  UPDATE_ITEM,
  DELETE_ITEM,
} from '../actionTypes/itemTypes'

const ItemReducer = (
  state = {
    items: {},
  },
  action
) => {
  if (action.type === GET_ITEMS) {
    return {
      ...state,
      items: action.payload,
    }
  }
  if (action.type === ADD_ITEM || action.type === UPDATE_ITEM) {
    return {
      ...state,
      items: {
        ...state.items,
        [action.payload.item_id]: action.payload,
      },
    }
  }
  if (action.type === DELETE_ITEM) {
    const newState = _.cloneDeep(state)
    delete newState.items[action.payload.item_id]
    return newState
  }

  return state
}

export default ItemReducer
