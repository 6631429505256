// https://tailwindcss.com/docs/breakpoints --> mobile first media queries
const createCSSMediaQuery = (units, minMax = 'min') =>
  `${minMax}-width: ${units}`

// https://getbootstrap.com/docs/3.4/customize/#media-queries-breakpoints
const BREAK_POINTS = {
  MOBILE: createCSSMediaQuery('480px'),
  TABLET: createCSSMediaQuery('768px'),
  TABLET_LARGE: createCSSMediaQuery('875px'),
  DESKTOP: createCSSMediaQuery('992px'),
  DESKTOP_LARGE: createCSSMediaQuery('1200px'),
  DESKTOP_EXTRA_LARGE: createCSSMediaQuery('1400px'),
}

const BREAK_POINTS_DESKTOP_FIRST = {
  MOBILE: createCSSMediaQuery('480px', 'max'),
  TABLET: createCSSMediaQuery('768px', 'max'),
  DESKTOP: createCSSMediaQuery('992px', 'max'),
  DESKTOP_LARGE: createCSSMediaQuery('1200px', 'max'),
  DESKTOP_EXTRA_LARGE: createCSSMediaQuery('1400px', 'max'),
}

export default BREAK_POINTS
export { BREAK_POINTS_DESKTOP_FIRST }
