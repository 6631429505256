import {
  ADD_NOTIFICATION_REF,
  LOG_IN,
  LOG_OUT,
  SET_CURRENT_USER,
  CLOSE_SIDEBAR,
  OPEN_SIDEBAR,
  UPDATE_PROFILE,
  SET_USER_PERMISSIONS,
  SET_WORKSHOP_COUNTRY_CODE,
  UPDATE_NOTIFICATION_COUNT,
} from '../actionTypes/AppTypes'

export default (
  state = {
    NotificationContainer: null,
    logged: false,
    currentUser: {},
    workshopType: 0,
    workshopId: null,
    sidebarOpen: false,
    permissions: {},
    socket: '',
    notificationsCount: 0,
  },
  action
) => {
  switch (action.type) {
    case ADD_NOTIFICATION_REF: {
      return {
        ...state,
        NotificationContainer: action.payload,
      }
    }
    case UPDATE_PROFILE: {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.payload,
        },
      }
    }
    case LOG_IN: {
      return {
        ...state,
        logged: true,
      }
    }
    case LOG_OUT: {
      return {
        ...state,
        logged: false,
      }
    }
    case SET_CURRENT_USER: {
      return {
        ...state,
        currentUser: action.payload,
        workshopType: action.payload?.workshop?.workshop_type,
        workshopId: action.payload?.workshop?.workshop_id,
      }
    }
    case OPEN_SIDEBAR: {
      return {
        ...state,
        sidebarOpen: true,
      }
    }
    case CLOSE_SIDEBAR: {
      return {
        ...state,
        sidebarOpen: false,
      }
    }
    case SET_USER_PERMISSIONS: {
      return {
        ...state,
        permissions: action.payload,
      }
    }
    case 'SET_SOCKET': {
      return {
        ...state,
        socket: action.payload,
      }
    }
    case SET_WORKSHOP_COUNTRY_CODE: {
      return {
        ...state,
        workshopCountryCode: action.payload,
      }
    }
    case UPDATE_NOTIFICATION_COUNT: {
      return {
        ...state,
        notificationsCount: action.payload,
      }
    }
    default: {
      return state
    }
  }
}
