import axios from 'axios'
import _ from 'lodash'

import {
  GET_ITEMS,
  ADD_ITEM,
  UPDATE_ITEM,
  DELETE_ITEM,
} from '../actionTypes/itemTypes'
import { NotificationHandler } from '../Helpers'

const getItemAction = (payload) => ({
  type: GET_ITEMS,
  payload,
})

const addItemAction = (payload) => ({
  type: ADD_ITEM,
  payload,
})

const updateItemAction = (payload) => ({
  type: UPDATE_ITEM,
  payload,
})

const deleteItemAction = (payload) => ({
  type: DELETE_ITEM,
  payload,
})

const getItems = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const itemsQuantity = _.size(getState().ItemReducer.items)
    if (itemsQuantity === 0) {
      axios
        .get('/api/items')
        .then((response) => {
          if (!response.data.error) {
            const items = _.keyBy(response.data.data, 'item_id')
            dispatch(getItemAction(items))
            resolve(response.data)
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al obtener items',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler('Error', 'error', 'Error al obtener items', error)
        })
    } else {
      resolve({})
    }
  })

const addItem = (item) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { workshop_id } = getState().AppReducer.currentUser
    axios
      .post('/api/items', { ...item, workshop_id })
      .then((response) => {
        if (!response.data.error) {
          dispatch(addItemAction(response.data.data))
          resolve(response)
          NotificationHandler('Agregado', 'success', `Item agregado`)
        } else {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al obtener items',
            response.data.data
          )
        }
      })
      .catch((error) => {
        reject()
        NotificationHandler('Error', 'error', 'Error al agregar items', error)
      })
  })

const updateItem = (item) => (dispatch) =>
  new Promise((resolve, reject) => {
    axios
      .put(`/api/items/${item.id}`, item)
      .then((response) => {
        if (!response.data.error) {
          dispatch(updateItemAction(response.data.data))
          resolve(response)
        } else {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al actualizar el item',
            response.data.data
          )
        }
      })
      .catch((error) => {
        reject()
        NotificationHandler(
          'Error',
          'error',
          'Error al actualizar el item',
          error
        )
      })
  })

const deleteItem = (item) => (dispatch) =>
  new Promise((resolve, reject) => {
    axios
      .delete(`/api/items/${item.id}`)
      .then((response) => {
        if (!response.data.error) {
          dispatch(deleteItemAction(response.data.data))
          resolve(response)
        } else {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al eliminar item',
            response.data.data
          )
        }
      })
      .catch((error) => {
        reject()
        NotificationHandler(
          'Error',
          'error',
          'Error al eliminar elementos',
          error
        )
      })
  })

export { getItems, addItem, updateItem, deleteItem }
