import axios from 'axios'
import _ from 'lodash'

import {
  GET_SERIES,
  ADD_SERIES,
  UPDATE_SERIES,
  DELETE_SERIES,
  UPDATE_ACTUAL_NUMBER,
} from '../actionTypes/SeriesTypes'
import { NotificationHandler } from '../Helpers'

const getSeriesAction = (payload) => ({
  type: GET_SERIES,
  payload,
})

const addSeriesAction = (payload) => ({
  type: ADD_SERIES,
  payload,
})

const updateSeriesAction = (payload) => ({
  type: UPDATE_SERIES,
  payload,
})

const deleteSeriesAction = (payload) => ({
  type: DELETE_SERIES,
  payload,
})

const updateActualNumberAction = (payload) => ({
  type: UPDATE_ACTUAL_NUMBER,
  payload,
})

export const getSeries = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const seriesQuantity = _.size(getState().SeriesReducer.series)
    if (seriesQuantity === 0) {
      axios
        .get('/api/series')
        .then((response) => {
          if (!response.data.error) {
            const series = _.keyBy(response.data.data, 'serie_id')
            dispatch(getSeriesAction(series))
            resolve(response.data)
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al obtener la serie',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al obtener la serie',
            error
          )
        })
    } else {
      resolve({})
    }
  })

export const addSeries = (serie) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { workshop_id } = getState().AppReducer.currentUser
    axios
      .post('/api/series', { ...serie, workshop_id })
      .then((response) => {
        if (!response.data.error) {
          const seriesToRedux = {
            ...response.data.data,
            invoiceTypes:
              getState().InvoiceTypeReducer.invoiceTypes[serie.invoice_type_id],
          }
          dispatch(addSeriesAction(seriesToRedux))
          resolve(response)
          NotificationHandler('Done', 'info', 'Serie agregada')
        } else {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al agregar la serie',
            response.data.data
          )
        }
      })
      .catch((error) => {
        reject()
        NotificationHandler(
          'Error',
          'error',
          'Error al agregar la serie',
          error
        )
      })
  })

export const updateSeries = (serie) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    if (serie.serie_id) {
      const { workshop_id } = getState().AppReducer.currentUser
      axios
        .put(`/api/series/${serie.serie_id}`, { ...serie, workshop_id })
        .then((response) => {
          if (!response.data.error) {
            const seriesToRedux = {
              ...response.data.data,
              invoiceTypes:
                getState().InvoiceTypeReducer.invoiceTypes[
                  serie.invoice_type_id
                ],
            }
            dispatch(updateSeriesAction(seriesToRedux))
            resolve(response)
            NotificationHandler(
              'Done',
              'info',
              'Serie actualizada',
              response.data.data
            )
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al actualizar Serie',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al actualizar Serie',
            error
          )
        })
    } else {
      reject()
      NotificationHandler(
        'Error',
        'error',
        `No se proporcionó id a: ${serie.serie_number}`
      )
    }
  })

export const deleteSeries = (serie) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (serie.serie_id) {
      axios
        .delete(`/api/series/${serie.serie_id}`)
        .then((response) => {
          if (response.data.error) {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al eliminar la serie',
              response.data.data
            )
          } else {
            dispatch(deleteSeriesAction(serie))
            resolve(response)
            NotificationHandler(
              'Done',
              'success',
              'Serie eliminada',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al eliminar la serie',
            error
          )
        })
    } else {
      reject()
      NotificationHandler(
        'Error',
        'error',
        `No se proporcionó id a: ${serie.serie_number}`
      )
    }
  })

export const updateActualNumber = (serie_id) => (dispatch) =>
  new Promise((resolve, reject) => {
    axios
      .put(`/api/series/updateActualNumber/${serie_id}`)
      .then((response) => {
        if (response.data.error) {
          NotificationHandler(
            'Error al actualizar el número de factura',
            'error'
          )
        } else {
          const { serie_actual_number } = response.data.data
          dispatch(updateActualNumberAction({ serie_id, serie_actual_number }))
          resolve(response.data)
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
