import React, { Component } from 'react'
import { connect } from 'react-redux'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory from 'react-bootstrap-table2-filter'
import { Panel, Grid, Row, Col, Button } from 'react-bootstrap'
import { PlusCircleFilled } from '@ant-design/icons';
import _ from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import {
  getPackages,
  addPackage,
  updatePackage,
  deletePackage,
} from '../../../../actions/PackageAction'
import { getServiceTypes } from '../../../../actions/ServiceTypeAction'
import {
  Container,
  ContainerHeader,
  BodyContent,
  showConfirmDialog,
  PanelHeader,
} from '../../Common/index'
import PackageModal from './PackageModal'
import getColumns from './columns'
import { getProcesses } from '../../../../actions/ProcessAction'
import { BREAK_POINTS_DESKTOP_FIRST } from '../../../../global/css/breakPoints'
import { AppLoader } from '../../Common/AppLoader'

const SCREEN_ID = 17

class PackageContainer extends Component {
  state = {
    showModal: false,
    loading: true,
    selectedPackage: {},
  }

  componentDidMount() {
    const { getAllPackages, getAllServiceTypes, getAllProcesses } = this.props
    getAllPackages()
      .then(() => {
        this.setState({ loading: false })
      })
      .catch(() => {
        this.setState({ loading: false })
      })
    getAllServiceTypes()
    getAllProcesses()
  }

  toggleModal = () => {
    const { showModal } = this.state
    this.setState({ showModal: !showModal })
  }

  addPackage = () => {
    this.setState({ selectedPackage: {} })
    this.toggleModal()
  }

  updatePackage = (_package) => {
    this.setState({ selectedPackage: _package })
    this.toggleModal()
  }

  deletePackage = (_package) => {
    const { removePackage } = this.props
    showConfirmDialog(
      '¿Eliminar?',
      `¿Desea eliminar el packageo ${_package.package_name}?`,
      removePackage,
      _package
    )
  }

  render() {
    const { showModal, selectedPackage, loading } = this.state
    const {
      serviceTypes,
      packages,
      processes,
      permissions,
      createPackage,
      modifyPackage,
    } = this.props
    const { can_write, can_edit, can_delete } = permissions[SCREEN_ID]
    return (
      <Container>
        <ContainerHeader title="Paquetes" />
        <BodyContent>
          <Grid fluid>
            <Row>
              <Col sm={12}>
                <Panel>
                  <Panel.Heading bsClass="panel-heading">
                    <Panel.Title>
                      <PanelHeader>
                        <div>
                          <p id="title">
                            <i className="fas fa-bars prefix prefix" />{' '}
                            Administrar Paquetes
                          </p>
                        </div>
                        <div>
                          {!!can_write && (
                            <Button
                              onClick={this.addPackage}
                              bsStyle="success"
                              className="btn-add btn-antd-icon"
                            >
                              <PlusCircleFilled />
                              Agregar registro
                            </Button>
                          )}
                        </div>
                      </PanelHeader>
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    {loading ? (
                      <AppLoader />
                    ) : (
                      <Row>
                        <Col md={10} />
                        <Col md={12}>
                          <CustomStyledTable>
                            <BootstrapTable
                              noDataIndication="Sin datos para mostrar"
                              keyField="package_id"
                              data={_.values(packages)}
                              columns={getColumns(
                                this.updatePackage,
                                this.deletePackage,
                                { can_edit, can_delete }
                              )}
                              filter={filterFactory()}
                              pagination={paginationFactory()}
                            />
                          </CustomStyledTable>
                        </Col>
                      </Row>
                    )}
                  </Panel.Body>
                </Panel>
              </Col>
            </Row>
          </Grid>
          <PackageModal
            modalOpen={showModal}
            toggleModal={this.toggleModal}
            editMode={!_.isEmpty(selectedPackage)}
            serviceTypes={serviceTypes}
            addPackage={createPackage}
            updatePackage={modifyPackage}
            selectedPackage={selectedPackage}
            processes={processes}
          />
        </BodyContent>
      </Container>
    )
  }
}

const CustomStyledTable = styled.div`
  @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
    table {
      border: none;
      padding: 0px 10px;

      thead {
        tr {
          display: flex;
          flex-direction: column;

          th {
            label {
              width: 100%;
            }
          }

          th {
            border: none;
          }

          th:nth-last-child(1),
          th:nth-last-child(2) {
            display: none;
          }
        }
      }

      tbody {
        tr {
          border: 1px solid #ddd;
          display: flex;
          flex-wrap: wrap;
          border-radius: 10px;
          margin: 20px 10px;
          padding-top: 10px;
          padding-bottom: 10px;

          td {
            width: 100%;
            border: 0;
            border-right: 0px !important;
            border-left: 0px !important;
          }

          td:nth-last-child(1),
          td:nth-last-child(2) {
            width: 40%;
          }

          td::before {
            font-weight: bold;
            margin-right: 0.5rem;
          }

          td:nth-child(1)::before {
            content: 'Nombre:';
          }

          td:nth-child(2)::before {
            content: 'Precio:';
          }

          td:nth-child(3)::before {
            content: 'Área de servicio:';
          }
        }
      }
    }
  }
`

const mapStateToProps = (store) => ({
  packages: store.PackageReducer.packages,
  serviceTypes: store.ServiceTypeReducer.service_types,
  processes: store.ProcessReducer.processes,
  permissions: store.AppReducer.permissions,
})

const mapDispatchToProps = (dispatch) => ({
  getAllPackages() {
    return dispatch(getPackages())
  },
  createPackage(_package, processes) {
    return dispatch(addPackage(_package, processes))
  },
  modifyPackage(_package, processes) {
    return dispatch(updatePackage(_package, processes))
  },
  removePackage(_package) {
    return dispatch(deletePackage(_package))
  },
  getAllServiceTypes() {
    dispatch(getServiceTypes())
  },
  getAllProcesses() {
    dispatch(getProcesses())
  },
})

PackageContainer.propTypes = {
  getAllPackages: PropTypes.func.isRequired,
  createPackage: PropTypes.func.isRequired,
  modifyPackage: PropTypes.func.isRequired,
  removePackage: PropTypes.func.isRequired,
  getAllServiceTypes: PropTypes.func.isRequired,
  getAllProcesses: PropTypes.func.isRequired,
  packages: PropTypes.arrayOf(PropTypes.object).isRequired,
  serviceTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  processes: PropTypes.arrayOf(PropTypes.object).isRequired,
  permissions: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(PackageContainer)
