import React from 'react'
import { Table, Button, Alert } from 'react-bootstrap'
import _ from 'lodash'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { EyeFilled, PlusCircleFilled } from '@ant-design/icons'
import ProductOrderDetail from './ProductOrderDetail'
import { getWorkOrderTotal } from '../functions'


const ProductOrderDetails = ({
  workOrderDetails,
  products,
  handleChangeDetail,
  deleteWorkOrderDetail,
  addWorkOrderDetail,
  handleChangeSelectDetail,
  editMode,
}) => (
  <DetailsContainer>
    <h3>Repuestos</h3>
    <div style={{ textAlign: "right", marginBottom: "10px" }}>
      <span style={{ fontSize: "12px" }}>
        Para una búsqueda más exacta puede ir a al Inventario y copiar el código del repuesto que deseas agregar
      </span>
      <br />
      <Button
        onClick={() => {
          window.open(
            '/products',
            '_blank' // <- This is what makes it open in a new window.
          );
        }} // no refresh page
        bsStyle="success"
        className="btn-add btn-antd-icon"
      >
        <EyeFilled />
        Ir al Inventario
      </Button>
    </div>
    <Table className="compact">
      <tbody>
        {_.map(workOrderDetails, (detail, key) => {
          if (!detail.isDeleted) {
            return (
              <ProductOrderDetail
                detail={detail}
                workOrderDetails={workOrderDetails}
                products={products}
                handleChangeDetail={handleChangeDetail}
                handleChangeSelectDetail={handleChangeSelectDetail}
                key={key}
                id={key}
                deleteWorkOrderDetail={deleteWorkOrderDetail}
                editMode={editMode}
              />
            )
          }
          return null
        })}
      </tbody>
    </Table>

    <div className="container-add-aditional-buttons">
      <Button onClick={() => addWorkOrderDetail('product')} bsStyle="success" className='btn-antd-icon'>
        <PlusCircleFilled />
        Agregar repuesto
      </Button>
    </div>

    <h3>Totales</h3>
    <div className="container-final-total">
      <Alert bsStyle="info">
        <p>
          Total de la venta: <b>{getWorkOrderTotal(workOrderDetails)}</b>
        </p>
      </Alert>
    </div>
  </DetailsContainer>
)

const DetailsContainer = styled.div`
  .container-final-total {
    border: 1px solid #ddd;
    border-radius: 10px;
  }

  .container-add-aditional-buttons {
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;

    button {
      border-radius: 4px !important;

      &:nth-child(1) {
        margin-right: 1.5rem;
      }
    }
  }

  .container-final-total {
    padding: 1rem;
  }

  table.compact {
    margin-top: 2rem;

    tbody {
      tr {
        margin-bottom: 4rem;
        border: 1px solid #9b9a9a;
        border-radius: 10px;
        display: flex;
        flex-direction: column;

        td {
          border: 0;
          width: 100%;
        }

        td:nth-child(1) {
          text-align: center;
          font-weight: bold;
        }
      }
    }
  }
`

ProductOrderDetails.propTypes = {
  workOrderDetails: PropTypes.arrayOf(PropTypes.object).isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  editMode: PropTypes.bool.isRequired,
  handleChangeDetail: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleChangeSelectDetail: PropTypes.arrayOf(PropTypes.object).isRequired,
  deleteWorkOrderDetail: PropTypes.arrayOf(PropTypes.object).isRequired,
  addWorkOrderDetail: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default ProductOrderDetails
