import React, { Component } from 'react'
import { connect } from 'react-redux'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory from 'react-bootstrap-table2-filter'
import { Panel, Grid, Row, Col, Button } from 'react-bootstrap'
import _ from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Steps } from 'intro.js-react';
import "intro.js/introjs.css";
import { MenuOutlined } from '@ant-design/icons';
import {
  Container,
  ContainerHeader,
  BodyContent,
  PanelHeader,
} from '../Common/index'

import getColumns from './columns'
import {
  addProduct,
  getProducts,
  updateProduct,
  deleteProduct,
  modifyStockProduct,
  fullSearchMarketplace
} from '../../../actions/ProductActions'
import { getCategories } from '../../../actions/CategoryAction'
import { getProviders } from '../../../actions/ProviderAction'
import { getModels } from '../../../actions/ModelAction'
import { BREAK_POINTS_DESKTOP_FIRST } from '../../../global/css/breakPoints'
import { AppLoader } from '../Common/AppLoader'

const SCREEN_ID = 26

class MarketplaceContainer extends Component {
  state = {
    showModal: false,
    showModalModifyStock: false,
    loading: true,

    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".step1",
        position: "bottom",
        intro: "Puedes buscar por nombre y repuestos desde aquí",
        nextLabel: "Siguiente novedad"
      },
      {
        element: ".step2",
        position: "left",
        intro: "Los respuestos que compres del marketplace los podrás encontrar aquí",
        nextLabel: "Siguiente novedad"
      },
      {
        element: ".step3",
        position: "left",
        intro: "Las ventas de respuestos desde el marketplace las podrás ver aquí",
      }
    ]
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions)
    // const { getAllProducts, getAllCategories, getAllModels, getAllProviders } = this.props
    /*     getAllProducts()
          .then(() => {
            this.setState({ loading: false })
          })
          .catch(() => {
            this.setState({ loading: false })
          })
        getAllCategories()
        getAllModels()
        getAllProviders() */
    this.setState({ loading: false })
    this.toggleSteps();
  }



  toggleSteps = () => {
    this.setState(prevState => ({ stepsEnabled: !prevState.stepsEnabled }));
  };

  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
  };

  /*   componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions)
    } */

  updateDimensions = () => {
    const { width } = window.screen
    this.setState({ width })
  }

  toggleModal = () => {
    const { showModal } = this.state
    this.setState({ showModal: !showModal })
  }

  toggleModalModifyStock = () => {
    const { showModalModifyStock } = this.state
    this.setState({ showModalModifyStock: !showModalModifyStock })
  }

  addProduct = () => {
    this.toggleModal()
  }

  updateProduct = () => {
    this.toggleModal()
  }

  deleteProduct = () => {

  }

  addStockProduct = () => {
    this.toggleModalModifyStock()
  }

  removeStockProduct = () => {
    this.toggleModalModifyStock()
  }

  fullSearch = (e) => {
    const { getFullSearchMarketplace } = this.props
    const { value } = e.target
    getFullSearchMarketplace(value)
  }


  render() {
    const { loading, width, steps, initialStep, stepsEnabled } = this.state // Extract state to const to use in render
    const { products, permissions } =
      this.props
    const { can_edit, can_delete } = permissions[SCREEN_ID]




    return (
      <Container>
        <ContainerHeader title="Marketplace" />
        <BodyContent>
          <Grid fluid>
            <Row>
              <Col sm={12}>
                <Panel>
                  <Panel.Heading bsClass="panel-heading">
                    <Panel.Title>
                      <PanelHeader>
                        <div>
                          <p id="title">
                            <MenuOutlined style={{ marginRight: '7px' }} /> Administrar
                          </p>
                        </div>
                        <div>
                          <Button
                            onClick={() => {
                              window.location.href = '/products-movements'
                            }} // no refresh page
                            bsStyle="success"
                            className="btn-add step3"
                          >
                            &nbsp; <i className="fas fa-dollar-sign" />
                            {/* Ver movimientos */}
                          </Button>
                          <span> </span>
                          <Button
                            onClick={() => {
                              window.location.href = '/products-movements'
                            }} // no refresh page
                            bsStyle="success"
                            className="btn-add step2"
                          >
                            <i className="fas fa-shopping-cart" />
                            {/* Ver estadísticas */}
                          </Button>
                        </div>
                      </PanelHeader>
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    {loading ? (
                      <AppLoader />
                    ) : (
                      <>
                        <input
                          type="text"
                          placeholder="Escribe para realizar una búsqueda"
                          className="search-products step1"
                          onChange={(e) => this.fullSearch(e)}
                        />
                        <Row>
                          <Col md={10} />
                          <Col md={12}>
                            <CustomStyledTable>
                              <BootstrapTable
                                noDataIndication=""
                                keyField="product_id"
                                data={_.values(products)}
                                columns={getColumns(
                                  this.updateProduct,
                                  this.deleteProduct,
                                  this.addStockProduct,
                                  this.removeStockProduct,
                                  {
                                    can_edit,
                                    can_delete,
                                  },
                                  width
                                )}
                                filter={filterFactory()}
                                pagination={paginationFactory()}
                              />
                            </CustomStyledTable>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Panel.Body>
                </Panel>
              </Col>
            </Row>
          </Grid>
        </BodyContent>

        <Steps
          enabled={stepsEnabled}
          steps={steps}
          initialStep={initialStep}
          onExit={this.onExit}
          options={
            {
              nextLabel: "Siguiente novedad",
              prevLabel: "Anterior",
              doneLabel: "Entendido",
              hidePrev: true,
              hideNext: false, // done
              showStepNumbers: false,
              showBullets: false
            }
          }
        />

      </Container>
    )
  }
}

const CustomStyledTable = styled.div`
  @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
    table {
      border: none;
      padding: 0px 10px;

      thead {
        tr {
          display: flex;
          flex-direction: column;

          th {
            label {
              width: 100%;
            }
          }

          th {
            border: none;
          }

          th:nth-last-child(1),
          th:nth-last-child(2) {
            display: none;
          }
        }
      }

      tbody {
        tr {
          border: 1px solid #ddd;
          display: flex;
          flex-wrap: wrap;
          border-radius: 10px;
          margin: 20px 10px;
          padding-top: 10px;
          padding-bottom: 10px;

          td {
            width: 100%;
            border: 0;
            border-right: 0px !important;
            border-left: 0px !important;
          }

          td:nth-last-child(1),
          td:nth-last-child(2) {
            width: 40%;
          }

          td::before {
            font-weight: bold;
            margin-right: 0.5rem;
          }

          td:nth-child(1)::before {
            content: 'Nombre:';
          }

          td:nth-child(2)::before {
            content: 'Código:';
          }

          td:nth-child(3)::before {
            content: 'Precio:';
          }

          td:nth-child(4) {
            display: none !important;
          }

          td:nth-child(5)::before {
            content: 'Editar';
          }

          td:nth-child(6)::before {
            content: 'Agregar stock';
          }

          td:nth-child(7)::before {
            content: 'Remover stock';
          }

          td:nth-child(7) {
            width: 100%;
          }

          td:nth-child(8)::before {
            content: 'Eliminar';
          }
        }
      }
    }
  }
`

MarketplaceContainer.propTypes = {
  permissions: PropTypes.objectOf(PropTypes.object).isRequired,
}

const mapStateToProps = (store) => ({
  products: store.ProductReducer.products,
  categories: store.CategoryReducer.categories,
  models: store.ModelReducer.models,
  providers: store.ProviderReducer.providers, // mapeo por el state del reducer
  permissions: store.AppReducer.permissions,
})

const mapDispatchToProps = (dispatch) => ({
  getAllProducts() {
    return dispatch(getProducts())
  },
  createProduct(product) {
    return dispatch(addProduct(product))
  },
  modifyProduct(product) {
    return dispatch(updateProduct(product))
  },
  removeProduct(product) {
    return dispatch(deleteProduct(product))
  },
  getAllCategories() {
    dispatch(getCategories())
  },
  getAllModels() {
    dispatch(getModels())
  },
  modifyStockProduct1(product) {
    dispatch(modifyStockProduct(product))
  },
  getAllProviders() {
    dispatch(getProviders())
  },
  getFullSearchMarketplace(search) {
    return dispatch(fullSearchMarketplace(search))
  },
})

MarketplaceContainer.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  getFullSearchMarketplace: PropTypes.func.isRequired,
}
export default connect(mapStateToProps, mapDispatchToProps)(MarketplaceContainer)
