import { useCallback, useState } from 'react'

export const useProductsTab = (
  productsArray,
  initialSelectedProducts = [],
  isCamelCase = false
) => {
  // TODO: This is going to be deleted when new endpoint is completely integrated
  const idField = isCamelCase ? 'productId' : 'product_id'
  const priceField = isCamelCase ? 'productPrice' : 'product_price'
  const quantityField = isCamelCase ? 'productQuantity' : 'product_quantity'

  const initialDiscount =
    initialSelectedProducts?.length > 0
      ? initialSelectedProducts[0].product_placed_discount
      : 0

  const initialValues =
    initialSelectedProducts?.length > 0
      ? initialSelectedProducts.map((product) => product[idField])
      : []

  const [openOptions, setOpenOptions] = useState(false)
  const [value, setValue] = useState(initialValues)
  const [discount, setDiscount] = useState(initialDiscount)
  const [selectedProducts, setSelectedProducts] = useState(
    initialSelectedProducts
  )

  const customFilter = useCallback((inputValue, option) => {
    return option.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
  }, [])

  const resetValues = () => {
    setValue([])
    setDiscount(0)
    setSelectedProducts([])
  }

  const getTotalWithDiscount = (quantity, price, discountValue) => {
    let result = quantity * price

    if (discountValue > 0) {
      result -= (result * discountValue) / 100
    }
    return result ? parseFloat(result) : 0.0
  }

  const getTotal = () => {
    const productsTotal = selectedProducts.reduce(
      (accumulator, currentItem) => {
        return (
          accumulator +
          getTotalWithDiscount(
            currentItem.product_asked_quantity,
            currentItem.product_placed_price,
            currentItem.product_placed_discount
          )
        )
      },
      0
    )

    return productsTotal.toFixed(2)
  }

  const deleteProduct = (index) => {
    const updatedProducts = selectedProducts.filter(
      (item) => item[idField] !== index
    )
    const updatedValues = value.filter((number) => number !== index)

    setSelectedProducts(updatedProducts)
    setValue(updatedValues)
  }

  const handleChange = (newValue) => {
    setValue(newValue)

    const updatedSelectedProducts = selectedProducts.filter(
      (selectedProduct) => {
        const isSelectedProductInNewValue = newValue.includes(
          selectedProduct[idField]
        )
        return isSelectedProductInNewValue
      }
    )

    newValue.forEach((id) => {
      const isIdInSelectedProducts = selectedProducts.some(
        (selectedProduct) => selectedProduct[idField] === id
      )

      if (!isIdInSelectedProducts) {
        const productIndex = productsArray.findIndex(
          (product) => product[idField] === id
        )

        if (productIndex !== -1) {
          const product = productsArray[productIndex]
          const productWithFields = {
            ...product,
            product_asked_quantity: 1,
            product_placed_discount: discount,
            product_placed_price: parseFloat(product[priceField]).toFixed(2),
          }

          updatedSelectedProducts.push(productWithFields)
        }
      }
    })

    setSelectedProducts(updatedSelectedProducts)
    setOpenOptions(false)
  }

  // Handler to update "product_asked_quantity" on selectedProducts
  const handleQuantityChange = (event, productId) => {
    const newValue = event.target.value

    const indexToUpdate = selectedProducts.findIndex(
      (option) => option[idField] === productId
    )
    const updatedOptions = [...selectedProducts]

    const inventaryProductQuantity =
      updatedOptions[indexToUpdate][quantityField]

    if (newValue > inventaryProductQuantity) {
      updatedOptions[indexToUpdate].product_asked_quantity =
        inventaryProductQuantity
    } else {
      updatedOptions[indexToUpdate].product_asked_quantity = newValue
    }

    setSelectedProducts(updatedOptions)
  }

  // Handler to update "product_placed_price" on selectedProducts
  const handlePriceChange = (newPrice, productId) => {
    const indexToUpdate = selectedProducts.findIndex(
      (option) => option[idField] === productId
    )
    const updatedOptions = [...selectedProducts]

    updatedOptions[indexToUpdate].product_placed_price = newPrice

    setSelectedProducts(updatedOptions)
  }

  // Handler for change "product_placed_discount" on selectedProducts
  const handleDiscountChange = (newDiscount) => {
    const validateDiscount = Math.min(100, Math.max(0, newDiscount))

    const updateSelectedProducts = selectedProducts.map((product) => ({
      ...product,
      product_placed_discount: validateDiscount,
    }))

    setDiscount(validateDiscount)
    setSelectedProducts(updateSelectedProducts)
  }

  const selectNewProduct = (newProduct) => {
    handleChange([...value, newProduct.productId])
    setSelectedProducts([
      ...selectedProducts,
      {
        ...newProduct,
        product_placed_price: parseFloat(newProduct[priceField]).toFixed(2),
        product_placed_discount: discount,
        product_asked_quantity: 1,
      },
    ])
  }

  const handlers = {
    handleChange,
    handlePriceChange,
    handleDiscountChange,
    handleQuantityChange,
  }

  return {
    productsArray,
    value,
    selectedProducts,
    discount,
    openOptions,
    customFilter,
    setOpenOptions,
    getTotal,
    getTotalWithDiscount,
    resetValues,
    deleteProduct,
    handlers,
    selectNewProduct,
  }
}
