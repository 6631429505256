import React from 'react'
import { Table } from 'react-bootstrap'
import styled from 'styled-components'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { numberFormatter } from '../../../../Helpers'
import InvoiceFooter from './InvoiceFooter'

const Detail = ({ detail }) => {
  const {
    quantity,
    description,
    originalPrice,
    totalDiscount,
    taxTotalOriginalPriceWithDiscount,
    originalPriceWithDiscountLessTaxTotal,
    totalAffectSale,
  } = detail

  return (
    <tr>
      <td className="text-center">{quantity}</td>
      <td className="text-left">{description}</td>
      <td className="text-right">{numberFormatter.format(originalPrice)}</td>
      <td className="text-center">{numberFormatter.format(totalDiscount)}</td>
      <td className="text-right">
        {numberFormatter.format(originalPriceWithDiscountLessTaxTotal)}
      </td>
      <td className="text-center">
        {numberFormatter.format(taxTotalOriginalPriceWithDiscount)}
      </td>
      <td className="text-right">{numberFormatter.format(totalAffectSale)}</td>
    </tr>
  )
}

const getPackageDetail = (pack) => {
  if (!_.isEmpty(pack)) {
    return <Detail detail={pack} isPack />
  }
  return null
}

const InvoiceDetails = (props) => {
  const { bodyFields, footerDetails } = props
  const { details, pack } = bodyFields
  let { countryTax } = bodyFields

  if (countryTax < 1) {
    countryTax *= 100
  }

  return (
    <TableContainer>
      <Table striped bordered condensed hover>
        <Header>
          <tr>
            <th>Cantidad</th>
            <th>Descripción</th>
            <th>Valor + IVA</th>
            <th>Desc.</th>
            <th>Precio U.</th>
            <th>IVA({countryTax}%)</th>
            <th>Ventas Afectas</th>
          </tr>
        </Header>
        <Body>
          {getPackageDetail(pack)}
          {_.map(details, (detail, i) => (
            <Detail key={i} detail={detail} />
          ))}
          <InvoiceFooter footerDetails={footerDetails} />
        </Body>
      </Table>
    </TableContainer>
  )
}

const TableContainer = styled.div`
  width: 100%;
  position: relative;
  overflow-x: auto;
`

const Header = styled.thead`
  font-size: 13px;
`

const Body = styled.tbody`
  tr td .text-right {
    text-align: right;
  }
  tr td .text-center {
    text-align: center;
  }
`

Detail.propTypes = {
  detail: PropTypes.objectOf(PropTypes.any).isRequired,
}

InvoiceDetails.propTypes = {
  bodyFields: PropTypes.objectOf(PropTypes.any).isRequired,
  footerDetails: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default InvoiceDetails
