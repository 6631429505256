import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Panel, Grid, Row, Col, Button } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import { NotificationManager } from 'react-notifications'
import ReactToolTip from 'react-tooltip'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'
import _ from 'lodash'
import { MenuOutlined, EditFilled, EyeFilled, DeleteFilled, PlusCircleFilled } from '@ant-design/icons';
import {
  formatterHeaderStyle,
  columnStyle,
  columnHeaderStyle,
} from '../../../Utilities/TableStyles'
import {
  getVehicleTypes as getAllVehicleTypes,
  deleteVehicleType as removeVehicleType,
} from '../../../../actions/VehicleTypesAction'
import VehicleTypesModal from './VehicleTypesModal'
import { PanelHeader } from '../../Common'
import { AppLoader } from '../../Common/AppLoader'

const SCREEN_ID = 25
const styleIcons = {
  fontSize: '17px',
}

class VehicleTypes extends Component {
  state = {
    vehicleTypes: [],
    show: false,
    loading: true,
    selectedVehicleType: {},
  }

  getInitialState = () => ({
    vehicleTypes: [],
    show: false,
    loading: true,
    selectedVehicleType: {},
  })

  UNSAFE_componentWillMount() {
    const { getVehicleTypesAction } = this.props
    getVehicleTypesAction()
      .then(() => {
        const { vehicleTypes } = this.props
        this.setState(
          {
            vehicleTypes: _.values(vehicleTypes).reverse(),
          },
          () => {
            this.setState({
              loading: false,
            })
          }
        )
      })
      .catch((error) => {
        NotificationManager.error(error, 'Error')
        this.setState({
          loading: false,
        })
      })
  }

  componentDidUpdate(prevProps) {
    const { vehicleTypes } = this.props
    const { vehicleTypes: prevVehicleTypes } = prevProps

    if (vehicleTypes !== prevVehicleTypes) {
      this.setVehicleTypesState()
    }
  }

  setVehicleTypesState() {
    const { vehicleTypes } = this.props
    this.setState({
      vehicleTypes: _.values(vehicleTypes).reverse(),
    })
  }

  toggleModal = () => {
    const { show } = this.state
    this.setState({ show: !show })
  }

  addVehicleType = () => {
    this.setState(
      {
        selectedVehicleType: { ...this.getInitialState().selectedVehicleType },
        editMode: false,
      },
      this.toggleModal
    )
  }

  editVehicleTyple = (row) => {
    this.setState(
      {
        selectedVehicleType: {
          vehicle_type_id: row.vehicle_type_id,
          vehicle_type_name: row.vehicle_type_name,
        },
        editMode: true,
      },
      this.toggleModal
    )
  }

  deleteVehicleType(row) {
    const { vehicle_type_name } = row
    const vehicle_type = {
      id: row.vehicle_type_id,
    }
    const { deleteVehicleTypeAction } = this.props
    const sweet = {
      title: '¿Eliminar?',
      text: `¿Desea eliminar el item '${vehicle_type_name}'?`,
      type: 'warning',
      confirmButtonText: 'Aceptar',
      // showMessageOnCancel: false,
      showCancelButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () =>
        new Promise((resolve, reject) => {
          deleteVehicleTypeAction(vehicle_type)
            .then((response) => {
              if (response) {
                resolve()
              } else {
                reject()
              }
            })
            .catch(() => {
              reject()
            })
        }),
    }

    swal(sweet)
      .then((response) => {
        if (!response.dismiss) {
          swal({
            title: 'Eliminado',
            text: `'${vehicle_type_name}' eliminado`,
            type: 'success',
          })
        }
      })
      .catch(() => {
        swal({
          title: 'Error',
          text: `Error al eliminar: '${vehicle_type_name}'`,
          type: 'error',
        })
      })
  }

  render() {
    const { permissions } = this.props
    const { loading, vehicleTypes, show, selectedVehicleType, editMode } =
      this.state

    const { can_write, can_edit, can_delete } = permissions[SCREEN_ID]
    const deleteButton = (cell, row) => (
      <Button
        onClick={() => this.deleteVehicleType(row)}
        bsStyle="link"
        bsSize="small"
        style={{ color: 'red' }}
        data-tip="Eliminar"
      >
        <DeleteFilled style={styleIcons} />
        <ReactToolTip />
      </Button>
    )

    const columns = [
      {
        dataField: 'vehicle_type_name',
        text: 'Nombre',
        style: columnStyle,
        headerStyle: columnHeaderStyle,
        sort: true,
        filter: textFilter({
          delay: 100,
          style: {
            margin: '10px 0 0 0',
            padding: '20px 2%',
          },
          placeholder: 'Buscar',
        }),
      },
      {
        dataField: 'id',
        className: 'test',
        text: '',
        headerStyle: formatterHeaderStyle,
        style: columnHeaderStyle({ textAlign: 'center' }),
        formatter: (cell, row) => (
          <Button
            onClick={() => this.editVehicleTyple(row)}
            bsStyle="link"
            bsSize="small"
            data-tip={can_edit ? 'Modificar' : 'Ver'}
          >
            {can_edit ? <EditFilled style={styleIcons} /> : <EyeFilled style={styleIcons} />}
            <ReactToolTip />
          </Button>
        ),
      },
    ]
    if (can_delete) {
      columns.push({
        dataField: 'id2',
        text: '',
        headerStyle: formatterHeaderStyle,
        style: columnHeaderStyle({ textAlign: 'center' }),
        formatter: deleteButton,
      })
    }
    const pagePermissions = {
      can_write,
      can_edit,
      can_delete,
    }
    return (
      <div className="component-content">
        <div className="content-header">
          <div className="header-section">
            <h1>
              <i className="fas fa-share-alt" />
              Tipos de vehículos
              <br />
            </h1>
          </div>
        </div>
        <div id="body-content">
          <Grid fluid>
            <Row>
              <Col sm={12}>
                <Panel>
                  <Panel.Heading bsClass="panel-heading">
                    <Panel.Title>
                      <PanelHeader>
                        <div>
                          <p id="title">
                            <MenuOutlined style={{ marginRight: '7px' }} />  Administrar tipo de
                            vehículos
                          </p>
                        </div>
                        <div>
                          {!!can_write && (
                            <Button
                              onClick={this.addVehicleType}
                              bsStyle="success"
                              className="btn-add btn-antd-icon"
                            >
                              <PlusCircleFilled />
                              Agregar registro
                            </Button>
                          )}
                        </div>
                      </PanelHeader>
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    {loading ? (
                      <AppLoader />
                    ) : (
                      <Row>
                        <Col md={10} />
                        <Col md={12}>
                          <BootstrapTable
                            noDataIndication="Sin datos para mostrar"
                            keyField="vehicle_type_id"
                            data={vehicleTypes}
                            columns={columns}
                            filter={filterFactory()}
                            pagination={paginationFactory()}
                          />
                        </Col>
                      </Row>
                    )}
                  </Panel.Body>
                </Panel>
              </Col>
            </Row>
          </Grid>
          <VehicleTypesModal
            modalState={show}
            handleClose={this.toggleModal}
            editMode={editMode}
            selectedVehicleType={selectedVehicleType}
            pagePermissions={pagePermissions}
          />
        </div>
      </div>
    )
  }
}

VehicleTypes.propTypes = {
  deleteVehicleTypeAction: PropTypes.func.isRequired,
  getVehicleTypesAction: PropTypes.func.isRequired,
  vehicleTypes: PropTypes.objectOf(PropTypes.object).isRequired,
  permissions: PropTypes.objectOf(PropTypes.object).isRequired,
}

const mapStateToProps = (store) => ({
  vehicleTypes: store.VehicleTypesReducer.vehicleTypes,
  permissions: store.AppReducer.permissions,
})

const mapDispatchToProps = (dispatch) => ({
  getVehicleTypesAction() {
    return dispatch(getAllVehicleTypes())
  },
  deleteVehicleTypeAction(vehicle_type) {
    return dispatch(removeVehicleType(vehicle_type))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(VehicleTypes)
