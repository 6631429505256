import React from 'react'
import { ConfigProvider, Tabs } from 'antd'
import PropTypes from 'prop-types'

const CustomTabs = ({ tabs, activeTabKey, handleTabChange }) => {
  const theme = {
    components: {
      Tabs: {
        cardBg: '#E7E7E7',
        itemActiveColor: '#000',
        itemSelectedColor: '#000',
        itemHoverColor: '#573384',
        tabTextColor: '#000',
      },
    },
  }

  return (
    <ConfigProvider theme={theme}>
      <Tabs
        activeKey={activeTabKey}
        defaultActiveKey='1'
        onChange={handleTabChange}
        type="card"
        tabBarStyle={{ marginBottom: 0 }}
        items={tabs}
      />
    </ConfigProvider>
  )
}

export default CustomTabs

const tabElementShape = PropTypes.shape({
  key: PropTypes.string.isRequired,
  label: PropTypes.element.isRequired,
  children: PropTypes.element.isRequired,
})

CustomTabs.propTypes = {
  tabs: PropTypes.arrayOf(tabElementShape).isRequired,
  activeTabKey: PropTypes.number.isRequired,
  handleTabChange: PropTypes.func.isRequired,
}
