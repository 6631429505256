/* eslint-disable import/no-extraneous-dependencies */
import React, { useMemo, useState } from 'react'
import { Input, Select } from 'antd'
import { DeleteFilled, ExclamationCircleOutlined } from '@ant-design/icons';
import CurrencyInput from 'react-currency-input-field'

import { updateWorkOrderProduct } from '../../../../api/apiAddProductService'
import { useProductsTab } from '../../../../hooks/useProductsTab'
import AddProductModalWrapper from './AddProductModal.styles'
import AddProductModalTypes from './AddProductModal.types'
import { NotificationHandler } from '../../../../Helpers'
import { AppLoader } from '../AppLoader'

const { Option } = Select

const AddProductModal = ({
  isOpen,
  setOpen,
  // Only used when this modal is opened from "Add something" modal
  setOptionsModal = null,
  setTab,
  products,
  workOrderId,
}) => {
  const productsArray = useMemo(() => {
    const allProducts = Object.values(products)

    const filteredProducts = allProducts.filter(
      (product) => product.product_quantity > 0
    )

    return filteredProducts
  }, [products])

  const {
    value,
    selectedProducts,
    discount,
    openOptions,
    customFilter,
    setOpenOptions,
    getTotal,
    getTotalWithDiscount,
    resetValues,
    deleteProduct,
    handlers,
  } = useProductsTab(productsArray)

  const {
    handleChange,
    handlePriceChange,
    handleDiscountChange,
    handleQuantityChange,
  } = handlers

  const [loading, setLoading] = useState(false)

  const resetAllValues = () => {
    resetValues()
    setOpen(false)
    setLoading(false)
  }

  const handleOk = async () => {
    try {
      setLoading(true)

      // if we use .map() the promises do not execute in order
      const addProduct = async (index) => {
        if (index >= selectedProducts.length) {
          await setTab('2')
          resetAllValues()
          NotificationHandler('¡Listo!', 'info', 'Repuestos agregados')
          return
        }

        const product = selectedProducts[index]
        await updateWorkOrderProduct(workOrderId, product)

        await addProduct(index + 1)
      }

      await addProduct(0)
    } catch (error) {
      NotificationHandler(
        'Error',
        'error',
        'No se pudieron agregar los respuestos'
      )
    }
  }

  // Handler cancel (close modal)
  const handleCancel = () => {
    if (setOptionsModal) {
      setOptionsModal(true)
    }
    resetAllValues()
  }

  const selectProps = {
    mode: 'multiple',
    style: {
      width: '100%',
    },
    value,
    options: productsArray.map((product) => ({
      label: `${product.product_name} ${product.product_bar_code} (cant: ${product.product_quantity})`,
      value: product.product_id,
      disabled: product.productQuantity <= 0,
    })),
    onChange: handleChange,
    placeholder: '¿Qué respuestos desea agregar?',
    maxTagCount: 'responsive',
    filterOption: customFilter,
  }

  return (
    <AddProductModalWrapper
      title="Agregar Repuesto"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      className="add-product"
      okText="Agregar"
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.76)' }}
      okButtonProps={{
        style: {
          backgroundColor: selectedProducts.length <= 0 ? '#eeeeee' : '#1E1E40',
          display: loading ? 'none' : 'inline-block',
        },
        disabled: selectedProducts.length <= 0,
      }}
      cancelButtonProps={{
        style: {
          display: loading ? 'none' : 'inline-block',
        },
      }}
    >
      <div className="main-inputs-wrapper">
        <Select
          className="select"
          mode={selectProps.mode}
          style={selectProps.style}
          value={selectProps.value}
          options={selectProps.options}
          onChange={selectProps.onChange}
          placeholder={selectProps.placeholder}
          maxTagCount={selectProps.maxTagCount}
          filterOption={selectProps.filterOption}
          open={openOptions}
          onDropdownVisibleChange={(visible) => setOpenOptions(visible)}
        >
          {productsArray?.map((option) => (
            <Option
              key={option.product_id}
              value={option.product_id}
              disabled={option.product_quantity < 1}
            >
              {`${option.product_name} ${option.product_bar_code}`}
            </Option>
          ))}
        </Select>
        {selectedProducts.length === 1 && (
          <div className="disclaimer">
            <ExclamationCircleOutlined style={{ paddingRight: '7px' }} />
            <p>Puedes seleccionar más de un repuesto </p>
          </div>
        )}

        <label htmlFor="discount" className="discount">
          Descuento:
          <Input
            suffix="%"
            min={1}
            type="number"
            max={100}
            inputMode="numeric"
            numeric="true"
            digitonly="true"
            itemID="discount"
            itemType="number"
            defaultValue={0}
            value={discount}
            onChange={(e) => handleDiscountChange(e.target.value)}
          />
        </label>
      </div>

      {selectedProducts.length > 0 && (
        <div className="selectedOptions">
          <h2>Repuestos a agregar: </h2>
          {loading && (
            <div className="loader">
              <AppLoader text="Agregando Repuestos" />
            </div>
          )}
          {!loading &&
            selectedProducts?.map((product) => (
              <div className="product" key={product.product_id}>
                <button
                  type="button"
                  className="delete"
                  onClick={() => deleteProduct(product.product_id)}
                >
                  <DeleteFilled />
                </button>
                <div className="product-name-wrapper">
                  <h4>{product.product_name}</h4>
                  <p>
                    <b>Precio actual: </b>${product.product_price.toFixed(2)}
                  </p>
                </div>

                <div className="secondary-inputs-wrapper">
                  <label htmlFor="quantity" className="quantity">
                    Cantidad:
                    <Input
                      itemID="quantity"
                      type="number"
                      value={product.product_asked_quantity}
                      defaultValue={1}
                      inputMode="numeric"
                      numeric="true"
                      digitonly="true"
                      min={1}
                      max={product.product_quantity}
                      onChange={(e) =>
                        handleQuantityChange(e, product.product_id)
                      }
                    />
                  </label>
                  <label htmlFor="price" className="price">
                    Precio:
                    <CurrencyInput
                      id="price"
                      name="price"
                      min={0}
                      prefix="$"
                      decimalSeparator="."
                      step={0.01}
                      groupSeparator=","
                      defaultValue={parseFloat(product.product_price).toFixed(
                        2
                      )}
                      allowNegativeValue={false}
                      value={product.product_placed_price}
                      decimalsLimit={2}
                      onValueChange={(price) =>
                        handlePriceChange(price, product.product_id)
                      }
                    />
                  </label>
                </div>

                <p className="subtotal-wrapper">
                  Sub-Total: ${' '}
                  {getTotalWithDiscount(
                    product.product_asked_quantity,
                    product.product_placed_price,
                    product.product_placed_discount
                  ).toFixed(2)}{' '}
                </p>
              </div>
            ))}

          <p className="total">
            <b>Total: </b> ${getTotal()}
          </p>
        </div>
      )}
    </AddProductModalWrapper>
  )
}

AddProductModal.propTypes = AddProductModalTypes
export default AddProductModal