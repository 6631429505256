import axios from 'axios'
import _ from 'lodash'

import { GET_ROLES } from '../actionTypes/RolTypes'
import { NotificationHandler } from '../Helpers'

const getRolesAction = (payload) => ({
  type: GET_ROLES,
  payload,
})

export const getRoles = (forceFetch) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const rolAmount = _.size(getState().RolReducer.roles)
    if (rolAmount === 0 || forceFetch) {
      axios
        .get('/api/roles')
        .then((response) => {
          if (!response.data.error) {
            const roles = _.keyBy(response.data.data, 'rol_id')
            dispatch(getRolesAction(roles))
            resolve(response.data)
          } else {
            reject()
            NotificationHandler(
              'Error:',
              'error',
              'Error al obtener roles',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error: No se cargaron los roles',
            error
          )
        })
    } else {
      resolve({})
    }
  })

export const updatePermissions =
  (permissions, { isNew, rol_id }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const { currentUser } = getState().AppReducer
      const { workshop_id } = currentUser
      const permissionsArray = []
      _.forEach(permissions, (permission) => {
        permissionsArray.push({
          ..._.omit(permission, 'screen_rol_id'),
          workshop_id,
        })
      })
      if (isNew) {
        axios
          .put(`/api/screen_roles/${workshop_id}/role/${rol_id}`, {
            screen_roles: permissionsArray,
          })
          .then((response) => {
            if (!response.data.error) {
              dispatch(getRoles(true))
              NotificationHandler('Hecho', 'success', 'Permisos modificados')
              resolve()
            } else {
              reject()
              NotificationHandler(
                'Error:',
                'error',
                'Error al modificar permisos',
                response.data.data
              )
            }
          })
          .catch((error) => {
            reject()
            NotificationHandler(
              'Error:',
              'error',
              'Error al modificar permisos',
              error
            )
          })
      } else {
        axios
          .put(`/api/screen_roles/${workshop_id}/role/${rol_id}`, {
            screen_roles: permissionsArray,
          })
          .then((response) => {
            if (!response.data.error) {
              dispatch(getRoles(true))
              NotificationHandler('Hecho', 'success', 'Permisos modificados')
              resolve()
            } else {
              reject()
              NotificationHandler(
                'Error:',
                'error',
                'Error al modificar permisos',
                response.data.data
              )
            }
          })
          .catch((error) => {
            reject()
            NotificationHandler(
              'Error:',
              'error',
              'Error al modificar permisos',
              error
            )
          })
      }
    })
