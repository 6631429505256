import styled from 'styled-components'
import { BREAK_POINTS_DESKTOP_FIRST } from '../../../../global/css/breakPoints'

const PhotosWrapper = styled.div`
  .ant-image-preview-wrap {
    z-index: 1500;
  }
  .image-gallery-image {
    height: 450px;
    width: 450px;
    margin: 0 auto;
    .ant-image {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .image-gallery-thumbnail:focus,
  .image-gallery-thumbnail.active {
    border: 3px solid black;
  }
  .image-gallery-thumbnail:hover {
    border: 3.8px solid black;
  }
  .image-gallery-thumbnails {
    margin-top: 32px;
  }
  .image-gallery-right-nav {
    right: -30px !important;
  }
  .image-gallery-thumbnail-image {
    object-fit: cover;
  }
  .image-gallery-left-nav {
    left: -30px !important;
  }
  .image-gallery-svg {
    height: 50px !important;
    &:hover {
      color: #d60e46 !important;
      background-color: unset !important;
    }
  }

  @media (${BREAK_POINTS_DESKTOP_FIRST.MOBILE}) {
    .image-gallery-image {
      height: 300px;
      width: 300px;
    }
    .image-gallery-right-nav {
      right: -40px !important;
    }
    .image-gallery-left-nav {
      left: -40px !important;
    }
  }
`
export default PhotosWrapper
