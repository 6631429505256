import React from 'react'

const PlayIcon = () => {
  return (
    <svg
      width="63"
      height="63"
      viewBox="0 0 63 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_di_317_41)">
        <path
          d="M31.5 0C16.3085 0 4 12.3085 4 27.5C4 42.6915 16.3085 55 31.5 55C46.6915 55 59 42.6915 59 27.5C59 12.3085 46.6915 0 31.5 0ZM44.3296 30.1613L24.8135 41.3609C23.0615 42.3367 20.8548 41.0837 20.8548 39.0323V15.9677C20.8548 13.9274 23.0504 12.6633 24.8135 13.6391L44.3296 25.504C46.1482 26.5242 46.1482 29.1522 44.3296 30.1613Z"
          fill="#79DE14"
        />
      </g>
      <defs>
        <filter
          id="filter0_di_317_41"
          x="0"
          y="0"
          width="63"
          height="63"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_317_41"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_317_41"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_317_41"
          />
        </filter>
      </defs>
    </svg>
  )
}

export default PlayIcon
