/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react'
import { Table, Button, Alert } from 'react-bootstrap'
import _ from 'lodash'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { PlusCircleFilled } from '@ant-design/icons';

import ClaimDetail from './ClaimDetail'
import PackageDetails from './PackageDetails'
import {
  getPackageDetailsTimeTotal,
  getPackageDetailsTotal,
  getDetailsTotal,
} from './functions'

const WorkOrderDetails = ({
  workOrderDetails,
  products,
  productsThatApply,
  handleChangeDetail,
  deleteWorkOrderDetail,
  addWorkOrderDetail,
  handleChangeSelectDetail,
  processes,
  processesThatApply,
  packageDetails,
  editMode,
  claimDetails,
}) => (
  <DetailsContainer>
    <div className="container-package-total">
      <Table>
        <thead>
          <tr>
            <th>#</th>
            <th>Proceso</th>
            <th>Precio proceso</th>
            <th>Tiempo estimado</th>
          </tr>
        </thead>
        <tbody>
          <PackageDetails
            packageDetails={packageDetails}
            processes={processes}
          />
          <tr>
            <td colSpan="2">
              <b>Total: </b>
            </td>
            <td>
              <b>{getPackageDetailsTotal(packageDetails)}</b>
            </td>
            <td>
              <b>{getPackageDetailsTimeTotal(packageDetails)}</b>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
    <div className="container-aditionals-services">
      <h3>Procesos y respuestos adicionales que fueron realizados</h3>
      <div>
        {Object.keys(workOrderDetails).length <= 0 && (
          <p className="container-package-total__label --center">
            No se agregaron respuestos ni servicios extras
          </p>
        )}
        {Object.keys(workOrderDetails).length > 0 && (
          <Table responsive bordered condensed>
            <thead>
              <tr>
                <th>Detalle</th>
                <th>Cantidad</th>
                <th>Precio U.</th>
                <th>Sub-total</th>
              </tr>
            </thead>
            <tbody>
              {_.map(workOrderDetails, (detail, index) => (
                <tr key={index}>
                  {detail.product && (
                    <td>{detail.product ? detail.product.product_name : ''}</td>
                  )}

                  {detail.process && (
                    <td>{detail.process ? detail.process.process_name : ''}</td>
                  )}

                  <td>
                    {detail.process
                      ? 1
                      : detail.work_order_detail_item_quantity}
                  </td>

                  {detail.product && (
                    <td>${detail.work_order_detail_item_price || 0}</td>
                  )}

                  {detail.process && (
                    <td>${detail.work_order_detail_workforce_price || 0}</td>
                  )}

                  <td>
                    $
                    {detail.product
                      ? detail.work_order_detail_item_price *
                      detail.work_order_detail_item_quantity
                      : detail.work_order_detail_workforce_price * 1}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </div>
    <div className="details-list">
      <h3>Procesos y respuestos que aplican al reclamo</h3>
      <Table className="compact">
        <tbody>
          {_.map(
            _.filter(
              claimDetails,
              (tmpDetail) => tmpDetail.extra_detail === false
            ),
            (detail) => {
              if (!detail.isDeleted) {
                return (
                  <ClaimDetail
                    detail={detail}
                    packageDetails={packageDetails}
                    workOrderDetails={claimDetails}
                    products={productsThatApply}
                    processes={processesThatApply}
                    handleChangeDetail={handleChangeDetail}
                    handleChangeSelectDetail={handleChangeSelectDetail}
                    key={detail.detail_key}
                    id={detail.detail_key}
                    deleteWorkOrderDetail={deleteWorkOrderDetail}
                    editMode={editMode}
                  />
                )
              }
              return null
            }
          )}
        </tbody>
      </Table>
    </div>

    <div className="container-add-aditional-buttons">
      <Button onClick={() => addWorkOrderDetail('product')} className='btn-antd-icon'>
        <PlusCircleFilled />
        Aplicar repuesto
      </Button>
      <Button onClick={() => addWorkOrderDetail('service')} className='btn-antd-icon'>
        <PlusCircleFilled />
        Aplicar servicio
      </Button>
    </div>

    <div>
      <h3>Procesos y respuestos a agregar en la orden de reclamo</h3>
      <Table className="compact">
        <tbody>
          {_.map(
            _.filter(claimDetails, (tmpDetail) => tmpDetail.extra_detail),
            (detail) => {
              if (!detail.isDeleted) {
                return (
                  <ClaimDetail
                    detail={detail}
                    packageDetails={packageDetails}
                    workOrderDetails={claimDetails}
                    products={products}
                    processes={processes}
                    handleChangeDetail={handleChangeDetail}
                    handleChangeSelectDetail={handleChangeSelectDetail}
                    key={detail.detail_key}
                    id={detail.detail_key}
                    deleteWorkOrderDetail={deleteWorkOrderDetail}
                    editMode={editMode}
                  />
                )
              }
              return null
            }
          )}
        </tbody>
      </Table>
    </div>

    <div className="container-add-aditional-buttons">
      <Button
        onClick={() => addWorkOrderDetail('product', true)}
        className='btn-antd-icon'>
        <PlusCircleFilled />
        Agregar repuesto extra
      </Button>
      <Button
        onClick={() => addWorkOrderDetail('service', true)}
        className='btn-antd-icon'>
        <PlusCircleFilled />
        Agregar servicio extra
      </Button>
    </div>

    <h3>Totales</h3>
    <div className="container-final-total">
      <Alert bsStyle="info">
        <p>
          Tiempo total: <b>{getPackageDetailsTimeTotal(claimDetails)}</b>
        </p>
        <hr />
        <p>
          Sub-total aplicado:{' '}
          <b>
            {getDetailsTotal(
              _.filter(claimDetails, (detail) => !detail.extra_detail)
            )}
          </b>
        </p>
        <p>
          Sub-total adicionales:{' '}
          <b>
            {getDetailsTotal(
              _.filter(claimDetails, (detail) => detail.extra_detail)
            )}
          </b>
        </p>
        <hr />
        <p>
          Total de presupuesto: <b>{getDetailsTotal(claimDetails)}</b>
        </p>
      </Alert>
    </div>
  </DetailsContainer>
)

const DetailsContainer = styled.div`
  .container-package-total {
    border: 1px solid #ddd;
    border-radius: 10px;
    margin-bottom: 3rem;

    &__label {
      font-size: 1.7rem;
      color: var(--primary-text-color);
      margin-bottom: 0px;

      &.--center {
        margin-top: 3rem;
        text-align: center;
      }
    }
  }

  .container-package-total {
    margin-bottom: 6rem;
  }

  .container-aditionals-services {
    margin-bottom: 8rem;
  }

  .container-add-aditional-buttons {
    margin-bottom: 10rem;
    display: flex;
    justify-content: center;

    button {
      border-radius: 4px !important;

      &:nth-child(1) {
        margin-right: 1.5rem;
      }
    }
  }

  table.compact {
    margin-top: 2rem;

    tbody {
      tr {
        margin-bottom: 4rem;
        border: 1px solid #9b9a9a;
        border-radius: 10px;
        display: flex;
        flex-direction: column;

        td {
          border: 0;
          width: 100%;
        }

        td:nth-child(1) {
          text-align: center;
          font-weight: bold;
        }
      }
    }
  }

  .container-aditional-subtotal,
  .container-final-total {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 1rem;
  }

  .container-aditional-subtotal {
    margin-bottom: 5rem;
  }
`

WorkOrderDetails.propTypes = {
  workOrderDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  productsThatApply: PropTypes.objectOf(PropTypes.any).isRequired,
  products: PropTypes.objectOf(PropTypes.object).isRequired,
  handleChangeDetail: PropTypes.func.isRequired,
  deleteWorkOrderDetail: PropTypes.func.isRequired,
  addWorkOrderDetail: PropTypes.func.isRequired,
  handleChangeSelectDetail: PropTypes.func.isRequired,
  processes: PropTypes.objectOf(PropTypes.any).isRequired,
  processesThatApply: PropTypes.objectOf(PropTypes.any).isRequired,
  packageDetails: PropTypes.objectOf(PropTypes.object).isRequired,
  claimDetails: PropTypes.objectOf(PropTypes.object).isRequired,
  editMode: PropTypes.bool.isRequired,
}

export default WorkOrderDetails
