import _ from 'lodash'

import {
  GET_SERVICE_TYPES,
  ADD_SERVICE_TYPE,
  UPDATE_SERVICE_TYPE,
  DELETE_SERVICE_TYPE,
} from '../actionTypes/serviceTypeTypes'

const ServiceTypeReducer = (
  state = {
    service_types: {},
  },
  action
) => {
  if (action.type === GET_SERVICE_TYPES) {
    return {
      ...state,
      service_types: action.payload,
    }
  }
  if (action.type === ADD_SERVICE_TYPE || action.type === UPDATE_SERVICE_TYPE) {
    return {
      ...state,
      service_types: {
        ...state.service_types,
        [action.payload.service_type_id]: action.payload,
      },
    }
  }
  if (action.type === DELETE_SERVICE_TYPE) {
    const newState = _.cloneDeep(state)
    delete newState.service_types[action.payload.service_type_id]
    return newState
  }

  return state
}

export default ServiceTypeReducer
