import { GET_ROLES } from '../actionTypes/RolTypes'

export default (
  state = {
    roles: {},
  },
  action
) => {
  switch (action.type) {
    case GET_ROLES: {
      return {
        ...state,
        roles: action.payload,
      }
    }

    default: {
      return state
    }
  }
}
