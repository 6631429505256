/* eslint-disable import/prefer-default-export */
import { Player } from '@lottiefiles/react-lottie-player'
import styled from 'styled-components'
import React from 'react'

import loaderAnimation from '../../../lotties/custom-loader-login.json'

export const LoginLoader = () => {
  return (
    <LoaderContainer>
      <Player
        src={loaderAnimation}
        loop
        autoplay
        style={{ width: '23%', maxWidth: '400px' }}
      />
    </LoaderContainer>
  )
}

const LoaderContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`