import _ from 'lodash'

import {
  GET_PROCESSES,
  ADD_PROCESS,
  UPDATE_PROCESS,
  DELETE_PROCESS,
} from '../actionTypes/ProcessTypes'

export default (state = { processes: {} }, action) => {
  switch (action.type) {
    case GET_PROCESSES: {
      return {
        ...state,
        processes: action.payload,
      }
    }
    case ADD_PROCESS: {
      return {
        ...state,
        processes: {
          ...state.processes,
          [action.payload.process_id]: action.payload,
        },
      }
    }
    case UPDATE_PROCESS: {
      return {
        ...state,
        processes: {
          ...state.processes,
          [action.payload.process_id]: action.payload,
        },
      }
    }
    case DELETE_PROCESS: {
      const newState = _.cloneDeep(state)
      delete newState.processes[action.payload.process_id]
      return newState
    }

    default: {
      return state
    }
  }
}
