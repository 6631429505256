import axios from 'axios'
import store from '../store'

export const Host = ''

const createNotification = (
  title,
  message,
  level,
  position,
  autoDismiss = 3,
  action
) => ({
  title,
  message,
  level,
  position: position || 'tr',
  autoDismiss,
  action,
})

export const NotificationHandler = (
  title,
  type,
  message,
  action,
  autoDismiss
) => {
  const Notification = store.getState().AppReducer.NotificationContainer
  if (Notification) {
    Notification.addNotification(
      createNotification(title, message, type, null, autoDismiss, action)
    )
  }
}

export const PhotoContainer =
  'https://storagedasmotos.blob.core.windows.net/dascontainer/'

export function setAuthorizationToken(token, expirationTimeInSec) {
  if (token) {
    localStorage.setItem('jwtToken', token)
    localStorage.setItem('tokenExpiration', expirationTimeInSec)
    axios.defaults.headers.common.authorization = `Bearer ${token}`
  } else {
    localStorage.removeItem('jwtToken')
    localStorage.removeItem('tokenExpiration')
  }
}

export function getRemainingTokenTime() {
  const expirationTime = localStorage.getItem('tokenExpiration')
  if (!expirationTime) {
    return 0
  }

  const currentTime = Math.floor(Date.now() / 1000)
  return Math.max(0, expirationTime - currentTime)
}

export function isTokenExpired() {
  const remainingTime = getRemainingTokenTime()

  if (remainingTime <= 0) {
    setAuthorizationToken(null, null)
    return true
  }
  return false
}

export const checkValidToken = (token) =>
  new Promise((resolve, reject) => {
    const notification = {}
    let logged = false
    if (token) {
      axios
        .post('/api/employees/checkToken', { token })
        .then((response) => {
          if (!response.data.error) {
            logged = true
            resolve({ logged, user: response.data.data })
          } else {
            notification.title = 'Error'
            notification.type = 'error'
            notification.message = 'Token inválido'
            notification.detail = response.data.data
            reject(notification)
          }
        })
        .catch((error) => {
          notification.title = 'Error'
          notification.type = 'error'
          notification.message = error.message
          notification.detail = error
          reject(notification)
        })
    } else {
      resolve({ logged })
    }
  })

export const numberFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
})

export const roundNumberTwoDecimals = (n) => Math.round(n * 100) / 100

export const buildTimeObjectString = (time) => {
  const { days, hours, minutes, seconds } = time

  const daysText = days === 1 ? 'día' : 'días'
  const hoursText = hours === 1 ? 'hora' : 'horas'
  const minutesText = minutes === 1 ? 'minuto' : 'minutos'

  const timeParts = []

  if (days > 0) {
    timeParts.push(`${days} ${daysText}`)
  }

  if (hours > 0) {
    timeParts.push(`${hours} ${hoursText}`)
  }

  if (minutes > 0) {
    timeParts.push(`${minutes} ${minutesText}`)
  }

  if (seconds > 0) {
    timeParts.push(`${seconds} s`)
  }

  const formattedTime = timeParts.join(', ')

  return formattedTime
}

export const getTimeString = (minutes = 0) => {
  const days = Math.floor(minutes / (24 * 60))
  const hours = Math.floor((minutes % (24 * 60)) / 60)
  const remainingMinutes = minutes % 60

  const parts = []

  if (days > 0) {
    parts.push(`${days} día${days === 1 ? '' : 's'}`)
    parts.push(`${days} día${days === 1 ? '' : 's'}`)
  }

  if (hours > 0) {
    parts.push(`${hours} hora${hours === 1 ? '' : 's'}`)
    parts.push(`${hours} hora${hours === 1 ? '' : 's'}`)
  }

  if (remainingMinutes > 0) {
    parts.push(`${remainingMinutes} minuto${remainingMinutes === 1 ? '' : 's'}`)
    parts.push(`${remainingMinutes} minuto${remainingMinutes === 1 ? '' : 's'}`)
  }

  return parts.join(', ') || '0 minutos'
}

export const isColorVisibleOnWhite = (hex, threshold = 200) => {
  // Remove the '#' character from the hex color
  const hexColor = hex.replace('#', '')

  const r = parseInt(hexColor.substr(0, 2), 16)
  const g = parseInt(hexColor.substr(2, 2), 16)
  const b = parseInt(hexColor.substr(4, 2), 16)

  const brightness = 0.299 * r + 0.587 * g + 0.114 * b

  return brightness <= threshold
}

export const getContrastingColor = (hex) => {
  // Remove the '#' character from the hex color
  const hexColor = hex.replace('#', '')

  // Convert the hex color to RGB
  const r = parseInt(hexColor.substr(0, 2), 16)
  const g = parseInt(hexColor.substr(2, 2), 16)
  const b = parseInt(hexColor.substr(4, 2), 16)

  // Calculate the brightness using the relative luminance formula
  const brightness = 0.299 * r + 0.587 * g + 0.114 * b

  // Determine a contrasting color based on brightness
  return brightness > 128 ? '#00000070' : '#FFFFFF'
}

export const createOption = (value, label, customData) => {
  return { value, label, customData }
}

export const getTimeElapsed = (fromDate) => {
  const currentDate = new Date()
  const pastDate = new Date(fromDate)
  const timeDifferenceInMS = currentDate - pastDate

  const days = Math.floor(timeDifferenceInMS / (1000 * 60 * 60 * 24))
  const months = Math.floor(days / 30)
  const years = Math.floor(months / 12)

  if (years > 0) {
    return `Hace ${years} año${years > 1 ? 's' : ''}`
  }
  if (months > 0) {
    return `Hace ${months} mes${months > 1 ? 'es' : ''}`
  }
  if (days > 0) {
    return `Hace ${days} día${days > 1 ? 's' : ''}`
  }

  return 'Hace 1 día'
}

export const createPhotoObject = (file) => ({
  name: file.name,
  type: file.type,
  size: file.size,
  lastModified: file.lastModified,
  lastModifiedDate: file.lastModifiedDate,
  originFileObj: file,
})

export const sanitizeVehiclePlateSearch = (text) => {
  const sanitizedText = text.replace(/[^a-zA-Z0-9-]/g, '')
  return sanitizedText.toUpperCase()
}

export const getAddedAndRemovedElements = (
  oldArray,
  newArray,
  idProperty = 'id'
) => {
  const addedElements = newArray.filter(
    (newItem) =>
      !oldArray.some((oldItem) => oldItem[idProperty] === newItem[idProperty])
  )
  const removedElements = oldArray.filter((oldElement) => {
    const oldElementId = oldElement[idProperty]
    return !newArray.some(
      (newElement) => newElement[idProperty] === oldElementId
    )
  })
  const unchangedElements = newArray.filter((newItem) =>
    oldArray.some((oldItem) => oldItem[idProperty] === newItem[idProperty])
  )

  return { addedElements, removedElements, unchangedElements }
}

export const getNextButtonText = (nextEnabledCondition, isEditMode = false) => {
  if (isEditMode) {
    return 'Siguiente'
  }
  return nextEnabledCondition ? 'Siguiente' : 'Omitir'
}
