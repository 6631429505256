import Axios from 'axios'
import _ from 'lodash'

import {
  GET_INVOICE,
  SET_WORK_ORDERS_TO_INVOICE,
} from '../actionTypes/InvoicingTypes'
import { NotificationHandler } from '../Helpers'
import { getReceptions } from './ReceptionAction'

const getInvoicesAction = (payload) => ({
  type: GET_INVOICE,
  payload,
})

const setWorkOrdersToInvoice = (payload) => ({
  type: SET_WORK_ORDERS_TO_INVOICE,
  payload,
})

export const getInvoice = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const size = _.size(getState().InvoicesReducer.invoices)
    if (size === 0) {
      Axios.get('/api/invoices')
        .then((response) => {
          if (response.data.error) {
            reject(response.data)
          } else {
            resolve(response.data)
            dispatch(getInvoicesAction(response.data.data))
          }
        })
        .catch((error) => {
          reject(error)
          NotificationHandler('Error', 'error', error.message)
        })
    } else {
      resolve()
    }
  })

const addInvoiceDetails = async (invoiceDetails, invoiceId) => {
  const details = _.map(invoiceDetails, (dt) => ({
    ...dt,
    invoice_id: invoiceId,
  }))
  try {
    return await Axios.post('/api/invoice_details', {
      invoice_details: details,
    })
  } catch (error) {
    NotificationHandler('Error', 'error', error.message)
    return error
  }
}

export const updateInvoiceStatus =
  ({ invoiceId, status }) =>
  async () => {
    try {
      const { data } = await Axios.put(
        `/api/invoices/update_status/${invoiceId}`,
        { status }
      )
      if (data.error) {
        NotificationHandler('Error', 'error', data.message)
      }
    } catch (error) {
      NotificationHandler('Error', 'error', error.message)
    }
  }

export const getToInvoiceWorkOrders = () => async (dispatch) => {
  try {
    const response = await Axios.get('/api/work_orders_history/to_invoice')
    if (response.data.error) {
      NotificationHandler('Error', 'error')
    } else {
      dispatch(setWorkOrdersToInvoice(response.data.data))
    }
  } catch (error) {
    NotificationHandler('Error', 'error', error.message)
  }
}

export const addInvoice = (invoice, details) => async (dispatch, getState) => {
  try {
    const { workshop_id } = getState().AppReducer.currentUser
    const { data } = await Axios.post('/api/invoices', {
      ...invoice,
      workshop_id,
    })
    if (data.error) {
      NotificationHandler('Error', 'error', data.message)
    }
    await addInvoiceDetails(details, data.data.invoice_id)
    await dispatch(getReceptions(true))
    await dispatch(getToInvoiceWorkOrders())
    return data
  } catch (error) {
    NotificationHandler('Error', 'error', error)
    return error
  }
}
