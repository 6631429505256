import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'

import {
  GET_WORK_ORDERS,
  DELETE_WORK_ORDER,
  UPDATE_WORK_ORDER,
  SET_WORK_ORDER_TO_ADMINISTRATE,
  SET_WORK_ORDER_QUANTITY_INFORMATION,
  GET_COMMENTS_WORK_ORDER,
  GET_WORK_ORDER,
} from '../actionTypes/workOrderTypes'
import { NotificationHandler } from '../Helpers'
import { getReceptions, deleteReceptionAction } from './ReceptionAction'
import { getHistory } from './HistoryAction'
import { getToInvoiceWorkOrders } from './InvoiceAction'
import { sendChatMessage } from '../api/apiService'

const getWorkOrdersActions = (payload) => ({
  type: GET_WORK_ORDERS,
  payload,
})
const getWorkOrderAction = (payload) => ({
  type: GET_WORK_ORDER,
  payload,
})

export const updateWorkOrderAction = (payload) => ({
  type: UPDATE_WORK_ORDER,
  payload,
})

const deleteWorkOrderAction = (payload) => ({
  type: DELETE_WORK_ORDER,
  payload,
})

const finishWorkOrderAction = (payload) => ({
  type: DELETE_WORK_ORDER,
  payload,
})

export const setWorkOrderToAdministrate = (payload) => ({
  type: SET_WORK_ORDER_TO_ADMINISTRATE,
  payload,
})

export const getWorkOrdersQuantityInformationActions = (payload) => ({
  type: SET_WORK_ORDER_QUANTITY_INFORMATION,
  payload,
})

export const getCommentsWorkOrderAction = (payload) => ({
  type: GET_COMMENTS_WORK_ORDER,
  payload,
})

export const getWorkOrders = () => (dispatch) =>
  new Promise((resolve, reject) => {
    axios
      .get('/api/work_orders')
      .then((response) => {
        if (!response.data.error) {
          const workOrders = _.keyBy(response.data.data, 'work_order_id')
          dispatch(getWorkOrdersActions(workOrders))
          resolve(response.data)
        } else {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al obtener las órdenes de trabajo',
            response.data.data
          )
        }
      })
      .catch((error) => {
        reject()
        NotificationHandler(
          'Error',
          'error',
          'Error al obtener las órdenes de trabajo',
          error
        )
      })
  })
export const getWorkOrderById = (id) => (dispatch) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/api/work_orders/${id}`)
      .then((response) => {
        if (!response.data.error) {
          const workOrder = _.keyBy(response.data.data, 'work_order_id')
          dispatch(getWorkOrderAction(workOrder))
          resolve(response.data.data)
        } else {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al obtener la órden de trabajo',
            response.data.data
          )
        }
      })
      .catch((error) => {
        reject()
        NotificationHandler(
          'Error',
          'error',
          'Error al obtener la órden de trabajo',
          error
        )
      })
  })

export const sendWorkOrder = async (workOrder, packages, currentUserId) => {
  try {
    const newWorkOrder = await axios.post('/api/work_orders', workOrder)
    const { work_order_id } = newWorkOrder.data.data

    let packageProcessPromises = []
    let additionalProcessPromises = []

    // to save package processes
    if (packages[workOrder.package_id]) {
      const { packageProcess } = packages[workOrder.package_id]

      packageProcessPromises = packageProcess.map((process) =>
        axios.post('/api/time_tracker', {
          process_id: process.process.process_id,
          time_tracker_sequence: process.process_sequence,
          package_id: workOrder.package_id,
          work_order_id,
        })
      )
    }

    // to save additional processes
    if (workOrder.workOrderDetails[0]) {
      const workOrderDetailsToSend = []

      _.forEach(workOrder.workOrderDetails, (detail) => {
        workOrderDetailsToSend.push({
          work_order_id,
          work_order_detail_item: detail.work_order_detail_item,
          work_order_detail_item_quantity: parseFloat(
            detail.work_order_detail_item_quantity || 0
          ),
          work_order_detail_discount: parseFloat(
            detail.work_order_detail_discount
          ),
          work_order_detail_discount_workforce: parseFloat(
            detail.work_order_detail_discount_workforce
          ),
          work_order_detail_item_price: parseFloat(
            detail.work_order_detail_item_price
          ),
          work_order_detail_workforce_id: detail.work_order_detail_workforce_id,
          work_order_detail_workforce_price: parseFloat(
            detail.work_order_detail_workforce_price
          ),
          work_order_detail_description: detail.work_order_detail_description,
        })
      })

      const additionalProcessesReponse = await axios.post(
        '/api/work_order_details',
        {
          work_order_details: workOrderDetailsToSend,
        }
      )

      additionalProcessPromises = additionalProcessesReponse.data.data.map(
        (detail) => {
          if (detail.work_order_detail_workforce_id) {
            return axios.post('/api/time_tracker', {
              work_order_detail_id: detail.work_order_detail_id,
              process_id: detail.work_order_detail_workforce_id,
              work_order_id,
            })
          }
          return Promise.resolve({})
        }
      )
    }

    await sendChatMessage(
      '¡Tu orden de trabajo ha sido creada!',
      currentUserId,
      work_order_id
    )

    return await Promise.all([
      ...packageProcessPromises,
      ...additionalProcessPromises,
    ])
  } catch (error) {
    NotificationHandler(
      'Error',
      'error',
      'Error al agregar la orden de trabajo',
      'error'
    )
    return Promise.reject(error)
  }
}

export const sendClaimWorkOrder = (workOrder) =>
  new Promise((resolve, reject) => {
    axios
      .post('/api/work_orders', workOrder)
      .then((response) => {
        if (!response.data.error) {
          const { work_order_id } = response.data.data
          const details = []
          _.forEach(workOrder.workOrderDetails, (detail) => {
            details.push({
              work_order_id: response.data.data.work_order_id,
              work_order_detail_item: detail.work_order_detail_item,
              work_order_detail_item_price: detail.work_order_detail_item_price,
              work_order_detail_workforce_id:
                detail.work_order_detail_workforce_id,
              work_order_detail_workforce_price:
                detail.work_order_detail_workforce_price,
              work_order_detail_description:
                detail.work_order_detail_description,
              work_order_detail_item_quantity:
                detail.work_order_detail_item_quantity,
            })
          })
          axios
            .post('/api/work_order_details', { work_order_details: details })
            .then((detailResponse) => {
              if (!detailResponse.data.error) {
                resolve(detailResponse.data)
                _.forEach(detailResponse.data.data, (detail) => {
                  if (detail.work_order_detail_workforce_id) {
                    axios
                      .post('/api/time_tracker', {
                        work_order_detail_id: detail.work_order_detail_id,
                        process_id: detail.work_order_detail_workforce_id,
                        work_order_id,
                      })
                      .then((timeTrackerResponse) => {
                        if (timeTrackerResponse.data.error) {
                          reject()
                          NotificationHandler(
                            'Error',
                            'error',
                            'Error al obtener las órdenes de trabajo',
                            timeTrackerResponse
                          )
                        }
                      })
                      .catch((error) => {
                        reject()
                        NotificationHandler(
                          'Error',
                          'error',
                          'Error al obtener las órdenes de trabajo',
                          error
                        )
                      })
                  }
                })
              } else {
                reject()
                NotificationHandler(
                  'Error',
                  'error',
                  'Error al agregar la orden de trabajo',
                  response.data.data
                )
              }
            })
            .catch((error) => {
              reject()
              NotificationHandler(
                'Error',
                'error',
                'Error al agregar la orden de trabajo',
                error
              )
            })
        } else {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al agregar la orden de trabajo',
            response.data.data
          )
        }
      })
      .catch((error) => {
        reject()
        NotificationHandler(
          'Error',
          'error',
          'Error al agregar la orden de trabajo',
          error
        )
      })
  })

export const addWorkOrder = (workOrder) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { workshop_id } = getState().AppReducer.currentUser
    const { packages } = getState().PackageReducer
    if (workOrder.work_order_claim) {
      // TODO
      sendClaimWorkOrder({ ...workOrder, workshop_id }, packages)
        .then(() => {
          resolve()
          NotificationHandler('Done', 'info', 'Reclamo agregado')
          dispatch(getWorkOrders(true))
        })
        .catch(() => {
          reject()
        })
    } else {
      const { receptionToWorkOrder } = getState().ReceptionReducer
      const { currentUser, socket } = getState().AppReducer

      sendWorkOrder(
        { ...workOrder, workshop_id },
        packages,
        currentUser.employee_id
      )
        .then((response) => {
          const message = {
            room: `Admin${currentUser.workshop_id}`,
            message: 'Una nueva orden ha sido ingresada',
            page: '/workOrders',
            workOrder: response,
          }
          socket.emit('send', message)
          resolve()
          NotificationHandler('Done', 'info', 'Orden de trabajo agregada')
          dispatch(deleteReceptionAction(receptionToWorkOrder))
          dispatch(getWorkOrders(true))
        })
        .catch(() => {
          reject()
        })
    }
  })

export const setWorkOrderInvoiced = (workOrderId) => (dispatch) =>
  new Promise((resolve, reject) => {
    axios
      .put(`/api/work_orders/setInvoiced/${workOrderId}`)
      .then(({ data }) => {
        if (data.error) {
          reject()
        } else {
          resolve(workOrderId)
          dispatch(getReceptions(true))
          dispatch(getToInvoiceWorkOrders())
        }
      })
      .catch((error) => {
        reject(error)
      })
  })

const updateWorkOrderDetails = (details, workOrderId, package_id) =>
  new Promise((resolve, reject) => {
    const detailsPromises = []
    let addNewWorkOrder
    _.forEach(details, (detail) => {
      if (detail.work_order_detail_id) {
        if (!detail.isDeleted) {
          detailsPromises.push(
            new Promise((resolveDetail, rejectDetail) => {
              axios
                .put(
                  `/api/work_order_details/${detail.work_order_detail_id}`,
                  detail
                )
                .then((detailResponse) => {
                  if (!detailResponse.data.error) {
                    resolveDetail(detailResponse.data)
                  } else {
                    rejectDetail()
                    NotificationHandler(
                      'Error',
                      'error',
                      'Error al actualizar la orden de trabajo',
                      detailResponse.data.data
                    )
                  }
                })
                .catch((error) => {
                  rejectDetail()
                  NotificationHandler(
                    'Error',
                    'error',
                    'Error al actualizar la orden de trabajo',
                    error
                  )
                })
            })
          )
        } else {
          detailsPromises.push(
            new Promise((resolveDetail, rejectDetail) => {
              if (
                detail.work_order_detail_workforce_id !== undefined &&
                detail.work_order_detail_workforce_id !== null
              ) {
                axios
                  .delete(`/api/time_tracker/${detail.work_order_detail_id}`)
                  .then(() => {
                    axios
                      .delete(
                        `/api/work_order_details/${detail.work_order_detail_id}`,
                        detail
                      )
                      .then((detailResponse) => {
                        if (!detailResponse.data.error) {
                          resolveDetail(detailResponse.data)
                        } else {
                          rejectDetail()
                          NotificationHandler(
                            'Error',
                            'error',
                            'Error al actualizar la orden de trabajo',
                            detailResponse.data.data
                          )
                        }
                      })
                      .catch((error) => {
                        rejectDetail()
                        NotificationHandler(
                          'Error',
                          'error',
                          'Error al actualizar la orden de trabajo',
                          error
                        )
                      })
                  })
                  .catch((error) => {
                    rejectDetail()
                    NotificationHandler(
                      'Error',
                      'error',
                      'Error al actualizar la orden de trabajo',
                      error
                    )
                  })
              } else {
                axios
                  .delete(
                    `/api/work_order_details/${detail.work_order_detail_id}`,
                    detail
                  )
                  .then((detailResponse) => {
                    if (!detailResponse.data.error) {
                      resolveDetail(detailResponse.data)
                    } else {
                      rejectDetail()
                      NotificationHandler(
                        'Error',
                        'error',
                        'Error al actualizar la orden de trabajo',
                        detailResponse.data.data
                      )
                    }
                  })
                  .catch((error) => {
                    rejectDetail()
                    NotificationHandler(
                      'Error',
                      'error',
                      'Error al actualizar la orden de trabajo',
                      error
                    )
                  })
              }
            })
          )
        }
      } else {
        const detailsToAdd = []
        detailsToAdd.push({
          work_order_id: workOrderId,
          work_order_detail_item: detail.work_order_detail_item,
          work_order_detail_item_price: parseFloat(
            detail.work_order_detail_item_price
          ),
          work_order_detail_discount: parseFloat(
            detail.work_order_detail_discount
          ),
          work_order_detail_discount_workforce: parseFloat(
            detail.work_order_detail_discount_workforce
          ),
          work_order_detail_workforce_id: detail.work_order_detail_workforce_id,
          work_order_detail_workforce_price: parseFloat(
            detail.work_order_detail_workforce_price
          ),
          work_order_detail_description: detail.work_order_detail_description,
          work_order_detail_item_quantity:
            detail.work_order_detail_item_quantity,
        })
        addNewWorkOrder = new Promise((resolveAdd, rejectAdd) => {
          axios
            .post('/api/work_order_details', {
              work_order_details: detailsToAdd,
            })
            .then((detailResponse) => {
              if (!detailResponse.data.error) {
                axios
                  .post('/api/time_tracker', {
                    package_id,
                    process_id: detail.work_order_detail_workforce_id,
                    work_order_id: workOrderId,
                  })
                  .then((timeTrackerResponse) => {
                    if (timeTrackerResponse.data.error) {
                      reject()
                      NotificationHandler(
                        'Error',
                        'error',
                        'Error al obtener las órdenes de trabajo',
                        timeTrackerResponse
                      )
                    }
                  })
                  .catch((error) => {
                    reject()
                    NotificationHandler(
                      'Error',
                      'error',
                      'Error al obtener las órdenes de trabajo',
                      error
                    )
                  })
                resolveAdd()
              } else {
                rejectAdd()
                NotificationHandler(
                  'Error',
                  'error',
                  'Error al actualizar el detalle del presupuesto',
                  detailResponse.data
                )
              }
            })
        })
      }
    })
    Promise.all([...detailsPromises, addNewWorkOrder])
      .then(() => {
        resolve()
      })
      .catch(() => {
        reject()
      })
  })

export const updateWorkOrder = (workOrder) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { workshop_id } = getState().AppReducer.currentUser
    if (workOrder.work_order_id) {
      axios
        .put(`/api/work_orders/${workOrder.work_order_id}`, {
          ...workOrder,
          workshop_id,
        })
        .then((response) => {
          if (!response.data.error) {
            updateWorkOrderDetails(
              workOrder.workOrderDetails,
              workOrder.work_order_id,
              workOrder.package_id
            ).then(() => {
              axios
                .put(
                  `/api/time_tracker/calculateProgress/${workOrder.work_order_id}`
                )
                .then((response2) => {
                  if (response2.data.error) {
                    reject()
                    NotificationHandler(
                      'Error',
                      'error',
                      'Error al actualizar la orden de trabajo',
                      response2.data.data
                    )
                  } else {
                    NotificationHandler(
                      'Done',
                      'info',
                      'Orden de trabajo actualizada'
                    )
                    dispatch(getWorkOrders(true))
                    resolve()
                  }
                })
                .catch((error) => {
                  reject()
                  NotificationHandler(
                    'Error',
                    'error',
                    'Error al actualizar la orden de trabajo',
                    error
                  )
                })
            })
            resolve(response)
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al actualizar la orden de trabajo',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al actualizar la orden de trabajo',
            error
          )
        })
    } else {
      reject()
      NotificationHandler(
        'Error',
        'error',
        'No se proporcionó id para la orden de trabajo'
      )
    }
  })

export const deleteWorkOrder = (workOrder) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (workOrder.work_order_id) {
      axios
        .delete(`/api/work_orders/${workOrder.work_order_id}`)
        .then((response) => {
          if (!response.data.error) {
            dispatch(deleteWorkOrderAction(workOrder))
            resolve(response)
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al eliminar la orden de trabajo',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al eliminar la orden de trabajo',
            error
          )
        })
    } else {
      reject()
      NotificationHandler('Error', 'error', 'No se proporcionó id')
    }
  })

export const refreshWorkOrder = (workOrder) => (dispatch) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/api/work_orders/${workOrder.work_order_id}`)
      .then((response) => {
        if (response.data.error) {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al refrescar la orden de trabajo',
            response.data.data
          )
        } else {
          resolve()
          dispatch(updateWorkOrderAction(response.data.data))
        }
      })
      .catch((error) => {
        NotificationHandler(
          'Error',
          'error',
          'Error al refrescar la orden de trabajo',
          error
        )
        reject()
      })
  })

export const finishWorkOrder = (workOrder) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    if (workOrder.work_order_id) {
      const work_order_finished_date = moment()
      axios
        .put(`/api/work_orders/finish/${workOrder.work_order_id}`, {
          work_order_finished_date,
        })
        .then((response) => {
          if (response.data.error) {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Al Finalizar Orden de Trabajo',
              response.data.data
            )
          } else {
            const { currentUser, socket } = getState().AppReducer
            const message = {
              room: `Admin${currentUser.workshop_id}`,
              message: 'Ir a Facturación',
              page: '/Invoices',
              workOrder,
            }
            socket.emit('send', message)
            NotificationHandler(
              'Finalizada',
              'info',
              'Orden de trabajo finalizada'
            )
            dispatch(finishWorkOrderAction(workOrder))
            dispatch(getHistory(true))
            dispatch(getReceptions(true))
            resolve(response)

            sendChatMessage(
              '¡Tu orden de trabajo ha sido completada!',
              currentUser.employee_id,
              workOrder.work_order_id,
              { notify: false } // TODO: Trigger Truora notification with this event
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Al Finalizar Orden de Trabajo',
            error
          )
        })
    } else {
      reject()
      NotificationHandler('Error', 'error', 'No se proporcionó id')
    }
  })

export const fillWorkOrders = (forceFill) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const size = getState().RecepcionReducer.recepciones.length
    if (size === 0 || forceFill) {
      axios
        .get('/api/work_orders')
        .then((response) => {
          if (response.data.error) {
            NotificationHandler('Error', 'error', 'Ordenes de trabajo')
            reject(response.data)
          } else if (response.data.length === 0) {
            dispatch({ type: 'NO_WORK_ORDERS' })
            resolve(response.data)
          } else {
            resolve(response.data)
            dispatch({
              type: 'FILL_WORKORDERS',
              recepciones: response.data.data,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    } else {
      resolve({ error: false, data: {} })
    }
  })

/*
  This action is to update the time-tracker of the time tracker table and to update it in the app state, it's used for the
  automatic change between processes. It doesn't return anything, only updates the state. This function is not when the
  work order is finished through the work order button.
*/
export const updateWorkOrderProcessTimeTracker =
  (timeTracker) => async (dispatch, getState) => {
    try {
      if (
        timeTracker.time_tracker_id === undefined ||
        timeTracker.time_tracker_id === null
      ) {
        throw Error('Proceso no especificado')
      }

      const { workOrderToAdministrate } = getState().WorkOrderReducer

      const { time_tracker_id } = timeTracker
      let timeTrackerData = {}
      if (timeTracker.process_status === 1) {
        timeTrackerData = {
          time_tracker_start: moment(), // Enviar un tiempo calculado BE
          process_status: timeTracker.process_status,
        }
      } else if (timeTracker.process_status === 2) {
        timeTrackerData = {
          process_status: timeTracker.process_status,
        }
      } else if (timeTracker.process_status === 3) {
        timeTrackerData = {
          time_tracker_end: moment(), // Enviar un tiempo calculado BE
          process_status: timeTracker.process_status,
          process_real_time: timeTracker.process.process_estimated_time, // If it's finished, set the real time ass the stimated time
        }
      } else if (timeTracker.process_real_time > 0) {
        // para los no iniciados
        timeTrackerData = {
          process_real_time: timeTracker.process_real_time,
        }
      }

      const updatedTimeTracker = await axios.put(
        `/api/mobile_time_tracker/${time_tracker_id}`,
        timeTrackerData
      )

      const timeTrackerObjectByKey = _.keyBy(
        workOrderToAdministrate.timeTracker,
        'time_tracker_id'
      )

      const timeTrackerObject = {
        ...timeTrackerObjectByKey,
        [timeTracker.time_tracker_id]: {
          ...timeTracker,
          ...updatedTimeTracker.data.data,
        },
      }

      const newWorkOrderToAdministrate = {
        ...workOrderToAdministrate,
        timeTracker: _.values(timeTrackerObject),
      }

      dispatch(setWorkOrderToAdministrate(newWorkOrderToAdministrate))
      dispatch(updateWorkOrderAction(newWorkOrderToAdministrate))
      Promise.resolve(updatedTimeTracker.data.data)
    } catch (error) {
      Promise.reject(error.message)
      // NotificationHandler('Error', 'error', error.message)
    }
  }

/*
  This function is to finish a time-tracker with others. The goal is only to return the updated time-tracker and next resolve its response
  to AdministrateWorkOrder.js file. This action doesn't return anything, only returns the update time-tracker.

  It's processed in this way because when update the time-tracker is tried from the app state it causes a lot of the use of componenDidUpdate()

  The app state isn't used because this action is created to be used in a loop. The problem with the loop is that pass so fast to process and get the
  updated app state. Each request is made at the same time but the response doesn't allow update the app state.

  This action returns the updated time-tracker and is merged with the others time-tracker. The actions to update the app state are not execute in
  the actions file anymore, are processed in the main component that contains the work order that is being processed.
*/
export const updateWorkOrderProcessTimeTrackerWithReturn =
  (timeTracker) => async () => {
    try {
      if (
        timeTracker.time_tracker_id === undefined ||
        timeTracker.time_tracker_id === null
      ) {
        throw Error('Proceso no especificado')
      }

      const { time_tracker_id } = timeTracker
      let timeTrackerData = {}
      if (timeTracker.process_status === 1) {
        timeTrackerData = {
          time_tracker_start: moment(),
          process_status: timeTracker.process_status,
        }
      } else if (timeTracker.process_status === 2) {
        timeTrackerData = {
          process_status: timeTracker.process_status,
          pause_reason_id: timeTracker.pause_reason_id,
          pause_reason_comment: timeTracker.pause_reason_comment,
          process_real_time: timeTracker.process_real_time,
        }
      } else if (timeTracker.process_status === 3) {
        timeTrackerData = {
          time_tracker_end: moment(),
          process_status: timeTracker.process_status,
        }
      }

      const updatedTimeTracker = await axios.put(
        `/api/mobile_time_tracker/${time_tracker_id}`,
        timeTrackerData
      )

      return Promise.resolve(updatedTimeTracker.data.data)
    } catch (error) {
      NotificationHandler('Error', 'error', error.message)
      return Promise.reject(error.message)
    }
  }

export const updateWorkOrderTimeStatus =
  (workOrder, updateWorkOrderRealTime = false) =>
  async (dispatch) => {
    try {
      if (
        workOrder.work_order_id === undefined ||
        workOrder.work_order_id === null
      ) {
        throw Error('Orden de trabajo no especificada')
      }

      let realTime =
        workOrder.work_order_real_time || workOrder.work_order_real_time > 0
          ? workOrder.work_order_real_time
          : 0
      if (updateWorkOrderRealTime) {
        // https://stackoverflow.com/questions/7709803/javascript-get-minutes-between-two-dates
        const now = new Date().getTime()
        const startDateTime = new Date(workOrder.work_order_start).getTime()

        const difference = startDateTime - now

        realTime += Math.abs(Math.round(difference / 60000))
      }
      const dataTime = {
        work_order_id: workOrder.work_order_id,
        work_order_start: workOrder.work_order_start,
        work_order_end: workOrder.work_order_end,
        work_order_status_id: workOrder.work_order_status_id,
        work_order_real_time: realTime,
      }
      const updatedWorkOrder = await axios.put(
        `/api/work_orders/${workOrder.work_order_id}`,
        dataTime
      )

      const newWorkOrderToAdministrate = updatedWorkOrder.data.data

      dispatch(setWorkOrderToAdministrate(newWorkOrderToAdministrate))
      dispatch(updateWorkOrderAction(newWorkOrderToAdministrate))
      return Promise.resolve(newWorkOrderToAdministrate)
    } catch (error) {
      NotificationHandler('Error', 'error', error.message)
      return Promise.reject(error.message)
    }
  }

export const getWorkOrdersQuantityInformation = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { workshop_id } = getState().AppReducer.currentUser
    axios
      .get(`/api/workshop/${workshop_id}/orders`)
      .then((response) => {
        if (!response.data.error) {
          const workOrdersQuantityInformation = response.data.data
          dispatch(
            getWorkOrdersQuantityInformationActions(
              workOrdersQuantityInformation
            )
          )
          resolve(response.data)
        } else {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al obtener la cantidad disponible de ordenes de trabajo',
            response.data.data
          )
        }
      })
      .catch((error) => {
        reject()
        NotificationHandler(
          'Error',
          'error',
          'Error al obtener la cantidad disponible de ordenes de trabajo',
          error
        )
      })
  })

export const getCommentsWorkOrder = (workOrder) => (dispatch) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/api/work_orders/comments/${workOrder}`)
      .then((response) => {
        if (!response.data.error) {
          const commentsWorkOrder = response.data.data
          dispatch(getCommentsWorkOrderAction(commentsWorkOrder))
          resolve(response.data)
        } else {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al obtener los comentarios de la orden de trabajo',
            response.data.data
          )
        }
      })
      .catch((error) => {
        reject()
        NotificationHandler(
          'Error',
          'error',
          'Error al obtener los comentarios de la orden de trabajo',
          error
        )
      })
  })

export const addComment = (comment) => (dispatch) => {
  axios.post('/api/work_orders/comments', comment).then(() => {
    dispatch(getCommentsWorkOrder(comment.workOrderId))
  })
}
