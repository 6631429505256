import _ from 'lodash'

import {
  GET_MODELS_FAMILY,
  ADD_MODEL_FAMILY,
  DELETE_MODEL_FAMILY,
} from '../actionTypes/FamilyModelTypes'

export default (
  state = {
    models: {},
  },
  action
) => {
  switch (action.type) {
    case GET_MODELS_FAMILY: {
      return {
        ...state,
        models: action.payload,
      }
    }
    case ADD_MODEL_FAMILY: {
      return {
        ...state,
        models: {
          ...state.models,
          [action.payload.family_model_id]: action.payload,
        },
      }
    }
    case DELETE_MODEL_FAMILY: {
      const newState = _.cloneDeep(state)
      delete newState.models[action.payload.family_model_id]
      return newState
    }

    default: {
      return state
    }
  }
}
