/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { connect } from 'react-redux'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { InboxOutlined, ToolFilled } from '@ant-design/icons'
import { ReceptionList, ListButton } from '../Styled/StyledComponents'
import { getToInvoiceWorkOrders } from '../../../../actions/InvoiceAction'
import Tab from './Tab'


const filterWorkOrderBy = (type) => (wo) => wo.work_order_type === type

class ReceptionsList extends Component {
  state = {
    loading: true,
    tab: 1,
    filter: '',
  }

  async componentDidMount() {
    const { getToInvoiceWorkOrder } = this.props
    await getToInvoiceWorkOrder()
    this.unsetLoading()
  }

  unsetLoading = () => {
    this.setState({
      loading: false,
    })
  }

  changeTab = (tab) => () => {
    this.setState({ tab })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  getList = () => {
    const { tab, loading, filter } = this.state
    const { workOrdersToInvoice } = this.props
    const workOrders = _.orderBy(
      workOrdersToInvoice.filter(filterWorkOrderBy(tab)),
      ['work_order_id'],
      ['desc']
    )
    if (tab === 1) {
      return (
        <Tab
          {...this.props}
          workOrdersToInvoice={workOrders}
          handleChange={this.handleChange}
          tab={tab}
          loading={loading}
          title="Órdenes de trabajo"
          placeholder="Buscar órden"
          noWorkOrdersText="Sin órdenes por facturar"
          filter={filter}
        />
      )
    }
    return (
      <Tab
        {...this.props}
        workOrdersToInvoice={workOrders}
        handleChange={this.handleChange}
        tab={tab}
        loading={loading}
        title="Ventas"
        placeholder="Buscar venta"
        noWorkOrdersText="Sin ventas por facturar"
        filter={filter}
      />
    )
  }

  render() {
    const { tab } = this.state
    return (
      <ReceptionList>
        <div className="list-handler">
          <ListButton onClick={this.changeTab(1)} active={tab === 1}>
            <ToolFilled style={{ marginRight: "7px" }} />
            Órdenes de trabajo
          </ListButton>
          <ListButton onClick={this.changeTab(2)} active={tab === 2}>
            <InboxOutlined style={{ fontSize: "18px", marginRight: "5px" }} />
            Ventas
          </ListButton>
        </div>
        <div>{this.getList()}</div>
      </ReceptionList>
    )
  }
}

const mapStateToProps = (state) => ({
  workOrdersToInvoice: state.InvoiceReducer.workOrdersToInvoice,
})
const mapDispatchToProps = (dispatch) => ({
  getToInvoiceWorkOrder() {
    return dispatch(getToInvoiceWorkOrders())
  },
})

ReceptionsList.propTypes = {
  workOrdersToInvoice: PropTypes.arrayOf(PropTypes.object).isRequired,
  getToInvoiceWorkOrder: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceptionsList)
