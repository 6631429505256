import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import { Panel, Grid, Row, Col, Button } from 'react-bootstrap'
import { PlusCircleFilled } from '@ant-design/icons'
import _ from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import {
  getReceptions,
  deleteReception as removeReception,
  updateReceptionStatus as modifyReceptionStatus,
  setReceptionToWorkOrder as setRpToWorkOrder,
} from '../../../../actions/ReceptionAction'

import {
  Container,
  ContainerHeader,
  BodyContent,
  showConfirmDialog,
  PanelHeader,
} from '../../Common/index'
import getColumns from './columns'
import { BREAK_POINTS_DESKTOP_FIRST } from '../../../../global/css/breakPoints'
import './Reception.css'
import { AppLoader } from '../../Common/AppLoader'
import { useAddReceptionContext } from '../../../../contexts/AddReceptionContext'
import { addReceptionActions } from '../../../../reducers/AddReceptionReducer'
import { getOneReceptionDetail } from '../../../../api/newReceptionService'
import { multimediaTypes } from '../../../../Constants'

const SCREEN_ID = 28

const ReceptionsContainer = ({
  getAllReceptions,
  deleteReception,
  updateReceptionStatus,
  setReceptionToWorkOrder,
  receptions,
  permissions,
  history,
}) => {
  const [loading, setLoading] = useState(true)
  const [selectedReception, setSelectedReception] = useState({})
  const [loadingButtons, setLoadingButtons] = useState({})
  const { dispatch } = useAddReceptionContext()

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getAllReceptions()
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }

    fetchData()
  }, [getAllReceptions])

  const addReception = () => {
    history.push('/newReception')
    dispatch({
      type: addReceptionActions.RESET_RECEPTION,
    })
  }

  const updateReception = async (reception) => {
    setSelectedReception(reception)

    const data = await getOneReceptionDetail(reception.reception_id)

    const { receptionId, receptionData } = data.data
    const {
      selectedVehicle,
      client,
      photos,
      vehicleState,
      processes,
      products,
      observations,
    } = receptionData

    history.push('/newReception')

    dispatch({
      type: addReceptionActions.RESET_RECEPTION,
    })

    dispatch({
      type: addReceptionActions.SET_IS_EDIT,
      payload: {
        isEdit: true,
        isVehicleSaved: true,
        isClientSaved: true,
        receptionId,
        selectedVehicle: selectedVehicle[0],
        client: client[0],
        photos: photos.filter(
          (resource) => resource.type === multimediaTypes.IMAGE
        ),
        videos: photos.filter(
          (resource) => resource.type === multimediaTypes.VIDEO
        ),
        vehicleState,
        package: {
          ...receptionData.package,
          packageTotalTime: receptionData.package.packageTime,
        },
        aditionalServices: processes?.map((process) => {
          return {
            ...process,
            process_placed_price: process.processPrice,
            process_placed_discount: process.processDiscount,
          }
        }),
        aditionalProducts: products?.map((product) => {
          return {
            ...product,
            product_placed_discount: product.productDiscount,
            product_placed_price: product.productPrice,
            product_asked_quantity: product.productQuantity,
          }
        }),
        observations,
      },
    })
  }

  const deleteReceptionHandler = (reception) => {
    showConfirmDialog(
      '¿Eliminar?',
      `¿Desea eliminar la recepción de la placa ${reception.vehicle.vehicle_license_plate}?`,
      deleteReception,
      reception
    )
  }

  const updateReceptionStatusHandler = (reception, newStatus) => {
    setLoadingButtons((prevLoadingButtons) => ({
      ...prevLoadingButtons,
      [reception.reception_id]: true,
    }))

    updateReceptionStatus(reception, newStatus)
      .then(() => {
        setLoadingButtons((prevLoadingButtons) => ({
          ...prevLoadingButtons,
          [reception.reception_id]: false,
        }))
      })
      .catch(() => {
        setLoadingButtons((prevLoadingButtons) => ({
          ...prevLoadingButtons,
          [reception.reception_id]: false,
        }))
      })
  }

  const toWorkOrder = (reception) => {
    setReceptionToWorkOrder(reception)
    history.push('/workOrders')
  }

  const { width } = selectedReception
  const { can_write, can_edit, can_delete } = permissions[SCREEN_ID]

  const columns = getColumns(
    updateReception,
    deleteReceptionHandler,
    updateReceptionStatusHandler,
    { loadingButtons },
    toWorkOrder,
    {
      can_edit,
      can_delete,
    },
    width
  )

  const data = _.values(receptions).reverse()
  const paginationOptions = {
    hidePageListOnlyOnePage: true,
    hideSizePerPage: data.length <= 10,
  }

  return (
    <Container>
      <ContainerHeader title="Recepciones" />
      <BodyContent>
        <Grid fluid className="mb-40">
          <Row>
            <Col sm={12}>
              <Panel
                style={{
                  overflow: 'visible',
                }}
              >
                <Panel.Heading bsClass="panel-heading">
                  <Panel.Title>
                    <PanelHeader>
                      <div>
                        <p id="title">Administrar Recepciones</p>
                      </div>
                      <div>
                        {!!can_write && (
                          <>
                            <Button
                              onClick={addReception}
                              bsStyle="success"
                              className="btn-add reception"
                            >
                              {' '}
                              Agregar Recepción
                              <PlusCircleFilled />
                            </Button>
                            <Button
                              onClick={addReception}
                              bsStyle="success"
                              id="btn_add_reception"
                              className="btn-add reception-desktop"
                            >
                              <i className="fas fa-plus-circle prefix" />
                              Agregar Recepción
                            </Button>
                          </>
                        )}
                      </div>
                    </PanelHeader>
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  {loading ? (
                    <AppLoader />
                  ) : (
                    <Row>
                      <Col md={10} />
                      <Col md={12}>
                        <CustomStyledTable>
                          <BootstrapTable
                            noDataIndication="Sin recepciones registradas"
                            keyField="reception_id"
                            data={data}
                            columns={columns}
                            pagination={paginationFactory(paginationOptions)}
                          />
                        </CustomStyledTable>
                      </Col>
                    </Row>
                  )}
                </Panel.Body>
              </Panel>
            </Col>
          </Row>
        </Grid>
      </BodyContent>
    </Container>
  )
}

const CustomStyledTable = styled.div`
  position: relative;
  .react-bootstrap-table-pagination-list {
    .pagination {
      .page-item {
        a {
          color: #1e1e40;
        }
      }
      .active {
        a {
          background-color: #1e1e40;
          color: white;
          border-color: #1e1e40;
        }
      }
    }
  }

  table {
    thead {
      tr {
        th {
          .order,
          .react-bootstrap-table-sort-order {
            margin-left: 4px !important;
            span {
              margin-left: 2px;
            }
          }
          label {
            margin-left: 6px;
          }
        }
      }
    }
  }

  .react-bootstrap-table-pagination-list-hidden {
    display: none;
  }
  //specific size where the label, input breaks
  @media (max-width: 2020px) {
    table {
      thead {
        tr {
          th {
            label {
              margin-left: unset;
            }
          }
        }
      }
    }
  }
  @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
    table {
      border: none;

      thead {
        display: none;
        tr {
          display: flex;
          flex-direction: column;

          th {
            display: none;
          }

          th:nth-last-child(1),
          th:nth-last-child(2),
          th:nth-last-child(3),
          th:nth-last-child(4) {
            display: none;
          }
        }
      }

      tbody {
        tr {
          border: 1px solid #ddd;
          display: flex;
          flex-wrap: wrap;
          border-radius: 10px;
          margin: 20px 0px;
          padding-top: 10px;
          padding-bottom: 10px;

          td {
            width: 100%;
            border: 0;
            border-right: 0px !important;
            border-left: 0px !important;
          }

          td:nth-last-child(1),
          td:nth-last-child(2) {
            width: 20%;
          }

          td:nth-last-child(4) {
            width: 100%;
          }
          td:nth-last-child(3) {
            width: 60%;
          }

          td::before {
            font-weight: bold;
            margin-right: 0.5rem;
          }

          td:nth-child(1)::before {
            content: 'Número:';
          }

          td:nth-child(2)::before {
            content: 'Recibido:';
          }

          td:nth-child(3)::before {
            content: 'Placa:';
          }

          td:nth-child(4)::before {
            content: 'Marca:';
          }

          td:nth-child(5)::before {
            content: 'Modelo:';
          }
        }
      }
    }
  }
`
const mapStateToProps = (store) => ({
  receptions: store.ReceptionReducer.receptions,
  permissions: store.AppReducer.permissions,
})

const mapDispatchToProps = (dispatch) => ({
  getAllReceptions() {
    return dispatch(getReceptions(true))
  },
  deleteReception(reception) {
    return dispatch(removeReception(reception))
  },
  updateReceptionStatus(reception, newStatus) {
    return dispatch(modifyReceptionStatus(reception, newStatus))
  },
  setReceptionToWorkOrder(reception) {
    dispatch(setRpToWorkOrder(reception))
  },
})

ReceptionsContainer.propTypes = {
  getAllReceptions: PropTypes.func.isRequired,
  deleteReception: PropTypes.func.isRequired,
  updateReceptionStatus: PropTypes.func.isRequired,
  setReceptionToWorkOrder: PropTypes.func.isRequired,
  receptions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.objectOf(PropTypes.any),
  ]).isRequired,
  history: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.objectOf(PropTypes.any),
  ]).isRequired,
  permissions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.objectOf(PropTypes.any),
  ]).isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceptionsContainer)
