import _ from 'lodash'

const getFullName = (persons) =>
  _.map(persons, (person) => {
    const client = _.cloneDeep(person)
    client.fullName = `${person.person_firstname} ${person.person_lastname}`
    return client
  })

export default getFullName
