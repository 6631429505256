import styled from 'styled-components'

export const VehicleModelFormWrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 3px;

  gap: 2.5rem;

  div.date-input {
    display: flex;
    flex-direction: column;
  }
`
export const ModalTitle = styled.h2`
  font-size: 1.3em;
  font-weight: 700;

  margin: 0;
`
