import React from 'react'
import { Button } from 'react-bootstrap'
import ReactToolTip from 'react-tooltip'
import {
  formatterHeaderStyle,
  columnStyle,
  columnHeaderStyle,
} from '../../Utilities/TableStyles'

const styleIcons = {
  fontSize: '17px',
}

const seeButton = (seeData) => (cell, row) =>
  (
    <Button
      onClick={() => {
        seeData(row)
      }}
      bsStyle="link"
      bsSize="small"
      data-tip="Ver"
    >
      <i className="fas fa-eye" style={styleIcons} />
      <ReactToolTip />
    </Button>
  )

const onlyPrintButton = (onlyPrintButtonFunction) => (cell, row) => {
  return (
    <Button
      onClick={() => {
        onlyPrintButtonFunction(row)
      }}
      bsStyle="link"
      bsSize="small"
      data-tip="Imprimir"
    >
      <i className="fas fa-print" style={styleIcons} />
      <ReactToolTip />
    </Button>
  )
}

const options = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}

const getDate = (date) => {
  const readableDate = new Date(date)
  return readableDate.toLocaleString('es-ES', options)
}

const getColumns = (seeData, onlyPrintFunction) => {
  const columns = [
    {
      dataField: 'work_order_id',
      text: 'Orden',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: false,
      formatter: (id) => `#${id}`,
    },
    {
      dataField: 'person.person_firstname',
      text: 'Cliente - Nombre',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: false,
    },
    {
      dataField: 'person.person_lastname',
      text: 'Cliente - Apellidos',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: false,
    },
    {
      dataField: 'employee.employee_name',
      text: 'Empleado - Nombre',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: false,
    },
    {
      dataField: 'employee.employee_lastname',
      text: 'Empleado - Apellidos',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: false,
    },
    {
      dataField: 'work_order_total',
      text: 'Total',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: false,
      formatter: (m) => `$ ${m}`,
    },
    {
      dataField: 'work_order_date',
      text: 'Fecha',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: false,
      formatter: getDate,
    },
    {
      dataField: '1',
      className: 'test',
      text: '',
      style: columnHeaderStyle({ textAlign: 'center' }),
      formatter: seeButton(seeData),
    },
    {
      dataField: '2',
      className: 'test2',
      text: '',
      headerStyle: formatterHeaderStyle,
      style: columnHeaderStyle({ textAlign: 'center' }),
      formatter: onlyPrintButton(onlyPrintFunction),
    },
  ]
  return columns
}

export default getColumns
