import React, { Component } from 'react'
import { connect } from 'react-redux'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory from 'react-bootstrap-table2-filter'
import { Panel, Grid, Row, Col, Button } from 'react-bootstrap'
import _ from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { MenuOutlined, PlusCircleFilled } from '@ant-design/icons';
import {
  Container,
  ContainerHeader,
  BodyContent,
  showConfirmDialog,
  PanelHeader,
} from '../../Common/index'
import VehicleModal from './VehicleModal'
import getColumns from './columns'
import { BREAK_POINTS_DESKTOP_FIRST } from '../../../../global/css/breakPoints'

import {
  getVehicles,
  addVehicle,
  updateVehicle,
  deleteVehicle,
} from '../../../../actions/VehicleAction'
import { getBrands } from '../../../../actions/BrandAction'
import { getModels } from '../../../../actions/ModelAction'
import { getColors } from '../../../../actions/ColorAction'
import { getMeasureUnits as getMUnits } from '../../../../actions/MeasureUnits'
import { getPersons, addPerson } from '../../../../actions/PersonAction'
import { getContributors } from '../../../../actions/ContributorTypesAction'
import { getStates } from '../../../../actions/StateAction'
import { getCities } from '../../../../actions/CityAction'
import { getUsersApp, updateUserApp } from '../../../../actions/UserAppAction'
import {
  getVehiclesUserApp,
  updateVehicleUserApp,
} from '../../../../actions/VehicleUserAppAction'
import { AppLoader } from '../../Common/AppLoader'

const SCREEN_ID = 23

class VehicleContainer extends Component {
  state = {
    showModal: false,
    loading: true,
    selectedVehicle: {},
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions)
    const {
      getAllVehicles,
      getAllBrands,
      getAllModels,
      getAllColors,
      getMeasureUnits,
      getAllPersons,
      getAllStates,
      getAllCities,
      getContributorTypes,
    } = this.props
    getAllVehicles()
      .then(() => {
        this.setState({ loading: false })
      })
      .catch(() => {
        this.setState({ loading: false })
      })
    getAllBrands()
    getAllModels()
    getAllColors()
    getMeasureUnits()
    getAllPersons()
    getAllStates()
    getAllCities()
    getContributorTypes()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  updateDimensions = () => {
    const { width } = window.screen
    this.setState({ width })
  }

  toggleModal = () => {
    const { showModal } = this.state
    this.setState({ showModal: !showModal })
  }

  addVehicle = () => {
    this.setState({ selectedVehicle: {} })
    this.toggleModal()
  }

  updateVehicle = (vehicle) => {
    this.setState({ selectedVehicle: vehicle })
    this.toggleModal()
  }

  deleteVehicle = (vehicle) => {
    const { removeVehicle } = this.props
    showConfirmDialog(
      '¿Eliminar?',
      `¿Desea eliminar el vehículo ${vehicle.vehicle_license_plate}?`,
      removeVehicle,
      vehicle
    )
  }

  render() {
    const { showModal, selectedVehicle, loading, width } = this.state
    const {
      brands,
      models,
      colors,
      measureUnits,
      vehicles,
      persons,
      permissions,
      states,
      cities,
      contributorTypes,
      createVehicle,
      modifyVehicle,
      createPerson,
      usersApp,
      getAllVehiclesUserApp,
      modifyVehicleUserApp,
      modifyUserApp,
      workshop_id,
    } = this.props
    const { can_write, can_edit, can_delete } = permissions[SCREEN_ID]
    const personPermissions = permissions[20]
    const pagePermissions = {
      can_write,
      can_edit,
      can_delete,
    }
    return (
      <Container>
        <ContainerHeader title="Vehículos" />
        <BodyContent>
          <Grid fluid>
            <Row>
              <Col sm={12} xs={12} md={12}>
                <Panel>
                  <Panel.Heading bsClass="panel-heading">
                    <Panel.Title>
                      <PanelHeader>
                        <div>
                          <p id="title">
                            <MenuOutlined style={{ marginRight: '7px' }} /> Administrar Vehículos
                          </p>
                        </div>
                        <div>
                          {!!can_write && (
                            <Button
                              onClick={this.addVehicle}
                              bsStyle="success"
                              className="btn-add btn-antd-icon"
                            >
                              <PlusCircleFilled />
                              Agregar registro
                            </Button>
                          )}
                        </div>
                      </PanelHeader>
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    {loading ? (
                      <AppLoader />
                    ) : (
                      <Row>
                        <Col md={10} />
                        <Col md={12}>
                          <CustomStyledTable>
                            <BootstrapTable
                              noDataIndication="Sin datos para mostrar"
                              keyField="vehicle_id"
                              data={_.values(vehicles).reverse()}
                              columns={getColumns(
                                this.updateVehicle,
                                this.deleteVehicle,
                                {
                                  can_edit,
                                  can_delete,
                                },
                                width
                              )}
                              filter={filterFactory()}
                              pagination={paginationFactory()}
                            />
                          </CustomStyledTable>
                        </Col>
                      </Row>
                    )}
                  </Panel.Body>
                </Panel>
              </Col>
            </Row>
          </Grid>
          <VehicleModal
            modalOpen={showModal}
            toggleModal={this.toggleModal}
            editMode={!_.isEmpty(selectedVehicle)}
            brands={brands}
            models={models}
            colors={colors}
            measureUnits={measureUnits}
            persons={persons}
            addVehicle={createVehicle}
            updateVehicle={modifyVehicle}
            selectedVehicle={selectedVehicle}
            addPerson={createPerson}
            states={states}
            cities={cities}
            contributorTypes={contributorTypes}
            pagePermissions={pagePermissions}
            personPermissions={personPermissions}
            usersApp={usersApp}
            getAllVehiclesUserApp={getAllVehiclesUserApp}
            modifyVehicleUserApp={modifyVehicleUserApp}
            modifyUserApp={modifyUserApp}
            workshop_id={workshop_id}
          />
        </BodyContent>
      </Container>
    )
  }
}

const CustomStyledTable = styled.div`
  @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
    table {
      border: none;

      thead {
        tr {
          display: flex;
          flex-direction: column;

          th {
            label {
              width: 100%;
            }
          }

          th {
            border: none;
          }

          th:nth-last-child(1),
          th:nth-last-child(2) {
            display: none;
          }
        }
      }

      tbody {
        tr {
          border: 1px solid #ddd;
          display: flex;
          flex-wrap: wrap;
          border-radius: 10px;
          margin: 20px 0px;
          padding-top: 10px;
          padding-bottom: 10px;

          td {
            width: 100%;
            border: 0;
            border-right: 0px !important;
            border-left: 0px !important;
          }

          td:nth-last-child(1),
          td:nth-last-child(2) {
            width: 40%;
          }

          td::before {
            font-weight: bold;
            margin-right: 0.5rem;
          }

          td:nth-child(1)::before {
            content: 'Placa:';
          }

          td:nth-child(2)::before {
            content: 'Marca:';
          }

          td:nth-child(3)::before {
            content: 'Modelo:';
          }

          td:nth-child(4)::before {
            content: 'Año:';
          }
        }
      }
    }
  }
`

const mapStateToProps = (store) => ({
  vehicles: store.VehicleReducer.vehicles,
  brands: store.BrandReducer.brands,
  models: store.ModelReducer.models,
  colors: store.ColorReducer.colors,
  measureUnits: store.MeasureUnitReducer.measureUnits,
  persons: store.PersonReducer.persons,
  contributorTypes: store.ContributorTypeReducer.contributorTypes,
  states: store.StateReducer.states,
  cities: store.CityReducer.cities,
  permissions: store.AppReducer.permissions,
  usersApp: store.UserAppReducer.usersApp,
  workshop_id: store.AppReducer.currentUser.workshop_id,
})

const mapDispatchToProps = (dispatch) => ({
  getAllVehicles() {
    return dispatch(getVehicles())
  },
  createVehicle(vehicle) {
    return dispatch(addVehicle(vehicle))
  },
  modifyVehicle(vehicle) {
    return dispatch(updateVehicle(vehicle))
  },
  removeVehicle(vehicle) {
    return dispatch(deleteVehicle(vehicle))
  },
  modifyVehicleUserApp(vehicle) {
    return dispatch(updateVehicleUserApp(vehicle))
  },
  modifyUserApp(user) {
    return dispatch(updateUserApp(user))
  },
  getAllColors() {
    dispatch(getColors())
  },
  getAllBrands() {
    dispatch(getBrands())
  },
  getAllModels() {
    dispatch(getModels())
  },
  getMeasureUnits() {
    dispatch(getMUnits())
  },
  getAllPersons() {
    dispatch(getPersons())
  },
  createPerson(person, contacts) {
    return dispatch(addPerson(person, contacts))
  },
  getContributorTypes() {
    return dispatch(getContributors())
  },
  getAllStates() {
    return dispatch(getStates())
  },
  getAllCities() {
    return dispatch(getCities())
  },
  getAllUsersApp() {
    return dispatch(getUsersApp())
  },
  getAllVehiclesUserApp(userApp) {
    return dispatch(getVehiclesUserApp(userApp))
  },
})

VehicleContainer.propTypes = {
  getAllVehicles: PropTypes.func.isRequired,
  createVehicle: PropTypes.func.isRequired,
  modifyVehicle: PropTypes.func.isRequired,
  removeVehicle: PropTypes.func.isRequired,
  modifyVehicleUserApp: PropTypes.func.isRequired,
  getAllBrands: PropTypes.func.isRequired,
  getAllModels: PropTypes.func.isRequired,
  getAllColors: PropTypes.func.isRequired,
  getMeasureUnits: PropTypes.func.isRequired,
  getAllPersons: PropTypes.func.isRequired,
  createPerson: PropTypes.func.isRequired,
  getContributorTypes: PropTypes.func.isRequired,
  getAllCities: PropTypes.func.isRequired,
  getAllStates: PropTypes.func.isRequired,
  modifyUserApp: PropTypes.func.isRequired,
  getAllVehiclesUserApp: PropTypes.func.isRequired,
  brands: PropTypes.arrayOf(PropTypes.object).isRequired,
  colors: PropTypes.arrayOf(PropTypes.object).isRequired,
  models: PropTypes.arrayOf(PropTypes.object).isRequired,
  measureUnits: PropTypes.arrayOf(PropTypes.object).isRequired,
  vehicles: PropTypes.arrayOf(PropTypes.object).isRequired,
  persons: PropTypes.arrayOf(PropTypes.object).isRequired,
  contributorTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  cities: PropTypes.arrayOf(PropTypes.object).isRequired,
  states: PropTypes.arrayOf(PropTypes.object).isRequired,
  usersApp: PropTypes.arrayOf(PropTypes.object).isRequired,
  permissions: PropTypes.objectOf(PropTypes.bool).isRequired,
  workshop_id: PropTypes.number.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(VehicleContainer)
