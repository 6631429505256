import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BREAK_POINTS_DESKTOP_FIRST } from '../../../../global/css/breakPoints'

import { SparePartsPhoneNumber } from '../../../../Constants';
import DeliveryIcon from '../../../icons/DeliveryIcon'

const SparePartsButton = ({ message }) => {
    const handleClick = () => {
        const whatsappURL = `https://api.whatsapp.com/send?phone=${SparePartsPhoneNumber}&text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };

    return (
        <CardContainer>
            <FlexContainer>
                <span
                    className="badge badge-primary"
                    style={{
                        backgroundColor: 'rgb(6, 214, 160)', position: 'absolute', top: '-18px', right: '0'
                    }}
                >
                    Nuevo
                </span>
                <TextContainer>
                    <h3>¿Necesitas repuestos para este vehículo?</h3>
                    <h4>Cotiza y recibe en tu taller</h4>
                    <CustomBadge>
                        <DeliveryIcon color="#FFF" size="10px" width="20px" /> Entrega en 30 minutos
                    </CustomBadge>
                </TextContainer>
                <CommonButton
                    type="button"
                    $bgColor="#8c236a"
                    className="btn-large"
                    onClick={handleClick}
                >
                    Cotiza aquí
                </CommonButton>
            </FlexContainer>
        </CardContainer>
    );
};

const CommonButton = styled.button`
    background-color: ${(props) => (props.$bgColor ? props.$bgColor : '#288dc3')};
    border: none;
    outline: none;
    color: var(--tertiary-text-color);
    font-weight: bold;
    padding: 0.8rem 1.2rem 0.8rem 1rem;
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    font-size: 2rem;
    width: 150;

    i {
        padding-right: 0;
        font-size: 1.75rem;
    }

    p {
        padding: 0;
    }

    &:hover {
        transform: scale(1.01);
    }
`

const FlexContainer = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
    flex-wrap: wrap;
    position: relative;

    @media (${BREAK_POINTS_DESKTOP_FIRST.MOBILE}) {
        justify-content: center;
    }
`
const CardContainer = styled.div`
    width: fit-content;
    height: min-content;
    border: 2px solid #1e1e40; 
    background-color: #1e1e40;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    padding: 10px 12px;
    border-radius: 10px;
`

const CustomBadge = styled.div`
    width: fit-content;
    height: min-content;
    padding: 10px 0;
    margin-top: 12px;
    color: #FFF;
    display: flex;
    align-items: center;
    gap: 8px;
`

const TextContainer = styled.div`
    flex-grow: 1;
    padding: 12px;

    h3,
    h4 {
        margin: 0;
        color: #fff;
    }

    h3 {
        font-size: 2rem;
    }

    h4 {
        font-size: 1.8rem;
        margin-top: 7px;
    }

    @media (${BREAK_POINTS_DESKTOP_FIRST.TABLET}) {
        h3 {
            font-size: 1.6rem;
        }

        h4 {
            font-size: 1.4rem;
        }
    }

    @media (${BREAK_POINTS_DESKTOP_FIRST.MOBILE}) {
        max-width: 100%;
        h3 {
            font-size: 1.6rem;
        }

        h4 {
            font-size: 1.4rem;
        }
    }
`;

SparePartsButton.propTypes = {
    message: PropTypes.string.isRequired,
};

export default SparePartsButton;
