import React from 'react'
import { Button } from 'react-bootstrap'
import ReactToolTip from 'react-tooltip'
import { textFilter } from 'react-bootstrap-table2-filter'
import { EditFilled, EyeFilled, DeleteFilled } from '@ant-design/icons';
import {
  formatterHeaderStyle,
  columnStyle,
  columnHeaderStyle,
} from '../../../../Utilities/TableStyles'

const styleIcons = {
  fontSize: '17px',
}

const editButton = (editContributor, can_edit) => (cell, row) =>
(
  <Button
    onClick={() => {
      editContributor(row)
    }}
    bsStyle="link"
    bsSize="small"
    data-tip={can_edit ? 'Modificar' : 'Ver'}
  >
    {can_edit ? <EditFilled style={styleIcons} /> : <EyeFilled style={styleIcons} />}
    <ReactToolTip />
  </Button>
)

const deleteButton = (deleteContributor) => (cell, row) =>
(
  <Button
    onClick={() => deleteContributor(row)}
    bsStyle="link"
    bsSize="small"
    style={{ color: 'red' }}
    data-tip="Eliminar"
  >
    <DeleteFilled style={styleIcons} />
    <ReactToolTip />
  </Button>
)

const getColumns = (
  editContributor,
  deleteContributor,
  { can_edit, can_delete }
) => {
  const columns = [
    {
      dataField: 'contributor_type_name',
      text: 'Nombre',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: textFilter({
        delay: 100,
        style: {
          margin: '10px 0 0 0',
          padding: '20px 2%',
        },
        placeholder: 'Buscar',
      }),
    },
    {
      dataField: 'contributor_type_retention_percentage',
      text: 'Porcentaje de retención',
      style: columnStyle,
      headerStyle: columnHeaderStyle,
      sort: true,
      filter: textFilter({
        delay: 100,
        style: {
          margin: '10px 0 0 0',
          padding: '20px 2%',
        },
        placeholder: 'Buscar',
      }),
      formatter: (cell, row) => `${row.contributor_type_retention_percentage}%`,
    },
    {
      dataField: 'id',
      className: 'test',
      text: '',
      headerStyle: formatterHeaderStyle,
      style: columnHeaderStyle({ textAlign: 'center' }),
      formatter: editButton(editContributor, can_edit),
    },
  ]
  if (can_delete) {
    columns.push({
      dataField: 'id2',
      text: '',
      headerStyle: formatterHeaderStyle,
      style: columnHeaderStyle({ textAlign: 'center' }),
      formatter: deleteButton(deleteContributor),
    })
  }
  return columns
}

export default getColumns
