import axios from 'axios'

export const getMechanicList = async () => {
  try {
    const response = await axios.get(`api/employees/mechanics/reception/all`)
    return response.data.data
  } catch (error) {
    throw ('Error cargando lista de mecánicos', error)
  }
}
