/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { PropTypes } from 'prop-types'
import { useFormContext, Controller } from 'react-hook-form'
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
} from '@material-ui/core'
import FormControlWrapper from './TextFormControl.styles'

const TextFormControl = ({
  label,
  error,
  isAutoFocus,
  name,
  required,
  requiredMessage,
  pattern,
  patternMessage,
}) => {
  const { control, watch } = useFormContext()
  const fielValue = watch(name)

  return (
    <FormControlWrapper>
      <FormControl variant="standard" error={!!error}>
        <InputLabel htmlFor={name} className="label-colored">
          {label}
          {required && !fielValue?.trim() ? '*' : ''}
        </InputLabel>
        <Controller
          name={name}
          defaultValue=""
          control={control}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Input
              id={name}
              aria-describedby={`${name}-text`}
              className="input-colored"
              onChange={onChange}
              autoFocus={isAutoFocus}
              onBlur={onBlur}
              ref={ref}
              value={value}
            />
          )}
          rules={{
            required: {
              value: required,
              message: requiredMessage,
            },
            pattern: {
              value: pattern,
              message: patternMessage,
            },
          }}
        />
        {!!error && (
          <FormHelperText className="error-message" id={`${name}-text`}>
            {error}
          </FormHelperText>
        )}
      </FormControl>
    </FormControlWrapper>
  )
}

TextFormControl.defaultProps = {
  required: false,
  requiredMessage: '',
  pattern: undefined,
  patternMessage: '',
  error: '',
  isAutoFocus: false,
}

TextFormControl.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
  isAutoFocus: PropTypes.bool,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  pattern: PropTypes.any,
  requiredMessage: PropTypes.string,
  patternMessage: PropTypes.string,
}

export default TextFormControl
