import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const TimeAgoClients = ({ inputDate }) => {
    const [timeAgo, setTimeAgo] = useState('');

    useEffect(() => {
        const calculateTimeAgo = () => {
            const m = moment.utc(inputDate, "YYYY-MM-DDTHH:mm:ss.SSSZ");
            const offsetInMinutes = moment.duration("00:00").asMinutes();
            m.utcOffset(offsetInMinutes, true);

            const currentMoment = moment.utc();
            const diffInMs = currentMoment.diff(m);
            const duration = moment.duration(diffInMs);

            const years = duration.asYears();
            const weeks = duration.asWeeks();
            const days = duration.asDays();
            const hours = duration.asHours();
            const minutes = duration.asMinutes();

            if (years >= 1) {
                setTimeAgo(`${Math.floor(years)} ${Math.floor(years) === 1 ? 'año' : 'años'}`);
            } else if (weeks >= 1) {
                setTimeAgo(`${Math.floor(weeks)} ${Math.floor(weeks) === 1 ? 'semana' : 'semanas'}`);
            } else if (days >= 1) {
                setTimeAgo(`${Math.floor(days)} ${Math.floor(days) === 1 ? 'día' : 'días'}`);
            } else if (hours >= 1) {
                setTimeAgo(`${Math.floor(hours)} ${Math.floor(hours) === 1 ? 'hora' : 'horas'}`);
            } else {
                const minutesFloor = Math.floor(minutes);
                if (minutesFloor === 0) {
                    setTimeAgo('hace < 1 minuto');
                } else {
                    setTimeAgo(`${minutesFloor} ${minutesFloor === 1 ? 'minuto' : 'minutos'}`);
                }
            }
        };

        calculateTimeAgo();
    }, [inputDate]);

    return <span>{timeAgo}</span>;
};

TimeAgoClients.propTypes = {
    inputDate: PropTypes.string.isRequired,
};

export default TimeAgoClients;