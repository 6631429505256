import styled from 'styled-components';

export const ObservationsStepWrapper = styled.div`
  margin: 10px;
  textarea {
    padding:12px;
    resize: none;
    width: 100%;
    height: 100%;
    border: 2px solid #8d2866;
    ::-webkit-scrollbar {
      width: 5px;
      margin-right: 5px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(140, 35, 106);
      background: linear-gradient(
        353deg,
        rgba(140, 35, 106, 0.8) 40%,
        rgba(80, 52, 127, 0.8) 100%
      );
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: rgb(140, 35, 106);
      background: linear-gradient(
        353deg,
        rgba(140, 35, 106) 40%,
        rgba(80, 52, 127) 100%
      );
    }
  }
`