import _ from 'lodash'

import {
  GET_EMPLOYEES,
  ADD_EMPLOYEE,
  UPDATE_EMPLOYEE,
  DELETE_EMPLOYEE,
} from '../actionTypes/EmployeeTypes'

export default (
  state = {
    employees: [],
  },
  action
) => {
  switch (action.type) {
    case GET_EMPLOYEES: {
      return {
        ...state,
        employees: action.payload,
      }
    }
    case ADD_EMPLOYEE: {
      return {
        ...state,
        employees: {
          ...state.employees,
          [action.payload.employee_id]: action.payload,
        },
      }
    }
    case UPDATE_EMPLOYEE: {
      return {
        ...state,
        employees: {
          ...state.employees,
          [action.payload.employee_id]: {
            ...state.employees[action.payload.employee_id],
            ...action.payload,
          },
        },
      }
    }
    case DELETE_EMPLOYEE: {
      const newState = _.cloneDeep(state)
      delete newState.employees[action.payload.employee_id]
      return newState
    }

    default: {
      return state
    }
  }
}
