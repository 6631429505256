import React from 'react'
import { Container, Text } from './Components'

const ForbiddenPage = () => (
  <Container>
    <Text>Página no encontrada, Error 404.</Text>
    <Text fontSize="20">Esta página no existe.</Text>
  </Container>
)

export default ForbiddenPage
