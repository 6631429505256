import styled from 'styled-components'

export const SuccessModalWrapper = styled.div`
  z-index: 10;
  margin: 24px 0;
  position: relative;

  .loader{
    width: 100%;
    height: 100%;
    display: flex;
  }

  .lf-player-container {
    position: relative;
    z-index: 10;
    margin-top: -90px;
  }
  p {
    margin-top: -100px;
    font-family: inherit;
    font-weight: bold;
    text-align: center;
    font-size: 2rem;
    background-image: linear-gradient(to right, #1e1e40, #b80667);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    text-align: center;
  }
`
