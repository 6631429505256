import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'

import { addReceptionActions } from '../../../../../reducers/AddReceptionReducer'
import { useAddReceptionContext } from '../../../../../contexts/AddReceptionContext'
import { getVehicleTypes } from '../../../../../actions/VehicleTypesAction'
import { NotificationHandler, createOption } from '../../../../../Helpers'
import { createVehicle } from '../../../../../api/newReceptionService'
import { getMeasureUnits } from '../../../../../actions/MeasureUnits'
import VehiclePlateForm from './VehiclePlateForm/VehiclePlateForm'
import VehicleModelForm from './VehicleModelForm/VehicleModelForm'
import SuccessModal from '../../../Common/SuccessModal/SuccessModal'
import { createVehicleSteps } from '../../../../../Constants'
import { getBrands } from '../../../../../actions/BrandAction'
import { getModels } from '../../../../../actions/ModelAction'
import VehicleDetails from './VehicleDetails/VehicleDetails'
import { useModal } from '../../../../../hooks/useModal'

const AddVehicleForm = ({
  measureUnits,
  getBrands: getAllBrands,
  getModels: getAllModels,
  getMeasureUnits: getAllMeasureUnits,
  getVehicleTypes: getAllVehicleTypes,
}) => {
  const { state, dispatch } = useAddReceptionContext()
  const { newVehicle } = state

  const measureUnitsArray = Object.values(measureUnits)

  const [step, setStep] = useState(1)
  const [isModalOpen, openModal, closeModal] = useModal()
  const [isCreationLoading, setCreationLoading] = useState(false)

  const totalSteps = 3

  const handleNext = () => {
    if (step < totalSteps) {
      setStep(step + 1)
    }
  }

  const handlePrevious = () => {
    if (step > 1) {
      setStep(step - 1)
    }
  }

  const navigateToSelectVehicle = () => {
    dispatch({ type: addReceptionActions.SET_SUB_STEP, payload: null })
    dispatch({ type: addReceptionActions.SET_PREVIOUS_STEP })
  }

  const handleCloseSuccessModal = () => {
    closeModal()
    navigateToSelectVehicle()
  }

  const selectNewVehicle = (createdVehicle) => {
    const labelString = `${newVehicle.vehicleLicensePlate} - ${
      newVehicle.selectedBrand.labelName || newVehicle.selectedBrand.label
    } ${newVehicle.selectedModel.label}`

    const foundUnit = measureUnitsArray.filter(
      (unit) => unit.measure_unit_id === newVehicle.measureUnitId
    )
    const unitName = foundUnit.length > 0 ? foundUnit[0]?.measure_unit_name : ''

    dispatch({
      type: addReceptionActions.SELECT_VEHICLE,
      payload: {
        selectedVehicle: {
          vehicleId: createdVehicle.vehicleId,
          licensePlate: createdVehicle.licensePlate,
          brandName:
            newVehicle.selectedBrand.labelName ||
            newVehicle.selectedBrand.label,
          modelName: newVehicle.selectedModel.label,
          vehicleYear: createdVehicle.year,
          vehicleName: labelString,
          measureUnit: unitName,
          ownerId: null,
          colorName: '',
        },
        vehicleValue: createOption(createdVehicle.vehicleId, labelString),
      },
    })
  }

  const addVehicle = async () => {
    setCreationLoading(true)

    const vehicle = {
      licensePlate: newVehicle.vehicleLicensePlate,
      modelId: newVehicle.selectedModel.value,
      year: newVehicle.selectedYearMomentObj.format('YYYY'),
      measureUnitId: newVehicle.measureUnitId,
      colors: newVehicle.colors,
    }

    try {
      const response = await createVehicle(vehicle)
      selectNewVehicle(response.data)
      dispatch({
        type: addReceptionActions.SET_REPLACE_OWNER,
        payload: true,
      })

      openModal()
      setTimeout(() => {
        handleCloseSuccessModal()
      }, 2000)
    } catch (e) {
      NotificationHandler(
        'No se pudo crear el vehículo',
        'error',
        'Intenta nuevamente más tarde'
      )
    } finally {
      setCreationLoading(false)
    }
  }

  useEffect(() => {
    dispatch({
      type: addReceptionActions.RESET_NEW_VEHICLE,
    })
  }, [])

  // To fetch form necessary info
  useEffect(() => {
    getAllBrands()
    getAllModels()
    getAllMeasureUnits()
    getAllVehicleTypes()
  }, [getAllBrands, getAllModels, getAllMeasureUnits, getAllVehicleTypes])

  const renderStep = () => {
    switch (step) {
      case createVehicleSteps.VEHICLE_PLATE:
        return (
          <VehiclePlateForm
            handleNext={handleNext}
            navigateToSelectVehicle={navigateToSelectVehicle}
          />
        )
      case createVehicleSteps.VEHICLE_MODEL:
        return (
          <VehicleModelForm
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            navigateToSelectVehicle={navigateToSelectVehicle}
          />
        )
      case createVehicleSteps.VEHICLE_DETAILS:
        return (
          <VehicleDetails
            isCreationLoading={isCreationLoading}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            addVehicle={addVehicle}
          />
        )
      default:
        return <></>
    }
  }

  return (
    <>
      {renderStep()}
      <SuccessModal
        isModalOpen={isModalOpen}
        closeModal={handleCloseSuccessModal}
        text="¡Vehículo registrado!"
      />
    </>
  )
}

AddVehicleForm.propTypes = {
  measureUnits: PropTypes.object.isRequired,
  getBrands: PropTypes.func.isRequired,
  getModels: PropTypes.func.isRequired,
  getMeasureUnits: PropTypes.func.isRequired,
  getVehicleTypes: PropTypes.func.isRequired,
}

const mapStateToProps = (store) => ({
  measureUnits: store.MeasureUnitReducer?.measureUnits,
})

const mapDispatchToProps = (dispatch) => ({
  getBrands() {
    dispatch(getBrands())
  },
  getModels() {
    dispatch(getModels())
  },
  getMeasureUnits() {
    dispatch(getMeasureUnits())
  },
  getVehicleTypes() {
    dispatch(getVehicleTypes())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(AddVehicleForm)
