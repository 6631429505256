import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Chart from 'chart.js'

class LineChartComponent extends Component {
  state = {
    idCanvas: `chart-${(Math.random() * 10000).toFixed(0)}`,
  }

  componentDidMount() {
    this.renderChart()
  }

  renderChart = () => {
    const { idCanvas } = this.state
    const { datasets, labels, legend, scale } = this.props

    const ctx = document.getElementById(idCanvas).getContext('2d')

    const defaultData = [
      {
        label: 'Demo 1',
        data: [12.5, 9.9],
        fill: false,
      },
      {
        label: 'Demo 2',
        data: [8, 6],
        fill: false,
      },
    ]

    // eslint-disable-next-line
    const myChart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels || ['Red', 'Blue'],
        datasets: !datasets ? defaultData : datasets,
      },
      options: {
        responsive: true,
        pointRadius: 20,
        legend: {
          display: legend,
        },
        scales: scale
          ? {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            }
          : {},
      },
    })
  }

  render() {
    const { idCanvas } = this.state

    return <canvas id={idCanvas} />
  }
}

LineChartComponent.defaultProps = {
  legend: false,
  scale: false,
}

LineChartComponent.propTypes = {
  datasets: PropTypes.arrayOf(PropTypes.object).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  legend: PropTypes.bool,
  scale: PropTypes.bool,
}

export default LineChartComponent
