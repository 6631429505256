import styled from 'styled-components'

const EvaluateModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  margin: 48px 0;

  h1 {
    font-size: 2.5rem;
  }
  
  .btns-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    align-items: center;
    .btn {
      min-width: 223px;
      width: min-content;
      display: flex;
      align-items: center;
      gap: 16px;
      border-radius: 4px;
      font-size: 1.5rem;
      i {
        font-size: 2rem;
      }
    }

    .btn-clipboard {
      i {
        color: #7a797b;
      }
      &:hover {
        background-color: #7a797b;
        color: white;
        i {
          color: white;
        }
      }
      &:focus {
        outline: none;
      }
    }

    .btn-whatsapp {
      i {
        color: #13bf39;
      }
      &:hover {
        background-color: #13bf39;
        color: white;
        i {
          color: white;
        }
      }
      &:focus {
        outline: none;
      }
    }
  }
`

export default EvaluateModalWrapper