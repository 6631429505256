import axios from 'axios'
import _ from 'lodash'

import { GET_MEASURE_UNITS } from '../actionTypes/MeasureUnitTypes'
import { NotificationHandler } from '../Helpers'

const getMeasureUnitsAction = (payload) => ({
  type: GET_MEASURE_UNITS,
  payload,
})

// eslint-disable-next-line import/prefer-default-export
export const getMeasureUnits = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const measureUnitsQuantity = _.size(
      getState().MeasureUnitReducer.measureUnits
    )
    if (measureUnitsQuantity === 0) {
      axios
        .get('/api/measure_units')
        .then((response) => {
          if (!response.data.error) {
            const measureUnits = _.keyBy(response.data.data, 'measure_unit_id')
            dispatch(getMeasureUnitsAction(measureUnits))
            resolve(response.data)
          } else {
            reject()
            NotificationHandler(
              'Error',
              'error',
              'Error al obtener unidades de medida',
              response.data.data
            )
          }
        })
        .catch((error) => {
          reject()
          NotificationHandler(
            'Error',
            'error',
            'Error al obtener unidades de medida',
            error
          )
        })
    } else {
      resolve({})
    }
  })
