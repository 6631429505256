/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react'
import { PropTypes } from 'prop-types'

import PreviousNextButton from '../../../../buttons/PreviousNextButton/PreviousNextButton'
import { useAddReceptionContext } from '../../../../../contexts/AddReceptionContext'
import GradientTextComponent from '../../../Common/GradientText.js/GradientText'
import { fetchLastFiveWorkOrders } from '../../../../../api/newReceptionService'
import { addReceptionActions } from '../../../../../reducers/AddReceptionReducer'
import { FlexColumnContainer } from '../../../Common/Components'
import EmptyState from '../../../Common/EmptyState/EmptyState'
import { WorkOrderCard } from './WorkOrderCard/WorkOrderCard'
import { NotificationHandler } from '../../../../../Helpers'
import HistoryModal from '../../../History/historyModal'
import { AppLoader } from '../../../Common/AppLoader'

const LastWorkOrdersStep = ({ handleNext, handlePrevious }) => {
  const { state, dispatch } = useAddReceptionContext()
  const { selectedVehicle, lastWorkOrders } = state

  const [selectedOrder, setSelectedOrder] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const toggleModalOpen = () => {
    setIsModalOpen(!isModalOpen)
  }

  const setWorkOrderDetail = (detail) => {
    setSelectedOrder(detail)
  }

  const getWorkOrders = async () => {
    try {
      setIsLoading(true)
      if (selectedVehicle) {
        const response = await fetchLastFiveWorkOrders(
          selectedVehicle?.licensePlate
        )
        dispatch({
          type: addReceptionActions.SET_LAST_WORK_ORDERS,
          payload: response.data || [],
        })
      }
    } catch (e) {
      NotificationHandler(
        'No se pudieron obtener las últimas órdenes de trabajo',
        'error'
      )
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!isModalOpen && selectedOrder?.work_order_id) {
      toggleModalOpen()
    }
  }, [selectedOrder])

  useEffect(() => {
    // Fetch of the work orders only if they have not been saved previously
    if (state.lastWorkOrders === null) {
      getWorkOrders()
    }
  }, [])

  const renderWorkOrders = () => {
    return lastWorkOrders?.length > 0 ? (
      lastWorkOrders?.map((workOrder) => (
        <WorkOrderCard
          key={workOrder.work_order_id}
          openDetail={toggleModalOpen}
          setWorkOrderDetail={setWorkOrderDetail}
          workOrder={workOrder}
        />
      ))
    ) : (
      <EmptyState text="El vehículo no tiene órdenes de trabajo registradas" />
    )
  }

  return (
    <>
      <main className="new-reception__main">
        <div className="new-reception__main__question">
          <GradientTextComponent text="Últimas 5 órdenes registradas" />
        </div>
        <div className="new-reception__main__content">
          {isLoading ? (
            <AppLoader text="Obteniendo datos..." />
          ) : (
            <FlexColumnContainer style={{ padding: '6px' }}>
              {renderWorkOrders()}
            </FlexColumnContainer>
          )}
        </div>
      </main>
      <div className="new-reception__navigation-buttons">
        <PreviousNextButton isPrevious onClickHandler={handlePrevious} />
        <PreviousNextButton
          isPrevious={false}
          nextText="Siguiente"
          onClickHandler={handleNext}
        />
      </div>
      {selectedOrder?.work_order_id && (
        <HistoryModal
          modalOpen={isModalOpen}
          toggleModal={toggleModalOpen}
          selectedHistory={selectedOrder}
          fieldName={selectedOrder.work_order_id}
        />
      )}
    </>
  )
}

export default LastWorkOrdersStep

LastWorkOrdersStep.propTypes = {
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
}
