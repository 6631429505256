import React from 'react'
import { Button, FormControl, ControlLabel } from 'react-bootstrap'
import styled from 'styled-components'
import Select from 'react-select'
import PropTypes from 'prop-types'

import { getProductFullName } from '../functions'

const getTotal = (detail) => {
  let totalProducts = parseFloat(
    detail.work_order_detail_item_price *
    (detail.work_order_detail_item_quantity || 0)
  )

  if (detail.work_order_detail_discount) {
    totalProducts -= totalProducts * (detail.work_order_detail_discount / 100)
  }

  const total = parseFloat(totalProducts || 0)

  return total.toLocaleString('en', { style: 'currency', currency: 'USD' })
}

const ProductOrderDetail = ({
  detail,
  products,
  id,
  handleChangeDetail,
  deleteWorkOrderDetail,
  handleChangeSelectDetail,
}) => {
  const {
    work_order_detail_item,
    work_order_detail_item_price,
    recommended_work_order_detail_item_price,
    fromCombo,
    work_order_detail_item_quantity,
    work_order_detail_item_max_quantity,
    type_card,
  } = detail
  const editedProductNamePlusQuantity = getProductFullName(products).map((a) => {
    return {
      ...a, 
      fullNameProduct: `${a.fullNameProduct} (${a.product_quantity})`, 
      disabled: (a.product_quantity <= 0)
    }
  })
  
  return (
    <Row fromCombo={fromCombo}>
      <ItemTitle typeCard={type_card} />
      <Product>
        <Select
          placeholder="Repuesto"
          noResultsText="Sin resultados"
          value={work_order_detail_item}
          onChange={(e) =>
            handleChangeSelectDetail(e, 'work_order_detail_item', id)
          }
          valueKey="product_id"
          labelKey="fullNameProduct"
          options={editedProductNamePlusQuantity}
        />
      </Product>
      <ProductPrice>
        <FormControl
          type="text"
          name="work_order_detail_item_quantity"
          value={work_order_detail_item_quantity}
          placeholder="Cantidad"
          onChange={(e) => handleChangeDetail(e, id, work_order_detail_item_max_quantity)}
          required
        />
      </ProductPrice>
      <ProductPrice>
        <ControlLabel>Precio sugerido</ControlLabel>
        <FormControl
          type="text"
          name="work_order_detail_item_price"
          value={recommended_work_order_detail_item_price || 0}
          placeholder="Precio"
          readOnly
        />
      </ProductPrice>
      <ProductPrice>
        <ControlLabel>Precio</ControlLabel>
        <FormControl
          type="text"
          name="work_order_detail_item_price"
          value={work_order_detail_item_price}
          placeholder="Precio"
          onChange={(e) => handleChangeDetail(e, id)}
        />
      </ProductPrice>
      <Total>
        <p>Sub-total</p>
        <FormControl
          type="text"
          value={getTotal(detail)}
          placeholder="Total"
          onChange={(e) => handleChangeDetail(e, id)}
          required
          readOnly
        />
      </Total>
      <DeleteButton>
        <center>
          <Button
            onClick={() => deleteWorkOrderDetail(id)}
            style={{ margin: 'auto' }}
            bsSize="sm"
            bsStyle="danger"
          >
            <span className="btn-icon">
              <svg viewBox="0 0 24 24" width="100%" height="100%">
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3zm-8 5v6h2v-6H9zm4 0v6h2v-6h-2zM9 4v2h6V4H9z" />
              </svg>
            </span>
            Remover {type_card === 'product' && 'repuesto'}
          </Button>
        </center>
      </DeleteButton>
    </Row>
  )
}

const Row = styled.tr`
  ${(props) => props.fromCombo && 'background-color: #b9f6ca;'}
`

const ItemTitle = ({ typeCard }) => (
  <td>{typeCard === 'product' && 'Repuesto'}</td>
)

ItemTitle.propTypes = {
  typeCard: PropTypes.string.isRequired,
}

const Product = styled.td`
  width: 55%;
`
const ProductPrice = styled.td`
  width: 10%;
`

const Total = styled.td`
  width: 10%;

  p {
    text-align: center;
    text-decoration: underline;
  }
`
const DeleteButton = styled.td`
  width: 25%;

  button {
    display: flex;
    align-items: center;
    border-radius: 4px !important;

    .btn-icon {
      width: 1.5rem;
      margin-right: 0.5rem;

      svg {
        fill: white;
      }
    }
  }
`

ProductOrderDetail.propTypes = {
  detail: PropTypes.objectOf(PropTypes.any).isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  id: PropTypes.number.isRequired,
  handleChangeDetail: PropTypes.func.isRequired,
  deleteWorkOrderDetail: PropTypes.func.isRequired,
  handleChangeSelectDetail: PropTypes.func.isRequired,
}

export default ProductOrderDetail
