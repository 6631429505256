import styled from 'styled-components'
import BREAK_POINTS from '../../../../../../global/css/breakPoints'

export const AddServiceFormWrapper = styled.div`
  margin-top: 20px;

  padding-bottom: 16px;

  @media (${BREAK_POINTS.TABLET}) {
    padding-bottom: 0;
  }

  .main-inputs-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    padding: 0 2px;

    .ant-select-selection-item {
      max-width: 90px;
    }
    .ant-select-selector,
    .ant-select-arrow {
      border-color: #acaeb0;
      color: black;
      span {
        color: black;
      }
    }
    .disclaimer {
      width: fit-content;
      padding: 4px 8px;
      border-radius: 4px;
      margin-bottom: 12px;
      display: flex;

      background-color: #eeeeee;

      align-items: center;
      p {
        font-size: 12px;
        margin-left: 5px;
      }
    }
  }

  h2 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    font-family: inherit;
    font-size: 1.5rem;

    span {
      font-weight: 500;
      font-size: 1.2rem;
      margin-top: 5px;
      color: gray;
    }
  }

  .selectedOptions {
    display: flex;
    flex-direction: column;
    gap: 8px;

    width: 100%;
    height: 100%;

    overflow-y: auto;

    ::-webkit-scrollbar {
      width: 10px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(140, 35, 106);
      background: linear-gradient(
        353deg,
        rgba(140, 35, 106, 0.8) 40%,
        rgba(80, 52, 127, 0.8) 100%
      );
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: rgb(140, 35, 106);
      background: linear-gradient(
        353deg,
        rgba(140, 35, 106) 40%,
        rgba(80, 52, 127) 100%
      );
    }

    .loader {
      padding: 20px 0;
    }
    .process {
      position: relative;

      margin: 3px;
      display: flex;
      flex-direction: column;
      gap: 8px;

      border-radius: 10px;
      border: solid #cacaca 1px;

      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);

      .delete {
        position: absolute;
        right: 8px;
        top: 6px;
        background: unset;
        margin: unset;
        border: unset;
        font-size: 1.6rem;
        color: #e33244;
        &:hover {
          opacity: 0.7;
        }
      }

      .process-name-wrapper {
        display: flex;
        flex-direction: column;
        gap: 5px;

        border-radius: 10px 10px 0 0;
        padding: 10px 14px;

        background-color: #f4f4f4;
        h4,
        p {
          margin: unset;
        }

        h4 {
          font-size: 1.4rem;
          max-width: 80%;
        }
        p {
          margin-top: 12px;
          font-size: 1.4rem;
        }
      }

      div.process-information {
        width: 100%;
        display: flex;
        gap: 8px;
        justify-content: space-between;
        padding: 0 10px;

        align-items: start;
        flex-wrap: wrap;

        div {
          p {
            margin-bottom: 6px;
          }
        }
      }

      .secondary-inputs-wrapper {
        display: inline-flex;
        max-width: 100px;
        label {
          font-size: 1.3rem;
          font-weight: 500;

          input {
            border: #acaeb0 solid 1px;
            width: 100%;
            border-radius: 4px;
            padding: 4px 8px;
            background-color: #eeeeee;
          }
        }
      }

      .subtotal-wrapper {
        padding: 0 10px 12px;
        text-align: right;
        font-weight: 500;
        margin: unset;
        margin-top: 8px;
      }
    }
  }

  .discountTotal {
    display: flex;
    justify-content: space-between;
    margin-top: 1.2em;
    padding-bottom: 3px;

    .discountInputWrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .discount {
        display: flex;
        flex-direction: row;
        margin-left: auto;
        justify-content: flex-end;
        align-items: center;
        gap: 1em;

        margin: unset;

        font-weight: 500;
        font-size: 1.4rem;
        span {
          border-color: #acaeb0;
        }
      }
    }

    .total {
      text-align: right;
      font-size: 1.6rem;
    }
  }
`
