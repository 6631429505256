import styled from 'styled-components';

export const WarningDisclaimerAnimatedWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;

  padding: 8px 12px;
  border-radius: 5px;

  background-color: #faeabb;

  border: 2px solid transparent;
  animation: borderColorAnimation 1s linear infinite;

  i {
    display: flex;
    color: #d6ae38;
    font-size: 1.8rem;
    display: inline-block;
    animation: colorAnimation 1s linear infinite;
    @keyframes colorAnimation {
      0% {
        color: transparent;
      }
      50% {
        color: #d6ae38;
      }
      100% {
        color: transparent;
      }
    }
  }

  p {
    font-size: 0.9em;
  }

  @keyframes borderColorAnimation {
    0% {
      border-color: transparent;
    }
    50% {
      border-color: #d6ae38;
    }
    100% {
      border-color: transparent;
    }
  }
`