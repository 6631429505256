import styled from 'styled-components'

export const Container = styled.div`
  width: 80%;
  margin: 0px auto;
  margin-top: 200px;
`

export const Text = styled.div`
  text-align: center;
  font-size: ${({ fontSize }) => fontSize || '30'}px;
  font-weight: bold;
`

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-around;
`

export const Image = styled.img`
  width: 200px;
  height: 300px;
`
