import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-bootstrap'

import {
  ControlLabel,
  FormControl,
  InvoiceFormHeader,
} from '../../../GlobalComponents/Utils'

const Header = ({ fields }) => {
  const { person_name, contributor_name, isBusiness } = fields
  return (
    <InvoiceFormHeader>
      <Row style={{ marginBottom: '3rem' }}>
        <Col xs={12} md={6}>
          <ControlLabel>Cliente</ControlLabel>
          <FormControl
            type="text"
            name="person_name"
            readOnly
            value={person_name}
          />
        </Col>
        {isBusiness && contributor_name && (
          <Col xs={12} md={6}>
            <ControlLabel>Tipo de contribuyente</ControlLabel>
            <FormControl
              type="text"
              name="contributor_name"
              readOnly
              value={contributor_name}
            />
          </Col>
        )}
      </Row>
    </InvoiceFormHeader>
  )
}

Header.propTypes = {
  fields: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default Header
