import moment from 'moment'
import { capitalize } from 'lodash'
import JsPDF from 'jspdf'
import { numberFormatter } from '../../../../Helpers'

const setFontStyle = (doc, { size, style }) => {
  doc.setFontSize(size)
  doc.setFontStyle(style)
}

const printTicketPDFWithoutTaxes = async (
  workshop,
  nTicket,
  bodyFields,
  footerDetails,
  customer
) => {
  // Initial values
  let x = 5
  let y = 5
  const lineHeight = 1.5
  const fontSize = 4
  const doc = new JsPDF('p', 'mm', [160, 220], true)
  setFontStyle(doc, { size: fontSize })

  const { pack, details } = bodyFields

  const pageWidth =
    doc.internal.pageSize.width || doc.internal.pageSize.getWidth()
  const pageHeight =
    doc.internal.pageSize.height || doc.internal.pageSize.getHeight()

  // ---- WORKSHOP INFO DETAILS ----- //
  const { workshop_name, workshop_address, workshop_phone } = workshop
  doc.text(workshop_name || '', pageWidth / 2, y, { align: 'center' })
  y += lineHeight
  doc.text(workshop_address || '', pageWidth / 2, y, { align: 'center' })
  y += lineHeight
  doc.text(workshop_phone || '', pageWidth / 2, y, { align: 'center' })

  // ---- TICKET INFO ----- //
  const date = capitalize(moment().format('DD-MM-YYYY hh:mm:ss A'))
  y += lineHeight
  y += lineHeight
  doc.text(date, pageWidth / 2, y, 'center')
  y += lineHeight
  doc.text(`Ticket: #${nTicket}` || '', pageWidth / 2, y, 'center')

  // ---- CUSTOMER INFO ----- //
  const { person_firstname, person_lastname } = customer
  y += lineHeight
  y += lineHeight
  if (customer) {
    doc.text(`Cliente: ${person_firstname} ${person_lastname}`, x, y)
  }

  // ---- TABLE DETAILS ----- //
  const resetX = x
  const marginX = 5

  y += lineHeight
  y += lineHeight
  y += lineHeight
  doc.text('Cant.' || '', x, y)

  x += marginX
  doc.text('Descripción' || '', x, y)

  x += marginX
  x += marginX
  x += marginX
  x += marginX
  x += marginX
  doc.text('Descuento' || '', x, y)

  x += marginX
  x += marginX
  doc.text('Total' || '', x, y)

  y += lineHeight

  // ---- package detail -----
  if (Object.keys(pack).length > 0) {
    x = resetX
    y += lineHeight
    doc.text(`${pack.quantity}` || '', x, y)

    x += marginX
    doc.text(`${pack.description}` || '', x, y)

    x += marginX
    x += marginX
    x += marginX
    x += marginX
    x += marginX
    const packageDiscount = numberFormatter.format(
      pack.totalDiscount
    )
    doc.text(`${packageDiscount}` || '', x, y)

    x += marginX
    x += marginX
    const packagePriceWithDiscount = numberFormatter.format(pack.totalAffectSale)
    doc.text(`${packagePriceWithDiscount}` || '', x, y)
  }

  // ---- aditional details -----
  details.map(
    // eslint-disable-next-line array-callback-return
    ({ quantity, description, totalDiscount, totalAffectSale }) => {
      x = resetX
      y += lineHeight

      doc.text(`${quantity}` || '', x, y)

      x += marginX
      doc.text(`${description}` || '', x, y)

      x += marginX
      x += marginX
      x += marginX
      x += marginX
      x += marginX
      const detailDiscount = numberFormatter.format(
        totalDiscount
      )
      doc.text(`${detailDiscount}` || '', x, y)

      x += marginX
      x += marginX
      const detailPriceWithDiscount = numberFormatter.format(
        totalAffectSale
      )
      doc.text(`${detailPriceWithDiscount}` || '', x, y)
    }
  )

  // ---- FOOTER MESSAGE ----- //
  const bottomY = pageHeight - lineHeight * 3
  y = bottomY
  doc.text('¡BUEN DÍA!', pageWidth / 2, y, { align: 'center' })

  // ---- TOTAL DETAILS ----- //
  const { total } = footerDetails

  const formatTotal = numberFormatter.format(total)

  x = pageWidth - marginX * 1.5
  y -= lineHeight
  y -= lineHeight
  y -= lineHeight
  doc.text(`${formatTotal}`, x, y, { align: 'right' })

  const resetY = y

  x -= marginX * 3
  y = resetY
  doc.text('Total:', x, y)


  const uri = doc.output('datauristring')
  return uri
}

export default printTicketPDFWithoutTaxes
