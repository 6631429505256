/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react'

import Upload from 'antd/es/upload/Upload'
import { Modal } from 'antd'
import MultiplePhotoInputWrapper from './MultiplePhotoInput.styles'
import MultiplePhotoInputPropTypes from './MultiplePhotoInput.types'
import getBase64 from '../../Utilities/getBase64'

const MultiplePhotoInput = ({ handleAddPhoto, photos, maxPhotosAllowed }) => {
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')

  const onChange = ({ fileList: newFileList }) => {
    handleAddPhoto(newFileList)
  }

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok')
    }, 0)
  }
  
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      // eslint-disable-next-line no-param-reassign
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage(file.url || file.preview)
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    )
    setPreviewOpen(true)
  }

  const handleCancel = () => setPreviewOpen(false)
  return (
    <MultiplePhotoInputWrapper>
      <p className="title">
        {photos.length > 0 && `Fotografías agregadas (${photos.length})`}
      </p>
      <Upload
        accept="image/*"
        name="images"
        listType="picture-card"
        className="content"
        fileList={photos}
        onChange={onChange}
        customRequest={dummyRequest}
        onPreview={handlePreview}
      >
        {photos.length + 1 <= maxPhotosAllowed && (
          <div className="upload">
            <img src="/images/img-upload.png" alt="upload icon" />
          </div>
        )}
      </Upload>
      <Modal
        open={previewOpen}
        footer={null}
        onCancel={handleCancel}
        zIndex={1100}
        title={previewTitle}
        centered
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '24px',
          }}
        >
          <img
            alt="example"
            style={{ width: '90%', height: '90%', objectFit: 'contain' }}
            src={previewImage}
          />
        </div>
      </Modal>
    </MultiplePhotoInputWrapper>
  )
}
MultiplePhotoInput.propTypes = MultiplePhotoInputPropTypes

MultiplePhotoInput.defaultProps = {
  maxPhotosAllowed: 5,
}

export default MultiplePhotoInput
