import React from 'react'
import { PropTypes } from 'prop-types'
import { Divider, Space, Button } from 'antd'

const SelectFooterButton = ({ buttonText, onClickHandler }) => {
  return (
    <>
      <Divider
        style={{
          margin: '8px 0',
        }}
      />
      <Space
        style={{
          padding: '0 0 4px',
        }}
      >
        <Button
          type="text"
          onClick={onClickHandler}
          style={{
            fontWeight: 500,
            display: 'flex',
            alignItems: 'center',
            margin: 0,
          }}
        >
          <i
            className="fas fa-plus-circle"
            style={{ fontSize: '2rem', color: '#2ABD69', marginRight: '7px' }}
          />{' '}
          {buttonText}
        </Button>
      </Space>
    </>
  )
}

SelectFooterButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  onClickHandler: PropTypes.func.isRequired,
}

export default SelectFooterButton
