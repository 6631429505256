import _ from 'lodash'

import {
  GET_PERSONS,
  ADD_PERSON,
  UPDATE_PERSON,
  DELETE_PERSON,
  UPDATE_PERSON_PHONE
} from '../actionTypes/PersonTypes'

export default (
  state = {
    persons: {},
  },
  action
) => {
  switch (action.type) {
    case GET_PERSONS: {
      return {
        ...state,
        persons: action.payload,
      }
    }
    case ADD_PERSON: {
      return {
        ...state,
        persons: {
          ...state.persons,
          [action.payload.person_id]: action.payload,
        },
      }
    }
    case UPDATE_PERSON: {
      return {
        ...state,
        persons: {
          ...state.persons,
          [action.payload.person_id]: action.payload,
        },
      }
    }
    case UPDATE_PERSON_PHONE: {
      return {
        ...state,
        persons: {
          ...state.persons,
          [action.payload.person_id]: action.payload,
        },
      }
    }
    case DELETE_PERSON: {
      const newState = _.cloneDeep(state)
      delete newState.persons[action.payload.person_id]
      return newState
    }

    default: {
      return state
    }
  }
}
