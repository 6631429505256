import styled from 'styled-components'
import BREAK_POINTS from '../../../../../global/css/breakPoints'

const SelectedVehicleCardWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
  width: 95%;
  max-width: 480px;
  border-radius: 8px;
  border: 2px solid #8d2866;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 12px 16px;
  align-items: center;

  @media (${BREAK_POINTS.TABLET}) {
    padding: 16px;
    margin-bottom: 20px;
  }

  gap: 8px;
  i {
    font-size: 3rem;

    @media (${BREAK_POINTS.TABLET}) {
      font-size: 4.5rem;
    }
  }
  .details {
    display: flex;
    flex-wrap: wrap;
    gap: 7px;

    p {
      font-weight: 700;
      text-transform: capitalize;
      margin: 0;
    }
    .license-plate {
      font-weight: 300;
    }

    @media (${BREAK_POINTS.TABLET}) {
      display: block;
    }
  }
`

export default SelectedVehicleCardWrapper
