import _ from 'lodash'

import {
  GET_MECHANICS,
  ADD_MECHANIC,
  UPDATE_MECHANIC,
  DELETE_MECHANIC,
} from '../actionTypes/MechanicTypes'

export default (
  state = {
    mechanics: {},
  },
  action
) => {
  switch (action.type) {
    case GET_MECHANICS: {
      return {
        ...state,
        mechanics: action.payload,
      }
    }
    case ADD_MECHANIC: {
      return {
        ...state,
        mechanics: {
          ...state.mechanics,
          [action.payload.mechanic_id]: action.payload,
        },
      }
    }
    case UPDATE_MECHANIC: {
      return {
        ...state,
        mechanics: {
          ...state.mechanics,
          [action.payload.mechanic_id]: action.payload,
        },
      }
    }
    case DELETE_MECHANIC: {
      const newState = _.cloneDeep(state)
      delete newState.mechanics[action.payload.mechanic_id]
      return newState
    }

    default: {
      return state
    }
  }
}
