import React from 'react'
import { PropTypes } from 'prop-types'

const VideoCameraIcon = ({ color, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fontSize={size}
      height="1em"
      viewBox="0 0 576 512"
    >
      <path
        opacity="1"
        fill={color}
        d="M336.2 64H47.8C21.4 64 0 85.4 0 111.8v288.4C0 426.6 21.4 448 47.8 448h288.4c26.4 0 47.8-21.4 47.8-47.8V111.8c0-26.4-21.4-47.8-47.8-47.8zm189.4 37.7L416 177.3v157.4l109.6 75.5c21.2 14.6 50.4-.3 50.4-25.8V127.5c0-25.4-29.1-40.4-50.4-25.8z"
      />
    </svg>
  )
}

VideoCameraIcon.propTypes = {
  color: PropTypes.string.isRequired,
  size: PropTypes.string,
}

VideoCameraIcon.defaultProps = {
  size: '13px',
}

export default VideoCameraIcon
