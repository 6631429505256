/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import { Button } from 'react-bootstrap'
import { Switch } from 'antd'
import { useForm, FormProvider } from 'react-hook-form'
import AddClientFormWrapper from './AddClientForm.styles'
import PreviousNextButton from '../../../../buttons/PreviousNextButton/PreviousNextButton'
import GradientTextComponent from '../../../Common/GradientText.js/GradientText'
import { addReceptionActions } from '../../../../../reducers/AddReceptionReducer'
import { useAddReceptionContext } from '../../../../../contexts/AddReceptionContext'
import TextFormControl from '../../../Common/TextFormControl/TextFormControl'
import PhoneFormControl from '../../../Common/PhoneFormControl/PhoneFormControl'
import InfoDisclaimer from '../../../Common/InfoDisclaimer/InfoDisclaimer'
import CustomSelect from '../../../../inputs/CustomSelect/CustomSelect'
import {
  emailRegex,
  stringRegex,
  stringRestrictedRegext,
} from '../../../../Utilities/validations'
import {
  formatPhoneToDB,
  getCountryCode,
} from '../../../../Utilities/formatPhone'
import {
  addClient,
  getContributorTypes,
} from '../../../../../api/apiAddClientService'
import { NotificationHandler } from '../../../../../Helpers'
import { useModal } from '../../../../../hooks/useModal'
import SuccessModal from '../../../Common/SuccessModal/SuccessModal'

const AddClientForm = ({ workshopCountryCode }) => {
  const [isModalOpen, openModal, closeModal] = useModal()
  const { dispatch, state } = useAddReceptionContext()
  const [isLegalPerson, setLegalPerson] = useState(false)
  const [contributorType, setContributorType] = useState(null)
  const [contribuitorTypesList, setContribuitorTypesList] = useState([])
  const { selectedVehicle } = state
  const methods = useForm({
    defaultValues: {
      name: '',
      lastname: '',
      email: '',
      phone: '',
      bussinesName: '',
      bussinesReason: '',
      bussinesEmail: '',
      bussinesPhone: '',
      bussinesNit: '',
      bussinesGiro: '',
    },
    mode: 'onChange',
  })
  const { errors } = methods.formState

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getContributorTypes()
        const mappedResponse = response.map((option) => {
          return {
            value: option.contributorTypeId,
            label: option.contributorTypeName,
            customData: option,
          }
        })
        setContribuitorTypesList(mappedResponse)
      } catch (e) {
        NotificationHandler(
          'Error',
          'error',
          'Error al obtener el listado de contribuidores'
        )
      }
    }
    getData()
  }, [])

  const handleIsLegalPerson = () => {
    methods.reset()
    setLegalPerson((prev) => !prev)
  }

  const WorkShopCountryCode =
    workshopCountryCode || localStorage.getItem('workshopCountryCode')

  const navigateToSelectClient = () => {
    dispatch({ type: addReceptionActions.SET_SUB_STEP, payload: null })
  }

  const handleCloseSuccessModal = () => {
    navigateToSelectClient()
    closeModal()
  }

  const onSubmit = async (data) => {
    try {
      const props = {
        clientType: isLegalPerson ? 1 : 0,
        clientName: data.name,
        clientLastName: data.lastname,
        clientPhone: formatPhoneToDB(data.phone),
        clientEmail: data.email,
        clientCountryCode: getCountryCode(data.phone),
        bussinesName: data.bussinesName,
        bussinesReason: data.bussinesReason,
        bussinesNit: data.bussinesNit,
        bussinesGiro: data.bussinesGiro,
        bussinesContributorType: contributorType?.value,
        bussinesPhone: formatPhoneToDB(data.bussinesPhone),
        bussinesCountryCode: getCountryCode(data.bussinesPhone),
        bussinesEmail: data.bussinesEmail,
      }

      const response = await addClient(props, selectedVehicle.vehicleId)

      const client = {
        clientName: `${response.data.personFirstname} ${response.data.personLastname}`,
        countryCode: response.data.countryCode,
        ownerId: response.data.personId,
        phone: response.data.personPhone,
      }
      const clientValue = {
        label: `${response.data.personFirstname} ${response.data.personLastname}`,
        value: response.data.personId,
        key: response.data.personId,
        title: undefined,
        disabled: undefined,
      }

      dispatch({
        type: addReceptionActions.SELECT_CLIENT,
        payload: {
          client,
          clientValue,
        },
      })
      openModal()
      setTimeout(() => {
        handleCloseSuccessModal()
      }, 2500)
    } catch (e) {
      NotificationHandler('Error', 'error', e)
    }
  }

  const handleSelectedContributorType = (_, option) => {
    setContributorType(option)
  }

  return (
    <>
      <main className="new-reception__main">
        <div className="new-reception__main__question">
          <GradientTextComponent
            text={isLegalPerson ? 'Nuevo Comercio' : 'Nuevo Cliente'}
          />
        </div>
        <div className="new-reception__main__content">
          <AddClientFormWrapper>
            <FormProvider {...methods}>
              <form>
                <div className="switch-wrapper">
                  <p>
                    Registrar como <b>comercio</b>
                  </p>
                  <Switch
                    onChange={handleIsLegalPerson}
                    checkedChildren="Sí"
                    unCheckedChildren="No"
                  />
                </div>
                {isLegalPerson ? (
                  <>
                    <TextFormControl
                      label="Nombre comercial"
                      name="bussinesName"
                      error={errors?.bussinesName?.message}
                      required
                      requiredMessage="El nombre es un campo requerido."
                      pattern={stringRegex}
                      patternMessage="Ingrese un nombre valido."
                    />
                    <TextFormControl
                      label="Razón social"
                      name="bussinesReason"
                      error={errors?.bussinesReason?.message}
                      pattern={stringRegex}
                      patternMessage="Ingrese un apellido valido."
                    />
                    <TextFormControl
                      label="Correo"
                      error={errors?.bussinesEmail?.message}
                      name="bussinesEmail"
                      pattern={emailRegex}
                      patternMessage="Ingrese un correo valido."
                    />
                    <div className="phone-wrapper">
                      <PhoneFormControl
                        label="Teléfono"
                        name="bussinesPhone"
                        required
                        error={errors?.bussinesPhone?.message}
                        requiredMessage="El teléfono es un campo requerido."
                        workShopCode={WorkShopCountryCode}
                      />
                      <InfoDisclaimer message="Este número será utilizado para enviar notificaciones por WhatsApp." />
                    </div>
                    <TextFormControl
                      label="NIT"
                      error={errors?.bussinesNit?.message}
                      name="bussinesNit"
                      pattern={stringRegex}
                      patternMessage="Ingrese un nit valido."
                    />
                    <TextFormControl
                      label="Giro"
                      error={errors?.bussinesGiro?.message}
                      name="bussinesGiro"
                      pattern={stringRestrictedRegext}
                      patternMessage="Ingrese un giro valido."
                    />
                    <CustomSelect
                      id="contribuitor-type"
                      items={contribuitorTypesList}
                      value={contributorType}
                      label="Tipo de contribuyente"
                      placeholder="Tipo de contribuyente"
                      handleValueChange={handleSelectedContributorType}
                    />
                  </>
                ) : (
                  <>
                    <TextFormControl
                      label="Nombre"
                      name="name"
                      error={errors?.name?.message}
                      required
                      requiredMessage="El nombre es un campo requerido."
                      pattern={stringRestrictedRegext}
                      patternMessage="Ingrese un nombre valido."
                    />
                    <TextFormControl
                      label="Apellido"
                      name="lastname"
                      error={errors?.lastname?.message}
                      required
                      requiredMessage="El apellido es un campo requerido."
                      pattern={stringRestrictedRegext}
                      patternMessage="Ingrese un apellido valido."
                    />
                    <TextFormControl
                      label="Correo"
                      error={errors?.email?.message}
                      name="email"
                      pattern={emailRegex}
                      patternMessage="Ingrese un correo valido."
                    />
                    <div className="phone-wrapper">
                      <PhoneFormControl
                        label="Teléfono"
                        name="phone"
                        required
                        error={errors?.phone?.message}
                        requiredMessage="El teléfono es un campo requerido."
                        workShopCode={WorkShopCountryCode}
                      />
                      <InfoDisclaimer message="Este número será utilizado para enviar notificaciones por WhatsApp." />
                    </div>
                  </>
                )}
              </form>
            </FormProvider>
          </AddClientFormWrapper>
        </div>
      </main>
      <div className="new-reception__navigation-buttons">
        <PreviousNextButton
          isPrevious
          onClickHandler={navigateToSelectClient}
        />
        <Button bsStyle="success" onClick={methods.handleSubmit(onSubmit)}>
          Crear cliente
        </Button>
      </div>
      <SuccessModal
        isModalOpen={isModalOpen}
        closeModal={handleCloseSuccessModal}
        text="¡Cliente registrado exitosamente!"
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  workshopCountryCode: state.AppReducer.workshopCountryCode,
})

AddClientForm.defaultProps = {
  workshopCountryCode: '',
}

AddClientForm.propTypes = {
  workshopCountryCode: PropTypes.any,
}

export default connect(mapStateToProps)(AddClientForm)
