import { Player } from '@lottiefiles/react-lottie-player'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import React from 'react'

import clockAnimation from '../../../lotties/clock-animation.json'
import { buildTimeObjectString } from '../../../Helpers'

const Timer = ({ time, className }) => (
  <TimerContainer className={className}>
    <div className="text">
      <Player
        src={clockAnimation}
        loop
        autoplay
        style={{ width: '18px', marginTop: '4px' }}
      />
      <span>{buildTimeObjectString(time)}</span>
    </div>
  </TimerContainer>
)

Timer.propTypes = {
  time: PropTypes.objectOf(PropTypes.number).isRequired,
  className: PropTypes.string.isRequired,
}

const TimerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  border-radius: 10px;
  background: rgb(166, 28, 93);
  background: linear-gradient(
    288deg,
    rgba(166, 28, 93, 1) 40%,
    rgba(69, 57, 141, 1) 100%
  );
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;

  .text {
    font-size: 1.6rem;
    font-weight: bold;

    display: flex;
    align-items: center;
    gap: 10px;
  }
`

export default Timer
