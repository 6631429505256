import { Modal } from 'antd'
import styled from 'styled-components'

const AddVideosModalStyled = styled(Modal)`
  &.add-photos {
    .ant-modal-content {
      .ant-modal-header {
        .ant-modal-title {
          font-size: 1.8rem;
        }
      }
      .ant-modal-body {
        margin: 20px 0 34px 0;
      }
    }
  }
`

export default AddVideosModalStyled
