import axios from 'axios'

export const getProvidersList = async () => {
  try {
    const response = await axios.get(`api/provider/reception/all`)
    return response.data.data
  } catch (error) {
    throw ('Error cargando lista de proveedores', error)
  }
}

export const createProvider = async (newProvider) => {
  try {
    const response = await axios.post('api/provider/reception/', {
      ...newProvider,
    })
     return response.data
  } catch (error) {
    throw ('Error creando proveedor', error)
  }
}